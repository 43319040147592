import React from 'react';
import { Progress } from 'semantic-ui-react';
import SweetAlert from "react-bootstrap-sweetalert";

import { AppFrameAction } from "./../appframe";
import { ContextConnector } from '../appcontext.js';
import { BIPSAppContext } from "../AppData";

class ModalLoading_Base extends React.PureComponent{    
    render(){
        let { isDataLoading=false} = this.props;
        return(
            <>   
                <AppFrameAction ref="frameAction" />
                <SweetAlert
                    show={isDataLoading}
                    text="SweetAlert in React"
                    style={{'color':'var(--text-white)',}}
                    confirmBtnCssClass={"btn btn-sm btn-popup bg-gray-tradding border-gray-tradding"}
                    customClass={"bg-dark-grey f-12"}
                    showConfirm={false}
                >
                    <div className="card card-body row mx-0">
                        <div className="w-100 text-center">
                            <div className="ui big active centered inline loader mb-3"></div>
                            <p className="f-12 text-white">Please wait a few moments...</p>
                        </div>
                        <Progress className="w-100 my-0" percent={100} active/>
                    </div>
                </SweetAlert>
            </>
        );
    }
}


//************************** Context Connector **************************
const ModalLoading = ContextConnector(BIPSAppContext,
    (vars) => ({
        isDataLoading: vars.isDataLoading
    }),
    ["handleAlertAutomatic"]
)(ModalLoading_Base)

export default ModalLoading;