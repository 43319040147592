import { baseAPI } from './index';


const fetchApi = async function (
    url,
    opts = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    },
    typeResponse = "json"
) {
    if(opts.headers === undefined){
        opts = { ...opts, headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        } }
    }
    var data = [], resp_code = "01";
    var fetchResponse = await fetch(`${baseAPI.api + url}`, opts)
    if (fetchResponse.status !== 200 || fetchResponse === null) {
        return {
            resp_code: fetchResponse.status,
            resp_msg: `HTTP request error ${fetchResponse.status}. Reason: ${fetchResponse.statusText}`
        }
    } else {
        if(typeResponse == "blob"){
            data = await fetchResponse.blob()
        }else{
            data = await fetchResponse.json()
            resp_code = data ? "00" : "01"
        }
        return {...data, resp_code};
    }
}

export { fetchApi }
