
const dummy = {
    // dummy data vars

    // PUSH NOTIF
    pushNotif: {
        "status": true,
        "headline": "NOTIFIKASI WEB",
        "content_data": "<p style=\"text-align:center;\"><strong>Pemberitahuan kepada Investor Pemula xx USER 39</strong></p><p style=\"text-align:center;\"><span style=\"background-color:rgb(255,255,255);color:rgb(33,37,41);font-family:-apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;font-size:16px;\"><span style=\"-webkit-text-stroke-width:0px;display:inline important;float:none;font-style:normal;font-variant-caps:normal;font-variant-ligatures:normal;font-weight:400;letter-spacing:normal;orphans:2;text-align:center;text-decoration-color:initial;text-decoration-style:initial;text-decoration-thickness:initial;text-indent:0px;text-transform:none;white-space:normal;widows:2;word-spacing:0px;\">Test Konten data Pemberitahuan kepada Investor Pemula xx USER 39</span></span></p>",
        "content_url": "http://103.182.72.16:18010/pusnot/img/471",
        "push_at": "",
        "behaviour_id": "960"
    },
    listNotif: {
        status: true,
        data: Array.from({ length: 5 },
            (v) => ({
                "created_at": "2023-08-30 04:03:35",
                "created_by": 1,
                "template_id": 141,
                "notifikasi_id": 471,
                "behaviour_id": 960,
                "headline": "NOTIFIKASI WEB",
                "content_data": "<p style=\"text-align:center;\"><strong>Pemberitahuan kepada Investor Pemula xx USER 39</strong></p><p style=\"text-align:center;\"><span style=\"background-color:rgb(255,255,255);color:rgb(33,37,41);font-family:-apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;font-size:16px;\"><span style=\"-webkit-text-stroke-width:0px;display:inline !important;float:none;font-style:normal;font-variant-caps:normal;font-variant-ligatures:normal;font-weight:400;letter-spacing:normal;orphans:2;text-align:center;text-decoration-color:initial;text-decoration-style:initial;text-decoration-thickness:initial;text-indent:0px;text-transform:none;white-space:normal;widows:2;word-spacing:0px;\">Test Konten data Pemberitahuan kepada Investor Pemula xx USER 39</span></span></p>",
                "content_data_mobile": "Pemberitahuan kepada Investor Pemula xx USER 39Test Konten data Pemberitahuan kepada Investor Pemula xx USER 39",
                "content_url": "http://103.182.72.16:18010/pusnot/img/471",
                "push_start_at": "2023-08-30 16:05:26",
                "push_end_at": "2023-08-31 16:05:26",
                "scheduled_push_at_id": 1,
                "scheduled_push_at_id_desc": "Send Right Now",
                "scheduled_push_end_id": 1,
                "scheduled_push_end_id_desc": "Send Right Now",
                "repeat_push": 1,
                "interval": 0,
                "interval_push_category_id": 1,
                "interval_push_category_id_desc": "Jam",
                "notif_count": 1
            })
        ),
    }
}

export { dummy }