import React from "react";
import { AgGridReact } from "ag-grid-react";

import { ContextConnector } from "../../appcontext";
import { BIPSAppContext } from "../../AppData";

import EtalaseChart from "./etalaseChart";
import { CustomTooltip } from '../../app_components';
import * as util from '../../utils';

class ModalEtalaseProduct_Base extends React.PureComponent{
    render() {
        let { FundName, FundCat, NAV_BY_PERIODE=[], 
            COMPANY_PROFILE = util.dummy.APERD_COMPANY_PROFILE_BY_IM_CODE.data, 
            FUND_DETAIL= util.dummy.APERD_FUND_DETAIL.data
        } = this.props;
        return (
            <>
                <div className="col-sm-12 text-white px-0 mx-0 py-2 f-12 t-border-top">
                    <div className="col-sm-12 row mx-0 px-0">
                        <div class="col-sm-6 px-0 mb-3 mt-3 f-13">
                            <h3>{FundName}</h3>
                            {COMPANY_PROFILE.Desc}
                        </div>
                        <div class="col-sm-6 px-0 mb-3 mt-3 text-right pt-4">
                            <a class="btn btn-dark f-12 mr-4" href={FUND_DETAIL.Fundfact} target="blank"><i class="fa fa-download"></i>&nbsp;Fund Fact Sheet</a>
                            <a class="btn btn-dark f-12" href={FUND_DETAIL.Prospectus} target="blank"><i class="fa fa-download"></i>&nbsp;Prospectus</a>
                        </div>
                        <div class="col-sm-4 d-border-right d-border-left">
                            Nab/Unit<br/>
                            Rp. {util.mathCall.setRupiah(Number(FUND_DETAIL.NAV)/Number(FUND_DETAIL.NAV_Unit), 0)}<br/>
                            Per {util.timeCall.todate1(FUND_DETAIL.NAV_Date)}<br/>
                        </div>
                        <div class="col-sm-4 d-border-right">
                            Jumlah Dana Kelola<br/>
                            Rp. {util.mathCall.setRupiah(FUND_DETAIL.NAV)}<br/>
                        </div>
                        <div class="col-sm-4 d-border-right">
                            Jenis Reksa dana<br/>
                            {FundCat}<br/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 text-white px-0 mx-0 py-2">
                    <h4>NAV Unit Chart</h4>
                    <div className="card bg-grey">
                        <div className="card-body card-283 mx-0 px-0 my-0 py-0">
                            <EtalaseChart chartData={NAV_BY_PERIODE} fundName={FUND_DETAIL.FundName} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class OrderLictCodeAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: "dateTop", headerName: "", sortable: true,
                    filter: "agTextColumnFilter", resizable: true,
                    width: 69, minWidth: 69, lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                    }, suppressSizeToFit: true, children: [{
                        field: "dateO", headerName: "Date", sortable: true,
                        filter: "agTextColumnFilter", resizable: true,
                        width: 69, minWidth: 69, lockPosition: true, lockVisible: true,
                        cellClass : function (params) {
                            return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                        }, suppressSizeToFit: true
                    },]},
                { field: "timeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "timeO", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "marketOrderTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "marketOrderNo0", headerName: "Market Order No.", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 140, minWidth: 140,
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "actionsTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width:160,
                    cellClass : function (params) {
                        var pl = params.data.actionsO;
                        return pl.includes('-') === true ? "text-danger text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                            "text-success text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                    }, children: [{ field: "actionsO", headerName: "Action", sortable: true, filter: "agTextColumnFilter", resizable: true,
                        width: 80, minWidth: 80,
                        cellClass : function (params) {
                            var pl = params.data.actionsO;
                            return pl === 'buy' ? "text-danger text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'sell' ? "text-success text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'amend' ? "text-primary text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'withdraw' ? "text-warning text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                    "text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                        }
                    },],
                },
                { field: "orderTypeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 130,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "orderType0", headerName: "Order Type", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 130, minWidth: 130,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            }
                        },
                    ],
                },
                { field: "statusTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                width: 130,
                cellClass : function (params) {
                    return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                }, children: [
                    { field: "statusO", headerName: "Status", sortable: true, filter: "agTextColumnFilter", resizable: true,
                        width: 130, minWidth: 130,
                        cellClass : function (params) {
                            return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                        }
                    },
                ],
            },
                { field: "remaksTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 230, suppressSizeToFit: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "remarksO", headerName: "Remark", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            suppressSizeToFit: true,tooltipField: 'remarksO',
                            tooltipComponentParams: { type: 'remarksO' },
                            
                            width: 230, minWidth: 230,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            }
                        },
                    ],
                },
                { field: "vol", headerName: "Vol", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 260,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "vlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },

                    ]
                },
                { field: "priceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: this.props.gridView == 'grid' ? 94 : 206,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "priceO", headerName: "Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 100, minWidth: 100,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "matchvol", headerName: "Match Vol", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "mlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                        { field: "mshares", headerName: "Shares", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            },
                        }
                    ]
                },
                { field: "matchpriceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "matchpriceO", headerName: "Match Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "leaveVolTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "leaveVol0", headerName: "Leave Vol(Lot)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 150, minWidth: 150,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "userIdTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "userId0", headerName: "User ID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',

            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            rowData: [
                { dateO: "04/07/2019",
                    timeO: "11:22:17",
                    actionsO: "withdraw",
                    orderType0: "Limit",
                    statusO: "done",
                    remarksO: "Order Amend Request Confirmed 123123123",
                    vlot: "10",
                    vshares: "1,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"12,650,000" ,
                    marketOrderNo0: "MKT0001",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:12:10",
                    actionsO: "",
                    orderType0: "Market",
                    statusO: "partial",
                    remarksO: "Order Amend Request Confirmed",
                    vlot: "30",
                    vshares: "3,000",
                    priceO: "12,650",
                    mlot: "20",
                    mshares: "2,000"   ,
                    matchpriceO:"12,600",
                    amountO:"25,300,000",
                    marketOrderNo0: "MKT0002",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:10:17",
                    actionsO: "amend",
                    orderType0: "Limit",
                    statusO: "done",
                    remarksO: "",
                    vlot: "30",
                    vshares: "3,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"37,950,000",
                    marketOrderNo0: "MKT0003",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:02:55",
                    actionsO: "",
                    statusO: "partial",
                    remarksO: "patial match",
                    vlot: "100",
                    vshares: "10,000",
                    priceO: "12,650",
                    mlot: "50",
                    mshares: "5,000"   ,
                    matchpriceO:"12,600",
                    amountO:"63,250,000",
                    marketOrderNo0: "MKT0004",
                    leaveVol0: "10",
                    userId0: "johndide",},
                { dateO: "04/07/2019",
                    timeO: "11:00:23",
                    actionsO: "buy",
                    statusO: "done",
                    remarksO: "",
                    vlot: "100",
                    vshares: "10,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"126,500,000",
                    marketOrderNo0: "MKT0005",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-233 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}


const ModalEtalaseProduct = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        COMPANY_PROFILE: vars.COMPANY_PROFILE,
        FUND_DETAIL: vars.FUND_DETAIL,
        NAV_BY_PERIODE: vars.NAV_BY_PERIODE,
    }),
)(ModalEtalaseProduct_Base);

export default ModalEtalaseProduct;