
import { fd, ceksize, GetInitialRow, GetRowChange } from "./appConfig.js";

const setPuluhan = (data) => data < 10 ? `0${data}` : data;

function timeModifier(type, data){
    let result, ndata = data.split(" "), ndate, ntime;
    switch (type) {
        case "ol":
            ndate = ndata[0].substr(0,4)+"-"+ndata[0].substr(4,2)+"-"+ndata[0].substr(6,2);
            return `${ndate} ${ndata[1].substr(0,8)}`; break;
        case "norder":
            ndate = ndata[0].substr(0,4)+"-"+ndata[0].substr(4,2)+"-"+ndata[0].substr(6,2)
            ntime = ndata[1].substr(0,2)+":"+ndata[1].substr(2,2)+":"+ndata[1].substr(4,2)
            return ndate+" "+ntime; break;
        case "chart":
            if(data){
                let d = new Date();
                let h = parseInt(data.substring(0,2));
                d.setHours(h);
                d.setMinutes(data.substring(3,5));
                d.setSeconds(data.substring(6,8));
                return parseInt(d.getTime()) + 25200000;
            }; break;
        case "reqDate": /*03/11/2020 => 2020-11-03 */
            ndate = data.split("/")
            return ndate[2]+"-"+ndate[1]+"-"+ndate[0]; break; 
        case "v0": /*2020-11-03 => 03/11/2020 */
            ndate = data.split("-")
            return ndate[2]+"/"+ndate[1]+"/"+ndate[0]; break;
        case "vt-1": /* 111545 => 11:15:45 */
            ndata = data.length === 5 ? `0${data}` : data;
            return ndata.substring(0, 2)+":"+ndata.substring(2, 4)+":"+ndata.substring(4, 6); break;
        case "vd-1": /* 20210115 => 15-01-2021*/
            return data.substring(6, 8)+"-"+data.substring(4, 6)+"-"+data.substring(0, 4); break;
        case "v1": 
            let na = new Date(data);
            let ndate = `${na.getFullYear()}-${setPuluhan(na.getMonth()+1)}-${setPuluhan(na.getDate())}`;
            let time = `${setPuluhan(na.getHours())}:${setPuluhan(na.getMinutes())}:${setPuluhan(na.getSeconds())}`;
            return `${ndate} ${time}`;
    }
}
// convert Share to Lot
function ShareToLot(number) {
    if(number != 0){
        // var numberRef = parseInt(number)
        var divider = 100;
        var lot = number/divider;
        return lot;
    }else{
        return number;
    }
}

// GET INQUERY STOCK DATA CODE-R
function GetStockDataR(data){
    return {
        "margin_type": "",
        "remarks2": data.remarks2,
        "fin_report": "-",
        "board_pos": "",
        "sector_no": data.sector_no,
        "remarks": data.remarks,
        "index_comp": "",
        "status": data.status,
        "base_price": data.base_price,
        "stock_code": data.stock_code,
        "sub_sector_name": data.sub_sector_name,
        "ipo_price": data.ipo_price,
        "tradable_share": data.tradable_share,
        "type": data.type,
        "neq_equity": "-",
        "sub_sector_no": data.sub_sector_no,
        "bankruptcy": "-",
        "sales_info": "-",
        "weight": data.weight,
        "trade_in_pre": "",
        "name": data.name,
        "audit_info": "-",
        "share_per_lot": data.share_per_lot,
        "sector_name": data.sector_name,
        "sector_code": data.sector_code,
        "listed_share": data.listed_share,
        "corp_action":""
    }
}
function GetWarningStock(data) {
    let warningStock = data.bankruptcy !== "-" ? data.bankruptcy :
    data.neq_equity !== "-" ? data.neq_equity :
    data.audit_info !== "-" ? data.audit_info :
    data.fin_report !== "-" ? data.fin_report :
    data.sales_info !== "-" ? data.sales_info : 
    data.lawsuit_info !== "-" ? data.lawsuit_info :
    data.restriction_info !== "-" ? data.restriction_info :
    data.meeting_info !== "-" ? data.meeting_info :
    data.ojk_info !== "-" ? data.ojk_info : "-"
    return warningStock;
}

function Calculator(type, a, b, c){
    let na = Number(a); let nb = Number(b); let nc = Number(c);
    switch (type) {
        /** PORTOFOLIO */
        case "pstockval": return (na * nb).toFixed(0); break;
        case "ppl": 
            let pl = nb === 0 ? '0' : ((na-nc)*nb).toFixed(0);
            let perPl =  nb === 0 ? '0' : ((((na-nc)/nc)*100)).toFixed(2);
            return pl+"|"+perPl; 
        break;
        case "valFormSell": return (nb * nc).toFixed(2); break;
        case "plFormSell": return ((na * nb) - ((nb * nc).toFixed(2))).toFixed(2); break;
        case "perPlFormSell": 
            let valFormSell = (nb * nc).toFixed(2);
            let plFormSell = ((na * nb) - valFormSell).toFixed(2);
            return ((plFormSell / valFormSell)*100).toFixed(2);
        break;
        case "addR": /** menjumlahkan a dengan ketentuan 1 [Regular Acount] */
            if(Number(a) < 200)
                return Number(a)+1          
            else if(Number(a) > 199 && Number(a) < 500)
                return Number(a)+2         
            else if(Number(a) > 499 && Number(a) < 2000)
                return Number(a)+5         
            else if(Number(a) > 1999 && Number(a) < 5000)
                return Number(a)+10         
            else if(Number(a) > 4999 )
                return Number(a)+25;
        break;
        case "subR": /** mengurangi a dengan ketentuan 1 [Regular Acount] */
            if(Number(a) < 201)
                return Number(a)-1          
            else if(Number(a) > 200 && Number(a) < 501)
                return Number(a)-2         
            else if(Number(a) > 500 && Number(a) < 2001)
                return Number(a)-5         
            else if(Number(a) > 2000 && Number(a) < 5001)
                return Number(a)-10         
            else if(Number(a) > 5000 )
                return Number(a)-25;
        break;  
            
    }
}

// === LOGIN
    function GetIndexLogin(data) {
        let ndata = [];
        data.map(val => ndata.push({symbol: val, last: 0, change: 0, percentage: ""}))
        return ndata;
    }

// === HEADER 
    function GetBoxHeaderReady(data) {
        let ndata = [];
        data.map(val => ndata.push({symbol: val, last: "", change: 0, percentage: "0", prev_index: "0"}))
        return ndata;
    }

// === MARKET & STATISTIC PAGE
    
    // CURRENCY & COMMODITY
    function GetCommodity(data) {
        let i = 0, x = Object.keys(data),  y = Object.values(data), l = x.length, z = [];
        for (i; i < l; i++){
            z.push({name: y[i], code: x[i], last: "", change: "", percentage: ""})
        }
        return GetInitialRow(ceksize("rgTrade"), fd.commodity, z);
    }

// === BROKER PAGE
    // BROKER INFO DETAIL
    function GetBrokerInfoDetail(data) {
        return [
            {"label": "Nama Member", "value": data.name},
            {"label": "No Akte", "value": data.deed_no},
            {"label": "Modal Dasar*", "value": data.base_cap},
            {"label": "Modal Disetor*", "value": data.paid_cap},
            {"label": "Status Perusahaan", "value": data.comp_status},
            {"label": "Status Operasional", "value": data.opr_status},
            {"label": "Ijin Usaha", "value": data.b_permit},
            // {"label": "Nilai MKBD Terakhir", "value": data.last_mkbd},
            {"label": "Kantor Pusat", "value": data.headquarter},
            {"label": "Nomor Telepon", "value": data.phone},
            {"label": "Faks", "value": data.fax},
            {"label": "Kode Pos", "value": data.zip},
            {"label": "Situs", "value": data.website}
        ];
    }
    // GET BROKER TRADE SUMMARY
    function GetBrokerTradeSummary1(type, refData, data){
        let x = Object.keys(data);
        let y = Object.values(data);
        let xleng = x.length, z = [], i = 0;
        if(xleng > 0) {
            for(i; i<xleng; i++){
                let ny = y[i].split("|")
                let ndata = {stock: x[i], vol: ny[0], val: ny[1], avgPrice: Number(ny[1])/Number(ny[0])}
                z.push(ndata);
            }
        }
        z.forEach((item) => {
            let j = refData.findIndex(o => o.stock === item.stock);
            if(j <= -1){
                refData.push({
                    stock: item.stock, 
                    buyVol: type === "buy" ? item.vol : 0, 
                    buyVal: type === "buy" ? item.val : 0, 
                    avgBuyPrice: type === "buy" ? item.avgPrice.toFixed(2) : 0, 
                    sellVol: type === "sell" ? item.vol : 0, 
                    sellVal: type === "sell" ? item.val : 0, 
                    avgSellPrice: type === "sell" ? item.avgPrice.toFixed(2) : 0, 
                    netVol: type === "buy" ? item.vol : -(item.vol), 
                    netVal:  type === "buy" ? item.val : -(item.val)});
            }else{
                if(type === "buy"){
                    refData[j].buyVol = item.vol;
                    refData[j].buyVal = item.val;                        
                    refData[j].avgBuyPrice = item.avgPrice.toFixed(2);
                }else{
                    refData[j].sellVal = item.val;
                    refData[j].sellVol = item.vol;
                    refData[j].avgSellPrice = item.avgPrice.toFixed(2); 
                }
                refData[j].netVol = Number(refData[j].buyVol) - Number(refData[j].sellVol);
                refData[j].netVal = Number(refData[j].buyVal) - Number(refData[j].sellVal);
            }
        });
        return GetInitialRow(ceksize('brokerTradeSummary'), fd.brokerTradeSum1, refData)
    }
     // GET BROKER TRADE HISTORY
    function GetBrokerTradeHistory(data){
        let z = [];
        data.buy.forEach((item) => {
            let i = z.findIndex(o=>o.stock === item.stock);
            if(i <= -1){
                z.push({
                    date: "", code: item.stock, buyVol: item.buy_vol, buyVal: item.buy_val, 
                    avgBuy: (Number(item.buy_val)/Number(item.buy_vol)).toFixed(2), 
                    sellVol: 0, sellVal: 0, avgSell: 0, netVol: item.buy_vol, netVal: item.buy_val
                });
            }else{
                z[i].buyVol = item.buy_vol; z[i].buyVal = item.buy_val; 
                z[i].avgBuy = (Number(item.buy_val)/Number(item.buy_vol)).toFixed(2);
                z[i].netVol = item.buy_vol - z[i].sellVol; 
                z[i].netVal = item.buy_val - z[i].sellVal
            }
        })
        data.sell.forEach((item) => {
            let i = z.findIndex(o=>o.code === item.stock);
            if(i <= -1){
                z.push({
                    date: "", code: item.stock, buyVol: 0, buyVal: 0, avgBuy: 0, 
                    sellVol: item.sell_vol, sellVal: item.sell_val, 
                    avgSell: (Number(item.sell_val)/Number(item.sell_vol)).toFixed(2), 
                    netVol: -(item.sell_vol), netVal: -(item.sell_val)
                });
            }else{
                z[i].sellVol = item.sell_vol; z[i].sellVal = item.sell_val; 
                z[i].avgSell = (Number(item.sell_val)/Number(item.sell_vol)).toFixed(2);
                z[i].netVol = z[i].buyVol - item.sell_vol; 
                z[i].netVal = z[i].buyVal - item.sell_val;
            }
        });
        return  GetInitialRow(ceksize('brokerTradeHistory')-1, fd.brokerTradeHistory, z);
    }

/** STOCK PAGE */
    function GetRecommendationTechDay(data, lasPrice){
        if(
            data.recommendation === undefined ||
            (data.recommendation.toUpperCase() === "BUY" && lasPrice < data.support_1) || 
            (data.recommendation.toUpperCase() === "BUY ON WEAKNESS" && lasPrice < data.support_2) || 
            (data.recommendation.toUpperCase() === "SELL ON STRENGTH" && lasPrice > data.resist_2) || 
            (data.recommendation.toUpperCase() === "SELL" && lasPrice > data.resist_1) 
        ){
            return ""
        }else{
            return data.recommendation
        }
    }
    function GetSpecialNotation(data) {
        let i = 0, x = Object.keys(data),  y = Object.values(data), l = x.length, z = [];
        for (i; i < l; i++){
            z.push({company: y[i], code: x[i], notation: "", board: "", notationDes: ""})
        }
        return GetInitialRow(ceksize("rgTrade"), fd.commodity, z);
    }

// === AUTOMATIC ORDER
    function GetStatusAutoOrder(data){
        let z = data.toUpperCase() === "X" ? "Executed" : data.toUpperCase() === "D" ? "Cancelled" : data.toUpperCase() === "W" ? "Working" : "Expired";
        return z;
    }
    // GET AUTOMATIC ORDER
    function GetConditionalAuto(rule, ind, tPrice){
        let nrule = rule === "LP" ? "Last Price" : (rule === "BO" ? "Best Offer Price" : "Best Bid Price");
        let nind =  ind === "L" ? "<=" : ">=";
        return nrule + "#" + nind + "#" + tPrice
    }
    // GET AUTOMATIC ORDER EXPIRE LIST
    function GetAutExpOrderList(data){
        let z = [], formatData;
        data.map((val, i) => {
            formatData = {
                hastag: i+1,
                ruleID: data[i].rule_id,
                date: data[i].create_date.split(" ")[0],
                cmd: data[i].side,
                condition: GetConditionalAuto(data[i].rule, data[i].indicator, data[i].trigger_price),
                vol: data[i].vol,
                code: data[i].stock_code,
                price: data[i].price,
                value: data[i].vol*data[i].price,
                expireDate: data[i].expired_date,
                executeStatus: GetStatusAutoOrder(data[i].status),                
                orderStatus: GetStatusOrder(data[i].order_fill_status, data[i].order_chain_status, data[i].order_request_status).nstatus
            };
            z.push(formatData);
        });
        return GetInitialRow(ceksize("historical"), fd.autExpOrderList, z);
    }

// ==== FUND TRANSFER 

// GET STOCK TRANSFER LIST
    function GetStockTransferList(data) {
        let i = 0, xleng = data.length, z = [];
        for(i; i < xleng; i++){
            let nfd = {
                stock: data[i].stock,
                date: data[i].request_date, 
                no: data[i].ref_no,
                vol: data[i].qty, 
                amount: (Number(data[i].price) *  Number(data[i].qty)).toFixed(2), 
                custodianBankDestination: data[i].custody_dest,
                status: data[i].status,
                msg_id: data[i].message_id
            };
            z.push(nfd)
        }
        return GetInitialRow(ceksize("fundTransferList"), fd.fundStockList, z)
    }

// == TRANSAKSI [BL, ]
    // get status and Action on orderdetail
    function GetStatusOrder(fill_status, chain_status, request_status,remark){
        let type = fill_status+"|"+chain_status+"|"+request_status;
        let cremark = remark == undefined ? "" : remark
        switch (type) {
            //New Order sending to Market & rejected by Market
            case "null|null|null" : return {nstatus:"Sending To Server", nremark:""}; break;
            case "null|O|null" : return {nstatus:"Sending To Server", nremark:""}; break;
            case "null|O|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;
    
            case "null|R|null" : return {nstatus:"Rejected By Server", nremark: cremark}; break;

            case "null|A|null" : return {nstatus:"Sending To Server", nremark:"Amending"}; break;
            case "null|A|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;
            
            case "null|C|null" : return {nstatus:"Sending To Server", nremark:""}; break;
            case "null|C|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;
            
            //New Order Open,  Partially Matched, Full Matched
            case "0|O|1" : return {nstatus:"Open", nremark:""}; break;
            case "1|O|1" : return {nstatus:"Partially Done", nremark:""}; break;
            case "2|O|1" : return {nstatus:"Done", nremark:""}; break;

            case "0|A|2" : return {nstatus:"Open", nremark:"Amended rejected : "+cremark}; break;
            case "0|A|1" : return {nstatus:"Open", nremark:"Amended"}; break;
            case "1|A|1" : return {nstatus:"Partially Done", nremark:"Amended"}; break;
            case "1|A|2" : return {nstatus:"Partially Done", nremark:"Amended rejected : "+cremark}; break;
            case "2|A|1" : return {nstatus:"Done", nremark:"Amended"}; break;   
            
            case "0|C|1" : return {nstatus:"Cancelled", nremark:""}; break;
            case "0|C|2" : return {nstatus:"Open", nremark:"Cancelled rejected : "+cremark}; break;
            case "1|C|1" : return {nstatus:"Cancelled", nremark:"Partially Done"}; break;        
            case "1|C|2" : return {nstatus:"Partially Done", nremark:"Cancel rejected : "+cremark}; break;
        }
    }

    function GetStatusOrderDetail(fill_status, chain_status, des){
        let type = fill_status+"|"+chain_status
        let ndes = des == undefined ? "" : des.includes("Order amend") ? "Amend" : des.includes("Order cancel") ? "Cancel" :  "New Order" 
        let nstat = des == undefined ? "" : des.includes("created") ? "Sending To Server" : "" 
        switch (type) {
            //New Order sending to Market & rejected by Market
            case "null|null" : return {nstatus:nstat, naction:ndes}; break;
            case "null|O" : return {nstatus:"Sending To Server", naction:"New Order"}; break;
            case "null|O" : return {nstatus:"Rejected", naction:"New Order"}; break;
            case "null|R" : return {nstatus:"Rejected", naction:"New Order"}; break;
    
            case "null|A" : return {nstatus:"Sending To Server", naction:"Amend"}; break;
            case "null|A" : return {nstatus:"Open", naction:"Amend"}; break;
            
            case "null|C" : return {nstatus:"Sending To Server", naction:"Cancel"}; break;
            case "null|C" : return {nstatus:"Open", naction:"Cancel"}; break;
            
            //New Order Open,  Partially Matched, Full Matched
            case "0|O" : return {nstatus:"Open", naction:"New Order"}; break;
            case "1|O" : return {nstatus:"Partially Done", naction:"New Order"}; break;
            case "2|O" : return {nstatus:"Done", naction:"New Order"}; break;
    
            case "0|A" : return {nstatus:"Open", naction:"Amend"}; break;
            case "1|A" : return {nstatus:"Partially Done", naction:"Amend"}; break;
            case "2|A" : return {nstatus:"Done", naction:"Amend"}; break;   
            
            case "0|A" : return {nstatus:"Open", naction:"Amend"}; break;
            
            case "0|C" : return {nstatus:"Cancelled", naction:"Cancel"}; break;
            case "1|C" : return {nstatus:"Cancelled", naction:"Cancel"}; break;        
        }
    }
    
    function UpdatePortfolioData(key, data, ref) {
        if(key === "create"){
            let lastprice = data ? (Number(ref.close_price) == 0 ? Number(ref.prev_price) : Number(ref.close_price)) : 0;
            let avgprice = data ? data.averageprice.toFixed(4) : 0;
            let pl =  data ? ((Number(lastprice)-Number(avgprice))*Number(data.totalqty)).toFixed(0) : 0;
            let perPl =  data ? (((Number(lastprice)-Number(avgprice))/Number(avgprice))*100).toFixed(2) : 0;
            let valFormSell = data ? data.sellableqty * Number(avgprice) : 0;
            let plFormSell = data ? ((lastprice-Number(avgprice))*data.sellableqty).toFixed(2) : 0;
            return {code: ref.code, avgprice, pl, perPl,
                lastprice: lastprice,
                lot: data ? ShareToLot(data.totalqty) : 0,  
                shares: data ? data.totalqty : 0, 
                slot: data ? ShareToLot(data.sellableqty) : 0, 
                sshares: data ? data.sellableqty : 0,
                stockval: data ? (Number(data.totalqty)*Number(lastprice)).toFixed(0) : 0, 
                stockvalR: data ? (Number(avgprice)*Number(data.totalqty)).toFixed(0) : 0, 
                valFormSell, plFormSell, 
                perPlFormSell: data ? ((plFormSell/valFormSell)*100).toFixed(2) : 0,  
            }
        } else if(key === "stock") {
            let npl = ((Number(data.lastprice)-Number(ref.averageprice))*Number(ref.totalqty)).toFixed(0)
            let nperPl = (((Number(data.lastprice)-Number(ref.averageprice))/Number(ref.averageprice))*100).toFixed(2)
            let valFormSell = ref.sellableqty * ref.averageprice;
            let plFormSell = ((data.lastprice - ref.averageprice) * ref.sellableqty).toFixed(2);
            return {...data, valFormSell, plFormSell, 
                stockval: data.lastprice*ref.totalqty,
                avgprice: ref.averageprice,
                lot: ShareToLot(ref.totalqty),
                shares: ref.totalqty,
                stockValR: ref.averageprice * ref.totalqty,
                pl: npl,
                perPl: nperPl,
                slot: ShareToLot(ref.sellableqty), 
                sshares: ref.sellableqty,
                perPlFormSell: ((plFormSell/valFormSell)*100).toFixed(2)
            };
        } else { /** "lastPrice" */
            let lastprice = ref.close_price == 0 ? ref.prev_price : ref.close_price;
            let npl = ( (lastprice - Number(data.avgprice)) * Number(data.shares) ).toFixed(0);
            let nperPl = ( ((lastprice - Number(data.avgprice)) / Number(data.avgprice)) * 100 ).toFixed(2);
            let plFormSell = ((lastprice - data.avgprice)* data.sshares).toFixed(2);
            return { ...data, lastprice, plFormSell,
                stockval: Number(data.shares) * lastprice,
                pl: npl, perPl: nperPl,
                perPlFormSell: ((plFormSell / data.valFormSell) * 100).toFixed(2)
            };
        }
    }

export { 
    timeModifier,
    GetStockDataR,
    GetWarningStock, Calculator,
    // LOGIN
        GetIndexLogin,
    // HEADER
        GetBoxHeaderReady,
    // LANDING PAGE
        GetStockTransferList,
    /** STOCK PAGE */
        GetRecommendationTechDay, GetSpecialNotation,
    // BROKER PAGE
        GetBrokerTradeSummary1, GetBrokerInfoDetail, GetBrokerTradeHistory,
    // AUTOMATIC ORDER
        GetStatusAutoOrder, GetAutExpOrderList,
    // MARKET & STATISTIC PAGE
        GetCommodity,
    // TRANSAKSI
        UpdatePortfolioData
}