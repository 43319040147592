import React from "react";
import {Button, Table,} from "react-bootstrap";
import Select from "react-select";
import {cssmode} from "../App";
import {Dropdown, Popup} from "semantic-ui-react";
import {AppFrameAction} from "../appframe";
import { ContextConnector } from '../appcontext.js';
import { BIPSAppContext,  searchCode, ConvertToRupiah, groupSearch, GroupList, showPage, ColorLabel } from '../AppData.js';

// import {ModalReconnect} from "./app_modals/modal_reconnect";
import { ResizeResponsive, cekCrudWatchlist } from '../utils';

import {RegisterAmendModal} from "../app_pages/stockPage";
import {BuyPageOld} from "../app_pages/stockPage";

import $ from "jquery";

const option = [
    { key: 'groupA', value: 'groupA', text: 'Group A' },
    { key: 'groupB', value: 'groupB', text: 'Group B' },
    { key: 'groupC', value: 'groupC', text: 'Group C' },
    { key: 'groupD', value: 'groupD', text: 'Group D' },
    { key: 'groupE', value: 'groupE', text: 'Group E' }
];

class SideBar_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            // showReconnect: false,
        };
        // this.inactivity = this.inactivity.bind(this);        
    }

    // reconnet start
    // closeReconnect = (e) => {
    //     // this.setState({showAlert:true});
    //     this.refs.frameAction.closeModal(100);
    //     this.setState({showReconnect: false});
    //     }
    // clickOpen = (e) => {
    //     if(this.state.showReconnect == false){
    //         this.setState({showReconnect: true});
    //         this.buttonClickReconnect();
    //     }
    // }

    // buttonClickReconnect = (e) => {
    //     this.refs.frameAction.showModal({
    //         headerClass: () => <div className="text-right">
    //             <i className="icofont icofont-close text-icofont-close text-white click-pointer"
    //                onClick={()=>this.closeReconnect()} id={"reconnectxbutton"}></i></div>,
    //         size: 'mini',
    //         contentClass: ModalReconnect,
    //         onClose: (result) => {console.log('Modal 1 result = ', result)}
    //     })
    // }

    // componentDidMount(){
    //     window.addEventListener('load', this.inactivity);
    // }
    // inactivity(){
    //     var time;

    //     document.onload = resetTimer;
    //     document.onmousemove = resetTimer;
    //     document.onmousedown = resetTimer; // touchscreen presses
    //     document.ontouchstart = resetTimer;
    //     document.onclick = resetTimer;     // touchpad clicks
    //     document.onkeypress = resetTimer;
    //     document.addEventListener('scroll', resetTimer, true); // improved; see comments
    //     window.addEventListener('load', resetTimer, true);
    //     var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    //     events.forEach(function(name) {
    //         document.addEventListener(name, resetTimer, true);
    //     });

    //     function logout() {
    //         let a = $("#login-state").css("display");
    //         // alert(a);
    //         if(a === "none"){
    //             $("#idTriggerReconnect").click();
    //         }
    //     }

    //     function resetTimer() {
    //         clearTimeout(time);
    //         time = setTimeout(logout, 3000000)
    //     }
    // };
    // reconnect finish
    // handle onclick code on sidebar
    handleListSideBar=(e)=>{
        if(this.props.onClickSidebar.analytic){
            $("#sideBarValue").val(e);
            $("#sideBarValue").click();
        }else if(this.props.onClickSidebar.page){
            this.props.handleSearchCode('tableInformation', e);
        }else if(this.props.onClickSidebar.stockTradeHis){
            this.props.handleSearchCode('stockTradeHistory', e);
        }else if(this.props.onClickSidebar.stockTradeSum){
            this.props.handleSearchCode('stockTradeSummary', e);
        }else if(this.props.onClickSidebar.tradeBidOffer){
            this.props.handleSearchCode('tradeOrderBook', e);
        }else if(this.props.onClickSidebar.tradePageAdv){
            this.props.handleSearchCode('tradePageAdv', e);
        }
    }

    changePage=(type,e)=>{
        if(type === 'previous'){
            this.props.changePage(e-1)
        }else{
            this.props.changePage(e+1)
        }
    }

    closeClickNoAlert = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickNewOrder = (e) => {
        if(!this.props.onClickSidebar.tradeBidOffer){
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right text-white">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                        onClick={this.closeClickNoAlert}></i></div>,
                size: 'large',
                contentClass: BuyPageOld,                
                onOpen :this.props.handleOnClick('all', 'cartIcon|openModalCart', "none"),
                onClose: ()=>this.props.handleOnClick('all', 'cartIcon|closeModalCart',"none"),
            })
        }
    }

    closeClick = (e) => {
        // amendActiveTab {1: 'amend group', 2: 'add group'}
        let {userName, watchlistCode, amendGroup, amendActiveTab, addGroupCode, statusAlertC, handleStatusAlert3 } = this.props;
        let message = amendActiveTab === '1' ? cekCrudWatchlist(userName, watchlistCode, amendGroup) : [];
        if(message.length > 0 || addGroupCode.length > 0){
            handleStatusAlert3('confirm',statusAlertC,'Save changes before quiting?', 'myWatclist#confirmSave#yes')
        }else{  
            this.refs.frameAction.closeModal(100);
        }
    }    

    buttonClickAmendRegister = (e) => {        
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'tiny',
            contentClass: RegisterAmendModal,
            onOpen :this.props.handleOnClick('all', 'openModalModifyWatchlist','none'),
            onClose: () => this.props.handleOnClick('all', 'closeModalModifyWatchlist','none'),
        })
    }

    isFireFox() {
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            var firefox = true;
        } else {
            var firefox = false;
        }

        return firefox;
    }

    render(){
        var p = this.props;
        var data = groupSearch(p.watchlistCode, p.sidebarStatusGroup).sort();
        var options = GroupList('listSideBar', p.watchlistCode);
        // icon untuk side bar
        const iconChange = (a)=> a < 0 ? "icofont icofont-caret-down f-8" : (a > 0 ? "icofont icofont-caret-up f-8" : "");
        let pointerClass = (a) => (Object.values(p.onClickSidebar).findIndex(d=>d==true) <= -1) || a === '\xa0' ? "" : " click-pointer";
        
        if(this.props.sidebarStatusGroup.length){
            ResizeResponsive();
            return(
                <>
                    <AppFrameAction ref="frameAction" />
                    <div id="mySideBar" className="col-sm-sidebar px-0 mx-0 bg-black-trading d-border-right d-border-left d-border-top card-575 d-border-bottom d-sidebar-potrait">
                        <div className="flex-grow-1">
                            <div className="flex-lg-column mb-1 cssmenu click-pointer">
                                <Popup content='New Order' position='top center' trigger={
                                    <div  onClick={this.buttonClickNewOrder} 
                                        className={`align-self-center text-center d-border-bottom col-sm-12 paddingY-2 px-0 mx-0 
                                            ${p.onClickSidebar.tradeBidOffer ? " click-disabled" : "click-pointer"} `}>
                                        <i className="fa-3x icofont-shopping-cart"></i>
                                    </div>
                                } />
                            </div>
                            <div className="align-self-center text-center px-1 py-0 h-25">
                                <Popup content='Modify Watchlist'
                                    mouseEnterDelay={900}
                                    mouseLeaveDelay={100}
                                    position='top center'
                                    trigger={
                                        <button className="f-9 col-sm-12 px-0 my-0 py-2 btn btn-sm btn-dark h-22" onClick={this.buttonClickAmendRegister}>
                                            <i className="icofont icofont-edit"></i> &nbsp; Modify
                                        </button>
                                } />
                            </div>
                            {/* <span
                                onClick={this.clickOpen}
                                id={"idTriggerReconnect"}>
                            </span> */}
                            <div className="nav flex-lg-column">
                                <Popup content='Watchlist Group'
                                    mouseEnterDelay={900}
                                    mouseLeaveDelay={100}
                                    position='top center'
                                    trigger={
                                        <div className={this.isFireFox() ? "nav-link align-self-center text-center px-0 d-border col-sm-12 mt-0 mb-0 py-0" :
                                            "nav-link align-self-center text-center px-0 d-border col-sm-12 my-0 py-0"}>
                                            <Dropdown placeholder='Group' search selection options={options} 
                                                className={"f-9 text-center align-self-center col-sm-12 grey h-31"} 
                                                onChange={(e,data)=>{this.props.handleOnClick('all', 'dropdownSideBar', data.value)}}
                                                value={this.props.sidebarStatusGroup}/>
                                        </div>
                                }/> 
                                <div className="d-sidebar-potrait px-0">
                                    <Table size="sm" borderless className="table-sidebar card-452 mb-0">
                                        <tbody>
                                        {
                                            this.props.sidebarData.map((list, index) => {                                                                                             
                                                return (<>
                                                    {
                                                        list.own === '\xa0' ?
                                                            <tr key={"potr1"+index} className={`d-border-bottom ${ this.isFireFox() ? 'pl-0 pr-1': 'px-1'}`} 
                                                                onClick={list.name === '\xa0' ? null : () => this.handleListSideBar(list.name)}>
                                                                <td className={this.isFireFox() ? "pl-0 pr-1" : "px-1 pb-0"}>
                                                                    <div className={`align-self-center text-left noselect ${pointerClass(list.name)}`}>
                                                                        <p className={this.isFireFox() ? "pl-2 mb-0 f-sdb-m" : "pl-2 mb-0 f-sdb-m"}>{list.name}</p>
                                                                        <div className={`text-right ${this.isFireFox() ? "f-sdb mb-0 " : "f-sdb mb-0 "} ${ColorLabel(Number(list.change))}`}>
                                                                            {
                                                                                list.name === '\xa0' ? <>&nbsp;</> : <>
                                                                                    <i className={iconChange(list.change)}></i>
                                                                                    {list.last == 0 ? <i className="icofont icofont-minus"></i> : list.change.substr(0,4)+" ("+list.percent.substr(0,5)+"%)"}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <p className={`text-right ${this.isFireFox() ? "f-sdb mb-0 " : "f-sdb mb-0 "} ${ColorLabel(Number(list.change))}`}>
                                                                            {
                                                                                list.name == '\xa0' ? <>&nbsp;</> : <>
                                                                                    {list.last == 0 ? <i className="icofont icofont-minus"></i> : ConvertToRupiah(list.last)}
                                                                                </>
                                                                                }
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        :
                                                        <Popup content={list.popUp}
                                                            mouseEnterDelay={500}
                                                            mouseLeaveDelay={100}
                                                            position='top left'
                                                            size='tiny'
                                                            trigger={
                                                                <tr key={"potr1"+index} className={`d-border-bottom
                                                                    ${ this.isFireFox() ? 'pl-0 pr-1': 'px-1'}`} 
                                                                    onClick={() => this.handleListSideBar(list.name)}>
                                                                    <td className={this.isFireFox() ? "pl-0 pr-1" : "px-1 pb-0"}>
                                                                        <div className={`align-self-center text-left noselect ${pointerClass(list.name)}`}>
                                                                            <p className={this.isFireFox() ? "pl-2 mb-0 f-sdb-m" : "pl-2 mb-0 f-sdb-m"}>{list.name}</p>
                                                                                <div className={`text-right ${this.isFireFox() ? "f-sdb mb-0 ": "f-sdb mb-0 "} ${ColorLabel(Number(list.change))} `}>
                                                                                    <i className={iconChange(list.change)}></i>
                                                                                    {list.last == 0 ? <i className="icofont icofont-minus"></i> : list.change.substr(0,4)+" ("+list.percent.substr(0,5)+"%)"}
                                                                                </div>
                                                                                <p className={`text-right ${this.isFireFox() ? "f-sdb mb-0 " : "f-sdb mb-0 "} ${ColorLabel(Number(list.change))}`}>
                                                                                    {list.last == 0 ? <i className="icofont icofont-minus"></i> : ConvertToRupiah(list.last)}
                                                                                </p>
                                                                                <p className={`text-right ${this.isFireFox() ? "f-sdb mb-0 " : "f-sdb mb-0 "} ${ColorLabel(Number(list.ownPercent))}`}>
                                                                                    {"@" + ConvertToRupiah(list.own) + "(" + list.ownPercent + "%)"}
                                                                                </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        />
                                                    }
                                                </>)
                                            })  
                                        }
                                        </tbody>
                                            <tfoot >
                                                <tr>
                                                    <td className="py-0 px-0">
                                                        <div className="align-self-center text-center noselect btn-area-sidebar">   
                                                            <button 
                                                                className={`btn btn-custom-nav ${(data.length > p.maxPpage && p.thisPage !== 0) ? "" : "click-disabled"}`} 
                                                                    disabled={`${(data.length > p.maxPpage && p.thisPage !== 0) ?  "" : "disabled"}`}
                                                                        onClick={data.length > p.maxPpage && p.thisPage !== 0 ? ()=>this.changePage('previous', p.thisPage) : null}>
                                                                <i className={`icofont icofont-ui-previous  ${(data.length > p.maxPpage && p.thisPage !== 0) ? "click-pointer" : ""}`}></i>
                                                            </button>
                                                            <button 
                                                                className={`btn btn-custom-primary btn-primary ${(data.length > 0) ? "" : "click-disabled"}`}
                                                                    disabled= {`${(data.length > 0) ?  "" : "disabled"}`}
                                                                        onClick={data.length > 0 ? ()=>p.handleOnClick("refreshPage","sidebarXS", p.sidebarCode) : null}>
                                                                <i className={`icofont icofont-refresh ${(data.length > 0) ? "click-pointer" : ""}`}></i>
                                                            </button>
                                                            <button 
                                                                className= {`btn btn-custom-nav f-16 ${(data.length > p.maxPpage && p.thisPage !== p.sumPage-1) ?  "" : "click-disabled"}`} 
                                                                    disabled= {`${(data.length > p.maxPpage && p.thisPage !== p.sumPage-1) ?  "" : "disabled"}`}
                                                                        onClick={data.length > p.maxPpage && p.thisPage !== p.sumPage-1 ? ()=>this.changePage("next", p.thisPage) : null}>
                                                                <i className={`icofont icofont-ui-next ${(data.length > p.maxPpage && p.thisPage !== p.sumPage-1) ? "click-pointer" : ""}`}></i>
                                                            </button> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>                                        
                                    </Table>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-sidebar px-0 mx-0 bg-black-trading d-border-right d-border-left d-border-top d-border-bottom d-sidebar-landscape">
                        <div className="flex-grow-1">
                            <div className="flex-lg-column mb-1 cssmenu">
                                <Popup content='New Order'
                                    mouseEnterDelay={900}
                                    mouseLeaveDelay={100}
                                    position='top center'
                                    trigger={
                                    <div onClick={this.buttonClickNewOrder} 
                                        className={`align-self-center text-center d-border-bottom col-sm-12 paddingY-2 px-0 mx-0 
                                            ${p.onClickSidebar.tradeBidOffer ? " click-disabled" : "click-pointer"} `}>
                                        <i className="fa-3x icofont-shopping-cart"></i>
                                    </div>
                                } />
                            </div>
                            <div className="align-self-center text-center px-1 py-0 h-25">
                                <Popup content='Modify Watchlist' position='top center' trigger={
                                    <button className="f-9 col-sm-12 px-0 my-0 py-2 btn btn-sm btn-dark h-22" onClick={this.buttonClickAmendRegister}>
                                        <i className="icofont icofont-edit"></i> &nbsp; Modify
                                    </button>
                                } />
                            </div>
                            <div className="nav flex-lg-column">
                                {/* <div className="nav-link align-self-center text-center px-0 d-border col-sm-12 mt-0 mb-0 py-0 tooltip"> */}
                                    <Popup content='Watchlist Group'
                                        mouseEnterDelay={900}
                                        mouseLeaveDelay={100}
                                        position='top center'
                                        trigger={
                                            <Dropdown placeholder='Group' search selection options={options} 
                                                className={"f-9 text-center align-self-center col-sm-12 grey h-31"} 
                                                onChange={(e,data)=>{this.props.handleOnClick('all', 'dropdownSideBar', data.value)}}
                                                value={this.props.sidebarStatusGroup}/>
                                        }
                                    />
                                {/* </div> */}
                                <div className="row col-sm-12 mx-0 px-0">
                                    <div className="col-sm-1 align-self-center mx-0">
                                        <div className="align-self-center text-center px-sidebar py-0 px-0 click-pointer">
                                            <div className="align-self-center text-center" onClick= {()=>this.props.handleOnClick("refreshPage","sidebarXS",this.props.sidebarCode)}>
                                                <i className="glyphicon glyphicon-refresh"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-1 align-self-center mx-0">
                                        <div className="align-self-center text-center px-sidebar py-0 px-0">
                                            <div className={`align-self-center text-center ${ (data.length > p.maxPpage && p.thisPage !== 0) ? "click-pointer" : "click-disabled"}`} 
                                                    onClick={data.length > p.maxPpage && p.thisPage !== 0 ? ()=>this.changePage('previous', p.thisPage) : null}>
                                                <i className={`icofont icofont-ui-previous f-16 ${ (data.length > p.maxPpage && p.thisPage !== 0) ? "text-bips-dark" : "text-bips-dark-disabled" }`}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-sm-9 mx-0 px-0" style={{justifyContent : 'center'}}>
                                        {
                                            this.props.sidebarData.map((list, index) => { 
                                                if(list.name !== '\xa0'){
                                                    return (<>
                                                        {
                                                            list.own == '\xa0' ? 
                                                                <div key={"lan"+index} 
                                                                    className={"align-self-center text-left px-sidebar py-sidebar click-pointer d-sidebar-landscape-hover"}
                                                                    onClick={() => this.handleListSideBar(list.name)}>
                                                                    <h5 className="mb-1">{list.name}</h5>
                                                                    <div  className={`f-10 mb-1 text-right ${ColorLabel(Number(list.change))} `}>
                                                                        <i className={list.last == 0 ? "" : iconChange(list.change)}></i>
                                                                        {list.last == 0  ? <i className="icofont icofont-minus"></i> : list.change.substr(0,5)+" ("+list.percent.substr(0,5)+"%)"}
                                                                    </div>
                                                                    <p className={`f-11 mb-1 text-right ${ColorLabel(Number(list.change))} `}>
                                                                        {list.last == 0  ? <i className="icofont icofont-minus"></i> : ConvertToRupiah(list.last)}
                                                                    </p>
                                                                </div>
                                                            : 
                                                            <Popup content={list.popUp}
                                                                mouseEnterDelay={500}
                                                                mouseLeaveDelay={100}
                                                                position='top left'
                                                                size='tiny'
                                                                trigger={
                                                                    <div key={"lan"+index} 
                                                                        className={"align-self-center text-left px-sidebar py-sidebar click-pointer d-sidebar-landscape-hover"}
                                                                        onClick={() => this.handleListSideBar(list.name)}>
                                                                        <h5 className="mb-1">{list.name}</h5>
                                                                        <div  className={`f-10 mb-1 text-right ${ColorLabel(Number(list.change)) }`}>
                                                                            <i className={list.last == 0 ? "" : iconChange(list.change)}></i>
                                                                            {list.last == 0  ? <i className="icofont icofont-minus"></i> : list.change.substr(0,5)+" ("+list.percent.substr(0,5)+"%)"}
                                                                        </div>
                                                                        <p className={`f-11 mb-1 text-right ${ColorLabel(Number(list.change)) }`}>
                                                                            {list.last == 0  ? <i className="icofont icofont-minus"></i> : ConvertToRupiah(list.last)}
                                                                        </p>
                                                                        <p className={`f-11 mb-1 text-right ${ColorLabel(Number(list.ownPercent)) }`}>
                                                                            {"@" + ConvertToRupiah(list.own) + "(" + list.ownPercent + "%)"}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                    </>)
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="col-sm-1 align-self-center mx-0">
                                        <div className="align-self-center text-center px-sidebar py-0 px-0">
                                            <div className={`align-self-center text-center ${ (data.length > p.maxPpage && p.thisPage !== p.sumPage-1) ? "click-pointer" : "click-disabled"}`} 
                                                    onClick={data.length > p.maxPpage && p.thisPage !== p.sumPage-1 ? ()=>this.changePage("next", p.thisPage) : null}>
                                                <i className={`icofont icofont-ui-next f-16 ${ (data.length > p.maxPpage && p.thisPage !== p.sumPage-1) ? "text-bips-dark" : "text-bips-dark-disabled" }`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else{
            return(<>
                <AppFrameAction ref="frameAction" />
                <div id="mySideBar" className="col-sm-sidebar px-0 mx-0 bg-black-trading d-border-right d-border-left d-border-top card-575 d-border-bottom d-sidebar-potrait">
                    <div className="flex-grow-1">
                        <div className="flex-lg-column mb-1 cssmenu">
                            <Popup content='New Order' position='top center' trigger={
                                <div onClick={this.buttonClickNewOrder} 
                                    className={`align-self-center text-center d-border-bottom col-sm-12 paddingY-2 px-0 mx-0 
                                        ${p.onClickSidebar.tradeBidOffer ? " click-disabled" : "click-pointer"} `}>
                                    <i className="fa-3x icofont-shopping-cart"></i>
                                </div>
                            } />        
                        </div>
                        <div className="align-self-center text-center px-1 py-0 h-25">
                            <button className="f-9 col-sm-12 px-0 my-0 py-2 btn btn-sm btn-dark h-22" onClick={this.buttonClickAmendRegister}>
                                <i className="icofont icofont-edit"></i> &nbsp; Modify
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-sidebar px-0 mx-0 bg-black-trading d-border-right d-border-left d-border-top d-border-bottom d-sidebar-landscape">
                    <div className="flex-grow-1">
                        <div className="flex-lg-column mb-1 cssmenu">
                            <Popup content='New Order'
                                mouseEnterDelay={900}
                                mouseLeaveDelay={100}
                                position='top center'
                                trigger={
                                <div onClick={this.buttonClickNewOrder} 
                                    className={`align-self-center text-center d-border-bottom col-sm-12 paddingY-2 px-0 mx-0 
                                        ${p.onClickSidebar.tradeBidOffer ? " click-disabled" : "click-pointer"} `}>
                                    <i className="fa-3x icofont-shopping-cart"></i>
                                </div>
                            } />
                        </div>
                        <div className="align-self-center text-center px-1 py-0 h-25">
                            <Popup content='Modify Watchlist' position='top center' trigger={
                                <button className="f-9 col-sm-12 px-0 my-0 py-2 btn btn-sm btn-dark h-22" onClick={this.buttonClickAmendRegister}>
                                    <i className="icofont icofont-edit"></i> &nbsp; Modify
                                </button>
                            } />
                        </div>
                    </div>
                </div>

            </>)
        }
    }
}


//************************** Context Connector **************************
const SideBar = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        watchlistCode: vars.watchlistCode,
        sidebarStatusGroup: vars.sidebarStatusGroup,
        onClickSidebar: vars.onClickSidebar,
        thisPage:vars.thisPage,
        sumPage:vars.sumPage,   
        maxPpage:vars.maxPpage,
        sidebarCode:vars.sidebarCode,
        sidebarData: vars.sidebarData,
        changePage:(page,type)=>actions.sendAction('changePage', {page,type}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode', {type, code}),
        // alert sweat
        userName:vars.userName,  
        amendGroup:vars.amendGroup,
        amendActiveTab:vars.amendActiveTab,
        addGroupCode:vars.addGroupCode,
        statusAlertC:vars.statusAlertC,
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ['changePage', 'handleOnClick', "handleStatusAlert3", "handleSearchCode"]
)(SideBar_Base);


export default SideBar;