import React from 'react';

import {Dropdown,Popup} from 'semantic-ui-react';
import {Table as TableBS} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import Select from "react-select";
import anychart from 'anychart';
import AnyChart from '../../node_modules/anychart-react/dist/anychart-react.min.js'

import { AppFrameAction } from '../appframe.js';
import {AppFrame} from "../appframe";
import {BIPSAppContext, ConvertToDateTime, ConvertToRupiah, searchCode, IconChange, ColorLabel} from "../AppData";
import {WSConnectionAction} from "./../appnetwork";

import FillHeaderTab from "../tabheaderfill";
import MenuOfContent from "../menuofcontent";
import {ContextConnector} from "../appcontext";
import { ResizeResponsive, widthSize, colorText, updownIcon, fmsg, comparatorType } from "../utils";
import TableInfoTransaction from "../app_transaction/tableInfoTransaction";
import FormBuy from "../app_transaction/form_buy";
import FormSell from "../app_transaction/form_sell";

import $ from 'jquery';
window.$ = window.jQuery = $;

const summaryOptions = [
    //untuk top active
    // { key: 'all', value: 'ALL', text: 'All' },
    { key: 'rg', value: 'RG', text: 'RG' },
    // { key: 'tn', value: 'TN', text: 'TN' },
    // { key: 'ng', value: 'NG', text: 'NG' },
];

//============= Sub header of Market & Statistic Page =============
// 1.Summary, 2.indices, 3,statistic, 4.Top broker, 5.new & research
const CustomFrameHeaderMarketStatistik= (props) =>{
    return (
        <div>
            <div className="col-sm-12 px-0 mx-0 align-self-center">
                <div className="col-sm-12 pb-0 px-0 mx-0 d-border-bottom">
                    <FillHeaderTab treeName="/marketstatistikPage" linkTitles={
                        {
                            marketStatistikPage: 'SUMMARY',
                            statisticMarketStatistikPage: 'MARKET INDEX',
                            indiceMarketStatistikPage: 'SECTORAL INDEX',
                            nonSectoralStatistikPage: 'NON SECTORAL INDEX',
                            // topBrokerMarketStatistikPage: 'TOP BROKER',
                            intIndicesMarketStatistikPage: 'INT.INDICES',
                            currencyCommodityMarketStatistikPage: 'CURRENCY & COMMODITY',
                        }
                    }/>
                </div>
            </div>
            <AppFrame treeName="/marketstatistikPage" headerComponent={MarketStatistikFrameHeader}/>
        </div>
    );
}

const MarketStatistikFrameHeader = (props) => {
    return (
        <>
        </>
    );
}

const ResearchMarketStatistikFrameHeader = (props) => {
    return (
        <>
        </>
    );
}

class MarketStatistik extends React.PureComponent {
    //hanya memanggil headernya saja
    render () {
        return (
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}

//============= 1. Summary Display =============
const stateOptionsTop = (type) => type === "topactive" ?
[//untuk top active
    { key: 'value', value: 'value', text: 'by value' },
    { key: 'volume', value: 'volume', text: 'by volume' },
    { key: 'frequency', value: 'frequency', text: 'by frequency' },
]
:[//untuk top gainer dan top looser --> tambahkan value
    { key: 'value', value: 'value', text: 'by value' },
    { key: 'percentage', value: 'percentage', text: 'by percentage' },
]
class MarketStatistikPage_Base extends React.PureComponent {

    handleTopType = (topType) => {
        let { setDataHandler } = this.props;
        let topChoice = topType === "topactive" ? "frequency" : "value";
        this.refs.netActionAux.send(fmsg[`ix_${topType}_${topChoice}`]);
        setDataHandler({ topType, topChoice, boardChoice: "RG" });
    }

    handleboardChoice = (type) => {
        // if(choice === 'all'){
        //     var newSummaryContainer = vars.summaryDataTop
        // }else{
        //     let temp = vars.summaryDataTop.filter(function(d) {
        //     return d.board === choice;
        //     });
        //     var newSummaryContainer = temp.length > 0 ? temp : init.topGAL
        // } 
        // return {...vars, boardChoice: choice, topGAL: topGAL}
    }

    handleTopChoice = ( choice ) => { 
        let { setDataHandler, topType } = this.props;
        this.refs.netActionAux.send(fmsg[`ix_${topType}_${choice}`]);
        setDataHandler({ topChoice: choice, boardChoice: "RG"});
    }

    render () {
        let p = this.props;
        return (
            <>
                <AppFrameAction ref="frameAction"  />
                <WSConnectionAction ref="netActionAux" socketID="aux" />
                <div className="card card-527">
                    <div className="card-header pt-1 h-49 bg-grey pb-0">
                        <div className="f-14 px-0 mx-0 py-0 col-sm-12 h-49">
                            <div className="row col-sm-12 px-0 mx-0">
                                <div className={"col-sm-6"}>
                                    <div
                                        className={`px-0 pt-3 pl-5 ${p.topType == "topactive" ? "livetradeMenuActive" : "livetradeMenu"}`}
                                        onClick={() => this.handleTopType("topactive")}
                                        style={{display: "inline-block"}}
                                    >
                                        <i className={p.topType == "topactive" ? "far fa-dot-circle" : "far fa-circle"}></i>
                                        &nbsp;
                                        Top Active
                                    </div>
                                    <div
                                        className={`px-0 pt-3 pl-5 ${p.topType == "topgainers" ? "livetradeMenuActive" : "livetradeMenu"}`}
                                        onClick={() => this.handleTopType("topgainers")}
                                        style={{display: "inline-block"}}
                                    >
                                        <i className={p.topType == "topgainers" ? "far fa-dot-circle" : "far fa-circle"}></i>
                                        &nbsp;
                                        Top Gainer
                                    </div>
                                    <div
                                        className={`px-0 pt-3 pl-5 ${p.topType == "toplosers" ? "livetradeMenuActive" : "livetradeMenu"}`}
                                        onClick={() => this.handleTopType("toplosers")}
                                        style={{display: "inline-block"}}
                                    >
                                        <i className={p.topType == "toplosers" ? "far fa-dot-circle" : "far fa-circle"}></i>
                                        &nbsp;
                                        Top Losers
                                    </div>
                                </div>
                                <div className="col-sm-6 px-0 mx-0 row text-right h-49 py-2">
                                    <div className="col-sm-3">
                                    </div>
                                    <div className="col-sm-4">
                                        <Dropdown placeholder='Choose' search selection options={summaryOptions} 
                                            className="col-sm-12 f-12"
                                            onChange={(e, data) => this.handleboardChoice(data.value)}
                                            value={p.boardChoice}/>
                                    </div>
                                    <div className="col-sm-4">
                                        <Dropdown selection  className="col-sm-12 f-12"
                                            onChange={(e, data) => this.handleTopChoice(data.value)}
                                            options={stateOptionsTop(p.topType)}
                                            value={p.topChoice}
                                            />
                                    </div>
                                    <div className={"col-sm-1 text-center"}>
                                        <Popup content='Refresh' position='top center' trigger={
                                            <button
                                                onClick={() => this.handleTopChoice(p.topChoice)}
                                                className="pull-left btn btn-primary"
                                                style={{"fontSize": "12px", "marginLeft": "-10px"}}>
                                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                            </button>
                                        }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <MarketStatistikAgGrid typegrid="summary" />
                    </div>
                </div>
            </>
        );
    }
}

class IndiceMarketSecondStatistikPage extends React.PureComponent{

    render(){
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction />

                <div className="card grid-294 bg-black-trading f-12">
                    <MarketIndicesAgGrid size={widthSize()}/>
                    {/*<MarketIndicesGrid clickbuy={this.buttonClickBuy} clicksell={this.buttonClickSell} />*/}
                </div>

                <div className="card card-233 bg-black-trading f-12">
                    <div className="card-header px-0 py-0">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                            <div className="bg-tableheader col-sm-12 px-0 mx-0 text-center py-3 h-30">FINANCE</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <MarketStatistikAgGrid typegrid="indices" />
                    </div>
                </div>
            </>
        );
    }
}

// >>>>>>> Top GALTable <<<<<<<
class MarketStatistikAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "no", headerName: "#",filter: "agTextColumnFilter",                     
                    width: s=="s49"?110:s=="s50"?100:s=="s67"?80:60, minWidth: 60,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                    cellRenderer: 'noRenderer'
                }, { 
                    field: "stock_code", headerName: "Code", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:s=="s67"?100:90, minWidth: 90,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                }, { 
                    field: "prev_price", headerName: "Prev.", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?135:s=="s50"?130:s=="s67"?100:85, minWidth: 85,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table text-right d-border-aggrid-right f-12 text-warning"
                }, { 
                    field: "close_price", headerName: "Last", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?135:s=="s50"?130:s=="s67"?100:81, minWidth: 81,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "updown", headerName: "", resizable: true,
                    width: 40, minWidth: 40,
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`,
                    cellRenderer: 'updownRenderer',
                }, { 
                    field: "change_price", headerName: "Change", resizable: true,filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?140:105, minWidth: 105,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "persen", headerName: "%", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?150:s=="s67"?140:73, minWidth: 73,
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`,
                }, { 
                    field: "open_price", headerName: "Open", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?125:s=="s50"?110:s=="s67"?100:91, minWidth: 91,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "low_price", headerName: "Low", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?110:s=="s50"?100:s=="s67"?90:82, minWidth: 82,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "high_price", headerName: "High", resizable: true,filter: "agNumberColumnFilter",
                    width: s=="s49"?120:s=="s50"?110:s=="s67"?100:86, minWidth: 86,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "avg", headerName: "Avg.", resizable: true,filter: "agNumberColumnFilter",
                    width: s=="s49"?135:s=="s50"?110:s=="s67"?110:s=="s75"?95:82, minWidth: 82,
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`,
                }, { 
                    field: "traded_val", headerName: "Value", resizable: true,filter: "agNumberColumnFilter",
                    width: s=="s49"?135:s=="s67"?125:s=="s75"?120:102, minWidth: 102,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                },{ 
                    field: "traded_vol_lot", headerName: "Vol (Lot)", resizable: true,filter: "agNumberColumnFilter",
                    width: s=="s49"?155:s=="s50"?130:s=="s67"?127:s=="s75"?120:110, minWidth: 110,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "traded_freq", headerName: "Freq", resizable: true,
                    width: s=="s49"?120:s=="s50"?110:s=="s75"?100:82, minWidth: 82,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                }, { 
                    field: "foreign_buy_val", headerName: "F.Buy (Val)", resizable: true,
                    width: s=="s49"?180:s=="s50"?165:s=="s75"?170:140, minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                },{ 
                    field: "foreign_sell_val", headerName: "F.Sell (Val)",filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?180:s=="s50"?165:s=="s75"?170:140, minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`
                },{ 
                    field: "fnet", headerName: "F.Net (Val)",filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?183:s=="s50"?163:s=="s75"?165:140, minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change_price && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`,
                },{ 
                    field: "action", headerName: "Action", width: 100, minWidth: 100, pinned: "right", lockPosition: true,
                    lockVisible: true,
                    cellClass: () => " grid-table text-center f-12 locked-col locked-visible d-border-aggrid-right ",
                    cellRenderer: 'actionRenderer',
                    suppressSizeToFit: true 
                },
            ],            
            defaultColDef: {
                sortable: false,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({ rowIndex, data }) => data.stock_code ? rowIndex + 1 : "",
                updownRenderer: ({ data }) => data.stock_code ? <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className={updownIcon(data.change_price)}></i>
                    </> : "",
                actionRenderer: ({ data }) => data.stock_code ? <span className="px-1">
                        <button className="btn-cellbuy btn btn-sm btn-danger mx-1 f-9 w-50" onClick={() => this.buttonClickBuy(data)}>Buy</button>
                        <button className="btn-cellsell btn btn-sm btn-success mx-1 f-9 w-50" onClick={() => this.buttonClickSell(data)}>Sell</button>
                    </span> : ""
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },

        }
    }
    //lagi
    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    closeClick = () => this.refs.frameAction.closeModal(1000);

    buttonClickBuy = (data) => this.refs.frameAction.showModal({
        headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={() => this.closeClick()}></i>
            </div>,
        size: 'large',
        contentClass: BuyModal,
        onOpen: this.props.handleOnClick('transaction', 'openBuySellModal', data.stock_code),
        onClose: () => this.props.handleOnClick('transaction','closeBuySellModal', 'none')
    });

    buttonClickSell = (data) => this.refs.frameAction.showModal({
        headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={() => this.closeClick()}></i>
            </div>,
        size: 'large',
        contentClass: SellModal,
        onOpen: this.props.handleOnClick('transaction', 'openBuySellModal', data.stock_code),
        onClose: () => this.props.handleOnClick('transaction','closeBuySellModal', 'none')
    });

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={this.props.typegrid =="summary" ? "card-478 ag-theme-balham-dark ag-striped-odd" : "card-202 ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.topGAL}
                        rowHeight= "32"
                        defaultColDef={this.state.defaultColDef}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onGridReady={this.onGridReady}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 2. Market Index =============
// >>>>>>> Marketstatistic Table <<<<<<<
class StatisticMarketStatistikPage_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            newRow: 100,
            seconds: 0,
            newStream: null,
        };
    }

    // stream chart
    componentDidMount() {
        $('#container').css('height', '100%');
    //     var tommorow = new Date();
    //     tommorow.setDate(tommorow.getDate()+1);
    //     const ntommorow = tommorow.getTime();
    //     //create new point every 1 minute
    //     var period = 4;
    //     //new price ticks come every 15 seconds
    //     var tickPeriod = 15000;

    //     var newTimestamp;

    //     var newDataRow = [];

    //     //current price variable
    //     var point = null;
    //     var time = null;

    //     var tempTitle = null;

    //     anychart.onDocumentReady(function () {

    //         var dataset = anychart.data.table();
    //         dataset.addData([
    //             // [1582703100063, 300],
    //             // [1582705100063, 500],
    //             // [1582709420063, 900],
    //         ]);

    //         // map the data
    //         var mapping = dataset.mapAs({x: 0, value: 1});

    //         // set chart type
    //         var chart = anychart.stock();
    //         var credits = chart.credits();
    //         credits.enabled(false);

    //         // set the series
    //         var series = chart.plot(0).line(mapping);

    //         series.name("");
    //         chart.title('Stock Streaming');

    //         // set container and draw chart
    //         chart.container("container").draw();

    //         //create empty array for point data update
    //         newDataRow[0] = new Array(2);

    //         //select the last point from existing datatable
    //         var selectable = mapping.createSelectable();
    //         selectable.selectAll();
    //         var iterator = selectable.getIterator();

    //         while (iterator.advance()) {
    //             //put data from the last exsiting point
    //             newDataRow[0][0] = iterator.get('x');
    //             newDataRow[0][1] = iterator.get('value');
    //         }
    //         //timestamp variable for incoming ticks
    //         newTimestamp = newDataRow[0][0];
    //         function reset(){
    //             dataset.remove(1509986691452,ntommorow);
    //         }
    //         function streamStart() {
    //             let code = document.getElementById('code').value;
    //             if(code.length < 1){
    //                 alert('data kosong');
    //             }else{
    //                 document.getElementById("handleStream").click();
    //                 dataInteval = setInterval(
    //                     // data streaming itself

    //                     function () {

    //                         newTimestamp += tickPeriod;
    //                         point = document.getElementById("tempVal").value;
    //                         time = document.getElementById("tempTime").value;
    //                         //current point update or create new point
    //                             // if (newTimestamp - newDataRow[0][0] <= period) {
    //                                 //set price as close for existing point
    //                                 newDataRow[0][2] = point;
    //                             // } else {
    //                                 //erase update data array
    //                                 newDataRow[0] = new Array(2);
    //                                 //set data for the new point
    //                                 newDataRow[0][0] = time;
    //                                 newDataRow[0][1] = point;
    //                             // }
    //                             dataset.addData(newDataRow);
    //                     }, 500            // interval
    //                 );
    //             }
    //         }

    //         let streamButton = document.getElementById("streamButton");
    //         let resetButton = document.getElementById("resetButton");
    //         let resetTitle = document.getElementById("resetTitle");

    //         let streamState = 0;
    //         let dataInteval;

    //         //untuk mengganti title chart
    //         resetTitle.onclick = function(){
    //             reset();

    //             tempTitle = document.getElementById("resetTitle").value;
    //             var dataset = anychart.data.table();
    //             dataset.addData([
    //                 [1582703100063, 300],
    //             ]);
    //             series.name(tempTitle);
    //             // set container and draw chart
    //             chart.container("container").draw();
    //         }

    //         resetButton.onclick = function(){
    //             // streamButton.innerHTML = "Start" + "\nstream";
    //             // streamState = 0;
    //             // clearInterval(dataInteval);
    //             reset();
    //         }
    //         streamButton.onclick = function () {
    //             streamButton.innerHTML = "Stop" + "\nstream";
    //             streamState++;

    //             if (streamState > 1) {
    //                 streamButton.innerHTML = "Start" + "\nstream";
    //                 streamState = 0;
    //                 clearInterval(dataInteval);
    //             } else {                    
    //                 streamStart();
    //             }

    //         };

    //     });
    }

    newStream = event => {
        if(this.state.newStream){
            document.getElementById("resetTitle").click();
            document.getElementById("resetButton").click();
            this.setState({newStream: false});
            this.props.handleStreamChart(this.props.streamStatus)
        }
    }

    handleStartStopStream = () => {        
        this.props.handleStreamChart(this.props.streamStatus)
    }
    // change list
    handleSelect=(data)=>{
        // document.getElementById("resetTitle").value = data.toUpperCase();
        // document.getElementById("resetTitle").click();
        // document.getElementById("resetButton").click();
        this.props.handleSearchCode('indexMarketIndex', data)
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    checkColor(param){
        if(param < 0){
            return "text-right text-danger py-1";
        }else if(param == 0){
            return "text-right text-warning py-1";
        }else{
            return "text-right text-success py-1";
        }
    }

    convertTime(param){
        if(param){
            var d = new Date();
            var h = parseInt(param.substring(0,2));
            d.setHours(h);
            d.setMinutes(param.substring(3,5));
            d.setSeconds(param.substring(6,8));
            console.log("jadi ini total waktu yang telah dikurang "+d);
            return parseInt(d.getTime()) + 25200000;
        }
    }
    render(){
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };
        // Chart
        anychart.theme('darkEarth');
        let chart = anychart.stock();
        let credits = chart.credits();
            credits.enabled(false);
        var dataset = anychart.data.table();
        dataset.addData(this.props.indexStreamChart);
        var data1 = dataset.mapAs({x: 0, value: 1,});
        var seriesRaw = chart.plot(0);
        var series = seriesRaw.line(data1);
        seriesRaw.yGrid().enabled(true);
        seriesRaw.yGrid().stroke({color: "#555555", dash: "3 4"});
        seriesRaw.xMinorGrid().enabled(true);
        seriesRaw.xMinorGrid().stroke({color: "#555555", dash: "2 4"});

        series.stroke("#64B5F6");
        series.name("Price");
        var columnTooltip = series.tooltip();
        chart.tooltip().titleFormat('{%x}{type:time}');
        columnTooltip.format("{%seriesName}: {%value}");

        columnTooltip.displayMode("single");

        chart.crosshair().xLabel().format(' ');

        chart.plot(0).legend(true);

        var title = chart.plot(0).legend().title();
        title.useHtml(true);

        // enable legend title
        title.enabled(true);
        title.text("&nbsp;");

        // set font size and align
        title.fontSize(14);
        title.hAlign("center");

        chart.scroller(true);
        chart.scroller().enabled(false);
        chart.container('container').draw();
        
        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        // index Data
        let p = this.props
        const iconIndex=(change)=>{
            if(change > 0){
                return "icofont icofont-caret-up"
            }
            else if(change < 0){
                return "icofont icofont-caret-down"
            }else{
                return ""
            }
        }
        // Statistic Data 
        var data =this.props.marketStatisticData;   
        var selectedData = searchCode('selected', this.props.codeSearchMarketIndex, this.props.indexlistOptions)
        return(
            <>
                <style>{'' +
                'thead.t-statistic th {' +
                '    border-bottom: 0.7px solid var(--warna-d-border)!important' +
                '}' +
                'tbody.tb-statistic tr td, ' +
                'tfoot.tb-statistic tr th {' +
                '    padding-top: 10px;' +
                '    padding-bottom: 10px;' +
                '}' +
                ''}
                </style>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction />
                <div className="px-1 mx-0 col-sm-12 row f-12 card-520">
                    <div className="col-sm-7 px-1 py-0 d-border-table-right">
                        <div className="card card-520 bg-black-trading">
                            <div className="card-header py-3 pr-0 h-121 n-border-bottom">
                                <div className="col-sm-12 mb-4 row text-left ml-1">
                                    <label className="align-self-center col-sm-2 px-0 mx-0 f-16">Index</label>
                                    {/*<Input defaultValue='AGRI' placeholder='Code' size='small' className="col-sm-7 text-center align-self-center"/>*/}
                                    <div className="col-sm-10 mr-0 pl-0 pr-0 text-left align-self-center">
                                        {/* <Select maxMenuHeight={150} styles={customStyles} size="small" placeholder={<div>Search..</div>} options={stockOptions} className="stockPageSelect" theme={this.selectSelectionTab}/> */}
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={150} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={this.props.indexlistOptions} 
                                            onChange={(e)=>this.handleSelect(e.value)}
                                            className="stockPageSelect text-left" theme={this.selectSelectionTab}
                                            value={selectedData}
                                            />
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                </div>
                                <div className="col-sm-12 mb-4 pt-3 row f-25">
                                    <div className={`col-sm-4 ${(p.indexData.change > 0 ) ? 'text-success' :(p.indexData.change < 0 ) ? 'text-danger' : 'text-warning'}`}>{ConvertToRupiah(p.indexData.last)}</div>
                                    <div className={`col-sm-3 text-center ${(p.indexData.change > 0 ) ? 'text-success' :(p.indexData.change < 0 ) ? 'text-danger' : 'text-warning'}`}>
                                        {p.indexData.change}
                                    </div>
                                    <div className={`col-sm-4 text-right ${(p.indexData.change > 0 ) ? 'text-success' :(p.indexData.change < 0 ) ? 'text-danger' : 'text-warning'}`}>
                                        {' ('+p.indexData.percentage+'%) '}
                                    </div>
                                    <div className={`col-sm-1 text-right ${(p.indexData.change > 0 ) ? 'text-success' :(p.indexData.change < 0 ) ? 'text-danger' : 'text-warning'}`}>
                                        <i className={iconIndex(p.indexData.change)}></i>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12">
                                    <div className="card card-399 text-white delayTransition">
                                        <div>
                                            {/* <button id="streamButton" onClick={this.newStream}  */}
                                            <button id="streamButton" onClick={()=>this.props.handleStreamChart(this.props.streamStatus)} 
                                                className="btn btn-sm btn-grey py-3 px-3 d-border h-40 ml-3 mt-3">{this.props.streamStatus ? "Stop Stream" : "Start Stream"}</button>                                             
                                            {/* <i id="resetButton"></i><input type="hidden" id={"tempVal"} value={this.props.streamChart}/>
                                            <input type="hidden" id={"tempTime"} value={timeModifier(this.props.timeChart, "chart")}/>
                                            <input type="hidden" id={"code"} value={this.props.codeSearchMarketIndex}/>                
                                            <input type="hidden" id={"newStream"} value={this.props.streamStatus}/> */}
                                            {/* <input type="hidden" id={"resetTitle"} value={this.props.codeSearchMarketIndex}/> */}
                                            {/* <input type="hidden" id={"resetTitle"}/> */}
                                            {/* <span onClick={this.handleStartStopStream} id={"handleStream"}></span> */}
                                            {/* <div id="container" className="mt-2 py-3 px-3 card-344"></div> */}
                                            <div  className="mt-2 py-3 px-3 card-344 addmorewidth" >
                                                <AnyChart instance={chart} id="container" title={this.props.codeSearchMarketIndex} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-5 px-1 py-0 d-border-table-left">
                        <div className="card card-520 bg-black-trading text-white">
                            <div className="card-body px-3 pt-3">
                                <div className="bg-tableheader text-center py-3 h-30">
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button className="pull-right btn btn-primary btn-10" 
                                            onClick={()=>this.props.handleOnClick("refreshPage","marketstatistic","none")}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                     }/>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {/*Biar di tengah*/}
                                        BOARD SUMMARY</span>
                                </div>
                                <TableBS responsive bordered size="sm"
                                    className="table-hover table-striped text-center align-self-center align-middle mb-3 card-152">
                                    <thead className="text-white t-statistic">
                                    <tr>
                                        <th className="py-1 bg-gray-tradding">BOARD</th>
                                        <th className="py-1 bg-gray-tradding">VALUE</th>
                                        <th className="py-1 bg-gray-tradding">VOL(SHR)</th>
                                        <th className="py-1 bg-gray-tradding">FREQ</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-white no-wrap">
                                        <tr>
                                            <td className="text-left py-1">Reguler</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.RG_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.RG_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.RG_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left py-1">Negotiated</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.NG_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.NG_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.NG_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left py-1">Cash</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.TN_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.TN_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.TN_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-primary text-left py-1">TOTAL</td>
                                            <td className="text-primary text-right py-1">{ConvertToRupiah(data.BValSum)}</td>
                                            <td className="text-primary text-right py-1">{ConvertToRupiah(data.BVolSum)}</td>
                                            <td className="text-primary text-right py-1">{ConvertToRupiah(data.BFreqSum)}</td>
                                        </tr>
                                    </tbody>
                                </TableBS>
                                <div className="bg-tableheader text-center py-3 h-30 mt-1">
                                    <span>&nbsp;</span></div>
                                    <TableBS responsive bordered size="sm"
                                        className="table-hover table-striped text-center bold align-self-center align-middle mb-3 card-92">
                                        <thead className="text-white t-statistic">
                                        <tr>
                                            <th className="py-1 bg-gray-tradding"></th>
                                            <th className="py-1 bg-gray-tradding">VALUE</th>
                                            <th className="py-1 bg-gray-tradding">VOL(SHR)</th>
                                            <th className="py-1 bg-gray-tradding">FREQ</th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-white no-wrap">
                                        <tr>
                                            <td className="text-left py-1">Rights</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.right_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.right_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.right_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left py-1">Warrants</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.warrant_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.warrant_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.warrant_freq)}</td>
                                        </tr>
                                        </tbody>
                                    </TableBS>
                                <div className="bg-tableheader text-center py-3 h-30 mt-1">
                                    <span>FOREIGN ACTIVITY</span>
                                </div>
                                <TableBS
                                    responsive
                                    bordered
                                    size="sm"
                                    className="table-hover table-striped text-center align-self-center align-middle card-152 mb-0">
                                    <thead className="text-white t-statistic">
                                        <tr>
                                            <th className="py-1 bg-gray-tradding">FOREIGN</th>
                                            <th className="py-1 bg-gray-tradding">VALUE</th>
                                            <th className="py-1 bg-gray-tradding">VOL(SHR)</th>
                                            <th className="py-1 bg-gray-tradding">FREQ</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-white no-wrap">
                                        <tr>
                                            <td className="text-left py-1">F.Buy</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fbuy_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fbuy_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fbuy_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left text-white py-1">F.Sell</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fsell_val)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fsell_vol)}</td>
                                            <td className="text-right py-1">{ConvertToRupiah(data.fsell_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left text-white py-1">F.Total</td>
                                            <td className="text-right text-primary py-1">{ConvertToRupiah(data.ftotal_val)}</td>
                                            <td className="text-right text-primary py-1">{ConvertToRupiah(data.ftotal_vol)}</td>
                                            <td className="text-right text-primary py-1">{ConvertToRupiah(data.ftotal_freq)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left text-white py-1">F.Net</td>
                                            <td className={this.checkColor(data.fnet_val)}>{ConvertToRupiah(data.fnet_val)}</td>
                                            <td className={this.checkColor(data.fnet_val)}>{ConvertToRupiah(data.fnet_vol)}</td>
                                            <td className={this.checkColor(data.fnet_val)}>{ConvertToRupiah(data.fnet_freq)}</td>
                                        </tr>
                                    </tbody>
                                </TableBS>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//============= 3. Sectoral Index Display =============
class IndiceMarketStatistikPage_Base extends React.PureComponent{

    refreshIndexMember = () => this.refs.netActionAux.send(fmsg.ix_indexStockVal(this.props.sectorName));

    render(){
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="netActionAux" socketID="aux" />

                <div className="card grid-294 bg-black-trading f-12">
                    <MarketIndicesAgGrid />
                </div>

                <div className="card card-233 bg-black-trading f-12">
                    <div className="card-header px-0 py-0">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                            <div className="bg-tableheader col-sm-12 px-0 mx-0 text-center py-3 h-30">
                                {this.props.sectorName || ""}
                                <Popup content='Refresh' position='top center' trigger={
                                    <button
                                        onClick={() => this.refreshIndexMember() }                                
                                        className="col-sm-1 pull-right btn btn-primary mr-2"
                                        style={{"marginTop":"-8px","width":"39px","height":"28px"}}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                    </button>
                                }/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <MarketStatistikAgGrid typegrid="indices" />
                    </div>
                </div>
            </>
        );
    }
}

// >>>>>>> sectoral index Table <<<<<<<
class MarketIndicesAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "index_code", headerName: "Sector", resizable: true,
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?150:125,
                    minWidth: 125,
                    lockVisible:true, lockPosition:true,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12 text-primary locked-col locked-visible",
                    cellRenderer: 'sectorRenderer',
                    suppressSizeToFit: true, pinned: 'left'
                }, { 
                    field: "index", headerName: "Last", filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?140:128,
                    minWidth: 128,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "change", headerName: "Change", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?140:122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    minWidth: 122,
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "persen", headerName: "%" , resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?155:s=="s75"?140:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "prev_index", headerName: "Prev. Closed", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?190:s=="s50"?180:s=="s67"?160:s=="s75"?135:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "open", headerName: "Open", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?145:s=="s75"?133:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, {
                    field: "high", headerName: "High", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?143:s=="s75"?133:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "low", headerName: "Low", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s60"?160:s=="s67"?145:s=="s75"?135:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, {
                    field: "vol", headerName: "Volume (Lot)", resizable: true, width: 122,  filter: "agNumberColumnFilter",
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "val", headerName: "Value (T)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?162:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "f_buy_val", headerName: "F.Buy (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?140:s=="s75"?132:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "f_sell_val", headerName: "F.Sell (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?180:s=="s60"?170:s=="s75"?150:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "fnet", headerName: "F.Net (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?230:s=="s50"?220:s=="s75"?150:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.fnet)}`
                },
            ],
            defaultColDef: {
                sortable: false,
                filter: true,
            },
            frameworkComponents: {
                sectorRenderer: ({ data }) => data.index_code ? <span className="searchSector click-pointer" 
                        onClick={() => self.props.handleSectorName(data.index_code, "sectoralIndex")}
                    >{data.index_code}</span> : ""
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="grid-294 ag-theme-balham-dark ag-striped-even"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={Object.values(this.props.indices)}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
//============= 4. Top Broker Display =============
// Top Broker, Top Broker Buyer, Top Broker Seller
class TopBrokerMarketStatistikPage_Base extends React.PureComponent {

    render(){
        return(
            <div className="f-12 px-2">
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction />
                <div className="card bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37 pt-3">
                        TOP BROKER
                        <Popup content='Refresh' position='top center' trigger={
                            <button
                                onClick={()=>this.props.handleOnClick("refreshPage","topbrokerMarketStatistic","none")}
                            className="col-sm-1 pull-right btn btn-primary"
                                style={{"fontSize":"12px","marginTop":"-7px","width":"38px"}}>
                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                            </button>
                        }/>
                    </div>
                    <div className="card-body">
                        <TopBrokerAgGrid size={widthSize()}/>
                    </div>
                </div>
                <div className="card card-175 bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37  pt-3">
                        TOP BUYER
                    </div>
                    <div className="card-body">
                        <TopBrokerBAgGrid size={widthSize()}/>
                    </div>
                </div>
                <div className="card card-175 bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37  pt-3">
                        TOP SELLER
                    </div>
                    <div className="card-body">
                        <TopBrokerSAgGrid size={widthSize()}/>
                    </div>
                </div>
            </div>
        );
    }
}

// >>>>>>> Table of Top Broker <<<<<<<
class TopBrokerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;        
        const self = this;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: true,
                    width: s=="s49"?140:s=="s50"?90:s=="s67"?70:50, minWidth:50,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    }},
                { field: "code", headerName: "Code", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?170:s=="s67"?140:120, minWidth:120,
                    suppressSizeToFit:true, lockVisible:true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12 locked-visible";
                    }},
                { field: "company", headerName: "Company", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?500:s=="s50"?460:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s90"?300:240,minWidth:240,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }},
                { field: "tval", headerName: "T. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s90"?180:150,minWidth:150,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "bval", headerName: "B. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?240:s=="s75"?230:s=="s80"?210:s=="s90"?190:170,minWidth: 170,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "sval", headerName: "S. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?290:s=="s50"?280:s=="s67"?240:s=="s75"?230:s=="s80"?210:s=="s90"?190:175,minWidth: 175,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "tvol", headerName: "T. Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?270:s=="s50"?220:s=="s67"?200:190,minWidth:190,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "tfreq", headerName: "T. Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?390:s=="s50"?380:s=="s67"?350:s=="s75"?340:s=="s80"?210:s=="s90"?190:170,minWidth:170,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
            getRowHeight: function (params) {
                return 23;
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138  ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.topBrokerVal}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// >>>>>>> Table of Top Broker Buyer <<<<<<<
class TopBrokerBAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: true,
                    width: s=="s49"?140:50, minWidth:50,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    }},
                { field: "code", headerName: "Code", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?195:s=="s67"?180:120,minWidth:120, uppressSizeToFit:true, lockVisible:true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12 locked-visible";
                    }},
                { field: "company", headerName: "Company", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?520:s=="s50"?500:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s90"?300:240,
                    minWidth: 240,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }},
                { field: "tval", headerName: "T. Val(T)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?330:s=="s50"?300:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s90"?180:150,
                    minWidth: 150,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "bval", headerName: "B. Val(T)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?325:s=="s50"?300:s=="s67"?250:s=="s75"?230:s=="s80"?210:s=="s90"?190:170,
                    minWidth: 170,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "tvol", headerName: "T. Vol(Lot)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?300:260,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "tfreq", headerName: "T. Freq", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?570:s=="s50"?550:s=="s67"?500:s=="s75"?480:s=="s80"?350:s=="s90"?310:275,
                    minWidth: 275,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 23;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138 ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.topBrokerBuy}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// >>>>>>> Table of Top Broker Seller <<<<<<<
class TopBrokerSAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: true,
                    width: s=="s49"?140:50,  minWidth: 50,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    }},
                { field: "code", headerName: "Code", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?200:s=="s50"?195:s=="s67"?180:120, minWidth: 120,
                    suppressSizeToFit:true, lockVisible:true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12 locked-visible";
                    }},
                { field: "company", headerName: "Company", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?520:s=="s50"?500:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s90"?300:240,
                    minWidth: 240,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }},
                { field: "tval", headerName: "T. Val(T)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?330:s=="s50"?300:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s90"?180:150,
                    minWidth: 150,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "sval", headerName: "S. Val(T)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?325:s=="s50"?300:s=="s67"?250:s=="s75"?230:s=="s80"?210:s=="s90"?190:170,
                    minWidth: 170,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }},
                { field: "tvol", headerName: "T. Vol(Lot)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?300:260, minWidth: 260,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "tfreq", headerName: "T. Freq", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?570:s=="s50"?550:s=="s67"?500:s=="s75"?480:s=="s80"?350:s=="s90"?310:275,
                    minWidth: 275,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 23;
            },
            rowData: [
                { no: 1,
                    code: "DX",
                    company: "Bahana Sekuritas",
                    tval: "99.64",
                    sval: "38.62",
                    tvol: "104.73",
                    tfreq: "5,040"},
                { no: 2,
                    code: "MS",
                    company: "Morgan Stanley Sekuritas Indonesia",
                    tval: "99.64",
                    sval: "38.62",
                    tvol: "104.73",
                    tfreq: "5,040"},
                { no: 3,
                    code: "KS",
                    company: "Kresna Sekuritas",
                    tval: "99.64",
                    sval: "38.62",
                    tvol: "104.73",
                    tfreq: "5,040"},
                { no: 4,
                    code: "RX",
                    company: "Macquarie Sekuritas Indonesia",
                    tval: "99.64",
                    sval: "38.62",
                    tvol: "104.73",
                    tfreq: "5,040"},
                { no: 5,
                    code: "YU",
                    company: "CGS-CIMB Sekuritas Indonesia",
                    tval: "99.64",
                    sval: "38.62",
                    tvol: "104.73",
                    tfreq: "5,040"},
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138 ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.topBrokerSell}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= INT.INDICES =============
class IntIndicesMarketStatistikPage_Base extends React.PureComponent {

    render () {
        let p = this.props;
        const paddingParagraph = {
            paddingTop: '10px'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid px-1 f-12" >
                    <div className="col-sm-12 px-0" style={paddingParagraph}>
                        <div className="cssmenu col-sm-8 mx-0 px-0 h-45">
                            <ul className={"d-border-top d-border-left d-border-right"}>
                                <li className={`d-border-right click-pointer col-sm-6 px-0 mx-0 f-12 text-center ${p.tabIntIndices === 1 ? "active" : "" }`} 
                                    onClick={() => p.handleOnClick("marketStatistic", "tabIntIndices", 1)}><a><span className="f-11">&nbsp; Stock</span></a></li>
                                <li className={`d-border-right click-pointer col-sm-6 px-0 mx-0 f-12 text-center ${p.tabIntIndices === 2 ? "active" : "" }`} 
                                    onClick={() => p.handleOnClick("marketStatistic", "tabIntIndices", 2)}><a><span className="f-11">&nbsp; Indices Futures</span></a></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 px-0 py-0 mx-0 my-0 bg-grey bg-black-trading d-border card-472">
                            <div className={p.tabIntIndices === 1 ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <div className="bg-tableheader text-center py-3 h-30">
                                        <Popup content='Refresh' position='top center' trigger={
                                            <button className="pull-right btn btn-primary btn-10"
                                                onClick={()=>this.props.handleOnClick("refreshPage","intIndices","none")}>
                                                <i className="glyphicon glyphicon-refresh" aria-hidden="true"></i>
                                            </button>
                                        }/>
                                        <span>International Indices</span>
                                    </div>                                    
                                    <InternationalIndicesAgGrid size={widthSize()}/>
                                </div>
                            </div>
                            <div className={p.tabIntIndices === 2 ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <div className="bg-tableheader text-center py-3 h-30">
                                        <Popup content='Refresh' position='top center' trigger={
                                            <button className="pull-right btn btn-primary btn-10"
                                                onClick={()=>this.props.handleOnClick("refreshPage", "intIndicesFutures","none")}>
                                                <i className="glyphicon glyphicon-refresh" aria-hidden="true"></i>
                                            </button>
                                        }/>
                                        <span>International Indices Futures</span>
                                    </div>
                                    <InternationalIndicesFuturesAgGrid size={widthSize()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
// INT.INDICES: STOCK
class InternationalIndicesAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "symbol", headerName: "Symbol", sortable: true, resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?150:s=="s85"?140:s=="s90"?130:120,
                    minWidth:120,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },{ 
                    field: "name", headerName: "Name", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?400:s=="s50"?350:s=="s67"?300:s=="s75"?250:s=="s80"?210:s=="s85"?200:s=="s90"?200:190,
                    minWidth: 190,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },{ 
                    field: "prev", headerName: "Prev", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?200:s=="s80"?170:s=="s85"?155:s=="s90"?150:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "lastUpdated", headerName: "Last Updated", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?195:s=="s80"?180:s=="s85"?155:s=="s90"?150:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToDateTime(value), 
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "high", headerName: "High", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?155:s=="s90"?145:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "low", headerName: "Low", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?155:s=="s90"?145:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "last", headerName: "Last Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?160:s=="s90"?145:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                },{ 
                    field: "change", headerName: "Change", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?160:s=="s90"?145:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                },{ 
                    field: "percentage", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s90"?130:120,
                    minWidth: 120,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-intIndices ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.intIndex}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight= "32"
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// INT.INDICES: INDICES FUTURES
class InternationalIndicesFuturesAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "index", headerName: "Index", sortable: true, resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?230:s=="s75"?210:s=="s80"?190:s=="s85"?170:s=="s90"?165:160,
                    minWidth:160,
                    cellClass: () => " grid-table d-border-aggrid-right text-primary f-12"
                },{ 
                    field: "month", headerName: "Month", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?230:s=="s75"?210:s=="s80"?190:s=="s85"?170:s=="s90"?165:160,
                    minWidth: 160,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },{ 
                    field: "last", headerName: "Last", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?230:s=="s75"?210:s=="s80"?190:s=="s85"?170:s=="s90"?170:170,
                    minWidth: 170,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "high", headerName: "High", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?230:s=="s75"?210:s=="s80"?190:s=="s85"?170:s=="s90"?170:155,
                    minWidth: 155,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "low", headerName: "Low", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?290:s=="s50"?270:s=="s67"?240:s=="s75"?230:s=="s80"?200:s=="s85"?180:s=="s90"?170:155,
                    minWidth: 155,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "change", headerName: "Change", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?320:s=="s50"?290:s=="s67"?245:s=="s75"?240:s=="s80"?200:s=="s85"?180:s=="s90"?160:150,
                    minWidth: 150,
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                },{ 
                    field: "changePercent", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?325:s=="s50"?290:s=="s67"?250:s=="s75"?240:s=="s80"?200:s=="s85"?180:s=="s90"?170:160,
                    minWidth: 160,
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                },{ 
                    field: "time", headerName: "Time", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?330:s=="s50"?300:s=="s67"?250:s=="s75"?240:s=="s80"?200:s=="s85"?180:s=="s90"?170:160,
                    minWidth: 160,
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-intIndices ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.intIndexFutures}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight= "32"
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= CURRENCY & COMMODITY =============
class CurrencyCommodityMarketStatistikPage_Base extends React.PureComponent {

    render(){
        let p = this.props;
        return(
            <div>
                <div className="col-sm-12 row px-0 mx-0 row">
                    <div className="col-sm-7 px-2 mx-0 card-520 mt-2">
                        <div className="bg-trading-gray pb-2">
                            <div className="bg-tableheader pr-2 pl-3 py-2">
                                <span className={"f-30"}>
                                    {p.currenciesActive.name}
                                </span>
                                <Popup content='Refresh' position='top center' trigger={
                                    <button className="pull-right btn btn-primary"
                                        onClick={()=>this.props.handleOnClick("refreshPage","currencies","none")}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden="true"></i>
                                    </button>
                                }/>
                                </div>
                                <table className={"col-sm-12 pl-3 f-19"}>
                                    <tr>
                                        <td className="bold pl-3 py-3">{ConvertToRupiah(p.currenciesActive.last)}</td>
                                        <td className={`px-1 py-3 ${ColorLabel(Number(p.currenciesActive.change))}`}>
                                            <i className={IconChange(Number(p.currenciesActive.change), "minus")}></i>&nbsp;{ConvertToRupiah(p.currenciesActive.change)}</td>
                                        <td className={`px-1 py-3 ${ColorLabel(Number(p.currenciesActive.change))}`}>
                                            { p.currenciesActive.percentage === "" ? "" : `( ${p.currenciesActive.percentage} %)`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className={"pl-3 py-1 f-12"}>
                                            <span>Last Updated: {p.currenciesActive.dateTime}</span>
                                        </td>
                                    </tr>
                                </table>
                            <CurrenciesAgGrid size={widthSize()} />
                        </div>
                    </div>
                    <div className="col-sm-5 pl-0 pr-3 mx-0 card-520 f-12 mt-2">
                            <div className="bg-trading-gray pb-2">
                                <div className="bg-tableheader pr-2 pl-3 py-2">
                                    <span className={"f-30"}>
                                        {p.commodityActive.name}
                                    </span>
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button className="pull-right btn btn-primary"
                                            onClick={()=>this.props.handleOnClick("refreshPage","commodity","none")}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden="true"></i>
                                        </button>
                                    }/>
                                </div>
                                <table className={"col-sm-12 pl-3 f-19"}>
                                    <tr>
                                        <td className="bold pl-3 py-3">{ConvertToRupiah(p.commodityActive.last)}</td>
                                        <td className={`px-1 py-3 ${ColorLabel(Number(p.commodityActive.change))}`}>
                                            <i className={IconChange(Number(p.commodityActive.change), "minus")}></i>&nbsp;{ConvertToRupiah(p.commodityActive.change)}</td>
                                        <td className={`px-1 py-3 ${ColorLabel(Number(p.commodityActive.change))}`}>
                                            { p.commodityActive.percentage === "" ? "" : `( ${p.commodityActive.percentage} %)`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className={"pl-3 py-1 f-12"}>
                                            <span>Last Updated:{p.commodityActive.dateTime}</span>
                                        </td>
                                    </tr>
                                </table>
                            <CommodityAgGrid size={widthSize()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// CURRENCY & COMMODITY: bagian kiri
class CurrenciesAgGrid_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "other", headerName: "Other Currencies", sortable: true, resizable: true,comparator: stringComparator,
                    width: s=="s49"?340:s=="s50"?310:s=="s67"?270:s=="s75"?260:s=="s80"?220:s=="s85"?200:s=="s90"?185:s=="s100"?180:180,
                    minWidth:180,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer: function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.other === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            eDiv.innerHTML = 
                                '<div class="searchCurrencies">'+params.data.other+'</div>';
                            var bButton = eDiv.querySelectorAll('.searchCurrencies')[0];

                            bButton.addEventListener('dblclick', ()=>self.props.handleOnClick("marketStatistic", "codeCurrencies", params.data.other));
                        }
                        return eDiv;
                    },
                },{ field: "last", headerName: "Last", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?340:s=="s50"?310:s=="s67"?270:s=="s75"?250:s=="s80"?220:s=="s85"?190:s=="s90"?190:s=="s100"?180:180,
                    minWidth: 180,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "change", headerName: "Change", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?340:s=="s50"?300:s=="s67"?270:s=="s75"?250:s=="s80"?220:s=="s85"?195:s=="s90"?190:s=="s100"?180:180,
                    minWidth: 180,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right  grid-table d-border-aggrid-right f-12" :
                                "text-warning text-right grid-table d-border-aggrid-right f-12");
                    }
                },{ field: "percentage", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?340:s=="s50"?300:s=="s67"?270:s=="s75"?250:s=="s80"?220:s=="s85"?200:s=="s90"?190:s=="s100"?170:170,
                    minWidth: 170,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right  grid-table d-border-aggrid-right f-12" :
                                "text-warning text-right grid-table d-border-aggrid-right f-12");
                    }
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    other: "USD/IDR",
                    last: 14.233,
                    change: "-5",
                    percentage: "0.003%",
                }
            ],
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        let p = this.props;
        return (
            <div className={"px-3"} style={{ width: "100%", height: "100%" }}>
                <p className={"text-primary f-15"}>
                    PREV CLOSE
                    &nbsp;<span className={`${ColorLabel(Number(p.currenciesActive.change))}`}>
                        {ConvertToRupiah(p.currenciesActive.prevClose)}
                    </span>
                </p>
                <div
                    className={"card-currencies ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={p.currencies}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// CURRENCY & COMMODITY: bag kanan
class CommodityAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "name", headerName: "Name", sortable: true, resizable: true,comparator: stringComparator,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?145:s=="s90"?140:s=="s100"?130:180,
                    minWidth:135,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer: function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.code === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            eDiv.innerHTML = 
                                '<div class="searchCommodity">'+params.data.name+'</div>';
                            var bButton = eDiv.querySelectorAll('.searchCommodity')[0];

                            bButton.addEventListener('dblclick', ()=>self.props.handleOnClick("marketStatistic", "codeCommodity", params.data.code));
                        }
                        return eDiv;
                    },
                },{ field: "last", headerName: "Last", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?180:s=="s80"?160:s=="s85"?150:s=="s90"?140:s=="s100"?130:180,
                    minWidth: 180,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "change", headerName: "Change", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?190:s=="s75"?180:s=="s80"?160:s=="s85"?130:s=="s90"?130:s=="s100"?130:130,
                    minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "percentage", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?210:s=="s50"?190:s=="s67"?160:s=="s75"?150:s=="s80"?140:s=="s85"?122:s=="s90"?120:s=="s100"?105:105,
                    minWidth: 105,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    name: "ACBB",
                    last: "2000.2",
                    change: "-23",
                    percentage: "1.6%",
                }
            ],
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div className={"px-3"} style={{ width: "100%", height: "100%" }}>
                <p className={"text-primary f-15"}>
                    &nbsp;
                </p>
                <div
                    className={"card-currencies ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.commodity}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class CurrenciesMarketStatistikPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        };

    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });
    render() {
        let data = this.props.currenciesActive;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };
        const stockOptions = [
            { value:'bmpt', code: 'BMPT', saham: 'Bumi Mega Pertama ' },
            { value:'bnmp-ppt', code: 'BNMP-PPT', saham: 'Bumi Nusa Putra ' },
            { value:'bumi', code: 'BUMI', saham: 'Bumi Resource ' },
            { value:'asii', code: 'ASII', saham: 'Argo Astra Lestari ' },
            { value:'tlkm', code: 'TLKM', saham: 'Telekomunikasi Indonesia ' },
            { value:'wskt', code: 'WSKT', saham: 'Waskita ' },
            { value:'indf', code: 'INDF', saham: 'Indofood ' },
            { value:'bbca', code: 'BBCA', saham: 'Bank BCA ' },
            { value:'smrg', code: 'SMGR', saham: 'Semen Indonesia ' },
            { value:'bbri', code: 'BBRI', saham: 'Bank BRI ' }
        ];
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <AppFrameAction ref="frameAction"/>
                <div className={"row pl-4 pr-4 mt-2"}>
                    <div className="col-sm-4 px-0 mb-3 bg-currencies">
                        <div className="col-sm-12 mx-0 text-center row px-2">
                            <div className="col-sm-11 px-0 text-left text-primary pt-3 pb-2">
                                <h1>{data.name}</h1>
                            </div>
                            <div className={"col-sm-1 px-0"}>
                                <Popup content='Refresh' position='top center' trigger={
                                    <button
                                        onClick={()=>this.props.handleOnClick("refreshPage","currencies","none")}
                                        className={`btn btn-primary pull-right`}
                                        style={{"font-size": "12px", "marginTop": "6px", "width": "38px"}}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                    </button>
                                }/>
                            </div>
                            <div className={"col-sm-5 px-0 mx-0 text-left"}>
                                {ConvertToRupiah(data.lastPrice)}
                            </div>
                            <div className={`col-sm-1 f-12 px-0 mx-0 pt-3 text-left ${ColorLabel(Number(data.change))}`}>
                                <i className={IconChange(Number(data.change), "minus")}></i>
                            </div>
                            <div className={`col-sm-6 f-12 px-0 mx-0 pt-3 text-left ${ColorLabel(Number(data.change))}`}>
                                {`${ConvertToRupiah(data.change)} ( ${data.perChange} %)`}
                            </div>
                            <div className={"col-sm-12 px-0 mb-3 py-2 f-12 text-left bb-blue"}>
                                Last Updated: {`${data.dateTime}`}
                            </div>
                        </div>
                        <CurrenciesAgGrid size={widthSize()}/>
                    </div>

                    <div className="col-sm-8 pl-1 pr-0 mb-3 ">
                        <div className="col-sm-12 px-2 mx-0 text-center row ">
                            <div className="col-sm-12 px-3 pt-3 mx-0 text-left f-15 bg-currencies">
                                INTERNATIONAL INDICES
                                <Popup content='Refresh' position='top center' trigger={
                                    <button
                                        onClick={()=>this.props.handleOnClick("refreshPage","intIndices","none")}
                                        className={`btn btn-primary pull-right col-sm-1`}
                                        style={{"font-size": "14px", "width": "38px", "margin-top": "-8px"}}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                    </button>
                                }/>
                            </div>

                        </div>
                        <InternationalIndicesAgGrid size={widthSize()}/>
                    </div>
                </div>


            </div>
        )
    };
}


//============= 5. New & Research Display =============
class NewResearchMarketStatistikPage extends React.PureComponent {
    render(){
        return(
            <div>
                {/*<BIPSAppProvider>*/}
                {/*<WSConnectionAction />*/}
                <div className="row col-sm-12 px-0 mx-0 pt-1">
                    <div className="col-sm-12 px-2 h-45">
                        <MenuOfContent treeName="/marketstatistikPage/newResearchMarketStatistikPage" linkTitles={
                            {
                                newsGeneral : 'General News',
                                newsStock : 'Stock News',
                                newsMutualFund : 'Mutual Fund News',
                                // newsResearch : 'Research'
                            }
                        } />
                    </div>
                    <div className="col-sm-12 px-2">
                        <AppFrame treeName="/marketstatistikPage/newResearchMarketStatistikPage" headerComponent={ResearchMarketStatistikFrameHeader}/>
                    </div>
                </div>
                {/*</BIPSAppProvider>*/}
            </div>
        );
    }
}

class SelectChoose extends React.PureComponent {
    selectStyleNight = theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            neutral0  : '#181717',
            neutral20 : '#565252',
            neutral30 : '#565252',
            neutral40 : '#cccccc',
            neutral80 : '#FFFFFF',
            primary75 : '#FFFFFF',
            primary50 : '#4D4D4E',
            primary25 : '#4D4D4E',
            primary : '#0363A7',
        },
    });

    selectStyleLight = theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            neutral0  : '#E7E7E7',
            neutral20 : '#9A9A9A',
            neutral30 : '#9A9A9A',
            neutral40 : '#767676',
            neutral80 : '#888888',
            primary75 : '#888888',
            primary50 : '#F3F3F3',
            primary25 : '#F3F3F3',
            primary : '#0363A7',
        },
    });

    render() {
        return (
            <div className="col-md-12 bg-black-grey px-0 text-center text-white">
                <Select
                    className="f-12"
                    defaultValue={option[0]}
                    label="Single select"
                    options={option}
                    theme={this.selectStyleNight}
                />
            </div>
        );
    }
}

class GeneralNewResearchPage_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        var genData = this.props.genNews
        var news = this.props.news
        if(genData[0].time === ""){
            genData.shift()
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col sm-12 px-0 mx-0 row">
                    <div className="col-sm-8 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-right">
                            <div className="card-header px-3 text-white h-73 d-border-bottom">
                                <h3>{news.highlight}
                                </h3>
                            </div>
                            <div className="card card-body card-406 scrollable px-3">
                                {/* <div className={"text-center align-self-center"}>
                                    <img src={newsImg1} alt="News 1" height={"auto"} width={"50%"} />
                                </div>*/}
                                <div className="py-4 text-white text-justify f-12">
                                    {news.content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-left">
                            <div className="card card-body card-479 scrollable px-3">
                            {
                                genData.map((data,index)=>{
                                    return<div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                             {/*<div className="col-sm-6 pl-0 pr-1 mx-0 text-center align-self-center">
                                                <img src={newsImg2} alt="News 1" height={"auto"} width={"100%"} />
                                            </div> */}
                                            <div className="col-sm-12 pr-0 pl-1 mx-0">
                                                <p className="f-16">
                                                {data.highlight}
                                                </p><br/>{data.time}
                                                {/* <span className="text-warning">Liputan6.com - </span> 27 minutes ago */}
                                            </div>
                                        </div>
                                }).reverse()
                            }

                                {/* <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    <div className="col-sm-6 pl-0 pr-1 mx-0 text-center align-self-center">
                                        <img src={newsImg2} alt="News 1" height={"auto"} width={"100%"} />
                                    </div>
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            The Fed Pangkas Suku Bunga,
                                            IHSG Dibuka Melemah ke 6,381.98
                                        </p><br/><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    <div className="col-sm-6 pl-0 pr-1 mx-0 text-center align-self-center">
                                        <img src={newsImg3} alt="News 1" height={"auto"} width={"100%"} />
                                    </div>
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            267 Saham Menghijau,
                                            IHSG Ditutup Menguat ke 6,204.19
                                        </p><br/><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    <div className="col-sm-6 pl-0 pr-1 mx-0 text-center align-self-center">
                                        <img src={newsImg4} alt="News 1" height={"auto"} width={"100%"} />
                                    </div>
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Seluruh Sektor Di Zona Merah,
                                            IHSG Ditutup Terjun Bebas ke 6,175.7
                                        </p><br/><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    <div className="col-sm-6 pl-0 pr-1 mx-0 text-center align-self-center">
                                        <img src={newsImg5} alt="News 1" height={"auto"} width={"100%"} />
                                    </div>
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Cara BEI Ajak Pengusaha Kecil
                                            Melantai Di Bursa Efek
                                        </p><br/><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class StockNewResearchPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col sm-12 px-0 mx-0 row">
                    <div className="col-sm-8 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-right">
                            <div className="card-header px-3 text-white h-73 d-border-bottom">
                                {/* <h3>
                                    Investor Asing Jual Saham Hampir Rp 2 Triliun,<br />
                                    IHSG Ditutup Turun 56,23 Poin
                                </h3> */}
                            </div>
                            {/* <div className="card card-body card-406 scrollable px-3"> 
                                <div className={"text-center align-self-center"}>
                                    <img src={newsImg1} alt="News 1" height={"auto"} width={"50%"} />
                                </div> */}
                                {/* <div className="py-4 text-white text-justify f-12">
                                    <span className="text-warning">Liputan6.com, Jakarta </span> -  Indeks Harga Saham
                                    Gabungan (IHSG) ditutup melemah pada perdagangan Selasa ini. Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. <br/><br/>
                                    Pada penutupan perdagangan saham Selasa (6/8/2019), IHSG melemah 56,23 poin atau 0,91 persen ke level 6.119,47. Indeks saham LQ45 juga merosot 1,54 persen ke posisi 960,75. <br/><br/>
                                    Sebanyak 207 saham di melemah sehingga mendorong IHSG ke zona merah. Sementara 121 saham menguat dan 128 saham diam di tempat. <br/><br/>
                                    Transaksi perdagangan saham cukup ramai. Total frekuensi perdagangan saham 548.686 kali dengan volume perdagangan 16,8 miliar saham. Nilai transaksi harian saham Rp 10,4 triliun. <br/><br/>
                                    Investor asing jual saham Rp 1,94 triliun di pasar regular. Posisi dolar Amerika Serikat (AS) berada di kisaran Rp 14.265.
                                    Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. Pelemahan tersebut dipimpin oleh sektor aneka industri yang turun 2,26 persen,
                                    diikuti sektor keuangan turun 1,90 persen, dan sektor perdagangan turun 1,28 persen.<br/><br/>
                                    Saham-saham yang melemah sehingga mendorong IHSG ke zona merah antara lain MLPT turun 25 persen
                                    ke Rp 525 per saham, YPAS merosot 23,81 persen ke Rp 352 per saham dan OASA turun 12,23 persen ke Rp 244 per saham.<br/><br/>
                                    Sementara saham-saham yang menguat antara lain SDRA yang naik 24,62 persen ke Rp 810 per saham, APEX naik 15 persen
                                    ke Rp 690 per saham dan FIRE naik 14,29 persen ke Rp 2.400 per saham. <br/><br/>
                                    Kepala Riset Valbury Sekuritas Alfiansyah mengatakan, kecemasan perang dagang AS dan
                                    China masih membayangi dan menjadi sentimen negatif bagi IHSG. "Apalagi data ekonomi
                                    Indonesia di bawah ekspektasi. Ini dapat memicu IHSG kembali terkoreksi," ujar Alfiansyah.<br/><br/>
                                    Kebijakan Presiden AS Donald Trump yang akan menerapkan bea masuk sebesar 10 persen
                                    terhadap barang-barang impor China mulai 1 September 2019 mendatang, dinilai akan berdampak
                                    kepada perekonomian global termasuk bagi perekonomian Indonesia.
                                </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-sm-4 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-left">
                            <div className="card card-body card-479 scrollable px-3">
                                {/* <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            267 Saham Menghijau,
                                            IHSG Ditutup Menguat ke 6,204.19
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            The Fed Pangkas Suku Bunga,
                                            IHSG Dibuka Melemah ke 6,381.98
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Seluruh Sektor Di Zona Merah,
                                            IHSG Ditutup Terjun Bebas ke 6,175.7
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Cara BEI Ajak Pengusaha Kecil
                                            Melantai Di Bursa Efek
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class MutualNewResearchPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col sm-12 px-0 mx-0 row">
                    <div className="col-sm-8 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-right">
                            <div className="card-header px-3 text-white h-73 d-border-bottom">
                                {/* <h3>
                                    Investor Asing Jual Saham Hampir Rp 2 Triliun,<br />
                                    IHSG Ditutup Turun 56,23 Poin
                                </h3> */}
                            </div>
                            {/* <div className="card card-body card-406 scrollable px-3">
                                <div className="py-4 text-white text-justify f-12">
                                    <span className="text-warning">Liputan6.com, Jakarta </span> -  Indeks Harga Saham
                                    Gabungan (IHSG) ditutup melemah pada perdagangan Selasa ini. Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. <br/><br/>
                                    Pada penutupan perdagangan saham Selasa (6/8/2019), IHSG melemah 56,23 poin atau 0,91 persen ke level 6.119,47. Indeks saham LQ45 juga merosot 1,54 persen ke posisi 960,75. <br/><br/>
                                    Sebanyak 207 saham di melemah sehingga mendorong IHSG ke zona merah. Sementara 121 saham menguat dan 128 saham diam di tempat. <br/><br/>
                                    Transaksi perdagangan saham cukup ramai. Total frekuensi perdagangan saham 548.686 kali dengan volume perdagangan 16,8 miliar saham. Nilai transaksi harian saham Rp 10,4 triliun. <br/><br/>
                                    Investor asing jual saham Rp 1,94 triliun di pasar regular. Posisi dolar Amerika Serikat (AS) berada di kisaran Rp 14.265.
                                    Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. Pelemahan tersebut dipimpin oleh sektor aneka industri yang turun 2,26 persen,
                                    diikuti sektor keuangan turun 1,90 persen, dan sektor perdagangan turun 1,28 persen.<br/><br/>
                                    Saham-saham yang melemah sehingga mendorong IHSG ke zona merah antara lain MLPT turun 25 persen
                                    ke Rp 525 per saham, YPAS merosot 23,81 persen ke Rp 352 per saham dan OASA turun 12,23 persen ke Rp 244 per saham.<br/><br/>
                                    Sementara saham-saham yang menguat antara lain SDRA yang naik 24,62 persen ke Rp 810 per saham, APEX naik 15 persen
                                    ke Rp 690 per saham dan FIRE naik 14,29 persen ke Rp 2.400 per saham. <br/><br/>
                                    Kepala Riset Valbury Sekuritas Alfiansyah mengatakan, kecemasan perang dagang AS dan
                                    China masih membayangi dan menjadi sentimen negatif bagi IHSG. "Apalagi data ekonomi
                                    Indonesia di bawah ekspektasi. Ini dapat memicu IHSG kembali terkoreksi," ujar Alfiansyah.<br/><br/>
                                    Kebijakan Presiden AS Donald Trump yang akan menerapkan bea masuk sebesar 10 persen
                                    terhadap barang-barang impor China mulai 1 September 2019 mendatang, dinilai akan berdampak
                                    kepada perekonomian global termasuk bagi perekonomian Indonesia.
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-4 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-left">
                            <div className="card card-body card-479 scrollable px-3">
                                {/* <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                   
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            267 Saham Menghijau,
                                            IHSG Ditutup Menguat ke 6,204.19
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Seluruh Sektor Di Zona Merah,
                                            IHSG Ditutup Terjun Bebas ke 6,175.7
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            The Fed Pangkas Suku Bunga,
                                            IHSG Dibuka Melemah ke 6,381.98
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Cara BEI Ajak Pengusaha Kecil
                                            Melantai Di Bursa Efek
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class ReseacrhNewResearchPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col sm-12 px-0 mx-0 row">
                    <div className="col-sm-8 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-right">
                            <div className="card-header px-3 text-white h-73 d-border-bottom">
                                <h3>
                                    Investor Asing Jual Saham Hampir Rp 2 Triliun,<br />
                                    IHSG Ditutup Turun 56,23 Poin
                                </h3>
                            </div>
                            <div className="card card-body card-406 scrollable px-3">
                               
                                <div className="py-4 text-white text-justify f-12">
                                    <span className="text-warning">Liputan6.com, Jakarta </span> -  Indeks Harga Saham
                                    Gabungan (IHSG) ditutup melemah pada perdagangan Selasa ini. Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. <br/><br/>
                                    Pada penutupan perdagangan saham Selasa (6/8/2019), IHSG melemah 56,23 poin atau 0,91 persen ke level 6.119,47. Indeks saham LQ45 juga merosot 1,54 persen ke posisi 960,75. <br/><br/>
                                    Sebanyak 207 saham di melemah sehingga mendorong IHSG ke zona merah. Sementara 121 saham menguat dan 128 saham diam di tempat. <br/><br/>
                                    Transaksi perdagangan saham cukup ramai. Total frekuensi perdagangan saham 548.686 kali dengan volume perdagangan 16,8 miliar saham. Nilai transaksi harian saham Rp 10,4 triliun. <br/><br/>
                                    Investor asing jual saham Rp 1,94 triliun di pasar regular. Posisi dolar Amerika Serikat (AS) berada di kisaran Rp 14.265.
                                    Dari 10 sektor pembentuk IHSG, seluruhnya sebagian besar melemah. Pelemahan tersebut dipimpin oleh sektor aneka industri yang turun 2,26 persen,
                                    diikuti sektor keuangan turun 1,90 persen, dan sektor perdagangan turun 1,28 persen.<br/><br/>
                                    Saham-saham yang melemah sehingga mendorong IHSG ke zona merah antara lain MLPT turun 25 persen
                                    ke Rp 525 per saham, YPAS merosot 23,81 persen ke Rp 352 per saham dan OASA turun 12,23 persen ke Rp 244 per saham.<br/><br/>
                                    Sementara saham-saham yang menguat antara lain SDRA yang naik 24,62 persen ke Rp 810 per saham, APEX naik 15 persen
                                    ke Rp 690 per saham dan FIRE naik 14,29 persen ke Rp 2.400 per saham. <br/><br/>
                                    Kepala Riset Valbury Sekuritas Alfiansyah mengatakan, kecemasan perang dagang AS dan
                                    China masih membayangi dan menjadi sentimen negatif bagi IHSG. "Apalagi data ekonomi
                                    Indonesia di bawah ekspektasi. Ini dapat memicu IHSG kembali terkoreksi," ujar Alfiansyah.<br/><br/>
                                    Kebijakan Presiden AS Donald Trump yang akan menerapkan bea masuk sebesar 10 persen
                                    terhadap barang-barang impor China mulai 1 September 2019 mendatang, dinilai akan berdampak
                                    kepada perekonomian global termasuk bagi perekonomian Indonesia.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 px-0 mx-0 f-12">
                        <div className="card card-479 d-border-left">
                            <div className="card card-body card-479 scrollable px-3">
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-12 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            267 Saham Menghijau,
                                            IHSG Ditutup Menguat ke 6,204.19
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Seluruh Sektor Di Zona Merah,
                                            IHSG Ditutup Terjun Bebas ke 6,175.7
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                    
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            Cara BEI Ajak Pengusaha Kecil
                                            Melantai Di Bursa Efek
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                                <div className="row col-sm-12 px-0 mx-0 d-border-bottom pb-4 mb-4 click-pointer">
                                   
                                    <div className="col-sm-6 pr-0 pl-1 mx-0">
                                        <p className="f-16">
                                            The Fed Pangkas Suku Bunga,
                                            IHSG Dibuka Melemah ke 6,381.98
                                        </p><br/>
                                        <span className="text-warning">Liputan6.com - </span> 27 minutes ago
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const option = [
    { value: 'choose', label: 'Choose' },
];

//============= 6. NON SECTORAL INDEX =============
class NonSectoralStatistikPage_Base extends React.PureComponent{
    
    refreshIndexMember = () => this.refs.netActionAux.send(fmsg.ix_indexStockVal(this.props.nonsectorName));

    render(){
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="netActionAux" socketID="aux" />

                <div className="card grid-294 bg-black-trading f-12">
                    <MarketNonIndicesAgGrid size={widthSize()}/>
                </div>

                <div className="card card-233 bg-black-trading f-12">
                    <div className="card-header px-0 py-0">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                            <div className="bg-tableheader col-sm-12 px-0 mx-0 text-center py-3 h-30">
                                {this.props.nonsectorName || ""}
                                <Popup content='Refresh' position='top center' trigger={
                                    <button
                                        onClick={() => this.refreshIndexMember() }     
                                        className="col-sm-1 pull-right btn btn-primary mr-2"
                                        style={{"marginTop": "-8px", "width": "39px", "height": "28px"}}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                    </button>
                                }/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <MarketStatistikAgGrid typegrid="indices" />
                    </div>
                </div>
            </>
        );
    }
}

class MarketNonIndicesAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "index_code", headerName: "Sector", resizable: true,
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?150:125,
                    minWidth: 125,
                    lockVisible:true, lockPosition:true,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12 text-primary locked-col locked-visible",
                    cellRenderer: 'sectorRenderer',
                    suppressSizeToFit: true, pinned: 'left'
                }, { 
                    field: "index", headerName: "Last", filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?140:128,
                    minWidth: 128,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "change", headerName: "Change", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?140:122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    minWidth: 122,
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "persen", headerName: "%" , resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?155:s=="s75"?140:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "prev_index", headerName: "Prev. Closed", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?190:s=="s50"?180:s=="s67"?160:s=="s75"?135:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "open", headerName: "Open", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?145:s=="s75"?133:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, {
                    field: "high", headerName: "High", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?143:s=="s75"?133:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "low", headerName: "Low", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s60"?160:s=="s67"?145:s=="s75"?135:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, {
                    field: "vol", headerName: "Volume (Lot)", resizable: true, width: 122,  filter: "agNumberColumnFilter",
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "val", headerName: "Value (T)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?170:s=="s75"?162:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "f_buy_val", headerName: "F.Buy (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?140:s=="s75"?132:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "f_sell_val", headerName: "F.Sell (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?180:s=="s60"?170:s=="s75"?150:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change)}`
                }, { 
                    field: "fnet", headerName: "F.Net (Val)", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?230:s=="s50"?220:s=="s75"?150:122,
                    minWidth: 122,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: ({ data }) => data.change && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.fnet)}`
                },
            ],
            defaultColDef: {
                sortable: false,
                filter: true,
            },
            frameworkComponents: {
                sectorRenderer: ({ data }) => data.index_code ? <span className="searchSector click-pointer" 
                        onClick={() => self.props.handleSectorName(data.index_code, "nonSectoralIndex")}
                    >{data.index_code}</span> : ""
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        let p = this.props;
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="grid-294 ag-theme-balham-dark ag-striped-even"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={Object.values(p.nonIndices).length > 0 ? Object.values(p.nonIndices) : p.sectoralIndex}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//************************** Modal**************************
class BuyModal_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        ResizeResponsive();
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350" /**"card-340" */ />
                    </div>
                    <div className={"col-sm-6 mt-0 d-border-active bg-buy card-520 d-border"}>
                        <FormBuy
                            idPrice="stockBuyPrice"
                            part="stockInfo"
                            idVol="stockBuyVol"
                            idValue="stockBuyValue"
                            columnSm="col-sm-12"
                            part="stock"
                        />
                    </div>
                </div>
            </>
        );
    }
}

class SellModal_Base extends React.PureComponent  {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        ResizeResponsive();
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350" /**"card-340" *//>
                    </div>
                    <div className="col-sm-6 mt-0 d-border-active bg-sell card-520 d-border">
                        <FormSell
                            idPrice="stockSellPrice"
                            part="stockInfo"
                            idVol="stockSellVol"
                            idValue="stockSellValue"
                            columnSm="col-sm-12"
                            part={"stock"}/>
                    </div>
                </div>
            </>
        );
    }
}


//************************** Context Connector **************************
// 1. SUMMARY
const MarketStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        topType: vars.topType,
        topChoice: vars.topChoice,
        boardChoice: vars.boardChoice,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["setDataHandler", "handleTopChoice", "handleTopType","handleOnClick"]
)(MarketStatistikPage_Base)

const MarketStatistikAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        topGAL: vars.topGAL,        
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick", ]
)(MarketStatistikAgGrid_Base)

// 2. MARKET INDEX
const StatisticMarketStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        marketStatisticData: vars.marketStatisticData,
        indexlistOptions:vars.indexlistOptions,
        thememode: vars.thememode,
        codeSearchMarketIndex:vars.codeSearchMarketIndex,
        indexData:vars.indexData,
        // data Stream
        // streamChart:vars.streamChart,
        indexStreamChart:vars.indexStreamChart,
        // timeChart:vars.timeChart,
        streamStatus:vars.streamStatus,
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode',{type, code}),
        handleStreamChart: (streamStatus) => { actions.sendAction('handleStreamChart', { streamStatus }) },
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleSearchCode", "handleStreamChart", "handleOnClick",]
)(StatisticMarketStatistikPage_Base);

// 3. MEMBER CAPITALIZATION / SECTORAL INDEX
const IndiceMarketStatistikPage= ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        sectorName: vars.sectorName,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick"]
)(IndiceMarketStatistikPage_Base)

const MarketIndicesAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        indices: vars.indices,
        sectoralIndex : vars.sectoralIndex,
        handleSectorName: (name, ntype) => { actions.sendAction('handleSectorName', { name, ntype }) },
    }),
    ["handleSectorName", ]
    )(MarketIndicesAgGrid_Base)
    
// NON SECTORAL INDEX
const NonSectoralStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        nonsectorName: vars.nonsectorName,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick"]
)(NonSectoralStatistikPage_Base)

const MarketNonIndicesAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        nonIndices: vars.nonIndices,
        sectoralIndex: vars.sectoralIndex,
        handleSectorName: (name, ntype) => { actions.sendAction('handleSectorName', { name, ntype }) },
    }),
    ["handleSectorName", ]
)(MarketNonIndicesAgGrid_Base)

// 4. TOP BROKER
const TopBrokerMarketStatistikPage= ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick"]
)(TopBrokerMarketStatistikPage_Base)

const TopBrokerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        topBrokerVal: vars.topBrokerVal
    })
)(TopBrokerAgGrid_Base)

const TopBrokerBAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        topBrokerBuy: vars.topBrokerBuy
    })
)(TopBrokerBAgGrid_Base)

const TopBrokerSAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        topBrokerSell: vars.topBrokerSell
    })
)(TopBrokerSAgGrid_Base)

//============= INT.INDICES =============
const IntIndicesMarketStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        tabIntIndices: vars.tabIntIndices,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(IntIndicesMarketStatistikPage_Base)

// INT.INDICES: STOCK
const InternationalIndicesAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        intIndex: vars.intIndex
    })
)(InternationalIndicesAgGrid_Base)

// INT.INDICES: INDICES FUTURES
const InternationalIndicesFuturesAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        intIndexFutures: vars.intIndexFutures
    })
)(InternationalIndicesFuturesAgGrid_Base)

//============= CURRENCY & COMMODITY =============
const CurrencyCommodityMarketStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        currenciesActive: vars.currenciesActive,
        commodityActive: vars.commodityActive,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(CurrencyCommodityMarketStatistikPage_Base)

// CURRENCY & COMMODITY: bag kanan
const CurrenciesAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({        
        currencies: vars.currencies,
        currenciesActive: vars.currenciesActive,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(CurrenciesAgGrid_Base)

// CURRENCY & COMMODITY: bag kanan
const CommodityAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        commodity: vars.commodity,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(CommodityAgGrid_Base)

//============= 4.5 Top Broker Display =============
const CurrenciesMarketStatistikPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        currenciesActive: vars.currenciesActive,
        handleOnClick : (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(CurrenciesMarketStatistikPage_Base)

// 5. NEWS
const GeneralNewResearchPage = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        genNews: vars.genNews,
        news:vars.news,
    })
)(GeneralNewResearchPage_Base)

// >>>>>>>>>>MODAL<<<<<<<<<<<<<<
const BuyModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(BuyModal_Base)

const SellModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState
    })
)(SellModal_Base)

//************************** Value Formatter For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}

const timeFormatter = (params) => {
    return ConvertToDateTime(params.value);
}

// >>>>>>> Standart Filter for Aggrid <<<<<<<
function stringComparator(valueA, valueB){
    if(valueA !== null && valueB !== null){
        if(valueA.length < 2){
            return null;
        }else if(valueB.length < 2){
            return null;
        }else{
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    }
}

function integerComparator(valueA, valueB){
    if(valueA == ""){
        return null;
    }else if(valueB == ""){
        return null;
    }else{
        return valueA - valueB;
    }
}

function dateComparator(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return date1;
    }
    if (date1Number === null) {
        return date1;
    }
    if (date2Number === null) {
        return date2;
    }
    return date1Number - date2Number;
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

export default MarketStatistikPage;
export {CustomFrameHeaderMarketStatistik, MarketStatistik,
    StatisticMarketStatistikPage,
    IndiceMarketStatistikPage,
    IntIndicesMarketStatistikPage,
    IndiceMarketSecondStatistikPage,
    TopBrokerMarketStatistikPage,
    CurrenciesMarketStatistikPage,
    CurrencyCommodityMarketStatistikPage,
    NewResearchMarketStatistikPage,
    NonSectoralStatistikPage,
    GeneralNewResearchPage, StockNewResearchPage, MutualNewResearchPage, ReseacrhNewResearchPage};
