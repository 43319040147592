import React from "react";
import {AppFrameAction} from "./../appframe";
import TableInfoTransaction from "./../app_transaction/tableInfoTransaction";
import FormBuy from "./../app_transaction/form_buy";

import { BIPSAppContext } from "../AppData";
import { ContextConnector } from '../appcontext.js';
import { ResizeResponsive } from './../utils';

class ModalBuy_Base extends React.PureComponent{
    componentDidMount(){
        ResizeResponsive();
    }

    render(){
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <div className="text-white f-12">
                    <div className="col sm-8 px-0 mx-0 row">
                        <div className="col-sm-6 px-2 mt-0">
                            <TableInfoTransaction lotshare="modalbuy" card="card-350" />
                        </div>
                        <div className="col-sm-6 mt-0 d-border-active bg-buy card-520 d-border">
                            <FormBuy idPrice="modalBuyPrice" idVol="modalBuyVol" idValue="modalBuyValue" columnSm="col-sm-11" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const ModalBuy = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(ModalBuy_Base)

export default ModalBuy;