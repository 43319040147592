import React from 'react';
import {AgGridReact} from "ag-grid-react";
import Switch from "react-switch";

import { AppFrameAction } from '../appframe.js';
import {WSConnectionAction} from "../appnetwork";
import {ContextConnector} from "../appcontext";
import { BIPSAppContext, ConvertToRupiah, ConvertToTime } from "../AppData";

import ModalBuy from "./../app_modals/modal_buy";
import ModalSell from "./../app_modals/modal_sell";
import TableInfoTransaction from "./../app_transaction/tableInfoTransaction";
import { ResizeResponsive, widthSize } from "../utils";
import FormSell from "../app_transaction/form_sell";
import FormBuy from "../app_transaction/form_buy";

import $ from 'jquery';
window.$ = window.jQuery = $;

class NewSwitch_Base extends React.PureComponent {
    constructor() {
        super();
    }

    render() {
        return (
            <div >
                <Switch onChange={()=>this.props.handleStartOrStopRunningTrade(this.props.runningStatus)}
                    height={20}  checked={this.props.runningStatus} />
            </div>
        );
    }
}

class LiveTradePage_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: BuyModal,
            onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
            onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
        })
    }

    buttonClickSell = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: SellModal,
            onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
            onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
        })
    }

    render () {
        return (
            <div>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction />
                <div className="row pl-4 pt-2 pb-3 h-48 f-livetrade">
                    <div className={`col-md-1 px-0 pt-3 text-center
                        ${this.props.tradeType == "all" ?"livetradeMenuActive":"livetradeMenu"}`}
                        onClick={()=>this.props.handleTradeType("all")} >
                        <i className={this.props.tradeType == "all" ? "far fa-dot-circle" : "far fa-circle"}></i>
                        &nbsp;&nbsp;&nbsp; All
                    </div>
                    <div className={`col-md-1 px-0 pt-3 text-center
                        ${this.props.tradeType== "watchlist" ? "livetradeMenuActive":"livetradeMenu"}`}
                        onClick={()=>this.props.handleTradeType("watchlist")} >
                        <i className={this.props.tradeType== "watchlist" ? "far fa-dot-circle" : "far fa-circle"}></i>
                        &nbsp; WatchList
                    </div>
                    <div className={`col-md-1 px-0 pt-3 text-center
                        ${this.props.tradeType == "foreign" ? "livetradeMenuActive":"livetradeMenu"}`}
                        onClick={()=>this.props.handleTradeType("foreign")} >
                        <i className={this.props.tradeType == "foreign" ? "far fa-dot-circle" : "far fa-circle"}></i>
                        &nbsp; Foreign
                    </div>
                    <div className={`col-md-1 px-0 pt-3 text-center
                        ${this.props.tradeType == "tick" ?"livetradeMenuActive":"livetradeMenu"}`}
                        onClick={()=>this.props.handleTradeType("tick")}>
                        <i className={this.props.tradeType == "tick" ? "far fa-dot-circle" : "far fa-circle"}></i>
                        &nbsp; Tick
                    </div>
                    <div className={`col-md-1 px-0 pt-3 text-center
                        ${this.props.tradeType == "nonRG"?"livetradeMenuActive":"livetradeMenu"}`}
                        onClick={()=>this.props.handleTradeType("nonRG")}>
                        <i className={this.props.tradeType == "nonRG" ? "far fa-dot-circle" : "far fa-circle"}></i>
                        &nbsp; Non-RG
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-1 pt-3">
                        <NewSwitch/>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-3 pt-1 text-center pr-0">
                        <button className="d-border col-sm-4 btn btn-danger mr-2 ml-5" onClick={this.buttonClickBuy}><span>Buy</span></button>
                        <button className="d-border col-sm-4 btn btn-success mr-2" onClick={this.buttonClickSell}><span>Sell</span></button>
                    </div>
                </div>
                <div className="col-sm-12 row px-0 mx-0 row">
                    <div className="col-sm-7 px-2 mx-0 card-520">
                        <div className="bg-trading-gray">  
                            <LiveTradeAgGrid size={widthSize()}/>                          
                        </div>
                    </div>
                    <div className="col-sm-5 px-2 mx-0 bg-grey card-520 f-12">
                        <TableInfoTransaction card="card-350" lotshare="infoLiveTradePage"/>
                    </div>
                </div>
            </div>
        );
    }
}

class LiveTradeAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "time", headerName: "Time", resizable: true,
                    width: s=="s49"?155:s=="s50"?145:s=="s67"?135:s=="s75"?130:s=="s80"?120:s=="s110"?70:100,
                    minWidth: 100, 
                    sort: "desc",
                    valueFormatter:TimeFormatter,
                    cellClass : function (params) {                        
                        return "text-left grid-table d-border-aggrid-right f-12";                       
                    }},
                { field: "code", headerName: "Code", resizable: true,
                    width: s=="s49"?160:s=="s50"?145:s=="s67"?125:s=="s75"?120:s=="s80"?100:s=="s85"?80:s=="s110"?80:70,
                    minWidth: 70,
                    suppressSizeToFit:true, lockVisible:true,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12 locked-visible";
                    },
                    cellRenderer:function(params){
                        if(params.data.code === "\xa0"){
                            return ""
                        }else{
                            return params.data.code
                        }
                    }},
                { field: "price", headerName: "Price", resizable: true,
                    width: s=="s49"?160:s=="s50"?145:s=="s67"?125:s=="s75"?120:s=="s80"?100:s=="s85"?75:s=="s110"?70:80,
                    minWidth: 80,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;                        
                            return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" 
                            : "text-warning text-right grid-table d-border-aggrid-right f-12" );                       
                    }},
                { field: "change", headerName: "Change", resizable: true,
                    width: s=="s49"?160:s=="s50"?145:s=="s67"?125:s=="s75"?120:s=="s80"?100:s=="s85"?85:85,
                    valueFormatter:currencyFormatter,
                    minWidth: 85,
                    cellClass : function (params) {
                        var change = params.data.change;                        
                            return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" 
                            : "text-warning text-right grid-table d-border-aggrid-right f-12" );                        
                    }},
                { field: "percent", headerName: "%", resizable: true,
                    width: s=="s49"?145:s=="s50"?120:s=="s67"?105:s=="s75"?100:s=="s80"?80:s=="s85"?65:63,
                    minWidth: 63,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" 
                            : "text-warning text-right grid-table d-border-aggrid-right f-12" );
                        } },
                { field: "vol", headerName: "Vol", resizable: true,
                    width: s=="s49"?140:s=="s50"?120:s=="s67"?115:s=="s75"?110:s=="s80"?90:s=="s85"?70:70, minWidth: 70,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" 
                            : "text-warning text-right grid-table d-border-aggrid-right f-12" );
                        }},
                { field: "buyer", headerName: "Buyer", resizable: true,
                    width: s=="s49"?155:s=="s50"?145:s=="s67"?130:s=="s75"?125:s=="s80"?105:s=="s85"?110:s=="s90"?110:s=="s110"?78:85,
                    minWidth: 85,
                    cellClass : function (params) {
                        let buyer = params.data.buyer;
                        let sBuyer = buyer.split('-')[0].toUpperCase();
                        function colorBC(a){
                            if(a == "D"){
                                return "text-center grid-table d-border-aggrid-right f-12 text-warning"
                            }else{
                                return "text-center grid-table d-border-aggrid-right f-12 text-success"
                            }
                        }
                        return buyer.length ? colorBC(sBuyer) :"text-center grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer : function (params) {
                        let buyer = params.data.buyer;
                        let sBuyer = buyer.split('-');
                        return buyer.length ? '<span >'+sBuyer[0]+'</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+sBuyer[1] : '';
                    } },
                { field: "seller", headerName: "Seller", resizable: true,
                    width: s=="s49"?155:s=="s50"?140:s=="s67"?125:s=="s75"?125:s=="s80"?110:s=="s85"?110:s=="s90"?110:s=="s110"?78:85,
                    minWidth: 85,
                    cellClass : function (params) {
                        let seller = params.data.seller;
                        let sSeller = seller.split('-')[0].toUpperCase();
                        function colorBC(a){
                            if(a == "D"){
                                return "text-center grid-table d-border-aggrid-right f-12 text-warning"
                            }else{
                                return "text-center grid-table d-border-aggrid-right f-12 text-success"
                            }
                        }
                        return seller.length ? colorBC(sSeller) : "text-center grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer : function (params) {
                        let seller = params.data.seller;
                        let sSeller = seller.split('-');
                        return seller.length ? '<span>'+sSeller[0]+'</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+sSeller[1] : '';
                    } },
                { field: "board", headerName: "Board", resizable: true,
                    width: s=="s49"?155:s=="s50"?155:s=="s67"?135:s=="75"?130:s=="s80"?120:s=="s85"?120:s=="s90"?120:s=="s110"?80:90,
                    minWidth: 90,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    } },
            ],
            rowClassRules: {
                "bg-data-orange f-18":function (params) {
                    let a = params.data.time.split("-")[1]
                        if(a==8)
                        return true
                        else
                        return false
                    }
            },
            defaultColDef: {
                sortable: false,
                filter: false,
            },
            getRowHeight : function (params) {
                if (props.scaleState === "100" || props.scaleState === "110" || props.scaleState === "120"){
                    var heightLiveTrade = 26;
                } else if (props.scaleState === "90"){
                    var heightLiveTrade = 30;
                } else if (props.scaleState === "80"){
                    var heightLiveTrade = 32;
                }
                return heightLiveTrade;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
            // getRowStyle: function(params) {
            //     // if (params.data.code.split("-")[1] == 1) {         
            //     if (params.node.id == props.sumTrade) {   
            //         return {
            //             'background-color': 'var(--warna-bg-data-orange)',
            //         };
            //     }
            //     return null;
            // }
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 0);
        
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-520 ag-theme-balham-dark ag-header-border-gray-live-trade ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <span>
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            rowData={this.props.livetradeContainer}                            
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            // getRowStyle={this.state.getRowStyle}
                            onGridReady={this.onGridReady}
                            onFirstDataRendered={this.onFirstDataRendered}>
                        </AgGridReact>
                    </span>
                </div>
            </>
        );
    }
}

//************************** MODAL **************************
class BuyModal_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        ResizeResponsive();
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350"/>
                    </div>
                    <div className={"col-sm-6 mt-0 d-border-active bg-buy card-520 d-border"}>
                        <FormBuy
                            idPrice="stockBuyPrice"
                            part="stockInfo"
                            idVol="stockBuyVol"
                            idValue="stockBuyValue"
                            columnSm="col-sm-12"
                            part="stock"
                        />
                    </div>
                </div>
            </>
        );
    }
}

class SellModal_Base extends React.PureComponent  {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        ResizeResponsive();
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350" />
                    </div>
                    <div className="col-sm-6 mt-0 d-border-active bg-sell card-520 d-border">
                        <FormSell
                            idPrice="stockSellPrice"
                            part="stockInfo"
                            idVol="stockSellVol"
                            idValue="stockSellValue"
                            columnSm="col-sm-12"
                            part={"stock"}/>
                    </div>
                </div>
            </>
        );
    }
}

//************************** Context Connector **************************
const NewSwitch = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        runningStatus:vars.runningStatus,
        handleStartOrStopRunningTrade:(status)=>{actions.sendAction('handleStartOrStopRunningTrade',{status})},
    }),
    ['handleStartOrStopRunningTrade', ]
)(NewSwitch_Base)

const LiveTradePage = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        tradeType:vars.tradeType,
        handleTradeType: (trade)=>{actions.sendAction('handleTradeType',{trade})},
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ['handleTradeType', 'handleOnClick', ]
)(LiveTradePage_Base)

const LiveTradeAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        scaleState: vars.scaleState,       
        livetradeContainer:vars.livetradeContainer,
    }),
)(LiveTradeAgGrid_Base);

const BuyModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(BuyModal_Base)

const SellModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState, 
    })
)(SellModal_Base)

//************************** Value Formatter For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter=(params)=>{
    return ConvertToRupiah(params.value);
}
// >>>>>>> Standart Time Formatter <<<<<<<
const TimeFormatter=(params)=>{
    return ConvertToTime(params.value);
}

export default LiveTradePage;
