import React from "react";
import $ from "jquery";
import PinInput from "react-pin-input";

import { AppFrameAction } from "../../appframe";
import { ContextConnector } from "../../appcontext";
import { BIPSAppContext } from "../../AppData";
import { ForgotPINModal } from './index';

class VerifyPIN_Base extends React.PureComponent{
    state = {
        value: "",
        visible:false,
        isEmpty: false,
    }

    onChange = value => this.setState({ value });

    // click pin
    onClickSubmit = (e) => {
        if(this.state.value.length >= '6' || this.props.verifyPinStatus === true){
            // if(this.state.value === this.props.pinVal || this.props.verifyPinStatus == true) {
                //     this.props.handleOnClick('verifyPin',"transaction")
            if(this.props.verifyPinStatus){
                // if (this.props.tipe === 'buy'){
                //     this.refs.frameAction.closeModal(100);
                //     var frameAction = this.refs.frameAction;
                //     frameAction.showModal({
                //         headerClass: () => <div className="text-white f-12 text-right"><i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}</div>,
                //         contentClass: detailBuyModal,
                //         onClose: (result) => console.log('Second modal result = ', result),
                //         size: "mini"
                //     });
                // } else if (this.props.tipe === 'sell'){
                //     this.refs.frameAction.closeModal(100);
                //     var frameAction = this.refs.frameAction;
                //     frameAction.showModal({
                //         headerClass: () => <div className="text-white f-12 text-right"><i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}</div>,
                //         contentClass: detailSellModal,
                //         onClose: (result) => console.log('Second modal result = ', result),
                //         size: "mini"
                //     });
                // }else if (this.props.tipe === 'amend'){
                //     this.refs.frameAction.closeModal(100);
                //     var frameAction = this.refs.frameAction;
                //     frameAction.showModal({
                //         headerClass: () =>
                //             <div className="row col-sm-12 px-0 mx-0">
                //                 <div className="col-sm-6 px-0 mx-0 text-white f-14">Order Information</div>
                //                 <div className="col-sm-6 px-0 mx-0 text-white f-12 text-right">
                //                     <i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}
                //                 </div>
                //             </div>,
                //         contentClass: detailAmendModal,
                //         onClose: (result) => console.log('Second modal result = ', result),
                //         size: "small"
                //     });
                // } else if (this.props.tipe === 'withdraw'){
                //     this.refs.frameAction.closeModal(100);
                //     var frameAction = this.refs.frameAction;
                //     frameAction.showModal({
                //         // headerClass: () => 'Ini : '+this.props.tipe,
                //         contentClass: detailWithdrawModal,
                //         onClose: (result) => console.log('Second modal result = ', result),
                //         size: "mini"
                //     });
                // }else if (this.props.tipe === 'pinLanding'){
                //     this.refs.frameAction.closeModal(100);
                //     this.props.handleOnClick('landingPage','investmentBoard')
                //     $("#pinPortofolio").removeClass("d-block");
                //     $("#pinPortofolio").addClass("d-none");
                //     $("#detailPortofolio").addClass("d-block");
                //     $("#detailPortofolio").removeClass("d-none");

                //     $("#contentPinStockCash").removeClass("d-none");
                //     $("#contentPinStockCash").addClass("d-block");

                //     $("#verifyPinStockCash").removeClass("d-block");
                //     $("#verifyPinStockCash").addClass("d-none");

                //     $("#FundPin").addClass("d-none");
                //     $("#ContentFund").removeClass("d-none");
                //     $("#ContentFund").addClass("d-block");

                //     $("#HistoricalPin").addClass("d-none");
                //     $("#ContentHistorical").removeClass("d-none");
                //     $("#ContentHistorical").addClass("d-block");

                //     $("#AccountPin").addClass("d-none");
                //     $("#ContentAccount").removeClass("d-none");
                //     $("#ContentAccount").addClass("d-block");

                //     $("#orderListPin").addClass("d-none");
                //     $("#orderListPinHist").addClass("d-none");
                //     $(".contentOrderList").removeClass("d-none");
                //     $(".contentOrderList").add("d-block");
                    
                //     $("#TradePLPIN").addClass("d-none");
                //     $("#ContentTradePL").removeClass("d-none");
                //     $("#ContentTradePL").addClass("d-block");
                    
                // }else if (this.props.tipe === 'fundtransfer'){
                //     this.refs.frameAction.closeModal(100);
                //     this.props.handleStatusAlert3('confirm',this.props.statusAlertC,'Are you sure?', 'landingPage#sendFundTransfer#none')

                // } else {
                    this.refs.frameAction.closeModal(100);
                    var visible = false;
                    var value = "";
                    this.setState({ visible });
                    this.pin.clear();
                    this.setState({ value });
                // }
            } else{
                this.props.handleOnClick('verifyPin',"checkPin", this.state.value)
                var visible = true;
                this.setState({ visible });
                var isEmpty = false;
                this.setState({ isEmpty });
            }
        }else{
            this.setState({ isEmpty: true });
            this.setState({ visible: false });
        }
        
    };

    onClickCloseAlert = (e) => {
        this.props.handleOnClick('verifyPin','closeWrongAlert', 'checkPin')
        var visible = false;
        var value = "";
        this.setState({ visible });
        this.pin.clear();
        this.setState({ value });
    };

    onClickCloseFullFill = (e) => {
        this.setState({ isEmpty: false });
        this.pin.clear();
    }

    forgotPIN = (e) => {
        var frameAction = this.refs.frameAction;
        let { lang='eng' } = this.props;
        frameAction.showModal({
            headerClass: () => <div className="text-right">
                    <i className="icofont icofont-close text-icofont-close text-border click-pointer" 
                        onClick={() => frameAction.closeModal()}></i>
                </div>,
            contentClass: ForgotPINModal,
            contentProps: { lang },
            size: "mini",
        });
    }

    componentDidMount(){
        $(".pincode-input-text").on('keypress',function(e) {
            if(e.which === 13) {
                $("#pin-click-verify").click();
            }
        });
        if(this.props.verifyPinStatus === true){
            $("#pin-click-verify").click();
        }
    }

    render(){
        const {value, isEmpty} = this.state;
        let { pos, lang='eng', tipe='default', refreshPin, handleOnClick, checkPinErrState, checkPinErrReason } = this.props;
        let jClassNameBtn = {
            'buy': 'btn-danger', 
            'sell': 'btn-success',
            'amend': 'btn-primary', 
            'withdraw': 'btn-brown',
            'default': 'btn-danger'
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <div className="text-white f-12">
                    <div className="text-center align-self-center align-middle">
                        <div className="d-border-bold img-round-icon">
                            <i className="icofont icofont-lock icofont-4x"></i>
                        </div>
                    </div>
                    <div className="form-group text-center pt-5">
                        <label className="col-sm-12 px-5 py-2 col-form-label f-14 font-weight-bold">
                            {lang === 'eng' ? 'Please insert 6 digit PIN Security' : 'Silahkan masukan 6 digit PIN Anda'}
                        </label>
                        {/*<label className="col-sm-12 px-5 py-2 col-form-label">Please fullfill with 6 digit security*/}
                            {/*PIN to verify your transaction</label>*/}
                    </div>
                    <div className="form-group mb-0">
                        <PinInput
                            inputStyle={{"color":/*cssmode == 'night' ? '#FFFFFF':*/'#999999', "border":"#565252 1px solid","border-radius":"10%","width":"15.25%"}}
                            inputFocusStyle={{"color":/*cssmode == 'night' ? '#FFFFFF':*/'#999999', "border":"#065A96 1px solid", "border-radius":"10%","width":"15.25%"}}
                            length={6}
                            focus
                            secret
                            ref={p => (this.pin = p)}
                            type="numeric"
                            onComplete={this.onClickSubmit}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group mx-0 my-0 py-2">
                        <label className="col-sm-12 my-0 py-0 px-1 col-form-label">
                            {lang === 'eng' ? 'Forgot your PIN?' : 'Lupa Password?'}
                            <span className="click-pointer btn btn-link text-primary" onClick={this.forgotPIN}> 
                                {lang === 'eng' ? 'Click here' : 'Klik disini'}
                            </span>
                        </label>
                    </div>

                    <div className="form-group pt-1 pb-1 mb-0 pb-0">
                        <div className="justify-content-center align-items-center d-flex py-0">
                            <button id="pin-click-verify" type="submit" 
                                className={`btn ${jClassNameBtn[tipe]} form-control py-0`}
                                onClick={this.onClickSubmit}
                            >
                                {lang === 'eng' ? 'Submit' : 'Kirimkan'}
                            </button>
                        </div>
                    </div>
                    <div className="form-group text-center f-12 mb-0 mt-2">
                        <span className="click-pointer btn btn-link text-primary text-underline" 
                            onClick={() => {refreshPin ? window.location.reload() : handleOnClick('verifyPin','cancelPin', false)}}
                        > 
                            {refreshPin ?
                                (lang === 'eng' ? ' Logout' : ' Keluar') 
                            : 
                                (lang === 'eng' ? ' Cancel' : ' Batal')
                            } 
                        </span>
                    </div>

                    <div className={`col-sm-12 text-center bg-danger ${checkPinErrState ? "fade-in" : "fade-out"}`}>
                        <div className={/*cssmode == 'night'? */"px-1 py-2 text-white" /*: "px-2 py-4 text-black"*/}>
                            <i className="click-pointer icofont icofont-close pull-right pt-1" onClick={this.onClickCloseAlert}></i>
                                {checkPinErrReason} 
                        </div>
                    </div>
                    <div className={`col-sm-12 text-center bg-info ${isEmpty ? "fade-in" : "fade-out"}`}>
                        <div className={/*cssmode == 'night'? */"px-1 py-2 text-white" /*: "px-2 py-4 text-black"*/}>
                            <i className="click-pointer icofont icofont-close pull-right pt-1" onClick={this.onClickCloseFullFill}></i>
                                {lang === 'eng' ? 'Please fullfil pin number.' : 'Silahkan lengkapi Pin Anda.'}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//************************** Context Connector **************************
const VerifyPIN = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        verifyPinStatus:vars.verifyPinStatus,
        checkPinErrState:vars.checkPinErrState,
        checkPinErrReason:vars.checkPinErrReason,
        statusAlertC:vars.statusAlertC,
        refreshPin: vars.refreshPin,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleOnClick", "handleStatusAlert3"] 
)(VerifyPIN_Base)

export default VerifyPIN;