import React from 'react';

import { AppFrameAction } from '../../appframe';
import { VerifyPIN } from './index';

class InputPin extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = { }
    }   

    buttonClickPin = (e) => {
        let { pos, lang='eng', onClose } = this.props;
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"></div>, 
            size: 'mini',
            contentClass: VerifyPIN,
            contentProps: { lang },
            onClose: () => onClose()
        })
    }

    render(){
        let { pos, lang='eng' } = this.props;
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <div className={`text-white f-12 ${(pos == "portofolio") ? 'p-pinportofolio' : 'p-pinlanding' }`} style={{ paddingTop: "60px"}}>
                    <div className="card-334 pt-5 mt-5">
                        <div className="text-center align-self-center align-middle mb-3">
                            <div className="d-border-bold img-round-icon">
                                <i className="icofont icofont-lock icofont-4x"></i>
                            </div>
                        </div>
                        <div className="form-group text-center pt-2 mb-3">
                            <label className="col-sm-12 px-5 py-1 col-form-label f-16 font-weight-bold mb-2">
                                {lang === 'eng' ? 'PLEASE INPUT PIN' : 'Silahkan Masukkan Pin Anda'}
                            </label>
                            <label className="col-sm-12 px-5 py-1 col-form-label">
                                &nbsp;
                            </label>
                        </div>
                        <div className="text-center">
                            <button
                                onClick={() => this.buttonClickPin()}
                                id="pin-click" type="submit" className={'btn btn-danger form-control py-0 col-md-3'}
                            >
                                {lang === 'eng' ? 'INPUT PIN' : 'MASUKAN PIN'}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default InputPin;