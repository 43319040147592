import React from "react";
import { AppFrameAction } from "./../appframe";
import { ContextConnector } from '../appcontext.js';
import { BIPSAppContext } from "../AppData";

import SweetAlert from "react-bootstrap-sweetalert";

// modalAlert without confirm button
class ModalAlertN_Base extends React.PureComponent{    
    render(){
        let { statusAlertN, handleStatusAlert3, msgAlert3 } = this.props;
        return(
            <>   
                <AppFrameAction ref="frameAction" />
                <SweetAlert
                    show={statusAlertN}
                    title={<span className="text-white">Info</span>}
                    text="SweetAlert in React"
                    showCancelButton
                    info
                    style={{'color':'var(--text-white)',}}
                    confirmBtnCssClass={"btn btn-sm btn-popup bg-gray-tradding border-gray-tradding"}
                    customClass={"bg-dark-grey f-12"}
                    onConfirm={() => handleStatusAlert3('noConfirm',statusAlertN)}
                    onCancel={() => handleStatusAlert3('noConfirm',statusAlertN)}                        
                    onEscapeKey={() => handleStatusAlert3('noConfirm',statusAlertN)}
                    onOutsideClick={() => handleStatusAlert3('noConfirm',statusAlertN)}
                >
                    <div className="text-white">{msgAlert3}</div>
                </SweetAlert>
            </>
        );
    }

}

// modalAlert with confirm button
class ModalAlertC_Base extends React.PureComponent{    
    render(){
        let data = this.props.ieudata
        // console.log('dataM', data)
        // myWatclist#confirmSave#yes
        if(data !== undefined){
            let dataM = data.split('#')
            
            return(
                <>   
                    <AppFrameAction ref="frameAction" />
                    <SweetAlert
                        show={this.props.statusAlertC}
                        warning
                        title={<span className={`text-white ${dataM[1] === 'confirmSave'? 'f-16' : ''}`}>{dataM[1] === 'confirmSave' ?'Save changes before quiting?' : this.props.msgAlert3 }</span>}
                        style={{'color':'var(--text-white)',}}
                        customClass={"bg-dark-grey f-12"}                        
                        customButtons={
                            <React.Fragment>
                                <button className={"btn btn-sa btn-trans btn-sm btn-popup"}
                                        onClick={() => this.props.handleStatusAlert3('confirm',this.props.statusAlertC)}>
                                    Cancel
                                </button>   
                            {/* 'confirmSave' => wathclist, 'saveAppearance'=>setting */}
                                {dataM[1] === 'confirmSave' || dataM[1] === "saveAppearance" || dataM[1] === "saveSecurity" ?
                                    <>&nbsp;
                                        <button className={"btn btn-sa btn-sm btn-popup btn-danger border-gray-tradding"}
                                                onClick={() => this.props.handleStatusAlert3('confirm',this.props.statusAlertC,"noSave")}>
                                            &nbsp;&nbsp;No&nbsp;&nbsp;
                                        </button>
                                        &nbsp;
                                        <button id="save" autoFocus={true} className={"btn btn-sa btn-sm btn-popup btn-info border-gray-tradding"}
                                                onClick={()=>this.props.handleOnClick(dataM[0],dataM[1],dataM[2])}>
                                            &nbsp;&nbsp;Yes&nbsp;&nbsp;
                                        </button>
                                    </>
                                    : <>&nbsp;
                                        {dataM[1] === "sendFundTransfer" ?
                                            <button autoFocus={true} className={"btn btn-sa btn-sm btn-popup btn-info border-gray-tradding"}
                                                    onClick={()=>this.props.handleOnClick(dataM[0],dataM[1],dataM[2])}>
                                                &nbsp;&nbsp;Yes&nbsp;&nbsp;
                                            </button>
                                            :
                                            <button autoFocus={true} className={"btn btn-sa btn-sm btn-popup btn-danger border-gray-tradding"}
                                                    onClick={()=>this.props.handleOnClick(dataM[0],dataM[1],dataM[2])}>
                                                &nbsp;&nbsp;Yes, delete it!&nbsp;&nbsp;
                                            </button>
                                        }
                                    </>
                                }
                            </React.Fragment>
                        }
                        >
                        {dataM[1] === 'confirmSave' ? '' :
                            <span className={"text-white"}>
                                You will not be able to undo this action!
                            </span>
                        }
                    </SweetAlert>
                </>
            );
        }else{return''}
    }
}

// ModalAlert
class ModalAlert_Base extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
        this.props.handleAlertAutomatic();
    }

    render() {
        return (<>
            <AppFrameAction ref="frameAction" />
            <div className="text-white f-12 text-center bg-dark-grey">
                <div className="form-group">
                    <p className="col-sm-12 px-5 py-2 col-form-label">
                        Anda memiliki beberapa <b><i>Order Otomatis</i></b> yang aktif silahkan diperiksa.
                        <br/><br/>
                            You have some active <b><i>Automatic Order</i></b> please check.
                        
                    </p>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary btn-sm col-sm-3" onClick={this.closeClick}>OK</button>
            </div>
        </>);
    }
}

//************************** Context Connector **************************
const ModalAlert = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        handleAlertAutomatic: () => {actions.sendAction('handleAlertAutomatic')}
    }),
    ["handleAlertAutomatic"]
)(ModalAlert_Base)

const ModalAlertN = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        msgAlert3:vars.msgAlert3,
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ['handleStatusAlert3', ]
)(ModalAlertN_Base)

const ModalAlertC = ContextConnector(BIPSAppContext,
    (vars, actions)=>({      
        statusAlertC:vars.statusAlertC,
        msgAlert3:vars.msgAlert3,
        ieudata:vars.ieudata,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ['handleStatusAlert3', 'handleOnClick', ]
)(ModalAlertC_Base)

export default ModalAlertN;
export {ModalAlert, ModalAlertC};