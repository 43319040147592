import React, { memo } from 'react';

import AtmMethod from './atm_method';
import InternetBankingMethod from './internet_banking_method';
import MobileBankingMethod from './mobile_banking_method';
import TellerMethod from './teller_method';

const EsbnPaymentMethod = memo(({ method, closeModal }) => {
   return (<>
      {  method === "atm" ?
            <AtmMethod />
         : method === "internet_banking" ?
            <InternetBankingMethod />
         : method === "mobile_banking" ?
            <MobileBankingMethod />
         : 
            <TellerMethod />
      }
      <div className="text-center">
         <button className="btn btn-primary btn-sm col-sm-3" 
            onClick={() => closeModal()}
         >
            OK
         </button>
      </div>
   </>)
});

export default EsbnPaymentMethod;