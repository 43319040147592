import React, { memo } from 'react';
import { Progress } from 'semantic-ui-react';

const PageLoading = memo(({ isLoading, lang = "eng"}) => {

   return (
      <div className={`m-5 p-5 ${isLoading ? "d-block" : "d-none"}`}>
         <div className="w-100 text-center">
            <div className="ui big active centered inline loader mb-3"></div>
            <p className="f-13 text-white">
               {lang === "eng" ?
                  "Please wait a few moments ..."
               : 
                  "Mohon menunggu ..."
               }
            </p>
         </div>
         <Progress className="w-100 my-0" percent={100} active/>
      </div>
   )
})

export default PageLoading;