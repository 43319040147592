import React from 'react';
import { Base64 } from 'js-base64';
import { ContextProvider} from './appcontext.js';
import { WSConnectionAction } from './appnetwork.js';
import { AppFrameAction } from './appframe.js';

import { fmsg, initSubscriptionFlags, baseAPI, setTempData, CheckRespPin, GetTimeForDateTicker, timeCall, dxInfo, mathCall, strCall } from './utils';
import * as fa from './utils/setDataFormat.js';

import Flash from './flash';
import Bus from './bus';

import { dummy } from './utils/dummy.js'

import {dConfig, fd, GetInitialRow, GetRowChange} from './appConfig.js';
import * as fg from './appFunctions.js';
import $ from 'jquery';
import moment from 'moment';
window.$ = window.jQuery = $;

window.flash = (message, type) => Bus.emit('flash', ({message, type}));

// initial configuration
const nor = dConfig.nor;
const init= dConfig.init;

function ConvertToRupiah(number, avg) {
  if(number == 0){
    return number
  }else if (number) {
      if( Array.from(number.toString())[0] !== '-'){
          var numberString = number.toString(),
          numberSplit = numberString.split('.'),
          mod = numberSplit[0].length%3,
          rupiah 	= numberSplit[0].substr(0, mod),
          thousand = numberSplit[0].substr(mod).match(/\d{1,3}/gi);
          
          if(thousand){
              var separator = mod ? ',' : '';
              rupiah += separator + thousand.join(',')
          }

          // khusus avg, dengan separator 4
          if(avg=='avg'){
            if(numberSplit[1].length == undefined)
              return rupiah + '.'+'0000'
            if(numberSplit[1].length == 1)
              return rupiah + '.' + numberSplit[1]+'000'
            if(numberSplit[1].length == 2)
              return rupiah + '.' + numberSplit[1]+'00'
            if(numberSplit[1].length == 3)
              return rupiah + '.' + numberSplit[1]+'0'
            if(numberSplit[1].length == 4)
              return rupiah + '.' + numberSplit[1]
          }else{
            return numberSplit[1] = undefined ?  rupiah  : (numberSplit[1] > 0 ? rupiah + '.' + numberSplit[1] : rupiah)
              // return hasil
          }
      } else {
          var numberString = number.toString(),
          numberSplit = numberString.split('.'),
          cutMinus = numberSplit[0].substr(1,numberSplit[0].length),
          mod = cutMinus.length%3,
          rupiah 	= cutMinus.substr(0, mod),
          thousand = cutMinus.substr(mod).match(/\d{1,3}/gi);
          
          if(thousand){
              var separator = mod ? ',' : '';
              rupiah += separator + thousand.join(',')
          }

          var hasil = numberSplit[1] = undefined ? rupiah  : (numberSplit[1] > 0 ? '-' + rupiah + '.' + numberSplit[1] : '-'+ rupiah)
          return hasil
      }
  }
};
// change the rupiah unit (thousand, million, billion and Trillion)
function CurrencyModifier(type, number) {
  let val = Number(number)
  // to thousand : divider = 1,000
  if(type === 'thousand'){
    return (val/1000).toFixed(0)
  }
  // to million : divider = 1,000,000
  else if(type === 'million'){
    return (val/1000000).toFixed(2)
  }
  // to billion : divider = 1,000,000,000
  else if(type === 'billion'){
    return (val/1000000000).toFixed(2) 
  }
  // to trillion : diveder = 1,000,000,000,000
  else if(type === 'trillion'){    
    return (val/1000000000000).toFixed(2)
  }
  // to (number.length > 9 ? "thousand" : number)
  else if(type === 'ifThousandMilion'){
    if(typeof(number) == 'string'){
      if(number.split(".")[0].length > 12){
        return ConvertToRupiah((val/1000000).toFixed(0))+" M"
      }else if(number.split(".")[0].length > 9 && number.split(".")[0].length < 13){
        return ConvertToRupiah((val/1000).toFixed(0))+" T"
      }else{
        return ConvertToRupiah(val)
      }
    }else{
      if(number.length > 12){
        return ConvertToRupiah((val/1000000).toFixed(0))+" M"
      }else if(number.length > 9 && number.length < 13){
        return ConvertToRupiah((val/1000).toFixed(0))+" T"
      }else{
        return ConvertToRupiah(val)
      }
    }
  }
}

// SET CSS COLOR TEXT 
function ColorLabel(change){
  if(change < 0){
    return " text-danger";
  } else if (change > 0) {
    return " text-success";
  } else {
    return " text-warning";
  }
}
// SET ICON 
function IconChange(change, type){
  if(change < 0){
    return "icofont icofont-caret-down";
  } else if (change > 0) {
      return "icofont icofont-caret-up";
  } else {
    if(type === "minus"){
      return "icofont icofont-caret-minus";
    }else{
      return "";
    }
  }
}

function timeModifier(data, type){
  let result
  let ndata = data.split(" ")
  if(type === "ol"){
      let ndate = ndata[0].substr(0,4)+"-"+ndata[0].substr(4,2)+"-"+ndata[0].substr(6,2)
      let ntime = ndata[1].substr(0,8)
      result = ndate+" "+ntime
  }else if(type === "norder"){
    let ndate = ndata[0].substr(0,4)+"-"+ndata[0].substr(4,2)+"-"+ndata[0].substr(6,2)
    let ntime = ndata[1].substr(0,2)+":"+ndata[1].substr(2,2)+":"+ndata[1].substr(4,2)
    result = ndate+" "+ntime
  }else if(type === "chart"){
    if(data){
        let d = new Date();
        let h = parseInt(data.substring(0,2));
        d.setHours(h);
        d.setMinutes(data.substring(3,5));
        d.setSeconds(data.substring(6,8));
        result = parseInt(d.getTime()) + 25200000;
    }
  }else if(type === 'reqDate'){ /*03/11/2020 => 2020-11-03 */
    let newD = data.split("/")
    result = newD[2]+"-"+newD[1]+"-"+newD[0];    
  }else if(type === 'v0'){ /*2020-11-03 => 03/11/2020 */
    let newD = data.split("-")
    result = newD[2]+"/"+newD[1]+"/"+newD[0]
  }else if(type === "vt-1") {/* 111545 => 11:15:45 */
    let ndata = data.length === 5 ? `0${data}` : data;
    result = ndata.substring(0, 2)+":"+ndata.substring(2, 4)+":"+ndata.substring(4, 6);
  }else if(type === "vd-1") {/* 20210115 => 15-01-2021*/
    result = data.substring(6, 8)+"-"+data.substring(4, 6)+"-"+data.substring(0, 4)
  }
  return result
}

// format waktu untuk Order
function getTime2(type){
  var today = new Date();
  var HH = today.getHours();
  var MM = today.getMinutes();
  var ss = today.getSeconds();
  var SSS = today.getMilliseconds();
  HH = HH < 10 ? "0"+HH : HH;
  MM = MM < 10 ? "0"+MM : MM;
  ss = ss < 10 ? "0"+ss : ss;
  SSS = SSS < 10 ? "00"+SSS : (SSS < 100 ? "0"+SSS : SSS);
  today = HH+''+MM+''+ss+''+SSS;
  var ord = ss+''+SSS;
  if(type === "time"){
      return parseInt(today);
  }else{ return parseInt(ord);}
}

// format waktu untuk Order
function getDate2(){
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1;
  var yyyy = today.getFullYear();
  dd = dd < 10 ? "0"+dd : dd;
  mm = mm < 10 ? "0"+mm : mm;
  today = yyyy+''+mm+''+dd;
  return parseInt(today);
}

// convert to Time : 15:51:56 
function ConvertToTime(number) {
   if(number){
      var numberRef = number.toString()
      var numberLength = numberRef.length;
      if (numberLength > 5 || number.length > 5){
          var hours =numberRef.substring(0, 2),
              minute = numberRef.substring(2, 4),
              second = numberRef.substring(4, 6),
              time = hours + ':' + minute + ':' + second
          return time
      } else {return ''}
  }
}

// convert to Date and Time : 06/12/2019 15:51:56 
function ConvertToDateTime(number, type) {    
  if(number){
      if(type === "v1"){
          let dT = number.split(" ")
          let d =dT[0].replace(/-/g,"/")
          let t = dT[1].substring(0, 8)
          return d+" "+t
      }
      // dari 06122019 155156 || 06122019 155156 jadi => 15:51:56 WIB | 06/12/2019
      else if(type === "v2"){
        let dT = number.includes("-") ? number.split("-") : number.split(" ");
          let d = dT[1].length === 5 ? "0"+ dT[1].toString() : dT[1].toString();
          let t = dT[0].toString();
          let time = d.substring(0, 2)+":"+d.substring(2, 4)+":"+d.substring(4, 6)
          let date = t.substring(6, 8)+"/"+t.substring(4, 6)+"/"+t.substring(0, 4)
          let newDT = time + " WIB | " + date
          return newDT;
      }
      // dari 06122019 155156 || 06122019 155156 jadi => 06/12/2019 15:51:56 
      else{
          let dT = number.includes("-") ? number.split("-") : number.split(" ");
          let d =  dT[1].length === 5 ? "0"+ dT[1].toString() : dT[1].toString();;
          let t = dT[0].toString();
          if (d.length > 4 && t.length > 5){
              let time = d.substring(0, 2)+":"+d.substring(2, 4)+":"+d.substring(4, 6)
              let date = t.substring(6, 8)+"/"+t.substring(4, 6)+"/"+t.substring(0, 4)
              let newDT = date+" "+time
              return newDT;
          } else {
              return ''
          }
      }
  }
}

// convert Lot To Share
function LotToShare(number) {
  if(number || number == 0){
      let numberRef =Number(number)
      let multiplier = 100;
      let share= numberRef*multiplier
      return share
  }
}

// convert Share to Lot
function ShareToLot(number) {
  if(number != 0){
      // var numberRef = parseInt(number)
      var divider = 100;
      var lot = number/divider;
      return lot;
  }else{
    return number;
  }
}

// get group list for listAmendGroup
function GroupListAmend(type, code, data){
  if(type === 'amendListGroup'){
    var x = Object.keys(data)
    var z = Object.values(data)
    // for code array and data array
    if(typeof(code) !== 'string'){
    var y = []
    for(let j=0; j<code.length; j++){
      for(let i=0; i<x.length; i++){
        if(code[j] === x[i])
        y.push({code : x[i]+' - '+z[i]})
      }
    }
    return y
    }else {
      let y = ''
      for(let j=0; j<code.length; j++){
        for(let i=0; i<x.length; i++){
          if(code === x[i])
          y={code : x[i]+' - '+z[i]}
        }
      }
      return y
    }
  }
}

// get group List
function GroupList(type, data){
  var newData = []
  // input:[[array[3],array[3]] >>>> output : [code1, code2, code3,....,codeN]
  if(type === 'myWatchlistCode'){
    for(var i=0; i<data.length; i++){
      var code = data[i].stocks
        newData.push(code)
      }      
    return [...new Set([].concat(...newData))];
  }
  else if(type === 'listSideBar'){
    for(let i=0; i<data.length; i++){
      var value = data[i].group_name;
      var key= "Group"+data[i].group_name;
      var text = "Group "+data[i].group_name
      newData.push({key, value, text})
    }
    return newData
  }
  else if(type === 'amendGroup'){    
    for(let i=0; i<data.length; i++){
      var groupname = "Group "+data[i].group_name,
      totalmember = data[i].stocks.length,
      action = '';
      newData.push({groupname, totalmember,action})
    }
    return newData
  }
  else if(type === 'amendListGroup'){
    for(let i=0; i<data.length; i++){
      var code = data[i]+'-';
      newData.push({code})
    }
    return newData
  }
  else if(type === 'listStock'){
    for (let i = 0; i < data.length; i++) {
      var value = data[i];
      var label = data[i];
      newData.push({ value, label });
    }
    return newData;
  }
  else if(type === 'addGroup'){
    for (var i = 0; i < data.length; i++) {
      var x = data[i].code
      var code = x.split(' - ');
      var sCode = code[0];
      newData.push(sCode);
    }
    return newData;
  }
  else if(type === 'dataChart'){
    for (var i = 0; i < data.length; i++) {
      newData.push(data[i]);
    }
    return newData;
  }
  else if(type === 'search'){
    var x = Object.keys(data);
    var y = Object.values(data);
    for (var i = 0; i < x.length; i++) {
      var value = x[i];
      var label = x[i]+'-'+y[i];
      newData.push({ value, label });
    }
    return newData;
  }
  else if(type === 'groupNames'){    
    for(var i=0; i<data.length; i++){
      var groupname = data[i].group_name;
      newData.push(groupname)
    }
    return newData
  }
  else if(type.includes('options')){
    let x = Object.keys(data);
    let y = Object.values(data);
    let desc = type.split("|")[1]
    for (let i = 0; i < x.length; i++) {
      let value = x[i];
      let code = x[i];
      let saham = y[i];
      newData.push({ desc, value, code, saham });
    }
    return newData;
  }
  // options for analitic
  else{
    var x = Object.keys(type);
    var y = Object.values(type);
    for (var i = 0; i < x.length; i++) {
      var value = x[i];
      var code = x[i];
      var saham = y[i];
      var id = data;
      newData.push({ value, code, saham, id });
    }
    return newData;
  }
}

function groupSearch(watchlistGroup, group){
  if(watchlistGroup){
    var listGroup = Object.values(watchlistGroup)
    var z =[]
    listGroup.forEach(list =>{ 
      if(list.group_name === group){
        z=list.stocks  
        }
      })
      return z
  }
}

//get code or index from data array 
function searchCode(type,code,data){
  // menampilkan data selain dari code yang di berikan
  if(type === 'arrayInArray'){
    for(var i=0; i<code.length; i++){
      let result = data.findIndex(element => element === code[i]);
      data.splice(result,1)
    }
    return data
  }
  else if(type === 'index'){
    //get index from data object array and code
    for(let i=0; i<data.length; i++){
      if(data[i].value === code)
      return i
    }
  }
  // untuk mengecek index pada order list
  else if(type === 'index3'){
    //get index from data object array and code
    for(var i=0; i<data.length; i++){
      if(data[i].order === code)
      return i
    }
  }
  // get index from data array and code
  else if(type === 'index1'){
    for(var j=0; j<data.length; j++){
      if(data[j] === code){
        return j
      }
    }    
  }
  // get Code from data object array and code 
  else if(type === 'code'){
    for (var i =0; i<data.length; i++){
      if(data[i].code === code)
      return data[i]
    }
  }
  //get a object from data object array and code
  else if(type === 'selected'){
    for(var i=0; i<data.length; i++){
      if(data[i].value === code)
      return [data[i]]
    }
  }
   //get a object from data object array and code
  if(type === 'selectedSearch'){
    for(var i=0; i<data.length; i++){
      if(data[i].code.split(' - ')[0] == code)
      return [data[i]]
    }
  }
  // get code from data array and code
  else if(type === 'code1'){
    for(var j=0; j<data.length; j++){
      if(data[j] === code){
        return data[j]
      }
    }    
  }else if(type === 'code2'){
    // get code from data object array and object.code
    for (var i =0; i<data.length; i++){
      if(data[i].code === code.code)
      return data[i]
    }
  }else if(type === 'code3'){
    // get code from data object array and code
    for (var i =0; i<data.length; i++){
      if(Number(data[i].bidOffer) === Number(code))
        return data[i].bidOffer
    }
  }else if(type === 'searchName'){
    var x = Object.keys(data)
    var z = Object.values(data)
    for (var i =0; i<x.length; i++){
      if(x[i] === code)
      return z[i]
    }
  }else if(type === 'code4'){
    // get code from data object array and code
    for (var i =0; i<data.length; i++){
      if(data[i].order === code)
        return data[i].order
    }
  }
  // get code from data object array and code array
  else if(type === 'code5'){
    for (var i =0; i<data.length; i++){
      if(data[i].order === code)
        return data[i].order
    }
  }
}

// get start and finish date for chart
function GetRangeDate(type,data){
  if(data.length){
    if(type === 'start'){
      var start = data[0]
      return start[0]
    }else if(type === 'finish'){
      var finish = data[data.length-1]
      return finish[0]
    }
  }
}

// get sum data in array with 2 parameter 
// 1. data 2.type with 2 options : a. array, b.object-<key name>
function sumDataArray(data,type){
  var total=0
  if(type ==='array'){
    for(var i = 0; i <data.length; i++){
      total += !isNaN(data[i].noBidOffer) && Number(data[i].noBidOffer);
    }    return total
  }else if(type.includes('object-')){
    var code = type.split('-')[1];
    for(var i = 0; i <data.length; i++){
      total += !isNaN(data[i][code]) && Number(data[i][code]);
    }
  }else if(type.includes('objectplus1-')){
    var code = type.split('-')[1];
    for(var i = 0; i <data.length; i++){
      var x = data[i][code].split("|")[0]
      total += !isNaN(x) && Number(x);
    }
  }else if(type.includes('objectplus2-')){
    let code = type.split('-')[1];
    let newData = data.filter((d)=> d.code !== "-")
    for(var i = 0; i <newData.length; i++){
      let y = newData[i][code].split("|")[1]
      total += !isNaN(y) && Number(y);
    }
  }
  return total
}

// calculate the average price
// a = val; b=vol
const AveragePrice = (a, b) => {
  var aRef = Number(a); var bRef = Number(b)
    var result = (aRef / bRef);
    return (result).toFixed(4)
}

const CalculatorS =(type, a, b, c)=>{
  let newData =[]
  // menjumlahkan data dengan ketentuan 1 untuk Regular Acount
  if(type === 'addR'){
    if(Number(a) < 200){
      return Number(a)+1          
    }else if(Number(a) > 199 && Number(a) < 500){
      return Number(a)+2         
    }else if(Number(a) > 499 && Number(a) < 2000){
      return Number(a)+5         
    }else if(Number(a) > 1999 && Number(a) < 5000){
      return Number(a)+10         
    }else if(Number(a) > 4999 ){
      return Number(a)+25       
    }
  }
  // menjumlahkan data dengan ketentuan 1 untuk Margin Acount
  else if(type === 'addM'){
    return Number(a)+1 
  }
  // mengurangi data dengan ketentuan 1 Regular Acount
  else  if(type === 'subR'){
    if(Number(a) < 201){
      return Number(a)-1          
    }else if(Number(a) > 200 && Number(a) < 501){
      return Number(a)-2         
    }else if(Number(a) > 500 && Number(a) < 2001){
      return Number(a)-5         
    }else if(Number(a) > 2000 && Number(a) < 5001){
      return Number(a)-10         
    }else if(Number(a) > 5000 ){
      return Number(a)-25       
    }
  }
  // mengurangi data dengan ketentuan 1 untuk Margin Acount
  else if(type === 'subM'){
    return Number(a)-1 
  }
  // menentukkan nilai maksimal dari sebuah array
  else if(type === 'max'){    
    for(var i=0; i < a.length; i++){
      newData.push(parseInt(a[i]))
    }
    return Math.max(...newData)
  }  
  // menentukkan nilai minimal dari sebuah array
  else if(type === 'min'){
    for(let i=0; i < a.length; i++){
      newData.push(parseInt(a[i]))
    }
    return Math.min(...newData)
  }
  // menentukan avgPrice (Val/vol) => a:lastprice, b:vol(shares), c:averge
  else if(type === 'asp'){
    let na = Number(a); let nb = Number(b); let nc = Number(c);
    let stockval = na*nb;
    let pl = nb === 0 ? '0' : ((na-nc)*nb).toFixed(0);
    let perPl =  nb === 0 ? '0' : ((((na-nc)/nc)*100)).toFixed(2);
    let npl = pl+"|"+perPl;
    let valFormSell = (nb * nc).toFixed(2);
    let plFormSell = ((na * nb) - valFormSell).toFixed(2);
    let perPlFormSell = ((plFormSell / valFormSell)*100).toFixed(2);
    return {stockval, npl, pl, perPl, valFormSell, plFormSell, perPlFormSell};
  }
}

const Multiplication=(a,b)=>{return a*b}

function GenerateId(type){
  switch(type){
    case "idST" : return "ST88" + getTime2('io') + '0';
    case "idFT" : return "FT88" + getTime2('io') + '0';
    case "idOA" : return "OA88" + getTime2('io') + '0';
  }
}
function GenerateTime(type, format){
  let today = new Date();
  let dd = today.getDate(), mm = today.getMonth()+1, yyyy = today.getFullYear(),
  HH = today.getHours(), MM = today.getMinutes(), ss = today.getSeconds(), SSS = today.getMilliseconds();
  // date
  if(dd<10){dd='0'+dd}
  if(mm<10){mm='0'+mm}
  // time
  if(HH<10){ HH='0'+HH; }
  if(MM<10) { MM='0'+MM; }
  if(ss<10) { ss='0'+ss; }
  if(SSS<10) {  SSS='00'+SSS; }  
  if(type === "datetime"){
    // "yyyy-mm-hh  hh:mm:ss"
    if(format === "YYYY-MM-DD hh:mm:ss"){
      return yyyy+'-'+mm+'-'+dd+' '+HH+':'+MM+':'+ss;
    }
  }else if(type === "date"){
    if(format === "YYYY-MM-DD"){
      return yyyy+'-'+mm+'-'+dd
    }
  }else if(type === "year"){
    return yyyy
  }
}

function OptionPriceFiltered(data, refData){
  if(refData.lBid > 0 && refData.lOffer > 0){
      return data;
  }else if(refData.lBid > 0){
      return data.filter(d => !d.value.includes("BO"))
  }else if(refData.lOffer > 0){
      return data.filter(d => !d.value.includes("BB"))
  }else {
      return data.filter(d => !d.value.includes("BO") && !d.value.includes("BB"))
  }
}

//============= Format Data =============
function GetSidebar(data, refData) {
  let ndata = data.filter(d => d!= "\xa0"), newData = [];
  ndata.map(d => {
    let rd = refData.find( dt => dt.stock_code === d );
    let nlastprice = rd == undefined ? 0 : (Number(rd.close_price) == 0 ? Number(rd.prev_price) : Number(rd.close_price));
    let nchange = nlastprice == 0 ? 0 : (nlastprice - Number(rd.prev_price)).toFixed(0);
    let nperChange = nlastprice == 0 ? 0 : ((nchange/Number(rd.prev_price))*100).toFixed(2);
    newData.push({ name: d, fullname: "\xa0", last: nlastprice, change: nchange,  percent: nperChange, own: "\xa0", ownPercent: "\xa0", popUp: "\xa0"})
  })
  return GetInitialRow(7, fd.sidebarData, newData);
}
//>>>>>>> Header 1 : My Acount <<<<<<<
// data Format for Portfolio in investment Board
function GetPortfolio(data){
  var z =[]
  for(var i=0; i<data.length; i++){
    var code = data[i].name, 
        avgprice =  data[i]["avg-buy-price"], 
        lastprice = "0", 
        lot = ShareToLot(data[i].quantity), 
        shares = data[i].quantity, 
        stockval = "0", 
        pl = "0"+ "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +"0",
        remark = "", 
        action= "";
    var portfolioFormat = {code,avgprice,lastprice,lot,shares,stockval,remark,pl,action}
    z.push(portfolioFormat)
    }
  return z
}
// getPortfolio from Stock Page
function GetPortfolio2(lastPortfolio, data, refData) {
  let z = [];
  // get new Data portfolio  
  data.map((val, i)=>{
    let rd = refData.find( dt => dt.stock_code === data[i].stockcode );
    let nlastprice = rd == undefined ? '-' : Number(rd.close_price) == 0 ? Number(rd.prev_price) : Number(rd.close_price)
    let nstockval = nlastprice == "-" ? "0" : (Number(data[i].totalqty)*Number(nlastprice)).toFixed(0);
    let navgPrice = data[i].averageprice == undefined ? 0 : Number(data[i].averageprice).toFixed(4);
    let npl =  nlastprice == "-" ? "0" :((Number(nlastprice)-Number(navgPrice))*Number(data[i].totalqty)).toFixed(0)
    let nperPl =  nlastprice == "-" ? "0" :(((Number(nlastprice)-Number(navgPrice))/Number(navgPrice))*100).toFixed(2)
    let code = data[i].stockcode,
      avgprice = navgPrice,
      lastprice = nlastprice,
      lot = ShareToLot(data[i].totalqty),
      shares = Number(data[i].totalqty),
      stockval =nstockval,
      pl = npl + "|" + nperPl,
      notation= "",
      slot = ShareToLot(data[i].sellableqty),
      stockValR = (navgPrice*Number(data[i].totalqty)).toFixed(0),
      sshares = data[i].sellableqty,
      action = "";
    let portfolioFormat = { code, stockValR, avgprice, lastprice, lot, slot,sshares, shares, stockval, notation, pl, action };
    z.push(portfolioFormat);
  })
  return GetInitialRow(nor.portfolio, fd.portfolio, z);
}
// 2.Stock & Cash
function GetStockCash(lastStockcash, data, refData, haircut){
  let z =[]
  data.map((val, i)=>{
    let rd = refData.find( dt => dt.stock_code == data[i].stockcode );
    let nlastprice = rd == undefined ? '-' : Number(rd.close_price) == 0 ? Number(rd.prev_price) : Number(rd.close_price)
    let nhaircut = haircut.length > 0 ? haircut.find(hc => hc.stock_code == data[i].stockcode) : 0
    let ghaircut = nhaircut == undefined || nhaircut.haircut == null || nhaircut.haircut == 0 ? 0 : ShareToLot(Number(nhaircut.haircut));
    let nmktvalueR = nlastprice == "-" ? "0" : (Number(data[i].totalqty)*Number(nlastprice)).toFixed(0);
    let nlqValR =  (1-ghaircut)*Number(nmktvalueR)
    let codeR = data[i].stockcode , 
        avgpriceR = data[i].averageprice == undefined ? 0 : data[i].averageprice.toFixed(4), 
        lastpriceR = nlastprice, 
        plot = ShareToLot(data[i].totalqty), 
        pshares = data[i].totalqty, 
        mktvalueR = nmktvalueR, 
        plR = nlastprice == "-" ? "0" : ((Number(nlastprice)-Number(avgpriceR))*Number(data[i].totalqty)).toFixed(0), 
        persenR = nlastprice == "-" ? "0" : (((Number(nlastprice)-Number(avgpriceR))/Number(avgpriceR))*100).toFixed(2), 
        slot = ShareToLot(data[i].sellableqty), 
        sshares = data[i].sellableqty, 
        hc= (1-ghaircut),
        notationR= "",
        lqValR = nlqValR.toFixed(0), 
        stockValR = (Number(avgpriceR)*Number(data[i].totalqty)).toFixed(0);
      let stockCashFormat = {codeR, hc, avgpriceR,lastpriceR,plot,pshares,mktvalueR,notationR,plR,persenR,slot,sshares,lqValR,stockValR}
    z.push(stockCashFormat)
  })
  return GetInitialRow(nor.stockCash, fd.stockCash, z)
}
function GetStockCashInfo(data, balance){
  let cek = data.filter(d => d.codeR != "\xa0");
  let sumStockVal = cek.length > 0 ? (sumDataArray(data, 'object-stockValR')).toFixed(2) : 0;
  let liquidVal = cek.length > 0 ? (sumDataArray(data, 'object-lqValR')).toFixed(2) : 0;
  let mktVal = cek.length > 0 ? (sumDataArray(data, 'object-mktvalueR')).toFixed(2) : 0;
  // let additionalBl = 0;
  let clRatio = (-1*balance/liquidVal).toFixed(2) < 0 || liquidVal == 0 ? 0 : (-1*balance/liquidVal).toFixed(2);
  let pl = cek.length > 0 ? (sumDataArray(data, 'object-plR')).toFixed(2) : 0;
  // let pl2 = mktVal - sumStockVal;
  let plRatio = Number(mktVal) == 0 ? 0 : (((mktVal - sumStockVal)/mktVal)*100).toFixed(2);       
  
  return { liquidVal, mktVal, clRatio, pl, plRatio }
}
function GetAccountInfo(data){
  let cStatus = data.custstatus === "T" ? "Suspend" : data.custstatus === "A" ? "Active" : 
      data.custstatus === "B" ? "Suspend Buy" :  data.custstatus === "S" ? "Suspend Sell" : 
      data.custstatus === "M"  || data.custstatus === "W" ? "Trial" : data.custstatus
  let fStatus = data.feetype === "A" ? "Active" : data.feetype
  let kType = data.idcardtype === "K" ? "KTP" : data.idcardtype
  return {
    ai1:[
        {"label":"Account Name","value": data.custname},
        {"label":"KSEI A/C No","value":""},
        {"label":"Alt Code","value":data.custcode},
        {"label":"KSEI SID","value":data.sid},
        // {"label":"User ID","value":data.userid},
        // {"label":"A/C Status Name","value":cStatus},
        {"label":"A/C Status","value":data.custstatus},
        {"label":"ID Type","value":kType},
        {"label":"ID No","value":data.idcardno},
        {"label":"ID Expire Date","value":data.idexpiredate},
        {"label":"Dividend Tax","value":data.srebasetax},
        {"label":"Commission Rate","value":data.custfee},
        {"label": "Sales Commission", "value": data.custfeedealer},
      ],
    ai2:[
        // {"label":"Tax Rate","value":""},
        {"label":"Managing Branch","value":""},
        {"label":"Country","value":""},
        {"label":"Job","value":data.namaoccupation},
        {"label":"Opening Date","value":data.opendate},
        {"label":"Opening Branch","value":""},
        

        // {"label":"Bank Code","value":data.bncode},
        // {"label":"Branch","value":data.cbbranch},
        // {"label":"Bank Account No","value":data.cbaccno},
        // {"label":"Bank Account Name","value":data.cbaccname},
        // {"label":"ai2","value":""},
        // {"label":"-","value":""},
        
        {"label":"ai2","value":""},
        {"label":"RDI Bank","value":data.rdnbncode == null ? "" : data.rdnbncode},
        {"label":"RDI Branch","value":data.rdncbbranch == null ? "" : data.rdncbbranch},
        {"label":"RDI Account Number","value":data.rdncbaccno},
        {"label":"RDI Account Name","value":data.rdncbaccname},
    ],
    bankAccount:{
      ba1: [{"label":"Bank Code","value":data.bncode},
            {"label":"Branch","value":data.cbbranch},
            {"label":"Bank Account No","value":data.cbaccno},
            {"label":"Bank Account Name","value":data.cbaccname},
      ],
      ba2: [{"label":"Bank Code","value":""},
          {"label":"Branch","value":""},
          {"label":"Bank Account No","value":""},
          {"label":"Bank Account Name","value":""},
      ],
      ba3: [{"label":"Bank Code","value":""},
          {"label":"Branch","value":""},
          {"label":"Bank Account No","value":""},
          {"label":"Bank Account Name","value":""},
      ]
    },
    ci1:[{"label":"Date of Birth","value":data.birthdate},
        {"label":"Place of Birth","value":data.birthplace},
        {"label":"Position","value":""},
        {"label":"Company Name","value":""},
        {"label":"Company Type","value":""},
    ],
    ciJob:[{"label":"Unknown Addr/Phone","value":""},
        {"label":"Email","value":data.email},
        {"label":"Mobile1","value":data.phone},
        {"label":"Mobile2","value":data.phonecell},
        {"label":"-","value":""},
    ],
    ciContact:[
      {"item":"\xa0","telno":"","faxno":""},
      {"item":"\xa0","telno":"","faxno":""},
      {"item":"\xa0","telno":"","faxno":""},
    ],
    ciAddress:[
        {"item":"\xa0","postno":data.zip,"address":data.address,"address2":data.address},
        {"item":"\xa0","postno":"","address":"","address2":""},
        {"item":"\xa0","postno":"","address":"","address2":""},
    ],
    rdi:[{"label":"RDI Bank","value":data.rdnbncode == null ? "" : data.rdnbncode +" "+data.rdncbbranch == null ? data.rdncbbranch : data.rdncbbranch},
        {"label":"RDI Account Number","value":data.rdncbaccno},
        {"label":"RDI Account Name","value":data.rdncbaccname},
    ]
  }
}
// 3. Historical
function GetTradeHistory(data){
  let nData =[]
  for(let i=0; i<data.length; i++){
      let ndate = data[i].trade_date.split(" ");
      let mkt = data[i].board;
      let nvol = data[i].trade_vol == 0 ? 0 : ((mkt === "NG" || mkt === "0NG") ? data[i].trade_vol : LotToShare(data[i].trade_vol));
      let fdW = {   
          date:ndate[0],
          trade: data[i].market_trade_id,
          order: data[i].market_ord_id,
          code: data[i].stock_code,
          cmd: data[i].side,
          type: "Day",
          mkt: mkt,
          vol: nvol,
          price:Number(data[i].trade_price),
          amount:Number(data[i].trade_price)*Number(nvol),
          time:ndate[1]
      }         
      nData.push(fdW)
  }
  return GetInitialRow(nor.historical, fd.tradeListHistory, nData);
}
function GetOrdersHistory(data, id){
  let nData =[]
  for(let i=0; i<data.length; i++){
    let nleaveVolC = Number(data[i].order_vol) - Number(data[i].order_matched)
    let nstatus = GetStatusOrderDetail(data[i].fill_status, data[i].chain_status).nstatus
    let fdW = {
      order:data[i].client_ord_id,
      marketNoC:data[i].market_ord_id,
      code:data[i].stock_code,
      cmd:data[i].side,
      type:data[i].time_in_force,
      mkt:data[i].board,
      vol:LotToShare(data[i].order_vol),
      price:Number(data[i].order_price),
      mvol:data[i].order_matched == 0 ? 0 : LotToShare(data[i].order_matched),
      mprice:Number(data[i].avg_price),
      leaveVolC:nleaveVolC == 0 ? 0 : LotToShare(nleaveVolC),
      status:nstatus,
      time:data[i].order_date, 
      rejectreason:data[i].remark, 
      userOrderC:id,
      soid: data[i].server_ord_id,
      userId: data[i].client_id, 
      terminal: data[i].terminal, 
      method: data[i].method
    }            
    nData.push(fdW)
  }
  return GetInitialRow(nor.historical, fd.orderHistory, nData);
}
function GetCashTransactionHistory(data){
  let z = []
  for(let i=0; i<data.length; i++){
      let fdW = {   
          date: data[i].date,
          debitCredit: data[i].debit_credit,
          desc: data[i].description,
          amount: Number(data[i].amount),
          trx:data[i].ctranstype,
      }         
      z.push(fdW)
  }
  return GetInitialRow(nor.historical, fd.cashTransactionHistory, z);

}
function GetStockTransactionHistory(data){
  let z = []
  for(let i=0; i<data.length; i++){
      let fdW = {   
          date: data[i].date,
          code: data[i].code_stock,
          buySell: data[i].buy_sell,
          inOut: Number(data[i].qty),
          trx:data[i].stranstype,
          price:data[i].price,
      }         
      z.push(fdW)
  }
  return GetInitialRow(nor.historical, fd.stockTransactionHistory, z);
}
// 4. Fund transfer
function GetFundTransferList(data){
  let ndata = []
  for(let i=0; i<data.length; i++){
    let fdata = {
      date: data[i].transfer_date,
      no:data[i].request_id,
      amount: Number(data[i].amount),
      fee: Number(data[i].fee),
      bank: data[i].bank,
      bankAcNo: Number(data[i].bank_account_no),
      reqDate: data[i].request_date,
      reqTime: data[i].request_date.substr(11,12),
      status:data[i].status,
      msg_id: data[i].message_id,
      ref_no: data[i].ref_no,
    }
    ndata.push(fdata)
  }
  return GetInitialRow(nor.historical, fd.fundTransferList, ndata);

}


function UpdateTradePLInfo(code, data){
  let zInfo = {sellAmount: 0, buyAmount: 0, salesPl: 0, perSalesPl: 0}, sumModalBuy = 0;
  code.forEach((item) => {
      let i = data.findIndex(x => x.code == item);
      if(i >= 0){
          zInfo.sellAmount += Number(data[i].sellAmount)
          zInfo.buyAmount += Number(data[i].buyAmount)
          sumModalBuy += Number(data[i].modalBuy)
          zInfo.salesPl += Number(data[i].PL)
      }
  });
  zInfo.salesPl = zInfo.salesPl.toFixed(2);
  zInfo.perSalesPl = sumModalBuy == 0 ? 0 : ((zInfo.salesPl/sumModalBuy)*100).toFixed(2);
  return zInfo;
}
// GET AUTOMATIC ORDER
function GetConditionalAuto(rule, ind, tPrice){
  let nrule = rule === "LP" ? "Last Price" : (rule === "BO" ? "Best Offer Price" : "Best Bid Price");
  let nind =  ind === "L" ? "<=" : ">=";
  return nrule + "#" + nind + "#" + tPrice
}

// SubHeader 2 : Market Index
// GetStream Chart
function GetIndexStreamChart(type,data){
  let z=[]
  if(type === "inquiry"){
    let x = Object.keys(data)
    let y = Object.values(data)
    for(let i=0; i<x.length; i++){        
      z.push([timeModifier(x[i], "chart"), y[i]])
    }
  }
  return z
}
// data format for Market Statistic
// val : in Billion units, Lot : in million units,
function GetMarketStat(data) {
  if(Object.keys(data).length > 0){
    return {
      RG_val:data.RG_val,
      NG_val:data.NG_val,
      TN_val:data.TN_val,
      BValSum:Number(data.RG_val) + Number(data.NG_val) + Number(data.TN_val),
      RG_vol: data.RG_vol,
      NG_vol: data.NG_vol,
      TN_vol: data.TN_vol,
      BVolSum: Number(data.RG_vol) + Number(data.NG_vol) + Number(data.TN_vol),
      RG_freq: data.RG_freq,
      NG_freq: data.NG_freq,
      TN_freq: data.TN_freq,
      BFreqSum:Number(data.RG_freq) + Number(data.NG_freq) + Number(data.TN_freq),
      fbuy_val: data.fbuy_val,
      fsell_val: data.fsell_val,
      ftotal_val: Number(data.fbuy_val) + Number(data.fsell_val),
      fnet_val: Number(data.fbuy_val) - Number(data.fsell_val),
      fbuy_vol : data.fbuy_vol,
      fsell_vol : data.fsell_vol,
      ftotal_vol: Number(data.fbuy_vol) + Number(data.fsell_vol),
      fnet_vol: Number(data.fbuy_vol) - Number(data.fsell_vol),
      fbuy_freq : data.fbuy_freq,
      fsell_freq : data.fsell_freq,
      ftotal_freq: Number(data.fbuy_freq) + Number(data.fsell_freq),
      fnet_freq:"",  
      right_val:data.right_val,
      right_vol:data.right_vol,
      right_freq:data.right_freq,
      warrant_val:data.warrant_val,
      warrant_vol:data.warrant_vol,
      warrant_freq:data.warrant_freq,
    };
  }else{
    return init.marktIndex
  }
}

// data format for Index member Detail
function getIndexMemberDetail(data,id){
  let net= data.foreign_buy_val-data.foreign_sell_val
  return   { 
    code : data.stock_code,
    board :data.board_code,
    no : id,
    prev : Number(data.prev_price),
    last : Number(data.close_price),
    change : Number(data.change_price),
    persen : (data.change_price*100/data.prev_price).toFixed(2),
    open : Number(data.open_price),
    low : Number(data.low_price),
    high : Number(data.high_price),
    avg :Number(data.traded_vol) !== '0' ? (Number(data.traded_val)/Number(data.traded_vol)).toFixed(4) : '0.0000',
    val : Number(data.traded_val),
    vol : ShareToLot(data.traded_vol),
    freq :Number(data.traded_freq),
    fbuy : Number(data.foreign_buy_val),
    fsell : Number(data.foreign_sell_val),
    fnet : net !== 0 ? net : '0',
    financial : CurrencyModifier('million',3000000000),
    action :''
  }
}
// SubHeader 5 : News Research
function GetNewsAndReseach(time, data){
  var time = Date(time).toString(),
      no = data.message_no,
      subject = data.subject,
      highlight = data.highlight,
      content = data.content;
      var newsFormat = {time, no, subject, highlight, content}
  return newsFormat
}
//>>>>>>> Header 3 : STOCK PAGE <<<<<<<
// SubHeader 3 : Stock wathclist
function GetPageWatchlistCode(data){
  let nData =[]
  for(let i=0; i<data.length; i++){
      let fdW = { code: data[i], price: "", change: "", persen: "", tvol: ""}
      nData.push(fdW)
  }
  let dif = nor.stockWatchlist-nData.length
  if(dif >= 1 ){
      for(let j=0; j<dif; j++){
          nData.push(fd.stockWatchlist)
      }
  }
  return nData
}
function GetStockWathclist(type,data){
  var x = Object.keys(data);
  var y = Object.values(data)
  var xleng = x.length
  var z =[]
  for(var i=0; i<xleng; i++){
    var code =  y[i].stock_code,
        price = Number(y[i].close_price),
        change = Number(y[i].change_price),
        persen = ((Number(y[i].change_price) / Number(y[i].prev_price))*100).toFixed(2),
        tvol = ShareToLot(y[i].traded_vol),
        accumulated= "0",
        avgprice= "0",
        tval = Number(y[i].traded_val),
        AccForVol = Number(y[i].foreign_buy_vol)-Number(y[i].foreign_sell_vol),
        AccForVal = Number(y[i].foreign_buy_val)-Number(y[i].foreign_sell_val),
        AvgPrice = Number(y[i].traded_val) == 0 ? "0.0000" : (Number(y[i].traded_val)/Number(y[i].traded_vol)).toFixed(4);
    var stockWatchlistFormat = {code,tval, price,change,persen,tvol, AccForVol, AccForVal, AvgPrice}
    var tradeWatchlistFormat = {code,tval, price,change,persen,tvol, accumulated, avgprice}
    if(type === 'stockPage'){
      z.push(stockWatchlistFormat)
    }else{
      z.push(tradeWatchlistFormat)
    }
  }
  return  GetInitialRow(nor.stockWatchlist, fd.stockWatchlist, z);
}

// SubHeader 4 : Stock Trade Summary
function GetStockTradeSummary(data) {
  let newData = [];
  for(let i=3; i<data.length; i++){
      let d = data[i].split("|");
      let nd = {price:Number(d[0]), volume:Number(d[1]), value:ShareToLot(d[2]), freq:Number(d[3])}
      newData.push(nd)
  }
  var dif = 17-newData.length
  if(dif >= 1 && dif <=17){
    for(let j=0; j<dif; j++){
      newData.push({price:"\xa0", vol:"\xa0", val:"\xa0", freq:"\xa0"})
    }
  }
  return newData;
}
function GetIStockTradeSummary(data) {
  let x = Object.keys(data)
  let y = Object.values(data)
  let newData = [];
  for(let i=0; i<x.length; i++){
      let d = y[i].split("|");
      let nd = {price:Number(x[i]), volume:ShareToLot(Number(d[0])), value:Number(d[1]), freq:Number(d[2])}
      newData.push(nd)
  }
  return GetInitialRow(17, fd.stockTradeSummary, newData);

}
function GetStockSummaryBroker(data){
  let x = Object.keys(data)
  let y = Object.values(data)
  let nData = []
  for(let i=0; i<x.length; i++){
      let nval = y[i].split('|')
      let z = { buyer: x[i], seller:x[i], volume: ShareToLot(Number(nval[0])), freq: Number(nval[2]), avg: (Number(nval[1])/Number(nval[0])).toFixed(4)}
      nData.push(z)
  }
  nData.sort(function(a, b){
    return b.volume-a.volume
  });
  return nData.length > 20 ? nData.slice(0,20) : GetInitialRow(nor.stockTradeHistoryMini, fd.stockSummaryBuyer, nData);
}

//>>>>>>> Header 4 : TRADE PAGE <<<<<<<
function GetOrderListInformation(ndata){
  let nolInfo = {olSelected:0,olMatchVolLot:0,olMatchAmount:0,olVolLot:0}
  let data = ndata.filter(d=>d.code !== "-")
  nolInfo.olSelected = data.length
  nolInfo.olMatchVolLot = (sumDataArray(data,'object-mlot')).toFixed(0)
  nolInfo.olMatchAmount = (sumDataArray(data,'object-amountMatchVol')).toFixed(0)
  nolInfo.olVolLot = (sumDataArray(data,'object-vlot')).toFixed(0)
  return nolInfo
}
// 1. Orderlist
function GetOrderList(data,type, haircut){
  if(type === "get"){  
    let z =[];
    data.map((val, i)=>{          
      let orbType = data[i].client_ord_id.toString().includes("11") ? "Dealer" : "Customer"
      let nsr =  GetStatusOrder(data[i].fill_status, data[i].chain_status, data[i].request_status , data[i].remark)
      let nhaircut = haircut.length > 0 ? haircut.find(hc => hc.stock_code == data[i].stock_code) : 0
      let ghaircut = nhaircut == undefined || nhaircut.haircut == null || nhaircut.haircut == 0 ? 0 : ShareToLot(Number(nhaircut.haircut));
      let nmlot = data[i].order_matched != undefined ? Number(data[i].order_matched) : ( nsr.nstatus == "Done" ? Number(data[i].order_vol) : 0)
      let navgprice = data[i].avg_price != undefined ? data[i].avg_price.toFixed(0) : nsr.nstatus == "Done" ? data[i].order_price : 0
      let order = data[i].client_ord_id,
          checked = 0,
          marketorder = Number(data[i].market_ord_id) == 0 ? '' : Number(data[i].market_ord_id),
          serverOrderId = data[i].server_ord_id,
          code = data[i].stock_code, 
          cmd = data[i].side, 
          status = nsr.nstatus, 
          remark = nsr.nremark, 
          type = fa.getExpireValue(data[i].time_force), 
          orderType= "",
          // orderType= fg.getValType(data[i].order_type),
          mkt = data[i].board, 
          vlot = Number(data[i].order_vol), 
          vshares = LotToShare(data[i].order_vol), 
          price = Number(data[i].order_price), 
          mprice= data[i].avg_price,
          mlot = nmlot, 
          sReject="",
          mshares = nmlot == 0 ? 0 : LotToShare(nmlot), 
          leaveVlot = nsr.nstatus.includes("Cancel") || nsr.nstatus.includes("Reject") ? 0 : vlot - mlot, 
          avgmatchprice = navgprice,
          amountLeaveVol = leaveVlot == 0 ? 0 : Number(LotToShare(leaveVlot))*Number(data[i].order_price),
          amountMatchVol = Number(avgmatchprice)*mshares,
          hc = ghaircut,
          obhc = (1-Number(hc))*Number(amountLeaveVol),
          amount = Number(LotToShare(data[i].order_vol))*Number(data[i].order_price), 
          time = timeModifier(data[i].time, "ol"),
          action= data[i].order_price,
          userId= data[i].client_id, 
          terminal= data[i].terminal, 
          method= data[i].method,
          orderBy = data[i].terminal;
      let orderlistFormat = {order, orderType, checked, marketorder,code,cmd,status,remark,sReject,type,mkt,leaveVlot,vlot,vshares,amountLeaveVol,
        price,mlot,mshares,avgmatchprice,amount,time,action, amountMatchVol, serverOrderId, mprice,orderBy, hc, obhc, userId, terminal, method,}
      z.push(orderlistFormat)
      });
    return GetInitialRow(nor.orderList, fd.orderList, z);
  }else if(type === "new"){
    let nvshare = LotToShare(data.order_vol)
    let nhaircut = haircut.length > 0 ? haircut.find(hc => hc.stock_code == data.symbol) : 0
    let ghaircut = nhaircut == undefined || nhaircut.haircut == null || nhaircut.haircut == 0 ? 0 : ShareToLot(Number(nhaircut.haircut));
    let ntime =  timeModifier(data.order_send_date+" "+data.order_send_time, "norder")
    return {
      order : data.client_order_id,
      checked : 0,
      marketorder : "",
      serverOrderId:'',
      code : data.symbol, 
      cmd : data.side == 1 ? "Buy" : "Sell", 
      status : "", 
      remark : "", 
      type : fa.getExpireValue(data.order_timeinforce), 
      mkt : strCall.marketType(data.order_market), 
      orderType: "",
      // orderType: fg.getValType(data.order_type),
      vlot : Number(data.order_vol), 
      vshares : nvshare, 
      price : Number(data.order_price), 
      mprice:0,
      mlot : 0, 
      mshares : 0, 
      leaveVlot:0,
      hc : ghaircut,
      obhc : 0,
      avgmatchprice :0, 
      amountLeaveVol:0,
      amountMatchVol :0,
      amount : Number(data.order_price)*Number(nvshare), 
      time : ntime,
      sReject:"",
      action: "",
      userId: "", 
      terminal: "Web", 
      method: data.is_booking ? "Booking" : "Entry",
      orderBy:"Customer",
      }
  }else if(type === 'fromOther'){
    let cek = data.status == undefined ? "Open" : "Sending";
    let orbType = data.client_ord_id.toString().includes('11') ? "Dealer" : "Customer";
    let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark)    
    let nhaircut = haircut.length > 0 ? haircut.find(hc => hc.stock_code == data.stock_code) : 0
    let ghaircut = nhaircut == undefined || nhaircut.haircut == null || nhaircut.haircut == 0 ? 0 : ShareToLot(Number(nhaircut.haircut));
    let nmlot = cek != "Open" ? 0 : data.order_matched == undefined ? 0 : Number(data.order_matched) == 0 ? 0 : Number(data.order_matched)
    let nvlot = cek === "Open" ? Number(data.order_vol) : "0"
    let nvshare = cek != "Open" ? "" : Number(data.order_vol) == 0 ? 0 : LotToShare(data.order_vol)
    let nleaveVlot = Number(nvlot) - nmlot
    let namountLeaveVol = cek === "Open" ? (nleaveVlot == 0 ? 0 : Number(data.order_price)*LotToShare(nleaveVlot)) : 0
    let navgmatchprice = (cek === "Open" || data.avg_price) ? data.avg_price.toFixed(0) : 0
    let nMessage = {
      order : data.client_ord_id,
      checked : 0,
      marketorder : cek === "Open" ? data.market_ord_id : '-',
      serverOrderId:data.server_ord_id,
      code : cek === "Open" ? data.stock_code : '', 
      cmd : cek === "Open" ? data.side : "", 
      status : nsr.nstatus, 
      remark : nsr.nremark, 
      type :cek === "Open" ? fa.getExpireValue(data.time_force) : '', 
      orderType: '', 
      // orderType: cek === "Open" ? fg.getValType(data.order_type) : '', 
      mkt : cek === "Open" ? data.board : '', 
      vlot : cek === "Open" ? data.order_vol : "0", 
      vshares : nvshare, 
      price : cek === "Open" ? data.order_price : "0", 
      mprice:cek === "Open" ? data.avg_price :  '0',
      mlot : nmlot, 
      leaveVlot: nleaveVlot,
      amountLeaveVol:namountLeaveVol,
      hc : ghaircut,
      obhc : (1-Number(ghaircut))*Number(namountLeaveVol),
      mshares : nmlot == 0 ? 0 : LotToShare(nmlot), 
      avgmatchprice :navgmatchprice, 
      amountMatchVol : navgmatchprice*nmlot*100,
      amount : cek === "Open" ? Number(data.order_price)*Number(nvshare) :  "0", 
      time : cek === "Open" ? timeModifier(data.time, "ol") : "",
      sReject:"",
      action: "",
      userId: data.client_id, 
      terminal: data.terminal, 
      method: data.method,
      orderBy:orbType,
      }
    return nMessage 
  }
}
function GetOrderDetail(data){
  let nData = data.length > 0 ? [] : init.orderDetailCode
  for(let i=0; i<data.length; i++){
      let ntime = data[i].event_time.split(" ");
      let nAmount = Number(data[i].order_price)*Number(data[i].order_vol)
      let nsi = GetStatusOrderDetail(data[i].fill_status == null ? "null" : data[i].fill_status,
                data[i].chain_status == null ? "null" : data[i].chain_status, data[i].description)
      let fdW = 	{
          "dateO":ntime[0],
          "timeO":ntime[1],
          "actionsO":nsi.naction,
          "statusO":nsi.nstatus,
          "remarksO":data[i].description,
          "vlot":Number(data[i].order_vol),
          "vshares":LotToShare(data[i].order_vol),
          "priceO":Number(data[i].order_price),
          "mlot":Number(data[i].order_matched),
          "mshares":Number(data[i].order_matched) == 0 ? '0' : LotToShare(data[i].order_matched),
          "matchpriceO":data[i].trade_price === null ? 0 : Number(data[i].trade_price),
          "amountO":nAmount,
          "marketOrderNo0": data[i].market_ord_id,  
          "leaveVol0": Number(data[i].order_vol)-Number(data[i].order_matched), 
          "userId0":data[i].user_id,
      }           
      nData.push(fdW)
  }
  return nData
}
// get status and Action on orderdetail
function GetStatusOrderDetail(fill_status, chain_status, des){
  let type = fill_status+"|"+chain_status
  let ndes = des == undefined ? "" : des.includes("Order amend") ? "Amend" : des.includes("Order cancel") ? "Cancel" :  "New Order" 
  let nstat = des == undefined ? "" : des.includes("created") ? "Sending To Server" : "" 
  switch (type) {
      //New Order sending to Market & rejected by Market
      case "null|null" : return {nstatus:nstat, naction:ndes}; break;
      case "null|O" : return {nstatus:"Sending To Server", naction:"New Order"}; break;
      case "null|O" : return {nstatus:"Rejected", naction:"New Order"}; break;
      case "null|R" : return {nstatus:"Rejected", naction:"New Order"}; break;

      case "null|A" : return {nstatus:"Sending To Server", naction:"Amend"}; break;
      case "null|A" : return {nstatus:"Open", naction:"Amend"}; break;
      
      case "null|C" : return {nstatus:"Sending To Server", naction:"Cancel"}; break;
      case "null|C" : return {nstatus:"Open", naction:"Cancel"}; break;
      
      //New Order Open,  Partially Matched, Full Matched
      case "0|O" : return {nstatus:"Open", naction:"New Order"}; break;
      case "1|O" : return {nstatus:"Partially Done", naction:"New Order"}; break;
      case "2|O" : return {nstatus:"Done", naction:"New Order"}; break;

      case "0|A" : return {nstatus:"Open", naction:"Amend"}; break;
      case "1|A" : return {nstatus:"Partially Done", naction:"Amend"}; break;
      case "2|A" : return {nstatus:"Done", naction:"Amend"}; break;   
      
      case "0|A" : return {nstatus:"Open", naction:"Amend"}; break;
      
      case "0|C" : return {nstatus:"Cancelled", naction:"Cancel"}; break;
      case "1|C" : return {nstatus:"Cancelled", naction:"Cancel"}; break;        
  }
}
// get Tradelist  data
function GetTradeList(data){
  let z =[]
  for(let i=0; i<data.length; i++){
      let ntime = data[i].trade_date.split(" ")
      let nvol = Number(data[i].trade_vol)*100
      let serverOrderId = data[i].server_ord_id,
          pserverOrderId = data[i].primary_server_ord_id,
          marketorder = data[i].market_ord_id,
          time = ntime[1],
          code = data[i].stock_code,
          price = data[i].trade_price,
          vol = data[i].trade_vol,
          val = Number(data[i].trade_price)*nvol,
          buyer = data[i].buyer,
          seller = data[i].seller,
          cmd =  data[i].side,
          tradeId=data[i].market_trade_id;
      let orderlistFormat = {serverOrderId,pserverOrderId,marketorder, time, code,price,vol,val,buyer,seller,cmd,tradeId}
      z.push(orderlistFormat)
      }
  return GetInitialRow(nor.tradeList, fd.tradeList, z);
}
// get tradeorder Summary dari onclick selected
function GetTradeOrderSummary(data){
  let ndata = data.filter(d=>d.code !== "-")
  let resArr = [], z=[], nVol=0, ntradeVol=0, nmshares=0, avgPrice=0, nleaveVol=0,namountMatchVol=0, ind = 0;
  ndata.forEach(function(item){
    let i = resArr.findIndex(x => x.code == item.code && x.cmd == item.cmd);
    if(i <= -1){
      resArr.push({
          code: item.code, 
          leaveVol: Number(item.leaveVlot),
          tradeVol: Number(item.mlot), 
          mshares: Number(item.mshares), 
          amountMatchVol: Number(item.amountMatchVol), 
          cmd: item.cmd, 
          avgPrice:item.amountMatchVol == 0 ? 0 :(Number(item.amountMatchVol)/Number(item.mshares)).toFixed(2),
          vol:Number(item.vlot)
      });
    }else{
      nVol = Number(resArr[i].vol)+Number(item.vlot)
      resArr[i].vol = nVol
      nleaveVol = Number(resArr[i].leaveVol)+Number(item.leaveVlot)
      resArr[i].leaveVol = nleaveVol
      ntradeVol = Number(resArr[i].tradeVol)+Number(item.mlot)
      resArr[i].tradeVol = ntradeVol
      nmshares =  Number(resArr[i].mshares)+Number(item.mshares)
      resArr[i].mshares = nmshares
      namountMatchVol =  Number(resArr[i].amountMatchVol)+Number(item.amountMatchVol)
      resArr[i].amountMatchVol = namountMatchVol
      resArr[i].avgPrice = namountMatchVol == 0 ? 0 : (namountMatchVol/nmshares).toFixed(2)
      
      ind = i;
    }
  });  
  
  return GetInitialRow(nor.tradeOrderSum, fd.tradeOrderSum, resArr);
}
// get trade Trade Summary dari onclick selected
function GetTradeTradeSummary(data, soid){
  let ndata = data.filter(d=>d.code !== "-")    
  let resArr = [], nVol=0, ind = 0;
  let fdata = ndata.filter(d=> soid.find(s=>d.marketorder === s || d.pserverOrderId === s))
  fdata.forEach(function(item){
    let i = resArr.findIndex(x => x.code == item.code && x.cmd == item.cmd && x.price == item.price);
    if(i <= -1){
      resArr.push({code: item.code, price:item.price, vol:Number(item.vol), cmd: item.cmd});
    }else{
      nVol = resArr[i].vol + Number(item.vol)
      resArr[i].vol = nVol
      ind = i;
    }
  });    
  return GetInitialRow(nor.tradeTradeSum, fd.tradeTradeSum, resArr);
}
// Get OrderBook List
function GetOrderBookList(data,type){
  if(type === "get"){  
      let z =[]
      for(let i=0; i<data.length; i++){
          let no = i+1,
              bookingNo = data[i].client_ord_id,
              status=data[i].exec_status == null ? "" : data[i].exec_status,
              remark=data[i].exec_remark,
              cmd = data[i].side,
              mkt = data[i].board,
              code = data[i].stock_code,
              price = Number(data[i].order_price),
              vol = LotToShare(data[i].order_qty),
              vollot = Number(data[i].order_qty),
              bookingDate = data[i].order_date.substr(0,19);            
          let orderbookFormat = {no,bookingNo,code,status,remark,cmd,mkt,vol,price,vollot,bookingDate}
          z.push(orderbookFormat)
          }
      return GetInitialRow(nor.tradeOrderBookList, fd.tradeOrderBookList, z)
  }else if(type === "new"){
    let ntime =  timeModifier(data.order_send_date+" "+data.order_send_time, "norder")
      return {
          no: 1,
          bookingNo: data.client_order_id,
          cmd: data.side == 1 ? "buy" : "sell",
          mkt:data.order_market === "0RG" ? "RG" : data.order_market === "0TN" ? "TN" : "NG",
          status:"",
          remark:'',
          code: data.symbol,
          price: data.order_price,
          vol: LotToShare(data.order_vol),
          vollot: data.order_vol,
          bookingDate : ntime
      }
  }else if(type === 'fromOther'){
      let cek = data.status == undefined ? "Open" : "Sending";
      let orbType = data.client_ord_id.toString().includes('11') ? "Dealer" : "Customer";
      let nremark = cek === "Open" ? data.chain_status.includes('Full') || data.fill_status.includes("Full") ? "" : data.chain_status : ""
      let nmlot = cek != "Open" ? 0 : data.order_matched == undefined ? 0 : Number(data.order_matched) == 0 ? 0 : Number(data.order_matched)
      let nvshare = cek != "Open" ? "" : Number(data.order_vol) == 0 ? 0 : LotToShare(data.order_vol)
      let nMessage = {
          order : data.client_ord_id,
          checked : 0,
          marketorder : cek === "Open" ? data.server_ord_id : '-',
          serverOrderId:data.server_ord_id,
          code : cek === "Open" ? data.stock_code : '', 
          cmd : cek === "Open" ? data.side : "", 
          status : cek === "Open" ? data.fill_status : data.status, 
          remark : nremark, 
          type :cek === "Open" ? 'Day' : '', 
          mkt : cek === "Open" ? data.board : '', 
          vlot : cek === "Open" ? data.order_vol : "0", 
          vshares : nvshare, 
          price : cek === "Open" ? data.order_price : "0", 
          mprice:cek === "Open" ? data.order_price :  '0',
          mlot : nmlot, 
          mshares : nmlot == 0 ? 0 : LotToShare(nmlot), 
          avgmatchprice :cek === "Open" ? data.avg_price : '0', 
          amount : cek === "Open" ? Number(data.order_vol)*Number(nvshare) :  "0", 
          time : cek === "Open" ? data.time : "",
          action: "",
          orderBy:orbType,
          }
      return nMessage 
  }
}
// get trade adv list
function GetTradeAdv(data){
  let nData =[]
  let x = Object.keys(data)
  let y = Object.values(data)
  for(let i=0; i<x.length; i++){
      let val = y[i].split("|")
      let fdTradeAdv = {broker:val[2],price:Number(val[3]),vol:Number(val[4]),value:Number(val[5])}
      nData.push(fdTradeAdv)
  }
  let dif = nor.tradeAdv-nData.length
  if(dif >= 1 ){
      for(let j=0; j<dif; j++){
          nData.push(fd.tradeAdv)
      }
  }
  return nData
}
function GetAutomaticOrder(data) {
  let z = [], formatData;
  data.map((val, i) => {
    let priceOpt = data[i].type == "MANUAL" ? data[i].type : data[i].type+""+data[i].order_price;
      formatData = {
          ruleID: data[i].rule_id,
          createdDate: data[i].create_date.split(".")[0],
          on: data[i].flag,
          typePrice: init.orderPriceOption.filter(d => d.value == priceOpt)[0].text, 
          price: data[i].type == "MANUAL" ? data[i].order_price : priceOpt,
          code: data[i].symbol,
          cmd: data[i].side,
          condition: GetConditionalAuto(data[i].rule, data[i].indicator, data[i].trigger_price),
          exp: timeModifier(data[i].expired_date, "v0"),
          vol: data[i].order_vol,
          typeOP: data[i].type,
          rule: data[i].rule,
          value: data[i].type == "MANUAL" ? data[i].order_vol*data[i].order_price*100 : "-",
          status: data[i].status
      }
      z.push(formatData);
  });
  return GetInitialRow(nor.orderSetttingList, fd.orderSetttingList, z)
}

//>>>>>>> Header 6 : LIVE TRADE <<<<<<<
// data format for Running Trade
function GetLiveTrade(data){
  let time = data[2],
      code = data[4],
      price =Number(data[5]),
      prev = Number(data[20].replace(/[^0-9,^.]/g, '')),
      change= price - prev,
      percent =((change / prev) * 100).toFixed(2),
      vol = ShareToLot(Number(data[6])),
      buyer= data[14]+'-'+data[15],
      seller= data[17]+'-'+data[18],
      board = data[8];
  // make a new object
  let tradeFormat = { time, code, price,change, percent, vol, buyer, seller, board}
  return tradeFormat;
}

//>>>>>>> TRANSACTION <<<<<<<
// data format for bid and offer
function BidOffer(data) {
  let obj = [];
  for (let i = 3; i < data.length; i++) {
    let value = data[i].split("|")
    let newData = {
      bidOffer: value[1] != "0" ? value[0] : "\xa0",
      noBidOffer: value[1] != "0" ? value[2] : "\xa0",
      bidOfferVol:value[2] != "0" ? ShareToLot(value[1]) : "\xa0",
    };
    obj.push(newData);
  }
  let dif = 10-obj.length
  if(dif >= 1 && dif <=9){
    for(let j=0; j<dif; j++){
      obj.push({bidOffer:"\xa0", noBidOffer:"\xa0", bidOfferVol:"\xa0"})
    }
  }
  return obj;
}
// get status and remark on ordelist
function GetStatusOrder(fill_status=null, chain_status=null, request_status=null, remark=""){
  let type = fill_status+"|"+chain_status+"|"+request_status;
  let cremark = remark == undefined ? "" : remark
  switch (type) {
      //New Order sending to Market & rejected by Market
      case "null|null|null" : return {nstatus:"Sending To Server", nremark:""}; break;
      case "null|O|null" : return {nstatus:"Sending To Server", nremark:""}; break;
      case "null|O|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;

      case "null|R|null" : return {nstatus:"Rejected By Server", nremark: cremark}; break;
      case "null|R|2" : return {nstatus:"Rejected By Server", nremark: cremark}; break;

      case "null|A|null" : return {nstatus:"Sending To Server", nremark:"Amending"}; break;
      case "null|A|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;
      
      case "null|C|null" : return {nstatus:"Sending To Server", nremark:""}; break;
      case "null|C|2" : return {nstatus:"Rejected By Market", nremark:cremark}; break;
      
      //New Order Open,  Partially Matched, Full Matched
      case "0|O|2" : return {nstatus:"Open", nremark:"Open rejected : "+cremark}; break;
      case "0|O|1" : return {nstatus:"Open", nremark:""}; break;
      case "1|O|1" : return {nstatus:"Partially Done", nremark:""}; break;
      case "2|O|1" : return {nstatus:"Done", nremark:""}; break;

      case "0|A|2" : return {nstatus:"Open", nremark:"Amended rejected : "+cremark}; break;
      case "0|A|1" : return {nstatus:"Open", nremark:"Amended"}; break;
      case "1|A|1" : return {nstatus:"Partially Done", nremark:"Amended"}; break;
      case "1|A|2" : return {nstatus:"Partially Done", nremark:"Amended rejected : "+cremark}; break;
      case "2|A|1" : return {nstatus:"Done", nremark:"Amended"}; break;   
      
      case "0|C|1" : return {nstatus:"Cancelled", nremark:""}; break;
      case "0|C|2" : return {nstatus:"Open", nremark:"Cancelled rejected : "+cremark}; break;
      case "1|C|1" : return {nstatus:"Cancelled", nremark:"Partially Done"}; break;        
      case "1|C|2" : return {nstatus:"Partially Done", nremark:"Cancel rejected : "+cremark}; break;

      default: return {nstatus: type, nremark: ""}
  }
}
export function getLowerAR(price, type) {
  // per 4 Sep 2023: upperAr 50-200 @35%, >200-5000 @25%, >5000 20%
  if(["ACCEL", "WATHCLIST", "WATCH_CALL"].includes(type)){
    return (0.9 * price).toFixed(0);
  } else if(price <= 200){
    return (price * (1 - 0.35)).toFixed(0)  
  } else if(price > 200 && price <= 5000){
    return (price * (1 - 0.25)).toFixed(0)  
  } else if(price > 5000){
    return (price * (1 - 0.20)).toFixed(0)        
  }
}
export function getUpperAR(price){
  // per 4 Sep 2023: upperAr 50-200 @35%, >200-5000 @25%, >5000 20%
  if(price <= 200){
    return (price * 1.35).toFixed(0)  
  }else if(price > 200 && price <= 5000){
    return (price * 1.25).toFixed(0)  
  }else if(price > 5000){
    return (price * 1.20).toFixed(0)        
  }
}
function GetLowerUpper(prevStatus, openPrice=0, prevPrice=0, stockData={}){
  let { trade_in_pre="", type="" } = stockData, lowerAR = 0, upperAR = 0;
  // let constLower = ["ACCEL", "WATHCLIST", "WATCH_CALL"].includes(type) ? 0.9 : 0.85;
  let preOpStatus = trade_in_pre.includes("The stock can be traded");
  let constPrice = (preOpStatus && (openPrice * 1) !==0 ) ? (openPrice * 1) : (prevPrice * 1);
  lowerAR = getLowerAR(Number(prevPrice), type);
  upperAR = getUpperAR(Number(prevPrice));

  return { lowerAR, upperAR }
}
// get Max buy/cash buy
function GetMaxBuy(lastPrice, remainTrade){
  let nlastPrice = Number(lastPrice), nremainTrade = Number(remainTrade)
  return nlastPrice == 0 ? 0 : Math.floor(nremainTrade/nlastPrice)
}

//>>>>>>> HEADER <<<<<<<
function GetTickerHeader(data){
  let newData = []
  for(let i=0; i<data.length; i++){
    let z = { symbol: data[i], last: "", change: 0, percentage: 0,prev_index:'0'}    
    newData.push(z)
  }
  return newData
}

// cek status user
function GetUserStatus(data){
  let cStatus = data === "T" ? "SUSPEND" : data === "A" ? "ACTIVE" : 
    data === "B" ? "SUSPEND BUY" :  data === "S" ? "SUSPEND SELL" : 
    data === "M" || data === "W" ? "TRIAL" : data
  let fmsg = data === "T" ||  data === "M" || data === "W" ? "Buy / Sell" : data === "B" ? "Buy" :  
    data === "S" ? "Sell" : "" 
  let nmsg = fmsg+" Order is not permitted"
  let code_status = data
  return {
    user_name : "",
    bank_user : "",
    bank_code : "",
    bank_account : "",
    bank_name : "",
    bank_branch: "",
    email : "",
    status : cStatus,
    code_status:data,
    erAlert : nmsg,      
  }
}

//>>>>>>> SIDEBAR<<<<<<<
// fungsi search berdasarkan page
function showPage(thisPage, maxPage, data) {
  if(typeof(data)==="object"){
    if (data.length > maxPage) {
      let first = Number(thisPage * maxPage);
      let last = Number((thisPage + 1) * maxPage);
      return data.slice(first, last);
    } else {
      return data;
    }
  }
}
//>>>>>>> FOOTER <<<<<<<
// fungsi search berdasarkan page
function GetFooter(data){
  let newData = []
  for(let i=0; i<data.length; i++){
    var z = { symbol: data[i], last: '', change: 0, percentage: 0,}
    newData.push(z)
  }
  var dif = 7-newData.length
  if(dif >= 1 && dif <=9){
    for(let j=0; j<dif; j++){
      newData.push({symbol: '-', last: '', change: 0, percentage: 0,})
    }
  }
  return newData
}

var BIPSAppVars = {
  //============= initial state of Setting =============    
  tabSetting : 0,
  userInfo: {},
  // PASSWORD
  tabPassPin: 1,
  fullscreenmode: false,
  signupState: false,
  changePassErrState: false,
  changePassErrReason: "",
  // PIN 
  checkPinErrState: false,
  checkPinErrReason: "",
  verifyPinStatus: false,
  opsPinId: '',
  // initial state for account mode (Light & dark) default: "dark"
  userPreference: fd.userPreference,
  changeUserPreference: {},
  settingModalStatus: false,
  thememode: true,
  scaleState: "100",
  pinUsage: "once",
  pinPage: "",
  chatId: "",
  // chart mode in Analitic
  chartMode: false,
  //initial state for account mode (Reguler & Margin) default: "Margin"
  GeneralType: true,  
  // warna className
  colorClass: "text-dark",
  // modal status
  isModal: false,
  // sweetAlert
  msgAlert: '',
  msgAlert3: '',
  statusAlert: false,
  statusAlertC: false,
  statusAlertN: false,
  saveStatus :false,
  // no active detection
  activateStatus: false,
  activateTimeout: {logout: 1200000, session: 30000},
  // status migration
  migrationState: {status: "no", tab: 1, selected: true, selectedAct: true },
  // pushNotif: dummy.pushNotif,
  // listNotif: dummy.listNotif,

  //============= initial state of login =============
  firstLogin: false,
  loginState: false,
  loginRequestID: 0,
  networkState: false,
  networkAuxState: false,
  loginErrState: false,
  loginErrReason: '',
  netAction: null,
  netActionAux: null,
  frameAction: null,
  notifAction: null,
  userID: '',
  sessionID: '',
  sessionXMPP: '',
  idXMPP: '',
  userName: '',
  // Remember me
  remembermeStatus: false,
  // Haircut data
  haircutData: [],
  brokerList: [],
  custodyList: [],

  //============= initial state of Frame Active =============
  subscriptionFlags: initSubscriptionFlags,

  //============= initial State of Header & Side Bar =============
  //>>> initial state of Header
  tradingStatus: '',
  compositeIDX: {},
  // data sederhana dari Account Info
  userProfile: fd.userProfile,
  stockProfile: [],
  stockList: {},
  myBalance: 0,
  balanceOpt: 'cashBalance',
  nsettlement: {custfee: 0, payable2: 0, payable0: 0, receivable0: 0, receivable2: 0, taxfee0: 0, taxfee2: 0},
  // account type
  marginId: '',
  custumerCodeMargin: '',
  regulerId: '',
  custumerCodeReguler: '',
  // Header ticker
  timerHeader: 0,
  flippedHeaderStatus: false,
  indexTickerHeader: 0,  
  boxDataTickerHeader: init.boxHeaderReady,
  //>>> initial state of side Bar
  onClickSidebar: {analytic: false, page: false, stockTradeHis: false, stockTradeSum: false, tradeBidOffer: false, tradePageAdv: false, cart: false},
  cartIconStatus: false,
  watchlistCode: {},
  myWatchlistCode: [],
  amendModalStatus: false,
  sidebarStatusGroup: '',
  dataWatchlist: [],
  activeGroup: [],  
  // page
  thisPage: 0,
  sumPage: 0,
  maxPpage: 7,
  sidebarCode: init.sidebarCode,
  sidebarData: init.sidebarData,
  // Merquer
  listMarquee: [],
  // options
  stocklistOptions: [],
  indexlistOptions: [],
  relativelistOptions: [],
  
  //============= initial State ofMarquee =============
  timerFooter: 0,
  flippedFooterStatus: false,
  allSaham: [],
  sumPageFooter: 0,
  firstBoxFooterData: init.boxFooterReady.slice(0,7),
  secondBoxFooterData: init.boxFooterReady.slice(0,7),
  boxDataFooter: [
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
    {symbol: '-', last: '', change: 0, percentage: 0,},
  ],
  thisPageFooter: 0,

  //============= SUBSCRIBE Code LIST=============
  stockSmryXsListCode: [],
  //============= initial State of Container =============
  addGroupCodeContainer: ['\xa0', '\xa0', '\xa0', '\xa0', '\xa0'],
  amendGroupContainer: ['\xa0', '\xa0'],
  amendCodeContainer: ['\xa0', '\xa0'],
  livetradeContainer: init.lvtrade,
  firstBoxHeader: {symbol: 'COMPOSITE', last: "", change: 0, percentage: '0', prev_index: '0'}, 
  secondBoxHeader: fd.boxHeaderReady,
  boxReadyFooter: init.boxFooterReady,

  //============= initial State of Header 1 : My Account =============
  // My Account Page : 1. investment board 
  stateLanding: 'k',
  portofolios: init.portfolio,
  portofolioInfo: init.portofolioInfo,
  fixedIncome: init.fixedIncome,
  // 2.Stock and Cash
  stockCash: init.stockCash,
  stockCashInfo: init.stockCashInfo,
  scSettlement: init.scSettlement,
  mutualFund: init.mutualFund,
  // My Account Page : 3. Historical
  rangeTradelistHistory: {start: "", to: ""},
  rangeOrderlistHistory: {start: "", to: ""},
  rangeTransactionHistory: {start: "", to: ""},
  tradeListHistory: init.tradeListHistory,
  orderHistory: init.orderHistory,
  stockTransactionHistory: init.stockTransactionHistory,
  cashTransactionHistory: init.cashTransactionHistory,
  // My Account Page : 4. Fund Transfer
  tabFundTransfer: 1,
  fundTransferT0T1: {t1: "\xa0", t2: "\xa0", c1: 0, c2: 0},
  rtgsStatus: false,
  amountFund: 0,
  amountWithdrawable: 0,
  transferDate: "",
  cancelFundtransfer: {},
  rangeFundTransferList: {start: "", to: ""},
  fundTransferList: init.fundTransferList,
  fundStockList: init.fundStockList,
  fundStockCodeList: [],
  rangeFundTransferSTL: {start: "", to: ""},
  fundTransferCancel1: init.fundTransferCancel1,
  fundTransferCancel2: init.fundTransferCancel2,
  idftCancel: '', 
  TradeControl: init.tradeControlSoe, 
  /** WARRANT - RIGHT EXERCISE */
  exerciseList: init.exerciseList,
  exerciseCode: [],
  exerciseState: {tab: 1,},
  
  // My Account Page : 5.5 Trade P/L
  rangeTradePl: {start: "", to: ""},
  tradePlInfo: init.tradePlInfo,
  // My Account Page : 5. Account info 
  ai: init.accountInfo,
  transHistory: init.transactionHistory,

  //============= initial State of Header 2 : Market & Statistic =============
  indexMemberCode: [],  
  // inquiryTopActive, inquiryTopGainer, inquiryTop Loser is status inquiry
  summaryDataTop: [],
  topType: 'topactive', topChoice: "frequency", boardChoice: 'RG',
  inquiryIndexData: {}, 
  sectorName: 'IDXBASIC',
  // member capitalization /  sectoral index
  sectoralIndexCode: fd.sectoralIndexCode,
  indices: {},
  // non Sectoral Index
  nonIndices: {},
  nonSectoralIndexCode: [],
  genNews: [{ time: "", no: "", subject: '', highlight: '', content: ''}],
  news: {},
  statusUpdate: '', 
  marketStatisticData: {},
  
  indexList: {},
  dataIndex: '',
  // Market Index
  indexData: {},
  codeSearchMarketIndex: "COMPOSITE",
  streamChart: [[1508889600000, 100]],
  indexStreamChart: [[1508889600000, 100]],
  timeChart: '',
  streamStatus: false, 
  firstDataStream: '', 

  // INT.INDICES
  tabIntIndices: 1,
  globalIdList: {},
  globalIdListArr: [],
  globalIdMaxPage: 0,
  indexFuturesListArr: [],

  // CURRENCY & COMMODITY
  forexList: {},
  forexListArr: [],
  forexMaxPage: 0,
  currenciesActive: fd.currenciesActive,
  currencies: init.currencies,
  commodityActive: fd.commodityActive,
  commodityListArr: [],
  commodity: init.commodity,

  //============= initial State of Header 2.5 : BrokerPage =============  
  codeBrokerInfo: "",
  // 1. Broker Info
  brokerInfoDetail: init.brokerInfoDetail,
  // 2. Broker Trade Summary
  brokerTradeSumInfo: {},
  brokerTradeSum1: init. brokerTradeSum1,
  brokerTradeSum2: init. brokerTradeSum2,
  // 3. Broker Trade History
  rangeBrokerTradeHistory: {start: "", to: ""},
  brokerTradeHisInfo: {},
  brokerTradeHistory: init.brokerTradeHistory,
  // 4. Top Broker

  //============= initial State of Header 3 : StockPage =============  
  // initial state of stock info
  codeSearchStockInfo: '',
  chartStockData: [],
  corpAction: init.corpAction,
  // STOCK FINANCIAL STATMENT / REPORT  
  stockFinancial: {tab: 1, valQtr: "1", valYear: GenerateTime("year")},
  stockFinancialyear: GenerateTime("year"),
  stockFinancialReport: init.stockFinancialReport,
  //  initial State stock Watchlist
  amendGroupName: '',
  amendGroup: [],  
  addGroupName: '',
  addGroupCode: [],
  amendActiveTab: '1',
  // paganation in Watchlist
  thisPageWatchlist: 0,
  sumPageWatchlist: 0,
  maxPageWatchlist: nor.stockWatchlist,
  pageWatchlistCode: [],
  // initial state for result selected
  codeSelected: '',
  //  initial State stock trade history
  rangeStockTradeHistory: {start: "", to: ""},
  stockTradeHistorySMRY : {},
  // codeSearchStockTradeHistory:'',
  tabStockTradeHistory: 1,
  stockTradeHistoryPrice: init.stockTradeHistoryPrice,
  stockTradeHistoryBuy: init.stockTradeHistoryBuy,
  stockTradeHistorySell: init.stockTradeHistorySell,
  tabStockTradeHistoryChart: 1,
  stockTradeHistoryChart: [],
  stockTradeHistoryChartBuy: [],
  stockTradeHistoryChartSell: [],
  stockTradeHistoryChartNet: [],
  // initial state of Stock Trade Summary
  stockTickBoardType: 'ALL',
  stockTradeSummarySMRY: {},
  stockTickMain: init.stockTickMain,
  stockTradeSummaryMain: init.stockTradeSummary,
  stockSummaryBuyerMain: init.stockSummaryBuyer,
  stockSummarySellerMain: init.stockSummaryBuyer,
  // technical Day
  techDayData: {},
  // special Notation
  specialNotationDetail: [],

  //============= initial State of Header 4 : Trade Page =============  
  // initial state of setting manual/automatic page
  isManual: true,
  lastSubHeaderManual: '',
  lastSubHeaderAuth: 'AutOrderSetting',
  // initial state of Orderlist
  orderList: init.orderlist,
  orderlistSelected: [],
  orderlistInformation: fd.orderlistInformation,
  tradeListMain: init.tradeList,
  tradeListData: [],
  tradeOrderSum: init.tradeOrderSum,
  tradeTradeSum: init.tradeTradeSum,
  amendOrderModalStatus: false,
  termsConditionStatus: true,
  agreementAutomaticStatus: false,
  orderDetail: fd.orderDetail,
  orderDetailCode: init.orderDetailCode,
  // initial state of Orderbook 
  // OB : order Book, iSD = Inquiry Stock Data,sStockS = subscribe Stocksmry
  statusOfTradeModal: false,
  trxTypeForOB1: '',
  trxTypeForOB2: '',
  trxTypeForOB3: '',
  codeForOB1: '',
  codeForOB2: '',
  codeForOB3: '',
  bidData1: [],
  bidData2: [],
  bidData3: [],
  offerData1: [],
  offerData2: [],
  offerData3: [],
  sStockSForOB1: {},
  sStockSForOB2: {},
  sStockSForOB3: {},
  iSDForOB1: {},
  iSDForOB2: {},
  iSDForOB3: {},
  box1: 0,
  box2: 0,
  box3: 0,
  // Trade Page : 3. ORDERBOOK LIST
  tradeOrderBookList: init.tradeOrderBookList,
  cancelOrderBook: {},
  //Trade Page : 4. ADVERTISEMENT LIST
  codeSearchTradeAdv: '',
  tradeAdvBid: init.tradeAdv,
  tradeAdvOffer: init.tradeAdv,
  // Trade Page : 5. Order Setting List
  codeSearchAutomatic: '',
  rulePrice: 'LP',
  setPriceAutomatic: 0,
  orderPriceOption: init.orderPriceOption,
  firstReqAutoOrder: true,
  orderSetttingList: init.orderSetttingList,
  setCondAutomatic: init.setCondAutomatic,
  tempAutoOrder: {},
  //== EXPIRE AUTOMATIC ORDER Display
  rangeAutExpOrder: {start: "", to: ""},
  autExpOrderList: init.autExpOrderList,
  // Trade Page : 6. SEND ORDER
  rangetradeSendOrder: {start: "", to: ""},
  tradeSendOrder: init.tradeSendOrder,
  // initial state of sen order
  
  //============= initial State of Header 5 : Analaitic Page =============
  chartList: {},
  analyticType: "single",
  //multichart
  addressMultiVal: 0,

  //============= initial State of Header 6 : LiveTrade =============
  tradeType: "all", 
  runningStatus: true,
  sumTrade: 0,
  tradeAll: init.lvtrade,
  tradeWatchlist: init.lvtrade,
  tradeForeign: init.lvtrade,
  tradeTick: init.lvtrade,
  tradeNonRg: init.lvtrade,
  
  //============= initial State of Header ESBN =============
  // 10/09/2021 Angga - Check Status Condition ESBN
  // 0 : Belum Aktifasi Data Diri, 1 : Belum Aktifasi Rekening, 2 : Belum Aktifasi SB, 3 : Sudah Lengkap
  checkStatusEsbn: 1,
  SBN_PORTFOLIO: [],
  onLoading: false,

  /** MUTUAL FUND */
  registerMutualFund: true, 
  filterPortfoioSBN: "all",
  aperdConvertCurrency: [{currency_from: "IDR", currency_value: 1}],
  subTotalAperd: 0,
  tempFundList: [],
  filterListFund: { IM: 'all'},
  
  //============= initial State of Transaction =============
  openModalBuySell: false,
  // market and expire options
  stateOptionsMkt: [
      { key: 'rg', value: '0RG', text: 'RG' },
      { key: 'tn', value: '0TN', text: 'TN' },
      // { key: 'ng', value: '0NG', text: 'NG' },
  ],
  transactionType: '',
  // tabel bid offer
  haircutCode: 0,
  codeStatus: 'ACTIVE',
  bidOfferCode: '',
  iStockData: {},
  sStockSmry: {},
  minBid: 0,
  maxOffer: 0,
  bidData: [],
  offerData: [],
  portfolioData: fd.portfolio, 
  BuySellModalStatus: false,
  // preopening, lower&upper
  lowerAR: 0 ,
  upperAR: 0,
  preOpeningStatus: false,
  // form buy sell
  tempOrder: {},
  bidOfferPrice: '',
  bidOfferVol: '0',
  bidOfferVal: '0',
  bidOfferMkt: '0RG',
  bidOfferExpire: '0',
  order_type: "7",
  prevStatus: true,
  orbookStatus: false,
  offerAlldStatus: false,
  clientOrderId: 0,
  // form amend
  ordered: {},
  amend: {},
  // form withdraw  
  amendWithdrawData: {},
  amendWithdrawIndex: '',
  // info form buy
  formBuyFooter: fd.formBuyFooter,
  formSellFooter: fd.formSellFooter,
  formAmendFooter: {cashOn: 0, remainTrade: 0},
  // BUYING LIMIT
  constBL: {Lqval: 0, obhc: 0, OB: 0, Ct2: 0, divider: 0.5, balance: 0},

}

var BIPSAppActions = {
  //============= initial setup =============
  setActionRefs: (vars, {netAction, frameAction, netActionAux, notifAction, notifStatus}) => ({...vars, netAction, frameAction, netActionAux, notifAction, notifStatus}),
  networkDisconnected: (vars) => ({...vars, loginState: false, networkState: false}),
  networkConnected: (vars) => ({...vars, loginState: false, networkState: true}),
  
  //============= Setting actions =============
  // action fullscaleMode
  handleFullScreenMode: (vars) => ({...vars, fullscreenmode: !vars.fullscreenmode}),
  // action Landing
  handleView: (vars, {isGrid}) => ({...vars, isGrid: !vars.isGrid}),
  // rememberme handler
  handleRememberMe: (vars) => {
    if (vars.remembermeStatus){
      sessionStorage.clear();
    }
    return {...vars, remembermeStatus: !vars.remembermeStatus}
  },
  // action login
  getLogin: (vars, {loginState}) => ({...vars, loginState: !vars.loginState}),
  // state signup
  isSignup: (vars, {signupState}) => ({...vars, signupState: !vars.signupState}),
  // state theme
  isNight: (vars, {thememode}) => ({...vars, thememode: thememode}),
  // chat bot state
  changeIdChatBot: (vars, {chatId}) => ({...vars, chatId: chatId}),
  // chart mode
  changeChartMode: (vars, {chartMode}) => ({...vars, chartMode: chartMode}), 
  // chat bot state
  changeAccountType: (vars, { GeneralType }) => {
    return{ ...vars, GeneralType: GeneralType }},
  // modal Buy, sell, amend, withdraw
  handleModalStatus: (vars, {BuySellModalStatus }) => {
    return { ...vars, BuySellModalStatus: !vars.BuySellModalStatus }
  },
  // sweetAlert
  handleStatusAlert: (vars, {statusAlert,msg, data}) => {
    if(msg === 'none'){
      return {...vars, statusAlert: !vars.statusAlert, ieudata: data}
    }else{
      return {...vars, statusAlert: !vars.statusAlert, msgAlert: msg}
    }
  },
  handleStatusAlert3: (vars, {type, statusAlert,msg, data}) => {
    if(type === 'noConfirm'){      
      return {...vars, statusAlertN: !vars.statusAlertN, msgAlert3: msg}
    }else{
      if(msg === 'noSave'){
        if(vars.amendModalStatus){
          vars.amendGroup = [];
          vars.addGroupCode = [];
          vars.amendModalStatus = false;
          vars.frameAction.closeModal(100)
        }
        else{
          vars.thememode = vars.userPreference.theme == "dark" ? true : false
          vars.scaleState = vars.userPreference.interface_scale.split("%")[0]
          vars.pinUsage = vars.userPreference.pin_usage;
          vars.frameAction.closeModal(100)
          vars.settingModalStatus = false
        }
      }
      return {...vars, statusAlertC: !vars.statusAlertC, msgAlert3: msg, ieudata: data}
    }
  },
  // handle Status Notification
  handleNotification: (vars, {notifStatus, notifMessage}) => {
    if(notifStatus){
      // console.log('ini status Alert', vars.notifStatus, vars.notifMessage)
      return {...vars, notifStatus: false, notifMessage: ''}
    }
  },
  // dropdown in header
  changeBalanceOpt: (vars, { balanceOpt }) => ({ ...vars, balanceOpt: balanceOpt }),
  // click next in Side Bar
  changePage: (vars, {page,type}) => {
    let newPage = page % vars.sumPage
    let lastThisCodeGoup = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort()
    let lastPageCode = showPage(vars.thisPage, vars.maxPpage, lastThisCodeGoup)
    let nextPageCode = showPage(newPage, vars.maxPpage, lastThisCodeGoup)
    let nstockSmryXsListCode = vars.stockSmryXsListCode

    //Unsubscribe code pada page sebelumnya dan subscribe code berikutnya
    let diflastPageCode = lastPageCode.filter(d => vars.portofolios.map(l => l.code).indexOf(d) == -1)
    if(diflastPageCode.length > 0){
      vars.netActionAux.send(fmsg.ux_stockSummaryXS(diflastPageCode)) 
      nstockSmryXsListCode = vars.stockSmryXsListCode.filter(item => diflastPageCode.indexOf(item.stock_code) == -1)      
    }
    let difnextPageCode = nextPageCode.filter(d => vars.portofolios.map(l => l.code).indexOf(d) == -1)
    if(difnextPageCode.length > 0){
      difnextPageCode.map(d => nstockSmryXsListCode.push({stock_code:d, prev_price:0, close_price: 0}))
      vars.netActionAux.send(fmsg.sx_stockSummaryXS(difnextPageCode)) 
    }
    let newSideBar = [...nextPageCode, ...init.sidebarCode.slice(nextPageCode.length, vars.maxPpage)];
    return {...vars, thisPage: newPage, sidebarCode: newSideBar, stockSmryXsListCode: nstockSmryXsListCode, sidebarData: GetSidebar(newSideBar, vars.stockSmryXsListCode)}
  },
  // handle action resize
  handleResize: (vars, {size}) => {
    let tipe = size.resize ? Number(size.resize)/100 : "";
    // console.log(size, tipe)
    // Header 1 : My Account (Landing Page)
    // SubHeader 1 : Investment Board
    if(vars.subscriptionFlags['landingPageInvboard']){
      if(vars.stateLanding == 0){
        let ndata = vars.portofolios.filter(d => d.code !== '-') 
        return {...vars, portofolios: GetRowChange("portfolio", ndata, size.windowHeight)}
      // }else if(vars.stateLanding == 1){
      //   let ndata = vars.fixedIncome.filter(d=>d.serial !== "\xa0")
      //   let cdata = GetRowChange("fixedIncome", ndata, size.windowHeight)
      //   return {...vars, fixedIncome:cdata}
      // }else if(vars.stateLanding == 2){
      //   let ndata = vars.mutualFund.filter(d=>d.code !== "\xa0")
      //   let cdata = GetRowChange("mutualFund", ndata, size.windowHeight)
      //   return {...vars, mutualFund:cdata}
      }
    }
    // SubHeader 2 : Stock & Cash
    if(vars.subscriptionFlags['stockCashPageInvboard']){
      let ndata = vars.stockCash.filter(d => d.codeR !== "\xa0")
      let cdata = GetRowChange("stockCash", ndata, size.windowHeight)
      return {...vars, stockCash: cdata}
    }
    // SubHeader 3 : Historicals
    if(vars.subscriptionFlags['tradeListHistoryPageInvboard']){
      let ndata = vars.tradeListHistory.filter(d => d.code !== "\xa0")
      let cdata = GetRowChange("tradeListHistory", ndata, size.windowHeight)
      return {...vars, tradeListHistory: cdata}
    }
    // SubHeader 4 : Fund Transfer
    // SubHeader 5 : Acount Info

    // Header 2 : market & statistik Page
    // SubHeader 1 : Summary
    // SubHeader 2 : Market Index
    // SubHeader 3 : Member capitalization / Sectoral Index
    // SubHeader 4 : Top Broker

    // Header 3 : STOCK PAGE
    // SubHeader 2 : Stock Watchlist
    // SubHeader 3 : Stock Trade History
    // SubHeader 4 : Stock Trade Summary

    // Header 4 : TRADE Page

    /** ESBN */
    if(vars.subscriptionFlags['EsbnTransHistorical']){
      vars.SBN_HISTORY = fa.addRowEmpty("card-344", vars.SBN_HISTORY.filter(d => d.emptyRow !== ""));
    }else if(vars.subscriptionFlags['EsbnxPagePortfolio']){
      vars.SBN_CONSOLIDATE_PORTFOLIO = fa.addRowEmpty("card-472", vars.SBN_CONSOLIDATE_PORTFOLIO);
    }
  },
  reconnectHandler: (vars,{connection}) => {
    vars.netAction.send(fmsg.reconnect(vars.session_id))
    vars.netActionAux.send(fmsg.reconnect(vars.session_id))

    return{...vars,}
  },
  timerHeaderHandler: (vars) => {
    if(vars.loginState == true && vars.networkState == true){
      // every 30 seconds
      if(vars.timerHeader % 30 === 0){ 
        vars.flippedHeaderStatus = !vars.flippedHeaderStatus
        if(vars.flippedHeaderStatus){ 
          let nextIndex = (vars.indexTickerHeader) % vars.sectoralIndexCode.length;
          let nextCode = vars.sectoralIndexCode[nextIndex]  
          vars.secondBoxHeader = vars.boxDataTickerHeader.filter(d => d.symbol == nextCode)[0]
          vars.indexTickerHeader = vars.indexTickerHeader + 1
        }      
      }
      // // every 5 minutes (300 seconds)
      if(vars.timerHeader % 300 === 0 && !vars.firstLogin){    
        vars.netAction.send({text: "PING"});
        vars.netActionAux.send({text:JSON.stringify({ "session_id": vars.sessionID})})
      }
      return{
        ...vars, timerHeader: vars.timerHeader+1
      }
    }
  },
  timerFooterHandler: (vars) => {
    if(vars.loginState == true && vars.networkState == true){
      if(vars.timerFooter % 3 === 0 ){
        var nextBoxDataFooter = init.boxFooterReady.slice(0,7) 
        if(vars.allSaham.length > 0){
          let nextPage = (vars.timerFooter/2) % vars.sumPageFooter;
          let nstock = showPage(nextPage, 3, vars.allSaham)
          let nCurrency = showPage(nextPage % vars.forexMaxPage, 2, vars.forexListArr)
          let nintIndex = showPage(nextPage % vars.globalIdMaxPage, 2, vars.globalIdListArr)
          let nextCodeFooter = [nstock[0], nstock[1] === undefined ? "-" : nstock[1],  nstock[2] === undefined ? "-" : nstock[2], 
              nCurrency[0], nCurrency[1] === undefined ? "-" :  nCurrency[1], nintIndex[0], nintIndex[1] === undefined ? "-" :  nintIndex[1] ]
          nextBoxDataFooter = GetFooter(nextCodeFooter)
          vars.netActionAux.send(fmsg.ix_stockSummaryXS(nstock));
          vars.netActionAux.send(fmsg.ix_forex(nCurrency));
          vars.netActionAux.send(fmsg.ix_globalIDX(nintIndex));
        }
        // console.log("first",nextBoxDataFooter, vars.boxDataFooter, vars.flippedFooterStatus)
        if(vars.flippedFooterStatus === true ){
          let s = vars.boxReadyFooter;
          s[0] = vars.boxDataFooter[0];
          s[1] = vars.boxDataFooter[1];
          s[2] = vars.boxDataFooter[2];
          s[3] = vars.boxDataFooter[3];
          s[4] = vars.boxDataFooter[4];
          s[5] = vars.boxDataFooter[5];
          s[6] = vars.boxDataFooter[6];
          vars.boxReadyFooter = s          
        }else {
          let s = vars.boxReadyFooter;
          s[7] = vars.boxDataFooter[0];
          s[8] = vars.boxDataFooter[1];
          s[9] = vars.boxDataFooter[2];
          s[10] = vars.boxDataFooter[3];
          s[11] = vars.boxDataFooter[4];
          s[12] = vars.boxDataFooter[5];
          s[13] = vars.boxDataFooter[6];
          vars.boxReadyFooter = s
        }
        vars.flippedFooterStatus = !vars.flippedFooterStatus
        // vars.boxDataFooter = vars.allSaham.length > 0 ? nextBoxDataFooter : init.boxFooterReady.slice(0,7)        
        vars.boxDataFooter = nextBoxDataFooter     
      }
      return{
        ...vars, timerFooter: vars.timerFooter+1,
      }
    }
  },

  //============= actions of Login and Logout  =============
  doLogin: (vars, {userID, password}) => {
    vars.netAction.send(fmsg.login(userID, password));
    if(vars.remembermeStatus){
      sessionStorage.setItem('document', JSON.stringify({"user": userID, "pwd": Base64.encode(password)}))
    }
    return {...vars, userName:userID }
  },
  // action login port 5050
  loginSuccessfulAux: (vars) => {   
    // state Config
    vars.networkAuxState = true;
    $("#stopIntervalLogin").click(); 
    // first request : 1. status trade, 2.Stock List, 3.Index List, 4.Composite Index
    vars.netActionAux.send(fmsg.sx_tradingStatus);
    vars.netActionAux.send(fmsg.ix_tradingStatus);
    vars.netActionAux.send(fmsg.ix_stockList);
    vars.netActionAux.send(fmsg.ix_indexList);
    vars.netActionAux.send(fmsg.ix_forexList);
    vars.netActionAux.send(fmsg.ix_globalIDXList);
  },
  loginFailAux: (vars, {reason}) => {
    console.log("LOGIN ke 5050 Gagal", reason)
    return {...vars, loginErrState: true, loginErrReason: reason}
  },
  reconnectS: (vars, {url, socketID}) => {
    // if(socketID === "aux"){
      // vars.netActionAux.createAndConnect({url: baseAPI.url2})
      vars.netActionAux.send({text:JSON.stringify({ "session_id":vars.sessionID})})
      console.log("reconnectAuxx", url, socketID)
    // }else{
    //   console.log("reconnect", url, socketID)
    // }
    return{...vars, networkAuxState: false}
  },
  getLoginRequestID: (vars, {cbRequestID}) => {
    var cid = vars.loginRequestID;
    cbRequestID(cid);
    return {...vars, loginRequestID: cid + 1}
  },
  handleLogout: (vars) => {
    vars.reconnectStatus = false;
    vars.netAction.send(fmsg.logout(vars.sessionID));
    window.location.reload(); 
  },
  
  //============= Setting Frame, actions of Subscribe & unSubscribe =============
  doSetFrameActive: (vars, {isActive}) => {vars.frameAction.setMainFrameActive(isActive);},
  doSetSubscription: (vars, {subscriptionID, flag}) => {
    var prevFlag = vars.subscriptionFlags[subscriptionID];

    // >>>>>>>>>>>>>> Header 1 : My Account (Landing Page)
    if (subscriptionID == 'landingPage') {
      if (!prevFlag && flag) { // switch on        
      }
      else if (prevFlag && !flag) { // switch off
        vars.portfolioData = {};
      }
    }
    // SubHeader 1 : Investment Board
    if (subscriptionID == 'landingPageInvboard') {
      if (!prevFlag && flag) { // switch on
        if(vars.verifyPinStatus){
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
        if(vars.portofolioInfo.lastReq === "subscribe"){
          vars.portofolioInfo.lastReq = "unSubscribe";
          let diffCode = vars.codePortofolio.filter(d=>vars.sidebarCode.indexOf(d) == -1);
          vars.netActionAux.send(fmsg.ux_stockSummaryXS(diffCode));
        }
      }
    }
    // SubHeader 2 : Stock & Cash
    if (subscriptionID == 'stockCashPageInvboard') {
      if (!prevFlag && flag) { // switch on
        vars.netActionAux.send(fmsg.ix_t1t2);
        vars.constBL = {...vars.constBL, divider: 0.5, hc: 0}
        if(vars.verifyPinStatus){
          let reqCode = vars.stockCash.filter(v => v.codeR !== "\xa0" && (v.lastpriceR == 0 || v.lastpriceR == "-")).map(v => v.codeR)
          if(reqCode.length > 0){
            vars.netActionAux.send(fmsg.ix_stockSummaryXS(reqCode));
          }
          vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
        vars.stateLanding = 'k';
        if(vars.stockCashInfo.lastReq === "subscribe"){
          vars.stockCashInfo.lastReq = "unSubscribe";
          let diffCode = vars.codePortofolio.filter(d=>vars.sidebarCode.indexOf(d) == -1);
          vars.netActionAux.send(fmsg.ux_stockSummaryXS(diffCode));
        }
      }
    }
    // SubHeader 3 : Historicals
    if (subscriptionID == 'tradeListHistoryPageInvboard') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
      }
    }    
    // SubHeader 4 : Fund Transfer
    if (subscriptionID == 'fundTransferPageInvboard') {
      if (!prevFlag && flag) { // switch on
        vars.netActionAux.send(fmsg.ix_t1t2);
        if(vars.tabFundTransfer == 1){
          vars.netAction.send(fmsg.q_cashProfile( vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        }else if(vars.tabFundTransfer == 2){
          let { start, to } = vars.rangeFundTransferList;
          if(start != "" && to != ""){
            vars.netAction.send(fmsg.q_fundTransferlist(vars.regulerId, start, to));
          }
        }else if(vars.tabFundTransfer == 5){
          let { start, to } = vars.rangeFundTransferSTL;
          if(start != "" && to != ""){
            vars.netAction.send(fmsg.q_stockTransfer(vars.regulerId, start, to));
          }
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
      }
    }
    if (subscriptionID == 'WarrantRightPageInvboard') {
      if (!prevFlag && flag) { // switch on
        if(vars.exerciseState.tab === 2 && vars.exerciseRange){
          let params = { ...vars.exerciseRange, "client_id": vars.regulerId};
          vars.netAction.send(fmsg.q_exerciseRightRequest(params))
        }else if(vars.exerciseState.tab == 1){
          vars.exerciseState = {tab: 1};
          vars.netAction.send(fmsg.q_exerciseRightList(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
      }
    }
    
    // SubHeader 5 : Acount Info
    if (subscriptionID == 'inquryAccountPageInvboard') {
      if (!prevFlag && flag) { // switch on
        vars.netAction.send(fmsg.q_userProfile(vars.regulerId));
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";
      }
    }
    // SubHeader 5.5 : TRADE PL
    if (subscriptionID == 'tradePLPageInvboard') {
      if (!prevFlag && flag) { // switch on
        if(vars.tradePl === undefined){
          vars.tradePl = fa.addRowEmpty('card-tradePL')
        }
        let { start, to } = vars.rangeTradePl;
        if(start != "" || to != ""){
          vars.netAction.send(fmsg.q_tradePL(vars.regulerId, start, to));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.pinPage = "";

      }
    }
    
    // SubHeader 6 : TC & SOE
    if (subscriptionID == 'InvboardTcAndSoa') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    
    // >>>>>>>>>>>>>> Header 2.5 : BROKER Page
    if (subscriptionID == 'brokerPage') {
      if (!prevFlag && flag) { // switch on
        if(vars.brokerList.length <= 0){
          vars.netActionAux.send(fmsg.ix_brokerList);
        }
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 1: BrokerInfo
    if (subscriptionID == 'brokerInfo') {
      if (!prevFlag && flag) { // switch on
        if(vars.codeBrokerInfo != ""){
          vars.netActionAux.send(fmsg.ix_brokerInfo(vars.codeBrokerInfo.code));
        }
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 2: BrokerTradeSummary
    if (subscriptionID == 'brokerTradeSummary') {
      if (!prevFlag && flag) { // switch on
        if(vars.codeBrokerInfo != ""){
          vars.netActionAux.send(fmsg.ix_brokerStockBuy(vars.codeBrokerInfo.code));
          vars.netActionAux.send(fmsg.ix_brokerStockSell(vars.codeBrokerInfo.code));
        }
        }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 3: BrokerTradeHistory
    if (subscriptionID == 'brokerTradeHistory') {
      if (!prevFlag && flag) { // switch on
        let { start, to } = vars.rangeBrokerTradeHistory;
        if(vars.codeBrokerInfo != "" && start != "" && to != ""){
          vars.netActionAux.send(fmsg.ix_brokerHistory(vars.codeBrokerInfo.code, start, to));
        }
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 4: BrokerTopListPage
    if (subscriptionID == 'brokerTopListPage') {
      if (!prevFlag && flag) { // switch on
        //broker val, broker buy, broker sell
        vars.netActionAux.send(fmsg.ix_topBrokerVal);
        vars.netActionAux.send(fmsg.ix_topBrokerBuy);
        vars.netActionAux.send(fmsg.ix_topBrokerSell);
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    
    // >>>>>>>>>>>>>> Header 2 : market & statistik Page
    if (subscriptionID == 'marketstatistikPage') {
      if (!prevFlag && flag) { // switch on       
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 1 : Summary
    if (subscriptionID == 'marketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        vars.netActionAux.send(fmsg[`ix_${vars.topType}_${vars.topChoice}`]);
        vars.topGAL ? vars.boardChoice = "RG" : vars.topGAL = fa.addRowEmpty("card-478");
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 2 : Market Index
    if (subscriptionID == 'statisticMarketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        let checkIndex = vars.boxDataTickerHeader.filter(d => d.symbol == vars.codeSearchMarketIndex)
        if(checkIndex.length > 0){
          vars.indexData = checkIndex[0]
        }else{
          vars.netActionAux.send(fmsg.sx_indexS(vars.codeSearchMarketIndex))
        }
        vars.netActionAux.send(fmsg.ix_marketStat)        
        vars.netActionAux.send(fmsg.ix_indexPeriodic(vars.codeSearchMarketIndex))          
        if(vars.streamStatus === true && vars.codeSearchMarketIndex !== ''){
          vars.netActionAux.send(fmsg.sx_indexPeriodic(vars.codeSearchMarketIndex))          
        }
      }
      else if (prevFlag && !flag) { // switch off
        let checkIndex = vars.boxDataTickerHeader.filter(d=>d.symbol == vars.codeSearchMarketIndex)
        if(vars.streamStatus === true && vars.codeSearchMarketIndex !== ''){
          vars.netActionAux.send(fmsg.ux_indexPeriodic(vars.codeSearchMarketIndex))   
          vars.firstDataStream = '';       
        }
        if(checkIndex.length == 0){
          vars.netActionAux.send(fmsg.ux_indexS(vars.codeSearchMarketIndex))
        }
      }
    }
    // SubHeader 3 : Member capitalization / Sectoral Index
    if (subscriptionID == 'indiceMarketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        if(Object.values(vars.indices).length === 0){
          vars.indices = fa.SetSectoralIndex(vars.sectoralIndexCode)
        }
        vars.topGAL = fa.addRowEmpty("card-202");
        vars.netActionAux.send(fmsg.sx_index(vars.sectoralIndexCode));
        vars.netActionAux.send(fmsg.ix_indexStockVal(vars.sectorName));
      }
      else if (prevFlag && !flag) { // switch off
        vars.netActionAux.send(fmsg.ux_index(vars.sectoralIndexCode));
        vars.topGAL = fa.addRowEmpty("card-478");
      }
    }  
    // SubHeader 3 : Member capitalization / Sectoral Index
    if (subscriptionID == 'nonSectoralStatistikPage') {
      if (!prevFlag && flag) { // switch on
        vars.topGAL = fa.addRowEmpty("card-202");
        if(Object.values(vars.nonIndices).length === 0){
          vars.sectoralIndex = fa.addRowEmpty("grid-294")
        }
        vars.netActionAux.send(fmsg.ix_index(vars.nonSectoralIndexCode));
        vars.nonsectorName && vars.netActionAux.send(fmsg.ix_indexStockVal(vars.nonsectorName));
      }
      else if (prevFlag && !flag) { // switch off
        vars.topGAL = fa.addRowEmpty("card-478");
      }
    } 

    // SubHeader 4 : Top Broker
    if (subscriptionID == 'topBrokerMarketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        //broker val, broker buy, broker sell
        vars.netActionAux.send(fmsg.ix_topBrokerVal);
        vars.netActionAux.send(fmsg.ix_topBrokerBuy);
        vars.netActionAux.send(fmsg.ix_topBrokerSell);
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // MARKET & STATISTIC: INT.INDICES
    if (subscriptionID == 'intIndicesMarketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        if(vars.tabIntIndices === 1){
          if(vars.intIndex === undefined){
            vars.intIndex = fa.GetIntIndex(vars.globalIdList);
          }
          vars.netActionAux.send(fmsg.ix_globalIDX(vars.globalIdListArr));
        }else{
        }
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    
    // MARKET & STATISTIC: CURRENCY & COMMODITY
    if (subscriptionID == 'currencyCommodityMarketStatistikPage') {
      if (!prevFlag && flag) { // switch on
        vars.currencies = fa.GetCurrencies(vars.forexList);
        vars.netActionAux.send(fmsg.ix_forex(vars.forexListArr));
        vars.netActionAux.send(fmsg.ix_comodityList);
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // Sub 1 : General News - SubHeader  News & Research
    if (subscriptionID == 'newsGeneral') {
      if (!prevFlag && flag) { // switch on
        vars.netActionAux.send(fmsg.sx_news);
      }
      else if (prevFlag && !flag) { // switch off
        vars.netActionAux.send(fmsg.ux_news);
        vars.news = {}
        vars.genNews = [{ time: "", no: "", subject: '', highlight: '', content: ''}];
      }
    }
    // Sub 2 : Stock News - SubHeader  News & Research
    if (subscriptionID == 'newsStock') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // Sub 3 : Mutual Fund News - SubHeader  News & Research
    if (subscriptionID == 'newsMutualFund') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
      }
    }

    // >>>>>>>>>>>>>> Header 3 : STOCK PAGE
    if (subscriptionID == 'stockPage') {
      if (!prevFlag && flag) { // switch on   
      }
      else if (prevFlag && !flag) { // switch off
      }
    }

    // SubHeader 1 : Stock Info
    if (subscriptionID == 'stockInfoPage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, page: true};
        if(vars.codeSearchStockInfo.length !== 0){
          vars.bidOfferCode = vars.codeSearchStockInfo
          let ncode = strCall.code(vars.codeSearchStockInfo)
          vars.bidOfferMkt = strCall.codeMarket(vars.codeSearchStockInfo);
          vars.bidOfferExpire = strCall.codeExpire(vars.codeSearchStockInfo);
          vars.haircutCode = fa.getHaircut(vars.haircutData, ncode);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.codeSearchStockInfo);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.codeSearchStockInfo), 
            {code: vars.codeSearchStockInfo, ...refStock})
          
          vars.netActionAux.send(fmsg.ix_stockData(ncode));    
          // vars.netActionAux.send(fmsg.ix_stockChart(ncode));
          vars.netActionAux.send(fmsg.sx_stockSummary(ncode));          
          vars.netActionAux.send(fmsg.sx_bid(vars.codeSearchStockInfo));
          vars.netActionAux.send(fmsg.sx_offer(vars.codeSearchStockInfo));
          vars.netActionAux.send(fmsg.sx_stockiepv(vars.codeSearchStockInfo));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, page: false};
        if(vars.codeSearchStockInfo.length !== 0){
          vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
        }
        vars.haircutCode = 0; vars.bidOfferCode = ""; vars.iStockData = {}; vars.sStockSmry = {}; vars.bidData = []; vars.offerData = []; vars.biOfferPrice = 0;
        vars.bidOfferVal = 0; vars.bidOfferVol = 0; vars.portfolioData = fd.portfolio; vars.bidOfferMkt = '0RG'; vars.bidOfferExpire = '0';vars.order_type = "7";
        vars.prevStatus = true; vars.offerAlldStatus = false;
      }
    }
    // Sub 1 : Stock Info - SubHeader  Stock Info
    if (subscriptionID == 'stockInfoTable') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off  
      }
    }
    // Sub 2 : Profile - SubHeader  Stock Info
    if (subscriptionID == 'profilTable') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // Sub 3 : Corp Action - SubHeader  Stock Info
    if (subscriptionID == 'stockCorpAction') {
      if (!prevFlag && flag) { // switch on
        if(vars.rangeCorpAction === undefined){
          vars.CORPORATE_ACTION_LIST = fa.addRowEmpty("CORPORATE_ACTION_LIST", []);
          vars.rangeCorpAction = { start: GetTimeForDateTicker(0,0), to: GetTimeForDateTicker(-2,0) };
          $("#datepickerstartCA").datepicker("setDate", vars.rangeCorpAction.start);
          $("#datepickerendCA").datepicker("setDate", vars.rangeCorpAction.to);
        }
        let { start, to } = vars.rangeCorpAction;
        vars.netAction.send(fmsg.q_corpAction(start, to));
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // dailyTechnicalTable
    if (subscriptionID == 'dailyTechnicalTable') {
      if (!prevFlag && flag) { // switch on
        if(vars.codeSearchStockInfo.length !== 0){
          vars.netActionAux.send(fmsg.ix_techDay([vars.codeSearchStockInfo]));
        }
      }
      else if (prevFlag && !flag) { // switch off
      }
    }

    // SubHeader 1.5 :stockFinancialStatement
    if (subscriptionID == 'stockFinancialStatement') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, page: true};
        if(vars.codeSearchStockInfo.length !== 0){
          vars.bidOfferCode = vars.codeSearchStockInfo
          let ncode = strCall.code(vars.codeSearchStockInfo)
          vars.bidOfferMkt = strCall.codeMarket(vars.codeSearchStockInfo);
          vars.bidOfferExpire = strCall.codeExpire(vars.codeSearchStockInfo);
          vars.haircutCode = fa.getHaircut(vars.haircutData, ncode);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.codeSearchStockInfo);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.codeSearchStockInfo), 
            {code: vars.codeSearchStockInfo, ...refStock});

          vars.netActionAux.send(fmsg.ix_stockData(ncode));
          vars.netActionAux.send(fmsg.sx_stockSummary(ncode));          
          vars.netActionAux.send(fmsg.sx_bid(vars.codeSearchStockInfo));
          vars.netActionAux.send(fmsg.sx_offer(vars.codeSearchStockInfo));
          vars.netActionAux.send(fmsg.sx_stockiepv(vars.codeSearchStockInfo));
          
          // request financial report
          let params = vars.stockFinancial.tab == 1 ? {"stock_code": strCall.code(ncode), "quarter": vars.stockFinancial.valQtr} : {"stock_code": strCall.code(ncode), "year": vars.stockFinancial.valYear}
          vars.netAction.send(fmsg.q_financialReport(params));          
        }      
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, page: false};
        if(vars.codeSearchStockInfo.length !== 0){
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
        }
        vars.haircutCode = 0; vars.bidOfferCode = ""; vars.iStockData = {}; vars.sStockSmry = {}; vars.bidData = []; vars.offerData = []; vars.biOfferPrice = 0;
        vars.bidOfferVal = 0; vars.bidOfferVol = 0; vars.portfolioData = fd.portfolio; vars.bidOfferMkt = '0RG'; vars.bidOfferExpire = '0';vars.order_type = "7";
        vars.prevStatus = true; vars.offerAlldStatus = false;
      }
    }
    // SubHeader 2 : Stock Watchlist
    if (subscriptionID == 'stockWatchlistPage') {      
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, page: true};
        let cekCode = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
        let code = cekCode.length > 0 && vars.codeSearchStockInfo.length == 0 ? cekCode[0] : vars.codeSearchStockInfo;
        if(code.length > 0){
          vars.haircutCode = fa.getHaircut(vars.haircutData, strCall.code(code));
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.code === code), code)
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === code);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === code), {code: code, ...refStock})

          // UPDATE FORM BUY/SELL
          vars.bidOfferPrice = 0; vars.bidOfferCode = code; 
          vars.bidOfferMkt = strCall.codeMarket(code);
          vars.bidOfferExpire = strCall.codeExpire(code);
          vars.bidOfferVal =  0; vars.prevStatus = true; vars.offerAlldStatus = false; vars.bidOfferVol = 0;
          vars.constBL.hc = vars.haircutCode;
          vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          vars.formBuyFooter = {...vars.formBuyFooter, cashOn: (vars.constBL.balance - vars.constBL.OB), remainTrade: fa.GetBuyLimit(vars.constBL, "setSub-stockWatchlistPage")};
          vars.formSellFooter = {...vars.formSellFooter, value: vars.portfolioData.valFormSell, pl: vars.portfolioData.plFormSell,
            ownQtyL: vars.portfolioData.slot, ownQtyS: vars.portfolioData.sshares, avgprice: vars.portfolioData.avgprice, perPl: vars.portfolioData.perPlFormSell,
          };
          // setPage Watchlist
          vars.codeSearchStockInfo = code;
          let thisGroupCodeSidebar = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
          vars.sumPageWatchlist = Math.ceil(thisGroupCodeSidebar.length/vars.maxPageWatchlist);
          vars.pageWatchlistCode = showPage(vars.thisPageWatchlist, vars.maxPageWatchlist, thisGroupCodeSidebar);
          let arrCode = vars.pageWatchlistCode.findIndex(d => d == strCall.code(code)) <= -1 ? [...vars.pageWatchlistCode, strCall.code(code)] : vars.pageWatchlistCode;
          
          // Subcribe and inquiry data for info stock
          vars.netActionAux.send(fmsg.sx_stockSummary(arrCode));
          vars.netActionAux.send(fmsg.ix_stockData(strCall.code(code)));
          vars.netActionAux.send(fmsg.sx_bid(code));
          vars.netActionAux.send(fmsg.sx_offer(code));
          vars.netActionAux.send(fmsg.sx_stockiepv(code));
        }else{
          vars.bidOfferPrice = vars.bidOfferCode.length == 0 || vars.codeSearchStockInfo.length == 0 ? 0 : vars.bidOfferPrice;
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, page: false};
        // unsubscribe code stocksmrys, bid & offer
        let arrCode = vars.pageWatchlistCode.findIndex(d => d == strCall.code(vars.bidOfferCode)) <= -1 ? [...vars.pageWatchlistCode, strCall.code(vars.bidOfferCode)] : vars.pageWatchlistCode;
        if(arrCode[0] != ""){
          vars.netActionAux.send(fmsg.ux_stockSummary(arrCode));
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
        }

        vars.haircutCode = 0; vars.bidOfferCode = ""; vars.iStockData = {}; vars.sStockSmry = {}; vars.bidData = []; vars.offerData = []; vars.biOfferPrice = 0;
        vars.bidOfferVal = 0; vars.bidOfferVol = 0; vars.portfolioData = fd.portfolio; vars.bidOfferMkt = '0RG'; vars.bidOfferExpire = '0';vars.order_type = "7";
        vars.prevStatus = true; vars.offerAlldStatus = false; vars.codeBlink = 'cb';
      }
    }
    // SubHeader 3 : Stock Trade History
    if (subscriptionID == 'stockHistoryPage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, stockTradeHis: true};
        if(vars.stockTradeHistoryMain === undefined){
          vars.stockTradeHistoryMain = fa.addRowEmpty("card-372", [], 27.5)
        } 
        if(vars.codeSearchStockInfo.length !== 0 ){
          let newCode = strCall.code(vars.codeSearchStockInfo);
          vars.rangeStockTradeHistory = vars.rangeStockTradeHistory.start == "" ? {start: GetTimeForDateTicker(3,1), to: GetTimeForDateTicker(0,1)} : vars.rangeStockTradeHistory;
          $("#datepickerstartSH").datepicker("setDate", vars.rangeStockTradeHistory.start);
          $("#datepickerendSH").datepicker("setDate", vars.rangeStockTradeHistory.to);
            
          let { start, to } = vars.rangeStockTradeHistory;
          if(vars.tabStockTradeHistory === 1){
            vars.netActionAux.send(fmsg.ix_tradeDaily(newCode, start, to));
          }else if(vars.tabStockTradeHistory === 2){
            vars.netActionAux.send(fmsg.ix_tradeAll(newCode, start, to));
          }else if(vars.tabStockTradeHistory === 3){
            vars.netActionAux.send(fmsg.ix_tradeForeign(newCode, start, to));
          }      
          vars.netActionAux.send(fmsg.ix_stockSummary(newCode));
          vars.netActionAux.send(fmsg.ix_tradeBuy(newCode, start, to));
          vars.netActionAux.send(fmsg.ix_tradeSell(newCode, start, to));
          vars.netActionAux.send(fmsg.ix_tradePrice(newCode, start, to));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, stockTradeHis: false};
      }
    }
    // SubHeader 4 : Stock Trade Summary
    if (subscriptionID == 'stockTradeSummaryPage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, stockTradeSum: true};
        if(vars.codeSearchStockInfo.length !== 0 ){
          vars.stockTickBoardType = strCall.codeType(vars.codeSearchStockInfo) === "TN" ? "TN" : "ALL"; 
          let nstockTickBoardType = vars.stockTickBoardType === "ALL" ? "" : "."+vars.stockTickBoardType
          if(vars.stockTickBoardType == "ALL"){
            vars.netActionAux.send(fmsg.ix_stockPrice(strCall.code(vars.codeSearchStockInfo)));           
            vars.netActionAux.send(fmsg.ix_stockBrokerBuy(strCall.code(vars.codeSearchStockInfo)));           
            vars.netActionAux.send(fmsg.ix_stockBrokerSell(strCall.code(vars.codeSearchStockInfo)));           
          }          
          vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(vars.codeSearchStockInfo)));
          vars.netActionAux.send(fmsg.sx_rtradeS(strCall.code(vars.codeSearchStockInfo), nstockTickBoardType)); 

          vars.stockTradeSummaryMain = init.stockTradeSummary; vars.stockTickMain = init.stockTickMain;
          vars.stockSummaryBuyerMain = init.stockSummaryBuyer; vars.stockSummarySellerMain = init.stockSummaryBuyer;
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, stockTradeSum: false};
        if(vars.codeSearchStockInfo !== ''){
          vars.stockTickBoardType = strCall.codeType(vars.codeSearchStockInfo) === "TN" ? "TN" : vars.stockTickBoardType;
          let nstockTickBoardType = vars.stockTickBoardType === "ALL" ? "" : "." + vars.stockTickBoardType
          vars.netActionAux.send(fmsg.ux_rtradeS(vars.codeSearchStockInfo, nstockTickBoardType));
          vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.codeSearchStockInfo))); 
        }
        vars.stockTradeSummaryMain = init.stockTradeSummary;
      }
    }
    // SubHeader 5 : stock Special Notation
    if (subscriptionID == 'stockSpecialNotation') {
      if (!prevFlag && flag) { // switch on
        if(!(vars.specialNotation)){
          vars.specialNotation = fa.addRowEmpty("card-specialNotation")
        }
        vars.netActionAux.send(fmsg.ix_stockWatchlist);
      }
      else if (prevFlag && !flag) { // switch off
        console.log("Unsub stockSpecialNotation")
      }
    }

    // >>>>>>>>>>>>>> Header 4 : TRADE Page
    // Manual Order => Sub 1 : Order List
    if (subscriptionID == 'tradePageManOrderbook') {
      if (!prevFlag && flag) { // switch on 
        if((vars.regulerId !== "" || vars.marginId !== "") && vars.verifyPinStatus) {
          let clientId = vars.GeneralType ? vars.regulerId : vars.marginId  
          vars.netAction.send(fmsg.q_orderList(clientId));            
          vars.netAction.send(fmsg.q_trades(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.lastSubHeaderManual = 'tradePageManOrderbook';
        vars.pinPage = "";
      }
    }
    // Manual Order => Sub 2 : Order Book /BID/OFFER
    if (subscriptionID == 'tradePageManWatchlist') {
        vars.onClickSidebar = {...vars.onClickSidebar, tradeBidOffer: true};
      if (!prevFlag && flag) { // switch on
        if(vars.watchlistCode.length != 0 || vars.codeForOB1 != '' || vars.codeForOB2 != '' || vars.codeForOB3 != '' ){
          let arrCode = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
            vars.codeForOB1 = vars.codeForOB1 != '' ? vars.codeForOB1 : arrCode[0] === undefined ? "" : arrCode[0];
            vars.codeForOB2 = vars.codeForOB2 != '' ? vars.codeForOB2 : arrCode[1] === undefined ? "" : arrCode[1];
            vars.codeForOB3 = vars.codeForOB3 != '' ? vars.codeForOB3 : arrCode[2] === undefined ? "" : arrCode[2];
            let codeBO = [vars.codeForOB1, vars.codeForOB2, vars.codeForOB3].filter(k => k != "")
            let codeIn = [strCall.code(vars.codeForOB1), strCall.code(vars.codeForOB2), strCall.code(vars.codeForOB3)].filter(k => k != "")
            // request data OrderBook : stock data, bid, offer,
            vars.netActionAux.send(fmsg.ix_stockData(codeIn));
            vars.netActionAux.send(fmsg.sx_stockSummary(codeIn));
            vars.netActionAux.send(fmsg.sx_offer(codeBO));
            vars.netActionAux.send(fmsg.sx_bid(codeBO));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, tradeBidOffer: false};
        vars.lastSubHeaderManual = 'tradePageManWatchlist';
        let codeBO = [vars.codeForOB1, vars.codeForOB2, vars.codeForOB3].filter(k=>k != "")
        let codeIn = [strCall.code(vars.codeForOB1), strCall.code(vars.codeForOB2), strCall.code(vars.codeForOB3)].filter(k=>k != "")

        // unsubscribe OrderBook : stock data, bid, offer,
        if(codeBO.length != 0){
          vars.netActionAux.send(fmsg.ux_stockSummary(codeIn));
          vars.netActionAux.send(fmsg.ux_offer(codeBO));
          vars.netActionAux.send(fmsg.ux_bid( codeBO));
        }
        vars.haircutCode = 0; vars.bidOfferCode = ""; vars.iStockData = {}; vars.sStockSmry = {}; vars.bidData = []; vars.offerData = [];
        vars.biOfferPrice = 0; vars.bidOfferVal = 0; vars.bidOfferVol = 0; vars.portfolioData = fd.portfolio; vars.bidOfferMkt = '0RG';
        vars.bidOfferExpire = '0';vars.order_type = "7"; vars.prevStatus = true; vars.offerAlldStatus = false;
      }
    }
    // Manual Order => Sub 3 : TRADE P/L
    if (subscriptionID == 'tradePagePL') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
        vars.lastSubHeaderManual = 'tradePagePL';
      }
    }
    // Manual Order => Sub 4 : TRADE Advertisment
    if (subscriptionID == 'tradePageAdv') {
      if (!prevFlag && flag) {
        vars.onClickSidebar = {...vars.onClickSidebar, tradePageAdv: true};
      }
      else if (prevFlag && !flag) { // switch off
        vars.lastSubHeaderManual = 'tradePageAdv';
        vars.onClickSidebar = {...vars.onClickSidebar, tradePageAdv: false};
      }
    }    
    // Manual Order => Sub 4 : ORDER BOOKING LIST
    if (subscriptionID == 'tradePageOrderBookList') {
      if (!prevFlag && flag) { // switch on
        if(vars.verifyPinStatus) {
          vars.netAction.send(fmsg.q_orderBookingList(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.lastSubHeaderManual = 'tradePageOrderBookList';       
        vars.pinPage = "";
      }
    }    
    // Automatic Order => Sub 5 : Order Setting
    if (subscriptionID == 'AutOrderSetting') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, page: true};
        let cekCode = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
        let code = cekCode.length != 0 && vars.codeSearchAutomatic == "" ? cekCode[0] : vars.codeSearchAutomatic;
        vars.bidOfferCode = code;
        vars.codeSearchAutomatic = code;
        if(vars.verifyPinStatus && code != ""){
          let codeType = vars.transactionType === "TN" ? vars.bidOfferCode + ".TN" : vars.bidOfferCode;
          vars.haircutCode = fa.getHaircut(vars.haircutData, vars.bidOfferCode);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === code);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === code), {code: code, ...refStock})
          
          vars.netActionAux.send(fmsg.sx_stockSummary(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ix_stockData(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.sx_bid(codeType));
          vars.netActionAux.send(fmsg.sx_offer(codeType));
          vars.netAction.send(fmsg.ao_listRule(vars.regulerId));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, page: false};
        vars.lastSubHeaderAuth = 'AutOrderSetting';
        if(vars.codeSearchAutomatic != ""){
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_stockSummary(vars.bidOfferCode));
          let ucodeType = vars.transactionType === "TN" ? vars.bidOfferCode + ".TN" : vars.bidOfferCode
          vars.netActionAux.send(fmsg.ux_bid(ucodeType));
          vars.netActionAux.send(fmsg.ux_offer(ucodeType));
        }
        vars.haircutCode = 0; vars.codeSearchAutomatic = vars.bidOfferCode; vars.rulePrice = 'LP'; vars.setPriceAutomatic = 0; vars.bidOfferCode = ""; vars.iStockData = {};
        vars.sStockSmry = {}; vars.portfolioData = fd.portfolio; vars.bidData = []; vars.offerData = []; vars.biOfferPrice = 0; vars.bidOfferVal = 0;
        vars.bidOfferVol = 0; vars.offerAlldStatus = false;
      }
    }
    // Automatic Order => Sub 6 : Send Order
    if (subscriptionID == 'AutSentOrder') {
      if (!prevFlag && flag) { // switch on
        let { start, to } = vars.rangetradeSendOrder;
        if(vars.verifyPinStatus && start != "" && to != ""){
          vars.netAction.send(fmsg.ao_executedRule(vars.regulerId, start, to));
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.lastSubHeaderAuth = 'AutSentOrder';
      }
    }

    // >>>>>>>>>>>>>> Header 5 : ANALYTIC CHART Page
    if (subscriptionID == 'analyticPage') {
      if (!prevFlag && flag) { // switch on
      }
      else if (prevFlag && !flag) { // switch off
      }
    }
    // SubHeader 1 : STOCK CHART
    if (subscriptionID == 'StockAnalyticPage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, analytic: true};
        vars.chartList = vars.stockList
        vars.analyticType = "singleChart";
        if(vars.lastCodeChart){
          vars.netActionAux.send(fmsg.ix_techDay([vars.lastCodeChart]));
          vars.netActionAux.send(fmsg.ix_stockSummaryXS(vars.lastCodeChart));
        }else{
          vars.techDayData = {}
        }
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, analytic: false};
        vars.chartList = vars.stockList
        vars.analyticType = "singleChart";
      }
    }
    // SubHeader 2 : MULTI CHART
    if (subscriptionID == 'AnalyticPage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, analytic: true};
        vars.chartList = vars.stockList
        vars.analyticType = "multiChart";
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, analytic: false};
        vars.chartList = vars.stockList
        vars.analyticType = "multiChart";
      }
    }
    // SubHeader 3 : INDEX Chart
    if (subscriptionID == 'IndiceAnalyticPage') {
      if (!prevFlag && flag) { // switch on
        vars.chartList = vars.indexList
        vars.analyticType = "singleChart";
      }
      else if (prevFlag && !flag) { // switch off
        vars.chartList = vars.stockList
        vars.analyticType = "singleChart";
      }
    }
    // SubHeader 4 : RELATIVE CHART
    if (subscriptionID == 'RelativePerformanceAnalyticPage') {
      if (!prevFlag && flag) { // switch on
        vars.chartList = vars.stockList
        vars.analyticType = "relativeChart";
      }
      else if (prevFlag && !flag) { // switch off
        vars.chartList = vars.stockList
        vars.analyticType = "relativeChart";
      }
    }

    // >>>>>>>>>>>>>> Header 6 :LIVE TRADE Page
    if (subscriptionID == 'livetradePage') {
      if (!prevFlag && flag) { // switch on
        vars.onClickSidebar = {...vars.onClickSidebar, page: true};
        // Subscribe Running Trade
        let code = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
        if(vars.runningStatus){
          if(vars.tradeType ==='all'){
            vars.livetradeContainer = vars.tradeAll
            vars.netActionAux.send(fmsg.sx_rtrade);
          }
          if(vars.tradeType === "watchlist"){
            vars.livetradeContainer = vars.tradeWatchlist
            vars.netActionAux.send(fmsg.sx_rtrade);
          }
          if(vars.tradeType === "foreign"){
            vars.livetradeContainer = vars.tradeForeign
            vars.netActionAux.send(fmsg.sx_rtrade);
          }
          if(vars.tradeType === "nonRG"){
            vars.livetradeContainer = vars.tradeNonRg
            vars.netActionAux.send(fmsg.sx_rtrade);
          }
          if(vars.tradeType === "tick") {
            code.length > 0 && vars.netActionAux.send(fmsg.sx_rtradeS(code[0]));  
            vars.livetradeContainer = vars.tradeTick
          }
        }
        // get Code for Transaction
        if(code.length > 0){          
          // Subcribe and inquiry data for info stock
          vars.bidOfferMkt = strCall.codeMarket(code[0]);
          vars.bidOfferExpire = strCall.codeExpire(code[0]);
          vars.haircutCode = fa.getHaircut(vars.haircutData, code[0]);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === code[0]);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === code[0]), {code: code[0], ...refStock});

          vars.netActionAux.send(fmsg.sx_stockSummary(code[0]));
          vars.netActionAux.send(fmsg.ix_stockData(code[0]));
          vars.netActionAux.send(fmsg.sx_bid(code[0]));
          vars.netActionAux.send(fmsg.sx_offer(code[0]));
          
          vars.bidOfferCode = code[0]
        }       
      }
      else if (prevFlag && !flag) { // switch off
        vars.onClickSidebar = {...vars.onClickSidebar, page: false};
        // Unsubscribe Running Trade
        if(vars.runningStatus){
          if(vars.tradeType === "tick" && vars.bidOfferCode !== '' ){
            vars.netActionAux.send(fmsg.ux_rtradeS(strCall.code(vars.bidOfferCode))); 
          }else{
            vars.netActionAux.send(fmsg.ux_rtrade);
          }
        }
        if(vars.bidOfferCode !== ''){
          // unsubscribe data for transactions
          vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));        
  
          vars.bidOfferCode = ''; vars.transactionType = ''; vars.iStockData = {}; vars.sStockSmry = {}; vars.portfolioData = fd.portfolio;
          vars.bidData = []; vars.offerData = []; vars.bidOfferPrice = 0; vars.bidOfferVol = 0;vars.bidOfferVal = 0; vars.bidOfferMkt = '0RG';
          vars.bidOfferExpire = '0';vars.order_type = "7"; vars.prevStatus = true; vars.offerAlldStatus = false;
        }
        vars.tradeAll = init.lvtrade; vars.tradeWatchlist = init.lvtrade; vars.tradeForeign = init.lvtrade;
        vars.tradeTick = init.lvtrade; vars.tradeNonRg = init.lvtrade;
      }
    }

    /** ESBN */
    if (subscriptionID == 'esbnPage') {
        if (!prevFlag && flag) { // switch on
          if(vars.checkStatusEsbn !== 3){
            vars.isLoadingStatusESBN = true;
            vars.netAction.send(fmsg.sbn_account(vars.SID));
          }
        }else if (prevFlag && !flag) { // switch off
        }
    }
    if (subscriptionID == 'EsbnPage') {
      if (!prevFlag && flag) { // switch on
        // console.log("EsbnPage")
      }else if (prevFlag && !flag) { // switch off
      }
    }
    if (subscriptionID == 'EsbnxPageTransaction') {
      if (!prevFlag && flag) { // switch on
        // console.log("EsbnxPageTransaction")
      }else if (prevFlag && !flag) { // switch off
      }
    }
    if (subscriptionID == 'EsbnxPageHistorical') {
        if (!prevFlag && flag) { // switch on
            console.log("EsbnxPageHistorical")
        }else if (prevFlag && !flag) { // switch off
        }
    }
    if (subscriptionID == 'EsbnTransSubscribe') {
        if (!prevFlag && flag && vars.verifyPinStatus) { // switch on
            vars.netAction.send(fmsg.sbn_list);
        }else if (prevFlag && !flag) { // switch off
        }
    }
    if (subscriptionID == 'EsbnTransHistorical') {
        if (!prevFlag && flag) { // switch on
          if(!(vars.SBN_HISTORY)){
            vars.SBN_HISTORY = fa.addRowEmpty("card-344");
          }
        }else if (prevFlag && !flag) { // switch off
        }
    }
    if (subscriptionID == 'EsbnxPagePortfolio') {
        if (!prevFlag && flag) { // switch on
          vars.verifyPinStatus && vars.netAction.send(fmsg.sbn_consolidatePortfolio(vars.SID));
          if(!(vars.SBN_CONSOLIDATE_PORTFOLIO)){
            vars.SBN_CONSOLIDATE_PORTFOLIO = fa.addRowEmpty("card-472");
          }
        }else if (prevFlag && !flag) { // switch off
        }
    }

    /** MUTUAL FUND / APERD */
    if(subscriptionID === "MutualRegistPage"){
      if (!prevFlag && flag) {
        if(vars.registerMutualFund){
          vars.isLoadingAperd = true;
          vars.netAction.send(fmsg.aperd_custStatus(vars.SID));
        }
      }
    }
    if(subscriptionID === "MutualFundxPageEtalase"){
      if (!prevFlag && flag) {
        vars.netAction.send(fmsg.aperd_getIM);
        vars.netAction.send(fmsg.aperd_getPortfolio(vars.SID));
        vars.netAction.send(fmsg.aperd_listMutualFund("0", "50"));
        if(vars.rangeAperdHistory === undefined){
          let start_date = timeCall.getdate("/", -1);
          let end_date = timeCall.getdate("/");
          vars.rangeAperdHistory = { start_date, end_date };
          $("#rangedatestart").datepicker("setDate", start_date);
          $("#rangedateend").datepicker("setDate", end_date);
        }
        vars.netAction.send(fmsg.aperd_historyTransaction(vars.SID, vars.rangeAperdHistory))
      }
    }
    if(subscriptionID === "MutualFundxPageCompare"){
      if (!prevFlag && flag) {
        !(vars.listFundType) && vars.netAction.send(fmsg.aperd_getFundType);
      }
    }
    if(subscriptionID === "MutualFundxPagePortfolio"){
      if (!prevFlag && flag) {
        vars.verifyPinStatus && vars.netAction.send(fmsg.aperd_getPortfolio(vars.SID));
        if(vars.rangeAperdHistory === undefined){
          let start_date = timeCall.getdate("/", -1);
          let end_date = timeCall.getdate("/");
          vars.rangeAperdHistory = { start_date, end_date };
          $("#rangedatestart").datepicker("setDate", start_date);
          $("#rangedateend").datepicker("setDate", end_date);
        }
        vars.netAction.send(fmsg.aperd_historyTransaction(vars.SID, vars.rangeAperdHistory))
      }
    }
    if(subscriptionID === "MutualFundxPageHistoris"){
      if (!prevFlag && flag) {
        if(vars.HISTORY_MUTUAL_FUND === undefined){
            vars.HISTORY_MUTUAL_FUND = fa.addRowEmpty("card-472");
        }
        if(vars.rangeAperdHistory === undefined){
          let start_date = timeCall.getdate("/", -1);
          let end_date = timeCall.getdate("/");
          vars.rangeAperdHistory = { start_date, end_date };
          $("#rangedatestart").datepicker("setDate", start_date);
          $("#rangedateend").datepicker("setDate", end_date);
        }
        vars.verifyPinStatus && vars.netAction.send(fmsg.aperd_historyTransaction(vars.SID, vars.rangeAperdHistory));
      }
    }
    
    
    if (prevFlag != flag)  
        return {...vars, subscriptionFlags: {...vars.subscriptionFlags, [subscriptionID]: flag}}
  },

  //************************** HANDLE ACTION IN PAGE ************************** 
  //============= actions of Header 1 :  MY ACCOUNT =============
   // state for landing
  changeStateLanding:(vars, {stateLanding})=>{
    // jika bagian portfolio yang di klik
    if(stateLanding == 0){
      let reqCode = vars.portofolios.filter(v => v.code !== "\xa0" && (v.lastprice == 0 || v.lastprice == "-")).map(v => v.code)
      if(reqCode.length > 0){
        vars.netActionAux.send(fmsg.ix_stockSummaryXS(reqCode));
      }

      if(vars.verifyPinStatus){
        vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));        
        return {...vars, stateLanding: stateLanding}
      }else{
        return {...vars, stateLanding: stateLanding}
      }
    }
    else if(stateLanding == 1){
      if(vars.portfolioStatus === true){
        let codePortfolio = vars.portofolios.filter(l=>(l.code !== '-')).map(d => d.code)
        let unsubCodePortfolio = codePortfolio.filter(function(obj) { return vars.sidebarCode.indexOf(obj) == -1; });
        vars.portfolioStatus = false;
      } 
      vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
      vars.netAction.send(fmsg.q_settlement(vars.regulerId));
      vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
      vars.frameAction.switchPage("stockCashPageInvboard", "/landingPage")
      vars.subscriptionFlags.landingPageInvboard = false
      vars.subscriptionFlags.landingPage = false
      vars.subscriptionFlags.stockCashPageInvboard = true
      return {...vars, stateLanding: 'k', }
    }
  },
  //============= actions of Header 2 :  Market & statistic =============
  // handle onclick in sectoral index
  handleSectorName:(vars, {name, ntype})=>{
    if((vars.sectorName == name && ntype == "sectoralIndex") || (vars.nonsectorName == name && ntype == "nonSectoralIndex") ){
      return{...vars, statusAlertN: true, msgAlert3: name + " already exists"}
    }else{
      vars.topGAL = fa.addRowEmpty("card-202");
      vars.netActionAux.send(fmsg.ix_indexStockVal(name));
      if(ntype == "sectoralIndex"){
        vars.sectorName = ""
        vars.sectorName = name
      }else{
        vars.nonsectorName = ""
        vars.nonsectorName = name
      }
      return { ...vars,
        // sectorName:name,
        indexMemberCode: [],
      }
    }
  },
  // handle onclick Stream Chart
  handleStreamChart:(vars, {streamStatus})=>{
    if(!streamStatus){      
      vars.netActionAux.send(fmsg.sx_indexPeriodic(vars.codeSearchMarketIndex))      
    } else{
      vars.netActionAux.send(fmsg.ux_indexPeriodic(vars.codeSearchMarketIndex))
    }
    return {
        ...vars, streamStatus: !vars.streamStatus
      }
  },
  
  //============= actions of Header 4 :  Trade =============
  // action trade
  handleManual:(vars, {isManual})=>{
    if(isManual){           
      vars.frameAction.switchPage(vars.lastSubHeaderAuth, "/tradePage");
      vars.subscriptionFlags[vars.lastSubHeaderManual] = false;
      vars.subscriptionFlags[vars.lastSubHeaderAuth] = true;
      let cekCode = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
      let code =  cekCode.length != 0 && vars.codeSearchAutomatic == "" ? cekCode[0] : vars.codeSearchAutomatic;
      if(code != ""){
        if(vars.verifyPinStatus){
          vars.bidOfferCode = code; 
          vars.haircutCode = fa.getHaircut(vars.haircutData, vars.bidOfferCode);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.bidOfferCode);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.bidOfferCode), 
            {code: vars.bidOfferCode, ...refStock});
        }
        vars.codeSearchAutomatic = code;
      }
      vars.onClickSidebar = vars.lastSubHeaderAuth === "AutOrderSetting" ? {...vars.onClickSidebar, page: true} : vars.onClickSidebar; 
    }else {
      vars.frameAction.switchPage( vars.lastSubHeaderManual, "/tradePage")
      vars.subscriptionFlags[vars.lastSubHeaderManual] = true;
      vars.subscriptionFlags[vars.lastSubHeaderAuth] = false;
    }
    return {...vars, isManual: !vars.isManual}
  },
  handleAlertAutomatic: (vars) => {
    vars.frameAction.switchPage("tradePage", "/");
    vars.isManual = false;
    vars.frameAction.switchPage(vars.lastSubHeaderAuth, "/tradePage");
    vars.subscriptionFlags[vars.lastSubHeaderManual] = false;
    vars.subscriptionFlags[vars.lastSubHeaderAuth] = true;
  },

  //============= actions of Header 5 : Analytic =============
  // action change chart
  handleMultiChart:(vars, {addressMultiVal})=>({...vars, addressMultiVal: addressMultiVal}),

  //============= action of Header 6 : Live Trade =============
  //update tradeType
  handleTradeType:(vars, {trade})=>{
    let groupName = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup)
    if(trade === "tick") {
      vars.netActionAux.send(fmsg.ux_rtrade);
      vars.tradeAll = init.lvtrade;
      vars.tradeWatchlist = init.lvtrade;
      vars.tradeForeign = init.lvtrade;
      vars.tradeTick = init.lvtrade;
      vars.tradeNonRg = init.lvtrade;
      if(vars.bidOfferCode.length > 0){
        vars.netActionAux.send(fmsg.sx_rtradeS(vars.bidOfferCode));   
      }else if(vars.watchlistCode.length){
        var code = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort();
        vars.netActionAux.send(fmsg.sx_rtradeS(code[0]));   
      }
    }
    if(trade ==='all')
      vars.livetradeContainer = vars.tradeAll
    if(trade === "watchlist")
      vars.livetradeContainer = vars.tradeWatchlist
    if(trade === "foreign")
      vars.livetradeContainer = vars.tradeForeign
    if(trade === "nonRG")
      vars.livetradeContainer = vars.tradeNonRg
    if(vars.tradeType === "tick") {
      if(vars.bidOfferCode !== ''){
        vars.netActionAux.send(fmsg.ux_rtradeS(vars.bidOfferCode));   
      }
      vars.netActionAux.send(fmsg.sx_rtrade); 
      vars.livetradeContainer = vars.tradeTick
    }
    return{
      ...vars,
      tradeType:trade,
      activeGroup:groupName,
    }
  },
  //handle start/stop running data
  handleStartOrStopRunningTrade:(vars,{status})=>{ 
    if(status){
      if(vars.tradeType === "tick" && vars.bidOfferCode !== '' ){
        vars.netActionAux.send(fmsg.ux_rtradeS(vars.bidOfferCode)); 
      }else{
        vars.netActionAux.send(fmsg.ux_rtrade);
      }
    }else{
      if(vars.tradeType === "tick" && vars.bidOfferCode !== '' ){
        vars.netActionAux.send(fmsg.sx_rtradeS(vars.bidOfferCode)); 
        vars.tradeTick = init.lvtrade
      }else{        
        vars.netActionAux.send(fmsg.sx_rtrade);
        switch(vars.tradeType){
          case "all" :{vars.tradeAll = init.lvtrade}; break;
          case "watchlist" :{vars.tradeWatchlist = init.lvtrade}; break;
          case "foreign" :{vars.tradeForeign = init.lvtrade}; break;
          case "nonRG" :{vars.tradeNonRg = init.lvtrade}; break;
        }
      }
    }
    return{...vars, runningStatus:!vars.runningStatus}
  },

  /** ESBN */
  headerTabEsbn: (vars, {tab}) => {
    vars.frameAction.switchPage("EsbnxPageTransaction", "/esbnPage");
    vars.subscriptionFlags["EsbnxPageTransaction"] = true;
    vars.subscriptionFlags["EsbnPage"] = false;
    vars.checkStatusEsbn = 3;
  },
  resetSbnInfo: (vars) => ({...vars, sbnInfo: {tab: 1, fund_acc: vars.sbnInfo.fund_acc, security_acc: vars.sbnInfo.security_acc}}),

  /** MUTUAL FUND */
  setTabMutualFund: (vars) => {
    vars.registerMutualFund = false;
    vars.frameAction.switchPage("MutualFundxPageEtalase", "/mutualfundPage");
    vars.subscriptionFlags["mutualfundPage"] = false;
    vars.subscriptionFlags["MutualFundxPageEtalase"] = true;
    return { ...vars, registerMutualFund: false}
  },

  //************************** HANDLE TRANSACTION ************************** 
  handleCount:(vars, {typeAction, typeCount, price})=>{
    if(typeCount === 'plus'){
      if(typeAction === 'buy'){
        if(vars.GeneralType){
          var newBidPrice = CalculatorS('addR',price)
        }else{
          var newBidPrice = CalculatorS('addM',price)
        }
        let newBidVal = LotToShare(Multiplication(newBidPrice, vars.bidOfferVol)) 
        // update footer on form buy
        let cashBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(price))
        let maxBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(price))
        vars.formBuyFooter.cashBuyS = cashBuy
        vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
        vars.formBuyFooter.maxBuyS = maxBuy
        vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))
        // pembatas harga bid kalo jenis Mktnya Negosiasi
        // if(vars.bidOfferMkt !== '0NG'){
        //   if(newBidPrice <= vars.upperAR){
        //     return{...vars,bidOfferPrice:newBidPrice, bidOfferVal:newBidVal}
        //   }else{
        //     return{...vars,statusAlertN:true, msgAlert3:'harga melebihi harga UpperAR'}
        //   } 
        // }else{
          return{...vars,bidOfferPrice:newBidPrice, bidOfferVal:newBidVal}
        // }
      }
      else if(typeAction === 'sell'){
        if(vars.GeneralType){
          var newOfferPrice = CalculatorS('addR', price)
        }else{
          var newOfferPrice = CalculatorS('addM', price)
        }
        let newBidVal = LotToShare(Multiplication(newOfferPrice, vars.bidOfferVol)) 
        // update footer on form buy
        let cashBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(price))
        let maxBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(price))
        vars.formBuyFooter.cashBuyS = cashBuy
        vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
        vars.formBuyFooter.maxBuyS = maxBuy
        vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))
        // pembatas harga bid kalo jenis Mktnya Negosiasi
        // if(vars.bidOfferMkt !== '0NG'){
        //   if(newOfferPrice <= vars.upperAR){
        //     return{...vars, bidOfferPrice:newOfferPrice, bidOfferVal:newBidVal}
        //   }else{
        //     return{...vars,statusAlertN:true, msgAlert3:'harga melebihi harga UpperAR'}
        //   }     
        // }else{
          return{...vars, bidOfferPrice:newOfferPrice, bidOfferVal:newBidVal}
        // } 
      }
      else if(typeAction === 'buyVol'){
        var newBidVol = Number(price)+1
        var newBidVal = LotToShare(Multiplication(vars.bidOfferPrice, newBidVol))
        return{...vars, bidOfferVol:newBidVol, bidOfferVal:newBidVal}
      }
      else if(typeAction.includes('sellVol')){
        var code = typeAction.split("-")[1]
        var index = vars.portofolios.findIndex(list => list.code === code);  
        if(index !== -1){
          if(price < vars.portofolios[index].lot){
            var newBidVol = Number(price)+1
            var newBidVal = LotToShare(Multiplication(vars.bidOfferPrice, newBidVol))
          }else{
            return{...vars,statusAlertN:true, msgAlert3:'jumlah saham Anda kurang'}
          }
          return{...vars, bidOfferVol:newBidVol, bidOfferVal:newBidVal}
        }else{
          return{...vars,statusAlertN:true, msgAlert3:'Anda tidak memiliki saham ini pada Portfolio'}
          // return{...vars,statusAlert:true, msgAlert:'Anda tidak memiliki saham ini pada Portfolio'}
        } 
      }
    }
    else if(typeCount === 'minus'){
      if(typeAction === 'buy'){
        if(Number(vars.bidOfferPrice) > 0){
          if(vars.GeneralType){
            var newBidPrice = CalculatorS('subR', price)
          }else{
            var newBidPrice = CalculatorS('subM', price)
          }         
          let newBidVal = LotToShare(Multiplication(newBidPrice, vars.bidOfferVol)) 
          // update footer on form buy
          let cashBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(price))
          let maxBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(price))
          vars.formBuyFooter.cashBuyS = cashBuy
          vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
          vars.formBuyFooter.maxBuyS = maxBuy
          vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))
          // pembatas harga bid kalo jenis Mktnya Negosiasi
          // if(vars.bidOfferMkt !== '0NG'){
          //   if(newBidPrice >= vars.lowerAR){
          //     return{...vars,bidOfferPrice:newBidPrice, bidOfferVal:newBidVal}
          //   }else{
          //     return{...vars,statusAlertN:true, msgAlert3:'harga di luar rentang AR'}
          //   } 
          // }else{
            return{...vars,bidOfferPrice:newBidPrice, bidOfferVal:newBidVal}
          // }
        }
      }
      else if(typeAction === 'sell'){
        if(Number(vars.bidOfferPrice) >= 1){
          if(vars.GeneralType){
            var newOfferPrice = CalculatorS('subR', price)
          }else{
            var newOfferPrice = CalculatorS('subM', price)
          }
          let newBidVal = LotToShare(Multiplication(newOfferPrice, vars.bidOfferVol))
          // update footer on form buy
          let cashBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(price))
          let maxBuy = Number(price) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(price))
          vars.formBuyFooter.cashBuyS = cashBuy
          vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
          vars.formBuyFooter.maxBuyS = maxBuy
          vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))
           // pembatas harga bid kalo jenis Mktnya Negosiasi
          // if(vars.bidOfferMkt !== '0NG'){
            // if(newOfferPrice >= vars.lowerAR){
            //   return{...vars, bidOfferPrice:newOfferPrice, bidOfferVal:newBidVal}
            // }else{
              // return{...vars,statusAlertN:true, msgAlert3:'harga di luar rentang AR'}
            // }
          // }else{
            return{...vars, bidOfferPrice:newOfferPrice, bidOfferVal:newBidVal}
          // }
        }
      }
      else if(typeAction === 'buyVol'){
        if(Number(vars.bidOfferVol) > 0){
          var newBidVol = Number(price)-1
          var newBidVal = LotToShare(Multiplication(vars.bidOfferPrice, newBidVol)) 
          return{...vars, bidOfferVol:newBidVol, bidOfferVal:newBidVal}
        }
      }
      else if(typeAction === 'sellVol'){
        if(Number(vars.bidOfferVol) > 0){
          var newBidVol = Number(price)-1
          var newBidVal = LotToShare(Multiplication(vars.bidOfferPrice, newBidVol))
          return{...vars, bidOfferVol:newBidVol, bidOfferVal:newBidVal}
        }
      }
    }
    else if(typeCount === 'onChange'){
      let dataPrice = price.replace(/[^0-9]/g, '')
      var newPrice = Number(dataPrice)
      if(dataPrice.length <= 7){
        if(typeAction === 'buy'){  
          var newBidVal = LotToShare(Multiplication(newPrice, vars.bidOfferVol)) 
          return{...vars, bidOfferPrice:newPrice, bidOfferVal:newBidVal }
        }
        else if(typeAction === 'sell'){
          var newBidVal = LotToShare(Multiplication(newPrice, vars.bidOfferVol)) 
          return{...vars, bidOfferPrice:newPrice, bidOfferVal:newBidVal}
        }
        else if(typeAction === 'buyVol'){
          if(newPrice <= 50000){
            var newBidVal = vars.order_type === "7" ? LotToShare(Multiplication(vars.bidOfferPrice, newPrice)) : "";
            return{...vars, bidOfferVol:newPrice, bidOfferVal:newBidVal}
          }
        }
        else if(typeAction === 'sellVol'){
          var newBidVal = vars.order_type === "7" ? LotToShare(Multiplication(vars.bidOfferPrice, newPrice)) : "";
          vars.portfolioData.slot ==  newPrice ?  vars.offerAlldStatus = true : vars.offerAlldStatus = false
          return{...vars, bidOfferVol:newPrice, bidOfferVal:newBidVal}
        }
      }      
    }
    else if(typeCount === 'clientOI'){
      let l =vars.orderList.length
      let maxNoOrder = vars.orbookStatus ? CalculatorS('max',vars.tradeOrderBookList.filter(d=>d.code !== "\xa0").map(l=>l.order)) 
        : CalculatorS('max',vars.orderList.filter(d=>d.code !== "\xa0").map(l=>l.order))
        var newClientIO = 0
        if(vars.orbookStatus){
          newClientIO = "088"+getTime2('io')+'0'
        }else{
          newClientIO = "88"+getTime2('io')+'0'
        }
        return {...vars,clientOrderId:newClientIO}
      // }else{
      //   return {...vars,clientOrderId:Number(maxNoOrder)+1}
      // }
    } else if(typeCount === "availableCash"){
        // console.log(Math.min(vars.scSettlement[7].t0, vars.scSettlement[7].t1, vars.myBalance) - vars.subTotalAperd)
        // return { ...vars, availableCash: Math.min(vars.scSettlement[7].t0, vars.scSettlement[7].t1, vars.myBalance) - vars.subTotalAperd }
        return { ...vars, availableCash: Math.min(vars.scSettlement[7].t0, vars.scSettlement[7].t1, vars.myBalance)}
    }
  }, 

  //************************ HANDLE SEARCH, ONCLICK, and ONCHANGE of Text ************************ 
  handleSearchCode:(vars,{ type, code})=>{
    let newCode = code
    // search Index (Market Index) pada Market & Statistic Page
    if(type === 'indexMarketIndex'){
      vars.netActionAux.send(fmsg.ix_indexPeriodic(newCode))
      let checkNewIndex = vars.boxDataTickerHeader.filter(d=>d.symbol == newCode)
      if(checkNewIndex.length > 0){
        vars.indexData = checkNewIndex[0]
      }else{
        let checkLastIndex = vars.boxDataTickerHeader.filter(d=>d.symbol == vars.codeSearchMarketIndex)
        if(checkLastIndex.length === 0){
          vars.netActionAux.send(fmsg.ux_indexS(vars.codeSearchMarketIndex))
        }
        vars.netActionAux.send(fmsg.sx_indexS(newCode))
      }    
      if(vars.streamStatus){
        vars.netActionAux.send(fmsg.ux_indexPeriodic(vars.codeSearchMarketIndex))
        vars.streamStatus = false
      } 
      return {
        ...vars, codeSearchMarketIndex:newCode, indexStreamChart:[]
      }
    }
    // BROKER: Broker info, Broker Trade Summary, Broker Trade History
    else if(type === "brokerInfo"){
      let nbroker = vars.brokerList.find(d => d.value === code);
      vars.netActionAux.send(fmsg.ix_brokerInfo(code));
      return {...vars, codeBrokerInfo: nbroker}      
    }
    else if(type === "brokerTradeSummary"){
      let nbroker = vars.brokerList.find(d => d.value === code)
      vars.netActionAux.send(fmsg.ix_brokerStockBuy(code));
      vars.netActionAux.send(fmsg.ix_brokerStockSell(code));
      return {...vars, codeBrokerInfo: nbroker, brokerTradeSum1: init.brokerTradeSum1, brokerTradeSumInfo: {}}      
    }
    else if(type === "brokerTradeHistory"){
      let nbroker = vars.brokerList.find(d => d.value === code)
      return {...vars, codeBrokerInfo: nbroker, brokerTradeHisInfo: {}}      
    }
    // search Code dropdown (Stock Info) pada Stock Page
    else if(type === 'stockInfo'){
      // console.log("ini search stock info")
      if(vars.codeSearchStockInfo.length !== 0){
        vars.netActionAux.send(fmsg.ux_stockSummary(vars.bidOfferCode));        
        // unsubcribe bid & offer
        vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
        vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
        vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
      }
      vars.haircutCode = fa.getHaircut(vars.haircutData, code);
      let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === code);
      vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === code), {code: code, ...refStock});
      
      vars.sStockSmry = {};
      if(vars.subscriptionFlags['stockPage']){    
        // vars.netActionAux.send(fmsg.ix_stockChart(newCode));
        vars.netActionAux.send(fmsg.ix_techDay([newCode]));
      }
      // request financial report
      if(vars.subscriptionFlags['stockFinancialStatement']){
        let params = vars.stockFinancial.tab == 1 ? {"stock_code": strCall.code(newCode), "quarter": vars.stockFinancial.valQtr} : {"stock_code": strCall.code(newCode), "year": vars.stockFinancial.valYear}
        vars.netAction.send(fmsg.q_financialReport(params));        
      }
      vars.netActionAux.send(fmsg.ix_stockData(newCode));
      vars.netActionAux.send(fmsg.sx_stockSummary( newCode));
      vars.netActionAux.send(fmsg.sx_bid(newCode));
      vars.netActionAux.send(fmsg.sx_offer(newCode));
      vars.netActionAux.send(fmsg.sx_stockiepv(newCode));

      return { ...vars, bidOfferCode: newCode, codeSearchStockInfo: newCode, bidData: [], offerData: [], bidOfferPrice: 0, bidOfferVol: 0,
        bidOfferVal: 0, bidOfferMkt: '0RG', bidOfferExpire: '0', order_type : "7", prevStatus: true, offerAlldStatus: false,
        chartStockData: []
      }
    }
    // search Code (Stock Trade History) pada Stock Page
    else if(type === "stockTradeHistory"){
      if(vars.codeSearchStockInfo == newCode){
        return {...vars, statusAlertN: true, msgAlert3: 'code already exists'}
      }else {
        // inquiry data 
        let newRangeStockTradeHistory = vars.rangeStockTradeHistory.start == "" ?  {start: GetTimeForDateTicker(3, 1), to: GetTimeForDateTicker(0, 1)} : vars.rangeStockTradeHistory;
        $("#datepickerstartSH").datepicker("setDate", newRangeStockTradeHistory.start);
        $("#datepickerendSH").datepicker("setDate", newRangeStockTradeHistory.to);

        let { start, to } = newRangeStockTradeHistory;
        if(vars.tabStockTradeHistory === 1){
          vars.netActionAux.send(fmsg.ix_tradeDaily(newCode, start, to));
        }else if(vars.tabStockTradeHistory === 2){
          vars.netActionAux.send(fmsg.ix_tradeAll(newCode, start, to));
        }else if(vars.tabStockTradeHistory === 3){
          vars.netActionAux.send(fmsg.ix_tradeForeign(newCode, start, to));
        }
        vars.stockTradeHistorySMRY = init.stockTradeHistorySMRY;
        vars.netActionAux.send(fmsg.ix_stockSummary(newCode));
        vars.netActionAux.send(fmsg.ix_tradeBuy(newCode, start, to));
        vars.netActionAux.send(fmsg.ix_tradeSell(newCode, start, to));
        vars.netActionAux.send(fmsg.ix_tradePrice(newCode, start, to));
        return{ ...vars,
          stockTradeHistoryPrice: init.stockTradeHistoryPrice,
          stockTradeHistoryBuy: init.stockTradeHistoryBuy,
          stockTradeHistorySell: init.stockTradeHistorySell,
          stockTradeHistoryMain: fa.addRowEmpty("card-372", [], 27.5),
          codeSearchStockInfo: newCode,
          rangeStockTradeHistory: newRangeStockTradeHistory,
        }
      }
    }
    // search Code (Stock Trade Summary) pada Stock Page
    else if(type === "stockTradeSummary"){
      if(vars.codeSearchStockInfo == newCode){
        return{...vars, statusAlertN: true, msgAlert3: 'code already exists'}
      }else{
        let nstockTickBoardType = vars.stockTickBoardType === "ALL" ? "" : "."+vars.stockTickBoardType   
        if(vars.codeSearchStockInfo !== ''){
          vars.netActionAux.send(fmsg.ux_rtradeS(vars.codeSearchStockInfo, nstockTickBoardType));      
          // vars.netActionAux.send(fmsg.ux_stockPrice(vars.codeSearchStockInfo)); 
          vars.netActionAux.send(fmsg.ux_stockSummary(vars.codeSearchStockInfo)); 
          vars.stockTickMain = init.stockTickMain;
        } 
        vars.sStockSmry = {};
        vars.stockTradeSummarySMRY = init.stockTradeHistorySMRY;
        vars.netActionAux.send(fmsg.sx_stockSummary(newCode));      
        vars.netActionAux.send(fmsg.sx_rtradeS(newCode, nstockTickBoardType));    
        // vars.netActionAux.send(fmsg.sx_stockPrice(newCode));    
        vars.netActionAux.send(fmsg.ix_stockPrice(newCode));                      
        vars.netActionAux.send(fmsg.ix_stockBrokerBuy(newCode));           
        vars.netActionAux.send(fmsg.ix_stockBrokerSell(newCode));
        return{
          ...vars,
          codeSearchStockInfo: newCode,
          stockTradeSummaryMain: init.stockTradeSummary,
          stockSummaryBuyerMain: init.stockSummaryBuyer,
          stockSummarySellerMain: init.stockSummaryBuyer,
        }
      }
    }
    // search Code pada Tabel Bid Offer
    else if(type === 'tableInformation'){
      if(vars.bidOfferCode == code){
        return{...vars, statusAlertN: true, msgAlert3: 'code already exists'}
      }else{
        // haircut code
        let cekhc = vars.haircutData.find(hc => hc.stock_code == strCall.code(code));
        if(cekhc){
          vars.haircutCode = Number(cekhc.haircut)
        }else{
          vars.haircutCode = 0;
          vars.netAction.send(fmsg.q_stockHaircutByCode([strCall.code(code)]))
        }
        let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === code.split(".")[0]);
        let portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === code.split(".")[0]), {code: code.split(".")[0], ...refStock});
        let statusCode = strCall.code(code) == strCall.code(vars.bidOfferCode);
        let lastCode = vars.bidOfferCode;
        vars.bidOfferCode = code;

        let sStockSmry = statusCode ? vars.sStockSmry : {}, bidOfferPrice = statusCode ? vars.sStockSmry.close_price : 0, bidData = [], offerData = [];
  
        // BID/OFFER PAGE
        if(vars.subscriptionFlags['tradePageManWatchlist']){
          sStockSmry = strCall.code(code) == strCall.code(vars.codeForOB1) ? vars.sStockSForOB1 : (strCall.code(code) == strCall.code(vars.codeForOB2) ? vars.sStockSForOB2 : 
            (strCall.code(code) == strCall.code(vars.codeForOB3) ? vars.sStockSForOB3 : "none"));
          vars.iStockData = strCall.code(code) == strCall.code(vars.codeForOB1) ? vars.iSDForOB1 : (strCall.code(code) == strCall.code(vars.codeForOB2) ? vars.iSDForOB2 : 
            ( strCall.code(code) == strCall.code(vars.codeForOB3) ? vars.iSDForOB3 : "none"));
          bidOfferPrice = sStockSmry.close_price == undefined || Number(sStockSmry.close_price) == 0 ? Number(sStockSmry.prev_price) : Number(sStockSmry.close_price);
          bidData = code == vars.codeForOB1 ? vars.bidData1 : (code == vars.codeForOB2 ? vars.bidData2 : (code == vars.codeForOB3 ? vars.bidData3 : "none"));
          offerData = code == vars.codeForOB1 ? vars.offerData1 : (code == vars.codeForOB2 ? vars.offerData2 : (code == vars.codeForOB3 ? vars.offerData3 : "none"));
          if(sStockSmry == "none"){
            sStockSmry = {};
            vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(lastCode)));
            vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(code)));
          }
          if(vars.iStockData == "none"){
            vars.iStockData = {};
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(code)));
          }
          if(bidData == "none"){
            vars.netActionAux.send(fmsg.ux_bid(lastCode));
            vars.netActionAux.send(fmsg.ux_offer(lastCode));
            vars.netActionAux.send(fmsg.sx_bid(code));
            vars.netActionAux.send(fmsg.sx_offer(code)); 
          }
  
          if(vars.box1 == 1 && bidData == "none"){
            vars.codeForOB1 = code; vars.bidData1 = []; vars.offerData1 = []; bidData = []; offerData = [];
          }else if(vars.box2 == 1 && bidData == "none"){
            vars.codeForOB2 = code; vars.bidData2 = []; vars.offerData2 = []; bidData = []; offerData = [];
          }else if(vars.box3 == 1 && bidData == "none"){
            vars.codeForOB3 = code; vars.bidData3 = []; vars.offerData3 = []; bidData = []; offerData = [];
          }
          // UPDATE FORM BUY/SELL
          if(vars.openModalBuySell){
            vars.bidOfferMkt = strCall.codeMarket(code);
            vars.bidOfferExpire = strCall.codeExpire(code);
            vars.bidOfferVal =  0; vars.prevStatus = true; vars.offerAlldStatus = false; vars.bidOfferVol = 0;
            vars.constBL.hc = vars.haircutCode;
            vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
            vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
            let cashOn = vars.constBL.balance - vars.constBL.OB;
            let cashBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(bidOfferPrice));
            let maxBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(bidOfferPrice));
            vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), 
              maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)), cashOn, remainTrade: fa.GetBuyLimit(vars.constBL, "searhCode-OpenModalBuySell")};
            vars.formSellFooter = {... vars.formSellFooter,
              ownQtyL: portfolioData.slot, ownQtyS: portfolioData.sshares, avgprice: portfolioData.avgprice, perPl: portfolioData.perPlFormSell,
              value: portfolioData.valFormSell, pl: portfolioData.plFormSell,
            };
          }
        }else{
          // unsub Bid/offer
          if(lastCode.length != 0){
            vars.netActionAux.send(fmsg.ux_bid(lastCode));
            vars.netActionAux.send(fmsg.ux_offer(lastCode));
            vars.netActionAux.send(fmsg.ux_stockiepv(lastCode));
          }
          // STOCK WATCHLIST PAGE
          if(vars.subscriptionFlags['stockWatchlistPage']){
            vars.codeSearchStockInfo = code;
            let nextCode = vars.pageWatchlistCode.find(d => d == strCall.code(code));
            let beforeCode = vars.pageWatchlistCode.find(d => d == strCall.code(lastCode));
            if(nextCode === undefined){
              if(beforeCode === undefined && !statusCode){
                vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(lastCode)));
                vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(code)));
              }else{
                vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(code)));
              }
            }else{
              if(beforeCode === undefined){
                vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(lastCode)));
              }
              sStockSmry = Object.values(vars.dataWatchlist).find(d => d.stock_code == strCall.code(code));
              bidOfferPrice = Number(sStockSmry.close_price) == 0 ? Number(sStockSmry.prev_price) : Number(sStockSmry.close_price);
            }
  
            // UPDATE FORM BUY/SELL
            vars.bidOfferMkt = strCall.codeMarket(code);
            vars.bidOfferExpire = strCall.codeExpire(code);
            vars.bidOfferVal =  0; vars.prevStatus = true; vars.offerAlldStatus = false; vars.bidOfferVol = 0;
            vars.constBL.hc = vars.haircutCode;
            vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
            vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
            let cashOn = vars.constBL.balance - vars.constBL.OB;
            let cashBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(bidOfferPrice));
            let maxBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(bidOfferPrice));
            vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), 
              maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)), cashOn, remainTrade: fa.GetBuyLimit(vars.constBL)};
            vars.formSellFooter = {... vars.formSellFooter,
              ownQtyL: portfolioData.slot, ownQtyS: portfolioData.sshares, avgprice: portfolioData.avgprice, perPl: portfolioData.perPlFormSell,
              value: portfolioData.valFormSell, pl: portfolioData.plFormSell,
            };
          }
          // STOCK INFO PAGE, FINANCIAL REPORT PAGE, LIVETRADE PAGE, AUTOMATIC ORDER PAGE
          else if(vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['stockFinancialStatement'] || vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['AutOrderSetting']){
            if(!statusCode){
              if(lastCode.length != 0){
                vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(lastCode)));
                if(vars.subscriptionFlags['livetradePage'] && vars.tradeType === "tick"){
                  vars.tradeTick = init.lvtrade;
                  vars.livetradeContainer = init.lvtrade;
                  if(vars.runningStatus){
                    vars.netActionAux.send(fmsg.ux_rtradeS(strCall.code(lastCode)));
                  }
                }
              }
              vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(code)));
              if(vars.subscriptionFlags['livetradePage'] && vars.tradeType === "tick" && vars.runningStatus){
                vars.netActionAux.send(fmsg.sx_rtradeS(strCall.code(code)));
              }
            }
            // UPDATE FORM BUY/SELL
            if(vars.openModalBuySell){
              vars.bidOfferMkt = strCall.codeMarket(code);
              vars.bidOfferExpire = strCall.codeExpire(code);
              vars.bidOfferVal =  0; vars.prevStatus = true; vars.offerAlldStatus = false; vars.bidOfferVol = 0;
              vars.constBL.hc = vars.haircutCode;
              vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
              vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
              let cashOn = vars.constBL.balance - vars.constBL.OB;
              let cashBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(bidOfferPrice));
              let maxBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(bidOfferPrice));
              vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), 
                maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)), cashOn, remainTrade: fa.GetBuyLimit(vars.constBL, "searchCode-stockInfo")};
              vars.formSellFooter = {... vars.formSellFooter,
                ownQtyL: portfolioData.slot, ownQtyS: portfolioData.sshares, avgprice: portfolioData.avgprice, perPl: portfolioData.perPlFormSell,
                value: portfolioData.valFormSell, pl: portfolioData.plFormSell,
              };
            }
            if(vars.subscriptionFlags['stockInfoPage']){
              vars.codeSearchStockInfo = code;
              // vars.netActionAux.send(fmsg.ix_stockChart(strCall.code(code)));
              if(vars.subscriptionFlags['dailyTechnicalTable']){
                vars.netActionAux.send(fmsg.ix_techDay([vars.codeSearchStockInfo]));
              }
            }else if(vars.subscriptionFlags['stockFinancialStatement']){
              vars.codeSearchStockInfo = code;
              let params = vars.stockFinancial.tab == 1 ? {"stock_code": strCall.code(code), "quarter": vars.stockFinancial.valQtr} : {"stock_code": strCall.code(code), "year": vars.stockFinancial.valYear};
              vars.netAction.send(fmsg.q_financialReport(params));
            }else if(vars.subscriptionFlags['AutOrderSetting']){
              vars.setPriceAutomatic = 0;
              vars.codeSearchAutomatic = code;
            }
          }else{
            if(vars.openModalBuySell){
              if(!statusCode){
                if(lastCode.length != 0){
                  vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(lastCode)));
                }
                vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(code)));
              }
              // UPDATE FORM BUY/SELL
              vars.bidOfferMkt = strCall.codeMarket(code);
              vars.bidOfferExpire = strCall.codeExpire(code);
              vars.bidOfferVal =  0; vars.prevStatus = true; vars.offerAlldStatus = false; vars.bidOfferVol = 0;
              vars.constBL.hc = vars.haircutCode;
              vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
              vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
              let cashOn = vars.constBL.balance - vars.constBL.OB;
              let cashBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(bidOfferPrice));
              let maxBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(bidOfferPrice));
              vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), 
                maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)), cashOn, remainTrade: fa.GetBuyLimit(vars.constBL, "searchCode-openBuySell")};
              vars.formSellFooter = {... vars.formSellFooter,
                ownQtyL: portfolioData.slot, ownQtyS: portfolioData.sshares, avgprice: portfolioData.avgprice, perPl: portfolioData.perPlFormSell, value: portfolioData.valFormSell, pl: portfolioData.plFormSell,
              };
            }
          }
        
          // inquiry Stock data & Sub Bid/offer
          if(!statusCode){
            vars.iStockData = {};
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(code)));
          }
          vars.netActionAux.send(fmsg.sx_bid(code));
          vars.netActionAux.send(fmsg.sx_offer(code));      
        }
        vars.netActionAux.send(fmsg.sx_stockiepv(code));
        return {...vars, bidOfferCode: code, sStockSmry, portfolioData, bidOfferPrice, bidData, offerData, order_type: "7"}
      }
    }
    // search Code pada orderbook
    else if(type.includes("tradeOrderBook") ){
      if(vars.box1 == 0 && vars.box2 == 0 && vars.box3 == 0){
        return {...vars, statusAlertN: true, msgAlert3: 'please select the box'}
      }else if(newCode == vars.codeForOB1 ||newCode == vars.codeForOB2 || newCode == vars.codeForOB3 ){
        return{...vars, statusAlertN: true, msgAlert3: 'code already exists'}
      }else{
        let checkCode = strCall.code(newCode) == strCall.code(vars.codeForOB1) ? vars.sStockSForOB1 : 
            (strCall.code(newCode) == strCall.code(vars.codeForOB2) ? vars.sStockSForOB2 :
            strCall.code(newCode) == strCall.code(vars.codeForOB3) ? vars.sStockSForOB3 : "none") 
        let checkICode = strCall.code(newCode) == strCall.code(vars.codeForOB1) ? vars.iSDForOB1 : 
            (strCall.code(newCode) == strCall.code(vars.codeForOB2) ? vars.iSDForOB2 : vars.iSDForOB3)
        let check3code = strCall.checkBoxStock(vars.codeForOB1, vars.codeForOB2, vars.codeForOB3)
        if(type === 'tradeOrderBook1' || vars.box1 == 1){
          // sub & unsubscribe Stock summary
          if(checkCode == "none"){
            vars.sStockSForOB1 = {}
            if( check3code == false){
              vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.codeForOB1)));
            }
            vars.netActionAux.send(fmsg.sx_stockSummary( strCall.code(newCode)));          
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(newCode)));  
            vars.sStockSmry = {};
          }else{
            vars.sStockSForOB1 = checkCode;
            vars.iSDForOB1 = checkICode;
          }
  
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.codeForOB1));
          vars.netActionAux.send(fmsg.ux_offer(vars.codeForOB1));
  
          // subscribe bid & offer
          vars.netActionAux.send(fmsg.sx_bid(newCode));
          vars.netActionAux.send(fmsg.sx_offer(newCode));
          return { ...vars, codeForOB1: newCode, bidData1: [], offerData1: []}
        }else if(type === 'tradeOrderBook2' || vars.box2 == 1){
          // sub & unsubscribe Stock summary
          if(checkCode == "none"){
            vars.sStockSForOB2 = {};
            if( check3code == false){
              vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.codeForOB2)));
            }
            vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(newCode)));
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(newCode)));
            vars.sStockSmry = {}; 
          }else{
            vars.sStockSForOB2 = checkCode;
            vars.iSDForOB2 = checkICode;
          }
  
          // unsubscribe the last code
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.codeForOB2));
          vars.netActionAux.send(fmsg.ux_offer(vars.codeForOB2));
          // subscribe new code
          vars.netActionAux.send(fmsg.sx_bid(newCode));
          vars.netActionAux.send(fmsg.sx_offer(newCode));
          return { ...vars, codeForOB2: newCode, bidData2: [], offerData2: []}
        }else{
          // sub & unsubscribe Stock summary
          if(checkCode == "none"){
            vars.sStockSForOB3 = {};
            if( check3code == false){
              vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.codeForOB3)));
            }
            vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(newCode)));
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(newCode)));
            vars.sStockSmry = {}; 
          }else{
            vars.sStockSForOB3 = checkCode;
            vars.iSDForOB3 = checkICode;
          }
  
          // unsubscribe the last code
          // unsubcribe bid & offer
  
          vars.netActionAux.send(fmsg.ux_bid(vars.codeForOB3));
          vars.netActionAux.send(fmsg.ux_offer(vars.codeForOB3));
          // subscribe new code
          vars.netActionAux.send(fmsg.sx_bid(newCode));
          vars.netActionAux.send(fmsg.sx_offer(newCode));
          return{ ...vars, codeForOB3: newCode, bidData3: [], offerData3: []}
        }
      }
    }
    else if(type === 'tradePageAdv'){   
      vars.tradeAdvBid = init.tradeAdv
      vars.tradeAdvOffer = init.tradeAdv   
      vars.netActionAux.send(fmsg.ix_tradeAdv(code,'BID'));           
      vars.netActionAux.send(fmsg.ix_tradeAdv(code,'OFFER'));
      return {...vars, codeSearchTradeAdv:code}
    }
    /** ANALITIC CHART */
    else if(type === 'singleChart'){
      vars.netActionAux.send(fmsg.ix_techDay([code]));
      return {...vars, lastCodeChart: code}
    }
  }, 
  setDataHandler: (vars, {newVars}) => ({...vars, ...newVars}),
  handleOnClick:(vars, {pageType, type, data})=>{
    if(pageType === "setData"){
      return {...vars, [type]: data}
    }
    if(pageType == "migrationAccount"){
      if(type == "changeTab" ){
        let nmigrationS = {tab:data, status:vars.migrationState.status, selected:vars.migrationState.selected, selectedAct: vars.migrationState.selectedAct}
        return{...vars, migrationState:nmigrationS}
      }
      if(type == "changeSelected"){
        let nmigrationS = {tab:vars.migrationState.tab, status:"no", selected:data, selectedAct: vars.migrationState.selectedAct}
        return{...vars, migrationState:nmigrationS}        
      }
      if(type == "changeSelectedAct"){
        let nmigrationS = {tab:vars.migrationState.tab, status:"no", selected:data, selectedAct: data}
        return{...vars, migrationState:nmigrationS}        
      }
      if(type == "migrateNow"){
        vars.netActionAux.send(fmsg.migrateAccount(vars.userName));
        vars.frameAction.closeModal(100) 
      }
    }
    if(pageType === 'activeAction' && type === 'cactivateStatus'){
      return{...vars, activateStatus:data}
    }
    if(pageType === 'closeAlertLogin'){
      return {...vars,loginErrState:false}
    }
    // handle onclick in All Page (side bar)    
    else if(pageType === 'all'){
      if(type === 'dropdownSideBar'){
        let groupNameLast = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort()
        let groupNameNew = groupSearch(vars.watchlistCode, data).sort();
        let newSumPage = Math.ceil(groupNameNew.length/vars.maxPpage);
        let nstockSmryXsListCode = vars.stockSmryXsListCode
        
        // unsubscribe and subcribe data for Side bar
        let lastPageCode=showPage(vars.thisPage, vars.maxPpage, groupNameLast)
         //Unsubscribe code pada page sebelumnya dan subscribe code berikutnya
        let diflastPageCode = lastPageCode.filter(d=>vars.portofolios.map(l=>l.code).indexOf(d) == -1)
        if(diflastPageCode.length > 0){
          vars.netActionAux.send(fmsg.ux_stockSummaryXS(diflastPageCode)) 
          nstockSmryXsListCode = vars.stockSmryXsListCode.filter(item=> diflastPageCode.indexOf(item.stock_code) == -1)      
        }
        let nextPageCode=showPage(0, vars.maxPpage, groupNameNew)
        let difnextPageCode = nextPageCode.filter(d=>vars.portofolios.map(l=>l.code).indexOf(d) == -1)
        if(difnextPageCode.length > 0){
          difnextPageCode.map(d=>nstockSmryXsListCode.push({stock_code:d, prev_price:0, close_price: 0}))
          vars.netActionAux.send(fmsg.sx_stockSummaryXS(difnextPageCode)) 
        }
                
        vars.subscriptionFlags["stockWatchlistPage"] && vars.netActionAux.send(fmsg.ux_stockSummary(vars.pageWatchlistCode.filter(l => l !== vars.bidOfferCode))) 
        vars.thisPageWatchlist = 0
        vars.sumPageWatchlist = Math.ceil(groupNameNew.length/vars.maxPageWatchlist)
        vars.pageWatchlistCode =showPage(vars.thisPageWatchlist, vars.maxPageWatchlist, groupNameNew)
        let cekCode = vars.pageWatchlistCode.filter(l => l == vars.bidOfferCode)
        vars.dataWatchlist= cekCode.length > 0 ? {[vars.bidOfferCode]:vars.sStockSmry} : {}
        vars.dataWatchlist= cekCode.length > 0 ? {[vars.bidOfferCode]:vars.sStockSmry} : {}
        vars.subscriptionFlags["stockWatchlistPage"] && vars.netActionAux.send(fmsg.sx_stockSummary(vars.pageWatchlistCode.filter(l => l !== vars.bidOfferCode)));
        let newSideBar = [...nextPageCode,...init.sidebarCode.slice(nextPageCode.length,vars.maxPpage)];
        
        return{ ...vars, sidebarStatusGroup: data,thisPage:0, sumPage:newSumPage, sidebarCode:newSideBar, 
          stockSmryXsListCode:nstockSmryXsListCode, sidebarData: GetSidebar(newSideBar, vars.stockSmryXsListCode)}     
      }
      // handle onclick of star Icon in SideBar
      else if(type.includes('cartIcon')){ 
        let ntype = type.split("|")[1];
        vars.bidOfferPrice = vars.bidOfferCode == "" ? 0 : vars.bidOfferPrice;
        if(ntype === "openModalCart"){
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
          vars.orbookStatus = fa.GetOrderbookStatus(vars.tradingStatus, vars.preOpeningStatus, vars.iStockData);
          if(vars.subscriptionFlags['stockWatchlistPage']){
            vars.bidOfferVol = 0; vars.bidOfferVal = 0; vars.offerAlldStatus = false;
          }else if(vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['stockFinancialStatement'] || vars.subscriptionFlags['AutOrderSetting'] || vars.subscriptionFlags['livetradePage']){
            if(vars.bidOfferCode.length !== 0 || vars.codeSearchStockInfo.length !== 0){
              vars.bidOfferVol = '0'; vars.bidOfferVal = '0'; vars.offerAlldStatus = false; vars.bidOfferPrice = vars.sStockSmry.close_price;
              vars.bidOfferMkt = strCall.codeMarket(vars.bidOfferCode);
              vars.bidOfferExpire = strCall.codeExpire(vars.bidOfferCode);
              
              // Update Form Buy
              vars.constBL.hc = vars.haircutCode;
              vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
              vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
              let remainTrade = fa.GetBuyLimit(vars.constBL, "onClick-keranjang"); 
              let cashOn = vars.constBL.balance - vars.constBL.OB
              let cashBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(cashOn/Number(vars.bidOfferPrice));
              let maxBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(remainTrade/Number(vars.bidOfferPrice));        
              vars.formBuyFooter = {...vars.formBuyFooter, remainTrade, cashOn, cashBuyS, cashBuyL: Math.floor(ShareToLot(cashBuyS)), maxBuyS, maxBuyL: Math.floor(ShareToLot(maxBuyS)) };
            }
          }else if(vars.subscriptionFlags['stockTradeSummaryPage'] && vars.codeSearchStockInfo.length !== 0){
            vars.bidOfferCode = vars.codeSearchStockInfo; vars.bidOfferVol = 0; vars.bidOfferVal = 0; vars.offerAlldStatus = false; vars.sStockSmry = vars.stockTradeSummarySMRY;
            vars.bidOfferPrice = vars.sStockSmry.close_price;
            vars.haircutCode = fa.getHaircut(vars.haircutData, strCall.code(vars.bidOfferCode));
            let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.bidOfferCode);
            vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.bidOfferCode), {code: vars.bidOfferCode, ...refStock});

            // Update Form Buy
            vars.constBL.hc = vars.haircutCode;
            vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
            vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
            let remainTrade = fa.GetBuyLimit(vars.constBL, "onClick-tradeSummary"); 
            let cashOn = vars.constBL.balance - vars.constBL.OB
            let cashBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(cashOn/Number(vars.bidOfferPrice));
            let maxBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(remainTrade/Number(vars.bidOfferPrice));        
            vars.formBuyFooter = {...vars.formBuyFooter, remainTrade, cashOn, cashBuyS, cashBuyL: Math.floor(ShareToLot(cashBuyS)), maxBuyS, maxBuyL: Math.floor(ShareToLot(maxBuyS)) };
            // inquiry & sub bid/offer
            vars.netActionAux.send(fmsg.ix_stockData(strCall.code(vars.bidOfferCode)));
            vars.netActionAux.send(fmsg.sx_bid(vars.bidOfferCode));
            vars.netActionAux.send(fmsg.sx_offer(vars.bidOfferCode));
          }else{
            vars.formBuyFooter = fd.formBuyFooter; vars.formSellFooter = fd.formSellFooter; vars.bidOfferCode = ""; vars.iStockData = {}; 
            vars.sStockSmry = {}; vars.portfolioData = fd.portfolio; vars.bidData = []; vars.offerData = []; vars.bidOfferPrice = 0; vars.bidOfferVol = 0; vars.bidOfferVal = 0; 
            vars.bidOfferMkt = "0RG"; vars.bidOfferExpire = "0";vars.order_type = "7"; vars.prevStatus = true;  vars.offerAlldStatus = false; vars.haircutCode = 0;
            vars.constBL.hc = 0;
          }
          return {...vars, cartIconStatus: true, openModalBuySell: true}
        }
        else if(ntype === "closeModalCart"){
          vars.bidOfferVol = 0; vars.bidOfferVal = 0; vars.offerAlldStatus = false;
          if(vars.subscriptionFlags['stockWatchlistPage']){
          }else if(vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['stockFinancialStatement'] || vars.subscriptionFlags['AutOrderSetting'] || vars.subscriptionFlags['livetradePage']){
            vars.formBuyFooter = fd.formBuyFooter; vars.formSellFooter = fd.formSellFooter;
          }else if(vars.subscriptionFlags['stockTradeSummaryPage'] && (vars.bidOfferCode.length !== 0 || vars.codeSearchStockInfo.length !== 0)){
            // if(vars.codeSearchStockInfo != vars.bidOfferCode){
            //   let checkCode = strCall.codeType(vars.bidOfferCode) === "TN" ? "attention" : "ignore";
            //   vars.stockTickBoardType = checkCode == "attention" ? 'TN' : "ALL";              
            //   let nstockTickBoardType = vars.stockTickBoardType === "ALL" ? "" : "."+vars.stockTickBoardType;
            //   if(checkCode == "ignore"){
            //     vars.netActionAux.send(fmsg.ix_stockPrice(strCall.code(vars.bidOfferCode)));           
            //     vars.netActionAux.send(fmsg.ix_stockBrokerBuy(strCall.code(vars.bidOfferCode)));           
            //     vars.netActionAux.send(fmsg.ix_stockBrokerSell(strCall.code(vars.bidOfferCode)));           
            //   }
            //   vars.netActionAux.send(fmsg.ux_rtradeS(strCall.code(vars.codeSearchStockInfo), nstockTickBoardType));
            //   vars.netActionAux.send(fmsg.sx_rtradeS(strCall.code(vars.bidOfferCode), nstockTickBoardType));
            //   vars.codeSearchStockInfo = vars.bidOfferCode; 
            //   vars.stockTradeSummaryMain = init.stockTradeSummary; vars.stockTickMain = init.stockTickMain; vars.stockSummaryBuyerMain = init.stockSummaryBuyer;
            //   vars.stockSummarySellerMain = init.stockSummaryBuyer;
            // }
            vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
            vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
            vars.stockTradeSummarySMRY = vars.sStockSmry;
          }else{
            if(vars.subscriptionFlags['stockCashPageInvboard']){
              vars.constBL.divider = 0.5;
              vars.constBL.hc = 0;
              vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
              vars.stockCashInfo = {...vars.stockCashInfo, bl: fa.GetBuyLimit(vars.constBL, "onClick-cloeChart")}
            }
            if(vars.bidOfferCode.length !== 0){
              vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));     
              vars.netActionAux.send(fmsg.ux_bid( vars.bidOfferCode));
              vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
              vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
            }
            vars.formBuyFooter = fd.formBuyFooter; vars.formSellFooter = fd.formSellFooter; vars.bidOfferCode = ""; vars.iStockData = {}; 
            vars.sStockSmry = {}; vars.portfolioData = fd.portfolio; vars.bidData = []; vars.offerData = []; vars.bidOfferPrice = 0; vars.bidOfferVol = 0; vars.bidOfferVal = 0; 
            vars.bidOfferMkt = "0RG"; vars.bidOfferExpire = "0";vars.order_type = "7"; vars.prevStatus = true;  vars.offerAlldStatus = false; vars.haircutCode = 0;
          }
          return {...vars, cartIconStatus: false, openModalBuySell: false};
        }
      }
      // handle onclick of open modal Modify Watchlist in SideBar
      else if(type === 'openModalModifyWatchlist'){ 
        if(vars.watchlistCode.length){
          let GroupName = GroupList('amendGroup', vars.watchlistCode)[0].groupname;
          let newAmendGroup = vars.watchlistCode
          let x = ['\xa0','\xa0',] 
          let dataAmend =  GroupList('amendGroup', newAmendGroup)
          let newAmendGroupContainer= [...dataAmend, ...x.slice(dataAmend.length,2)]    

          let codeList = groupSearch(newAmendGroup,GroupName.split(" ")[1]).sort()
          let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList) 
          let newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
          return{...vars, amendGroupName :GroupName , amendGroup:newAmendGroup, amendModalStatus:true, 
            amendGroupContainer:newAmendGroupContainer, amendCodeContainer:newAmendCodeContainer,
          }
        }
      }
      // handle onclick of close Modify Watchlist in SideBar
      else if(type === 'closeModalModifyWatchlist'){ 
        return{
          ...vars, amendGroup:[],  addGroupCode:[], amendModalStatus:false, amendActiveTab:'1'
        }
      }
    }
    // handle onclick in transaction (Buy/buy More, Sell)
    else if(pageType === 'transaction') {
      // onclick buy, sell, 
      if(type === 'openBuySellModal'){
        vars.orbookStatus = fa.GetOrderbookStatus(vars.tradingStatus, vars.preOpeningStatus, vars.iStockData); 
        // Subcribe and inquiry data for table informations        
        if(vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['stockFinancialStatement']){
          vars.bidOfferVol = '0'; vars.bidOfferVal = '0'; vars.offerAlldStatus = false; vars.bidOfferPrice = vars.sStockSmry.close_price;
          vars.bidOfferMkt = strCall.codeMarket(vars.bidOfferCode);
          vars.bidOfferExpire = strCall.codeExpire(vars.bidOfferCode);
          // Update Form Buy
          vars.constBL.hc = vars.haircutCode;
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
          let remainTrade = fa.GetBuyLimit(vars.constBL, "onClick-openBuySell"); 
          let cashOn = vars.constBL.balance - vars.constBL.OB
          let cashBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(cashOn/Number(vars.bidOfferPrice));
          let maxBuyS = Number(vars.bidOfferPrice) == 0 ? 0 : Math.floor(remainTrade/Number(vars.bidOfferPrice));        
          vars.formBuyFooter = {...vars.formBuyFooter, remainTrade, cashOn, cashBuyS, cashBuyL: Math.floor(ShareToLot(cashBuyS)), maxBuyS, maxBuyL: Math.floor(ShareToLot(maxBuyS)) };
        }else{
          vars.haircutCode = fa.getHaircut(vars.haircutData, strCall.code(data));
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === data);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === data), {code: data, ...refStock});

          vars.formSellFooter = {...vars.formSellFooter, ownQtyL: vars.portfolioData.slot, ownQtyS: vars.portfolioData.sshares, avgprice: vars.portfolioData.avgprice,
            perPl: vars.portfolioData.perPlFormSell, value: vars.portfolioData.valFormSell, pl: vars.portfolioData.plFormSell};
          // Update Form Buy
          vars.constBL.hc = vars.haircutCode;
          vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          vars.formBuyFooter = {...vars.formBuyFooter, cashOn: (vars.constBL.balance - vars.constBL.OB), remainTrade: fa.GetBuyLimit(vars.constBL, "onClick-openBuySell")};
          vars.bidOfferPrice = 0;
          vars.bidOfferVol='0';
          vars.bidOfferCode = data;
          if(vars.subscriptionFlags['stockTradeSummaryPage']) {
            vars.sStockSmry = vars.stockTradeSummarySMRY;
            vars.bidOfferPrice = vars.stockTradeSummarySMRY.close_price;
          } else{
            vars.netActionAux.send(fmsg.sx_stockSummary(strCall.code(data)));
          }    
          vars.netActionAux.send(fmsg.ix_stockData(strCall.code(data)));
          vars.netActionAux.send(fmsg.sx_bid(data));
          vars.netActionAux.send(fmsg.sx_offer(data));
          vars.netActionAux.send(fmsg.sx_stockiepv(data));
        }
        return {...vars, openModalBuySell: true, order_type: "7"}
      }
      // handle onclick X in Modal Buy and Sell
      else if(type === 'closeBuySellModal'){
        // unsubscribe data : stockSmry, bid and offer
        if(vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['stockFinancialStatement']){
          vars.bidOfferVol = 0; vars.bidOfferVal = 0;
        }else{
          if(!vars.subscriptionFlags['stockTradeSummaryPage'] ) {
            vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));
          }
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
          
          vars.bidOfferCode='';
          vars.transactionType='';
          vars.iStockData={};
          vars.sStockSmry={};
          vars.bidData=[];
          vars.offerData=[];
          vars.bidOfferPrice=0;
          vars.bidOfferVol='0';
          vars.bidOfferVal='0';
          vars.bidOfferMkt='0RG';
          vars.bidOfferExpire='0';
          vars.order_type = "7";
          vars.prevStatus=true;
          vars.clientOrderId=0;
          vars.portfolioData=fd.portfolio; 
          vars.offerAlldStatus=false;
        }
        return {...vars, openModalBuySell: false}
      }
      // handle onclick bid/offer price in tabel information table
      else if(type === 'bidOfferPrice'){
        if(vars.subscriptionFlags['AutOrderSetting'] && !vars.cartIconStatus){
          return {...vars, setPriceAutomatic: Number(data) }
        }else{
          // update footer on form buy/sell
          let cashBuy = Number(data) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(data))
          let maxBuy = Number(data) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(data))
          vars.formBuyFooter.cashBuyS = cashBuy
          vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
          vars.formBuyFooter.maxBuyS = maxBuy
          vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))
          let nval = Number(data)*Number(vars.bidOfferVol)
          return{
            ...vars, bidOfferPrice:data, bidOfferVal:LotToShare(nval)
          }      
        }
      }
      // handle onClick Max in form buy
      if(type === 'maxVol'){
        let maxVol = vars.myBalance <= 0 ? 0 : Math.floor(ShareToLot(vars.myBalance/Number(vars.bidOfferPrice))).toFixed(0);
        let newBidVal = vars.myBalance <= 0 ? 0 : Multiplication(vars.bidOfferPrice, maxVol);
        return{...vars, bidOfferVol: maxVol, bidOfferVal: LotToShare(newBidVal)}
      } 
      // handle checkbox in Prevent same order in form buy/sell
      else if(type === 'prev_same'){
        return{...vars, prevStatus:!vars.prevStatus}
      } 
      //  handle checkbox in Order Book in form buy/sell
      else if(type === 'order_book'){
        if(vars.tradingStatus === "Break"){
          return{...vars, orbookStatus:!vars.orbookStatus}
        }
      }
      //  handle dropdown in Mkt in form buy/sell
      else if(type === 'mkt'){        
        if(data === '0TN'){
          vars.bidOfferExpire = "S"
        }else{
          vars.bidOfferExpire ='0'
        }
        return{...vars, bidOfferMkt:data}
      }
      //  handle dropdown in Expire in form buy/sell
      else if(type === 'expire'){
        return{...vars, bidOfferExpire:data}
      }
      //  handle dropdown in order_type in form buy/sell
      else if(type === 'order_type'){
        let bidOfferPrice = data === "1" ? "" : vars.sStockSmry.close_price;
        let bidOfferVal =  data === "1" ? "" : vars.sStockSmry.close_price * vars.bidOfferVol;
        return{...vars, bidOfferPrice, bidOfferVal, order_type: data}
      }
      // handle checkbox in form sell
      else if(type === 'all'){
        let ndata = Math.floor(data).toFixed(0)
        var newBidVal = Multiplication(vars.bidOfferPrice, ndata)
        return{...vars, bidOfferVol:ndata, bidOfferVal:LotToShare(newBidVal), offerAlldStatus:!vars.offerAlldStatus}
      }  
      // handle onclick BUY (order)
      else if(type === 'buyOrder'){
        let price = Number(vars.bidOfferPrice)
        let clientId = vars.GeneralType ? vars.regulerId : vars.marginId
        // Message buy Order for VM 10
        let message ={ 
          "client_order_id":vars.clientOrderId,       
          "symbol": strCall.code(vars.bidOfferCode),
          "order_vol": vars.bidOfferVol,
          "order_price": price,
          "order_market": vars.bidOfferMkt,
          "order_timeinforce": vars.bidOfferExpire,
          // "order_type": vars.order_type,
          "order_expire":getDate2(),
          "prevent_same_order":"Y",
          // "prevent_same_order":vars.prevStatus ? "Y" : 'N',
          "is_booking":vars.orbookStatus ? true : false,
          "order_send_time":getTime2("time"),
          "order_send_date":getDate2(),
          "side":"1",
          "client_id":clientId,
          // 'limit':7
        };
        vars.netAction.send(fmsg.newOrder(message));
        if(!vars.verifyPinStatus){
          vars.tempOrder = message;
        }
        // Close Modal BuySell
        if (vars.subscriptionFlags['stockWatchlistPage']){ 
          if(vars.cartIconStatus){
            vars.frameAction.closeModal(100)
          }
        }else{
          vars.frameAction.closeModal(100)          
        }  

        if(vars.subscriptionFlags['stockInfoPage'] === true || vars.subscriptionFlags['livetradePage'] === true){
          return{...vars, bidOfferVol: '0', bidOfferVal: '0', prevStatus: true}
        }else{
          return{...vars, bidOfferPrice: '',bidOfferVol: '0', bidOfferVal: '0', prevStatus: true, }
        }
      }
      // handle onClick SELL (order)
      if(type === 'sellOrder'){
        let newMyVolLot = Number(vars.myVolLot)-Number(vars.bidVol)
        let price = Number(vars.bidOfferPrice)
        // Message buy Order for VM 10
        let message ={
          "client_order_id":vars.clientOrderId,
          "symbol": strCall.code(vars.bidOfferCode),
          "order_vol": vars.bidOfferVol,
          "order_price": price,
          "order_market": vars.bidOfferMkt,
          "order_timeinforce":vars.bidOfferExpire,
          // "order_type": vars.order_type,
          "order_expire":getDate2(),
          "prevent_same_order":"Y",
          // "prevent_same_order":vars.prevStatus ? "Y" : 'N',
          "is_booking":vars.orbookStatus ? true : false,
          "order_send_time":getTime2("time"),
          "order_send_date":getDate2(),
          "side":"2",
          "client_id":vars.GeneralType ? vars.regulerId : vars.marginId,
          // 'limit':7,
        };
        vars.netAction.send(fmsg.newOrder(message));
        if(!vars.verifyPinStatus){
          vars.tempOrder = message;
        }
        // Close Modal BuySell
        if (vars.subscriptionFlags['stockWatchlistPage']){ 
          if(vars.cartIconStatus){
            vars.frameAction.closeModal(100)
          }
        }else{
          vars.frameAction.closeModal(100)          
        }

        return{...vars, bidOfferPrice:'',bidOfferVol:'0', bidOfferVal:'0', prevStatus:true, 
          myVolLot:newMyVolLot, offerAlldStatus:false,}
      }
    }
    // handle onclick in My Account / Landing page
    else if(pageType === "landingPage"){
      if(type === 'investmentBoard'){
        // request for Portfolio Page
        if(vars.subscriptionFlags['landingPageInvboard'] && vars.stateLanding == 0 && vars.portfolioStatus === true){
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        }else if(vars.subscriptionFlags['landingPageInvboard'] && vars.stateLanding == 1 || vars.subscriptionFlags['stockCashPageInvboard']){
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
          vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
        }else if(vars.subscriptionFlags['fundTransferPageInvboard']){
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        }
      }
      // Button stop/resume Streaming Portfolio page
      else if(type === "startStopStreamPortfolio"){
        let ncode = vars.codePortofolio.filter(d=>!vars.sidebarCode.includes(d));
        vars.netActionAux.send(vars.portofolioInfo.stream ? fmsg.ux_stockSummaryXS(ncode) : fmsg.sx_stockSummaryXS(ncode)) 
        
        // if(!vars.portofolioInfo.stream){
        //   vars.portofolioInfo.refresh = true;
        //   vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        // }
        let portofolioInfo = {...vars.portofolioInfo, stream: !vars.portofolioInfo.stream}
        return {...vars, portofolioInfo}
      }
      // Button stop.resume Streaming Stock Cash Page
      else if(type === "startStopStreamStockCash"){
        let ncode = vars.codePortofolio.filter(d=>!vars.sidebarCode.includes(d));
        vars.netActionAux.send(vars.stockCashInfo.stream ? fmsg.ux_stockSummaryXS(ncode) : fmsg.sx_stockSummaryXS(ncode));
        // if(!vars.stockCashInfo.stream){
        //   vars.stockCashInfo.refresh = true;
        //   vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        // }
        let stockCashInfo = {...vars.stockCashInfo, stream: !vars.stockCashInfo.stream,}
        return {...vars, stockCashInfo}
      }
      // onclick in Historycal
      else if(type.includes('History')){
        let { start, to } = vars.rangeTradelistHistory;
        if(type === "GoTradelistHistory"){
          if(start === "" || to === ""){
            return { ...vars,statusAlertN:true, msgAlert3:'Please insert date' }
          }else{
            vars.netAction.send(fmsg.q_tradesHistory(vars.regulerId, start, to));
          }
        }
        else if(type === "GoOrderlistHistory"){
          let { start, to } = vars.rangeOrderlistHistory;
          if(start === "" || to === ""){
            return{...vars,statusAlertN:true, msgAlert3:'Please insert date'}
          }else{
            vars.netAction.send(fmsg.q_ordersHistory(vars.regulerId, start, to));
          }
        }
        else if(type === "GoTransactiontHistory"){
          let { start, to } = vars.rangeTransactionHistory;
          if(start === "" || to === ""){
            return { ...vars,statusAlertN:true, msgAlert3:'Please insert date' }
          }else{
            vars.netAction.send(fmsg.q_cashHistory(vars.regulerId, start, to));
            vars.netAction.send(fmsg.q_stockHistory(vars.regulerId, start, to));
          }
        }
      }
      // double klik order in order history
      // jika orderDetail di klik
      else if(type === 'openModalOrderDetail'){
        let nOrderDetail= [
            {label:"Order Number",value:data.order},
            {label:"Market Order Number",value:data.marketNoC},
            {label:"Code",value:data.code},
            {label:"Command",value:data.cmd},
            {label:"Type",value:data.type},
            {label:"Market",value:data.mkt},
            {label:"Price",value:data.price},
            {label:"Total Match Volume (Lot)",value:ShareToLot(data.mvol)},
            {label:"Order Volume (Lot)",value:ShareToLot(data.vol)},
            {label:"Order By",value:data.userOrderC},
            {label:"Status",value:data.status},
        ]               
        vars.netAction.send(fmsg.q_ordersHistDetail(data.soid));
        return{...vars, orderDetail:nOrderDetail}
      }
      // onclick in Fund Transfer
      else if(type === 'tabFundTransfer'){
        if(data == 1){
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        }
        if(data == 2){
          let { start, to} = vars.rangeFundTransferList
          if(start != "" && to != ""){
            vars.netAction.send(fmsg.q_fundTransferlist(vars.regulerId, start, to));
          }
        }
        if(data === 3 || data === 4){
          if(vars.custodyList.length <= 0){
            vars.fundStockCodeList = vars.stocklistOptions.filter((item) => vars.codePortofolio.includes(item.code));
            vars.netActionAux.send(fmsg.ix_custodyList);
          }
        }
        if(data == 5){          
          let { start, to } = vars.rangeFundTransferSTL;
          if(start != "" && to != ""){
            vars.netAction.send(fmsg.q_stockTransfer(vars.regulerId, start, to));
          }
        }
        return{...vars, tabFundTransfer:data,}
      }
      else if(type === 'GoFundTransferList'){
        let { start, to } = vars.rangeFundTransferList;
        if(start === "" || vars.rangeFundTransferList.to === ""){
          return{...vars,statusAlertN:true, msgAlert3:'Please insert date'}
        }else{
          vars.netAction.send(fmsg.q_fundTransferlist(vars.regulerId, start, to));
        }
      }
      else if(type === "GoFundTransferSTL"){
        let { start, to } = vars.rangeFundTransferSTL;
        if(start === "" || to === ""){
          return { ...vars,statusAlertN:true, msgAlert3:'Please insert date' }
        }else{
          vars.netAction.send(fmsg.q_stockTransfer(vars.regulerId, start, to));
        }
      }
      else if(type === 'checkBoxRTGS'){
        return {...vars, rtgsStatus:!vars.rtgsStatus}
      }
      else if(type.includes("cancelFundTransfer")){
        let ntype = type.split("|")[1]
        if(ntype === 'openModal'){
          console.log("ini teh cancel", data)
          let cdata = vars.fundTransferList.filter(d=>d.no == data)[0]
          return{...vars,cancelFundtransfer:cdata}
        }
        else if(ntype === "cancelYes"){
          let { msg_id, ref_no } = vars.cancelFundtransfer;
          vars.netAction.send(fmsg.ft_cancel(msg_id, ref_no));
          vars.frameAction.closeModal(100)
        }
      }
      else if(type === "cancelStocktransfer"){
        vars.netAction.send(fmsg.stock_cancelTransfer(data));
      }
      // send Fund Transfer
      else if(type === 'sendFundTransfer'){
        let params;
        switch(vars.tabFundTransfer) {
          case 1: {
            if(vars.statusAlertC){ 
              vars.statusAlertC = false;
              vars.netAction.send(fmsg.ft_request({
                "message_id": GenerateId('idFT'),
                "transfer_type": vars.rtgsStatus ? "RTGS" : "KLIRING",
                "transfer_qty": (vars.amountWithdrawable * 1),
                "request_date": GenerateTime("datetime","YYYY-MM-DD hh:mm:ss")
              }));   
              return {...vars, amountFund: 0, amountWithdrawable: 0, transferDate: "" }
            }
          }; break;
          case 3: {
            vars.netAction.send(fmsg.stock_transfer(data));
          }; break;
          case 4: {
            vars.netAction.send(fmsg.stock_receive(data));
          }; break;
        }
      }
      /** Warant / right */
      else if(type === "tabExercise"){
        if(data === 2 && vars.exerciseRange){
          let params = { ...vars.exerciseRange, "client_id": vars.regulerId};
          vars.netAction.send(fmsg.q_exerciseRightRequest(params))
        }else if(data == 1){
          vars.netAction.send(fmsg.q_exerciseRightList(vars.regulerId));
        }
        return {...vars, exerciseState: {tab: data}}
      }else if(type === "openExercise"){
        return{...vars, tempExercise: setTempData.openExercise(data)};
      }else if(type === "sendExercise"){
        vars.netAction.send(fmsg.rightIssue(vars.tempExercise));
        return {...vars, tempExercise: {}, exerciseState: {tab: vars.exerciseState.tab}}
      }else if(type === "goExercise"){
        let rangeReq= {
          "start_date": timeModifier(data.start, 'reqDate'),
          "end_date": timeModifier(data.to, 'reqDate')
        };
        vars.netAction.send(fmsg.q_exerciseRightRequest({"client_id": vars.regulerId, ...rangeReq}));
        return {...vars, exerciseRange: rangeReq}
      }
      // Onclick in TRADEPL
      else if(type === "GoTradePl"){
        let { start, to } = vars.rangeTradePl;
        if(start === "" || to === ""){
          return { ...vars,statusAlertN:true, msgAlert3:'Please insert date' }
        }else{
          vars.netAction.send(fmsg.q_tradePL(vars.regulerId, start, to));
        }
      }
      else if(type === "filterTradePL"){
        return {...vars, tradePlInfo: UpdateTradePLInfo(data, vars.tradePl) }
      }
    }
    // MARKET & STATISTIC
    else if(pageType === "marketStatistic"){
      if(type === "tabIntIndices"){
        let msqRequest = data === 1 ? fmsg.ix_globalIDX(vars.globalIdListArr) : fmsg.ix_futureList;
        vars.netActionAux.send(msqRequest);
        if(data === 2 && vars.intIndexFutures === undefined){
          vars.intIndexFutures = fa.addRowEmpty("card-intIndices");
        }
        return {...vars, tabIntIndices: data}
      }
      if(type === "codeCurrencies"){
        let currenciesActive = vars.currencies.find(d => d.other === data);
        return {...vars, currenciesActive}
      }
      if(type === "codeCommodity"){
        let commodityActive = vars.commodity.find(d => d.code === data);
        return {...vars, commodityActive}
      }
    }
    // BROKER PAGE
    else if(pageType === "brokerPage"){
      if(type === 'GoBrokerTradeHistory'){
        let { start, to } = vars.rangeBrokerTradeHistory;
        if(vars.codeBrokerInfo.saham === undefined){
          return { ...vars, statusAlertN: true, msgAlert3: 'Please insert Broker Code' }
        } else if(start === "" || to === ""){
          return { ...vars, statusAlertN: true, msgAlert3: 'Please insert date' }
        } else {
          vars.brokerTradeHistory = init.brokerTradeHistory; vars.brokerTradeHisInfo = {};
          vars.netActionAux.send(fmsg.ix_brokerHistory(vars.codeBrokerInfo.code, start, to));
        }
      }
    }
    // handle onclick in Trade page
    else if(pageType === 'trade'){
      // handle change box on Orderbook
      if(type==='tradeBox'){
        let box = data.split('-')
        return{...vars, box1:Number(box[0]), box2:Number(box[1]), box3:Number(box[2])}
      }
      // jika button buy/sel pada order book di klik
      else if(type.includes("TradeModal")){   
        let codeList = [vars.codeForOB1, vars.codeForOB2, vars.codeForOB3]
        let stockData =[vars.iSDForOB1, vars.iSDForOB2, vars.iSDForOB3]
        let bidData = [vars.bidData1, vars.bidData2, vars.bidData3]
        let offerData = [vars.offerData1, vars.offerData2, vars.offerData3]
        let summaryData = [vars.sStockSForOB1, vars.sStockSForOB2, vars.sStockSForOB3]
        // jika open modal
        if(type == "openTradeModal"){
          // untuk mengatur box pada orderBook (trade Page)
          let bsCode = searchCode('code1',data,codeList)
          let bsIndex = searchCode('index1',data,codeList)
          codeList.splice(bsIndex,1)
          
          // status PreOpening, LowerUpper
          let status = stockData[bsIndex].trade_in_pre ? stockData[bsIndex].trade_in_pre.includes("The stock can be traded") : false;
          let nAR = GetLowerUpper(status, summaryData[bsIndex].open_price, summaryData[bsIndex].prev_price, stockData[bsIndex])
          vars.orbookStatus = fa.GetOrderbookStatus(vars.tradingStatus, status, stockData[bsIndex])
          
          // get haircut & update buy Limit / tradelimit          
          vars.haircutCode = fa.getHaircut(vars.haircutData, strCall.code(bsCode));
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === bsCode.split(".")[0]);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === bsCode.split(".")[0]), {code: bsCode.split(".")[0], ...refStock});

          
          vars.formSellFooter = {...vars.formSellFooter, ownQtyL: vars.portfolioData.slot, ownQtyS: vars.portfolioData.sshares, avgprice: vars.portfolioData.avgprice,
            perPl: vars.portfolioData.perPlFormSell, value: vars.portfolioData.valFormSell, pl: vars.portfolioData.plFormSell};
          
          // Update Form Buy
          vars.constBL.hc = vars.haircutCode;
          vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          vars.formBuyFooter.remainTrade = fa.GetBuyLimit(vars.constBL, "onClick-tradeModal");
          let nsummaryData = summaryData[bsIndex]
          let cashBuy = GetMaxBuy(nsummaryData.close_price, vars.formBuyFooter.cashOn)
          let maxBuy = GetMaxBuy(nsummaryData.close_price, vars.formBuyFooter.remainTrade)
          vars.formBuyFooter.cashBuyS = cashBuy
          vars.formBuyFooter.cashBuyL = Math.floor(ShareToLot(cashBuy))
          vars.formBuyFooter.maxBuyS = maxBuy
          vars.formBuyFooter.maxBuyL = Math.floor(ShareToLot(maxBuy))

          let nprice = nsummaryData.close_price == 0 ? nsummaryData.prev_price : nsummaryData.close_price
          let nmkt = strCall.codeMarket(bsCode);
          let nexp = strCall.codeExpire(bsCode);

          vars.netActionAux.send(fmsg.sx_stockiepv(bsCode.split(".")[0]));

          return{...vars, statusOfTradeModal:true, openModalBuySell: true,
            bidOfferCode:bsCode, 
            bidData:bidData[bsIndex],
            offerData:offerData[bsIndex],
            sStockSmry:summaryData[bsIndex],
            iStockData:stockData[bsIndex],
            bidOfferPrice:nprice,
            bidOfferVol:'0',
            bidOfferVal:'0',
            bidOfferMkt:nmkt,
            bidOfferExpire:nexp,
            prevStatus:true,
            offerAlldStatus:false,
            preOpeningStatus:status,
            lowerAR:nAR.lowerAR,
            upperAR:nAR.upperAR,}      
        }
        // jika close modal Order book Trade   
        else if(type == "closeTradeModal"){   
          if(vars.box1 == 1){
            vars.bidData1=vars.bidData; vars.offerData1=vars.offerData;   
            vars.sStockSForOB1 = vars.sStockSmry
          }else if(vars.box2 == 1){
            vars.bidData2=vars.bidData; vars.offerData2=vars.offerData;
            vars.sStockSForOB2 = vars.sStockSmry
          }else if(vars.box3 == 1){
            vars.bidData3=vars.bidData; vars.offerData3=vars.offerData;
            vars.sStockSForOB3 = vars.sStockSmry
          } 
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
          return{...vars, statusOfTradeModal:false,  bidOfferCode:'', 
            openModalBuySell: false,
            sStockSmry:{},
            iStockData:{},
            bidOfferPrice:'',
            bidOfferVol:'0',
            bidOfferVal:'0',
            bidOfferMkt:'0RG',
            bidOfferExpire:'0',
            order_type: "7",
            prevStatus:true, 
            // orbookStatus:false,
            offerAlldStatus:false, } 
        }
      }
      // fungsi onclick Selection pada Orderlist
      else if(type.includes("selected")){
        let nTradeSum = init.tradeTradeSum
        let ntradeListMain = init.tradeList

        // update Trade Summary and update Trade List
        let nmoid = [...data.map(k=>k.marketorder),...data.map(k=>k.serverOrderId)]
        nTradeSum = GetTradeTradeSummary(vars.tradeListData, nmoid)
        ntradeListMain = GetInitialRow(nor.tradeList, fd.tradeList, vars.tradeListData.filter(d=> nmoid.find(s=>d.marketorder === s || d.pserverOrderId === s)))
        
        return{...vars, tradeOrderSum:GetTradeOrderSummary(data), tradeListMain:ntradeListMain, tradeTradeSum:nTradeSum,
          orderlistInformation:GetOrderListInformation(data)}
      }
      // jika button Amend pada orderlist di klik (trade page)
      else if(type === 'openModalAmend'){
        let id = vars.orderList.findIndex(obj => obj.order == data);
        let ordered = vars.orderList[id]
        let ncode = ordered.mkt == "TN" ? ordered.code+".TN" : ordered.code
        // get haircut 
        vars.haircutCode = fa.getHaircut(vars.haircutData, ordered.code);
        let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === ordered.code);
        vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === ordered.code), {code: ordered.code, ...refStock});

        // Update Form Buy
        vars.constBL.hc = vars.haircutCode;
        vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
        vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
        vars.formAmendFooter.remainTrade = fa.GetBuyLimit(vars.constBL, "onClick-modalAmend");
        vars.formAmendFooter.cashOn = vars.myBalance
        // request data  : stock data, bid, offer,         
        vars.netActionAux.send(fmsg.ix_stockData(ordered.code));
        vars.netActionAux.send(fmsg.sx_stockSummary(ordered.code));
        vars.netActionAux.send(fmsg.sx_bid(ncode));
        vars.netActionAux.send(fmsg.sx_offer(ncode));  
        vars.netActionAux.send(fmsg.sx_stockiepv(ncode));

        return{...vars, bidOfferCode:ncode, amend:ordered, bidOfferPrice:ordered.price, 
          bidOfferVol:ordered.vlot, bidOfferVal:ordered.amount, bidData:[], offerData:[],
          prevStatus:true, bidOfferExpire:ordered.type, bidOfferMkt:ordered.mkt,
          amendWithdrawIndex:data, amendOrderModalStatus:true, iStockData:{},sStockSmry:{},
        }      
      }
      // jika X pada modal Amend di klik (trade page)   
      else if(type === 'closeModalAmend'){
        // unsubscribe : stock data, bid, offer,        
        vars.netActionAux.send(fmsg.ux_stockSummary(strCall.code(vars.bidOfferCode)));
        vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
        vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
        vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
        return{...vars,amendOrderModalStatus:false,iStockData:{},sStockSmry:{},portfolioData:fd.portfolio,
          bidData:[],offerData:[], bidOfferCode:"", bidOfferPrice:'', bidOfferVol: 0}
      }
      // jika klik ok saat Amend
      else if(type === 'amendOrder'){
        let nd = vars.amend
        let message ={ 
          "order_vol":Number(vars.bidOfferVol),
          "order_price":Number(vars.bidOfferPrice),
          "soid":nd.serverOrderId,         
        }
        if(vars.regulerId !== ''){   
          vars.netAction.send(fmsg.amendOrder(message));
        }
        // console.log(message, nd)       
        vars.frameAction.closeModal(100);
        let ntime = timeModifier(getDate2()+" "+getTime2("time"), "norder")
        let newOrdeL = vars.orderList.map(d => d.serverOrderId == nd.serverOrderId ?  
          {...d, price:vars.bidOfferPrice, vlot:vars.bidOfferVol,vshares:LotToShare(vars.bidOfferVol),
            amount : Number(LotToShare(vars.bidOfferVol))*Number(vars.bidOfferPrice), time:ntime } : d)
        return{...vars, orderList:newOrdeL, amendOrderModalStatus:false,
          iStockData:{},sStockSmry:{},portfolioData:fd.portfolio,
          bidData:[],offerData:[], bidOfferCode:"", bidOfferPrice:''}
      }
      // jika button withdrawd klik (trade Page)
      else if(type === 'openModalWithdraw'){
        let id = vars.orderList.findIndex(obj => obj.order == data);
        return{...vars, amendWithdrawData: vars.orderList[id], amendWithdrawIndex:id}
      }
      // jika withdraw ok
      else if(type === 'withdrawOrder'){ 
        vars.regulerId !== '' && vars.netAction.send(fmsg.cancelOrder(vars.amendWithdrawData.serverOrderId));
        return{...vars, amendWithdrawData:{}}
      }
      // jika orderDetail di klik
      else if(type === 'openModalOrderDetail'){
        let olDetail = vars.orderList.filter(d=>d.order === data)[0]
        let nOrderDetail= [
            {label:"Order Number",value:olDetail.order},
            {label:"Market Order Number",value:olDetail.marketorder},
            {label:"Code",value:olDetail.code},
            {label:"Command",value:olDetail.cmd},
            {label:"Type",value:olDetail.type},
            {label:"Market",value:olDetail.mkt},
            {label:"Price",value:olDetail.price},
            {label:"Total Match Volume (Lot)",value:olDetail.mlot},
            {label:"Order Volume (Lot)",value:olDetail.vlot},
            {label:"Order By",value:vars.userName},
            {label:"Status",value:olDetail.status},
        ]               
        vars.netAction.send(fmsg.q_ordersDetail(olDetail.serverOrderId));
        return{...vars, orderDetail:nOrderDetail}
      }
      // ORDERBOOKLIST
      else if(type.includes("cancelOrderbook")){
        let ntype = type.split("|")[1]
        if(ntype === 'openModal'){          
          let cdata = vars.tradeOrderBookList.filter(d=>d.bookingNo == data)[0]
          return{...vars,cancelOrderBook:cdata}
        }
        if(ntype === "cancelOrder"){
          vars.netAction.send(fmsg.q_cancelBooking(vars.regulerId, vars.cancelOrderBook.bookingNo));
          vars.frameAction.closeModal(100);
        }
      }
      // AUTOMATIC handle set automatic order setting [count, save, delete, edit, update]
      else if(type.includes("actionAut")){
        if(type === "actionAutPlusSetPrice"){ return {...vars, setPriceAutomatic: CalculatorS('addR', data)}; }
        if(type === "actionAutMinusSetPrice" && data > 0){ return {...vars, setPriceAutomatic: CalculatorS('subR', data)}; }
        if(type === "actionAutSetPrice"){ return {...vars, setPriceAutomatic: data.replace(/[^0-9]/g, '') }; }
        if(type === "actionAutSetRulePrice"){ 
          let setPriceAutomatic = data === "LP" ? vars.sStockSmry.close_price : (data === "BB" ? vars.sStockSmry.best_bid_price : vars.sStockSmry.best_offer_price);
          return {...vars, rulePrice: data, setPriceAutomatic }; 
        }        
        if(type === "actionAutoSaveTemp"){
          let type = data.orderPrice === "MANUAL" ? "MANUAL" : data.orderPrice.slice(0,2);
          let order_price = data.orderPrice === "MANUAL" ? data.manualPrice : data.orderPrice.slice(2);
          let msg = {
            "client_id": vars.regulerId,
            "symbol": vars.bidOfferCode,
            "side": data.orderType,
            "rule": vars.rulePrice,
            "indicator": data.condPrice,
            "trigger_price": Number(vars.setPriceAutomatic),
            "type": type,
            "flag": data.statusOrder ? 1 : 0,
            "order_price": Number(order_price),
            "order_vol": data.orderVol,
            "expired_date": timeModifier(data.date, "reqDate")
          };
          return {...vars, setPriceAutomatic: vars.sStockSmry.close_price, rulePrice: "LP", tempAutoOrder: msg}
        }
        if(type === "actionAutoSaveOK"){
          vars.netAction.send(fmsg.ao_createRule(vars.tempAutoOrder));
          return {...vars, tempAutoOrder: {}, termsConditionStatus: data}
        }
        if(type === "actionAutoSaveCancel"){return {...vars, tempAutoOrder: {}, termsConditionStatus: data}}
        if(type === "actionAutoDelete"){
          vars.netAction.send(fmsg.ao_deleteRule(data));
        }
        if(type === "actionAutoShowEdit") {
          let ndata = vars.orderSetttingList.find(d => d.ruleID === data);
          $("#datepickerAutEdit").datepicker("setDate", ndata.exp);
          let set = ndata.condition.split("#")
          let nsetCondAutomatic = {
            ruleID: ndata.ruleID,
            orderType: ndata.cmd == "buy" ? "1" : "2",
            orderVol: ndata.vol,
            orderPrice: init.orderPriceOption.filter(d => d.text == ndata.typePrice)[0].value,
            manualPrice: ndata.price,
            typePrice: set[0] === "Last Price" ? "LP" : (set[0] === "Best Offer Price" ? "BO"  : "BB"),
            condPrice: set[1] === "<=" ? "L" : "G",
            setPrice: set[2],
            date: ndata.exp,
            statusOrder: ndata.on,
            rule: ndata.rule
          }
          return {...vars, setPriceAutomatic: set[2], setCondAutomatic: nsetCondAutomatic}
        }
        if(type === "actionAutoUpdate"){
          let type = data.orderPrice === "MANUAL" ? "MANUAL" : data.orderPrice.slice(0,2);
          let order_price = data.orderPrice === "MANUAL" ? data.manualPrice : data.orderPrice.slice(2);          
          let msg = {
            "rule_id": data.ruleID,
            "indicator": data.condPrice,
            "trigger_price": Number(vars.setPriceAutomatic),
            "type": type,
            "order_price": Number(order_price),
            "order_vol": data.orderVol,
            "expired_date": timeModifier(data.date, "reqDate"),
            "rule": data.rule
          }
          vars.netAction.send(fmsg.ao_updateRule(msg));
          if(vars.setCondAutomatic.statusOrder != data.statusOrder){
            vars.netAction.send(fmsg.ao_switchRule({rule_id: data.ruleID, rule: data.rule}));
          };
          return {...vars, setPriceAutomatic: vars.sStockSmry.close_price, rulePrice: "LP" }
        }
        if(type === "actionAutoStatus"){
          vars.netAction.send(fmsg.ao_switchRule(data));
        }
        if(type === "actionAutoSetOptions" && vars.subscriptionFlags['AutOrderSetting']){
          return {...vars, orderPriceOption: init.orderPriceOption}
        }
        if(type === "actionAutInformation"){
          vars.frameAction.switchPage(vars.lastSubHeaderAuth, "/tradePage");
          vars.subscriptionFlags[vars.lastSubHeaderManual] = false;
          vars.subscriptionFlags[vars.lastSubHeaderAuth] = true;
          let code = vars.watchlistCode.length != 0 && vars.codeSearchAutomatic == "" ? groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort()[0] : vars.codeSearchAutomatic;
          if(code != ""){
            if(vars.verifyPinStatus){
              // get haircut 
              vars.bidOfferCode = code; 
              vars.haircutCode = fa.getHaircut(vars.haircutData, vars.bidOfferCode);
              let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.bidOfferCode);
              vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.bidOfferCode), 
                {code: vars.bidOfferCode, ...refStock});
            }
            vars.codeSearchAutomatic = code;
          }
          vars.onClickSidebar = vars.lastSubHeaderAuth === "AutOrderSetting" ? {...vars.onClickSidebar, page: true} : vars.onClickSidebar; 
          vars.frameAction.closeModal(100);
          vars.netAction.send(fmsg.ao_updateStatus);
          return {...vars, isManual: false}
        }
      }
      else if(type === "GoTradeSentOrder"){
        let { start, to } = vars.rangetradeSendOrder;
        if(start === "" || to === ""){
          return { ...vars, statusAlertN: true, msgAlert3: 'Please insert date' }
        }else{
          vars.netAction.send(fmsg.ao_executedRule(vars.regulerId, start, to ));
        }
      }
      // EXPIRE AUTOMATIC ORDER Display
      else if(type === "GoAutExpireOrder"){
        let { start, to } = vars.rangeAutExpOrder;
        if(start === "" || to === ""){
          return {...vars, statusAlertN:true, msgAlert3: 'Please insert date'}
        }else{
          vars.netAction.send(fmsg.ao_expiredRule(vars.regulerId, start, to));
        }
      }
    }
    // handle onClick in Stock info
    else if(pageType === 'stockPage'){
      // handle tab Quater or Year in STOCK FINANCIAL REPORT
      if(type === 'tabQuarterYear'){
        if(vars.codeSearchStockInfo == ""){
          return{...vars,statusAlertN:true, msgAlert3:'Please insert the stock code'}
        }else{
          if(data == 1){
            let params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "quarter": vars.stockFinancial.valQtr}
            vars.netAction.send(fmsg.q_financialReport(params));
          }else{
            // request financial report
            let params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "year": vars.stockFinancial.valYear}
            vars.netAction.send(fmsg.q_financialReport(params));
          }
          let ndata = {tab: data, valQtr: vars.stockFinancial.valQtr, valYear: vars.stockFinancial.valYear}
          return {...vars, stockFinancial: ndata}
        }
      }
      else if(type === "dropdownQuarter"){
          let params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "quarter": data}
          if(vars.codeSearchStockInfo != ""){
            vars.netAction.send(fmsg.q_financialReport(params));
          }
          let ndata = {tab: vars.stockFinancial.tab, valQtr: data, valYear: vars.stockFinancial.valYear}
          return {...vars, stockFinancial: ndata}
      }
      // handle onclick in code table stock watchlist
      else if(type === 'stockWathlist'){
        if(vars.bidOfferCode == data){
          return{...vars, statusAlertN: true, msgAlert3: 'code already exists'}
        }else{      
          // unsubcribe bid & offer
          vars.netActionAux.send(fmsg.ux_bid(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_offer(vars.bidOfferCode));
          vars.netActionAux.send(fmsg.ux_stockiepv(vars.bidOfferCode));
  
          // get haircut 
          let nhaircut = vars.haircutData.length > 0 ? vars.haircutData.find(hc => hc.stock_code == data) : 0
          vars.haircutCode = nhaircut == undefined || nhaircut.haircut == null || nhaircut.haircut == 0 ? 0 :Number(nhaircut.haircut);
          let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === data);
          vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === data), {code: data, ...refStock});
          
          // UPDATE FORM BUY/SELL
          let sStockSmry = vars.dataWatchlist[data];
          let bidOfferPrice = sStockSmry.close_price;
          vars.constBL.hc = vars.haircutCode;
          vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
          vars.constBL.Lqval = mathCall.sumArray(vars.stockProfile, "lqVal");
          let cashOn = vars.constBL.balance - vars.constBL.OB;
          let cashBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.cashOn/Number(bidOfferPrice));
          let maxBuy = Number(bidOfferPrice) == 0 ? 0 : Math.floor(vars.formBuyFooter.remainTrade/Number(bidOfferPrice));
          vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), 
            maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)), cashOn, remainTrade: fa.GetBuyLimit(vars.constBL, "onClick-stockWatchlist")};
          vars.formSellFooter = {... vars.formSellFooter, ownQtyL: vars.portfolioData.slot, ownQtyS: vars.portfolioData.sshares, 
            avgprice: vars.portfolioData.avgprice, perPl: vars.portfolioData.perPlFormSell, value: vars.portfolioData.valFormSell, pl: vars.portfolioData.plFormSell,
          };
          vars.netActionAux.send(fmsg.ix_stockData(data));
          vars.netActionAux.send(fmsg.sx_bid(data));
          vars.netActionAux.send(fmsg.sx_offer(data)); 
          vars.netActionAux.send(fmsg.sx_stockiepv(data));

          // count Lower & upper
          let nAR = GetLowerUpper(vars.preOpeningStatus, vars.dataWatchlist[data].open_price, vars.dataWatchlist[data].prev_price, vars.iStockData)
          vars.orbookStatus =  fa.GetOrderbookStatus(vars.tradingStatus, vars.preOpeningStatus, vars.iStockData)
          return { ...vars, bidOfferCode: data, sStockSmry, bidOfferPrice, bidData: [], offerData: [], bidOfferVol: 0, bidOfferVal: 0, 
            bidOfferMkt: '0RG', bidOfferExpire: '0', order_type: "7", prevStatus: true, offerAlldStatus: false, lowerAR: nAR.lowerAR, upperAR: nAR.upperAR,
          }
        }
      }
      // handle onclick Page of Stock Watchlist
      else if(type === 'stockWatchlistPageChange'){
        let newPageWatchlist = data % vars.sumPageWatchlist
        // wathclist code with filter group 
        let thisGroupCodeSidebar = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup).sort()
          
        // let nextPageWathlistCode=showPage(newPageWatchlist, vars.maxPageWatchlist, vars.myWatchlistCode)
        let nextPageWathlistCode=showPage(newPageWatchlist, vars.maxPageWatchlist, thisGroupCodeSidebar)
        //Unsubscribe code pada page sebelumnya dan subscribe code berikutnya
        vars.netActionAux.send(fmsg.ux_stockSummary(vars.pageWatchlistCode)) 
        vars.netActionAux.send(fmsg.sx_stockSummary(nextPageWathlistCode)) 
        return {...vars, thisPageWatchlist:newPageWatchlist, pageWatchlistCode:nextPageWathlistCode, dataWatchlist:{} }
      }
      // handle onclick BUY in Sub header of Stock Watchlist
      else if(type === 'buyStockWatchlist'){
        if(vars.bidOfferPrice == 0){
          vars.bidOfferPrice = Number(vars.sStockSmry.close_price) == 0 ? vars.sStockSmry.close_price : vars.sStockSmry.prev_price
        }
        let nmkt = strCall.codeMarket(vars.bidOfferCode);
        let nexp = strCall.codeExpire(vars.bidOfferCode);

        // let total =  vars.scSettlement.find(d=>d.label === "Total")
        // let newFormBuyFooter={cashOn:total.t2,remainTrade:0,cashBuyS:0,cashBuyL:0,maxBuyS:0,maxBuyL:0}
        return {...vars, bidOfferVol:'0', bidOfferVal:'0',bidOfferMkt:nmkt, order_type: "7",
          // formBuyFooter : newFormBuyFooter,
          bidOfferExpire:nexp, prevStatus:true, offerAlldStatus:false,
        }
      }
      // handle onclick SELL in Sub header of Stock Watchlist
      else if(type === 'sellStockWatchlist'){
        if(vars.bidOfferPrice == 0){
          vars.bidOfferPrice = Number(vars.sStockSmry.close_price) == 0 ? vars.sStockSmry.close_price : vars.sStockSmry.prev_price
        }
        let nmkt = strCall.codeMarket(vars.bidOfferCode);
        let nexp = strCall.codeExpire(vars.bidOfferCode);
        return {...vars, bidOfferVol:'0', bidOfferVal:'0',bidOfferMkt:nmkt, order_type: "7",
        bidOfferExpire:nexp, prevStatus:true, offerAlldStatus:false,}
      }
      // handle Tab Stock Trade Hostory
      else if(type ==="tabStockTradeHistory"){
        let { start, to } = vars.rangeStockTradeHistory;
        if(data === 1){
          if(vars.codeSearchStockInfo != ""){
            vars.netActionAux.send(fmsg.ix_tradeDaily(strCall.code(vars.codeSearchStockInfo), start, to));
          }
        }else if(data === 2){
          if(vars.codeSearchStockInfo != ""){
            vars.netActionAux.send(fmsg.ix_tradeAll(strCall.code(vars.codeSearchStockInfo), start, to));
          }          
        }else if(data === 3){
          if(vars.codeSearchStockInfo != ""){
            vars.netActionAux.send(fmsg.ix_tradeForeign(strCall.code(vars.codeSearchStockInfo), start, to));
          }
        }
        return{...vars, tabStockTradeHistory:data, stockTradeHistoryMain: fa.addRowEmpty("card-372", [], 27.5)}
      }
      // handle tab stock trade history => Broker Chart
      else if(type ==="tabStockTradeHistoryChart"){
        let nchart = vars.stockTradeHistoryChartBuy
        if(data === 1){
          nchart = vars.stockTradeHistoryChartBuy
        }else if(data === 2){
          nchart = vars.stockTradeHistoryChartSell
        }else{
          if(vars.stockTradeHistoryChartNet.length == 0 && vars.codeSearchStockInfo != ""){
            let { start, to } = vars.rangeStockTradeHistory;
            vars.netActionAux.send(fmsg.ix_tradeAll(strCall.code(vars.codeSearchStockInfo), start, to));
            nchart = []
          }else{
            nchart = vars.stockTradeHistoryChartNet
          }
        }
        return {...vars, tabStockTradeHistoryChart:data, stockTradeHistoryChart:nchart}
      }
      else if(type === "specialNotationDetail"){
        let a = data.split("^"), specialNotationDetail = [];
        a.map(item=>specialNotationDetail.push({notation: item.split(" = ")[0],notationDesc:item.split(" = ")[1]}))
        return {...vars,specialNotationDetail}
      }
    }
    // handle modify wathlist
    else if(pageType === 'myWatclist'){
      // onChange Amwend or Add Code
      if(type === 'amendOrAdd'){
        if(data === '1'){
          let GroupName = GroupList('amendGroup', vars.watchlistCode)[0].groupname;
          let newAmendGroup = vars.watchlistCode   
          let x = ['\xa0','\xa0',] 
          let dataAmend =  GroupList('amendGroup', newAmendGroup)
          let newAmendGroupContainer= [...dataAmend, ...x.slice(dataAmend.length,2)]    

          let codeList = groupSearch(newAmendGroup,GroupName.split(" ")[1]).sort()
          let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList) 
          let newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
          return{
            ...vars, amendActiveTab:data,amendGroupName :GroupName , amendGroup:newAmendGroup,
            amendGroupContainer:newAmendGroupContainer, amendCodeContainer:newAmendCodeContainer,
          }
        }
        else{
        //   let message = fa.cekCrudWatchlist(vars.userName, vars.watchlistCode, vars.amendGroup)
        //   if(message.length > 0){            
        //     vars.statusAlertC=true;
        //     vars.msgAlert3 = 'Save changes before move tab?';
        //     vars.ieudata='myWatclist#confirmSave#yes';
        //   }
          return{
            ...vars, amendActiveTab:data
          }
        }
        
      }
      // on Selected in modify watchlist
      else if(type === 'codeSelected'){
        return{...vars, codeSelected:data}
      }
      // handle edit icon on AMEND GROUP
      else if(type === 'amendForGroup'){
        let x = ['\xa0','\xa0',]
        let codeList = groupSearch(vars.amendGroup,data.split(" ")[1]).sort()
        let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList)    
        let newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
        return{...vars, amendGroupName:data, amendCodeContainer:newAmendCodeContainer}
      }
      // handle plus (+) on AMEND GROUP
      else if(type === 'amendForCode'){
        let newAmendGroup = vars.amendGroup
        let newCode = data.split('|')[0]
        let indexGroup = data.split('|')[1]
        // Update Amend Group
        let stocks = newAmendGroup[indexGroup].stocks
        let updateStck =[newCode, ...stocks]
        let updatedObj = { ...newAmendGroup[indexGroup], stocks: updateStck};
        let updatednewAmendGroup = newAmendGroup.map(d=>d.group_name === updatedObj.group_name ? { ...d, stocks: updatedObj.stocks} : d);
        let x = ['\xa0','\xa0',] 
        let dataAmend =  GroupList('amendGroup', updatednewAmendGroup)
        let newAmendGroupContainer= [...dataAmend, ...x.slice(dataAmend.length,2)]

        let codeList = groupSearch(updatednewAmendGroup,vars.amendGroupName.split(" ")[1]).sort()
        let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList) 
        let newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
        return{...vars, amendGroup:updatednewAmendGroup, amendGroupContainer:newAmendGroupContainer,
          amendCodeContainer:newAmendCodeContainer,}
      }      
      // handle save on AMEND GROUP
      else if(type === 'amendGroupToServer'){
        let message = fa.cekCrudWatchlist(vars.userName, vars.watchlistCode, vars.amendGroup)
        if(message.length > 0){
          message.map(m => vars.netActionAux.send(m));
          return{...vars, codeSelected:''}
        }else{
          return{...vars,statusAlertN:true, msgAlert3:'Anda belum melakukan perubahan Wathclist'}
        }  
      }
      // handle exit modal (X) for confirm Save Amend
      else if(type === 'confirmSave'){
        vars.statusAlertC=false 
        if(vars.amendActiveTab === '1'){
          let message = fa.cekCrudWatchlist(vars.userName, vars.watchlistCode, vars.amendGroup)         
          message.map(m => vars.netActionAux.send(m));
          vars.amendModalStatus=false
          vars.frameAction.closeModal(100)        
          return{...vars, codeSelected:'',}
        }else{
          if(vars.addGroupName.length === 0){
            vars.statusAlertN = true
            vars.msgAlert3 = 'Please input group name.'
          }else{
            let groupNameList = GroupList('groupNames', vars.watchlistCode)
            let result = searchCode('code1',vars.addGroupName,groupNameList)
            if(result === undefined){
              let groupList = GroupList('addGroup',vars.addGroupCode);
              let x = ['\xa0','\xa0','\xa0','\xa0','\xa0']
              let message = { action_type: "INSERT-DB", sub_type: "WATCHLIST",
                params: {username: vars.userName, group_name: vars.addGroupName, stocks: groupList}}
              vars.netActionAux.send({text:JSON.stringify(message)}) 
              vars.amendModalStatus=false
              vars.frameAction.closeModal(100)
              return{...vars, addGroupCode:[], codeSelected:'', amendGroup:[], addGroupCodeContainer:x, addGroupName:''}
            }else{
              vars.statusAlertN = true
              vars.msgAlert3 = 'Nama Group sudah ada di list'
            }
          }          
        }
      }
      // handle plus (+) on ADD GROUP
      else if(type === 'addCodeToNewGroup'){
        var newAddGroupCode = vars.addGroupCode
        newAddGroupCode.push({code:data+" - "+vars.stockList[data]})  
        let x = ['\xa0','\xa0','\xa0','\xa0','\xa0']
        let newAddCodeContainer = [...x.slice(newAddGroupCode.length,4), ...newAddGroupCode]
        return{
        ...vars, addGroupCode:newAddGroupCode, addGroupCodeContainer:newAddCodeContainer,
        }
      }
      // handle Delete with confirm
      else if(type === 'delete'){
        vars.statusAlert=false
        vars.statusAlertC=false
        var code = data.split('|')      
        // handle trash icon in Group on AMEND GROUP
        if(code[0] === 'deleteGroupFromAmendGroup'){
          vars.statusAlertC=false;
          // Update Amend Group
          let sCode = code[1].split(' ')[1];
          let delIndex = vars.amendGroup.findIndex(obj => obj.group_name == sCode);
          const updatednewAmendGroup = [...vars.amendGroup.slice(0, delIndex), ...vars.amendGroup.slice(delIndex + 1)];
          let x = ['\xa0','\xa0',] 
          let dataAmend =  GroupList('amendGroup', updatednewAmendGroup)
          let newAmendGroupContainer= [...dataAmend, ...x.slice(dataAmend.length,2)]
          if(updatednewAmendGroup.length > 0 ){
            var newAmendGroupName = updatednewAmendGroup.length > 0  ? GroupList('amendGroup', updatednewAmendGroup)[0].groupname : '';

            let codeList = groupSearch(updatednewAmendGroup,newAmendGroupName.split(" ")[1]).sort()
            let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList) 
            var newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
          }else{
            var newAmendGroupName = '';
            var newAmendCodeContainer= x
          }
          return{...vars, amendGroup:updatednewAmendGroup, amendGroupName :newAmendGroupName , amendGroupContainer:newAmendGroupContainer,
            amendCodeContainer:newAmendCodeContainer}
        }
        // handle trash icon in code on AMEND GROUP
        else if(code[0] === 'deleteCodeFromAmendGroup'){
          vars.statusAlertC=false
          let newAmendGroup = vars.amendGroup.length == 0 ? vars.watchlistCode : vars.amendGroup
          let sCode = vars.amendGroupName.split(' ');
          // Update Amend Group
          let objIndex = newAmendGroup.findIndex(obj => obj.group_name == sCode[1]);
          let stocks = newAmendGroup[objIndex].stocks
          let stckIndex = newAmendGroup[objIndex].stocks.findIndex(obj=>obj==code[1]);
          let updateStck =[...stocks.slice(0, stckIndex), ...stocks.slice(stckIndex + 1)]
          let updatedObj = { ...newAmendGroup[objIndex], stocks: updateStck};
          let updatednewAmendGroup = [...newAmendGroup.slice(0, objIndex), updatedObj, ...newAmendGroup.slice(objIndex + 1)];
          let x = ['\xa0','\xa0',] 
          let dataAmend =  GroupList('amendGroup', updatednewAmendGroup)
          let newAmendGroupContainer= [...dataAmend, ...x.slice(dataAmend.length,2)] 

          let codeList = groupSearch(updatednewAmendGroup,vars.amendGroupName.split(" ")[1]).sort()
          let dataCodeAmend = GroupListAmend('amendListGroup',codeList,vars.stockList) 
          let newAmendCodeContainer= [...dataCodeAmend, ...x.slice(dataCodeAmend.length,2)]
          return{...vars, amendGroup:updatednewAmendGroup, amendGroupContainer:newAmendGroupContainer,
            amendCodeContainer:newAmendCodeContainer,}
        }
        // handle minus (-) in ADD GROUP
        else if(code[0] === 'deleteCodeFromNewGroup'){
          vars.statusAlertC=false
          let newAddGroupCode = vars.addGroupCode
          newAddGroupCode.splice(code[0],1)
          let x = ['\xa0','\xa0','\xa0','\xa0','\xa0',]
          let newAddCodeContainer = [...x.slice(newAddGroupCode.length,4), ...newAddGroupCode]
          return{
          ...vars, addGroupCode:newAddGroupCode, addGroupCodeContainer:newAddCodeContainer,
          }
        }
      }
      // handle save on ADD GROUP
      else if(type === 'addGroupToServer'){
        let groupList = GroupList('addGroup',vars.addGroupCode);
        let x = ['\xa0','\xa0','\xa0','\xa0', '\xa0']
        let message = { action_type: "INSERT-DB", sub_type: "WATCHLIST",
          params: {username: vars.userName, group_name: data, stocks: groupList}}
        vars.netActionAux.send({text:JSON.stringify(message)})       
        return{...vars, addGroupCode:[], codeSelected:'', amendGroup:vars.watchlistCode, addGroupCodeContainer:x, addGroupName:''}
      }
    
    }
    // handle Refresh Page
    else if(pageType === 'refreshPage'){
      if(type === 'sidebarXS'){
        let ndata = data.filter(d => d !== '\xa0')
        vars.netActionAux.send(fmsg.ix_stockSummaryXS(ndata));
      }
      // Header 1 : My Account
      else if(type === 'portfolioMyAccount'){
        let reqCode = vars.portofolios.filter(v => v.lastprice == 0 || v.lastprice == "-").map(v => v.code)
        if(reqCode.length > 0){
          vars.netActionAux.send(fmsg.ix_stockSummaryXS(reqCode));
        }

        vars.portofolios = init.portfolio
        vars.portofolioInfo.refresh = true;
        window.setTimeout(() =>{
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        }, 500);  
        return {...vars,}      
      }
      else if(type === 'stockAndCash'){
        let reqCode = vars.stockCash.filter(v => v.lastpriceR == 0 || v.lastpriceR == "-").map(v => v.codeR)
        if(reqCode.length > 0){
          vars.netActionAux.send(fmsg.ix_stockSummaryXS(reqCode));
        }

        vars.scSettlement = init.scSettlement;
        vars.stockCash = init.stockCash;
        vars.stockCashInfo.refresh = true;
        vars.constBL = {...vars.constBL, divider: 0.5, hc: 0}
        window.setTimeout(() =>{
          vars.netAction.send(fmsg.q_orderList(vars.regulerId)); 
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
          vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
        }, 30); 
      }
      else if(type === 'marketstatistic'){
        vars.netActionAux.send(fmsg.ix_marketStat) 
      }
      else if(type === "topbrokerMarketStatistic"){
        //broker val, broker buy, broker sell
        if (!(vars.topBrokerVal)) { 
          vars.topBrokerVal = fa.addRowEmpty("card-138");
          vars.topBrokerBuy = fa.addRowEmpty("card-138");
          vars.topBrokerSell = fa.addRowEmpty("card-138")
        }        
        vars.netActionAux.send(fmsg.ix_topBrokerVal);
        vars.netActionAux.send(fmsg.ix_topBrokerBuy);
        vars.netActionAux.send(fmsg.ix_topBrokerSell);
      }
      // MARKET & STATISTIC: INT.INDICES
      else if(type === "intIndices"){
        vars.netActionAux.send(fmsg.ix_globalIDX(vars.globalIdListArr));
      }else if(type === "intIndicesFutures"){
        vars.netActionAux.send(fmsg.ix_future(vars.indexFuturesListArr));
      }
      // MARKET & STATISTIC: CURRENCY & COMMODITY
      else if(type === "currencies"){
        vars.currencies = init.currencies;
        vars.currencies = fa.GetCurrencies(vars.forexList);
        vars.netActionAux.send(fmsg.ix_forex(vars.forexListArr));
      }
      else if(type === "commodity"){
        vars.netActionAux.send(fmsg.ix_comodity(vars.commodityListArr));
      }
      // Broker Page
      else if(type === "brokerInfo"){
        vars.netActionAux.send(fmsg.ix_brokerInfo(vars.codeBrokerInfo.code));
      }
      else if(type === "brokerTradeSummary"){
        if(vars.codeBrokerInfo.code !== undefined){
          vars.netActionAux.send(fmsg.ix_brokerStockBuy(vars.codeBrokerInfo.code));
          vars.netActionAux.send(fmsg.ix_brokerStockSell(vars.codeBrokerInfo.code));
        }
      }
      else if(type === 'brokerTradeHistory'){
        let { start, to } = vars.rangeBrokerTradeHistory;
        if(vars.codeBrokerInfo.code === undefined ){
          return { ...vars, statusAlertN: true, msgAlert3: 'Please insert Broker Code' }
        } else if(start === "" || to === ""){
          return { ...vars, statusAlertN: true, msgAlert3: 'Please insert date' }
        }else{
          vars.brokerTradeHistory = init.brokerTradeHistory; vars.brokerTradeHisInfo = {};
          vars.netActionAux.send(fmsg.ix_brokerHistory(vars.codeBrokerInfo.code, start, to));
        }
      }
      // Header 3 : StockPage
      else if(type === 'stocktradehistory'){
        if(vars.codeSearchStockInfo.length !== 0){
          let newCode = vars.codeSearchStockInfo; 
          let { start, to } = vars.rangeStockTradeHistory;
          if(vars.tabStockTradeHistory === 1){
            vars.netActionAux.send(fmsg.ix_tradeDaily(newCode, start, to));
          }else if(vars.tabStockTradeHistory === 2){
            vars.netActionAux.send(fmsg.ix_tradeAll(newCode, start, to));
          }else if(vars.tabStockTradeHistory === 3){
            vars.netActionAux.send(fmsg.ix_tradeForeign(newCode, start, to));
          }
          vars.netActionAux.send(fmsg.ix_stockSummary(newCode));
          vars.netActionAux.send(fmsg.ix_tradeBuy(newCode, start, to));
          vars.netActionAux.send(fmsg.ix_tradeSell(newCode, start, to));
          vars.netActionAux.send(fmsg.ix_tradePrice(newCode, start, to));
          return{
            ...vars,
            stockTradeHistorySMRY: init.stockTradeHistorySMRY,
            stockTradeHistoryMain: fa.addRowEmpty("card-372", [], 27.5),
            stockTradeHistoryPrice: init.stockTradeHistoryPrice,
            stockTradeHistoryBuy: init.stockTradeHistoryBuy,
            stockTradeHistorySell: init.stockTradeHistorySell,
          }
        }
      }      
      else if(type === 'stocktradesummary'){
        if(vars.codeSearchStockInfo.length !== 0){
          vars.stockTradeSummarySMRY = init.stockTradeHistorySMRY;
          vars.netActionAux.send(fmsg.ix_stockPrice(strCall.code(vars.codeSearchStockInfo)));
          vars.netActionAux.send(fmsg.ix_stockBrokerBuy(strCall.code(vars.codeSearchStockInfo)));           
          vars.netActionAux.send(fmsg.ix_stockBrokerSell(strCall.code(vars.codeSearchStockInfo)));      
        }
      }
      else if(type === 'stockFinancialReport'){
        if(vars.codeSearchStockInfo.length == 0){
          return{...vars,statusAlertN:true, msgAlert3:'Please insert the stock code'}
        }else{
          let params;
          if(vars.stockFinancial.tab == 1){
            params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "quarter": vars.stockFinancial.valQtr}
          }else{
            params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "year": vars.stockFinancial.valYear}
          }
          vars.netAction.send(fmsg.q_financialReport(params)); 
          return {...vars,  stockFinancialReport: init.stockFinancialReport,}
        }
      }
      // Header 4 : TradePage
      else if(type === 'tradePageAdv'){
        vars.tradeAdvBid = init.tradeAdv
        vars.tradeAdvOffer = init.tradeAdv
        window.setTimeout(() =>{
          vars.netActionAux.send(fmsg.ix_tradeAdv(vars.codeSearchTradeAdv,'BID'));           
          vars.netActionAux.send(fmsg.ix_tradeAdv(vars.codeSearchTradeAdv,'OFFER'));
        }, 500);
      }
      else if(type === "Ordelist"){
        vars.netAction.send(fmsg.q_orderList(vars.regulerId));
        vars.netAction.send(fmsg.q_trades(vars.regulerId));
        vars.netAction.send(fmsg.q_cashBalance(vars.regulerId));
        vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        return{...vars,orderList:fd.orderlist, tradeOrderSum:init.tradeOrderSum,tradeListMain:init.tradeList,
          tradeTradeSum:init.tradeTradeSum, orderlistInformation:fd.orderlistInformation,}
      }
      // Transaction
      else if(type === 'formbuysell'){
        if(data === "formBuy"){
          console.log(data, vars.constBL)
        }
        vars.netAction.send(fmsg.q_orderList(vars.regulerId)); 
        vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
        vars.netActionAux.send(fmsg.ix_stockData(strCall.code(vars.bidOfferCode)));
      }
    }
    // handle send/Verify Page (portfolio, stock&cash, fundtransfer Or Transaction)
    else if(pageType === 'verifyPin'){
      if(type === 'closeWrongAlert'){
        if(data === 'checkPin'){
          return {...vars, checkPinErrState:false}
        }else{
          return {...vars, changePassErrState:false,}
        }
      }else if(type === "cancelPin"){
        vars.frameAction.closeModal(100);
        return{...vars, verifyPinStatus: false, checkPinErrState: false, opsPinId: "", tempOrder: {} }
      }
      else if(type === "checkPin"){
        if(!vars.verifyPinStatus){
          // set pinPage for always
          if(vars.pinUsage === "always"){
            if(vars.subscriptionFlags['landingPageInvboard'] && vars.stateLanding == 0 ){
              vars.pinPage = "portfolioPage";
            }else if(vars.subscriptionFlags['landingPageInvboard'] && vars.stateLanding == 1 || vars.subscriptionFlags['stockCashPageInvboard']){
              vars.pinPage = "stockCashPage";
            }else if(vars.subscriptionFlags['tradeListHistoryPageInvboard']){
              vars.pinPage = "tradeListPage";
            }else if(vars.subscriptionFlags['fundTransferPageInvboard']){
              vars.pinPage = "fundTransferPage";
            }else if(vars.subscriptionFlags['inquryAccountPageInvboard']){
              vars.pinPage = "accountPagePage";
            }else if(vars.subscriptionFlags['tradePageManOrderbook']){
              vars.pinPage = "orderListPage";
            }else if(vars.subscriptionFlags['tradePageOrderBookList']){
              vars.pinPage = "ordeBookListPage";
            }else if(vars.subscriptionFlags['tradePLPageInvboard']){
              vars.pinPage = "tradePLPage";
            }else if(vars.subscriptionFlags['AutOrderSetting']){
              vars.pinPage = "orderSetting";
            }else if(vars.subscriptionFlags['AutSentOrder']){
              vars.pinPage = "sentOrder";
            }
          }
          
          vars.netAction.send(fmsg.checkPin(data, vars.opsPinId));
          return{...vars, opsPinId:''}
        }
      }
    }
    // selection handler 
    else if(pageType === 'selection'){
      // dropdown on the stocktradesummary  page (ALL/NG/TN)
      if(type === "dropdownStockTradeSummary"){
        if(vars.codeSearchStockInfo !== ''){
          let boardType = vars.stockTickBoardType == "ALL" ? '' : "."+vars.stockTickBoardType
          let nboardType = data == "ALL" ? '' : "."+data
          vars.netActionAux.send(fmsg.ux_rtradeS(strCall.code(vars.codeSearchStockInfo), boardType));
          // vars.netActionAux.send(fmsg.ux_stockSummary(vars.codeSearchStockInfo)); 
          vars.netActionAux.send(fmsg.sx_rtradeS(strCall.code(vars.codeSearchStockInfo), nboardType));                      
          vars.stockTickMain=init.stockTickMain
        }
        // vars.netActionAux.send(fmsg.sx_stockSummary(newCode)); 
        // vars.netActionAux.send(fmsg.ix_stockBrokerBuy(newCode));           
        // vars.netActionAux.send(fmsg.ix_stockBrokerSell(newCode));
        // vars.netActionAux.send(fmsg.ix_stockPrice(newCode)); 
        return {...vars, stockTickBoardType:data}
      }
    }
    // onChange Datepicker
    else if(pageType === 'datepicker'){
      switch(type) {
        case "stocktradehistory": return {...vars, rangeStockTradeHistory: data }; break;
        case "tradelistHistory": return {...vars, rangeTradelistHistory: data }; break;
        case "orderHistory": return {...vars, rangeOrderlistHistory: data }; break;
        case "transactionHistory": return {...vars, rangeTransactionHistory: data }; break;
        case "FundTransferList": return {...vars, rangeFundTransferList: data }; break;
        case "FundTransferSTL": return {...vars, rangeFundTransferSTL: data }; break;
        case "transferDate": return {...vars, transferDate: data}; break;
        case "tradePl": return {...vars, rangeTradePl: data}; break;
        case "tradeSentOrder": return {...vars, rangetradeSendOrder: data}; break;
        case "brokerTradehistory": return {...vars, rangeBrokerTradeHistory: data}; break;
        case "AutExpireOrder": return {...vars, rangeAutExpOrder: data}; break;
      }
    }
    // SETTING
    else if(pageType === "setting"){      
      if(type === "changeTabPinPass"){
        return {...vars, tabPassPin: data}
      }
      if(type === "closeModalChangePassword"){
        if(vars.firstLogin){
          return{...vars,statusAlertN:true, msgAlert3:'Please Change password and Pin for acces data'}
        }else{
          vars.frameAction.closeModal(100)          
        }
      }
    }
  },
  onChangeTextHandler:(vars, {pageType, type, data})=>{
    if(pageType === 'myWatchlist'){
      if(type === 'addGroupName'){
        return{ ...vars, addGroupName:data}
      }
    }
    else if(pageType === 'fundTransfer'){
      let ndata = data.replace(/[^0-9]/g, '')
      let cekMin = Math.min(vars.scSettlement[7].t0, vars.scSettlement[7].t1, vars.myBalance) - vars.constBL.OB;
      if(ndata > cekMin){
        return{...vars, statusAlertN: true, msgAlert3: 'Withdrawal Amount max: '+ConvertToRupiah(cekMin) }
      }else{
        if(type === "amountFund"){
          return{ ...vars, amountFund: ndata}
        }else if(type === "amountWithdrawable"){
          return{ ...vars, amountWithdrawable: ndata}
        }
      }
    }
    else if(pageType === 'financialReport'){
      if(type === "changeYear"){
        let nyear = data.replace(/[^0-9]/g, '')
        return {...vars, stockFinancialyear: nyear}
      }
      if(type === "handleKeyDown"){
        let params = {"stock_code": strCall.code(vars.codeSearchStockInfo), "year": vars.stockFinancialyear}
        vars.netAction.send(fmsg.q_financialReport(params));
        let ndata = {tab: vars.stockFinancial.tab, valQtr: vars.stockFinancial.valQtr, valYear:vars.stockFinancialyear}
        return {...vars, stockFinancial: ndata}
      }
    }
  },

  //************************** UPDATE MESSAGE REPLAY SOCKET 2 : 5050 **************************
  //============= update Subscribe replay =============
  updateSubscriberReplay : (vars, {sub_type, code, data})=>{  
    // update Newss
    if(sub_type === 'NEWS'){
      if(vars.news.time === undefined){
        var nNews = GetNewsAndReseach(Date.now(), data)
      }else{
        var nNews = vars.news
      }
      var newGenNews = vars.genNews.concat(GetNewsAndReseach(Date.now(), data));
      if(vars.genNews.length >= 5 ){
        newGenNews.shift()
      }
      return {
        ...vars,
        genNews:newGenNews,
        statusUpdate:sub_type,
        news:nNews,
      }
    }
    else{
      console.log("NEW MESSAGE : ", sub_type, data, JSON.stringify(data))
    }
  },

  //============= update Subscribe replay for Stringify =============
  updateSubscribeStringReplay : (vars, {ndata})=>{
    let arrData = ndata.split("#")      
    // update TRADESTATUS
    if(arrData[1] === "TRDSTATUS"){
        window.flash(arrData[3], 'success');
        return {...vars, tradingStatus: fa.GetTradingStatus(arrData)}
    }
    //  update IDEXS for Header
    else if(arrData[1] === 'INDEXS'){
      let newIndexSData = { "index_code": arrData[2], "index": arrData[3], "prev_index": arrData[4]}
      let change = (arrData[3]-arrData[4]).toFixed(2)
      let perChange = ((change/arrData[4])*100).toFixed(2)
      // update dataTicket Header
      let newBoxDataTickerHeader =vars.boxDataTickerHeader.map(d => d.symbol == arrData[2] ? 
        {...d, last:Number(arrData[3]), change:Number(change), percentage: Number(perChange),prev_index :Number(arrData[4]) } : d) 
        // console.log(arrData, change,perChange,arrData[4], newBoxDataTickerHeader )
      // update firstor second box
      if(arrData[2] === vars.firstBoxHeader.symbol){
        vars.firstBoxHeader.last = arrData[3]
        vars.firstBoxHeader.change=change
        vars.firstBoxHeader.percentage = perChange
        vars.firstBoxHeader.prev_index = arrData[4]
      }
      if(arrData[2] === vars.secondBoxHeader.symbol){
        vars.secondBoxHeader.last = arrData[3]
        vars.secondBoxHeader.change=change
        vars.secondBoxHeader.percentage = perChange
        vars.secondBoxHeader.prev_index = arrData[4]
      }
      // update index
      if(vars.subscriptionFlags["statisticMarketStatistikPage"] && vars.codeSearchMarketIndex === arrData[2]){
        vars.indexData = { symbol:arrData[2], last:Number(arrData[3]), change:Number(change), percentage: Number(perChange),prev_index :Number(arrData[4])};
        // console.log(vars.indexData)
        if(vars.firstDataStream === ''){
          vars.firstDataStream = arrData[3]
        }
      }
      //   }
      //   return{ 
      //     ...vars,
      //     compositeIDX:newIndexSData
      //   }
      // }else
      // {
        // if(vars.firstDataStream === ''){
        //   vars.firstDataStream = arrData[3]
        // }        
        return{ 
          ...vars,
          // indexData:newIndexSData,
          boxDataTickerHeader:newBoxDataTickerHeader
        }
      // }

    }
    else if(arrData[1] === 'INDEXPERIODIC'){
      let ndata = vars.indexStreamChart.concat([[timeModifier(arrData[2], "chart"),arrData[3]]])
      return{...vars, indexStreamChart:ndata}
    }
    // data stock wathlist extra small (without Vol)
    else if(arrData[1] === "STOCKSMRYXS"){
      let nstockSmryXsListCode = vars.stockSmryXsListCode.map(d=>d.stock_code === arrData[2] ? {...d, prev_price: Number(arrData[3]), close_price: Number(arrData[4])} : d)
      let nlastprice = Number(arrData[4]) == 0 ? Number(arrData[3]) : Number(arrData[4]);
      let nchange = nlastprice == 0 ? 0 : (nlastprice - Number(arrData[3])).toFixed(0);
      let nperChange = nlastprice == 0 ? 0 : ((nchange/Number(arrData[3]))*100).toFixed(2);
      let newPortofolios = vars.portofolios;

      // update portfolio
      if(vars.portofolioInfo.stream){
        newPortofolios = vars.portofolios.map(d => d.code == arrData[2] && d.lastprice != arrData[4] ? {...d, 
          lastprice : nlastprice,
          stockval: (nlastprice*Number(d.shares)).toFixed(0),
          pl : fg.Calculator('ppl',nlastprice, d.shares, d.avgprice),
          valFormSell: fg.Calculator('valFormSell', nlastprice, d.sshares, d.avgprice),
          plFormSell: fg.Calculator('plFormSell', nlastprice, d.sshares, d.avgprice),
          perPlFormSell: fg.Calculator('perPlFormSell', nlastprice, d.sshares, d.avgprice),
        }:d)
      }

      // update sidebarData
      let nsidebarData = vars.sidebarData.map(d=>{
        if(d.name === arrData[2] && d.last != nlastprice){
          let ownPercent = d.own === "\xa0" ? "\xa0" : d.own == 0 ? 0 : (((Number(nlastprice)-Number(d.own))/Number(d.own))*100).toFixed(2);
          let popUp = d.own === "\xa0" ? "\xa0" : "You have '" + d.name + "' @" + d.own + "(" + ownPercent + "%)";
          return { ...d, last: nlastprice, change: nchange, percent: nperChange,  ownPercent, popUp}
        }else{  return d   }
      });

      // update Stock&cash
      let newStockCash = vars.stockCash
      if(vars.subscriptionFlags["stockCashPageInvboard"]){
        if(vars.stockCashInfo.stream){          
          newStockCash = vars.stockCash.map(d => d.codeR == arrData[2] && d.lastpriceR != arrData[4]  ? 
            {...d, lastpriceR : nlastprice, 
              mktvalueR:(Number(nlastprice)*Number(d.pshares)).toFixed(0), 
              stockValR:(Number(d.avgpriceR)*Number(d.pshares)).toFixed(0),
              plR: CalculatorS('asp',nlastprice,d.pshares,d.avgpriceR).pl,
              persenR:CalculatorS('asp',nlastprice,d.pshares,d.avgpriceR).perPl,
              lqValR :(d.hc*Number(nlastprice)*Number(d.pshares)).toFixed(0)
            } : d);
          // update StockCash Info
          let mktVal = sumDataArray(newStockCash, 'object-mktvalueR').toFixed(2);
          let liquidVal = sumDataArray(newStockCash, 'object-lqValR').toFixed(2);
          let clRatio = (-1*  vars.stockCashInfo.balance/ liquidVal).toFixed(2) < 0 ||  liquidVal == 0 ? 0 : (-1*  vars.stockCashInfo.balance/ liquidVal).toFixed(2);
          let pl = sumDataArray(newStockCash, 'object-plR').toFixed(2);
          let sumStockVal = sumDataArray(newStockCash, 'object-stockValR').toFixed(2);
          let plRatio = mktVal == 0 ? 0 : (((mktVal - sumStockVal)/mktVal)*100).toFixed(2);
          // update constanta BL
          vars.constBL.Lqval = liquidVal;
          vars.stockCashInfo = {...vars.stockCashInfo, pl, plRatio, clRatio, liquidVal, mktVal, bl: fa.GetBuyLimit(vars.constBL, arrData[1])}
          }
      }
      if(vars.subscriptionFlags['landingPageInvboard'] && (vars.stateLanding == "k" || vars.stateLanding == "0")){
        let mktVal = sumDataArray(newPortofolios, 'object-stockval');
        let stockValR = sumDataArray(newPortofolios, 'object-stockValR');
        vars.portofolioInfo.mktVal = mktVal;
        vars.portofolioInfo.pl = sumDataArray(newPortofolios, 'objectplus1-pl').toFixed(2);
        vars.portofolioInfo.plRatio = mktVal == 0 ? 0 : (((mktVal - stockValR)/mktVal)*100).toFixed(2);
      }
      if(vars.portfolioData.code != "\xa0" && (vars.openModalBuySell || vars.subscriptionFlags['stockWatchlistPage'] || 
        vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['stockFinancialStatement'] || 
        vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['AutOrderSetting'])){
        if(vars.portfolioData.code == arrData[2]){
          let remainTrade = fa.GetBuyLimit(vars.constBL, arrData[1]);
          let maxBuy = vars.bidOfferPrice == 0 ? 0 : Math.floor(remainTrade/Number(vars.bidOfferPrice));
          vars.formBuyFooter = { ...vars.formBuyFooter, remainTrade, maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)) };
          vars.portfolioData = fg.UpdatePortfolioData("price", vars.portfolioData, {close_price: Number(arrData[4]), prev_price: Number(arrData[3]),})
        } 
      }
      let stockProfile = vars.stockProfile.map(d => d.stockcode === arrData[2] ? {...d, 
        lastprice: nlastprice, 
        mktVal: d.totalqty * nlastprice, 
        lqVal: (1-d.haircut) * d.totalqty * nlastprice,
        pl: (nlastprice - d.averageprice) * d.totalqty,
        persenPl: (nlastprice - d.averageprice) / d.averageprice,  
      } : d);
      vars.portofolioInfo.mktVal = mathCall.sumArray(stockProfile, "mktVal");
      return { ...vars, stockProfile, 
        portofolios: newPortofolios,
        stockSmryXsListCode: nstockSmryXsListCode,
        stockCash: newStockCash,
        sidebarData: nsidebarData,
      }
    }
    // Response Subscribe Stock Summary
    else if(arrData[1] === "STOCKSMRY"){
      let data = fa.getStockSMRY(arrData);
      let nsStockSmry = vars.sStockSmry;
      if(vars.subscriptionFlags['stockPage'] || vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['AutOrderSetting']){
        if(arrData[2] === strCall.code(vars.bidOfferCode)){   
          let nAR = GetLowerUpper(vars.preOpeningStatus, data.open_price, data.prev_price, vars.iStockData)
          vars.lowerAR = nAR.lowerAR;
          vars.upperAR = nAR.upperAR;
          nsStockSmry = data;
          vars.bidOfferPrice = vars.bidOfferPrice == 0 ? data.close_price : vars.bidOfferPrice;
          vars.codeStatus = data.secboard_state == 0 ? "ACTIVE" : "SUSPEND";
          
          if(vars.subscriptionFlags['dailyTechnicalTable']){
            vars.techDayData = {...vars.techDayData, nRec: fg.GetRecommendationTechDay(vars.techDayData, data.close_price)};
          }
        }
        if(vars.openModalBuySell || vars.subscriptionFlags["stockWatchlistPage"]){
          // update footer on form buy
          let cashBuy = Number(data.close_price) == 0 ?  Math.floor(vars.formBuyFooter.cashOn/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.cashOn/Number(data.close_price));
          let maxBuy = Number(data.close_price) == 0 ? Math.floor(vars.formBuyFooter.remainTrade/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.remainTrade/Number(data.close_price));
          vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)) };
        }
        if(vars.subscriptionFlags["stockWatchlistPage"]){
          vars.codeBlink = arrData[2];
          let cekCode = vars.pageWatchlistCode.find(d => d == arrData[2]);
          vars.dataWatchlist = cekCode === undefined ? vars.dataWatchlist : {...vars.dataWatchlist, [arrData[2]]: data};
        }
        if(vars.subscriptionFlags['stockTradeSummaryPage']){
          vars.stockTradeSummarySMRY = data;
        }
        if(vars.subscriptionFlags['AutOrderSetting'] && vars.setPriceAutomatic == 0){
          let setPriceAutomatic = vars.rulePrice === "LP" ? data.close_price : (vars.rulePrice === "BB" ? data.best_bid_price : data.best_offer_price);
          vars.setPriceAutomatic = Number(setPriceAutomatic);
        }
      }else if(vars.subscriptionFlags['tradePageManWatchlist']){
        if(arrData[2] === strCall.code(vars.bidOfferCode) && vars.openModalBuySell){  
          let nAR = GetLowerUpper(vars.preOpeningStatus, data.open_price, data.prev_price, vars.iStockData);
          nsStockSmry = data;
          vars.lowerAR = nAR.lowerAR; vars.upperAR = nAR.upperAR;
          vars.bidOfferPrice = vars.bidOfferPrice == 0 ? data.close_price : vars.bidOfferPrice;
          vars.codeStatus = data.secboard_state == 0 ? "ACTIVE" : "SUSPEND";
          // update footer on form buy
          let cashBuy = Number(data.close_price) == 0 ?  Math.floor(vars.formBuyFooter.cashOn/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.cashOn/Number(data.close_price));
          let maxBuy = Number(data.close_price) == 0 ? Math.floor(vars.formBuyFooter.remainTrade/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.remainTrade/Number(data.close_price));
          vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)) };
        }
        if(arrData[2] === strCall.code(vars.codeForOB1)){ vars.sStockSForOB1 = data; }
        if(arrData[2] === strCall.code(vars.codeForOB2)){ vars.sStockSForOB2 = data; }
        if(arrData[2] === strCall.code(vars.codeForOB3)){ vars.sStockSForOB3 = data; }
      }else{
        if(arrData[2] === strCall.code(vars.bidOfferCode) && (vars.openModalBuySell || vars.amendOrderModalStatus)){ 
          let nAR = GetLowerUpper(vars.preOpeningStatus, data.open_price, data.prev_price, vars.iStockData)
          nsStockSmry = data;
          vars.lowerAR = nAR.lowerAR; vars.upperAR = nAR.upperAR;
          vars.codeStatus = data.secboard_state == 0 ? "ACTIVE" : "SUSPEND";
          vars.bidOfferPrice = vars.bidOfferPrice == 0 ? data.close_price : vars.bidOfferPrice;
          // update footer on form buy
          let cashBuy = Number(data.close_price) == 0 ?  Math.floor(vars.formBuyFooter.cashOn/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.cashOn/Number(data.close_price));
          let maxBuy = Number(data.close_price) == 0 ? Math.floor(vars.formBuyFooter.remainTrade/Number(data.prev_price)) : Math.floor(vars.formBuyFooter.remainTrade/Number(data.close_price));
          vars.formBuyFooter = { ...vars.formBuyFooter, cashBuyS: cashBuy, cashBuyL: Math.floor(ShareToLot(cashBuy)), maxBuyS: maxBuy, maxBuyL: Math.floor(ShareToLot(maxBuy)) };
        }
      }
      return{...vars, sStockSmry: nsStockSmry};

    }
    // Response Subscribe Bid
    else if(arrData[1] === 'BIDOFFER.BID'){    
      let nbid = BidOffer(arrData)
      // set offer data in orderbook
      if(vars.subscriptionFlags['tradePageManWatchlist']){
        if(arrData[2] === vars.bidOfferCode){
          vars.bidData = nbid
        }
        if(arrData[2]=== vars.codeForOB1){
          vars.bidData1=nbid
        } 
        if(arrData[2] === vars.codeForOB2){
          vars.bidData2=nbid
        }
        if(arrData[2] === vars.codeForOB3){
          vars.bidData3=nbid
        }
        return{ ...vars,}
      }else{
        if(arrData[2] === vars.bidOfferCode){
          return{ ...vars, bidData:nbid,}
        }
      }
    }
    // Response Subscribe Offer
    else if(arrData[1] === "BIDOFFER.OFFER"){     
      let noffer = BidOffer(arrData)
      // set offer data in orderbook
      if(vars.subscriptionFlags['tradePageManWatchlist']){
        if(arrData[2] === vars.bidOfferCode){
          vars.offerData = noffer
        }
        if(arrData[2] === vars.codeForOB1){
          vars.offerData1 = noffer
        } 
        if(arrData[2] === vars.codeForOB2){
          vars.offerData2 = noffer
        }
        if(arrData[2] === vars.codeForOB3){
          vars.offerData3 = noffer
        }
        return{ ...vars,}
      }else{  
        if(arrData[2] === vars.bidOfferCode){
          return {...vars, offerData:noffer}
        }
      }
    }
    // Response Subscribe Offer
    else if(arrData[1] === 'RTRADE'){
      let newRunningData = GetLiveTrade(arrData)
      // update TradeAll
      let newTradeAll = vars.tradeAll.concat(newRunningData);
        if(vars.tradeAll.length >= nor.lvtrade ){ newTradeAll.shift()};

      // update foreign
      let newTradeForeign = vars.tradeForeign;
      if(arrData[14] === "F" || arrData[17] === "F"){
        if(arrData.length){
          newTradeForeign = vars.tradeForeign.concat(newRunningData)
          if(vars.tradeForeign.length >= nor.lvtrade){ newTradeForeign.shift(); }
        }
      }
      
      // update Watchlist
      let newTradeWatchlist = vars.tradeWatchlist;
      var groupName = groupSearch(vars.watchlistCode, vars.sidebarStatusGroup)
      var find = groupName.find(function(element) { return arrData[4] == element;});
      if(find !== undefined){
        newTradeWatchlist = vars.tradeWatchlist.concat(newRunningData)
        if(vars.tradeWatchlist.length >= nor.lvtrade ){ newTradeWatchlist.shift()}
      }

      // Update NonRG
      let newTradeNonRG = vars.tradeNonRg;
      if(arrData[8] == "NG" || arrData[8] == "TN"){
        if(arrData.length){
          newTradeNonRG = vars.tradeNonRg.concat(newRunningData)
          if(vars.tradeNonRg.length >= nor.lvtrade){ newTradeNonRG.shift();}
        }
      }
      // set container live trade
      let nlivetradeContainer = vars.tradeType === "all" ? newTradeAll : (vars.tradeType === "watchlist" ? newTradeWatchlist : 
        (vars.tradeType == "foreign" ? newTradeForeign : (vars.tradeType == "nonRG" ? newTradeNonRG : init.lvtrade)))
      return {
        ...vars,
        tradeAll: newTradeAll,
        tradeWatchlist: newTradeWatchlist,
        tradeForeign: newTradeForeign,
        tradeNonRg:newTradeNonRG,
        livetradeContainer: nlivetradeContainer,
      }
    }
    // Response subscribe Running trade small for stock trade Tick
    else if(arrData[1].includes('RTRADES')){
      let newData = GetLiveTrade(arrData)
      // Stock tick on Stock Page
      if(vars.subscriptionFlags['stockTradeSummaryPage']){
        if(arrData[4] == vars.codeSearchStockInfo){
          let newStockTickMain = vars.stockTickMain.reverse().concat(newData)
          if(vars.stockTickMain.length >= nor.stockTradeSummaryMain ){
            newStockTickMain.shift()
            newStockTickMain.reverse() 
          }
          return { ...vars, stockTickMain:newStockTickMain,}
        }
      }
      // Tick on Livetrade Page
      else if(vars.subscriptionFlags['livetradePage']){
        if(arrData[4] == vars.bidOfferCode){
          if(arrData.length){
            let newTradeTick = vars.tradeTick.concat(newData)
            if(vars.tradeTick.length >= nor.lvtrade){
              newTradeTick.shift();
            }
            return{
              ...vars,
              tradeTick:newTradeTick,
              livetradeContainer:newTradeTick,
            }
          }
        }
      }
    }
    // response Subscribe StockPrice for Stock Trade Summary
    else if(arrData[1] === 'STOCKPRICE'){     
      console.log(arrData) 
      // return {...vars, stockTradeSummaryMain: GetStockTradeSummary(arrData)}
    }
    // Response Subscribe Index for Sectoral Index
    else if(arrData[1] === 'INDEX'){
      let ndata = fa.GetIndexSummary('subscription', arrData);
      return { ...vars, indices: { ...vars.indices, [arrData[2]]: ndata } }
    }
    else if(arrData[1] === "STOCKIEPV"){
      if(vars.portfolioData.code === arrData[2]) {
        return { ...vars, 
          portfolioData: { ...vars.portfolioData, board_code: arrData[3], price: arrData[4], volume: arrData[5] }
        }
      }
    }
    else{
      console.log("NEW MESSAGE : ",ndata, arrData)
    }
  },
  updateQueryReplayAux: (vars, {sub_type, data})=>{
    if(sub_type === "BROKER_HISTORY"){
      let { brokerTradeHistory, brokerTradeHisInfo } = fa.GetBrokerTradeHistory(data);
      return { ...vars, brokerTradeHistory, brokerTradeHisInfo }
    }
  },
  //============= update Inquery replay =============
  updateInquiryReply : (vars, {sub_type, data})=>{    
    // Response Trade Status
    if(sub_type === "TRDSTATUS"){
        return {...vars, tradingStatus: fa.GetTradingStatus(data) }
    }
    // Response Stock List
    else if(sub_type === "STOCKLIST"){
      if(Object.keys(data).length > 0){
        const ordered = {};
        Object.keys(data).sort().forEach(function(key) {
          ordered[key] = data[key];
        });
        let allSahamNew = Object.keys(ordered);
        let sumPageFooterNew = Math.ceil(allSahamNew.length/3)
        vars.netActionAux.send(fmsg.ix_stockSummaryXS(allSahamNew.slice(0,3)));
        let nextBoxDataFooter = GetFooter(allSahamNew.slice(0,3))
        if(vars.relativelistOptions.length === 0){
          var newRelativeListOptions = GroupList('options|stock', ordered)
        }else{
          var newRelativeListOptions = vars.relativelistOptions.concat(GroupList('options|stock', ordered))
        }
        return{...vars, stockList:ordered, stocklistOptions:GroupList('options|stock', ordered), 
          allSaham:allSahamNew, sumPageFooter:sumPageFooterNew, boxDataFooter:nextBoxDataFooter,
          relativelistOptions:newRelativeListOptions,
        }
      }
    }
    else if(sub_type === "STOCKWATCHLIST"){
      if(Object.keys(data).length > 0){
        vars.specialNotation = fa.GetSpecialNotation(data, vars.specialNotation);
        vars.netActionAux.send(fmsg.ix_stockData(Object.keys(data)));
      }
    }
    // Response Index List
    else if(sub_type === 'INDEXLIST'){
      if(Object.keys(data).length > 0){
        if(vars.relativelistOptions.length === 0){
          var newRelativeListOptions = GroupList('options|index', data)
        }else{
          var newRelativeListOptions = vars.relativelistOptions.concat(GroupList('options|index', data))
        }  
        let tickerHeaderList = [...vars.sectoralIndexCode, "COMPOSITE"];
        vars.netActionAux.send(fmsg.sx_indexS(tickerHeaderList))
        return { ...vars, indexList:data, indexlistOptions:GroupList('options|index', data),
          relativelistOptions:newRelativeListOptions, boxDataTickerHeader:GetTickerHeader(tickerHeaderList),
          nonSectoralIndexCode: Object.keys(data).filter(d => vars.sectoralIndexCode.indexOf(d) === -1 && d != "COMPOSITE").sort(),
        }
      }
    }
    else if(sub_type === "COMMODITYLIST"){
      let commodityListArr = Object.keys(data).sort();
      let ncommodity = fg.GetCommodity(data);
      vars.netActionAux.send(fmsg.ix_comodity(commodityListArr));
      return {...vars, commodityListArr, commodity: ncommodity}
    }
    else if(sub_type.includes("COMMODITY|")){
      if(data.code != undefined){
        let nchange = (data.last - data.prev).toFixed(4);
        let perChange = (((data.last - data.prev)/data.prev)*100).toFixed(2); 
        // update commodity
        let ncommodity = vars.commodity.map(d => d.code === data.code ? {...d,
          last: data.last,
          change: nchange, 
          percentage: perChange,
          dateTime: ConvertToDateTime((data.date+ " "+data.time), "v2"),
        }:d);
        // update commodity Active
        let ncommodityActive = (vars.commodityActive.code === data.code) ? 
          { name: vars.commodityActive.name,
            code: vars.commodityActive.code,
            dateTime: ConvertToDateTime((data.date+ " "+data.time), "v2"),
            last: data.last,
            change: nchange, 
            percentage: perChange,
          } : vars.commodityActive;
        return {...vars, commodity: ncommodity, commodityActive: ncommodityActive}
      }
    }
    else if(sub_type === "FUTURELIST"){
      let indexFuturesListArr = Object.keys(data).sort();
      let intIndexFutures = fa.GetIntIndexFutures(data);
      vars.netActionAux.send(fmsg.ix_future(indexFuturesListArr));
      return {...vars, intIndexFutures, indexFuturesListArr}
    }
    else if(sub_type.includes("FUTURE|")){
      if(data.code != undefined){
        let nchange = (data.last - data.prev);
        let perChange = (((data.last - data.prev)/data.prev)*100).toFixed(2); 
        // update intIndexFutures
        let nintIndexFutures = vars.intIndexFutures.map(d => d.index === data.code ? {...d,
          month: timeModifier(data.date, "vd-1"), 
          last: data.last, 
          high: data.high, 
          low: data.low, 
          change: nchange, 
          changePercent: perChange, 
          time: timeModifier(data.time, "vt-1")
        }:d);
        return {...vars, intIndexFutures: nintIndexFutures}
      }
    }
    else if(sub_type.includes("TECHDAY|")){
      let techDayData = data.stock_code ? {...data, lastPrice: "", change: "", nRec: ""} : {};
      if(vars.subscriptionFlags['StockAnalyticPage']){
        vars.netActionAux.send(fmsg.ix_stockSummaryXS(vars.lastCodeChart));
      }else if(vars.subscriptionFlags['dailyTechnicalTable']){
        vars.netActionAux.send(fmsg.ix_stockSummaryXS(strCall.code(vars.bidOfferCode)));
      }
      return {...vars, techDayData}
    }
    // Response Forex list
    else if(sub_type === "FOREXLIST"){
      let nArr = Object.keys(data).sort();
      let nMax =Math.ceil(nArr.length/2);
      vars.boxDataFooter[3].symbol = nArr[0];
      vars.boxDataFooter[4].symbol = nArr[1];
      vars.netActionAux.send(fmsg.ix_forex([nArr[0], nArr[1]]));
      return {...vars, forexList: data, forexListArr: nArr, forexMaxPage: nMax}
    }
    // Response Global Id list
    else if(sub_type === "GLOBALIDXLIST"){
      let nArr = Object.keys(data).sort();
      let nMax =Math.ceil(nArr.length/2);
      vars.boxDataFooter[5].symbol = nArr[0];
      vars.boxDataFooter[6].symbol = nArr[1];
      vars.netActionAux.send(fmsg.ix_globalIDX([nArr[0], nArr[1]]));

      return {...vars, globalIdList: data, globalIdListArr: nArr, globalIdMaxPage: nMax}
    }
    else if(sub_type.includes("STOCKSMRYXS|")){      
      let change = Number(data.close_price) == 0 ? '0' : (Number(data.close_price)-Number(data.prev_price)).toFixed(0)
      let perChange = Number(data.close_price) == 0 ? '0' : ((change/Number(data.prev_price))*100).toFixed(2) 
      let nboxDataFooter = vars.boxDataFooter.map(d => d.symbol == data.stock_code ? 
        {...d, 
          last : Number(data.close_price) == 0 ? 0 : data.close_price, 
          change: change, 
          percentage:perChange} 
        : d);

      if(vars.subscriptionFlags['StockAnalyticPage'] && vars.lastCodeChart == data.stock_code && vars.techDayData.lastPrice === ""){
        vars.techDayData = {...vars.techDayData, lastPrice: data.close_price, change}
      }
      if(vars.subscriptionFlags['dailyTechnicalTable'] && strCall.code(vars.bidOfferCode) == data.stock_code){
        vars.techDayData = {...vars.techDayData, nRec: fg.GetRecommendationTechDay(vars.techDayData, data.close_price)};
      }
      let stockSmryXsListCode = vars.stockSmryXsListCode.map(d => d.stock_code == data.stock_code ? 
        {...d, close_price : Number(data.close_price) == 0 ? 0 : data.close_price, prev_price: data.prev_price } : d);
      let sidebarData = vars.sidebarData.map(d => d.name == data.stock_code ? {...d, last: data.close_price, change, percent: perChange } : d);

      return{...vars, boxDataFooter:nboxDataFooter,stockSmryXsListCode, sidebarData }

    }
    else if(fa.TOPGAL.some(d => d === sub_type)){
      return { ...vars, topGAL: fa.GetTopGAL(data, vars.topType, sub_type) }
    }else if(sub_type.includes("INDEXSTOCKVAL|")){
        return { ...vars, topGAL: fa.GetTopGAL(data, "card-202")}
    }
    // Response Market Statistic
    else if(sub_type === 'MARKETSTAT'){
      return { ...vars, marketStatisticData:GetMarketStat(data) }
    }    
    // inquiry index member for sectoral index
    else if(sub_type.includes("INDEX|")){
      let indexCode = sub_type.split("|")[1];
      if(data.index_code != undefined){
        let ndata = fa.GetIndexSummary('inquiry', data);
        if(vars.subscriptionFlags['nonSectoralStatistikPage'] && vars.nonsectorName === undefined){
          vars.nonsectorName = indexCode;
          vars.netActionAux.send(fmsg.ix_indexStockVal(vars.nonsectorName));
        }
        return { ...vars, nonIndices: { ...vars.nonIndices, [sub_type]: ndata }
        }
      }
    }
    // inquiry index member for request stock summary
    else if(sub_type=="INDEX.MEMBER|"+vars.sectorName || sub_type=="INDEX.MEMBER|"+vars.nonsectorName){
      if(data.length > 0){
        vars.netActionAux.send(fmsg.ix_stockSummary(data.slice(0,50)));
        return { ...vars, indexMemberCode:data.slice(0,50) }
      }
    }
    else if(sub_type.includes("FOREX|")){
      if(data.code != undefined){
        let nchange = (data.last - data.prev);
        let perChange = (((data.last - data.prev)/data.prev)*100).toFixed(2); 
        // update currencies
        let ncurrencies = vars.currencies.map(d => d.other === data.code ? {...d,
            dateTime: ConvertToDateTime((data.date+ " "+data.time), "v2"), 
            date: data.date, 
            last: data.last, 
            prevClose: data.prev, 
            change: nchange.toFixed(4), 
            percentage: perChange
        }:d);
        // update currencies Active
        let ncurrenciesActive = (vars.currenciesActive.other === data.code) ? 
          {
            other: vars.currenciesActive.code, 
            name: vars.currenciesActive.name,
            dateTime: ConvertToDateTime((data.date+ " "+data.time), "v2"), 
            date: data.date, 
            last: data.last, 
            prevClose: data.prev, 
            change: nchange.toFixed(4), 
            percentage: perChange
          } : vars.currenciesActive;
        // Update Footer
        let nboxDataFooter = vars.boxDataFooter.map(d => d.symbol == data.code ? 
          {...d, 
            last: data.last, 
            change: nchange.toFixed(2), 
            percentage: perChange} 
          : d);
        return {...vars, currencies: ncurrencies, currenciesActive: ncurrenciesActive, boxDataFooter: nboxDataFooter}
      }
    }
    else if(sub_type.includes("GLOBALIDX|")){
      if(data.code != undefined){
        let change = (data.last - data.prev).toFixed(2);
        let percentage = (((data.last - data.prev)/data.prev)*100).toFixed(2);
        // Update Footer
        let nboxDataFooter = vars.boxDataFooter.map(d => d.symbol == data.code ? 
            {...d, last: data.last, change, percentage} 
          : d);
        if(vars.subscriptionFlags['intIndicesMarketStatistikPage']){
          vars.intIndex = vars.intIndex.map(d => d.symbol === data.code ? {...d, ...data,
            lastUpdated: data.date+" "+data.time, change, percentage
          } : d);
        }

        return {...vars, boxDataFooter: nboxDataFooter}
      }
    }
    // inquiry stocksummary for sectoral index
    else if(sub_type.includes("STOCKSMRY|")){
      if(data.stock_code !== undefined){
        // stock Trade history panel in Stock Page
        if(vars.subscriptionFlags.stockHistoryPage == true){
          return {...vars, stockTradeHistorySMRY: data}
        }
      }
    }
    // response Inquiry Top broker fo TOP BROKER
    else if(sub_type == "BROKERLIST"){
      let nbroker = Object.keys(data).length > 0 ? GroupList('options|broker', data) : {};
      return { ...vars, brokerList: nbroker}
    }
    else if(sub_type.includes("BROKERINFO|")){
      let brokerInfoDetail = Object.keys(data).length > 0 ? fg.GetBrokerInfoDetail(data) : init.brokerInfoDetail;
      return {...vars, brokerInfoDetail}
    }
    else if(sub_type === "CUSTODYLIST"){
      let custodyList = Object.keys(data).length > 0 ? GroupList('options|custody', data) : [];
      return {...vars, custodyList}
    }
    else if(sub_type === "TOP_BROKER_VAL"){
      return { ...vars, topBrokerVal: fa.GetTopBroker(data) }
    }else if(sub_type === "TOP_BROKER_BUY"){
      return { ...vars, topBrokerBuy: fa.GetTopBroker(data) }
    }else if(sub_type === "TOP_BROKER_SELL"){
      return { ...vars, topBrokerSell: fa.GetTopBroker(data) }
    }
    // Response Chart
    else  if(sub_type.includes('PERSISTENT.CHART.STOCKSHORT|')){
    // else  if(sub_type.includes('PERSISTENT.CHART.STOCK|')){
      let code =  sub_type.split("|")[1];
      if(code == strCall.code(vars.bidOfferCode)){
        return{...vars, chartStockData:data}
      }
    }
    // Response Stock Data
    else if(sub_type.includes("STOCKDATA|")){
      let code =  sub_type.split("|")[1];
      let ndata = data // code.includes("-R") ? fg.GetStockDataR(data) : data;
      if(data.stock_code !== undefined){
        if(vars.subscriptionFlags['stockSpecialNotation']){
          let notation = ndata.special_notation.replaceAll("","-").substr(1,ndata.special_notation.length*2-1);
          let specialNotation = vars.specialNotation.map(d=>d.code == code ? {...d, 
            board: ndata.board_pos, notation , notationDes: ndata.special_notation_desc
          } : d)
          if(code == strCall.code(vars.bidOfferCode)){ 
            let status = ndata.trade_in_pre.includes("The stock can be traded")      
            vars.orbookStatus = fa.GetOrderbookStatus(vars.tradingStatus, status, ndata)
            return{ ...vars, iStockData: ndata, preOpeningStatus: status }
          }
          return{ ...vars, specialNotation}
        }
        if(vars.subscriptionFlags['tradePageManWatchlist']){
          if(vars.statusOfTradeModal){
            return{ ...vars, iStockData: ndata }
          }else{
            if(code == strCall.code(vars.codeForOB1)){
              return{ ...vars, iSDForOB1: ndata}
            } 
            if(code == strCall.code(vars.codeForOB2)){
              return{ ...vars, iSDForOB2: ndata}
            }
            if(code == strCall.code(vars.codeForOB3)){
              return{ ...vars, iSDForOB3: ndata}              
            }  
          }      
        }
        if(code == strCall.code(vars.bidOfferCode)){ 
          let status = ndata.trade_in_pre.includes("The stock can be traded")      
          vars.orbookStatus = fa.GetOrderbookStatus(vars.tradingStatus, status, ndata)
          return{ ...vars, iStockData: ndata, preOpeningStatus: status }
        }
      }
    }
    else if(sub_type.includes("STOCKDATASN|")){
      let code =  sub_type.split("|")[1];
      if(data.stock_code !== undefined){
        let notation = data.special_notation.replaceAll("","-").substr(1,data.special_notation.length*2-1);
        if(vars.subscriptionFlags['landingPageInvboard']){
          let newPortofolios = vars.portofolios;
          newPortofolios = vars.portofolios.map(d => d.code == code ? 
            {...d, notation, notationDes: data.special_notation_desc} : d)
          return {...vars, portofolios: newPortofolios}
        }else if(vars.subscriptionFlags['stockCashPageInvboard']){
          let newStockCash = vars.stockCash;
          newStockCash = vars.stockCash.map(d => d.codeR == code ? 
            {...d, notationR: notation, notationDes: data.special_notation_desc} : d);
          return {...vars, stockCash: newStockCash}
        }
      }
    }
    // Response Stock Price
    else if(sub_type.includes("STOCKPRICE|")){
      let nd = Object.keys(data).length > 0 ? GetIStockTradeSummary(data) : init.stockTradeSummary    
      return {...vars, stockTradeSummaryMain:nd}
    }
    // response Stock Broker Code
    else if(sub_type.includes("BROKERSTOCK")) {
      let brokerTradeSumInfo;
      let refData = vars.brokerTradeSum1.filter(d => d.stock !== "\xa0");
      let type = sub_type.includes("BROKERSTOCKSELL") ? "sell" : "buy";
      let brokerTradeSum1 = fg.GetBrokerTradeSummary1(type, refData, data); 
      if(brokerTradeSum1.filter(d=>d.stock !== "\xa0").length > 0){
        let tBuyVal = sumDataArray(brokerTradeSum1, 'object-buyVal'), tSellVal = sumDataArray(brokerTradeSum1, 'object-sellVal'), tVal = tBuyVal + tSellVal;
        brokerTradeSumInfo = {tBuyVal, tSellVal, tVal};
      }else{
        brokerTradeSumInfo = {};
      }
      return {...vars, brokerTradeSum1, brokerTradeSumInfo };
    }
    else if(sub_type.includes("STOCKBROKERBUY")){
      let nd = Object.keys(data).length > 0 ? GetStockSummaryBroker(data) : init.stockSummaryBuyer 
      return{...vars,stockSummaryBuyerMain:nd }
    }else if(sub_type.includes("STOCKBROKERSELL")){
      let nd = Object.keys(data).length > 0 ? GetStockSummaryBroker(data) : init.stockSummaryBuyer 
      return{...vars,stockSummarySellerMain:nd }
    }
    // response data forTrade Adv
    else if(sub_type.includes("ORDER.NG.")){ 
      let type = sub_type.split('.')[2]  
      if(type.includes("BID")){
        return {...vars, tradeAdvBid:GetTradeAdv(data) }
      }else{
        return {...vars, tradeAdvOffer:GetTradeAdv(data) }
      }      
    }
    else if(sub_type.includes('INDEXPERIODIC')){
      let ncode = sub_type.split("|")[1]
      if(vars.codeSearchMarketIndex == ncode){
        return {...vars, indexStreamChart:GetIndexStreamChart("inquiry",data)}
      }
    }
    else if(sub_type === "T1T2"){
      let nfundTransferT0T1 =  {
        t1: timeModifier(data.T1, "v0"), 
        t2: timeModifier(data.T2, "v0"),
        c1: vars.fundTransferT0T1.c1, 
        c2: vars.fundTransferT0T1.c2
      }
      return {...vars, fundTransferT0T1: nfundTransferT0T1 }
    }
    else if(sub_type.includes("STOCKIEPV|")){
      if(vars.portfolioData.code === sub_type.split("|")[1]){
        return { ...vars, portfolioData: { ...vars.portfolioData, ...data} }
      }
    }
    else{
      console.log("NEW MESSAGE : ", sub_type, data, JSON.stringify(data))
    }
    
  },
  //============= update Database replay =============
  updateDBReplay:(vars, {sub_type,data})=>{
    if(sub_type == "MIGRATION"){
      vars.userProfile.code_status = data[0].status
      let nmigrationS = {tab:vars.migrationState.tab, status:"success", selected:vars.migrationState.selected, 
        selectedAct:vars.migrationState.selectedAct}
      return {...vars,migrationState:nmigrationS}
    }
    // response Watchlist
    else if(sub_type === "WATCHLIST"){
      let ndata = data.stocks === undefined ? data :  [data]
      // update data amend saat modal modify wathclist aktif
      if(vars.amendModalStatus){
        vars.statusAlertN=true;
        vars.msgAlert3='Amend / Add Group berhasil di save'
      }     
      if(ndata.length){
        // sort a Object array
        Array.prototype.sortBy = function(p) {
          return this.slice(0).sort(function(a,b) {
            return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
          });
        }
        
        // pagination for Sidebar
        let newData = ndata.sortBy('group_name');
        let newStatusGroup = newData[0].group_name
        let thisCodeSideBar = newData[0].stocks.sort();
        let sumPage = Math.ceil(thisCodeSideBar.length/vars.maxPpage)
        let page0=showPage(vars.thisPage, vars.maxPpage, thisCodeSideBar)          
        let newSideBar = [...page0,...init.sidebarCode.slice(page0.length,vars.maxPpage)]
        let reqCodeStockSmrxs = page0.filter(d=>vars.stockSmryXsListCode.map(l=>l.stock_code).indexOf(d) == -1)
        if(reqCodeStockSmrxs.length > 0){
          reqCodeStockSmrxs.map(d=>vars.stockSmryXsListCode.push({stock_code:d, prev_price:0, close_price: 0}))            
          vars.netActionAux.send(fmsg.sx_stockSummaryXS(reqCodeStockSmrxs)) 
        }

        // pagination for Watchlist
        let myWatchlistCodeNew = GroupList('myWatchlistCode', ndata)          
        if(vars.subscriptionFlags["stockWatchlistPage"]){
          let thisGroupCodeSidebar = groupSearch(newData, newStatusGroup).sort()
          vars.sumPageWatchlist = Math.ceil(thisGroupCodeSidebar.length/vars.maxPageWatchlist)
          vars.pageWatchlistCode =showPage(vars.thisPageWatchlist, vars.maxPageWatchlist, thisGroupCodeSidebar) 
          vars.netActionAux.send(fmsg.sx_stockSummary(vars.pageWatchlistCode));
        }
        
        return{
          ...vars,
          watchlistCode:newData,            
          sidebarStatusGroup:newStatusGroup,
          sumPage:sumPage,
          myWatchlistCode:myWatchlistCodeNew,
          sidebarCode: newSideBar,
          sidebarData: GetSidebar(newSideBar, vars.stockSmryXsListCode),
        }
      }else{
        if(vars.subscriptionFlags["stockWatchlistPage"] && vars.bidOfferCode !== ""){
          vars.bidOfferCode = ""
          vars.iStockData = {};
          vars.sStockSmry = {}
          vars.bidData = [];
          vars.offerData = [];
          vars.biOfferPrice = '0';
          vars.bidOfferVal = '0';
          vars.bidOfferVol = '0';
          vars.portfolioData = fd.portfolio;
          vars.bidOfferMkt = '0RG';
          vars.bidOfferExpire = '0';
          vars.order_type = "7";
          vars.prevStatus = true;
          vars.haircutCode = 0;
          // vars.orbookStatus = false;
          vars.offerAlldStatus = false;
        }
        return {...vars, 
          watchlistCode:[],
          sidebarStatusGroup: '',
          sidebarCode: init.sidebarCode,
          sidebarData: init.sidebarData,
        }
      }
      
    }
    // Response Stock history trade price, buy, sell, all, foreign, daily
    // data = dummy[sub_type].data
    else if(sub_type === "H_TRADE_PRICE"){     
      let ndata = data.price == undefined ? data : [data] 
      return { ...vars,
        stockTradeHistoryPrice: fa.GetStockTradeHistory('topPrice',ndata)
      }
    }else if(sub_type === "H_TRADE_BUY"){
      let refdata = data.buyer_code == undefined ? data : [data]
      let ndata = fa.GetStockTradeHistory('topBuyerSeller', refdata)
      let nbChart = fa.GetStockTradeHistory('topBuyerChart', ndata.slice(0,10))        
      if(vars.tabStockTradeHistoryChart === 1){
        vars.stockTradeHistoryChart = nbChart
      }   
      return {...vars,stockTradeHistoryBuy : ndata, stockTradeHistoryChartBuy:nbChart}
    }else if(sub_type === "H_TRADE_SELL"){
      let refdata = data.seller_code == undefined ? data : [data]
      let ndata = fa.GetStockTradeHistory('topBuyerSeller', refdata)
      let nsChart = fa.GetStockTradeHistory("topSellerChart", ndata.slice(0,10)) 
      if(vars.tabStockTradeHistoryChart === 2){
        vars.stockTradeHistoryChart = nsChart
      }     
      return {...vars, stockTradeHistorySell : ndata, stockTradeHistoryChartSell:nsChart}
    }else if(sub_type === 'H_TRADE_ALL'){
      let refdata = data.broker == undefined ? data : [data]
      let ndata = fa.GetStockTradeHistory('brokerSummary', refdata)
      let nChart = fa.GetStockTradeHistory('topNetChart',ndata.slice(0,10)) 
      if(vars.tabStockTradeHistoryChart == 3){
        vars.stockTradeHistoryChart = nChart
      }
      return {...vars, stockTradeHistoryMain: ndata, stockTradeHistoryChartNet:nChart }
    }else if(sub_type === "H_TRADE_DAILY"){
      let ndata = data.date == undefined ? data : [data]
      return { ...vars, 
        stockTradeHistoryMain: fa.GetStockTradeHistory('daily', ndata)
      }
    }else if(sub_type === "H_TRADE_FOREIGN"){
      let ndata = data.date == undefined ? data : [data]
      return {...vars,
        stockTradeHistoryMain: fa.GetStockTradeHistory("foreignNet", ndata)
      }
    }else if(sub_type === "USER_PREFERENCE"){
      let ndata = data.length > 0 ? {...data[0], 
        timeout: (Number(data[0].timeout) < 300000 && Number(data[0].timeout) !== 0) ? 300000 : data[0].timeout,
        action_type: data.length > 0 ? "UPDATE-DB" : "INSERT-DB",
      } : vars.userPreference;
      let thememode = ndata.theme == "dark" ? true : false;
      let activateTimeout = {logout: 1200000, session: ndata.timeout}
      vars.scaleState = ndata.interface_scale.split("%")[0]
      vars.pinUsage = ndata.pin_usage;
      if(vars.pinUsage === "always"){
        vars.verifyPinStatus = false;
      }
      if(vars.settingModalStatus){
        vars.statusAlertN = true;
        vars.msgAlert3 = 'setting changes successfully ';
        vars.settingModalStatus = false;
      }
      Number(ndata.timeout) === 0 ? $("#bDeActivate").click() : $("#bActivate").click();
      return{...vars, thememode, activateTimeout, userPreference:ndata}
    }
    else{
      console.log("NEW MESSAGE : ", sub_type, data, JSON.stringify(data))
    }
  }, 

  //************************** UPDATE MESSAGE REPLAY SOCKET 1 : 12000 **************************
  //============= update XMPP Login =============  
  updateSessionXMPP:(vars,{data})=>{
    var newSessionXMPP = data["session-id"]
    return {
      ...vars,sessionXMPP:newSessionXMPP, 
    }
  },
  setReplyMsg: (vars, {action_type, sub_type, status, data, reason='', remark, ops_id, ...p}) => {
    if(action_type === 'LOGIN-RESPONSE'){
      if(status === 'OK'){
        vars.netActionAux.send(fmsg.x_login(vars.userName, p.session_id));
        p.chg_pwd ? (p.user_status === "M" && $("#triggerMigrate").click()) : $("#change-passpin-verification").click();
        vars.frameAction.setMainFrameActive(true);
        return {...vars, sessionID: p.session_id, userProfile: GetUserStatus(p.user_status), 
          agreementAutomaticStatus: p.ao_agreement, loginState: true, firstLogin: !(p.chg_pwd)
        }
      } else {
        let loginErrReason = dxInfo[status] || reason + ". Please try again later.";           
        return {...vars, loginErrState: true, loginErrReason}
      }
    } else if(action_type === "LOGOUT-RESPONSE" && status === "FORCED"){
      return {...vars, statusAlertN: true, msgAlert3: dxInfo[status]}
    }
    else if(action_type === 'ACCOUNT-REPLY'){
        window.flash(reason, strCall.statusMsg(status)); 
        if(strCall.statusMsg(status) === 'success'){
            if(sub_type === "CHANGE-PASSWORD" && vars.firstLogin){
                vars.tabPassPin = 2
            } else vars.frameAction.closeModal(100);
        } else return { ...vars, changePassErrState: true, changePassErrReason: reason }
    }
    else if(action_type === "FUND-REPLY"){
      let nstatus = sub_type === "TRANSFER" || sub_type === "CANCEL" ? sub_type+" Success" : sub_type+":"+reason
      let ntype = sub_type === "TRANSFER" || sub_type === "CANCEL" ? 'success' : 'error';
      window.flash(nstatus, ntype);
      vars.netAction.send(fmsg.q_cashBalance(vars.regulerId));
      vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
      vars.netAction.send(fmsg.q_settlement(vars.regulerId));
      vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
      if(sub_type === "CANCEL"){
        let { start, to } = vars.rangeFundTransferList;
        vars.netAction.send(fmsg.q_fundTransferlist(vars.regulerId, start, to));
      }
      return{...vars,}
    }
    else if(action_type === "ORDER"){
      !vars.checkPinErrState && $("#pin-click-verification").click();
      return { ...vars, verifyPinStatus: false, opsPinId: ops_id } 
    }
    else if(action_type === "PIN-REPLY"){
      status = sub_type === "XCHECK" ? CheckRespPin(vars.sessionID, status) : status;
      let msg = status == "OK" ? "Check Pin Success" : sub_type + " " + status;
      window.flash(msg, strCall.statusMsg(status));
      if(strCall.statusMsg(status) === 'success'){
        if(sub_type === "CHECK" || sub_type === "XCHECK"){
          if(!vars.verifyPinStatus){
              vars.verifyPinStatus = true
              vars.frameAction.closeModal(100);
              $("#pin-click-verify").click();
          }

          if(vars.pinUsage === "always"){
            vars.verifyPinStatus = false;
          }
          if(vars.subscriptionFlags['landingPageInvboard']){
            vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
          }else if(vars.subscriptionFlags['stockCashPageInvboard']){
            vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
            vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_settlement(vars.regulerId));
          }else if(vars.subscriptionFlags['inquryAccountPageInvboard']){
            vars.netAction.send(fmsg.q_userProfile(vars.regulerId));
          }else if(vars.subscriptionFlags['tradePageManOrderbook']){
            let clientId = vars.GeneralType ? vars.regulerId : vars.marginId  
            vars.netAction.send(fmsg.q_orderList(clientId));            
            vars.netAction.send(fmsg.q_trades(vars.regulerId))
          }else if(vars.subscriptionFlags['tradePageOrderBookList']){
            vars.netAction.send(fmsg.q_orderBookingList(vars.regulerId));
          }else if(vars.subscriptionFlags['AutOrderSetting'] && vars.codeSearchAutomatic != ""){
            vars.bidOfferCode = vars.codeSearchAutomatic;
            let codeType = vars.transactionType === "TN" ? vars.bidOfferCode + ".TN" : vars.bidOfferCode;
            // get haircut 
            vars.haircutCode = fa.getHaircut(vars.haircutData, vars.bidOfferCode);
            let refStock = vars.stockSmryXsListCode.find(d=>d.stock_code === vars.bidOfferCode);
            vars.portfolioData = fg.UpdatePortfolioData("create", vars.stockProfile.find(d=>d.stockcode === vars.bidOfferCode), 
              {code: vars.bidOfferCode, ...refStock});

            vars.netActionAux.send(fmsg.sx_stockSummary(vars.bidOfferCode));
            vars.netActionAux.send(fmsg.ix_stockData(vars.bidOfferCode));
            vars.netActionAux.send(fmsg.sx_bid(codeType));
            vars.netActionAux.send(fmsg.sx_offer( codeType));
            vars.netAction.send(fmsg.ao_listRule(vars.regulerId));
          }else if(vars.subscriptionFlags['AutSentOrder'] && vars.rangetradeSendOrder.start != "" && vars.rangetradeSendOrder.to != ""){
            let { start, to } = vars.rangetradeSendOrder;
            vars.netAction.send(fmsg.ao_executedRule(vars.regulerId, start, to));
          }else if(vars.subscriptionFlags['EsbnTransSubscribe'] && vars.sbnInfo.tab === 2){
              vars.netAction.send(fmsg.sbn_order(vars.sbnInfo.params));
          }else if(vars.subscriptionFlags['EsbnxPagePortfolio'] && vars.sbnInfo.tab === 2){
              vars.netAction.send(fmsg.sbn_orderEarlyRedemption(vars.sbnInfo.params));
          }
          return {...vars, opsPinId:''}
        }else{
          if(vars.firstLogin){
            // request data porfolio,  orderlist & tradelist   
            vars.netAction.send(fmsg.q_stockHaircut)
            // vars.netAction.send(fmsg.q_orderList(vars.regulerId)); 
            // vars.netAction.send(fmsg.q_trades(vars.regulerId));
            vars.netAction.send(fmsg.q_cashBalance(vars.regulerId));
            // vars.netAction.send(fmsg.q_userProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_settlement(vars.regulerId));
            vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
            vars.firstLogin = false;
            vars.userProfile.code_status === "M" && $("#triggerMigrate").click();
          }
          vars.frameAction.closeModal(100);
        }
      }else{
        let nmsg = reason || remark;
        if(sub_type === "CHANGE-PIN"){
          return { ...vars, changePassErrState: true, changePassErrReason: nmsg}
        }else{
          vars.tempOrder.symbol !== undefined && vars.netAction.send(fmsg.newOrder(vars.tempOrder));
          if(vars.subscriptionFlags['esbnPage']){
            let cmsg = nmsg.split(': ')[1];
            nmsg = cmsg ? <>Pin yang Anda Masukan Salah.<br/>({cmsg.replace('of', 'dari')})</> : <>PIN Anda telah dikunci.<br/> {dxInfo.infoLayanan}</>;
          }
          return { ...vars, checkPinErrState: true, checkPinErrReason: nmsg, onLoading: false, statusAlertN: true, msgAlert3: nmsg }
        }

      }
    }
    else if(action_type === "AUTOMATIC-ORDER-REPLY"){
      let ntype = status === "NOK" || sub_type === "ORDER-ERROR" ? "error" : "success";
      let msg = status === "NOK" || sub_type === "ORDER-ERROR" ? remark || reason : `${sub_type} Success`;
      window.flash(msg, ntype);
      if(sub_type === "AGREEMENT"){
        !vars.verifyPinStatus && $("#pin-click").click();
        return { ...vars, agreementAutomaticStatus: true }
      }else{
        vars.netAction.send(fmsg.ao_listRule(vars.regulerId));
      }
    }
    else if(action_type === "STOCK-REPLY"){
      let jSub_type = ["TRANSFER", "CANCEL-TRANSFER", "CANCEL-RECEIVE", "RECEIVE"];
      let nstatus = jSub_type.includes(sub_type) ? sub_type+" Success" : status+":"+reason;
      let ntype = jSub_type.includes(sub_type) ? 'success' : 'error';
      window.flash(nstatus, ntype);
      if(sub_type === "CANCEL-TRANSFER"){
        let { start, to } = vars.rangeFundTransferSTL;
        vars.netAction.send(fmsg.q_stockTransfer(vars.regulerId, start, to));
      }
    }
    else if(action_type === "RIGHT-ISSUE-REPLY"){
      let message = status === "OK" ? sub_type+" Success" : sub_type+":"+reason;
      let type = status === "OK" ? 'success' : 'error';
      if(sub_type === "CANCEL" && status === "OK"){
        let params = { ...vars.exerciseRange, "client_id": vars.regulerId};
        vars.netAction.send(fmsg.q_exerciseRightRequest(params))
      }else if(sub_type === "EXERCISE"){
        vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
        vars.netAction.send(fmsg.q_exerciseRightList(vars.regulerId));
      }
      window.flash(message, type);
    }
    else if(action_type === "APERD"){
      if(sub_type === "APERD_CUST_STATUS"){
        if(status === "OK"){
          vars.registerMutualFund = false;
          vars.frameAction.switchPage("MutualFundxPageEtalase", "/mutualfundPage");
          vars.subscriptionFlags["mutualfundPage"] = false;
          vars.subscriptionFlags["MutualFundxPageEtalase"] = true;
        } else if(remark.RC === "101") {
          window.flash(`Registrasi ${remark.RCM}`, 'OK');
          return { ...vars, stepRegisterAperd: 4 }
        } else {
          vars.netAction.send(fmsg.aperd_arialProvince);
        }
        vars.isLoadingAperd = false;
      } else if(sub_type === "APERD_ARIAL_PROVINCE"){
        return { ...vars, ARIAL_PROVINCE: data.map(d => ({...d, value: d.province_code, text: d.name})) }
      } else if(sub_type === "APERD_CITY_CODE_BY_PROVINCE"){
        return { ...vars, CITY_CODE_BY_PROVINCE: data.map(d => ({...d, value: d.city_code, text: d.name})) }
      } else if(sub_type === "APERD_RISK_PROFILE_ASSESSMENT"){
        return { ...vars, RISK_PROFILE_ASSESSMENT: status === "OK" ? data : [] }
      } else if(sub_type === "APERD_RISK_PROFILE_RESULT"){
        return { ...vars, RISK_PROFILE_RESULT: status === "OK" ? data : {} }
      } else if(sub_type === "APERD_PERSONAL_REGIST_KYC"){
          window.flash(status === "OK" ? `Register ${data.RCM}` : `Register Fail : ${remark.RCM}`, status);
          if(status === "OK"){
              return { ...vars, stepRegisterAperd: 4 };
          }
      } else if(sub_type === "APERD_GET_FUND_TYPE"){
        return { ...vars, listFundType: fa.getFundType(data)}
      } else if(sub_type === "GET_IM" && status === "OK"){
        return { ...vars, listIMAperd: fa.getListIM(data) }
      } else if(sub_type === "APERD_GET_LIST_MUTUAL_FUND"){
        if(data){
          let ndata = data.result.map(d => ({ ...d, 
              value: d.FundCode, 
              portfolio: vars.PORTFOLIO_MUTUAL_FUND.find(x => d.FundCode === x.FundCode),
              d1: "", d3: ""
          }));
          let aperdOption = data.result.map(d => ({ 
              value: d.FundCode, 
              code: d.FundCode, 
              saham: d.FundName,
              type: d.FundType,
              id: 'chrtCompare',
              desc: 'reksanadana' 
          }));
          return { ...vars, aperdOption, LIST_MUTUAL_FUND: ndata }
        }
      } else if(sub_type === "APERD_FUND_BY_IM_CODE"){
        return { ...vars, LIST_FUND_BY_IM_CODE: status === "OK" ? data : [] }
      } else if(sub_type === "APERD_FUND_DETAIL"){
        return { ...vars, FUND_DETAIL: data }
      } else if(sub_type === 'APERD_NAV_BY_PERIODE'){
          return { ...vars, NAV_BY_PERIODE: fa.setNavChartAperd(status === 'OK' ? data.result : []) }
      } else if(sub_type === 'APERD_COMPARE_PRODUCT'){
          return { ...vars, COMPARE_PRODUCT: fa.setCompareChartAperd(data.Fund) }
      } else if(sub_type === "APERD_COMPANY_PROFILE_BY_IM_CODE"){
        return { ...vars, COMPANY_PROFILE: data }
      } else if(sub_type === "APERD_GENERAL_INFO_BY_IM_CODE"){
        return { ...vars, GENERAL_INFO: data }
      } else if(sub_type === "APERD_FUND_BY_FUND_CODE" && status === "OK"){
          let { fee_redm, Nav, fee_switch, fee_subs, FundCode, currency, IMCode } = data.result[0];
          let PORTFOLIO_MUTUAL_FUND = vars.PORTFOLIO_MUTUAL_FUND.map(d => 
              d.FundCode === FundCode ? ({ ...d, fee_redm, Nav, fee_switch, fee_subs, currency, IMCode }) : d
          );
          vars.netAction.send(fmsg.aperd_getMutualFundByIMCode(IMCode))
          return { ...vars, PORTFOLIO_MUTUAL_FUND }
      } else if(sub_type === "APERD_CUSTODIAN_BANK" && status === "OK"){
          let userInfo = {...vars.userInfo, subs_cb: data.find(d => d.bank_code === vars.userInfo.rdnbncode)};
          return { ...vars, userInfo }
      } else if(sub_type === "APERD_CB_BY_RDN_N_FUND" && status === "OK"){
          let cbByRdnNFund = vars.cbByRdnNFund || [];
          let ndata = data[0] || {};
          let tempFund = vars.tempFundList.find(d => d.FundCode === ndata.FundCode);
          if(tempFund){
            vars.tempFundCode = ndata.FundCode;
            vars.netAction.send(fmsg.aperd_getBankChargeData(vars.userInfo.rdnbncode, ndata.bank_code, tempFund.QtyAmount, tempFund.Fee_nominal));
            vars.isDataLoading = true;
          }
          cbByRdnNFund.push(data[0]);
          return { ...vars, cbByRdnNFund }
      } else if(sub_type === "APERD_CONVERT_CURRENCY" && status === "OK"){
          let aperdConvertCurrency = vars.aperdConvertCurrency;
          let fIndex = aperdConvertCurrency.findIndex(d => d.currency_from === vars.tempConvertCurrency.currency_from);
          if(fIndex < 0){
              aperdConvertCurrency.push({ ...vars.tempConvertCurrency, currency_value: data.currency_value }) 
          } else {
              aperdConvertCurrency[fIndex].currency_value = data.currency_value;
          }
          return { ...vars, aperdConvertCurrency, tempConvertCurrency: {} }
      } else if(sub_type === "APERD_GET_BANK_CHARGE_DATA"){
        if(status === "OK"){
          let fidx = vars.tempFundList.findIndex(d => d.FundCode === vars.tempFundCode);
          if(fidx > -1){
            vars.tempFundList[fidx].transaction_type = data.transaction_type
            vars.tempFundList[fidx].bank_charge = Number(data.bank_charge)
          }
        }
        vars.isDataLoading = false;
      } else if(sub_type === "APERD_SUBSCRIPTION"){
          window.flash(status === "OK" ? `SUBSCRIPTION ${data.RCM}` : `SUBSCRIPTION FAIL : ${remark.RCM}`, status);
          if(status === "OK"){
            vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_settlement(vars.regulerId));     
            vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
          }
      } else if(sub_type === "APERD_REDEMPTION"){
          window.flash(status === "OK" ? `REDEMPTION ${data.RCM}` : `REDEMPTION FAIL : ${remark.RCM}`, status);
          if(status === "OK"){
            vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
            vars.netAction.send(fmsg.q_settlement(vars.regulerId));     
            vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
          }
      } else if (sub_type === "APERD_SWITCHING"){
          window.flash(status === "OK" ? `SWITCHING ${data.RCM}` : `SWITCHING FAIL : ${remark.RCM}`, status);

      } else if(sub_type === "APERD_GET_PORTFOLIO"){
        return { ...vars, PORTFOLIO_MUTUAL_FUND: status === "NOK" ? [] : data.result.Fund }
      } else if(sub_type === "APERD_TRANSACTION_HISTORY"){
        // data = dummy.APERD_TRANSACTION_HISTORY.data
        // let subTotalAperd = data.filter(d => d.status === "0").reduce((a,b) => a + Number(b.Amount), 0);

        return { ...vars, HISTORY_MUTUAL_FUND: fa.addRowEmpty("card-472", data), /*subTotalAperd*/ }
      }
    } else if(action_type === "NOTIFICATION"){
      return { ...vars, pushNotif: {status: true, ...data} }
    } else if (action_type === "PUSH_NOTIF"){
      if(sub_type === "NOTIF_AFTER_LOGIN" && status === "OK"){
        return { ...vars, listNotif: {status: true, data: data.list_notif_after_login} }
      }
    }
  },
  updateDBReplayS1:(vars, {sub_type, ndata})=>{
    // Response User profile
    if(sub_type === "USER_PROFILE") {
      // ndata = dummy.USER_PROFILE.data
      // ndata = [{ ...ndata[0], custstatus: "A" }]
        if(ndata.length > 0){
            let {approvelimit, margincall, concentration_flag, concentration_margin, custfee, custname, custcode, cbaccno, sid, user_id } = ndata[0];
            vars.nsettlement.custfee = Number(custfee);
            let stockCashInfo = {...vars.stockCashInfo, user_name: custname, bank_account: cbaccno};
            vars.constBL = {...vars.constBL, approvelimit, margincall, concentration_flag, concentration_margin};
            vars.netAction.send(fmsg.push_notif_after_login(custcode))
            return {...vars, userInfo: ndata[0], SID: sid, ai: GetAccountInfo(ndata[0]), userProfile: fa.GetUserProfile(ndata[0]), stockCashInfo,
              userPreference: { ...vars.userPreference, user_id }
            }
        }
    }
    else if(sub_type === "CASH_PROFILE"){
      if(ndata.length >0){ 
        let nscSettlement = fa.GetSCSettlement("get", ndata[0], vars.nsettlement)
        let nmyBalance = Number(nscSettlement[7].t2);
        vars.constBL.Ct2 = nscSettlement[7].t2;
        // update available t0/t1 fund transfer
        vars.fundTransferT0T1.c1 = nscSettlement[7].t0;
        vars.fundTransferT0T1.c2 = nscSettlement[7].t1;
        // update StockCash Info
        vars.constBL.balance = nmyBalance;
        if(vars.subscriptionFlags["stockCashPageInvboard"]){
          let clRatio = (-1* nmyBalance/vars.stockCashInfo.liquidVal).toFixed(2) < 0 ||  vars.stockCashInfo.liquidVal == 0 ? 0 : (-1*nmyBalance/ vars.stockCashInfo.liquidVal).toFixed(2);
          vars.stockCashInfo = {...vars.stockCashInfo, balance: nmyBalance, clRatio, bl: fa.GetBuyLimit(vars.constBL, sub_type)}
        }
        vars.portofolioInfo.balance = Number(nscSettlement[7].t2);
        return { ...vars, scSettlement: nscSettlement, myBalance: nmyBalance, }
      }
    }
    else if(sub_type === "STOCK_PROFILE"){
      let data = ndata
      if(data.length > 0 ){ 
        // cek kode yang beda dengan sidebar
        let codePortofolio = data.map(d => d.stockcode)
        vars.netAction.send(fmsg.q_stockHaircutByCode(codePortofolio))
        let diffCode = codePortofolio.filter(d=>vars.sidebarCode.indexOf(d) == -1);
        diffCode.length > 0 && diffCode.map(d=>vars.stockSmryXsListCode.push({stock_code:d, prev_price:0, close_price: 0}))            
        // console.log("data", data, "diffCode", diffCode, vars.stockSmryXsListCode, "sidebarCode", vars.sidebarCode, "codePortofolio", codePortofolio)
        // portfolio Page
        let newPortofolios = vars.portofolios;
        if(vars.openModalBuySell ||(vars.subscriptionFlags['landingPageInvboard'] && (vars.stateLanding == "k" || vars.stateLanding == "0"))){
          newPortofolios = GetPortfolio2(vars.portofolios, data, vars.stockSmryXsListCode)
          let reqType;
          if(vars.portofolioInfo.lastReq === ""){
            vars.portofolioInfo.lastReq = vars.portofolioInfo.stream ?  "subscribe" : "inquiry";
            vars.netActionAux.send(vars.portofolioInfo.stream ? fmsg.sx_stockSummaryXS(diffCode) : fmsg.ix_stockSummaryXS(diffCode));
          }else if(vars.portofolioInfo.lastReq === "inquiry"){
            vars.netActionAux.send(fmsg.ix_stockSummaryXS(diffCode));
          }else if( vars.portofolioInfo.stream && vars.portofolioInfo.lastReq === "unSubscribe"){
            vars.netActionAux.send(fmsg.sx_stockSummaryXS(diffCode));
          }
          // req STOCKDATANS
          vars.netActionAux.send(fmsg.ix_stockDataSN(data.map(d=>d.stockcode)));
          // update portofolioInfo
          if(vars.subscriptionFlags['landingPageInvboard'] && (vars.stateLanding == "k" || vars.stateLanding == "0")){
            let mktVal = sumDataArray(newPortofolios, 'object-stockval');
            let stockValR = sumDataArray(newPortofolios, 'object-stockValR');
            vars.portofolioInfo.mktVal = mktVal;
            vars.portofolioInfo.pl = sumDataArray(newPortofolios, 'objectplus1-pl').toFixed(2);
            vars.portofolioInfo.plRatio = mktVal == 0 ? 0 : (((mktVal - stockValR)/mktVal)*100).toFixed(2);
          }
        }
        // update sidebarData
        let nsidebarData = vars.sidebarData;
        data.forEach((item) => {
          let i = nsidebarData.findIndex(d => d.name === item.stockcode && d.own != item.averageprice), own, ownPercent;
          if(i >= 0){
            let nlastprice = nsidebarData[i].last == "\xa0" ? 0 : nsidebarData[i].last;
            own = Number(item.averageprice).toFixed(0);
            ownPercent = own == 0 ? 0: (((Number(nlastprice) - Number(own)) / Number(own)) * 100).toFixed(2);
            nsidebarData[i].popUp = "You have '" + item.stockcode + "' @" + ConvertToRupiah(own) + "(" +ownPercent + "%)";
            nsidebarData[i].own = own;
            nsidebarData[i].ownPercent = ownPercent;
          }
        });
        // Update stock cash
        let newStockCash = vars.stockCash;
        if(vars.subscriptionFlags['stockCashPageInvboard']){
          newStockCash = GetStockCash(vars.stockCash, data, vars.stockSmryXsListCode, vars.haircutData)
          // update StockCash Info
          let mktVal = sumDataArray(newStockCash, 'object-mktvalueR').toFixed(2);
          let liquidVal = sumDataArray(newStockCash, 'object-lqValR').toFixed(2);
          let clRatio = (-1*  vars.stockCashInfo.balance/ liquidVal).toFixed(2) < 0 ||  liquidVal == 0 ? 0 : (-1*  vars.stockCashInfo.balance/ liquidVal).toFixed(2);
          let pl = sumDataArray(newStockCash, 'object-plR').toFixed(2);
          let sumStockVal = sumDataArray(newStockCash, 'object-stockValR').toFixed(2);
          let plRatio = mktVal == 0 ? 0 : (((mktVal - sumStockVal)/mktVal)*100).toFixed(2);
          // update constanta BL
          vars.constBL.Lqval = liquidVal;
          vars.stockCashInfo = {...vars.stockCashInfo, pl, plRatio, clRatio, liquidVal, mktVal, bl: fa.GetBuyLimit(vars.constBL, sub_type)}
          vars.netActionAux.send(fmsg.ix_stockDataSN(data.map(d=>d.stockcode)));
          // subscribe/inquiry stocksmryxs
          let reqType;
          if(vars.stockCashInfo.lastReq === ""){
            vars.stockCashInfo.lastReq = vars.stockCashInfo.stream ?  "subscribe" : "inquiry";
            vars.netActionAux.send(vars.stockCashInfo.stream ? fmsg.sx_stockSummaryXS(diffCode) : fmsg.ix_stockSummaryXS(diffCode));
          }else if(vars.stockCashInfo.lastReq === "inquiry"){
            vars.netActionAux.send(fmsg.ix_stockSummaryXS(diffCode));
          }else if(vars.stockCashInfo.stream && vars.stockCashInfo.lastReq === "unSubscribe"){
            vars.netActionAux.send(fmsg.sx_stockSummaryXS(diffCode));
          }
        }
        if(vars.subscriptionFlags['WarrantRightPageInvboard'] || (vars.subscriptionFlags['fundTransferPageInvboard'] && (vars.tabFundTransfer === 3 || vars.tabFundTransfer === 4))){
          vars.fundStockCodeList = vars.stocklistOptions.filter((item) => codePortofolio.includes(item.code));
        }
        // update portfolioData
        if(vars.portfolioData.code != "\xa0" && (vars.openModalBuySell || vars.subscriptionFlags['stockWatchlistPage'] || 
          vars.subscriptionFlags['stockInfoPage'] || vars.subscriptionFlags['stockFinancialStatement'] || 
          vars.subscriptionFlags['livetradePage'] || vars.subscriptionFlags['AutOrderSetting'])){
            let portfolioCheck = data.find(d=>d.stockcode === vars.portfolioData.code);
            vars.portfolioData = portfolioCheck ? fg.UpdatePortfolioData("stock", vars.portfolioData, portfolioCheck) : vars.portfolioData;
        }
        let nstockProfile = fa.GetStockProfile(data, vars.haircutData, vars.stockSmryXsListCode, sub_type);
        vars.portofolioInfo.mktVal = mathCall.sumArray(nstockProfile, "mktVal")
        return{...vars, stockProfile: nstockProfile, sidebarData: nsidebarData, codePortofolio, portofolios: newPortofolios, stockCash: newStockCash}
      }
    }
    else if(sub_type === "BALANCE_LIMIT"){
      let stockCashInfo = ndata.length > 0 ? {...vars.stockCashInfo, additionalBl: ndata[0].balance_limit } : vars.stockCashInfo;
      return {...vars, stockCashInfo}
    }
    else if(sub_type === 'CORPORATE_ACTION_LIST'){
      return { ...vars, CORPORATE_ACTION_LIST: fa.addRowEmpty('CORPORATE_ACTION_LIST', ndata) }
    } else if(sub_type === 'CORPORATE_ACTION_DETAIL'){
      return { ...vars, CORPORATE_ACTION_DETAIL: ndata}
    }
    else{
      console.log('cek', sub_type)
      let isArray = Array.isArray(ndata);
      let newData = isArray ? fa.addRowEmpty(sub_type, ndata) : ndata;
      return { ...vars, [sub_type]: newData }
    }
  }, 
  //============= update Query replay =============  
  updateQueryReplay:(vars,{sub_type, ndata})=>{
    let data = ndata.result === undefined ? ndata : ndata.result
    // console.log(sub_type,data, JSON.stringify(data))
    // Response Portfolio Client
    if(sub_type === "PORTFOLIO-CLIENTS"){
      if(data.length > 0){
        if(vars.marginId === ''){
          // if(vars.marginId === '' && data[0]['type'] === "CT_MARGIN"){
          vars.marginId=data[0]["client-id"]
        }
        if(vars.regulerId === '' ){
          // if(vars.regulerId === '' && data[1]['type'] === "CT_REGULAR"){
          vars.regulerId=data[0]["client-id"]
        }
        if(vars.custumerCodeMargin === ''){
          vars.custumerCodeMargin=data[0].name
        }
        if(vars.custumerCodeReguler === ''){
          vars.custumerCodeReguler=data[0].name
        }
        //req auto
        if(vars.firstReqAutoOrder){
          vars.netAction.send(fmsg.ao_listRule(data[0]["client-id"]));
        }
        // request data porfolio,  orderlist & tradelist   
        if(!vars.firstLogin){
          vars.netAction.send(fmsg.q_orderList(vars.regulerId));
          vars.netAction.send(fmsg.q_userProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
          vars.netAction.send(fmsg.q_settlement(vars.regulerId));
          vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));
        }
        return {...vars, regulerId:data[0]["client-id"], marginId:data[0]["client-id"]}
      }
    }
    // Response  "ACCOUNT-INFO"
    if(sub_type ===  "ACCOUNT-INFO"){
      // console.log('ini account info', data)
    }
    // Response OrderList
    else if(sub_type === "ORDERLIST"){
      // data = ORDERLIST.data.result
      if(data.length > 0){
        let ndata = data.filter(d=>d.board !== "NG")
        let newOrder = GetOrderList(ndata, "get", vars.haircutData);
        let OBdata = newOrder.filter(d=>d.cmd == "Buy");
        let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2);
        let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2);
        vars.constBL = {...vars.constBL, obhc, OB};
        vars.netAction.send(fmsg.q_stockHaircutByCode(data.map(d => d.stock_code)))
        if(vars.subscriptionFlags["stockCashPageInvboard"]){
          vars.stockCashInfo.bl = fa.GetBuyLimit(vars.constBL, sub_type);
        }
        return {...vars, orderList:newOrder, orderlistSelected:[],}
      }
    }
    // Response tradeList Client
    else if(sub_type === "TRADELIST_CLIENT"){
      if(data.length > 0){ 
        let newTradeList = GetTradeList("tClient",data)
        return {...vars, tradeList:newTradeList,}
      }
    } 
    // Response Orderbook List
    else if(sub_type === "ORDER-BOOKING"){
        return {...vars, tradeOrderBookList:GetOrderBookList(data, "get")}
    }
    else{
      console.log("NEW MESSAGE : ", sub_type, data, JSON.stringify(data))
    }
  },
  QUERY_REPLY_HANDLER:(vars, {sub_type, data, status, data_req, data_last, remark}) => {
    // data = dummy[sub_type].data || [];
    // status = dummy[sub_type].status;
    // data_req = dummy[sub_type].data_req;
    // data_last = dummy[sub_type].data_last;
    // remark = dummy[sub_type].remark; 
    /** HISTORICAL - INVESTMENT BOARD */
    if(sub_type === "ORDERS_HISTORY"){
        return {...vars, orderHistory: GetOrdersHistory(data.result, vars.userName)}
    } else if(sub_type === "ORDERS_DETAIL"){
        let orderDetailCode = GetOrderDetail(data.result)
        return {...vars, orderDetailCode}
    } else if(sub_type === "ORDERS_HIST_DETAIL"){
        let norderDetailCode = GetOrderDetail(data.result)
        return {...vars, orderDetailCode: norderDetailCode}
    } else if(sub_type === "TRADES_HISTORY"){
        return {...vars, tradeListHistory: GetTradeHistory(data.result)}
    } else if(sub_type === "TRANSACTIONS_HISTORY_CASH"){
        return {...vars, cashTransactionHistory: GetCashTransactionHistory(data.result)}
    } else if(sub_type === "TRANSACTIONS_HISTORY_STOCK"){
        return {...vars, stockTransactionHistory: GetStockTransactionHistory(data.result)}
    }
    /** STOCK & CASH - INVESTMENT BOARD */
    else if(sub_type === "CASH_BALANCE"){
        if(data.length > 0){
            return {...vars }
        }
    } else if(sub_type === "SETTLEMENT"){   
        let ndsettlement = { ...data, "custfee": vars.nsettlement.custfee }
        let nscSettlement = fa.GetSCSettlement("update", vars.scSettlement, ndsettlement);
        let nmyBalance = nscSettlement[7].t2;
        vars.constBL.Ct2 = nscSettlement[7].t2;
        // update StockCash Info
        vars.constBL.balance = nmyBalance;
        // Update stockCashInfo
        if(vars.subscriptionFlags["stockCashPageInvboard"]){
            let clRatio = (-1* nmyBalance/vars.stockCashInfo.liquidVal).toFixed(2) < 0 ||  vars.stockCashInfo.liquidVal == 0 ? 0 : (-1*nmyBalance/ vars.stockCashInfo.liquidVal).toFixed(2);
            vars.stockCashInfo = {...vars.stockCashInfo, balance: nmyBalance, clRatio, bl: fa.GetBuyLimit(vars.constBL)}
        }

        // update available t0/t1 fund transfer
        vars.fundTransferT0T1.c1 = nscSettlement[7].t0;
        vars.fundTransferT0T1.c2 = nscSettlement[7].t1;
        return {...vars, nsettlement: ndsettlement, scSettlement: nscSettlement, myBalance: nmyBalance}
    }
    /** FUND TRANSFER - INVESTMENT BOARD */
    else if(sub_type === "STOCK_TRANSFER"){
        let fundStockList = fg.GetStockTransferList(data.result);
        return { ...vars, fundStockList }
    }else if(sub_type === "FUND_TRANSFER"){
        return {...vars, fundTransferList: GetFundTransferList(data.result)}
    }
    /** EXERCISE - INVESTMENT BOARD */
    else if(sub_type === "EXERCISE-RIGHT"){
      let cekMin = Math.min(vars.scSettlement[7].t0, vars.scSettlement[7].t1, vars.myBalance);
      let exerciseCode = [];
      data.map(d => {
        let refdata = vars.stockProfile.find((v) => v.stockcode === d.asset_code);
        refdata && exerciseCode.push({ ...d, 
          ratio: d.ratio_stock/d.ratio_right,
          saham: `${d.catype} ${vars.stockList[d.stock_code]}`,
          sellableqty: refdata ? refdata.sellableqty : 0,
          a_cash: cekMin,
        })
      });
      return {...vars, exerciseCode};
    }
    else if(sub_type === "REQUEST-EXERCISE-RIGHT"){
      return { ...vars, exerciseList: fa.SetExerciseList(data) }
    }
    /** TRADE PL - INVESTMENT BOARD */
    else if(sub_type === "PL"){
        if(Object.keys(data).length > 0){
            let {tradePl, tradePlInfo} = fa.GetTradePL(data);
            return {...vars, tradePlInfo, tradePl}
        } else {
          return { ...vars, statusAlertN: true, msgAlert3: "Data not found" }
        }
    }
    /** FINANCIAL_REPORT - STOCK */
    else if(sub_type === "FINANCIAL_REPORT"){
        if(data_req.length == 0 && data_last.length == 0){
            return {...vars, stockFinancialReport: init.stockFinancialReport }
        }else{
            let countData = data_req.length <= 4 ? 0 : data_req.length-4
            let dataReq = data_req.slice(countData).sort((a, b) => a.year - b.year)
            let dataLast = data_last.sort((a, b) => a.quarter - b.quarter).slice(data_last.length-1);
            return {...vars, stockFinancialReport: fa.GetStockFinancialReport(dataReq, dataLast, vars.stockFinancial.valYear)}
        }
    }
    /** AUTOMATIC ORDER - TRADE */
    else if(sub_type === "AUTO_ORDER"){
        let cekData = data.result.filter(d=>d.status === "Working").length;
        if(vars.firstReqAutoOrder && cekData > 0){
            $("#triggerAlert").click();
            vars.lastSubHeaderManual = 'tradePageManOrderbook';
            vars.isManual = false;
        }
        return {...vars, roleWorking: cekData, firstReqAutoOrder: false, orderSetttingList: GetAutomaticOrder(data.result)}
    } else if(sub_type === "AUTO_ORDER_EXPIRED"){
        let autExpOrderList  = data.length > 0 ? fg.GetAutExpOrderList(data) : init.autExpOrderList;
        return {...vars, autExpOrderList}
    } else if(sub_type === "AUTO_ORDER_EXECUTED"){
        return {...vars, tradeSendOrder: fa.GetSentOrderList(data.result) }
    }
    
    /** ESBN */
    else if(sub_type === "SBN_ACCOUNT"){
      // status = dummy.SBN_ACCOUNT.status; data = dummy.SBN_ACCOUNT.data;
      if(status === "OK"){
        vars.checkStatusEsbn = 3;
        vars.subscriptionFlags["EsbnPage"] = false;
        vars.subscriptionFlags["EsbnxPageTransaction"] = true;
        vars.subscriptionFlags["EsbnTransSubscribe"] = true;
        vars.frameAction.switchPage("EsbnxPageTransaction", "/esbnPage");
        if(data.RekeningDana.length === 0){
            let params = {
                "bank_id": mathCall.setHundreds(vars.userInfo.idrdnbncode),
                "sid": vars.SID,
                "account_no": vars.userInfo.rdncbaccno,
                "name": vars.userInfo.custname
            }
            vars.netAction.send(fmsg.sbn_createFundAcc(params));
        }
        return { ...vars, isLoadingStatusESBN: false, onLoading: false, sbnInfo: {tab: 1, security_acc: data.RekeningSB[0] || {}, fund_acc: data.RekeningDana[0]} }
      }else{
        return { ...vars, isLoadingStatusESBN: false, onLoading: false, checkStatusEsbn: remark.rc === "102" ? 1 : 2}
      }
    }else if(sub_type === "SBN_REQ_REGISTRATION"){
        window.flash(sub_type + " " + (remark || "OK"), status);
        let msgAlert3 = status === "NOK" ? sub_type + " " + remark.message :
            <><b>Permintaan pendaftaran Anda sedang dalam proses</b>. <br/>Mohon tunggu dalam waktu 1x24 jam, dan cek secara berkala ke akun ESBN Anda. Untuk informasi lebih lanjut, silakan hubungi Pusat Dukungan kami 14009.</>;
        return { ...vars, statusAlertN: true, msgAlert3, onLoading: false, checkStatusEsbn: status === "NOK" ? 1 : 2}
    }else if(sub_type === "SBN_SHOW_SECURITIES_ACC" && status === "OK"){
        return { ...vars, sbnInfo: {security_acc: data[0]} }
    }else if(sub_type === "SBN_SHOW_FUND_ACC" && status === "OK"){
        return { ...vars, sbnInfo: {...vars.sbnInfo, fund_acc: data[0]} }
    }else if(sub_type === "SBN_REDEEM_OFFER" && status === "OK"){
      if(vars.subscriptionFlags["EsbnTransSubscribe"]){
        let mredeem = new Date(data.TglMulaiRedeem.split("Z")[0]);
        let aredeem = new Date(data.TglAkhirRedeem.split("Z")[0]);
        let today = new Date(moment().format("YYYY-MM-DDTHH:mm:ss"));
        let sPencairanAwal = today - mredeem < 0 ? "Periode Pencairan belum tiba" : 
          (mredeem >= today <= aredeem ? "cairkan" : "Tidak dapat dicairkan");
        let ndata = vars.SBN_LIST.map(d => d.Seri === data.Seri ? {...d, earlyReedem: data, sPencairanAwal} : d);
        return { ...vars, SBN_LIST: ndata };
      }else if(vars.subscriptionFlags["EsbnxPagePortfolio"]){
        let ndata = vars.SBN_SHOW_PORTFOLIO.map(d => d.seri === data.Seri ? {...d, earlyReedem: data} : d);
        return { ...vars, SBN_SHOW_PORTFOLIO: ndata };
      }
    }
    else if(sub_type === "SBN_LIST"){
        // data = dummy.SBN_LIST.data;
        let SBN_CODE = data.map(d => ({value: d.Seri, key: d.Seri, idSeri: d.Id, text: `${d.Seri} - ${d.TglBayarKupon}`, coupondate: d.TglBayarKupon}) );
        SBN_CODE = [{value: "all", text: "ALL", coupondate: ""}, ...SBN_CODE]
        let nSBN_ID = data.map(d => ({value: d.Seri, key: d.Seri, text: d.Id + " - " + d.Seri}) );
        let SBN_LIST = vars.subscriptionFlags["EsbnTransSubscribe"] ? data.map(d => ({...d, earlyReedem: undefined}) ) : [];
        if(vars.subscriptionFlags["EsbnTransSubscribe"] && data.length > 0){
          vars.netAction.send(fmsg.sbn_redeemOfferBySeriId(data[0].Id))
        }
        return {...vars, SBN_LIST, SBN_CODE, SBN_ID: [{key: "", value: "all", text: "Semua"}, ...nSBN_ID]}
    }else if(sub_type === "SBN_SHOW_KUOTA_SERI_BY_ID"){
      // data = dummy.SBN_SHOW_KUOTA_SERI_BY_ID.data;
      let { KuotaSeri=0, KuotaInvestor=0 } = data || {}
      return { ...vars, sbnInfo: {...vars.sbnInfo, KuotaSeri, KuotaInvestor} }
    }else if(sub_type === "SBN_CONSOLIDATE_PORTFOLIO"){
      let { list_portofolio } = data;
      let ndata = status === "OK" ? 
        (vars.filterPortfoioSBN == "all" ? list_portofolio : list_portofolio.filter(d => d.seri == vars.filterPortfoioSBN))
          .map(d => ({...d, nominal: d.remaining_amount || d.total_nominal})) 
      : [];
      let SBN_CONSOLIDATE_PORTFOLIO = fa.addRowEmpty("card-472", ndata);
      return {...vars, SBN_CONSOLIDATE_PORTFOLIO }
    }else if(sub_type === "SBN_SHOW_PORTFOLIO"){
      let SBN_SHOW_PORTFOLIO = fa.getPortfolioSBN(data);
      return {...vars, SBN_SHOW_PORTFOLIO}
    }else if(sub_type === "SBN_GET_ALL_ORDER_SERI_BY_SID"){
      let SBN_HISTORY = fa.getSbnHistory(data, [], vars.filterHisOrderSBN);
      if(data.length === 0){ vars.statusAlertN = true; vars.msgAlert3 = "Data not found"; }
      return { ...vars, SBN_HISTORY}
    }else if(sub_type === "SBN_ORDER"){
        window.flash(sub_type + " " + (remark || ""), status === 'OK' ? 'success' : 'error');
        let nsbnInfo = status === 'OK' ? {...vars.sbnInfo, 
            tab: 3,
            status: true,
            billCode: data.KodeBilling,
            deadline: data.BatasWaktuBayar,
        } : vars.sbnInfo;
        return { ...vars, isDataLoading: false, onLoading: false, sbnInfo: nsbnInfo, statusAlertN: status === 'NOK' ? true : false, msgAlert3: (remark || "") }
    }else if(sub_type === "SBN_ORDER_EARLY_REDEMPTION"){
        window.flash(sub_type + " " + (remark || ""), status === 'OK' ? 'success' : 'error');
        let sbnInfo = status === 'OK' ? {...vars.sbnInfo, 
          tab: 3,
          status: true,
          redeemCode: data.KodeRedeem,
        } : vars.sbnInfo;
        status === "OK" && vars.netAction.send(fmsg.sbn_showPortfolio(vars.SID));
        return { ...vars, onLoading: false, sbnInfo, statusAlertN: status === 'NOK' ? true : false, msgAlert3: (remark || "")}
    }else if(sub_type === "SBN_GET_EARLY_REDEMPTION_BY_ORDER_CODE"){
        return { ...vars, earlyRedemptiondByOrderCode: status === "OK" ? data : [] }
    }else if(sub_type === "SBN_HISTORY_ORDER_BY_ORDER_CODE" && status === "OK"){
        let SBN_HISTORY_ORDER = data.riwayat_pemesanan.map(d=> ({...d, KodeBilling: data.result.KodeBilling || ""}) )
        return { ...vars, SBN_HISTORY_ORDER}
    }
    else if(sub_type === "STOCK_HAIRCUT" && data.length > 0){
        let ids = new Set(vars.haircutData.map(d => d.stock_code));
        let haircutData = [...vars.haircutData, ...data.filter(d => !ids.has(d.stock_code))]
        if(vars.bidOfferCode === data[0].stock_code){
            vars.haircutCode = fa.getHaircut(haircutData, vars.bidOfferCode)
            vars.constBL.hc = vars.haircutCode;
            vars.constBL.divider = 1 - 1/2*(1-Number(ShareToLot(vars.haircutCode)));
        }
        let stockProfile = fa.GetStockProfile(vars.stockProfile, haircutData, vars.stockSmryXsListCode, sub_type);
        return{...vars, stockProfile, haircutData}
    } else if(sub_type === "TRADES"){
        return {...vars, tradeListData: GetTradeList(data.result) }
    }else{
        return {...vars, [sub_type]: data}
        // console.log("NEW MESSAGE : ", sub_type, data, JSON.stringify(data))
    }
  },

  //============= update Order replay =============  
  updateOrderReplay: (vars,{sub_type, ndata}) => { 
    let data = ndata.data === undefined ? ndata : ndata.data
    // Response Order Update
    if(sub_type === "ORDER-UPDATE"){
      let newOrdeL = vars.orderList    
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status, data.remark);
      window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));
      window.flash(nsr.nstatus, 'success');

      if(sub_type === "ORDER-REJECT" && data.method && data.method === "Automatic") {
        vars.netAction.send(fmsg.ao_listRule(vars.regulerId));
      }

      // status = Sending To Server
      if(nsr.nstatus.includes("Sending")){
        if(data.client_ord_id == undefined){
          newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
            {...d, status: d.status == '' ? nsr.nstatus : d.status, userId: data.client_id} : d)
        }else{
          // new order from customer
          if(data.client_ord_id.toString().includes("88")){
            newOrdeL = vars.orderList.map(d => d.order == data.client_ord_id ? 
              {...d,  serverOrderId : data.server_ord_id, status: nsr.nstatus, userId: data.client_id } : d)
          }
          // new order from mobile or Destop
          else{
            // update OrderList
            let nindex = vars.orderList.map(l=>l.order).indexOf('')
            let newD = GetOrderList(data, "fromOther", vars.haircutData);
            if(nindex == -1){
              newOrdeL = vars.orderList.concat()
            }else{
              newOrdeL = [...vars.orderList.slice(0, nindex), newD, ...vars.orderList.slice(nindex + 1),];
            }
          }          
        }
        return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, 
          tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum, orderlistInformation:fd.orderlistInformation,}
      }
      else{
        // request trades, orderlist, cash, settlement, stock_profile, balance_limit
        if(vars.subscriptionFlags["tradePageManOrderbook"]){
          vars.netAction.send(fmsg.q_trades(vars.regulerId))
        }
        vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
        vars.netAction.send(fmsg.q_settlement(vars.regulerId));
        vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));     
        vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));
        // new order from customer
        let cekOrderList = vars.orderList.map(l=>l.serverOrderId).indexOf(data.server_ord_id)
        let nmshare = data.order_matched == 0 ? 0 : LotToShare(data.order_matched)
        let nleaveVlot = Number(data.order_vol) - Number(data.order_matched)
        if(cekOrderList != -1){
          newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
            {...d, 
              marketorder: data.market_ord_id,
              mshares: nmshare,
              mlot: Number(data.order_matched),
              status: nsr.nstatus,
              remark: nsr.nremark,
              leaveVlot: nleaveVlot,
              amountLeaveVol: LotToShare(nleaveVlot)*Number(data.order_price),
              obhc: (1-Number(d.hc))*LotToShare(nleaveVlot)*Number(data.order_price),
              avgmatchprice: data.avg_price.toFixed(0),
              time: timeModifier(data.time, "ol"),
              amount: LotToShare(Number(data.order_vol))*Number(d.price),
              amountMatchVol: (Number(data.avg_price)*nmshare).toFixed(0),
              type: fa.getExpireValue(data.time_force),
              method : data.method,
            } : d)
          }else{
            // update OrderList
            let nindex = vars.orderList.map(l=>l.order).indexOf('')
            let newD = GetOrderList(data, "fromOther", vars.haircutData);
            if(nindex == -1){
              newOrdeL = vars.orderList.concat(newD)
            }else{
              newOrdeL = [...vars.orderList.slice(0, nindex), newD, ...vars.orderList.slice(nindex + 1),];
            }
        }

        // Update BuyLimit
        let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
        let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2)
        let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2);
        vars.constBL = {...vars.constBL, OB, obhc};
        
        return {...vars, orderList: newOrdeL, tradeOrderSum: init.tradeOrderSum, tradeListMain: init.tradeList, tradeTradeSum: init.tradeTradeSum,
          orderlistInformation: fd.orderlistInformation }
      } 
    }
    // Response Order reject
    if(sub_type === "ORDER-REJECT"){ 
      // notification
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark);
      window.flash = (message, type="error") => Bus.emit('flash', ({message, type}));
      window.flash(nsr.nstatus, 'error'); 

      // Update Order List
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ?  
        {...d, status:nsr.nstatus, remark:nsr.nremark, leaveVlot:0, sReject:"Rejected",
          type: fa.getExpireValue(data.time_force), time:timeModifier(data.time, "ol"),} : d)
      
      // Update BuyLimit
      let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
      let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2);
      let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2);
      vars.constBL = {...vars.constBL, OB, obhc};

      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,
        orderlistInformation:fd.orderlistInformation,}
    }
    if(sub_type === 'NEW-ORDER-ERROR'){
        window.flash(data.status + ' - ' + data.reason, 'error'); 
        vars.subscriptionFlags['tradePageManOrderbook'] && vars.netAction.send(fmsg.q_orderList(vars.regulerId));
    }
    // Response Order Error
    if(sub_type === "ORDER-ERROR"){
      // notification
      window.flash = (message, type="error") => Bus.emit('flash', ({message, type}));
      window.flash("Rejected By Server", 'error'); 

      // update Order List
      let newOrdeL = vars.orderList
      if(data.client_ord_id != undefined){
        newOrdeL = vars.orderList.map(d => d.order == data.client_ord_id ? 
          {...d, status: "Rejected By Server", remark:data.reason, leaveVlot:0 } : d)
      }
      if(data.server_ord_id != undefined){
        newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
          {...d, status: "Rejected By Server", remark:data.reason, leaveVlot:0 } : d)
      }
      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, 
        tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,
        orderlistInformation:fd.orderlistInformation,}
    }
    // Response Amended
    if(sub_type === "AMEND-ORDER"){
      // notification
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark);
      window.flash(nsr.nstatus, 'success');

      // request trades, orderlist, cash, settlement, stock_profile, balance_limit
      if(vars.subscriptionFlags["tradePageManOrderbook"]){
        vars.netAction.send(fmsg.q_trades(vars.regulerId));
      }
      vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
      vars.netAction.send(fmsg.q_settlement(vars.regulerId));
      vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));     
      vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));

      let nmshare = Number(data.order_matched) == 0 ? 0 : LotToShare(data.order_matched)
      let vshares = Number(data.order_vol) == 0 ? '0' : LotToShare(data.order_vol)
      let nleaveVlot = Number(data.order_vol) - Number(data.order_matched)
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, 
          marketorder:data.market_ord_id,
          price:Number(data.order_price),
          vlot:Number(data.order_vol),
          vshares:vshares,
          mshares:nmshare,
          mlot:Number(data.order_matched),
          status: nsr.nstatus,
          remark: nsr.nremark,
          leaveVlot: nleaveVlot,
          amountLeaveVol: LotToShare(nleaveVlot)*Number(data.order_price),
          obhc :(1-Number(d.hc))*LotToShare(nleaveVlot)*Number(data.order_price),
          avgmatchprice:data.avg_price.toFixed(0),
          time:timeModifier(data.time, "ol"),
          amountMatchVol : (Number(data.avg_price)*nmshare).toFixed(0)
        } : d)

      // Update BuyLimit
      let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
      let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2)
      let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2)
      vars.constBL = {...vars.constBL, OB, obhc};
      
      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,
        orderlistInformation:fd.orderlistInformation,}
    }
    // Response Amend Reject
    if(sub_type === "AMEND-REJECT"){
      // notification
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark);
      window.flash(nsr.nstatus, 'error'); 
      // update Order List
      let lastData = vars.amend
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, remark:nsr.nremark, leaveVlot:0, sReject:"Rejected", vlot:lastData.vlot,
          vshares:lastData.vshares, price:lastData.price, } : d)

      // Update BuyLimit
      let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
      let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2)
      let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2)
      vars.constBL = {...vars.constBL, OB, obhc};

      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum, 
        orderlistInformation:fd.orderlistInformation,}
    }
    // Response Amend Error
    if(sub_type === "AMEND-ERROR"){
      // notification
      window.flash = (message, type="error") => Bus.emit('flash', ({message, type}));
      window.flash("Rejected By Server", 'error'); 
      // update Order List
      let lastData = vars.amend
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, status: d.status == "" ? "Rejected By Server" : d.status, vlot:lastData.vlot,
          vshares:lastData.vshares, price:lastData.price, remark:data.reason, leaveVlot:0,  } : d)
      
      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, 
        tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum, orderlistInformation:fd.orderlistInformation,}
    }
    // Response Cancel 
    if(sub_type === "CANCEL-ORDER"){
      // notification
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark);
      window.flash(nsr.nstatus, 'success');

      // request trades, orderlist, cash, settlement, stock_profile, balance_limit
      if(vars.subscriptionFlags["tradePageManOrderbook"]){
        vars.netAction.send(fmsg.q_trades(vars.regulerId));
      }
      vars.netAction.send(fmsg.q_cashProfile(vars.regulerId));
      vars.netAction.send(fmsg.q_settlement(vars.regulerId));
      vars.netAction.send(fmsg.q_stockProfile(vars.regulerId));     
      vars.netAction.send(fmsg.q_balanceLimit(vars.regulerId));  

      let nleaveVlot = 0
      let nmshare = Number(data.order_matched) == 0 ? '0' : LotToShare(data.order_matched)
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, 
          marketorder:data.market_ord_id,
          mshares:nmshare,
          mlot:Number(data.order_matched),
          status: nsr.nstatus,
          remark: nsr.nremark,
          leaveVlot :  nleaveVlot,
          amountLeaveVol: LotToShare(nleaveVlot)*Number(data.order_price),
          obhc :(1-Number(d.hc))*LotToShare(nleaveVlot)*Number(data.order_price),
          avgmatchprice:data.avg_price.toFixed(0),
          time:timeModifier(data.time, "ol"),
          amountMatchVol : (Number(data.avg_price)*nmshare).toFixed(0)
        } : d)
      
      // Update BuyLimit
      let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
      let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2)
      let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2)
      vars.constBL = {...vars.constBL, OB, obhc};
      
      return {...vars, orderList:newOrdeL,tradeOrderSum:init.tradeOrderSum, tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,
        orderlistInformation:fd.orderlistInformation,}
    } 
    // Response Amend Reject
    if(sub_type === "CANCEL-REJECT"){
      // notification
      let nsr =  GetStatusOrder(data.fill_status, data.chain_status, data.request_status , data.remark);
      window.flash(nsr.nstatus, 'error'); 
      // update Order List
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, remark:nsr.nremark, leaveVlot:0, sReject:"Rejected",} : d)

      // Update BuyLimit
      let OBdata = newOrdeL.filter(d=>d.cmd == "Buy")
      let OB = (sumDataArray(OBdata,'object-amountLeaveVol')).toFixed(2)
      let obhc = (sumDataArray(OBdata,'object-obhc')).toFixed(2);
      vars.constBL = {...vars.constBL, OB, obhc};

      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,
        orderlistInformation:fd.orderlistInformation,}
    }   
    // Response Cancel Reject (kalo ada)
    if(sub_type === "CANCEL-ERROR"){
      // notification
      window.flash = (message, type="error") => Bus.emit('flash', ({message, type}));
      window.flash("Rejected By Server", 'error'); 
      // update Order List
      let newOrdeL = vars.orderList.map(d => d.serverOrderId == data.server_ord_id ? 
        {...d, status: d.status == "" ? "Rejected By Server" : d.status, remark:data.reason, leaveVlot:0 } : d)
      return {...vars, orderList:newOrdeL, tradeOrderSum:init.tradeOrderSum, 
        tradeListMain:init.tradeList, tradeTradeSum:init.tradeTradeSum,orderlistInformation:fd.orderlistInformation,}
    }
    // Response Order Booking
    if(sub_type === "ORDER-BOOKING"){
      let type = data.status == "FAIL" ? "error" : "success";
      let msg = `ORDER-BOOKING ${data.status} ${(data.status === "FAIL") ? data.reason + (vars.userProfile.status === 'ACTIVE' ? '' : dxInfo.callCenter) : ""}`;
      window.flash(msg, type); 
      vars.netAction.send(fmsg.q_orderBookingList(vars.regulerId));
    }
    if(sub_type === "CANCEL-BOOKING"){
      window.flash("CANCEL-BOOKING "+data.status, 'success'); 
      vars.netAction.send(fmsg.q_orderBookingList(vars.regulerId));
    }
  },
}

const BIPSAppContext = React.createContext({});

class BIPSAppProvider extends React.PureComponent {

  constructor (props) {
    super(props);
    this.appProvider = null; // will be set when ContextProvider is rendered
    this.netAction = null;
    this.netActionAux = null;
    this.frameAction = null;
  }

  messageHandler = (msg, socketID) => {
    // if(socketID =='aux'){
    // console.log('BIPSAppProvider.messageHandler(). socketID',socketID,' Message = ', msg);    
    //>>>>>>>>>>>>>>>>>>>>>>>>>> Socket 1 : 1200 <<<<<<<<<<<<<<<<<<<<<<<<<<<
    if (socketID === undefined) { // message from main socket
      // console.log('BIPSAppProvider.messageHandler(). socketID',socketID,' Message = ', msg);
        let msgData;
      try {  
        // Message Response from Server
        msgData = JSON.parse(msg);
      }
      catch {
        console.log('Invalid JSON: ', msg);
        return;
      }
      if(msgData.sub_type === "XMPP"){
        console.log('ini xmpp ')
        this.appProvider.sendAction('updateSessionXMPP',{data:msgData.data})
      }
      //============= call action RECONNECT SUCCESS / FAILED =============  
      else if(msgData.action_type === "RECONNECT-RESPONSE"){
        console.log('ini reconnect 12000')
      }      
      //============= call action updateQueryReplay =============  
      else if(msgData.action_type === 'QUERY-REPLY'){
        // console.log(msgData, msg) 
        this.appProvider.sendAction('updateQueryReplay', {sub_type:msgData.sub_type, ndata: msgData.data});
      }  
      else if(msgData.action_type === 'QUERY_REPLY'){
        this.appProvider.sendAction('QUERY_REPLY_HANDLER', {...msgData} );
      }
      //============= call action updateOrderReplay =============  
      else if(msgData.action_type === 'ORDER-REPLY'){
        // console.log(msgData, msg) 
        this.appProvider.sendAction('updateOrderReplay', {sub_type:msgData.sub_type, ndata: msgData});
      }
      //============= call action updateDBReplay =============  
      else if(msgData.action_type === 'REPLY-DB'){
        // console.log(msg, msgData)
        this.appProvider.sendAction('updateDBReplayS1', {sub_type:msgData.sub_type, ndata: msgData.data});
      }
      //============= call action setReplyMsg =============  
      else{
        this.appProvider.sendAction('setReplyMsg', {...msgData});
      }

    }
    //>>>>>>>>>>>>>>>>>>>>>>>>>> Socket 2 : 5050 <<<<<<<<<<<<<<<<<<<<<<<<<<<
    else{
      // let msgData;
      // console.log('BIPSAppProvider.messageHandler(). socketID',socketID,' Message = ', msg);          
      try {
        if(msg.includes("action_type")){
          // Message Response from Server
          let msgData = JSON.parse(msg);
          //============= call action LOGIN =============  
          if (msgData.action_type === 'DF-RESPONSE') {
            if (msgData.status == 'OK') {                  
              this.appProvider.sendAction('loginSuccessfulAux');              
              // this.frameAction.setMainFrameActive(true)         
            }else{
              this.appProvider.sendAction('loginFailAux',{reason: msgData.reason});
            }
          }
          //============= call action RECONNECT SUCCESS / FAILED =============
          else if(msgData.action_type === "RECONNECT-RESPONSE"){
            console.log('ini reconnect 5050', msg)
          }
          //============= call action updateSubscriberReplay =============    
          if (msgData.action_type === 'UPDATE') {
            this.appProvider.sendAction('updateSubscriberReplay', {sub_type:msgData.sub_type, code: msgData.code, data: msgData.data});
          }
          //============= call action updateInquiryReply =============  
          else if(msgData.action_type === 'INQUIRY-REPLY'){
            // console.log(msgData)
            this.appProvider.sendAction('updateInquiryReply', {sub_type:msgData.sub_type, data: msgData.data});
          }
          else if(msgData.action_type === "QUERY_REPLY"){
            this.appProvider.sendAction('updateQueryReplayAux', {sub_type:msgData.sub_type, data: msgData.data});
          }
          //============= call action updateDBReplay =============  
          else if(msgData.action_type === 'REPLY-DB'){
            // console.log(msg)
            this.appProvider.sendAction('updateDBReplay', {sub_type:msgData.sub_type, data: msgData.data});
          }
        }else{  
          this.appProvider.sendAction('updateSubscribeStringReplay', {ndata: msg});
        }
      }
      catch {
        console.log('Invalid JSON: ',msg);
        return;
      }      
    }
    
    // use this.appProvider.sendAction, 
    // to access vars of this provider
  }

  connectionState = (isConnected, url, socketID) => {
    // console.log('ini wss', socketID);
    console.log('BISAppProvider.connectionState invoked ', isConnected ? 'Connected' : 'Disconnected', "socketID",socketID);
    // console.log('status frame ', this.frameAction);
    // if fall to disconnected state then retry connection
    if(socketID ==undefined){
      if (!isConnected) {
        this.appProvider.sendAction('networkDisconnected');
        console.log(`Reconnecting in ${baseAPI.reconnect / 1000} seconds...`);
        this.frameAction.setMainFrameActive(false);
        window.location.reload(); 
        // this.appProvider.sendAction('reconnectS',{url:url,socketID:socketID });
        window.setTimeout(() => this.netAction.createAndConnect({url: baseAPI.url}), baseAPI.reconnect);
      }
      else {
        this.appProvider.sendAction('networkConnected');
      }
    }else{
      if (!isConnected) {
        // window.setTimeout(() => this.netActionAux.createAndConnect({url: baseAPI.url2}), baseAPI.reconnect);
        // this.netActionAux.createAndConnect({url: baseAPI.url2})
        // this.appProvider.sendAction('reconnectS',{url:url,socketID:socketID }); 
        // console.log(`Reconnecting aux in ${baseAPI.reconnect / 1000} seconds...`);
        window.location.reload();
      }
    }
    // use this.appProvider.sendAction, 
    // to access vars of this provider
  }

  frameShow = (instance) => {
    // console.log(`Frame shown. Instance name ${instance.instanceName}, class name ${instance.className}`);
    Object.keys(initSubscriptionFlags).map(d => 
      instance.instanceName === d && this.appProvider.sendAction('doSetSubscription', {subscriptionID: d, flag: true}) 
    );
  }

  frameClose = (instance) => {
    // console.log(`Frame closed. Instance name ${instance.instanceName}, class name ${instance.className}`);
    Object.keys(initSubscriptionFlags).map(d => 
      instance.instanceName === d && this.appProvider.sendAction('doSetSubscription', {subscriptionID: d, flag: false}) 
    );
  }

  frameHide = (instance) => {
    // console.log(`Frame hidden. Instance name ${instance.instanceName}, class name ${instance.className}`);
    Object.keys(initSubscriptionFlags).map(d => 
      instance.instanceName === d && this.appProvider.sendAction('doSetSubscription', {subscriptionID: d, flag: false}) 
    );
  }

  componentDidMount () {

    /* make link frame and net API */    
    this.appProvider.sendAction('setActionRefs', {netAction: this.netAction, frameAction: this.frameAction, netActionAux: this.netActionAux, });
    
    /* set frame event handlers here */
    this.frameAction.setEventHandlers({onShow: this.frameShow, onHide: this.frameHide, onClose: this.frameClose});

    /* set network event handlers here */
    this.netAction.setEventHandlers({
      onMessageHandler: this.messageHandler, 
      onConnectionState: this.connectionState
    });

    /* init connection to host */
    this.netAction.createAndConnect({url: baseAPI.url}); 
    this.netActionAux.createAndConnect({url: baseAPI.url2}); 

    /* set frameActive status to false */
    this.frameAction.setMainFrameActive(false);
  }

  render () {
    console.log('BIPSAppProvider rendered', );
    return (
      <ContextProvider ref={(value) => {this.appProvider = value;}} context={BIPSAppContext} vars={BIPSAppVars} actions={BIPSAppActions}>
        <AppFrameAction ref={(value) => {this.frameAction = value;}} />
        <WSConnectionAction ref={(value) => {this.netAction = value;}} />   
        <Flash/> 
        {/*for ws 2  */}
        <WSConnectionAction ref={(value) => {this.netActionAux = value;}} socketID="aux" />  
        {this.props.children}
      </ContextProvider>
    );
  };
} 

export { BIPSAppProvider, BIPSAppContext, GenerateId,
  ConvertToRupiah, CurrencyModifier, LotToShare, ShareToLot,
  ConvertToTime, ConvertToDateTime,
  sumDataArray, GroupList, groupSearch, GroupListAmend,
  searchCode, GetRangeDate, GetLowerUpper, 
  GetStockWathclist, showPage, ColorLabel, IconChange,
};
