import React from "react";
import { Button, Table } from "react-bootstrap";
import { List, Checkbox, Dropdown, Popup } from "semantic-ui-react";

import {AppFrameAction} from "./../appframe";
import { ContextConnector } from '../appcontext.js';
import {BIPSAppContext, searchCode, } from "../AppData";
import { ModalRestricted } from "../app_modals";
import VerifyPIN, { tanggal, detailSellModal } from "../app_pages/verifyPin";
import { ResizeResponsive, getExpireOption, orderType, mathCall, strCall } from "../utils";

class FormSell_Base extends React.PureComponent{
    
    componentDidMount(){
        ResizeResponsive();
    }
    
    handleCheckbox=(e, data)=>{
        // checkbox All
        if(data.label === 'All'){
            if(this.props.portfolioData.lot === 0){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'you do not have '+strCall.code(this.props.bidOfferCode),)
            }else if(Number(this.props.portfolioData.slot) <= 0){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN, 'Insufficient sellable quantity')
            }else{ 
                this.props.handleOnClick('transaction','all', Number(this.props.portfolioData.slot))
            }
        }
        // checkbox prevent same order
        else if(data.label === 'Prevent same order'){
            this.props.handleOnClick('transaction','prev_same','none')
        }
        // check order book
        else if(data.label === 'Order Booking'){
            this.props.handleOnClick('transaction','order_book','none')
        }
    }

    onChange=(type, e)=>{
        const price = e.target.value
        if(type === 'price'){
            this.props.handleCount('sell','onChange', price)
        }else if(type === 'sellVol'){
            this.props.handleCount('sellVol','onChange', price)
        }
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickPIN = (e) => {
        var offerPrice = Number(this.props.bidOfferPrice)
        var bidVol = Number(this.props.bidOfferVol)
        var minPrice = this.props.lowerAR
        var maxPrice = this.props.upperAR 
        
        // jika bid vol lebih besar di banding dengan myVol
        if(this.props.bidOfferCode.length === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'please input stock',)
        }else if(this.props.portfolioData.lot === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,this.props.bidOfferCode+". No stock avaiable to trade.",)
        }else if(this.props.order_type === "7" && offerPrice === 0 && bidVol === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price and vol can not be zero.',)
        }else if(this.props.order_type === "7" && offerPrice === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price can not be zero.',)
        }
        else if(bidVol === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Vol can not be zero.',)
        }
        // pembatas price khusus RG dan TN
        // else if(this.props.bidOfferMkt !== '0NG' && !(offerPrice >= minPrice && offerPrice <= maxPrice) ){
        //     this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price out of range.',)   
        // }
        else{  
            // kalo simulator sedang mati
            if(this.props.regulerId === ''){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Trading server is not available',)
            }
            else if(Number(this.props.portfolioData.slot) == 0){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Insufficient sellable quantity')
            }
            else if(Number(bidVol) > Number(this.props.portfolioData.slot)){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'your volume is less',)
            }
            else if(this.props.userProfile.status ==="TRIAL" || this.props.userProfile.status === "SUSPEND" || this.props.userProfile.status === "SUSPEND SELL"){
                // this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN, this.props.userProfile.erAlert)
                this.refs.frameAction.showModal({
                    headerClass: () => <div className="text-white text-center">
                        <h3 className="text-center pt-1">Sell is not permitted</h3></div>,
                        size: 'mini',
                    contentClass: ModalRestricted,
                    onClose: (result) => {console.log('Modal 1 result = ', result)}
                })
            }
            else  {
                this.refs.frameAction.showModal({
                    // headerClass: () => <div className="text-right"></div>,
                    headerClass: () => <div className="text-white f-12 text-right"><i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}</div>,
                    // contentClass: PINVerify,
                    contentClass: detailSellModal,
                    onOpen:this.props.handleCount('none','clientOI', this.props.clientOrderId),
                    onClose: (result) => console.log('Second modal result = ', result),
                    size: "mini"
                });
            }
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var newCode =this.refs.codeSearch.value.toUpperCase()
            let searchType = newCode.split(".")
            // check the text after the dot
            if(searchType[1] === "TN" || searchType[1] === undefined){
                var codeList = Object.keys(this.props.stockList)
                var codeResult = searchCode('code1', searchType[0], codeList)
                if(codeResult === undefined){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock not found!',)
                }else if(newCode === this.props.bidOfferCode){
                        this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock already exists',)
                }else{
                    this.props.handleSearchCode('tableInformation', newCode);
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock not found!',)
            }
            this.refs.codeSearch.value =''
        }
    }

    checkDate(){
        if(this.props.part === "stock"){
            return;
        }else{
            return (
                <div className="col-sm-2 text-right text-form px-0 my-0 py-0 mx-0 pt-1">
                <i className="fa fa-calendar-alt"></i>&nbsp;
                {tanggal()}
            </div>
            );
        }
    }

    render(){
        var p = this.props;
        let stateOptionsMkt = p.bidOfferCode.includes("-R") || this.props.bidOfferMkt === '0TN' ? [{ key: 'tn', value: '0TN', text: 'TN' }] :
            (p.orbookStatus ? [{ key: 'rg', value: '0RG', text: 'RG' }] : p.stateOptionsMkt);
        return(
            <div className="f-12 px-3">
                <AppFrameAction ref="frameAction" />
                <Table borderless className={`${(this.props.part === "stockInfo") ? 'card-467' : 'card-475' } mb-0`}>
                    <tbody>
                    <tr>
                        <td className="py-0">
                        <div className="row mb-0">
                            <div className={"col-sm-2"}></div>
                            <div className="col-sm-8 f-15">
                                {
                                    p.iStockData.board_pos === "Acceleration Board" ? 
                                        <div className="pb-0 text-form">
                                            <i className="icofont icofont-warning text-danger"></i>
                                            &nbsp; <b>Member of acceleration board</b>
                                        </div>
                                    : (p.iStockData.board_pos && p.iStockData.board_pos.includes("Watch-list")) ?
                                        <div className="pb-0 text-form">
                                            <i className="icofont icofont-warning text-danger"></i>
                                            &nbsp; <b>Watchlist - Call Auction Stock</b>
                                        </div>
                                    
                                    : ""
                                }
                            </div>
                            {this.checkDate()}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-0">
                                <div className="col-sm-2 text-form">Code</div>
                                <div className="col-sm-10 row pr-0">
                                    <div className="col-sm-4 pr-0 text-center align-self-center">
                                        <input type="text" placeholder={p.bidOfferCode.length === 0 ? "Code" : (p.transactionType === "TN" ? p.bidOfferCode+".TN" : p.bidOfferCode)} 
                                            defaultValue='' onKeyDown={this.handleKeyDown} ref="codeSearch" autoFocus={p.bidOfferCode.length === 0 ? true : false}
                                            className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                    </div>
                                    <div className="input-group col-sm-8 align-self-center pl-4">
                                        <span className="input-group-btn">
                                            <label className="form-control ui icon button bg-gold px-2 py-3" style={{"margin": "0px","border-bottom-left-radius": ".28571429rem","border-top-left-radius": ".28571429rem", "border-bottom-right-radius": "0px", "cursor": "default"}}>
                                                {p.haircutCode+"%"}
                                            </label>
                                        </span>
                                        <input type="text" placeholder="Name" value={p.bidOfferCode == "" ? "" : p.iStockData.name} className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                        <span className="input-group-btn">
                                            <label className="form-control ui icon button bg-gold px-2 py-3" style={{"margin": "0px", "border-bottom-right-radius": "0px", "cursor": "default"}}>
                                                {p.codeStatus}
                                            </label>
                                        </span>
                                    </div>
                                    <div className="col-sm-12">
                                        <Checkbox label='Prevent same order' className="f-12 text-white mt-2 mb-0 align-self-center text-form" 
                                        onChange={(e, data) => this.handleCheckbox(e,data)}  checked={p.prevStatus}/>
                                    </div>
                                </div>
                                <div className="col-sm-12 row mb-0">
                                    <div className={"col-sm-2"}></div>
                                    <div className="col-sm-10 f-15 pr-0 mr-0">
                                        {p.bidOfferCode != "" && p.iStockData.special_notation && <div className="pb-0 text-form">
                                            <b>
                                                <Popup 
                                                    content={
                                                        <List>
                                                            {p.bidOfferCode != "" && p.iStockData.special_notation && p.iStockData.special_notation_desc.split("^").map((item, id) => (
                                                                <List.Item key={"snd"+id}>
                                                                    <List.Content>{item}</List.Content>
                                                                </List.Item>
                                                            ))}
                                                        </List>
                                                    }
                                                    mouseEnterDelay={100}
                                                    mouseLeaveDelay={100}
                                                    header="Special Notation Stock"
                                                    position='left center'
                                                    trigger={
                                                        <i className="fa fa-info-circle text-danger"></i>
                                                } />
                                                &nbsp; Special Notation Stock
                                            </b>
                                        </div>}
                                        {/* {
                                            ireport === "-" || ireport === undefined ? "" :
                                                <div className="pb-0 text-form">
                                                    <b>
                                                        <i className="fa fa-info-circle text-danger"></i>
                                                        &nbsp; Not yet submit annual financial report
                                                        &nbsp; {ireport}
                                                    </b>
                                                </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 pr-0 text-form">Order Type</div>
                                    <div className="col-sm-9">
                                        <Dropdown 
                                            placeholder='OrderType' 
                                            search selection 
                                            options={orderType}
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            defaultValue={p.order_type}
                                            value={p.order_type}
                                            onChange={(e, {name, value}) => {
                                                if(p.bidOfferCode.length === 0){
                                                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'pelase insert stock',)
                                                }else{
                                                    this.props.handleOnClick('transaction','order_type', value)}
                                                }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Price</div>
                                    <div className="col-sm-9">
                                        {/* <NumberInput idclassname={p.idPrice} name="sell_price" placeholder="Price" size="small" defaultValue={"12650"} className="col-sm-12 px-0 f-12 text-center align-self-center" /> */}
                                        <div className="input-group">
                                        <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('sell','minus', p.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    disabled={p.order_type === "1" ? true : false}
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={p.order_type === "1" ? "" : mathCall.setRupiah(p.bidOfferPrice)} 
                                                value={mathCall.setRupiah(p.bidOfferPrice)} 
                                                onChange={(e)=>this.onChange('price',e)}
                                                placeholder={p.order_type === "1" ? "" : "0"}
                                                disabled={p.order_type === "1" ? true : false}
                                                onFocus={(e) => e.target.select()}/>
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('sell','plus', p.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    disabled={p.order_type === "1" ? true : false}
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    {/*<Checkbox label='Auto Last' className="f-12 text-white col-sm-11 px-4 my-0 align-self-center" />*/}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Vol</div>
                                    <div className="col-sm-9">
                                        {/* <NumberInput idclassname={p.idVol} name="sell_vol" placeholder="Vol" size="small" defaultValue={"10"} className="col-sm-12 px-0 f-12 text-center align-self-center" /> */}
                                        {/* Without button + - */}
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}}
                                                className="col-sm-12 f-12 text-center align-self-center form-control" defaultValue={mathCall.setRupiah(p.bidOfferVol)} 
                                                value={mathCall.setRupiah(p.bidOfferVol)}  onChange={(e)=>this.onChange('sellVol',e)} placeholder="0"
                                                onFocus={(e) => e.target.select()}/>
                                        {/* with button + - */}
                                        {/* <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('sellVol','minus', p.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={mathCall.setRupiah(p.bidOfferVol)} value={mathCall.setRupiah(p.bidOfferVol)} 
                                                onChange={(e)=>this.onChange('sellVol',e)}
                                                />
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('sellVol-'+p.bidOfferCode,'plus', p.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span>
                                        </div>  */}
                                    </div>
                                </div>
                                <div className="col-sm-4 pl-5 row text-center align-middle align-self-center pr-0">
                                    <label className="col-sm-5 pr-4 pl-0 mb-0 bg-sell py-2 align-self-center text-left">Lot</label>
                                    <div className="col-sm-7 px-0 mx-0 py-2 align-self-center text-left">
                                        <Checkbox label='All' className="f-12 text-white my-0 align-self-center text-form"
                                            onChange={(e, data) => this.handleCheckbox(e,data)} 
                                            checked={this.props.offerAlldStatus} />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Mkt.</div>
                                    <div className="col-sm-9">
                                        <Dropdown placeholder='Mkt' search selection options={stateOptionsMkt} 
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            onChange={(e, data)=>p.handleOnClick('transaction','mkt',data.value)}  
                                            value={this.props.bidOfferMkt}/>
                                    </div>
                                </div>
                                <div className="col-sm-4 align-middle align-self-center">
                                    <div className="col-sm-12 px-0">
                                        <Checkbox label='Order Booking' className="f-12 text-white px-0 py-2 my-0 align-self-center text-form" 
                                            onChange={(e, data) => this.handleCheckbox(e,data)} 
                                            checked={p.orbookStatus}/>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 mb-3 py-form text-form">Expire</div>
                                    <div className="col-sm-9 mb-3 py-form">
                                        <Dropdown placeholder='Expire' search selection 
                                            options={getExpireOption(p.order_type, p.bidOfferMkt)} 
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            onChange={(e, data)=>p.handleOnClick('transaction','expire',data.value)} 
                                            value={this.props.bidOfferExpire}/>
                                    </div>

                                    <div className="col-sm-3 mb-3 py-form text-form">Value</div>
                                    <div className="col-sm-9 mb-3 py-form">
                                        <input type="text" id={p.idValue} name="sell_value" placeholder={p.order_type === "1" ? "" : "0"} size="small" 
                                            value={mathCall.setRupiah(p.bidOfferVal)} className="col-sm-12 f-12 text-center align-self-center form-control" 
                                            style={{"borderRadius": "0px", "text-align": "right"}} readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    <Button size="sm" className="btn btn-lg btn-success col-sm-8" onClick={this.buttonClickPIN}>
                                        <i className="icon-icon-sell-btn fa-2x"></i>
                                        <br/>Sell
                                    </Button>
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            className={`col-sm-4 btn btn-primary btn-refresh-2-right`}
                                            style={{"font-size":"12px","width":"38px"}}
                                            onClick={()=>this.props.handleOnClick('refreshPage','formbuysell','form sell')}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    }/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row px-4 py-form">
                                <Table responsive borderless size="sm" className="text-white pb-0 mb-0 d-border-table">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                    <td className="no-wrap bg-gray-tradding d-border-tr-black">Owned qty<br/>(Lot)</td>
                                        <td className="no-wrap bg-grey d-border-tr-gray text-right">{mathCall.setRupiah(p.formSellFooter.ownQtyL)}</td>
                                        <td className="no-wrap bg-gray-tradding d-border-tr-black">Owned qty<br/>(shr)</td>
                                        <td className="no-wrap bg-grey d-border-tr-gray text-right">{mathCall.setRupiah(p.formSellFooter.ownQtyS)}</td>
                                    </tr>
                                    <tr>
                                        <td className="no-wrap bg-gray-tradding d-border-tr-black">Value</td>
                                        <td className="no-wrap bg-grey d-border-tr-gray text-right">{mathCall.setRupiah(p.formSellFooter.value)}</td>
                                        <td className="no-wrap bg-gray-tradding d-border-tr-black">Avg. Price</td>
                                        <td className="no-wrap bg-grey d-border-tr-gray text-right">{mathCall.setRupiah(p.formSellFooter.avgprice)}</td>
                                    </tr>
                                    <tr>
                                        <td className="no-wrap bg-gray-tradding">P/L</td>
                                        <td className="no-wrap bg-grey text-right">{mathCall.setRupiah(p.formSellFooter.pl)}</td>
                                        <td className="no-wrap bg-gray-tradding">P/L %</td>
                                        <td className="no-wrap bg-grey text-right">{mathCall.setRupiah(p.formSellFooter.perPl, 2)}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                {/* <Popup content='Refresh' position='top center' trigger={
                    <button
                        className={`col-sm-1 btn btn-primary btn-refresh-right`}
                        style={{"font-size":"12px","width":"38px","position":"fixed",
                            "display":(this.props.part=="stock") ? "none":"block"}}
                        onClick={()=>this.props.handleOnClick('refreshPage','formbuysell','form sell')}>
                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                    </button>
                }/>  */}
            </div>
        );
    }
}

class PINVerify_Base extends React.PureComponent {

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'sell'/>
            </>
        );
    }
}


//************************** Context Connector **************************
const FormSell = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        userProfile:vars.userProfile,
        stateOptionsMkt:vars.stateOptionsMkt,
        stockList:vars.stockList, 
        bidOfferCode :vars.bidOfferCode,
        portfolioData:vars.portfolioData,
        haircutCode:vars.haircutCode,
        codeStatus:vars.codeStatus,
        iStockData:vars.iStockData,
        bidOfferPrice:vars.bidOfferPrice,   
        bidOfferVol:vars.bidOfferVol,
        bidOfferVal:vars.bidOfferVal,
        bidOfferMkt:vars.bidOfferMkt,
        bidOfferExpire:vars.bidOfferExpire,
        order_type: vars.order_type,
        prevStatus:vars.prevStatus,
        clientOrderId:vars.clientOrderId,
        orbookStatus:vars.orbookStatus,
        lowerAR:vars.lowerAR,
        upperAR:vars.upperAR,
        offerAlldStatus:vars.offerAlldStatus,
        regulerId:vars.regulerId,
        formSellFooter:vars.formSellFooter,
        transactionType:vars.transactionType,
        handleCount:(typeAction, typeCount,price)=>actions.sendAction('handleCount',{typeAction, typeCount,price}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode', {type, code}),
        // alert
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleCount", "handleOnClick", "handleSearchCode", "handleStatusAlert3"]
)(FormSell_Base)

const PINVerify = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState
    })
)(PINVerify_Base)

export default FormSell;