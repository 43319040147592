import React, { memo } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Checkbox, Dropdown, Input, Progress, Popup } from 'semantic-ui-react';
import Select from "react-select";

import { BIPSAppContext, ConvertToRupiah } from "../AppData";
import { WSConnectionAction } from "../appnetwork";
import { ContextConnector } from "../appcontext";
import { AppFrame, AppFrameAction } from "../appframe";
import FillHeaderTab from "../tabheaderfill";
import { InputPin } from './app_pin';

import * as util from "../utils";
import * as pages from "./app_mutualFund";
import { CustomTooltip, RangeDatePicker } from "../app_components";

import $ from "jquery";
import PageLoading from '../app_components/pageLoading';

window.$ = window.jQuery = $;

const MutualFundFrameHeader = memo((props) => {
    return (
        <>
        </>
    );
})

class MutualFund extends React.PureComponent {

    render() {
        return (
            //hanya memanggil headernya saja
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}

const CustomFrameHeaderMutualFund_Base = memo(({ registerMutualFund }) => {
    return (
        util.pageStatus.aperd ? 
            <div>
                <div className={registerMutualFund ? 'd-block' : 'd-none'}>
                    <div className="row col-sm-12 px-0 mx-0 align-self-center">
                        <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                            <FillHeaderTab tabMode="beforeregister" tabColumn="sm-4" treeName="/mutualfundPage" 
                                linkTitles={
                                    {
                                        MutualRegistPage: 'REGISTER TO MUTUAL FUND',
                                    }
                                } 
                            />
                        </div>
                    </div>
                </div>
                <div className={registerMutualFund ? 'd-none' : 'd-block'}>
                    <div className="row col-sm-12 px-0 mx-0 align-self-center">
                        <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                            <FillHeaderTab tabMode="afterregister" tabColumn="sm-3" treeName="/mutualfundPage"
                                linkTitles={
                                    {
                                        MutualFundxPageEtalase: 'PRODUCT LIST',
                                        MutualFundxPageCompare: 'PRODUCT COMPARISON',
                                        // MutualFundxPageRoboAdvisor: 'ROBO ADVISOR',
                                        MutualFundxPagePortfolio: 'PORTFOLIO',
                                        // MutualFundxPageBasket: 'BASKET',
                                        MutualFundxPageHistoris: 'HISTORY TRANSACTION',
                                    }
                                } 
                            />
                        </div>
                    </div>
                </div>
                <AppFrame treeName="/mutualfundPage" headerComponent={MutualFundFrameHeader} />
            </div>
        :
            <div>
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                        <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                            <i className="f-18 ion ion-md-sync click-pointer"></i>
                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16 py-5">
                    <div className="py-5">
                        <div className="py-5">
                            <i className="icofont icofont-warning-alt f-18"></i>
                            <p>Mutual Fund</p>
                            <p>Not yet available. <br/>{util.dxInfo.callCenter}</p>
                        </div>
                    </div>
                </div>
            </div>
    );
})
/** REGISTRASI MUTUAL FUND */
class RegistrasiMutual_Base extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = this.props.sInvestData;
    }

    handleChange = (e, { name, value }) => {
        if(name === "province"){
            this.props.setDataHandler({ CITY_CODE_BY_PROVINCE: [] });
            this.refs.netAction.send(util.fmsg.aperd_cityCodeByProvince(value));
            this.setState({ correspond_city_code: ''})
        }
        this.setState({ [name]: value })
    }

    onSubmit = (step) => {
        let { setDataHandler, stepHandler, userInfo } = this.props;
        let formCheck = util.refAperd.mandatoryField.filter(x => !Object.keys(this.state).includes(x));
        if(formCheck.length > 0){
            setDataHandler({ statusAlertN: true, msgAlert3: "Please complete the registration form" })
        }else if(!(this.state.checked)){
            setDataHandler({ statusAlertN: true, msgAlert3: "Please Check Declare"})
        }else{
            this.refs.netAction.send(util.fmsg.aperd_riskProfileQuestion);
            let name = userInfo.rdncbaccname.split(" ");
            let sInvestData = { ...this.state,
                sid: userInfo.sid,
                type: "1",
                sa_code: userInfo.sid,
                first_name: name[0],
                middle_name: name[1] || "",
                last_name: name[2] || "",
                nationality: "ID",
                id_no: userInfo.idcardno,
                birth_place: userInfo.birthplace,
                birth_date: userInfo.birthdate.replaceAll("-", ""),
                occupation: "3",
                statement_type: "",
                "Client_Code": userInfo.custcode
            }
            stepHandler(step, {sInvestData});
            setDataHandler({ RISK_PROFILE_ASSESSMENT: undefined });
        }
    }

    render (){
        let { userInfo, CITY_CODE_BY_PROVINCE=[], ARIAL_PROVINCE=[], isLoadingAperd=true } = this.props;
        return (
            <div id="exTab" className="container-fluid mx-0 f-12" style={{ paddingTop : "5px" }}>
                <WSConnectionAction ref="netAction" />
                <PageLoading isLoading={isLoadingAperd} />
                <span className={isLoadingAperd ? "d-none" : "d-block"}>
                    <div className="row pt-2">
                        <div className={"col-sm-12 px-0"}>
                            <label class="align-self-center px-0">Customer Data</label>
                            <div className="tab-content clearfix bg-black-trading px-0 py-0 no-border-radius mt-2 mb-2">
                                <div className="tab-pane active py-0" id="1b">
                                    <div className={"row"}>
                                        <div className={"col-sm-6"}>
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169-mf"}>
                                                <tr>
                                                    <td className={"d-border"}>SID </td>
                                                    <td width="50%" className={"even-td d-border hover-tables"} >{userInfo.sid}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Email </td>
                                                    <td width="50%" className={"d-border hover-tables"}>{userInfo.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Full Name </td>
                                                    <td width="50%" className={"even-td d-border hover-tables"}>{userInfo.custname}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>ID Number </td>
                                                    <td width="50%" className={"d-border hover-tables"} >{userInfo.idcardno}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Place Of Birth </td>
                                                    <td width="50%" className={"even-td d-border hover-tables"}>{userInfo.birthplace}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Birth Date </td>
                                                    <td width="50%" className={"d-border hover-tables"}>{userInfo.birthdate}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className={"col-sm-6"}>
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169-mf"}>
                                                <tr>
                                                    <td width="50%" className={"d-border"} >Occupation </td>
                                                    <td width="50%" className={"even-td d-border hover-tables"}>{userInfo.occupation}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Address </td>
                                                    <td width="50%" className={"d-border hover-tables"}>{userInfo.address}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>City </td>
                                                    <td width="50%" className={"even-td d-border hover-tables"}>{userInfo.city}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Phone Number </td>
                                                    <td width="50%" className={"d-border hover-tables"}>{userInfo.phonecell}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>Home Number </td>
                                                    <td width="50%" className={"even-td d-border hover-table"}>{userInfo.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>NPWP Number </td>
                                                    <td width="50%" className={"d-border hover-tables"}></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-sm-12 px-0 mt-2"}>
                            <label class="align-self-center">S-Invest Requirement Data</label>
                            <div className="tab-content clearfix bg-black-trading py-0 px-0 mt-1 no-border-radius">
                                <div className="tab-pane active py-0" id="2b">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169-mf"}>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Gender&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Gender'
                                                            fluid search selection
                                                            options={util.refAperd.gender}
                                                            name="gender"
                                                            onChange={this.handleChange}
                                                            value={this.state.gender}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Education&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Education'
                                                            fluid search selection
                                                            options={util.refAperd.education}
                                                            name="education"
                                                            onChange={this.handleChange}
                                                            value={this.state.education}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Religion&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Religion'
                                                            fluid search selection
                                                            options={util.refAperd.religion}
                                                            name="religion"
                                                            onChange={this.handleChange}
                                                            value={this.state.religion}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Income Level&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Income'
                                                            fluid search selection
                                                            options={util.refAperd.income}
                                                            name="income_level"
                                                            onChange={this.handleChange}
                                                            value={this.state.income_level}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Marital Status&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Marital Status'
                                                            fluid search selection
                                                            options={util.refAperd.martial}
                                                            name="marital_status"
                                                            onChange={this.handleChange}
                                                            value={this.state.marital_status}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Investment Objective&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0"} >
                                                        <Dropdown placeholder='Select Investment Objective'
                                                            fluid search selection
                                                            options={util.refAperd.investment}
                                                            name="obj_invest"
                                                            onChange={this.handleChange}
                                                            value={this.state.obj_invest}
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-md-6">
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169-mf"}>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Source of Fund&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0 text-right"} >
                                                        <Dropdown placeholder='Select Revenue'
                                                            fluid search selection
                                                            options={util.refAperd.revenue}
                                                            name="fund_source"
                                                            onChange={this.handleChange}
                                                            value={this.state.fund_source}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Correspondence Address&nbsp;
                                                    </td>
                                                    <td width="50%" className={" d-border hover-tables px-0 py-0 text-right"} >
                                                        <Input placeholder="Corresponce Address" fluid 
                                                            name="correspond_address"
                                                            onChange={this.handleChange}
                                                            value={this.state.correspond_address}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Correspondence Province&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0 text-right"} >
                                                        <Dropdown placeholder='Select Province'
                                                            fluid search selection
                                                            options={ARIAL_PROVINCE}
                                                            name="province"
                                                            onChange={this.handleChange}
                                                            value={this.state.province}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border"}>
                                                        Correspondence City&nbsp;
                                                    </td>
                                                    <td width="50%" className={"d-border hover-tables px-0 py-0 text-right"} >
                                                        <Dropdown placeholder='Select City'
                                                            fluid search selection
                                                            options={CITY_CODE_BY_PROVINCE}
                                                            name="correspond_city_code"
                                                            onChange={this.handleChange}
                                                            value={this.state.correspond_city_code}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border text-right"}>&nbsp;</td>
                                                    <td className={"d-border text-right"}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td className={"d-border text-right"}>&nbsp;</td>
                                                    <td className={"d-border text-right"}>&nbsp;</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 f-12">
                            <Checkbox label='I declare that i have: ' 
                                onChange={() => this.setState(s => ({ ...s, checked: !(s.checked) }) )}
                                checked={this.state.checked}
                            />
                            <br/>
                            <label>
                                I hereby agree to be bound by the terms and conditions in the prospectus, fund fact sheet, collective
                                investment contracts and/or other documents and that bind and agree to provide personal data
                                information and information related investment product that i have to relevant authorities.
                            </label>
                            <button class="d-border mx-1 pull-right col-md-2 btn btn-info f-12" onClick={() => this.onSubmit(2)}>
                                <span>Submit</span>
                            </button>
                        </div>
                    </div>
                </span>
            </div>
        )
    }
}

class UserQuestion_Base extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            questionId: 0,
            result: {},
        }
    }

    onNextHandler = (nextId) => {
        let { stepNumber, stepHandler, RISK_PROFILE_ASSESSMENT, SID } = this.props;
        if(RISK_PROFILE_ASSESSMENT.length === nextId){
            let riskProfileVal = Object.values(this.state.result).reduce((a,b) => a + Number(b), 0);
            this.refs.netAction.send(util.fmsg.aperd_riskProfileResult(SID, riskProfileVal.toString()));
            stepHandler(stepNumber + 1);
        }else{
            this.setState({questionId: nextId})
        }
    }

    onPrevHandler = (prevId) => {
        let { stepNumber, stepHandler } = this.props;
        if(prevId < 0){
            stepHandler(stepNumber - 1, "onPrevHandler")
        }else{
            this.setState({questionId: prevId})
        }
    }

    onChoiceHandler = (key, val) => this.setState(ps => ({ ...ps, result: { ...ps.result, [key]: val } }) )
    
    render(){
        let { RISK_PROFILE_ASSESSMENT } = this.props;
        let { questionId, result } = this.state;
        let jvalue = {a: 1, b: 2, c: 3, d: 4};
        return (
            <div id="exTab" className="container-fluid mx-0 f-12" style={{ paddingTop : "5px" }}>
                <WSConnectionAction ref="netAction" />
                <div className="row pt-2">
                {
                    RISK_PROFILE_ASSESSMENT ?
                        RISK_PROFILE_ASSESSMENT.length > 0 ? 
                            <div className={"col-sm-12 px-0"}>
                                <label class="align-self-center px-0">Risk Profile Assesment</label>
                                <label class="pull-right">Question {questionId+1} of {RISK_PROFILE_ASSESSMENT.length}</label>
                                <div className="tab-content clearfix bg-black-trading px-0 py-0 no-border-radius mt-2 mb-2">
                                    <div className="tab-pane active py-0" id="1b">
                                        <div className={"row container-fluid pt-2 pb-4 card-514"}>
                                            <div className="col-sm-10 col-sm-offset-1 mt-1">
                                                <div className="info-box">
                                                    <span className="info-box-icon">
                                                        {questionId + 1}.
                                                    </span>
                                                    <div className="info-box-content">
                                                        <span className="info-box-text">{RISK_PROFILE_ASSESSMENT[questionId].question_ind}</span>
                                                        <span className="info-box-number">{RISK_PROFILE_ASSESSMENT[questionId].question_eng}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-8 col-sm-offset-2 px-4 mt-2">
                                                {
                                                    Object.keys(RISK_PROFILE_ASSESSMENT[questionId].answer_ind[0]).map((item, key) => 
                                                        <div key={key+"rpa"} className={"answer-box click-pointer"} onClick={() => this.onChoiceHandler(`ques${questionId}`, jvalue[item])}>
                                                            <span className="answer-box-icon">
                                                                {item}.
                                                            </span>
                                                            <div className={`answer-box-content ${(result[`ques${questionId}`] === jvalue[item]) ? 'answer-box-selected' : ''}`}>
                                                                <span className="answer-box-text">
                                                                    {RISK_PROFILE_ASSESSMENT[questionId].answer_ind[0][item]}
                                                                </span>
                                                                <span className="answer-box-number">
                                                                    {RISK_PROFILE_ASSESSMENT[questionId].answer_eng[0][item]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="col-sm-12 px-0 f-12 px-3 mt-4">
                                                <button class="d-border mx-1 pull-left col-md-2 btn btn-info f-12" 
                                                    onClick={() => this.onPrevHandler(questionId - 1)}
                                                >
                                                    <span>Prev</span>
                                                </button>
                                                <button class="d-border mx-1 pull-right col-md-2 btn btn-info f-12"
                                                    disabled={!(result[`ques${questionId}`])}
                                                    onClick={() => this.onNextHandler(questionId + 1)}
                                                >
                                                    <span>Next</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className="col-sm-12 p-5 mt-5">
                                <div className="w-100 text-center">
                                    <h2 className="text-white">Risk Profile Question Not Found</h2>
                                </div>
                            </div>
                :
                    <div className="col-sm-12 p-5 mt-5">
                        <div className="w-100 text-center">
                            <div className="ui big active centered inline loader mb-3"></div>
                            <p className="f-13 text-white">Please wait a few moments...</p>
                        </div>
                        <Progress className="w-100 my-0" percent={100} active/>
                    </div>
                }
                </div>
            </div>
        )
    }
}

class Gauge_Base extends React.PureComponent{
    constructor(props){
        super(props);
    }
    render(){
        let { stepHandler, RISK_PROFILE_RESULT } = this.props;
        let jRotate = ["rotate-low", "rotate-lowmid", "rotate-mid", "rotate-high"];
        let nRotate = ["LOW", "MIDDLE-LOW", "MIDDLE", "HIGH"];
        return (
            <div id="exTab" className="container-fluid mx-0 f-12" style={{ paddingTop : "5px" }}>
                <div className="row pt-2">
                    { 
                        RISK_PROFILE_RESULT === undefined ? 
                            <div className="col-sm-12 p-5 mt-5">
                                <div className="w-100 text-center">
                                    <div className="ui big active centered inline loader mb-3"></div>
                                    <p className="f-13 text-white">Please wait a few moments...</p>
                                </div>
                                <Progress className="w-100 my-0" percent={100} active/>
                            </div>
                        :
                            <div className={"col-sm-12 px-0"}>
                                <label class="align-self-center px-0">Risk Profile Result</label>
                                <div className="tab-content clearfix px-0 py-0 no-border-radius mt-2 mb-2">
                                    <div className="tab-pane active py-0" id="1b">
                                        <div className={"row container-fluid pt-2 pb-4 card-480"}>
                                            <div className={"col-sm-12 text-center py-4"}>
                                                <h3> Your Risk Profile </h3>
                                            </div>
                                            <div className={"col-sm-12 text-center py-4"}>
                                                <div class="gauge-wrapper">
                                                    <div class="gauge four rischio3">
                                                        <div class="slice-colors">
                                                            <div class="st slice-item"></div>
                                                            <div class="st slice-item"></div>
                                                            <div class="st slice-item"></div>
                                                            <div class="st slice-item"></div>
                                                        </div>
                                                        <div class={`needle ${jRotate[RISK_PROFILE_RESULT.id_risk-1]}`}></div>
                                                        <div class="gauge-center">
                                                            <div class="label">RISK</div>
                                                            <div class="number">{nRotate[RISK_PROFILE_RESULT.id_risk-1]}</div>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="gauge-desc bg-dark-grey">
                                                    <h3>{RISK_PROFILE_RESULT.risk_profile}</h3>
                                                    <p>{RISK_PROFILE_RESULT.profile_note}</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 px-0 f-12 px-3 mt-4">
                                                {/* <button class="d-border mx-1 pull-left col-md-2 btn btn-info f-12" onClick={() => stepHandler(1)}>
                                                    <span>Start Over</span>
                                                </button> */}
                                                <button class="d-border mx-1 pull-right col-md-2 btn btn-info f-12" onClick={() => stepHandler(4, {RISK_PROFILE_RESULT})}>
                                                    <span>Next</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }  
                </div>
            </div>
        )
    }
}

class MutualRegistPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = { 
            stepNumber: 1,
            sInvestData: {
                // "gender": "1",
                // "education": "1",
                // "religion": "1",
                // "income_level": "1",
                // "marital_status": "1",
                // "obj_invest": "1",
                // "fund_source": "1",
                // "correspond_address": "",
                // "correspond_city_code": "",
                // "checked": true,
            }
        }
        
    }

    stepHandler = (step, data={}) => {
        let { setDataHandler, RISK_PROFILE_RESULT } = this.props;
        if(step === 1){
            data === "onPrevHandler" && setDataHandler({ RISK_PROFILE_RESULT: undefined })
            let sInvestData = data === "onPrevHandler" ? this.state.sInvestData : {};
            this.setState({ stepNumber: step, sInvestData, riskProfile: 0 })
        }else{
            this.setState(s => ({ ...s, stepNumber: step, ...data}));
            step === 3 && setDataHandler({ RISK_PROFILE_RESULT: util.dummy.APERD_RISK_PROFILE_RESULT.data });
            step === 4 && this.refs.netAction.send(util.fmsg.aperd_register({ 
                ...this.state.sInvestData, risk_profile: RISK_PROFILE_RESULT.id_risk 
            }));
        }
    }

    render() {
        let { stepNumber, sInvestData, riskProfile } = this.state;
        let { stepRegisterAperd } = this.props;
        return (
            <>
                <AppFrameAction ref="frameAction" /> 
                <WSConnectionAction ref="netAction" />
                <div className="col-sm-12 px-4 pb-0 bg-grey bg-black-trading pt-0 d-border card-527">
                    {
                        stepRegisterAperd ?
                            <div id="exTab" className="container-fluid mx-0 f-12" style={{ paddingTop : "5px" }}>
                                <div className="row pt-2">
                                    <div className={"col-sm-12 px-0"}>
                                        <div className="clearfix px-0 py-0 no-border-radius mt-2 mb-2">
                                            <div className="tab-pane active py-0" id="1b">
                                                <div className={"row container-fluid pt-2 pb-4"}>
                                                    <div className={"col-sm-12 text-center py-4"}>
                                                        <div className="icon-rpa ">
                                                            <i className="icofont-user-male em10"></i>
                                                            <i class="icofont-clock-time em4"></i>
                                                        </div>
                                                        <h2>Your request registration is on process.</h2>
                                                        <h2>Please wait within 1x24 hours, and check regulary to your Mutual Fund Account.</h2>
                                                        <h2>For more information please contact our Support Center 14009.</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : ( stepNumber === 1 ?
                                <RegistrasiMutual 
                                    stepNumber={ stepNumber } 
                                    sInvestData={ sInvestData }
                                    stepHandler={(step, data) => this.stepHandler(step, data)} 
                                />
                            : stepNumber === 2 ?
                                <UserQuestion 
                                    stepNumber={ stepNumber }
                                    SID={ sInvestData.sid }
                                    stepHandler={(step, data) => this.stepHandler(step, data)}
                                />
                            :
                                <Gauge
                                    riskProfile= { riskProfile }
                                    stepHandler={(step) => this.stepHandler(step)}
                                />
                        )
                    }
                </div>
            </>
        );
    }

}

/** ETALASE PRODUCT */
class MutualFundPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            filterStatus: 'open',
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            checkbox4: false,
            checkbox5: false,
            checkbox6: false,
            checkbox7: false,
            checkbox8: false,
        }
        
    }
    resetCheckbox = () => {
        this.setState({ checkbox1: false });
        this.setState({ checkbox2: false });
        this.setState({ checkbox3: false });
        this.setState({ checkbox4: false });
        this.setState({ checkbox5: false });
        this.setState({ checkbox6: false });
        this.setState({ checkbox7: false });
        this.setState({ checkbox8: false });
    }

    onCloseEtalaseFilter(){
        $("#btn-etalase-filter").click();
    }

    onFilterIM = (e, { value }) => {
        let filterListFund = { IM: value }
        this.props.setDataHandler({ filterListFund });
    }

    render() {
        let { filterListFund:{ IM }, listIMAperd = [] } = this.props
        return (
            <div>
                <AppFrameAction ref="frameAction" />
                <div className="card-header header-pegadaian bg-grey py-2 f-12">
                    <div className="row col-sm-12 px-0 mx-0 py-1">
                        <div className="col-sm-8 px-0 mx-0 f-14 align-self-center"></div>
                        <div className="row col-sm-4 text-right font-weight-bold px-0 mx-0">
                            <div className="col-sm-6 px-1 mx-0">
                                {/* <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark" data-title="Table Selection">Table Selection</button> */}
                            </div>
                            {/* <Dropdown icon={null} text={ */}
                                <div className="col-sm-12 px-1 mx-0">
                                    &nbsp;
                                    <Dropdown
                                        placeholder='Select Investment Management'
                                        className="text-white etalase-filter"
                                        fluid
                                        search
                                        selection
                                        options={listIMAperd}
                                        defaultValue={IM}
                                        onChange={this.onFilterIM}
                                    />
                                    {/* <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark" id="btn-etalase-filter"><i className="fa fa-filter"></i> Filter</button> */}
                                </div>
                            {/* // } className="text-white align-self-center col-sm-6 px-0 etalase-filter">
                            //     <Dropdown.Menu onClick={(e) => e.stopPropagation()} closeOnChange={false} closeOnEscape={false}
                            //         className={'px-4 py-2 bg-black-trading f-14 d-border etalase-filter-menu'} style={{ left: 'auto', right: 0 }}>
                            //         <i onClick={this.onCloseEtalaseFilter} class="icofont icofont-close text-icofont-close text-white click-pointer pull-right"></i>
                            //         <h3  className="">Mutual Fund Type</h3>
                            //         <Dropdown.Divider className="d-border py-0 px-2 mt-1 mb-3" />
                            //         <Checkbox 
                            //                 label='Equity Fund (EF)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox1: !prevState.checkbox1}))}
                            //                 checked={this.state.checkbox1}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='Balanced Fund (BF)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox2: !prevState.checkbox2}))}
                            //                 checked={this.state.checkbox2}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='Fixed Income (FI)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox3: !prevState.checkbox3}))}
                            //                 checked={this.state.checkbox3}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='Money Market Fund (MM)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox4: !prevState.checkbox4}))}
                            //                 checked={this.state.checkbox4}
                            //                 />
                            //                 <br/>
                            //         <h3  className="">Mutual Fund Categories</h3>
                            //         <Dropdown.Divider className="d-border py-0 px-2 mt-1 mb-3" />
                            //         <Checkbox 
                            //                 label='Reksa dana Umum (RU)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox5: !prevState.checkbox5}))}
                            //                 checked={this.state.checkbox5}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='Reksa dana Syariah (SY)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox6: !prevState.checkbox6}))}
                            //                 checked={this.state.checkbox6}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='ETF Umum (ERU)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox7: !prevState.checkbox7}))}
                            //                 checked={this.state.checkbox7}
                            //                 />
                            //                 <br/>
                            //         <Checkbox 
                            //                 label='ETF Syariah (ESY)' 
                            //                 className="my-0 checkbox-etalase"  
                            //                 onChange={() => this.setState((prevState) => ({checkbox8: !prevState.checkbox8}))}
                            //                 checked={this.state.checkbox8}
                            //                 />
                            //                 <br/>
                            //         <Dropdown.Divider className="d-border py-0 my-2" />
                            //         <button className="my-2 px-1 col-sm-5 btn btn-md btn-dark btn-reset-etalase" onClick={this.resetCheckbox}> Reset</button>
                            //         <button className="my-2 px-1 col-sm-5 pull-right btn btn-md btn-dark"> Save</button>
                            //     </Dropdown.Menu>
                            // </Dropdown> */}

                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16">
                    <EtalaseAgGrid gridView="tab" classView="f-12" />
                </div>
            </div>
        );
    }

}

const setWidth = (s) => s=="s49"?170:s=="s50"?145:s=="s67"?160:s=="s75"?150:s=="s80"?107:s=="s85"?105:105;
class EtalaseAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = util.widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "detail", headerName: "", sortable: true, filter: "agTextColumnFilter",
                    width: 50, minWidth: 50, 
                    comparator: util.comparatorType.text, lockPosition: true, lockVisible: true,
                    cellClass: () => " grid-table d-border-aggrid-right locked-col locked-visible ag-buysell",
                    cellRenderer: "detailRenderer",
                    suppressSizeToFit: true
                },{ 
                    field: "FundName", headerName: "Mutual Fund", sortable: true, filter: "agTextColumnFilter", 
                    resizable: true, comparator: util.comparatorType.text,
                    width: s=="s49"?284:s=="s50"?270:s=="s67"?255:s=="s75"?250:s=="s80"?223:200,
                    minWidth: 200,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12",
                    // cellRenderer: "fundCodeRenderer",
                    suppressSizeToFit: true
                },{ 
                    field: "FundType", headerName: "Type", sortable: true, resizable: true,  
                    comparator: util.comparatorType.text,
                    width: s=="s49"?170:s=="s50"?150:s=="s67"?130:s=="s80"?150:"s75"?110:92, minWidth: 101,
                    cellClass: () => " grid-table d-border-aggrid-right f-12 text-center",
                },{ 
                    field: "Nav", headerName: "NAV/Unit", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: setWidth(s), 
                    // width: s=="s49"?170:s=="s50"?145:s=="s67"?130:s=="s75"?120:s=="s80"?100:s=="s85"?95:95, 
                    minWidth: 95,
                    comparator: util.comparatorType.integer,
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                    headerComponentParams: { 
                        template: '' +
                        '<div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation">\n' +
                        '   <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" style="opacity: 0; transition: opacity 0.2s ease 0s, border 0.2s ease 0s;"></span>\n'+
                        '   <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">\n'+
                        '       <span class="ag-header-cell-text " role="columnheader" unselectable="on"><span>NAV/Unit\n'+
                        '        <i class="fa fa-info-circle click-pointer" title="As of '+ util.timeCall.getdate("/", 0, -1) +'" style="font-size: 8px; vertical-align: text-top;"></i></span></span>\n' +
                        '       <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"></span>\n' +
                        '       <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>\n' +
                        '       <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>\n' +
                        '       <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>\n' +
                        '       <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true"></span>\n'+
                        '   </div>' +
                        '</div>' 
                    },
                    cellRenderer: "lastNavRenderer",
                },{ 
                    field: "m1", headerName: "1M", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    // width: s=="s49"?170:s=="s50"?140:s=="s67"?132:s=="s75"?110:101, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "m3", headerName: "3M", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    // width: s=="s49"?170:s=="s50"?140:s=="s67"?120:s=="s75"?110:101,
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "m6", headerName: "6M", sortable: true, filter: "agNumberColumnFilter",
                    // width: s=="s49"?170:s=="s50"?140:s=="s67"?120:s=="s75"?110:101, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },{ 
                    field: "y1", headerName: "1Y", sortable: true, filter: "agNumberColumnFilter",
                    // width: s=="s49"?160:s=="s50"?140:s=="s67"?120:s=="s75"?110:101, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "y3", headerName: "3Y", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    // width: s=="s49"?160:s=="s50"?140:s=="s67"?120:s=="s75"?110:101, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "y5", headerName: "5Y", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    // width: s=="s49"?160:s=="s50"?140:s=="s67"?120:s=="s75"?110:101, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "Ytd", headerName: "YTD", sortable: true, filter: "agNumberColumnFilter",
                    // width: s=="s49"?160:s=="s50"?140:s=="s67"?120:s=="s75"?110:90, 
                    width: setWidth(s), minWidth: 101,
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 2, '0.00 %', ' %'),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                // },{ 
                //     field: "hilo", headerName: "Hi-LO", 
                //     headerComponentParams: { template: '' +
                //         '<div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation">\n' +
                //         '   <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" style="opacity: 0; transition: opacity 0.2s ease 0s, border 0.2s ease 0s;"></span>\n'+
                //         '   <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">\n'+
                //         '       <span class="ag-header-cell-text " role="columnheader" unselectable="on"><span>Hi-LO <i class="fa fa-info-circle click-pointer" title="Hi-LO&#013;Description Text" style="font-size: 8px; vertical-align: text-top;"></i></span></span>\n' +
                //         '       <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true"></span>\n'+
                //         '   </div>' +
                //         '</div>' 
                //     },
                //     sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: s=="s49"?130:s=="s50"?120:s=="s67"?100:s=="s75"?100:s=="s80"?100:s=="s85"?100:100, minWidth: 100,
                //     comparator: util.comparatorType.text,
                //     cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                // },{ 
                //     field: "sharpe", headerName: "SHARPE",  
                //     headerComponentParams: { template: '' +
                //         '<div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation">\n' +
                //         '   <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" style="opacity: 0; transition: opacity 0.2s ease 0s, border 0.2s ease 0s;"></span>\n'+
                //         '   <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">\n'+
                //         '       <span class="ag-header-cell-text" role="columnheader" unselectable="on"><span>SHARPE <i class="fa fa-info-circle" title="SHARPE&#013;Description Text" style="font-size: 8px; vertical-align: text-top;"></i></span></span>\n' +
                //         '       <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true"></span>\n'+
                //         '   </div>' +
                //         '</div>' 
                //     },
                //     sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: s=="s49"?130:s=="s50"?120:s=="s67"?100:s=="s75"?100:s=="s80"?100:s=="s85"?100:100, minWidth: 100,
                //     comparator: util.comparatorType.text,
                //     cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                // },{ 
                //     field: "ddown", headerName: "DDOWN",  
                //     headerComponentParams: { template: '' +
                //         '<div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation">\n' +
                //         '   <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" style="opacity: 0; transition: opacity 0.2s ease 0s, border 0.2s ease 0s;"></span>\n'+
                //         '   <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">\n'+
                //         '       <span class="ag-header-cell-text" role="columnheader" unselectable="on"><span>DDOWN <i class="fa fa-info-circle"  title="DDOWN&#013;Description Text" style="font-size: 8px; vertical-align: text-top;"></i></span></span>\n' +
                //         '       <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>\n' +
                //         '       <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true"></span>\n'+
                //         '   </div>' +
                //         '</div>' 
                //     },
                //     sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: s=="s49"?130:s=="s50"?120:s=="s67"?100:s=="s75"?100:s=="s80"?100:s=="s85"?100:100, minWidth: 100,
                //     comparator: util.comparatorType.text,
                //     cellClass: ({ data }) => ` grid-table d-border-aggrid-right f-12 ${data.ddown === "-N/A-" ? "text-center" : "text-right text-primary" }`,
                // },{ 
                //     field: "AUM", headerName: "AUM", sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: s=="s49"?130:s=="s50"?120:s=="s67"?100:s=="s75"?100:s=="s80"?100:s=="s85"?100:100, minWidth: 100,
                //     comparator: util.comparatorType.text,
                //     cellClass: () => " grid-table d-border-aggrid-right text-left f-12",
                },{ 
                    field: "buysell", headerName: "", sortable: true, filter: "agTextColumnFilter",
                    width:180, minWidth:180,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass: () => " grid-table d-border-aggrid-right locked-col locked-visible ag-buysell",
                    cellRenderer: "buySellRenderer",
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',
                flex: 1,
                // minWidth: 105,
            },
            animateRows: true,
            tooltipShowDelay: 0,
            frameworkComponents: {
                customTooltip: CustomTooltip,
                detailRenderer: ({ data }) => data.FundCode ? 
                    <button class="btn-etalasesearch btn btn-sm btn-dark mx-0 f-9" onClick={() => this.buttonClickFundDetail(data)}>
                        <i class="fa fa-search"></i>
                    </button> : "",
                lastNavRenderer: ({ data }) => data.FundCode ? 
                    <div className='clearfix'>
                        <span className='float-left pr-3'>{data.currency}</span>
                        <span className='float-right'>{util.mathCall.setRupiah(data.Nav, 2, '0.00')}</span>
                    </div> : "",
                // fundCodeRenderer: ({ data }) => data.FundCode ? 
                //     <span class="btn-cellorder px-1 click-pointer" onDoubleClick={() => this.buttonClickFundDetail(data)}> 
                //         {data.FundName} 
                //     </span> : "",
                buySellRenderer: ({ data }) => data.FundCode ? 
                    <span class="px-1">
                        <button class="btn-etalasebuy btn btn-sm btn-success mx-1 f-9" 
                            onClick={() => this.clickModalSubscription(data)}
                        >SUBSCRIPTION</button>
                        <button class="btn-etalasesell btn btn-sm btn-danger mx-1 f-9" 
                            disabled={!(data.portfolio)} 
                            onClick={() => this.clickModalRedemption(data)}
                        >REDEMPTION</button>
                    </span> : ""
            },
            // onRowSelected: (event) =>  {
            //     console.log(event);
            //     console.log(event.node.selected);
            //     console.log(event.rowIndex);
            // },
            // onSelectionChanged: (event) => console.log(event),
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onCurrencyCheck = (currency) => {
        this.props.setDataHandler({ tempConvertCurrency: {currency_from: currency, currency_to: "IDR"} })
        this.refs.netAction.send(util.fmsg.aperd_convertCurrency(currency));
    }

    onOpenModal = (data, type) => {
        if(type === "order" || type === "fundInfo"){
            let custBank = (this.props.cbByRdnNFund || []).find(d => d.FundCode === data.FundCode)
            !(custBank) && this.refs.netAction.send(util.fmsg.aperd_cbByRdnFund(data.FundCode, this.props.userInfo.rdnbncode));
            this.refs.netAction.send(util.fmsg.aperd_fundDetail(data.FundCode));
            this.refs.netAction.send(util.fmsg.aperd_companyProfileByIMCode(data.IMCode));
            this.refs.netAction.send(util.fmsg.aperd_navByPeriodeAndFundCode(data.FundCode, type === "order" ? "1Y" : "3Y"));
        }else if(type === "subscription" || type === "redemption"){
            this.props.handleCount(null, "availableCash");
            type === "subscription" &&this.refs.netAction.send(util.fmsg.aperd_getMutualFundByIMCode(data.IMCode));
            data.currency.toUpperCase() !== "IDR" && this.onCurrencyCheck(data.currency);
            this.props.setDataHandler({tempFundList: []});
        }
        this.props.setDataHandler({aperdModalType: type})
    }
    
    closeClick = (e) => this.refs.frameAction.closeModal(100);

    buttonClickOrderDetail = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div class="row">
                    <div className="col-sm-6 pl-4 pr-0 mx-0">Detail Product</div>
                    <div className="col-sm-6 pr-4 mx-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: pages.ModalEtalaseProduct,
            contentProps: data,
            onOpen: this.onOpenModal(data, "order"),
            onClose: () => this.props.setDataHandler({NAV_BY_PERIODE: []})
        })
    }

    buttonClickFundDetail = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div class="row">
                    <div className="col-sm-6 pl-4 pr-0 mx-0">Product Profile</div>
                    <div className="col-sm-6 pr-4 mx-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: pages.ModalEtalaseFund,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data, "fundInfo")
        })
    }

    clickModalSubscription = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                    <div className="col-sm-10 px-0">Subscription</div>
                    <div className="col-sm-2 px-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: pages.ModalSubscription,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data, "subscription"),
            onClose: () => this.props.setDataHandler({ cbByRdnNFund: [] })
        })
    }

    clickModalRedemption = (data) => {
        let { portfolio, fee_redm, Nav } = data
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                    <div className="col-sm-10 px-0">Redemption</div>
                    <div className="col-sm-2 px-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: pages.ModalRedemption,
            contentProps: {dataTable: {...portfolio, fee_redm, Nav}},
            onOpen: this.onOpenModal(data, "redemption")
        })
    }

    rowData = () => {
        let { filterListFund:{ IM }, LIST_MUTUAL_FUND=[] } = this.props; 
        let listMutualFund = LIST_MUTUAL_FUND;
        if (IM !== "all"){
            listMutualFund = LIST_MUTUAL_FUND.filter(d => d.IMCode === IM);
        }
        return util.addRowEmpty("card-472", listMutualFund)
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="netAction" />
                <div
                    className={"card-472 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.rowData()}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight={32}
                        rowSelection={this.state.rowSelection}
                        onGridReady={util.onGridReady}
                        onFirstDataRendered={util.onFirstDataRendered}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        groupSelectsFiltered={true}
                        id="tooltipHeader"
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

/** COMPARE PRODUCT */
class MutualFundPageCompare_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        let classChart = 'card-515 bg-dark-grey';

        let boxScroll = {
            overflowX: 'hidden'
        }

        let tabChartView = (
            <div className="bg-black-trading f-12" id="CompareChrt">
                <main>
                    <div className="container-fluid">
                        <div className="container px-1 mx-0 col-sm-12 row">
                            <div className={"col-md-12 px-1 pt-2"} id="chartContentCompare">
                                <div className={"d-border-inactive card " + classChart} style={boxScroll} id="chartBoxCompare">
                                    <div className="w-100 px-3 pt-3">
                                        <h5>Select Product</h5>
                                        <hr className="my-0"/>
                                    </div>
                                    {/* <CompareProductChar viewMode={true} key="chrtCompare" charVal="chrtCompare"/> */}
                                    <pages.EtalaseChart2 />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );

        return (
            <div>
                {tabChartView}
            </div>
        );
    }

}

class MutualFundPageRoboAdvisor_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AppFrameAction ref="frameAction" />
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                        <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                            <i className="f-18 ion ion-md-sync click-pointer"></i>
                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16 py-5">
                    <div className="py-5">
                        <div className="py-5">
                            <i className="icofont icofont-warning-alt f-18"></i>
                            <p>Mutual Fund - Robo Advisor</p>
                            <p>Not yet available. <br/>{util.dxInfo.callCenter}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

/** PORTFOLIO */
class MutualFundPagePortfolio_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    queryPortfolio = () => {
        this.refs.netAction.send(util.fmsg.aperd_getPortfolio(this.props.SID))
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    onCurrencyCheck = (currency) => {
        this.props.setDataHandler({ tempConvertCurrency: {currency_from: currency, currency_to: "IDR"} })
        this.refs.netAction.send(util.fmsg.aperd_convertCurrency(currency));
    }

    onOpenModal = (data, type) => {
        this.props.handleCount(null, "availableCash");
        if(data.IMCode){
            this.refs.netAction.send(util.fmsg.aperd_getMutualFundByIMCode(data.IMCode));
        }else{
            this.refs.netAction.send(util.fmsg.aperd_getMutualFundByFundCode(data.FundCode))
        }
        this.props.setDataHandler({tempFundList: []})
        data.Currency.toUpperCase() !== "IDR" && this.onCurrencyCheck(data.currency);
    }

    clickModalSubscription = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                <div className="col-sm-10 px-0">Subscription</div><div className="col-sm-2 px-0 text-right">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i></div>
                </div>,
            size: 'large',
            contentClass: pages.ModalSubscription,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data, "subscription"),
            onClose: () => this.props.setDataHandler({ cbByRdnNFund: [] })
        })
    }

    clickModalRedemption = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                <div className="col-sm-10 px-0">Redemption</div><div className="col-sm-2 px-0 text-right">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i></div>
                </div>,
            size: 'large',
            contentClass: pages.ModalRedemption,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data, "redemption")
        })
    }

    clickModalSwitching = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                <div className="col-sm-10 px-0">Switching</div><div className="col-sm-2 px-0 text-right">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i></div>
                </div>,
            size: 'large',
            contentClass: pages.ModalSwitching,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data, "switching")
        })
    }

    onInvestNow = () => this.refs.frameAction.switchPage("MutualFundxPageEtalase", "/mutualfundPage");

    render() {
        let { PORTFOLIO_MUTUAL_FUND=[], verifyPinStatus } = this.props;
        let modalAwal = PORTFOLIO_MUTUAL_FUND.reduce((a, b) => a + Number(b.ModalAwal), 0);
        let keuntungan = PORTFOLIO_MUTUAL_FUND.reduce((a, b) => a + Number(b.Keuntungan), 0);

        return (<div>
            <AppFrameAction ref="frameAction" />
            <WSConnectionAction ref="netAction" />
            {
                verifyPinStatus ? 
                    <>
                        <div className="card-header header-pegadaian bg-gray-tradding">
                            <div className="row col-sm-12 px-0 mx-0 py-1">
                                <div className="col-sm-12 pb-3 mx-0 f-14 align-self-center" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                                    <h4>PORTFOLIO</h4>
                                </div>
                                <hr/>
                                <div className="row col-sm-12 py-3 px-0 mx-0 f-14 align-self-center">
                                    <div className="col-sm-3 mx-0 f-14 align-self-center">
                                        <label className="f-16">Total Investment</label>
                                        <p>
                                            IDR {util.mathCall.setRupiah(modalAwal, 2)}
                                        </p>
                                    </div>
                                    <div className="col-sm-3 mx-0 f-14 align-self-center">
                                        <label className="f-16">Total Portfolio</label>
                                        <p>
                                            IDR {util.mathCall.setRupiah(keuntungan + modalAwal, 2)}
                                        </p>
                                    </div>
                                    <div className="col-sm-3 mx-0 f-14 align-self-center">
                                        <label className="f-16">Total Profit</label>
                                        <p>
                                            IDR &nbsp;&nbsp;
                                            <span className={util.colorText(keuntungan)} style={{ "fontSize" : "12px" }}>
                                                {util.mathCall.setRupiah(keuntungan)}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-sm-3 mx-0 f-14 align-self-center">
                                        <label className="f-16">Total %</label>
                                        <p>
                                            <span className={util.colorText(keuntungan)} style={{ "fontSize" : "12px" }}>
                                                {util.mathCall.setRupiah(keuntungan*100/modalAwal, 2, 0, ' %')}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-2 text-right font-weight-bold px-0 mx-0 align-self-center">
                                    {/* <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark"
                                        onClick={() => this.onInvestNow()}
                                    >Invest Now</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-406 align-self-center f-16 pt-3 px-3 bg-trading-gray" style={{ "overflowX": "hidden","minHeight": "514px"}}>
                            <div className="align-self-center">
                                <div className="py-2 px-0 col-sm-12" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                                    <label className="f-16">Mutual Fund List</label>
                                </div>
                                {
                                    PORTFOLIO_MUTUAL_FUND.map((data, index) => (
                                        <div key={index} className="row py-3 mx-0 px-0 col-sm-12" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                                            <div className="row mx-0 px-0 col-sm-6">
                                                <div className="col-sm-12 mx-0 px-0 my-0 py-0">
                                                    <label className="f-16">{data.FundName}</label>
                                                </div>
                                                <div className="col-sm-12 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>{data.FundType}</p>
                                                </div>

                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>
                                                        NAV/Unit&nbsp;
                                                        <Popup content={`(As of ${util.timeCall.todate3(data.Last_Nav_Date)})`}
                                                            mouseEnterDelay={150}
                                                            mouseLeaveDelay={100}
                                                            position='top center'
                                                            trigger={
                                                                <i className="fa fa-info-circle text-dark"
                                                                    style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                                                ></i>
                                                            }
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>{data.Currency} {util.mathCall.setRupiah(data.LastPrice, 2)}</p>
                                                </div>

                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>Initial Investment</p>
                                                </div>
                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>{data.Currency} {util.mathCall.setRupiah(data.ModalAwal)}</p>
                                                </div>

                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>Potential P/L</p>
                                                </div>
                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p className={Number(data.Keuntungan) > 0 ? "text-success" : "text-danger"} style={{ "fontSize" : "12px" }}>
                                                        {data.Currency} {util.mathCall.setRupiah(data.Keuntungan)}&nbsp;
                                                        <span className={Number(data.Keuntungan) > 0 ? "text-success" : "text-danger"}  style={{ "fontSize" : "12px" }}>
                                                            ({Number(data.Keuntungan) > 0 && "+"}{util.mathCall.setRupiah(data.Keuntungan*100/data.ModalAwal, 2, 0, ' %')})
                                                        </span>
                                                    </p>
                                                </div>

                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>Average Price</p>
                                                </div>
                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>{data.Currency} {util.mathCall.setRupiah(data.BuyPrice, 2)}</p>
                                                </div>

                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>Units</p>
                                                </div>
                                                <div className="col-sm-6 mx-0 px-0 my-0 py-0">
                                                    <p style={{ "fontSize" : "12px" }}>{util.mathCall.setRupiah(data.Unit, 4)}</p>
                                                </div>
                                            </div>
                                            <div className="row mx-0 px-1 col-sm-6 align-self-lg-end text-right">
                                                <div className="col-sm-4 mx-0 px-2 my-0 py-0">
                                                    <button className="mx-0 col-sm-12 btn btn-md btn-danger" 
                                                        onClick={() => this.clickModalRedemption(data)}
                                                    > REDEMPTION </button>
                                                </div>
                                                <div className="col-sm-4 mx-0 px-2 my-0 py-0">
                                                    <button className="mx-0 col-sm-12 btn btn-md btn-primary" 
                                                        onClick={() => this.clickModalSwitching(data)}
                                                    > SWITCHING </button>
                                                </div>
                                                <div className="col-sm-4 mx-0 px-2 my-0 py-0">
                                                    <button className="mx-0 col-sm-12 btn btn-md btn-success" 
                                                        onClick={() => this.clickModalSubscription(data)}
                                                    >SUBSCRIPTION</button>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </> 
            : 
                <div className="container-fluid px-1 f-12" >
                    <div className="col-sm-12 px-0" style={{paddingTop: '10px'}}>
                        <InputPin onClose={() => this.queryPortfolio()} />
                    </div>
                </div>
            }
        </div>);
    }

}

/** HISTORY */
class MutualFundPageHistoris_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    closePinModal = () => {
        let start_date = util.timeCall.getdate("/", -1);
        let end_date = util.timeCall.getdate("/");
        this.props.setDataHandler({rangeAperdHistory: { start_date, end_date } })
        $("#datepickerstartMutualHis").datepicker("setDate", start_date);
        $("#datepickerendMutualHis").datepicker("setDate", end_date);
        this.refs.netAction.send(util.fmsg.aperd_historyTransaction(this.props.SID, {start_date, end_date}));
    }

    doGo = () => {
        let { SID, rangeAperdHistory } = this.props;
        this.refs.netAction.send(util.fmsg.aperd_historyTransaction(SID, rangeAperdHistory));
    }

    render() {
        let { rangeAperdHistory={start_date: "", end_date: ""} } = this.props;
        return (<div>
            <WSConnectionAction ref="netAction" />
            {
                this.props.verifyPinStatus ?
                    <>
                        <div className="card-header header-pegadaian bg-grey py-0">
                            <div className="row col-sm-12 px-0 mx-0 py-0">
                                <div className="row f-12 col-sm-12">
                                    <div className={"col-sm-10"}>
                                        <RangeDatePicker
                                            startLabel="Period" endLabel="To"
                                            dateValue={rangeAperdHistory}
                                            dateName="rangeAperdHistory"
                                            onChangeDate={val => this.props.setDataHandler({rangeAperdHistory: val}) }
                                            goAction={() => this.doGo()}
                                        />
                                    </div>
                                    <div className="col-md-2" style={{paddingTop: '8px'}}>
                                        {/* <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark"><i className="fa fa-filter"></i> Filter</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body align-self-center text-center f-16">
                            <MyHistoryAgGrid size={util.widthSize()} gridView="tab" classView="f-12"/>
                        </div>
                    </>
                :   
                    <div className="container-fluid px-1 f-12" >
                        <div className="col-sm-12 px-0" style={{paddingTop: '10px'}}>
                            <InputPin onClose={() => this.closePinModal()} />
                        </div>
                    </div>
            }
        </div>);
    }
}

class MyHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = util.widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "TransactionDate", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    width: s=="s49"?360:s=="s50"?320:s=="s67"?280:s=="s75"?260:s=="s80"?220:s=="s85"?200:s=="s90"?190:s=="s100"?180:180, minWidth: 180,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12 click-pointer",
                    suppressSizeToFit: true
                },{ 
                    field: "transaction_id", headerName: "Transaction ID ", sortable: true, resizable: true,
                    width: s=="s49"?460:s=="s50"?420:s=="s67"?385:s=="s75"?350:s=="s80"?300:s=="s85"?280:s=="s90"?270:s=="s100"?250:250, minWidth: 250,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right f-12 text-center",
                },{ 
                    field: "side", headerName: "Type", sortable: true, resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?180:s=="s85"?160:s=="s90"?150:s=="s100"?145:130, minWidth: 130,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right f-12 text-center",
                    valueFormatter: ({ value }) => util.refAperd.trxType[value]
                },{
                    field: "nav_buy", headerName: "Nav Buy", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?180:s=="s85"?160:s=="s90"?150:s=="s100"?145:130, minWidth: 130,
                    comparator: util.comparatorType.integer,
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                    cellRenderer: "lastNavRenderer",
                },{ 
                    field: "FundName", headerName: "Mutual Fund", sortable: true, resizable: true,
                    width: s=="s49"?255:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?152:s=="s90"?150:s=="s100"?145:145, minWidth: 145,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right f-12 text-center",
                },{ 
                    field: "Amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?150:s=="s90"?130:s=="s100"?120:120, minWidth: 120,
                    comparator: util.comparatorType.integer,
                    // valueFormatter: ({ value }) => util.mathCall.setRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                    cellRenderer: "amountRenderer",
                // },{ 
                //     field: "qty", headerName: "Unit", sortable: true, filter: "agNumberColumnFilter",
                //     resizable: true, 
                //     width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?150:s=="s90"?130:s=="s100"?110:110, minWidth: 110,
                //     comparator: util.comparatorType.integer,
                //     valueFormatter: ({ value }) => util.mathCall.setRupiah(value, 4),
                //     cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                // },{ 
                //     field: "last_nav", headerName: "NAV/Unit", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                //     width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?160:s=="s90"?150:s=="s100"?160:160,
                //     comparator: util.comparatorType.integer,
                //     cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                //     cellRenderer: "lastNavRenderer",
                },{ 
                    field: "Switch_in_fund", headerName: "Switch To", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?150:s=="s90"?150:s=="s100"?133:133,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                },{ 
                    field: "status", headerName: "Status", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?150:s=="s90"?150:s=="s100"?133:133,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                    valueFormatter: ({ value }) => util.refAperd.trxStatus[value],
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',
            },
            groupIncludeFooter: true,
            groupIncludeTotalFooter: true,
            animateRows: true,
            tooltipShowDelay: 0,
            frameworkComponents: {
                customTooltip: CustomTooltip,
                lastNavRenderer: ({ data }) => data.transaction_id ? 
                    <div className='clearfix'>
                        <span className='float-left pr-3'>{data.Currency}</span>
                        <span className='float-right'>{util.mathCall.setRupiah(data.last_nav, 2, '0.00')}</span>
                    </div> : "",
                amountRenderer: ({ data }) => data.transaction_id ? 
                    <div className='clearfix'>
                        <span className='float-left pr-3'>{data.Currency}</span>
                        <span className='float-right'>{util.mathCall.setRupiah(data.Amount)}</span>
                    </div> : "",
            },
            rowSelection: "multiple",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-472 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.HISTORY_MUTUAL_FUND}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="28"
                        rowSelection={this.state.rowSelection}
                        onGridReady={util.onGridReady}
                        onFirstDataRendered={util.onFirstDataRendered}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        groupSelectsFiltered={true}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class MyBasketAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = util.widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "-", headerName: "#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 35, minWidth: 35,
                    comparator: util.comparatorType.text,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    checkboxSelection: ({ data }) => data.FundCode ? true : false,
                    cellClass: () => " grid-table d-border-aggrid-right text-center text-primary f-12 click-pointer",
                    suppressSizeToFit: true
                },{ 
                    field: "FundCode", headerName: "Reksa dana", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: util.comparatorType.text,
                    width: s=="s49"?380:s=="s50"?340:s=="s67"?300:s=="s75"?290:s=="s80"?250:s=="s85"?240:s=="s90"?230:s=="s100"?210:210,
                    cellClass: () => " grid-table d-border-aggrid-right text-left text-primary f-12",
                    cellRenderer: 'fundCodeRenderer',
                    suppressSizeToFit: true
                },{ 
                    field: "jenistrans", headerName: "Jenis Transaksi", sortable: true, resizable: true,
                    width: s=="s49"?380:s=="s50"?340:s=="s67"?300:s=="s75"?270:s=="s80"?250:s=="s85"?230:s=="s90"?220:s=="s100"?210:210,
                    minWidth:200,
                    comparator: util.comparatorType.text,
                    cellClass: () => " grid-table d-border-aggrid-right f-12 text-center",
                },{ 
                    field: "jumlahunit", headerName: "Jumlah Unit", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?390:s=="s50"?340:s=="s67"?300:s=="s75"?290:s=="s80"?244:s=="s85"?180:s=="s90"?165:s=="s100"?155:155,
                    minWidth:155, 
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "jumlahrupiah", headerName: "Jumlah Rupiah", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, 
                    width: s=="s49"?390:s=="s50"?355:s=="s67"?310:s=="s75"?290:s=="s80"?250:s=="s85"?240:s=="s90"?220:s=="s100"?210:210,
                    minWidth:200, 
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "biayatrans", headerName: "Biaya Transaksi", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?380:s=="s50"?355:s=="s67"?310:s=="s75"?290:s=="s80"?250:s=="s85"?240:s=="s90"?220:s=="s100"?210:210,
                    minWidth:200, 
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "subtotal", headerName: "Sub Total", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, 
                    width: s=="s49"?380:s=="s50"?340:s=="s67"?310:s=="s75"?290:s=="s80"?250:s=="s85"?240:s=="s90"?220:s=="s100"?210:210,
                    minWidth:200, 
                    comparator: util.comparatorType.integer,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',
            },
            groupIncludeFooter: true,
            groupIncludeTotalFooter: true,
            animateRows: true,
            tooltipShowDelay: 0,
            frameworkComponents: {
                customTooltip: CustomTooltip,
                fundCodeRenderer: ({ data }) => data.FundCode ?
                    <span class="btn-cellorder px-1 click-pointer" onDoubleClick={() => this.clickModalSubscription(data)}>
                        {data.FundCode}
                    </span> : "",
            },
            rowData: [
                {
                    tanggal : "02 Jan 2021" + s,
                    idtrans : "TRX13xxxx",
                    jenistrans :"SUB",
                    reksadana : "Obligasi ABC",
                    nominal :"1000000",
                    unit :"1",
                    lastnabup :"1000000",
                },{
                    tanggal : "02 Jan 2021",
                    idtrans : "TRX13xxxx",
                    jenistrans :"SUB",
                    reksadana : "Obligasi ABC",
                    nominal :"1000000",
                    unit :"1",
                    lastnabup :"1000000",
                },{
                    tanggal : "02 Jan 2021",
                    idtrans : "TRX13xxxx",
                    jenistrans :"SUB",
                    reksadana : "Obligasi ABC",
                    nominal :"1000000",
                    unit :"1",
                    lastnabup :"1000000",
                },{
                    tanggal : "02 Jan 2021",
                    idtrans : "TRX13xxxx",
                    jenistrans :"SUB",
                    reksadana : "Saham ABC",
                    nominal :"1000000",
                    unit :"1",
                    lastnabup :"1000000",
                },{
                    emptyRow: ""
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },{
                    emptyRow: "",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    closeClick = (e) => this.refs.frameAction.closeModal(100)

    clickModalSubscription = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="row mx-2 py-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px" }}>
                    <div className="col-sm-10 px-0">Subscription</div>
                    <div className="col-sm-2 px-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: pages.ModalSubscription,
        })
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-323 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="28"
                        onGridReady={util.onGridReady}
                        onFirstDataRendered={util.onFirstDataRendered}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        groupSelectsFiltered={true}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class MutualFundPageBasket_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const paddingParagraph = {
            paddingTop: '10px'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid px-1 f-12" >
                    <div className="col-sm-12 px-0" style={paddingParagraph}>
                        <BasketPage />
                    </div>
                </div>
            </>
        );
    }
}

const BasketPage_Base = (props) => {
    return (
        <div>
            {/*<BIPSAppProvider>*/}
            <WSConnectionAction />
            <div className="row col-sm-12 px-0 mx-0 align-self-center">
                <div className="col-sm-8 px-0 mx-0 d-border-bottom">
                    <FillHeaderTab esbnMode="afterregister" treeName="/mutualfundPage/MutualFundxPageBasket" linkTitles={
                        {
                            xMutualFundMyBasket : 'MY BASKET',
                            xMutualFundHistoryTrans: 'HISTORIS TRANSAKSI',
                        }
                    } />
                </div>
            </div>
            <AppFrame treeName="/mutualfundPage/MutualFundxPageBasket" headerComponent={MutualFundFrameHeader} />
        </div>
    );
}

class MutualFundPageMyBasket_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    render() {
        const mutualOptions = [
            { value:'bsb', reksadana: 'Bahana Sukuk Bunga' },
            { value:'bdl', reksadana: 'Bahana Dana Likiud' },
            { value:'bga', reksadana: 'Bahana Ganesha Abadi' },
            { value:'bip', reksadana: 'Bahana Investasi Prima' },
        ];

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var reksadana = option.data.reksadana.toLowerCase().includes(searchText.toLowerCase());

            if (reksadana) {
                return true;
            }
        };

        return (
            <div>
                <AppFrameAction ref="frameAction" />
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        <div className="col-sm-10 px-0 mx-0 f-14 align-self-center text-left">
                            <div className="col-sm-2 px-1 mx-0">
                                <button className="my-2 mx-0 col-sm-12 btn btn-md btn-success" onClick={() => {
                                    this.refs.frameAction.switchPage("MutualFundxPageEtalase","/mutualfundPage");
                                }}><i className="fa fa-plus"></i> Add</button>
                            </div>
                        </div>
                        <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                            <Select
                                getOptionLabel={(option) => `${option.reksadana}`}
                                filterOption={customFilter}
                                isSearchable={true}
                                maxMenuHeight={155}
                                styles={customStyles}
                                placeholder={<div>Search..</div>}
                                options={mutualOptions}
                                className="stockPageSelect text-left"
                                theme={this.selectSelectionTab}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16">
                    <MyBasketAgGrid size={util.widthSize()} gridView="tab" classView="f-12"/>
                    <div className="row col-sm-12 px-2 mx-0 py-4">
                        <div className="col-sm-9 px-0 mx-0 f-14 align-self-center text-left">*ceklis box untuk memilih reksa dana yang akan di cancel atau di bayar.</div>
                        <div className="row col-sm-3 text-right font-weight-bold px-0 mx-0">
                            <div className="col-sm-6 px-1 mx-0">
                                <button className="my-2 mx-0 col-sm-12 btn btn-md btn-danger">Cancel</button>
                            </div>
                            <div className="col-sm-6 px-1 mx-0">
                                <button className="my-2 mx-0 col-sm-12 btn btn-md btn-success">Check Out</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


// =========== CONTEXT CONNECTOR ===========
const CustomFrameHeaderMutualFund = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        registerMutualFund: vars.registerMutualFund
    }),
)(CustomFrameHeaderMutualFund_Base);

/** REGISTER */
const RegistrasiMutual = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        userInfo: vars.userInfo,
        isLoadingAperd: vars.isLoadingAperd,
        ARIAL_PROVINCE: vars.ARIAL_PROVINCE,
        CITY_CODE_BY_PROVINCE: vars.CITY_CODE_BY_PROVINCE,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars})
    }),
    ["setDataHandler"]
)(RegistrasiMutual_Base);

const UserQuestion = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        RISK_PROFILE_ASSESSMENT: vars.RISK_PROFILE_ASSESSMENT,
    }),
)(UserQuestion_Base);

const Gauge = ContextConnector(BIPSAppContext,
    ( vars ) => ({
        RISK_PROFILE_RESULT: vars.RISK_PROFILE_RESULT,
    })
)(Gauge_Base);

const MutualRegistPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        stepRegisterAperd: vars.stepRegisterAperd,
        RISK_PROFILE_RESULT: vars.RISK_PROFILE_RESULT,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        setTabMutualFund: () => actions.sendAction('setTabMutualFund')
    }),
    ["setDataHandler", "setTabMutualFund"]
)(MutualRegistPage_Base);

/** ETALASE PRODUK */
const MutualFundPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        filterListFund: vars.filterListFund,
        listIMAperd: vars.listIMAperd,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', { newVars }),
    }),
    ["setDataHandler"]
)(MutualFundPage_Base);

const EtalaseAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        LIST_MUTUAL_FUND: vars.LIST_MUTUAL_FUND,
        filterListFund: vars.filterListFund,
        userInfo: vars.userInfo,
        cbByRdnNFund: vars.cbByRdnNFund,
        handleCount: (typeAction, typeCount, price) => actions.sendAction('handleCount', {typeAction, typeCount, price}),
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["handleCount", "setDataHandler"]
)(EtalaseAgGrid_Base);

/** PORTDOLIO */
const MutualFundPagePortfolio = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        PORTFOLIO_MUTUAL_FUND: vars.PORTFOLIO_MUTUAL_FUND,
        // userInfo: vars.userInfo,
        SID: vars.SID,
        verifyPinStatus: vars.verifyPinStatus,
        handleCount: (typeAction, typeCount, price) => actions.sendAction('handleCount', {typeAction, typeCount, price}),
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["handleCount", "setDataHandler"]
)(MutualFundPagePortfolio_Base);

/** HISTORY */
const MutualFundPageHistoris = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        SID: vars.SID,
        verifyPinStatus: vars.verifyPinStatus,
        rangeAperdHistory: vars.rangeAperdHistory,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["setDataHandler"]
)(MutualFundPageHistoris_Base);

const MyHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        HISTORY_MUTUAL_FUND: vars.HISTORY_MUTUAL_FUND
    }),
)(MyHistoryAgGrid_Base);

const MutualFundPageCompare = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
    }),
)(MutualFundPageCompare_Base);

const MutualFundPageRoboAdvisor = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
    }),
)(MutualFundPageRoboAdvisor_Base);

const MutualFundPageBasket = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
    }),
)(MutualFundPageBasket_Base);

const BasketPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
    }),
)(BasketPage_Base);

const MutualFundPageMyBasket = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        thememode: vars.thememode,
    }),
)(MutualFundPageMyBasket_Base);

export default MutualFundPage;
export {CustomFrameHeaderMutualFund, MutualFund, MutualFundPageCompare, MutualFundPageRoboAdvisor, MutualFundPagePortfolio,
    MutualFundPageBasket, MutualFundPageMyBasket, MutualFundPageHistoris, MutualRegistPage}
