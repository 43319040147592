import DarkEarth from "./chartTheme/darkEarth";
import Coffee from "./chartTheme/coffee";
import DarkBlue from "./chartTheme/darkBlue";
import Morning from "./chartTheme/morning";
import LightBlue from "./chartTheme/lightBlue";
import LightEarth from "./chartTheme/lightEarth";
import DarkGlamour from "./chartTheme/darkGlamour";
import LightGlamour from "./chartTheme/lightGlamour";
import DarkProvence from "./chartTheme/darkProvence";
import LightProvence from "./chartTheme/lightProvence";
import DarkTurquoise from "./chartTheme/darkTurquoise";
import LightTurquoise from "./chartTheme/lightTurquoise";
import Monochrome from "./chartTheme/monochrome";
import Pastel from "./chartTheme/pastel";
import Sea from "./chartTheme/sea";
import Wines from "./chartTheme/wines";

const setThemeMode = (thememode) => {
    document.body.style.setProperty('--warna-dasar', thememode === true  ? "#010101" : "#FCFCFC");
    document.body.style.setProperty('--warna-header-card', thememode === true  ? "#181818" : "#E7E8E8");
    document.body.style.setProperty('--warna-text-basic', thememode === true  ? "#FFFFFF" : "#272727");
    document.body.style.setProperty('--warna-text-header', thememode === true  ? "#FEFEFE" : "#282828");
    document.body.style.setProperty('--warna-black-white', thememode === true  ? "#000000" : "#FCFCFC");
    document.body.style.setProperty('--warna-black-white-gradient', thememode === true  ? "#212121" : "#dcddde");
    document.body.style.setProperty('--warna-cssmenu', thememode === true  ? "#F7F7F7" : "#878787");
    
    document.body.style.setProperty('--warna-gray', thememode === true  ? "#4D4E4E" : "#999999");
    document.body.style.setProperty('--warna-inactive-gradient', thememode === true  ? "#0F0F10" : "#F9FAFB");
    document.body.style.setProperty('--warna-btn-dark', thememode === true  ? "#3D3E3F" : "#CDCDCE");
    document.body.style.setProperty('--warna-btn-dark-hover', thememode === true  ? "#333332" : "#CDCDDD");
    document.body.style.setProperty('--warna-scroll', thememode === true  ? "#676767" : "#B3B4B4");
    document.body.style.setProperty('--warna-black-white-semantic', thememode === true  ? "#FFFFFF" : "#010101");
    document.body.style.setProperty('--warna-background-semantic', thememode === true  ? "#333332" : "#FEFEFE");
    document.body.style.setProperty('--warna-background-semantic-gradient', thememode === true  ? "#010101" : "#FFFFFF");
    document.body.style.setProperty('--warna-d-border', thememode === true  ? "#565252" : "#999999");
    document.body.style.setProperty('--warna-d-border-bold', thememode === true  ? "#FFFFFF" : "#999999");
    document.body.style.setProperty('--warna-d-border-black', thememode === true  ? "#010101" : "#E7E7E7");
    document.body.style.setProperty('--warna-bg-dark-grey', thememode === true  ? "#1A1A1A" : "#E9E9E9");
    document.body.style.setProperty('--warna-bg-trading-gray', thememode === true  ? "#262626" : "#E3E3E3");
    document.body.style.setProperty('--warna-text-menu', thememode === true  ? "#FFFFFF" : "#E7E7E7");
    document.body.style.setProperty('--warna-hover-menu', thememode === true  ? "#111111" : "#f4f4f4");
    document.body.style.setProperty('--warna-table-striped', thememode === true  ? "#272727" : "#E7E8E8");

    document.body.style.setProperty('--warna-navy-odd', thememode === true  ? "#0E0E20" : "#ABACAC");
    document.body.style.setProperty('--warna-navy-even', thememode === true  ? "#2E3354" : "#CBCBCA");
    document.body.style.setProperty('--warna-text-danger', thememode === true  ? "#eb2720" : "#ea0500");
    document.body.style.setProperty('--warna-text-success', thememode === true  ? "#05FE01" : "#449b52");
    document.body.style.setProperty('--warna-text-warning', thememode === true  ? "#ffe222" : "#f59100");

    document.body.style.setProperty('--warna-text-jam', thememode === true  ? "#6DCCDC" : "#09549A");
    document.body.style.setProperty('--warna-navy-headermenu-odd', thememode === true  ? "#0E0E20" : "#F4F5F6");
    document.body.style.setProperty('--warna-navy-headermenu-even', thememode === true  ? "#2E3354" : "#DFDFDE");
    document.body.style.setProperty('--warna-shadow', thememode === true  ? "#000000" : "#ACACAC");
    document.body.style.setProperty('--warna-border-headermenu', thememode === true  ? "#5f68ad" : "#F9F9F9");
    document.body.style.setProperty('--warna-bg-data-orange', thememode === true  ? "#e68c27" : "#e6e027");
    
    document.body.style.setProperty('--warna-btn-dark-disabled', thememode === true  ? "#3D3E3F20" : "#CDCDCE20");
    document.body.style.setProperty('--warna-btn-dark-hover-disabled', thememode === true  ? "#33333240" : "#CDCDDD40");
    document.body.style.setProperty('--warna-pembatas', thememode === true  ? "#373839" : "#CDCDD5");
}

const customStyles = {
    control: (base, state) => ({
        ...base,
        // match with the menu
        borderRadius: 0,
        border: "var(--warna-d-border) 1px solid"
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
    }),
    menuList: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0
    })
};

const selectSelectionTab = (theme, thememode) => ({
    ...theme,
    borderRadius: 5,
    colors: {
        ...theme.colors,
        neutral0: thememode === true ? '#3D3E3F' : '#CDCDCE', //'#565252' : '#999999'
        neutral20: thememode === true ? '#333332' : '#E9E9E9', //'#333332' : '#CDCDCE'
        neutral30: thememode === true ? '#333332' : '#E9E9E9', //'#333332' : '#CDCDCE'
        neutral40: thememode === true ? '#1A1A1A' : '#1A1A1A',
        neutral80: thememode === true ? '#FFFFFF' : '#878787', //'#FFFFFF' : '#FFFFFF',
        primary75: thememode === true ? '#FFFFFF' : '#FFFFFF', //'#FFFFFF' : '#FFFFFF',
        primary50: thememode === true ? '#4D4D4E' : '#4D4D4E', //'#333332' : '#4D4D4E',
        primary25: thememode === true ? '#202020' : '#ece9ea', //'#7e7a7a' : '#c5c2c3',
        primary: '#0071BC',
    }
});

const chartThemeList = [
    {value: 'darkEarth', label: 'Dark Earth', theme: DarkEarth},
    {value: 'lightEarth', label: 'Light Earth', theme: LightEarth},
    {value: 'darktBlue', label: 'Dark Blue', theme: DarkBlue},
    {value: 'lightBlue', label: 'Light Blue', theme: LightBlue},
    {value: 'darkGlamour', label: 'Dark Glamour', theme: DarkGlamour},
    {value: 'lightGlamour', label: 'Light Glamour', theme: LightGlamour},
    {value: 'darkProvence', label: 'Dark Provence', theme: DarkProvence},
    {value: 'lightProvence', label: 'Light Provence', theme: LightProvence},
    {value: 'darkTurquoise', label: 'Dark Turquoise', theme: DarkTurquoise},
    {value: 'lightTurquoise', label: 'Light Turquoise', theme: LightTurquoise},
    {value: 'coffee', label: 'Coffee', theme: Coffee},
    {value: 'monochrome', label: 'Monochrome', theme: Monochrome},
    {value: 'morning', label: 'Morning', theme: Morning},
    {value: 'pastel', label: 'Pastel', theme: Pastel},
    {value: 'sea', label: 'Sea', theme: Sea},
    {value: 'wines', label: 'Wines', theme: Wines},
]

export { 
    setThemeMode, 
    chartThemeList, 
    customStyles, 
    selectSelectionTab 
}