import { bank_bca, bank_bni, bank_bsi, bank_bri, bank_mandiri, bank_cimb } from '../../img';

export const optList = {
   pageESBNStatus: true,
   declareRedeem: [
      'Telah menyampaikan data redemption dengan benar dan lengkap.',
      'Setuju bahwa data redemption yang telah disubmit tidak dapat diubah/dibatalkan.'
   ],
   declareSubscription: [
      // <>Telah membaca dan memahami isi <b><a href="https://www.kemenkeu.go.id/media/13645/memorandum-informasi-st006-final.pdf" target="_blank">Informasi Memorandum</a></b>.</>,
      'Telah menyampaikan data pemesanan dengan benar dan lengkap;',
      'Setuju untuk menguasakan (wakalah) pengelolaan dana investasi pada Sukuk Tabungan dan seluruh hak terkait Aset SBSN Sukuk \
         Tabungan kepada Perusahaan Penerbit SBSN Indonesia sebagai Wali Amanat untuk kegiatan investasi yang menghasilkan keuntungan \
         (Untuk Sukuk Tabungan, dalam hal telah menjadi pemegang atau pemilik Sukuk Tabungan).',
      'Setuju bahwa pemesanan yang telah disubmit tidak dapat diubah/dibatalkan dan jika tidak dilakukan pembayaran dalam periode tertentu \
         setelah menerima kode billing maka secara otomatis batal/menjadi kedaluwarsa.'
   ],
   term_condition_redemption: [
      {
         textIndo: 'Telah menyampaikan data redemption dengan benar dan lengkap.'
      },{
         textIndo: 'Setuju bahwa data redemption yang telah disubmit tidak dapat diubah/dibatalkan.'
      }
   ],
   term_condition_registration: [
      {
         textIndo: 'Layanan transaksi SBN Ritel secara elektronik adalah fasilitas pemesanan SBN Ritel yang dapat \
            dilakukan oleh investor melalui media elektronik dan jaringan internet yang terintegrasi dengan \
            sistem SBN Ritel pada Kementerian Keuangan.'
      },{
         textIndo: 'Hukum yang berlaku dalam rangka proses registrasi dan pemesanan SBN Ritel adalah hukum Indonesia.'
      },{
         textIndo: 'Pengguna setuju untuk mematuhi peraturan terkait yang berlaku di wilayah Negara Kesatuan Republik Indonesia.'
      },{
         textIndo: 'Pengguna adalah individu yang cakap dan memiliki kemampuan serta kapasitas dalam berinvestasi dalam SBN Ritel.'
      },{
         textIndo: 'Pengguna telah memahami dan menyetujui seluruh ketentuan dalam proses registrasi SBN Ritel \
            secara elektronik serta melengkapi semua data yang dibutuhkan secara benar dan lengkap. \
            Pemerintah tidak bertanggung jawab atas kesalahan atau ketidaklengkapan data yang disampaikan oleh Pengguna.'
      },{
         textIndo: 'Pengguna wajib melakukan registrasi dengan menyiapkan data yang sekurang-kurangnya terdiri \
            dari Surat Elektronik (e-mail), Single Investor Identification (SID), Rekening Dana, dan Rekening \
            Surat Berharga (Efek) untuk dapat menggunakan layanan transaksi SBN Ritel secara elektronik.'
      },{
         textIndo: 'Data registrasi yang disampaikan oleh Pengguna melalui sistem SBN Ritel ini antara lain meliputi \
            data e-mail, SID, Rekening Dana, Rekening Surat Berharga (Efek) akan menjadi milik \
            Pemerintah, dan Pengguna memberikan persetujuan kepada Pemerintah untuk menggunakan \
            data tersebut dalam rangka pemasaran dan/atau transaksi SBN Ritel dengan mengacu pada \
            ketentuan kerahasiaan data dan peraturan lain yang berlaku di wilayah Negara Kesatuan Republik Indonesia.'
      },{
         textIndo: 'Pengguna bertanggung jawab secara pribadi untuk menjaga keamanan dan kerahasiaan akun, \
            kata sandi, dan/atau standar keamanan lainnya untuk semua aktivitas dalam penggunaan transaksi SBN Ritel secara elektronik.'
      },{
         textIndo: 'Pengguna membebaskan Pemerintah dari segala tuntutan menyangkut penyalahgunaan akun, kata sandi, dan/atau standar keamanan lainnya.'
      },{
         textIndo: 'Dalam hal terdapat kendala dalam penggunaan transaksi SBN Ritel secara elektronik, Pengguna wajib segera menghubungi mitra distribusi.'
      },{
         textIndo: 'Pengguna bertanggung jawab atas segala biaya, klaim, kerusakan, kerugian (termasuk namun \
            tidak terbatas pada kerugian yang timbul dari Transaksi) atau kewajiban lainnya karena \
            ketidakmampuan dan ketidakpahaman Investor dalam menggunakan layanan transaksi SBN Ritel secara elektronik.'
      },{
         textIndo: 'Pengguna membebaskan Pemerintah dan/atau Mitra Distribusi dari segala kerugian yang timbul \
            atas terjadinya kerusakan sistem layanan transaksi SBN Ritel secara elektronik dan/atau \
            keadaan darurat yang berada di luar kendali Pemerintah dan/atau Mitra Distribusi yang \
            mengakibatkan sistem layanan transaksi SBN Ritel secara elektronik tidak dapat bekerja secara \
            normal. Kejadian atau keadaan darurat (force majeure) tersebut mencakup namun tidak terbatas pada hal-hal sebagai berikut: ',
         textIndo_: [
            'bencana alam;',
            'perang yang timbul dari atau sebagai akibat perang;',
            'tindakan sabotase oleh teroris;',
            'Pemberontakan;',
            'gempa bumi;',
            'badai letusan gunung berapi;',
            'banjir, badai, kondisi cuaca luar biasa buruk, yang dinyatakan resmi oleh Pemerintah \
               Republik Indonesia; perubahan situasi kondisi politik dan ekonomi maupun kebijakan \
               pemerintah yang luar biasa yang berakibat tidak kondusif untuk melakukan transaksi SBN Ritel; dan/atau',
            'perubahan atas peraturan perundang-undangan yang berpengaruh langsung atas transaksi SBN Ritel'
         ]
      }
   ],
   term_condition_subscription: [
      {
         textIndo: 'Persetujuan pemesanan ini sebagai Akta Ikrar Wakaf elektronik tanpa penandatanganan langsung, yang akan dinyatakan batal jika \
            tidak dilakukan pembayaran atau status pemesanan berubah menjadi unpaid order (dalam hal melakukan pemesanan untuk Cash Waqf Linked Sukuk).'
      },{
         textIndo: 'Setuju untuk menerima kuasa dari Nazhir untuk menginvestasikan dana wakaf uang dalam Cash Waqf Linked Sukuk (CWLS).'
      },{
         textIndo: 'Setuju untuk menguasakan (wakalah) pengelolaan dana investasi pada CWLS dan seluruh hak terkait Aset SBSN CWLS kepada Perusahaan Penerbit SBSN Indonesia \
            sebagai Wali Amanat untuk kegiatan investasi yang menghasilkan keuntungan (dalam hal telah menjadi pemegang atau pemilik CWLS).'
      },{
         textIndo: 'Menyetujui dilakukan pendebetan rekening dana wakif dalam rangka pemindahbukuan hasil investasi CWLS (kupon/imbalan) pada tiap-tiap \
            tanggal pembayaran kupon/imbalan Cash Waqf Linked Sukuk oleh Mitra Distribusi untuk dikreditkan kepada rekening Nazhir \
            yang ditunjuk sebagaimana tercantum dalam memorandum informasi.'
      }
   ],
   statusTrxESBN: [
      {key: "", value: "all", text: "ALL" /** "SEMUA" */},
      {key: "Pending", value: "Pending", text: "PENDING" /** "DITUNDA"*/},
      {key: "Confirmed", value: "Confirmed", text: "CONFIRMED" /** "DIKONFIRMASI" */},
      {key: "Reject", value: "Reject", text: "REJECT" /** "DITOLAK" */},
      {key: "Verified Order", value: "Verified Order", text: "VERIFIED ORDER" /** "VERIFIKASI" */},
      {key: "Completed Order", value: "Completed Order", text: "COMPLETED ORDER" /** "SELESAI"  */},
      {key: "Unpaid Order", value: "Unpaid Order", text: "UNPAID ORDER" /** "BELUM DIBAYAR" */},
   ]
};

export const internetBankingMethod = [
   { name: 'BCA',
      img: bank_bca,
      steps: [
         "Pilih menu Pembayaran",
         "Pada laman menu Pembayaran, pilih Surat Berharga Negara",
         "Masukkan Kode Billing dan Sumber Dana",
         "Tekan tombol Lanjutkan",
         "Cek detail pembayaran, masukkan Respon KeyBCA Appli 1, lalu klik Kirim",
         "Pembayaran berhasil dan informasi Nomor Transaksi Penerimaan Negara (NTPN) dapat terlihat di bukti transaksi yang muncul pada halaman KlikBCA",
      ]
   },
   { name: 'BNI',
      img: bank_bni,
      steps: [
         "Pilih menu Transaksi",
         "Pilih menu Pembelian/Pembayaran kemudian pilih Pembayaran Tagihan",
         "Pilih list opsi Penerimaan Negara kemudian pilih Pembayaran Pajak/PNBP/Cukai",
         "Selanjutnya nasabah pilih nama biller sesuai billing yang akan dibayar (DITJEN ANGGARAN), input nomor Kode Billing, pilih Mata Uang dan pilih Rekening Debet",
         "Layar akan menampilkan data billing untuk diverifikasi, jika sudah sesuai lanjutkan proses Bayar dengan input password yang dihasilkan dari token",
         "Nasabah dapat download bukti pembayaran sebagai Bukti Penerimaan Negara (BPN)",
      ]
   },
   { name: 'Mandiri',
      img: bank_mandiri,
      steps: [
         "Pilih menu Bayar",
         "Pilih Penerimaan Negara",
         "Pada kolom Jenis Pajak, pilih Pajak/PNBP/Cukai",
         "Pada kolom Kode Billing, masukkan ID/Kode Billing lalu klik Lanjutkan",
         "Layar akan menunjukkan detil pembayaran pajakmu, pastikan bahwa informasi yang ditayangkan tersebut sudah sesuai, jika sudah sesuai klik Lanjutkan",
         "Masukkan PIN Mandiri dari Token Mandiri, lalu klik Kirim",
         "Bukti transaksi akan dikirim oleh Bank Mandiri ke e-mail.",

      ]
   },
   { name: 'BRI',
      img: bank_bri,
      steps: [
         "Pilih menu Pembayaran Tagihan",
         "Scroll dan Pilih menu Pembayaran MPN di menu sebelah kiri",
         "Pilih rekening yang akan dipakai untuk pembayaran kemudian masukkan Kode Billing pembayaran MPN. Kemudian tekan Kirim",
         "Review pembayaran MPN anda, bila sudah benar silahkan tekan permintaan token. \
            Token akan dikirimkan melalui SMS ke nomor handphone yang telah didaftarkan. \
            Masukkan Password internet banking anda dan mToken yang telah dikirimkan. Kemudian Tekan tombol (Kirim)",
         "Jika telah dikirim maka Kode Billing MPN telah terbayarkan",
      ]
   }
];

export const atmMethod = [
   { name: 'BCA',
      img: bank_bca,
      steps: [
         "Masukkan kartu dan PIN ATM BCA",
         "Pada menu Utama, pilih Transaksi Lainnya lalu pilih Pembayaran",
         "Pada menu Pembayaran, pilih MPN/Pajak lalu pilih Penerimaan Negara",
         "Masukkan Kode Billing dan pastikan data pembayaran sudah benar",
         "Setelah konfirmasi dan pembayaran berhasil, akan terdapat struk Nomor Transaksi Penerimaan Negara (NTPN)",
      ]
   },
   { name: 'BNI',
      img: bank_bni,
      steps: [
         "Masukkan kartu dan PIN ATM BNI",
         "Pada menu utama, pilih Menu Lain",
         "Selanjutnya pilih menu Pembayaran",
         "Kemudian pilih menu Pajak/Penerimaan Negara",
         "Pilih Pajak/PNBP/BEA & Cukai",
         "Masukkan Kode Billing yang akan dibayar",
         "Setelah dipastikan kebenarannya, dapat dilakukan proses pembayaran dengan pilih tombol Ya",
      ]
   },
   { name: 'CIMB Niaga',
      img: bank_cimb,
      steps:[
         "Saat di layar pertama, pilih menu Pembayaran",
         "Pilih menu Lanjut",
         "Pilih menu Fasilitas Umum (Pajak, Perusahaan Air)",
         "Pilih menu Penerimaan Negara",
         "Pilih Jenis Layanan Penerimaan Negara",
         "Masukkan Kode Billing yang terdiri dari 15 digit.",
         "Pilih Rekening Sumber Dana.",
         "Klik Ok pada tampilan layar konfirmasi pembayaran",
      ]
   },
   { name: 'Mandiri',
      img: bank_mandiri,
      steps: [
         "Masukkan kartu debit Mandiri dan masukkan PIN",
         "Pada menu Utama, pilih menu Bayar/Beli",
         "Pada menu Bayar/Beli, pilih menu Penerimaan Negara lalu pilih Pajak",
         "Masukkan ID/Kode Billing dan konfirmasi data pembayaran",
         "Setelah transaksi berhasil, mesin akan Nomor Transaksi Penerimaan Negara (NTPN)",
      ]
   },
   { name: 'BRI',
      img: bank_bri,
      steps: [
         "Pilih Bahasa yang disediakan",
         "Tekan Lanjutkan",
         "Masukkan PIN",
         "Pilih menu Transaksi Lain",
         "Pilih Pembayaran",
         "Pilih menu Lainnya",
         "Pilih menu MPN",
         "Masukkan 15 digit kode billing",
         "Konfirmasi pembayaran",
      ],
   }
];

export const mobileBankingMethod = [
   { name: 'BNI',
      img: bank_bni,
      steps: [
         "Pilih menu Pembayaran",
         "Pilih menu Penerimaan Negara",
         "Masukkan Kode Billing",
         "Verifikasi pembayaran dengan password",
      ]
   },
   { name: 'Mandiri',
      img: bank_mandiri,
      steps: [
         "Buka dan login aplikasi Livin by Mandiri",
         "Pilih menu Bayar",
         "Pilih menu Pajak",
         "Pilih menu Pajak/PNBP/Cukai",
         "Masukkan Kode Billing yang akan dibayarkan",
         "Konfirmasi pemesanan pastikan sesuai dengan pemesanan",
         "Klik Lanjut Bayar",
         "Masukkan PIN Livin’ mobile banking ",
         "Konfirmasi pembayaran",


      ]
   },
   { name: 'BRI',
      img: bank_bri,
      steps: [
         "Pilih menu Transaksi Lainnya",
         "Pilih menu Pajak dan Retribusi",
         "Pilih menu Penerimaan Negara",
         "Masukkan 15 digit Kode Billing",
         "Tekan Selanjutnya",
         "Konfirmasi pembayaran pastikan sesuai dengan pesanan",
      ]
   },
   { name: 'BSI',
      img: bank_bsi,
      steps: [
         "Pilih menu Pembayaran",
         "Pilih menu Penerimaan Negara (MPN)",
         "Pilih menu Pajak/Cukai/SBN/Paspor",
         "Masukkan Kode Billing",
         "Tekan Selanjutnya",
         "Konfirmasi pembayaran pastikan sesuai dengan pesanan",
      ]
   }
];