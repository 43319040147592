import React from 'react';

const refAperd = {
    gender: [
        { key: 'male', value: "1", flag: 'male', text: 'Male' },
        { key: 'female', value: "2", flag: 'female', text: 'Female' },
    ],
    education: [
        { key: 'primaryschool', value: "1", flag: 'primaryschool', text: 'Primary School' },
        { key: 'juniorhighschool', value: "3", flag: 'juniorhighschool', text: 'Junior High School' },
        { key: 'seniorhighschool', value: "3", flag: 'seniorhighschool', text: 'Senior High School' },
        { key: 'diploma', value: "4", flag: 'diploma', text: 'Diploma' },
        { key: 'undergraduate', value: "5", flag: 'undergraduate', text: 'Under Graduate' },
        { key: 'postgraduate', value: "6", flag: 'postgraduate', text: 'Post Graduate' },
        { key: 'doctoralprogram', value: "7", flag: 'doctoralprogram', text: 'Doctoral Program' },
        { key: 'others', value: "8", flag: 'others', text: 'Others' },
    ],
    religion: [
        { key: 'islam', value: "1", flag: 'islam', text: 'Islam' },  
        { key: 'protestant', value: "2", flag: 'protestant', text: 'Protestant' },  
        { key: 'catholic', value: "3", flag: 'catholic', text: 'Catholic' },  
        { key: 'hinduism', value: "4", flag: 'hinduism', text: 'Hinduism' },  
        { key: 'budhism', value: "5", flag: 'budhism', text: 'Budhism' },  
        { key: 'confucianism', value: "6", flag: 'confucianism', text: 'Confucianism' },  
        { key: 'others', value: "7", flag: 'others', text: 'Others' },  
    ],
    income: [
        { key: '10million', value: "1", flag: '10million', text: '< 10 Million/year' },  
        { key: '50million', value: "2", flag: '50million', text: '> 10 - 50 Million/year' },  
        { key: '100million', value: "3", flag: '100million', text: '> 50 - 100 Million/year' },  
        { key: '500million', value: "4", flag: '500million', text: '> 100 - 500 Million/year' },  
        { key: '1billion', value: "5", flag: '1billion', text: '> 500 - 1 Billion/year' },  
        { key: 'morebillion', value: "1", flag: 'morebilion', text: '> 1 Billion/year' },  
    ],
    martial: [
        { key: 'single', value: "1", flag: 'single', text: 'Single' },  
        { key: 'maried', value: "2", flag: 'maried', text: 'Maried' },  
        { key: 'divorce', value: "3", flag: 'divorce', text: 'Divorce' },  
    ],
    investment: [
        { key: 'gainmargin', value: "1", flag: 'gainmargin', text: 'Gain from price margin' },  
        { key: 'investment', value: "2", flag: 'investment', text: 'Investment' },  
        { key: 'speculation', value: "3", flag: 'speculation', text: 'Speculation' },  
        { key: 'obtainrevenue', value: "4", flag: 'obtainrevenue', text: 'Obtain the revenue or income' },  
        { key: 'others', value: "5", flag: 'others', text: 'Others' },  
    ],
    revenue: [
        { key: 'revenue', value: "1", flag: 'revenue', text: 'Revenue' },  
        { key: 'businessprofit', value: "2", flag: 'businessprofit', text: 'Business Profit' },  
        { key: 'savinginterest', value: "3", flag: 'savinginterest', text: 'Saving Interest' },  
        { key: 'legacy', value: "4", flag: 'legacy', text: 'Legacy' },  
        { key: 'fundparents', value: "5", flag: 'fundparents', text: 'Fund from parents or children' },  
        { key: 'grant', value: "6", flag: 'grant', text: 'Grant' },  
        { key: 'fundhusband', value: "7", flag: 'fundhusband', text: 'Fund from husband' },  
        { key: 'drawing', value: "8", flag: 'drawing', text: 'Drawing' },  
        { key: 'investmentgain', value: "9", flag: 'investmentgain', text: 'Investment Gain' },  
        { key: 'others', value: "10", flag: 'others', text: 'Others' },  
    ],
    city: [
        { key: 'bandung', value: '3204', flag: 'bandung', text: 'Bandung' },  
        { key: 'cimahi', value: '3277', flag: 'cimahi', text: 'Cimahi' },  
    ],
    province: [
        { key: 'ACEH', value: "11", flag: 'ACEH', text: 'ACEH' },  
        { key: 'SUMATERA_UTARA', value: '12', flag: 'SUMATERA_UTARA', text: 'SUMATERA UTARA' },  
        { key: 'SUMATERA_BARAT', value: '13', flag: 'SUMATERA_BARAT', text: 'SUMATERA BARAT' },
        { key: 'RIAU', value: '14', flag: 'RIAU', text: 'RIAU' },
        { key: 'JAMBI', value: '15', flag: 'JAMBI', text: 'JAMBI' },
        { key: 'SUMATERA SELATAN', value: '16', flag: 'SUMATERA SELATAN', text: 'SUMATERA SELATAN' },
        { key: 'BENGKULU', value: '17', flag: 'BENGKULU', text: 'BENGKULU' },
        { key: 'LAMPUNG', value: '18', flag: 'LAMPUNG', text: 'LAMPUNG' },
        { key: 'KEPULAUAN_BANGKA_BELITUNG', value: '19', flag: 'KEPULAUAN_BANGKA_BELITUNG', text: '	KEPULAUAN BANGKA BELITUNG' },
        { key: 'KEPULAUAN_RIAU', value: '21', flag: 'KEPULAUAN_RIAU', text: 'KEPULAUAN RIAU' },
        { key: 'DKI_JAKARTA', value: '31', flag: 'DKI_JAKARTA', text: 'DKI JAKARTA' },
        { key: 'JAWA_BARAT', value: '3', flag: 'JAWA_BARAT', text: 'JAWA BARAT' },
        { key: 'JAWA_TENGAH', value: '33', flag: 'JAWA_TENGAH', text: 'JAWA TENGAH' },
        { key: 'DAERAH_ISTIMEWA_YOGYAKARTA', value: '34', flag: 'DAERAH_ISTIMEWA_YOGYAKARTA', text: 'DAERAH ISTIMEWA YOGYAKARTA' },
        { key: 'JAWA_TIMUR', value: '35', flag: 'JAWA_TIMUR', text: 'JAWA TIMUR' },
        { key: 'BANTEN', value: '36', flag: 'BANTEN', text: 'BANTEN' },
        { key: 'BALI', value: '51', flag: 'BALI', text: 'BALI' },
        { key: 'NUSA_TENGGARA_BARAT', value: '52', flag: 'NUSA_TENGGARA_BARAT', text: 'NUSA TENGGARA BARAT' },
        { key: 'NUSA_TENGGARA_TIMUR', value: '53', flag: 'NUSA_TENGGARA_TIMUR', text: 'NUSA TENGGARA TIMUR' },
        { key: 'KALIMANTAN_BARAT', value: '61', flag: 'KALIMANTAN_BARAT', text: 'KALIMANTAN BARAT' },
        { key: 'KALIMANTAN_TENGAH', value: '62', flag: 'KALIMANTAN_TENGAH', text: 'KALIMANTAN TENGAH' },
        { key: 'KALIMANTAN_SELATAN', value: '63', flag: 'KALIMANTAN_SELATAN', text: 'KALIMANTAN SELATAN' },
        { key: 'KALIMANTAN_TIMUR', value: '64', flag: 'KALIMANTAN_TIMUR', text: 'KALIMANTAN TIMUR' },
        { key: 'KALIMANTAN_UTARA', value: '65', flag: 'KALIMANTAN_UTARA', text: 'KALIMANTAN UTARA' },
        { key: 'SULAWESI_UTARA', value: '71', flag: 'SULAWESI_UTARA', text: 'SULAWESI UTARA' },
        { key: 'SULAWESI_TENGAH', value: '72', flag: 'SULAWESI_TENGAH', text: 'SULAWESI TENGAH' },
        { key: 'SULAWESI_SELATAN', value: '73', flag: 'SULAWESI_SELATAN', text: 'SULAWESI SELATAN' },
        { key: 'SULAWESI_TENGGARA', value: '74', flag: 'SULAWESI_TENGGARA', text: 'SULAWESI TENGGARA' },
        { key: 'GORONTALO', value: '75', flag: 'GORONTALO', text: 'GORONTALO' },
        { key: 'SULAWESI_BARAT', value: '76', flag: 'SULAWESI_BARAT', text: 'SULAWESI BARAT' },
        { key: 'MALUKU', value: '81', flag: 'MALUKU', text: 'MALUKU' },
        { key: 'MALUKU_UTARA', value: '82', flag: 'MALUKU_UTARA', text: 'MALUKU UTARA' },
        { key: 'PAPUA', value: '91', flag: 'PAPUA', text: 'PAPUA' },
        { key: 'PAPUA_BARAT', value: '92', flag: 'PAPUA_BARAT', text: 'PAPUABARAT' },
    ],
    mandatoryField: ["gender", "education", "religion", "income_level", "marital_status", "obj_invest", "fund_source", "correspond_address", "province", "correspond_city_code"],
    payment: [
        { value:'1', paymentcode: 'Bank Transfer'},
        { value:'2', paymentcode: 'Credit Card'},
        { value:'3', paymentcode: 'Virtual Account'},
        { value:'4', paymentcode: 'QRIS'},
        { value:'5', paymentcode: 'e-Wallet'},
    ],
    bank: [
        { value:'1', bankcode: 'Bank BCA'},
        { value:'2', bankcode: 'Bank BNI'},
        { value:'3', bankcode: 'Bank BRI'},
        { value:'4', bankcode: 'Bank Mandiri'},
        { value:'5', bankcode: 'Bank Danamon'},
    ],
    trxType: {"1": "Subscription", "2": "Redemption", "3": "Switching"},
    trxStatus: {'0': 'order', '1': 'success', '2': 'rejected'},
    mutualFundType: [
        { id: 1, value: "Saham", code: "Saham", saham: "Saham", desc: 'reksanadana' },
        { id: 2, value: "Pasar Uang", code: "Pasar Uang",saham: "Pasar Uang", desc: 'reksanadana' },
        { id: 3, value: "Campuran", code: "Campuran", saham: "Campuran", desc: 'reksanadana' },
        { id: 4, value: "Pendapatan Tetap", code: "Pendapatan Tetap", saham: "Pendapatan Tetap", desc: 'reksanadana' },
    ],
    periode: [
        // { value: '1M', label: '1 Bulan'},
        // { value: '2M', label: '2 Bulan'},
        { value: '3M', label: '3 Bulan'},
        // { value: '4M', label: '4 Bulan'},
        // { value: '5M', label: '5 Bulan'},
        { value: '6M', label: '6 Bulan'},
        // { value: '7M', label: '7 Bulan'},
        // { value: '8M', label: '8 Bulan'},
        { value: '9M', label: '9 Bulan'},
        // { value: '10M', label: '10 Bulan'},
        // { value: '11M', label: '11 Bulan'},
        { value: '1Y', label: '1 Tahun'},
        { value: '5Y', label: '5 Tahun'},
    ],
    termAndCondition: {
        Subscription: [
            <>Transaction under 11 A.M<br/> Processed by using NAV/Unit on the same trading day (H+0).</>,
            <>Transaction above 11 A.M<br/> Processed by using NAV/Unit on the next trading day (H+1).</>,
            <>Bank charges will be charged to investor account, if investor account different from mutual fund account at the custodian bank.</>,
            // <>Transaksi yang dilakukan sebelum pukul 11.00 WIB,<br/> akan diproses berdasarkan Nilai Aktiva Bersih (NAB) yang ditetapkan pada akhir hari bursa yang bersangkutan/hari yang sama (H+0).</>,
            // <>Transaksi yang dilakukan setelah pukul 11.00 WIB,<br/> akan diproses berdasarkan Nilai Aktiva Bersih (NAB) yang ditetapkan pada akhir hari Bursa berikutnya/keesokan hari (H+1).</>,
        ],
        Redemption: [
            <>Transaction under 11 A.M<br/> Processed by using NAV/Unit on the same trading day (H+0).</>,
            <>Transaction above 11 A.M<br/> Processed by using NAV/Unit on the next trading day (H+1).</>,
            <>Cash will be disbursed to your investor account within 2-7 trading days, depending on the type of mutual fund and directly disbursed by the custodian bank.</>,
            <>Bank charges will be charged to investor account by custodian bank, if investor account different from custodian bank account.</>,
            // <>
            //     Harga Reksa Dana,<br/>
            //     Harga jual anda akan menggunakan harga/unit pada hari yang sama, jika dilakukan sebelum pukul 11.00 WIB.<br/>
            //     Harga jual anda akan menggunakan harga/unit pada hari bursa berikutnya, jika dilakukan setelah pukul 11.00 WIB.
            // </>,
            // <>
            //     Durasi Pencairan Reksa Dana<br/>
            //     Dana akan cair ke Rekening Dana Nasabah anda dalam 2-7 hari bursa, tergantung jenis reksa dana yang dicairkan.<br/>
            //     Distribusi Hasil pencairan Reksa Dana tersebut,di kirimkan langsung oleh Bank Kustodi/Bank Penampung ke Rekening Dana Nasabah anda.
            // </>,
            // <>
            //     Biaya Transfer antar Bank<br/>
            //     Jika Rekening Dana Nasabah anda berbeda dengan Bank Kustodi/Bank Penampung, maka akan dikenakan biaya transfer oleh pihak bank.
            // </>
        ],
        Switching: [
            <>Transaction under 11 A.M<br/> Processed by using NAV/Unit on the same trading day (H+0).</>,
            <>Transaction above 11 A.M<br/> Processed by using NAV/Unit on the next trading day (H+1).</>,
            <>Switching is subscription transaction to other mutual fund from the mutual fund product that you own and can only be done on one same Investment Manager.</>,
            // <>Switching (pengalihan) Reksa dana adalah mengalihkan produk reksa dana yang anda miliki, ke produk reksa dana lainnya dan hanya dapat dilakukan pada 1 (satu) Manajer Investasi yang sama</>
        ],
        "Stamp Duty": [
            `Based on the regulations stated in Law No. 10 of 2020 concerning Stamp Duty (State Stamp \n
                Duty Law). The government stipulates massive Stamp Duty with a fixed rate of IDR 10,000 (ten \n
                thousand rupiahs) per day on mutual fund transaction documents with a total transaction value of \n
                all APERD (Mutual Fund Selling Agent) above IDR 10,000,000 (ten million rupiahs).`,
            <>Documents for mutual fund transactions referred to in this case are documents for subscription, redemption, and switching (switch-in/switch-out) Mutual Fund products.</>,
            <>The calculation of Stamp Duty is carried out directly by the Indonesian Central Securities Depository (KSEI) proportionally.</>,
            <>Stamp Duty Fees will be charged to the client the following month after the client had a transaction.</>,
            <>The stamp duty fee is debited by Bahana Sekuritas from the Investor Account (RDN).</>
        ]
    }
} 

export {
    refAperd
}