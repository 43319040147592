import React from "react";
import {AgGridReact} from "ag-grid-react";
import { Popup } from "semantic-ui-react";

import { ContextConnector } from "../../appcontext";
import { WSConnectionAction } from "../../appnetwork";
import { BIPSAppContext } from "../../AppData";
import { AppFrameAction } from '../../appframe.js';

import { ModalSbnCodeDetail, ModalRedeemEsbn } from "./index";
import { ResizeResponsive, comparatorType, fmsg, thousandSeparator, mathCall, dummy, timeCall } from '../../utils';
import moment from "moment";

class DetailEarlyRedemption_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: true, filter: "agNumberColumnFilter",
                    width: 50, minWidth: 50, 
                    comparator: comparatorType["text"],
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    },
                    cellRenderer: 'noRenderer'
                },
                { field: "kode_pemesanan", headerName: "Kode Pemesanan", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?200:s=="s67"?200:s=="s75"?200:s=="s80"?200:200, minWidth: 200, 
                    comparator: comparatorType["text"],
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    }, suppressSizeToFit: true
                },
                { field: "seri", headerName: "Seri SBN", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?240:s=="s50"?200:s=="s67"?150:s=="s75"?140:110, minWidth: 110, 
                    comparator: comparatorType["text"],
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    },
                    suppressSizeToFit: true
                },
                { field: "tgl_pemesanan", headerName: "Tanggal Pemesanan", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, sort:'desc',
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?210:210, minWidth: 210,  
                    comparator: comparatorType["text"],
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    } },
                { field: "nominal", headerName: "Nominal  (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?200:s=="s75"?160:130, minWidth: 130, 
                    comparator: comparatorType["integer"],
                    valueFormatter: (params) => params.value && thousandSeparator(params.value),
                    cellClass : function (params) {
                        return " grid-table text-right f-12 d-border-aggrid-right";
                    }},
                { field: "kepemilikan_sisa", headerName: "Sisa Nominal Kepemilikan (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?250:250, minWidth: 230, 
                    comparator: comparatorType["integer"],
                    valueFormatter: (params) => params.value && thousandSeparator(params.value),
                    cellClass : function (params) {
                        return " grid-table text-right f-12 d-border-aggrid-right";
                    }},
                { field: "sisa_pencairan", headerName: "Nominal Pencairan Awal (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?300:s=="s80"?250:s=="s85"?250:s=="s90"?250:250, minWidth: 250, 
                    comparator: comparatorType["integer"],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass : function (params) {
                        return " grid-table text-right f-12 d-border-aggrid-right";
                    }},
                // { field: "tingkat_kupon", headerName: "Tingkat Kupon (%)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                //     width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?230:230, minWidth: 230, 
                //     comparator: comparatorType["text"],
                //     valueFormatter: ({ value }) => mathCall.setRupiah(value, 3, '0.000 %', ' %'),
                //     cellClass : function (params) {
                //         return " grid-table text-center f-12 d-border-aggrid-right";
                //     } },
                // { field: "tgl_kupon", headerName: "Tanggal Pembayaran Kupon", sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?250:250, minWidth: 250, 
                //     comparator: comparatorType["text"],
                //     cellClass : function (params) {
                //         return " grid-table text-right f-12 d-border-aggrid-right";
                //     } },
                { field: "tgl_jatuh_tempo", headerName: "Tanggal Jatuh Tempo", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?250:s=="s90"?250:250, minWidth: 200, 
                    comparator: comparatorType["text"],
                    valueFormatter: ({ value }) => value && timeCall.todate2(value, " "),
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    } },
                { field: "early_redemption_button", headerName: "Pencairan Awal", width: 140, minWidth: 140,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center locked-col locked-visible d-border-aggrid-right";
                    },
                    cellRenderer: 'reedemRenderer',
                    suppressSizeToFit: true 
                },
                { field: "detail", headerName: "Detail", width: 100, minWidth: 100,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center locked-col locked-visible d-border-aggrid-right click-pointer";
                    },
                    cellRenderer : 'detailRenderer',
                    suppressSizeToFit: true 
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({rowIndex, data}) => data.early_redemption_button ? rowIndex + 1 : "",
                reedemRenderer: ({ data }) => data.early_redemption_button ? 
                        (data.sPencairanAwal && data.sPencairanAwal != "cairkan" ?
                            data.sPencairanAwal
                        :
                            <Popup 
                                content={`Minimum pencairan ${mathCall.setRupiah(data.kelipatan_redeem)}`}
                                position='top center'
                                disabled={!data.btnDisable}
                                size='mini'
                                trigger={
                                    <button class={`btn-cellredemption btn btn-sm btn-primary mx-1 f-9 w-50 px-1 ${data.btnDisable && 'disabled'}`}
                                        onClick={()=> !data.btnDisable && this.onRedeem(data)}
                                    > Cairkan </button> 
                                } 
                            />
                        )
                    : "",
                detailRenderer: ({data}) => data.early_redemption_button ? <span class="px-1" onClick={()=>this.onDetail(data)}>
                        <i class="btn-cellredemption fa fa-search click-pointer"></i>
                    </span> : "",
                
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    onRedeem = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div style={{ borderBottom:"1px solid white" }}>
                    <div className="row py-2">
                        <div className="col-sm-6">
                            <h5 className="font-weight-bold">EARLY REDEMPTION</h5>
                            <i className="f-11">Harap periksa kembali data Anda sebelum melanjutkan ke langkah berikutnya</i>
                        </div>
                        <div className="col-sm-6 text-right">
                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={() => this.closeClick()}></i>
                        </div>
                    </div>
                </div>,
            size: 'large',
            contentClass: ModalRedeemEsbn,
            contentProps: data,
            onClose: () => this.props.resetSbnInfo()
        })
    }

    onDetail = (data) => {
        this.props.setDataHandler({'earlyRedemptiondByOrderCode': []});
        this.refs.netAction.send(fmsg.sbn_getEarlyRedemptionByOrderCode(data.kode_pemesanan));
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div style={{ borderBottom:"1px solid white" }}>
                    <div className="row py-2">
                        <div className="col-sm-6">
                            <h5 id="sbncodetitle" className="font-weight-bold">{data.seri}</h5>
                        </div>
                        <div className="col-sm-6 text-right">
                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                        </div>
                    </div>
                </div>,
            size: 'large',
            contentClass: ModalSbnCodeDetail,
            contentProps: data,
            onClose: (result) => this.props.setDataHandler({'earlyRedemptiondByOrderCode': []})
        })
    }

    render() {
        ResizeResponsive()
        return (<>
            <div className="pt-4" style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction"/>
                <WSConnectionAction ref="netAction" />
                <div
                    className={"card-intIndices ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{ width: "100%" }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.SBN_SHOW_PORTFOLIO}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight={29}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    >
                    </AgGridReact>
                </div>
            </div>
        </>);
    }
}

const DetailEarlyRedemption = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        SBN_SHOW_PORTFOLIO: vars.SBN_SHOW_PORTFOLIO,
        resetSbnInfo: () => actions.sendAction('resetSbnInfo'),
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }), ["resetSbnInfo", "setDataHandler"]
)(DetailEarlyRedemption_Base);   


export default DetailEarlyRedemption;