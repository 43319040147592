import React, { memo } from "react";

import { AppFrameAction } from "../../appframe";
import { BIPSAppContext } from "../../AppData";
import { ContextConnector } from '../../appcontext.js';

class CorpActionDetail_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        let data = this.props;
        return (
            <>
                <AppFrameAction ref="frameAction"/>
                <div className={"col-sm-12 px-0 pt-0"}>
                    <div className="col-sm-12 px-0 mx-0 text-center pt-2 pb-2 h-30 f-15 bg-tableheader">
                        Corp Action Detail Information
                    </div>
                    <table className="table tb-notation d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13">
                        <tr>
                            <th colspan="2" className="text-center f-15">
                                {data.catype}
                                &nbsp;-&nbsp;
                                {data.scripcode}
                            </th>
                        </tr>
                        <tr>
                            <td className="text-left" width="42%">Category</td>
                            <td>{`${data.ca_name} (${data.catype})`}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Code</td>
                            <td>{data.scripcode}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Record date</td>
                            <td>{data.recorddate}</td>
                        </tr>
                        {/* <tr className={(this.state.type == 0) ? "":"d-none"}>
                            <td className="text-right">Revised Date</td>
                            <td>2021-08-30 12:00:00</td>
                        </tr> */}
                    </table>
                    {
                        data.CORPORATE_ACTION_DETAIL.length > 0 && (
                            data.catype === "DIV" ?
                                <CashDividend data={data.CORPORATE_ACTION_DETAIL[0]}/>
                            : data.catype === "STOCKDIV" ?
                                <StockDividend data={data.CORPORATE_ACTION_DETAIL[0]} />
                            : data.catype === "MIX" ?
                                <CashAndStockDividend data={data.CORPORATE_ACTION_DETAIL[0]} />
                            : data.catype === "HMETD" ?
                                <RightWarrantIssue data={data.CORPORATE_ACTION_DETAIL[0]} />
                            : data.catype === "BONUS" ?
                                <BonusPage data={data.CORPORATE_ACTION_DETAIL[0]} />
                            : 
                                <SplitReverseStock data={data.CORPORATE_ACTION_DETAIL[0]} />)
                    }
                    <div className="col-sm-12 text-center">
                        <button className="btn btn-primary mt-2" onClick={() => this.refs.frameAction.closeModal(100)}>
                            Close
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

const CashDividend = memo(({ data }) => {
    return (
        <table 
            className={`table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13`}
            style={{height: "100%"}}>
            <thead className="bg-tableheader">
                <th className="text-center" colSpan="4">&nbsp;</th>
            </thead>
            <tbody>
                <tr>
                    <td>For Each QTY (Shr)</td>
                    <td className="font-weight-bold">{data.stmratios}</td>
                    <td>Dividen/Share</td>
                    <td className="font-weight-bold">{data.stmratiom}</td>
                </tr>
                <tr>
                    <td>Cum Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.cumdatergng}</td>
                    <td>Cum Date (TN)</td>
                    <td className="font-weight-bold">{data.cumdatetn}</td>
                </tr>
                <tr>
                    <td>Ex Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.exdtergng}</td>
                    <td>Ex Date (TN)</td>
                    <td className="font-weight-bold">{data.exdatetn}</td>
                </tr>
                <tr>
                    <td>Recording Date</td>
                    <td className="font-weight-bold">{data.recorddate}</td>
                    <td>Payment Date</td>
                    <td className="font-weight-bold">{data.paymentdate}</td>
                </tr>
                <tr>
                    <td colSpan="4" className="font-weight-bold">Tax</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Tax (I)</td>
                    <td className="font-weight-bold">{data.taxi}</td>
                    <td className="font-weight-bold">Tax (A)</td>
                    <td className="font-weight-bold">{data.taxa}</td>
                </tr>
            </tbody>                                                    
        </table>
    )
});

const StockDividend = memo(({ data }) => {
    return (
        <table 
            className={`table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13`}
            style={{height: "100%"}}>
            <thead className="bg-tableheader">
                <th className="text-center" colSpan="6">&nbsp;</th>
            </thead>
            <tbody>
                <tr>
                    <td>For Each QTY (Shr)</td>
                    <td className="font-weight-bold">{data.stsratio1}</td>
                    <td>Dividen/Share</td>
                    <td className="font-weight-bold">{data.stsratio2}</td>
                    <td className="text-right">Price</td>
                    <td className="text-right font-weight-bold">{data.nominalold}</td>
                </tr>
                <tr>
                    <td>Cum Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.cumdatergng}</td>
                    <td colspan="3">Cum Date (TN)</td>
                    <td className="font-weight-bold">{data.cumdatetn}</td>
                </tr>
                <tr>
                    <td>Ex Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.exdtergng}</td>
                    <td colspan="3">Ex Date (TN)</td>
                    <td className="font-weight-bold">{data.exdatetn}</td>
                </tr>
                <tr>
                    <td>Recording Date</td>
                    <td className="font-weight-bold">{data.recorddate}</td>
                    <td>Distribution Date</td>
                    <td className="font-weight-bold">{data.distributedate}</td>
                    <td>Payment Date</td>
                    <td className="font-weight-bold">{data.paymentdate}</td>
                </tr>
                <tr>
                    <td colSpan="6" className="font-weight-bold">Tax</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Tax (I)</td>
                    <td className="font-weight-bold">{data.taxi}</td>
                    <td className="font-weight-bold" colSpan="2">Tax (A)</td>
                    <td className="font-weight-bold">{data.taxa}</td>
                </tr>
            </tbody>                                                    
        </table>
    )
});

const CashAndStockDividend = memo(({ data }) => {
    return (
        <table 
            className={`table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13`}
            style={{height: "100%"}}>
            <thead className="bg-tableheader">
                <th className="text-center" colSpan="6">&nbsp;</th>
            </thead>
            <tbody>
            <tr>
                    <td>For Each QTY (Shr)</td>
                    <td className="font-weight-bold">{data.stmratios}</td>
                    <td>Dividen/Share</td>
                    <td className="font-weight-bold">{data.stmratiom}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>For Each QTY (Shr)</td>
                    <td className="font-weight-bold">{data.stsratio1}</td>
                    <td>Will Receive QTY</td>
                    <td className="font-weight-bold">{data.stsratio2}</td>
                    <td className="text-right">Price</td>
                    <td className="text-right font-weight-bold">{data.nominalold}</td>
                </tr>
                <tr>
                    <td>Cum Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.cumdatergng}</td>
                    <td colspan="3">Cum Date (TN)</td>
                    <td className="font-weight-bold">{data.cumdatetn}</td>
                </tr>
                <tr>
                    <td>Ex Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.exdtergng}</td>
                    <td colspan="3">Ex Date (TN)</td>
                    <td className="font-weight-bold">{data.exdatetn}</td>
                </tr>
                <tr>
                    <td>Recording Date</td>
                    <td className="font-weight-bold">{data.recorddate}</td>
                    <td>Payment Date</td>
                    <td className="font-weight-bold">{data.paymentdate}</td>
                    <td>Distribution Date</td>
                    <td className="font-weight-bold">{data.distributedate}</td>
                </tr>
                <tr>
                    <td colSpan="6" className="font-weight-bold">Tax</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Tax (I)</td>
                    <td className="font-weight-bold">{data.taxi}</td>
                    <td className="font-weight-bold" colSpan="2">Tax (A)</td>
                    <td className="font-weight-bold">{data.taxa}</td>
                </tr>
            </tbody>                                                    
        </table>
    )
});

const RightWarrantIssue = memo(({ data }) => {
    return (
        <>
            <table 
                className={`table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13`}
                style={{height: "100%"}}>
                <thead className="bg-tableheader">
                    <th className="text-center" colSpan="6">&nbsp;</th>
                </thead>
                <tbody>
                <tr>
                        <td>For Each QTY (Shr)</td>
                        <td className="font-weight-bold">{data.rtsratios}</td>
                        <td>Will Receive HMDETD (Shr)</td>
                        <td className="font-weight-bold">{data.rtsratior}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>For Each HMDETD (Shr)</td>
                        <td className="font-weight-bold">{data.rtsratios}</td>
                        <td>Can Buy Qty (Shr)</td>
                        <td className="font-weight-bold">{data.rtsratior}</td>
                        <td>At Price</td>
                        <td className="text-right font-weight-bold">{data.rtsprice}</td>
                    </tr>
                    <tr>
                        <td>For Each New Qty (Shr)</td>
                        <td className="font-weight-bold">{data.rtwratior}</td>
                        <td>Will Receive Warrant (Shr)</td>
                        <td className="font-weight-bold">{data.rtwratiow}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>For Each Warrant (Shr)</td>
                        <td className="font-weight-bold">{data.wtsratiow}</td>
                        <td>Can Buy Qty (Shr)</td>
                        <td className="font-weight-bold">{data.wtsratios}</td>
                        <td>At Price</td>
                        <td className="font-weight-bold text-right">{data.wtsprice}</td>
                    </tr>
                    <tr>
                        <td>Recording Date</td>
                        <td className="font-weight-bold">{data.recorddate}</td>
                        <td>Payment Date</td>
                        <td className="font-weight-bold">{data.paymentdate}</td>
                        <td>Distribution Date</td>
                        <td className="font-weight-bold">{data.distributedate}</td>
                    </tr>
                </tbody>                                                    
            </table>
            <div class="row mx-0">
                <table 
                    className="col-sm-6 table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13">
                    <thead className="bg-tableheader">
                        <th className="text-center" colSpan="2">RIGHT</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Right Code</td>
                            <td>{data.rightcode}</td>
                        </tr>
                        <tr>
                            <td>ISIN Code</td>
                            <td>{data.isincoderight}</td>
                        </tr>
                        <tr>
                            <td>HMETD Listing Date</td>
                            <td>{data.listingdate}</td>
                        </tr>
                        <tr>
                            <td>HMETD Trading Period</td>
                            <td>{data.righttradedate1}</td>
                        </tr>
                        <tr>
                            <td>HMETD Exercise Period</td>
                            <td>{data.rightexercisedate1}</td>
                        </tr>
                        <tr>
                            <td>Distribute Exercise Period</td>
                            <td>{data.distributeexecdate1}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                <table 
                    className="col-sm-6 table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13">
                    <thead className="bg-tableheader">
                        <th className="text-center" colSpan="2">WARRANT</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Warrant Code</td>
                            <td>{data.warrantcode}</td>
                        </tr>
                        <tr>
                            <td>ISIN Code</td>
                            <td>{data.isincodewarrant}</td>
                        </tr>
                        <tr>
                            <td>Start Date Trade</td>
                            <td>{data.warranttradedate1}</td>
                        </tr>
                        <tr>
                            <td>End Trade Date (RG&NG)</td>
                            <td>{data.warranttradedatergng2}</td>
                        </tr>
                        <tr>
                            <td>End Trade Date (TN)</td>
                            <td>{data.warranttradedatetn2} </td>
                        </tr>
                        <tr>
                            <td>Start Exercise Date</td>
                            <td>{data.warrantexercisedate1}</td>
                        </tr>
                        <tr>
                            <td>End Exercise Date</td>
                            <td>{data.warrantexercisedate2}</td>
                        </tr>
                        <tr>
                            <td>Expire Date</td>
                            <td>{data.warrantexpiredate}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
});

const SplitReverseStock = memo(({ data }) => {
    return (
        <div class="row mx-0">
            <table 
                className="col-sm-6 table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13">
                <thead className="bg-tableheader">
                    <th className="text-center" colSpan="2">OLD</th>
                </thead>
                <tbody>
                    <tr>
                        <td>For Each Qty (Shr)</td>
                        <td>{data.stsratio1}</td>
                    </tr>
                    <tr>
                        <td>Nominal</td>
                        <td>{data.nominalold}</td>
                    </tr>
                    <tr>
                        <td>End Trade Date (RG/NG)</td>
                        <td>{data.cumdatergng}</td>
                    </tr>
                    <tr>
                        <td>End Trade Date (TN)</td>
                        <td>{data.recorddate}</td>
                    </tr>
                    <tr>
                        <td>End Settlement Date</td>
                        <td>{data.settlementdate2}</td>
                    </tr>
                </tbody>
            </table>
            <table 
                className="col-sm-6 table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13">
                <thead className="bg-tableheader">
                    <th className="text-center" colSpan="2">NEW</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Will Be Reserve To (Shr)</td>
                        <td>{data.stsratio2}</td>
                    </tr>
                    <tr>
                        <td>Nominal</td>
                        <td>{data.nominalnew}</td>
                    </tr>
                    <tr>
                        <td>Start Trade Date (RG/NG)</td>
                        <td>{data.exdtergng}</td>
                    </tr>
                    <tr>
                        <td>Start Trade Date (TN)</td>
                        <td>{data.distributedate}</td>
                    </tr>
                    <tr>
                        <td>Start Settlement Date</td>
                        <td>{data.settlementdate1}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
});

const BonusPage = memo(({ data }) => {
    return (
        <table 
            className={`table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13`}
            style={{height: "100%"}}
        >
            <thead className="bg-tableheader">
                <th className="text-center" colSpan="4">&nbsp;</th>
            </thead>
            <tbody>
                <tr>
                    <td width="42%" className="text-right">For Each Qty. (Shr)</td>
                    <td className="font-weight-bold">{data.stsratio1}</td>
                </tr>
                <tr>
                    <td className="text-right">Will Receive Qty. (Shr)</td>
                    <td className="font-weight-bold">{data.stsratio2}</td>
                </tr>
                <tr>
                    <td className="text-right">Round</td>
                    <td className="font-weight-bold"></td>
                </tr>
                <tr>
                    <td className="text-right">Cum Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.cumdatergng}</td>
                </tr>
                <tr>
                    <td className="text-right">Ext Date (RG&NG)</td>
                    <td className="font-weight-bold">{data.exdtergng}</td>
                </tr>
                <tr>
                    <td className="text-right">Recording Date</td>
                    <td className="font-weight-bold">{data.recorddate}</td>
                </tr>
                <tr>
                    <td className="text-right">Cum Date (TN)</td>
                    <td className="font-weight-bold">{data.cumdatetn}</td>
                </tr>
                <tr>
                    <td className="text-right">Ex Date (TN)</td>
                    <td className="font-weight-bold">{data.exdatetn}</td>
                </tr>
                <tr>
                    <td className="text-right">Distribution Date</td>
                    <td className="font-weight-bold">{data.distributedate}</td>
                </tr>
            </tbody>                                                    
        </table>
    )
});

const CorpActionDetail = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        CORPORATE_ACTION_DETAIL: vars.CORPORATE_ACTION_DETAIL || [],
    }),
)(CorpActionDetail_Base);

export default CorpActionDetail;