const apiList = {
    AWSNew: {
        url: "ws://34.87.125.180:12000",
        url2: "ws://34.87.125.180:5050",
        api: "http://34.87.125.180:5050/",
        reconnect: 1000
    },
    GCP: {
        url: "wss://bahana.ihsansolusi.co.id:12000",
        url2: "wss://bahana.ihsansolusi.co.id:5050",
        api: "https://bahana.ihsansolusi.co.id:5050/",
        reconnect: 1000
    },
    VM10: {
        url: "ws://10.1.9.10:12000",
        url2: "ws://10.1.9.10:5050",
        api: "http://10.1.9.10:5050/",
        reconnect: 1000
    },
    VM10_new: {
        url: "ws://10.1.10.10:12000",
        url2: "ws://10.1.10.10:5050",
        api: "http://10.1.10.10:5050/",
        reconnect: 1000
    },
    DXSIMULATOR: {
        url: "wss://dxsimulator.bahana.co.id:12000",
        url2: "wss://dxsimulator.bahana.co.id:5050",
        api: "https://dxsimulator.bahana.co.id:5050/",
        reconnect: 1000
    },
    DXTRADE: {
        url: "wss://dxtrade.bahana.co.id:12000",
        url2: "wss://dxtrade.bahana.co.id:5050",
        api: "https://dxtrade.bahana.co.id:5050/",
        reconnect: 1000
    },
    DXTRADE2: {
        url: "wss://dxtrade2.bahana.co.id:12000",
        url2: "wss://dxtrade2.bahana.co.id:5050",
        api: "https://dxtrade2.bahana.co.id:5050/",
        reconnect: 1000
    },
    DRC: {
        url: "wss://drc-dxtrade.bahana.co.id:12000",
        url2: "wss://drc-dxtrade.bahana.co.id:5050",
        api: "https://drc-dxtrade.bahana.co.id:5050/",
        reconnect: 1000
    },
    vm172: {
        url: "ws://172.17.101.11:12000",
        url2: "ws://172.17.101.11:5050",
        api: "http://172.17.101.11:5050/",
        reconnect: 1000
    },
    dev_hthbg: {
        url: "wss://dev-hthbg.bahana.co.id:12000",
        url2: "wss://dev-hthbg.bahana.co.id:5050",
        api: "https://dev-hthbg.bahana.co.id:5050",
        reconnect: 1000
    }
}
const baseAPI = apiList.DXTRADE2;
export const versionApp = "1.1.0+3";
export const isDev = false; // aws ? true : false

const pageStatus = {
    esbn: true,
    aperd: true
}

const initSubscriptionFlags = {
    stockSummary: false,
    portfolio: false,
    runningTrace: false,

    /** My Account */
    landingPage: false,
    landingPageInvboard: false,
    stockCashPageInvboard: false,
    tradeListHistoryPageInvboard: false,
    fundTransferPageInvboard: false,
    inquryAccountPageInvboard: false,
    InvboardTcAndSoa: false,
    tradePLPageInvboard: false,
    WarrantRightPageInvboard: false,

    /** Market & Statistic */
    marketstatistikPage: false,
    marketStatistikPage: false,
    statisticMarketStatistikPage: false,
    indiceMarketStatistikPage: false,
    nonSectoralStatistikPage: false,
    indiceMarketSecondStatistikPage: false,
    topBrokerMarketStatistikPage: false,
    intIndicesMarketStatistikPage: false,
    currencyCommodityMarketStatistikPage: false,  
    // tree for sub Header News & Reseacrh in market&Statistic Page
    newsGeneral: false,
    newsStock: false,
    newsMutualFund: false,

    /** BROKER PAGE */
    brokerPage: false,
    brokerInfo: false,
    brokerTradeSummary: false,
    brokerTradeHistory: false,
    brokerTopListPage: false,
    
    /** STOCK PAGE */
    stockPage: false,
    stockInfoPage: false, 
    stockFinancialStatement: false,   
    stockWatchlistPage: false,
    stockHistoryPage: false,
    stockTradeSummaryPage: false,
    stockSpecialNotation: false,
    // tree for sub Header Stock Info in Stock Page
    stockInfoTable: false,
    dailyTechnicalTable: false,
    profilTable: false,
    stockCorpAction: false,
    
    /** TRADE PAGE */
    tradePage: false,
    tradePageManOrderbook: false,
    tradePageManWatchlist: false,
    tradePagePL: false,
    tradePageOrderBookList: false,
    AutOrderSetting: false,
    AutSentOrder: false,
    AutExpOrder: false,
    tradePageAdv: false,

    /** ANALITIC */
    analyticPage: false,
    StockAnalyticPage: false,
    AnalyticPage: false,
    IndiceAnalyticPage: false,
    RelativePerformanceAnalyticPage: false,

    /** LIVE TRADE */
    livetradePage: false,

    /** E-SBN */
    esbnPage: false,   
    EsbnPage: false,
    EsbnxPagePortfolio: false,
    EsbnxPageTransaction: false,
    EsbnxPageHistorical: false,
    EsbnTransSubscribe: false,
    EsbnTransHistorical: false,

    /** MUTUAL FUND */
    mutualfundPage: false,
    MutualRegistPage: false,
    MutualFundxPageEtalase: false,
    MutualFundxPageCompare: false,
    MutualFundxPagePortfolio: false,
    MutualFundxPageHistoris: false,
    // MutualFundxPageRoboAdvisor: false,
    // MutualFundxPageBasket: false,
    // xMutualFundMyBasket: false,
    // xMutualFundHistoryTrans: false,

    /** Chart & Support */
    chatsupportPage: false,
    ChatUserPage: false,
    ChatSuppPage: false,
    ChatCommentPage: false,
};

const sectoralIndexCode = [
    "IDXBASIC", "IDXCYCLIC", 
    "IDXENERGY", "IDXFINANCE", 
    "IDXHEALTH", "IDXINDUST", 
    "IDXINFRA", "IDXNONCYC", 
    "IDXPROPERT", "IDXTECHNO", 
    "IDXTRANS"
];

const fieldName = {
    index: [
        "index_code","name","exchange_base_val","exchange_market_val","index","open","high","low",
        "prev_index","val","vol","f_buy_val","f_sell_val","freq"
    ],
    stocksmry: [
        "stock_code", "board_code", "individual_index", "available_for_frg", "secboard_state", 
        "prev_price", "high_price", "low_price", "close_price", "change_price", "traded_val", 
        "traded_vol", "traded_freq", "open_price", "best_bid_price", "best_bid_vol", 
        "best_offer_price", "best_offer_vol", "average_price", "change_price_pcts", 
        "foreign_buy_val", "foreign_buy_vol", "foreign_sell_val", "foreign_sell_vol"
    ]
}

const dxInfo = {
    callCenter: 'Please Contact Our Call Center [14009].',
    infoLayanan: 'Silahkan hubungi dukungan layanan kami [14009].',
    LOCKED: 'UserID is temporarily disabled due to incorrect password. Please contact Customer Service.', // user locked
    MAINTENANCE: 'System is currently under maintenance. Please try again later.',
    FORCED: 'Same user id has been logged in from other situation'
}

const bankInfo = {
    BNGA: {
        "bank_code": "BNIAIDJA",
        "Nama": "Bank CIMB Niaga",
        "Id": "3"
    },
    BBCA: {
        "bank_code": "CENAIDJA",
        "Nama": "BCA (BANK CENTRAL ASIA)",
        "Id": "5"
    },
    BMRI: {
        "bank_code": "BMRIIDJA",
        "Nama": "BANK MANDIRI",
        "Id": "4"
    },
    BBNI: {
        "bank_code": "BNINIDJA",
        "Nama": "BANK BNI" /*46*/,
        "Id": "6"
    },
    BNLI: {
        "bank_code": "BBBAIDJA",
        "Nama": "BANK PERMATA",
        "Id": "10"
    },
    BBRI: {
        "bank_code": "BRINIDJA",
        "Nama": "BANK RAKYAT INDONESIA (BRI)",
        "Id": "13"
    },
    BBTN: {
        "bank_code": "BTANIDJA",
        "Nama": "BTN (Bank Tabungan Negara)",
        "Id": "103"
    }
}

export const AperdFundType = [
    {
        "fund_type_code": "SH",
        "fund_type_name": "Equity Fund",
        "id": "1"
    },
    {
        "fund_type_code": "PU",
        "fund_type_name": "Money Market",
        "id": "3"
    },
    {
        "fund_type_code": "Pendapatan Tetap",
        "fund_type_name": "Fixed Income",
        "id": "4"
    },
    {
        "fund_type_code": "Campuran",
        "fund_type_name": "Balanced Fund",
        "id": "5"
    },
    {
        "fund_type_code": "SHSY",
        "fund_type_name": "Equity Sharia",
        "id": "6"
    },
    {
        "fund_type_code": "IF",
        "fund_type_name": "Index Fund",
        "id": "7"
    }
]


export { 
    baseAPI, 
    pageStatus,
    initSubscriptionFlags, 
    sectoralIndexCode, 
    fieldName, 
    dxInfo, 
    bankInfo 
};