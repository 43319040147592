import React from "react";
import NumberFormat from 'react-number-format';
import {Input} from "semantic-ui-react";

import {BIPSAppContext} from "../../AppData";
import {AppFrameAction} from "../../appframe";
import {ContextConnector} from "../../appcontext";
import $ from "jquery";
import {WSConnectionAction} from "../../appnetwork";

import { thousandSeparator, fmsg, strCall, mathCall } from '../../utils';
import { optList, TermConditionRedemption } from "./index";

/**
 * optList adalah objek yang berisi pernyataan2 yang digunakan di ESBN 
 * seperti peryataan untuk transaksi redeem
 */

class RedeemEsbnModal_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    /*closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }*/

    state = {
        inputamountredeem: 0,
        nextcheck2:false,
        pin: '',

        active1:true,
        active2:false,
        active3:false,
        active4:false,
        active5:false,
        activeTab:1,
        next2:false,
        next3:false,
        checkform:false,
        checkconfirmation:false,
        form: { message: "" }
    }

    onClickCloseAlertDeclareRedeem = (e) => {
        $("#alert-declareredeem").removeClass("fade-in");
        $("#alert-declareredeem").addClass("fade-out");
    };

    clickRedeemAlert = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center">
                    <h3 className="text-center pt-1">Syarat dan Ketentuan Pencairan e-SBN</h3>
                </div>,
            size: 'tiny',
            contentClass: TermConditionRedemption,
            contentProps: {onClose: () => this.refs.frameAction.closeModal(100) }
        })
    }

    clickPinAlert = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center"><h3 className="text-center pt-1">Informasi PIN</h3></div>,
            size: 'mini',
            contentClass: ModalPINWrong,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    handleChangeInput = event => {
        const { value, maxLength } = event.target;
        const message = value.slice(0, maxLength);

        this.setState({
            form: {
                message
            }
        });

        if($("#inputesbnpin").val() === ""){
            this.setState({next3: false});
            $("#inputesbnpin").addClass("border-error");
            $("#req_esbnpin").removeClass("d-none");
            $("#req_esbnpin").addClass("d-block");
        } else {
            if($("#inputesbnpin").val() === "123456"){
                $("#inputesbnpin").removeClass("border-error");
                $("#req_esbnpin").removeClass("d-block");
                $("#req_esbnpin").addClass("d-none");
                this.setState({next3: true});
            } else {
                $("#inputesbnpin").removeClass("border-error");
                $("#req_esbnpin").removeClass("d-block");
                $("#req_esbnpin").addClass("d-none");
                this.setState({next3: false});
            }
        }
    };

    nextHandler = (tab) => {
        let p = this.props, s = this.state;
        if(tab === 1){
            this.setState({ ...s, active1: true});
            p.setDataHandler({"sbnInfo": {...p.sbnInfo, tab: tab}})
        }else if(tab === 2){
            if(s.inputamountredeem < Number(p.min_redeem)){
                p.handleStatusAlert3('noConfirm', true, `Minimum pencairan Rp. ${thousandSeparator(p.min_redeem)}`)
            }else if(Number(s.inputamountredeem) > Number(p.sisa_pencairan)){
                p.handleStatusAlert3('noConfirm', true, "Maksimal pencairan Rp. " + thousandSeparator(Number(p.sisa_pencairan)))
                // p.handleStatusAlert3('noConfirm', true, "Reach maximum early redemption. Rp. " + thousandSeparator(Number(p.sisa_pencairan)))
            }else if(Number(s.inputamountredeem) % p.kelipatan_redeem !== 0){
                p.handleStatusAlert3('noConfirm', true, "Kelipatan pencairan Rp. " + thousandSeparator(p.kelipatan_redeem))
                // p.handleStatusAlert3('noConfirm', true, "multiples of redeem Rp. " + thousandSeparator(p.kelipatan_redeem))
            }else if(!s.nextcheck2){
                p.handleStatusAlert3('noConfirm', true, "Silahkan periksa daftar pernyataan")
            }else{
                this.setState({ ...s, active2: true});
                p.setDataHandler({"sbnInfo": {...p.sbnInfo, tab: tab}})
            }
        }else if(tab === 3){
            if(s.pin === ""){
                this.props.handleStatusAlert3('noConfirm', true, 'Silahkan masukan pin Anda');
            }else{
                p.setDataHandler({"onLoading": true});
                let params = {
                    "order_code": p.kode_pemesanan,
                    "sid": p.userInfo.sid,
                    "amount": s.inputamountredeem
                }
                p.setDataHandler({"sbnInfo": {...p.sbnInfo, params}});
                this.refs.netAction.send(fmsg.checkPin(s.pin));
            }
        }
    }

    closeClickRedeem = (e) => {
        let { userInfo, id_seri } = this.props;
        this.refs.netAction.send(fmsg.sbn_showPortfolio(userInfo.sid, id_seri));
        this.refs.frameAction.closeModal(100);
    }

    render() {
        let p = this.props, s = this.state;

        return (
            <>
                <WSConnectionAction ref="netAction" />
                <AppFrameAction ref="frameAction" />
                <div id="alert-declareredeem" className={"col-sm-2 text-center fade-out f-12"} style={{ position : "absolute", zIndex : 1, background: "brown", bottom: "35px", left: "35px" }}>
                    <div className={/*{cssmode == 'night'? */"px-2 pt-2 text-right text-white" /*: "px-2 pt-2 text-right text-black"*/}><i className="click-pointer icofont icofont-close" onClick={this.onClickCloseAlertDeclareRedeem}></i></div>
                    <div id="content-alertdeclareredeem" className={"px-2 py-4 text-white"}></div>
                </div>
                <div className="text-white f-12 container-fluid">
                    <div className="col align-self-center py-5">
                        <div className="step-tab step-esbn">
                            <div id="crumbs" className="w-100">
                                <ul className="nav nav-pills w-100">
                                    <li className={`step1 col-sm-form-wizard ${p.sbnInfo.tab === 1 && "active "} ${p.sbnInfo.status ? "click-disabled" : "click-pointer"}`} 
                                        onClick={() => p.sbnInfo.tab !== 1 && !p.sbnInfo.status && this.nextHandler(1)} disabled={p.sbnInfo.status}>
                                        <a className={(s.active1 ? "active " : "") + (p.sbnInfo.status ? "click-disabled" : "click-pointer")} disabled>Formulir</a>
                                    </li>
                                    <li className={`col-sm-form-wizard ${p.sbnInfo.status ? "click-disabled" : "click-pointer"} ${p.sbnInfo.tab === 2 && "active"}`} 
                                        onClick={() => (p.sbnInfo.tab !== 2 && !p.sbnInfo.status) && this.nextHandler(2)}>
                                        <a className={`${s.active2 && "active"} ${p.sbnInfo.status ? "click-disabled" : "click-pointer"}`} disabled>Konfirmasi</a>
                                    </li>
                                    <li className={`step-last col-sm-form-wizard ${p.sbnInfo.status && "click-disabled"}`} disabled={p.sbnInfo.status}>
                                        <a className={p.sbnInfo.tab === 3 && "active"} disabled>Selesai</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div></div>
                        <div className="tab-content clearfix" >
                            <div className="tab-pane active" id="redeem1">
                                <div className="container content-step">
                                    <div className="row">
                                        <div id="sbncoderedeem" className="col-sm-12 py-3 bg-black-trading" 
                                            style={{ borderRadius:'0 10px 0 0', color: 'white', backgroundColor: '#080808', marginBottom: '2px', border: '.5px #ffffff30 solid' }}>
                                            {p.seri}
                                        </div>
                                        <div className="col-sm-12 py-2 px-0">
                                            {
                                                p.sbnInfo.tab < 3 ?
                                                    <table width="100%" className={"table table-bordered table-responsive mb-0 card-334"}>
                                                        <tr>
                                                            <td className={"d-border"}>Nomor SID </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"} >{p.userInfo.sid || ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Nama Nasabah </td>
                                                            <td width="50%" className={"d-border hover-tables"}>{p.userInfo.custname || ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Nama Pemilik Rekening </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>
                                                                {p.userInfo.rdncbaccname || ""}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>No Rekening Dana </td>
                                                            <td width="50%" className={"d-border hover-tables"} >
                                                                {p.userInfo.rdncbaccno || ""}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Nama Bank </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>
                                                                {strCall.rdnBank(p.userInfo.rdnbncode)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>No Rekening SB </td>
                                                            <td width="50%" className={"d-border hover-tables"}>
                                                                {p.sbnInfo.security_acc.NoRek || ""}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Tingkat Kupon (%) </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>
                                                                {mathCall.setRupiah(p.tingkat_kupon, 3, '0.000 %', ' %')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Kode Pemesanan </td>
                                                            <td width="50%" className={"d-border hover-tables"}>{p.kode_pemesanan}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Kelipatan Pemesanan (IDR) </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>
                                                                {thousandSeparator(p.kelipatan_redeem)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Sisa Kepemilikan (IDR) </td>
                                                            <td width="50%" className={"d-border hover-tables"}>
                                                                {thousandSeparator(p.kepemilikan_sisa)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Sisa Pencairan (IDR) </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>
                                                                {thousandSeparator(Number(p.sisa_pencairan))}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border"}>Nominal Pencairan (IDR) <span className="text-danger">*</span></td>
                                                            <td width="50%" className={"d-border hover-tables"}>
                                                                <NumberFormat
                                                                    value={s.inputamountredeem}
                                                                    placeholder='Nominal Pencairan (IDR)'
                                                                    className={`${p.sbnInfo.tab === 1 ? "form-control input-left bg-grey d-border" : ""} pr-1 text-left align-self-center `}
                                                                    decimalSeparator = "."
                                                                    displayType={p.sbnInfo.tab === 1 ? 'input' : 'text'}
                                                                    type="text"
                                                                    thousandSeparator={true}
                                                                    allowNegative={false}
                                                                    disabled={p.onLoading}
                                                                    // isAllowed={({ value }) => value <= Number(p.sisa_pencairan) ? true : false}
                                                                    onValueChange={({value}) => this.setState({...s, inputamountredeem: value }) }
                                                                    onFocus={e => e.target.select()}
                                                                />
                                                                <small className="pl-4 text-danger d-none" id="req_redeemamount">harus diisi</small>
                                                            </td>
                                                        </tr>
                                                        {p.sbnInfo.tab === 2 && 
                                                            <tr>
                                                                <td className={"d-border text-right"}>Masukan Pin Anda <span className="text-danger">*</span> </td>
                                                                <td width="50%" className={"d-border hover-tables"}>
                                                                    <NumberFormat
                                                                        value={s.pin}
                                                                        className="form-control input-left bg-grey d-border col-sm-12 pl-4 pr-1 text-left align-self-center"
                                                                        type="password"
                                                                        placeholder='Masukan Pin Anda'
                                                                        onFocus={e => e.target.select()}
                                                                        disabled={p.onLoading}
                                                                        isAllowed={({ value }) => value.length <= 6 ? true : false}
                                                                        onValueChange={({value}) => this.setState({...s, pin: value })}
                                                                    />
                                                                    <small className="pl-4 text-danger d-none" id="req_esbnpinsubs">harus diisi</small>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </table>
                                                :
                                                    <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>
                                                        <tr>
                                                            <td className={"d-border text-right"}>Nominal Pencairan Awal (IDR) </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"} >
                                                                {thousandSeparator(s.inputamountredeem)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border text-right"}>Kode Pencairan Awal </td>
                                                            <td width="50%" className={"d-border hover-tables"}>{p.sbnInfo.redeemCode || ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border text-right"}>No Akun Penerima </td>
                                                            <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.rdncbaccno || ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"d-border text-right"}>Nama Bank </td>
                                                            <td width="50%" className={"d-border hover-tables"} >{strCall.rdnBank(p.userInfo.rdnbncode)}</td>
                                                        </tr>
                                                    </table>
                                            }
                                        </div>
                                        {p.sbnInfo.tab === 1 &&
                                            <div className="col-sm-12 py-3">
                                                <input disabled={this.state.checkform ? true : false} className="magic-checkbox" type="checkbox" name="declareredeem"
                                                    id="declareredeem" checked={s.nextcheck2} onChange={() => {
                                                        !s.nextcheck2 && this.clickRedeemAlert();
                                                        this.setState({...s, nextcheck2: !s.nextcheck2})
                                                    }}/>
                                                <label htmlFor="declareredeem" className="text-white f-12-center">
                                                    Saya menyatakan:
                                                    <ol className='pl-4' style={{listStyleType: '"✔ "'}}>
                                                        {optList.declareRedeem.map((item, i) => <li key={'dcred'+i}>{item}</li>)}
                                                    </ol>
                                                </label>
                                            </div>
                                        }
                                    </div>
                                    {
                                        p.sbnInfo.tab === 3 && 
                                            <div className={"row bg-gray-tradding py-3"}>
                                                <div className="col-sm-12 px-0">
                                                    <div className="tab-content clearfix">
                                                        <div className="tab-pane active py-2" id="4b">
                                                            <div className={"col-sm-12 mt-4 card-169"}>
                                                                <div className={"text-center col-sm-12 bg-success py-5"}>
                                                                    <i className="text-white">Pencairan awal berhasil diproses</i>
                                                                </div>
                                                                <div className={"col-sm-12 text-right mb-0 px-3 h-40 py-5"}>
                                                                    <button onClick={this.closeClickRedeem} className={"btn btn-primary pull-right"}>Finish</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {p.sbnInfo.tab !== 3 && 
                                    <div className="container next-btn">
                                        <button className="btn btn-primary pull-right" disabled={p.onLoading}
                                            onClick={()=>this.nextHandler(p.sbnInfo.tab + 1)}>
                                                {p.onLoading ? "Memuat..." : "Selanjutnya"}
                                            </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class ModalPINWrong extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white f-12 text-center bg-dark-grey">
                    <div className="form-group">
                        <p className="col-sm-12 px-5 py-2 col-form-label">
                            Pin Anda Salah.
                        </p>
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-primary btn-sm col-sm-3" onClick={this.closeClick}>OK</button>
                </div>
            </>
        );
    }
}

const RedeemEsbnModal = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        userInfo: vars.userInfo,
        sbnInfo: vars.sbnInfo,
        onLoading: vars.onLoading,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        handleStatusAlert3:(type, statusAlert, msg, data) => actions.sendAction('handleStatusAlert3', {type, statusAlert, msg, data}),
    }), ["setDataHandler", 'handleStatusAlert3']
)(RedeemEsbnModal_Base);

export default RedeemEsbnModal;
