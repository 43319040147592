import React from "react";
import { Button, Table } from "react-bootstrap";
import { List, Checkbox, Dropdown, Popup } from "semantic-ui-react";

import { AppFrameAction } from "./../appframe";
import { ContextConnector } from '../appcontext.js';
import { BIPSAppContext, searchCode } from "../AppData";
import { ModalRestricted } from "../app_modals";
import { ResizeResponsive, GetBuyLimit, getExpireOption, orderType, mathCall } from "../utils";
import VerifyPIN, { tanggal, detailBuyModal } from "../app_pages/verifyPin";

class FormBuy_Base extends React.PureComponent{
    
    componentDidMount(){
        ResizeResponsive();
    }

    onChange=(type, e)=>{
        const price = e.target.value
        if(type === 'price'){
            this.props.handleCount('buy','onChange', price)
        }else if(type === 'buyVol'){            
            this.props.handleCount('buyVol','onChange', price)
        }
    }   

    handleCheckbox=(e,data)=>{
        // untuk prevent same order
        if(data.label === 'Prevent same order'){
            this.props.handleOnClick('transaction','prev_same','none')
        }
        // untuk order book
        else if(data.label === 'Order Booking'){
            this.props.handleOnClick('transaction','order_book','none')
        }
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickPIN = (e) => {
        var bidPrice = Number(this.props.bidOfferPrice)
        var bidVol = Number(this.props.bidOfferVol)
        var bidVal = Number(this.props.bidOfferVal)
        var cashBalance = Number(this.props.myBalance)
        var minPrice = this.props.lowerAR
        var maxPrice = this.props.upperAR

        if(this.props.bidOfferCode.length === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'pelase insert stock',)
        }else if(this.props.order_type === "7" && bidPrice === 0 && bidVol === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price and vol can not be zero.',)
        }else if(this.props.order_type === "7" && bidPrice === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price can not be zero.',)
        }else if(bidVol === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Vol can not be zero.',)
        }
        // pembatas price
        // else if(this.props.bidOfferMkt !== '0NG' && !(bidPrice >= minPrice && bidPrice <= maxPrice)){
        //     this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price out of range.',)   
        // }
        // if bidPrice = 9,000,000 alert price in undefined
        else if(this.props.bidOfferMkt === '0NG' && bidPrice >= 9000000){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Harga yang di tawarkan berada pada batas maksimal',)   
        }
        else{ 
            if(this.props.regulerId === ''){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Trading server is not available',)
            }
            else if(cashBalance <= bidVal && bidVal >= cashBalance){
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'your balance is lacking',)
            }
            else if(this.props.userProfile.status ==="TRIAL" || this.props.userProfile.status ==="SUSPEND" || this.props.userProfile.status ==="SUSPEND BUY"){
                // this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN, this.props.userProfile.erAlert)
                this.refs.frameAction.showModal({
                    headerClass: () => <div className="text-white text-center">
                        <h3 className="text-center pt-1">Buy is not permitted</h3></div>,
                        size: 'mini',
                    contentClass: ModalRestricted,
                    onClose: (result) => {console.log('Modal 1 result = ', result)}
                })
            }
            else  {
                this.refs.frameAction.showModal({
                    // headerClass: () => <div className="text-right"></div>,
                    headerClass: () => <div className="text-white f-12 text-right"><i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}</div>,
                    contentClass: detailBuyModal, 
                    // contentClass: PINVerify, 
                    onOpen:this.props.handleCount('none','clientOI', this.props.clientOrderId),
                    onClose: (result) => console.log('Second modal result = ', result),
                    size: "mini"
                });
            }
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var newCode =this.refs.codeSearch.value.toUpperCase()
            let searchType = newCode.split(".")
            // check the text after the dot
            if(searchType[1] === "TN" || searchType[1] === undefined){
                var codeList = Object.keys(this.props.stockList)
                var codeResult = searchCode('code1', searchType[0], codeList)
                if(codeResult === undefined){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock not found.',)
                }else if(newCode === this.props.bidOfferCode){
                        this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock already exists',)
                }else{
                    this.props.handleSearchCode('tableInformation', newCode);
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Stock not found.',)

            }
            this.refs.codeSearch.value =''
        }
    }

    buttonMax = ()=>{
        var bidPrice = Number(this.props.bidOfferPrice)
        
        if(bidPrice === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price can not be zero.)',)
        }else{ 
            this.props.handleOnClick('transaction','maxVol','none')
        }
    } 
    
    checkDate(){
        if(this.props.part === "stock"){
            return;
        }else{
            return (
                <div className="col-sm-2 text-right text-form px-0 my-0 py-0 mx-0 pt-1">
                    <i className="fa fa-calendar-alt"></i>&nbsp;
                    {tanggal()}
                </div>
            );
        }
    }

    render(){
        let p = this.props;
        let cashOnT2 = p.constBL.balance - p.constBL.OB;
        let remainTrade = GetBuyLimit(p.constBL);
        let stateOptionsMkt = p.bidOfferCode.includes("-R") || this.props.bidOfferMkt === '0TN' ? [{ key: 'tn', value: '0TN', text: 'TN' }] :
            (p.orbookStatus ? [{ key: 'rg', value: '0RG', text: 'RG' }] : p.stateOptionsMkt);
        return(
            <div className="f-12  px-3">
                <AppFrameAction ref="frameAction" />
                <Table borderless className={`${(this.props.part === "stockInfo") ? 'card-467' : 'card-475' } mb-0`}>
                    <tbody>
                    <tr>
                        <td className="py-0">
                            <div className="col-sm-6 f-18 text-danger"></div>
                            <div className="row mb-0">
                                <div className={"col-sm-2"}></div>
                                <div className="col-sm-8 f-15">
                                    {
                                        p.iStockData.board_pos === "Acceleration Board" ? 
                                            <div className="pb-0 text-form">
                                                <i className="icofont icofont-warning text-danger"></i>
                                                &nbsp; <b>Member of acceleration board</b>
                                            </div>
                                        : (p.iStockData.board_pos && p.iStockData.board_pos.includes("Watch-list")) ?
                                            <div className="pb-0 text-form">
                                                <i className="icofont icofont-warning text-danger"></i>
                                                &nbsp; <b>Watchlist - Call Auction Stock</b>
                                            </div>
                                        
                                        : ""
                                    }
                                </div>
                                {this.checkDate()}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-0">
                            <div className="col-sm-2 text-form">Code</div>
                            <div className="col-sm-10 row pr-0">
                                <div className="col-sm-4 pr-0 text-center align-self-center">
                                    <input type="text" placeholder={p.bidOfferCode.length === 0 ? "Code" : (p.transactionType === "TN" ? p.bidOfferCode+".TN" : p.bidOfferCode)} 
                                        defaultValue="" onKeyDown={this.handleKeyDown} ref="codeSearch" autoFocus={p.bidOfferCode.length === 0 ? true : false}
                                        className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                </div>
                                <div className="input-group col-sm-8 align-self-center pl-4">
                                    <span className="input-group-btn">
                                        <label className="form-control ui icon button bg-gold px-2 py-3" style={{"margin": "0px","border-bottom-left-radius": ".28571429rem","border-top-left-radius": ".28571429rem", "border-bottom-right-radius": "0px", "cursor": "default"}}>
                                            {p.haircutCode+"%"}
                                        </label>
                                    </span>
                                    <input type="text" placeholder="Name" value={p.bidOfferCode == "" ? "" : p.iStockData.name} className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                    <span className="input-group-btn">
                                        <label className="form-control ui icon button bg-gold px-2 py-3" style={{"margin": "0px","border-bottom-right-radius": "0px", "cursor": "default"}}>
                                            {p.codeStatus}
                                        </label>
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <Checkbox label='Prevent same order' className="f-12 text-form mt-2 mb-0 align-self-center" 
                                        onChange={(e, data) => this.handleCheckbox(e,data)} 
                                        checked={this.props.prevStatus}/>
                                </div>
                            </div>
                            <div className="col-sm-12 row">
                                <div className={"col-sm-2"}></div>
                                <div className="col-sm-10 mr-0 pr-0 f-15">
                                    {p.bidOfferCode != "" && p.iStockData.special_notation && <div className="pb-0 text-form">
                                        <b>
                                            <Popup 
                                                content={
                                                    <List>
                                                        {p.bidOfferCode != "" && p.iStockData.special_notation && p.iStockData.special_notation_desc.split("^").map((item, id) => (
                                                            <List.Item key={"snd"+id}>
                                                                <List.Content>{item}</List.Content>
                                                            </List.Item>
                                                        ))}
                                                    </List>
                                                }
                                                mouseEnterDelay={100}
                                                mouseLeaveDelay={100}
                                                header="Special Notation Stock"
                                                position='left center'
                                                trigger={
                                                    <i className="fa fa-info-circle text-danger"></i>
                                            } />
                                            &nbsp; Special Notation Stock
                                        </b>
                                    </div>}
                                    {
                                        // ireport === "-" || ireport === undefined ? "" :
                                        // <div className="pb-0 text-form">
                                        //     <b>
                                        //     <i className="fa fa-info-circle text-danger"></i>
                                        //         {/* &nbsp; Not yet submit annual financial report */}
                                        //         &nbsp; {ireport}
                                        //     </b>
                                        // </div> 
                                    }
                                </div>                                
                            </div>
                        </div>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 pr-0 text-form">Order Type</div>
                                    <div className="col-sm-9">
                                        <Dropdown 
                                            placeholder='OrderType' 
                                            search selection 
                                            options={orderType}
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            defaultValue={p.order_type}
                                            value={p.order_type}
                                            onChange={(e, {name, value}) => {
                                                if(p.bidOfferCode.length === 0){
                                                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'pelase insert stock',)
                                                }else{
                                                    this.props.handleOnClick('transaction','order_type', value)}
                                                }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Price</div>
                                    <div className="col-sm-9">
                                        {/* <NumberInput idclassname={this.props.idPrice} name="buy_price" placeholder="Price" size="small" 
                                        defaultValue={bidPrice} className="col-sm-12 px-0 f-12 text-center align-self-center" 
                                        value={{bidPrice}}/> */}
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('buy','minus', p.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    disabled={p.order_type === "1" ? true : false}
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={p.order_type === "1" ? "" : "0"} value={mathCall.setRupiah(p.bidOfferPrice)}
                                                placeholder={p.order_type === "1" ? "" : "0"}
                                                onChange={(e)=>this.onChange('price',e)} 
                                                disabled={p.order_type === "1" ? true : false}
                                                onFocus={(e) => e.target.select()} />
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('buy','plus', p.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    disabled={p.order_type === "1" ? true : false}
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    {/*<Checkbox label='Auto Last' className="f-12 text-white col-sm-11 px-4 my-0 align-self-center" />*/}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Vol</div>
                                    <div className="col-sm-9 py-form">
                                        {/* <NumberInput idclassname={this.props.idVol} name="buy_vol" placeholder="Vol" size="small" defaultValue={p.bidOfferVol} className="col-sm-12 px-0 f-12 text-center align-self-center" /> */}
                                        {/* Without button + - */}
                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                            className="col-sm-12 f-12 text-center align-self-center form-control" defaultValue={mathCall.setRupiah(p.bidOfferVol)} 
                                            value={mathCall.setRupiah(p.bidOfferVol)} onChange={(e)=>this.onChange('buyVol',e)} placeholder="0"
                                            onFocus={(e) => e.target.select()}/>
                                        {/* with button + - */}
                                        {/*<div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('buyVol','minus', p.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={mathCall.setRupiah(p.bidOfferVol)} value={mathCall.setRupiah(p.bidOfferVol)} 
                                                onChange={(e)=>this.onChange('buyVol',e)} 
                                                />
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>p.handleCount('buyVol','plus', p.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span> 
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-sm-4 pl-5 row text-center align-middle align-self-center pr-0">
                                    <label className="col-sm-5 pr-4 pl-0 mb-0 bg-buy py-2 align-self-center text-left">Lot</label>
                                    <button type="button" onClick={this.buttonMax}
                                            className="col-sm-7 px-0 btn btn-sm btn-dark py-2 align-self-center">Max
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Mkt.</div>
                                    <div className="col-sm-9">
                                        <Dropdown placeholder='Mkt' search selection options={stateOptionsMkt} 
                                            onChange={(e, data)=>this.props.handleOnClick('transaction','mkt',data.value)}
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            value={this.props.bidOfferMkt}/>
                                    </div>
                                </div>
                                <div className="col-sm-4 align-middle align-self-center">
                                    <div className="col-sm-12 px-0">
                                        <Checkbox label='Order Booking' className="f-12 text-white px-0 py-2 my-0 align-self-center text-form" 
                                        onChange={(e, data) => this.handleCheckbox(e,data)} 
                                        checked={this.props.orbookStatus}/>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 mb-2 py-form text-form">Expire</div>
                                    <div className="col-sm-9 mb-2 py-form">
                                        <Dropdown placeholder='Expire' search selection
                                            options={getExpireOption(p.order_type, p.bidOfferMkt)} 
                                            className={"f-12 text-center align-self-center col-sm-12"} 
                                            onChange={(e, data)=>p.handleOnClick('transaction','expire',data.value)} 
                                            value={this.props.bidOfferExpire}/>
                                        </div>

                                    <div className="col-sm-3 mb-2 py-form text-form">Value</div>
                                    <div className="col-sm-9 mb-2 py-form">
                                        <input type="text" id={p.idValue} name="buy_value" placeholder={p.order_type === "1" ? "" : "0"} size="small"  
                                            value={mathCall.setRupiah(p.bidOfferVal)} 
                                            className="col-sm-12 f-12 text-center align-self-center form-control"
                                            style={{"borderRadius": "0px", "text-align": "right"}} readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    <Button size="sm" className="btn btn-lg btn-danger col-sm-8" onClick={this.buttonClickPIN}>
                                        <i className="icon-icon-buy-btn fa-2x"></i>
                                        <br/>Buy
                                    </Button>
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            className={`col-sm-4 btn btn-primary btn-refresh-2-right`}
                                            style={{"font-size":"12px","width":"38px"}}
                                                onClick={()=>this.props.handleOnClick('refreshPage','formbuysell','formBuy')}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    } />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row px-4 py-form">
                                <Table responsive borderless size="sm" className="text-white pb-0 mb-0 d-border-table">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td width="25%" className="no-wrap bg-gray-tradding d-border-tr-black">
                                            Cash On <br/> T+2
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey d-border-tr-gray text-right">
                                            {mathCall.setRupiah(cashOnT2)}
                                        </td>
                                        <td width="25%" className="no-wrap bg-gray-tradding d-border-tr-black">
                                            Remain <br/> Trade Limit
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey d-border-tr-gray text-right">
                                            {mathCall.setRupiah(remainTrade)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%" className="no-wrap bg-gray-tradding d-border-tr-black">
                                            Cash Buy <br/>(Share)
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey d-border-tr-gray text-right">
                                            {cashOnT2 <= 0 || Number(p.bidOfferPrice) === 0 ? 0 : mathCall.setRupiah(Math.floor(cashOnT2/Number(p.bidOfferPrice)) || 0)}
                                        </td>
                                        <td width="25%" className="no-wrap bg-gray-tradding d-border-tr-black">
                                            Cash Buy <br/>(Lot)
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey d-border-tr-gray text-right">
                                            {cashOnT2 <= 0 || Number(p.bidOfferPrice) === 0 ? 0 : mathCall.setRupiah(Math.floor(cashOnT2/(100*Number(p.bidOfferPrice))) || 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%" className="no-wrap bg-gray-tradding">
                                            Max Buy <br/>(Share)
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey text-right">
                                            {/*cashOnT2 <= 0 || */Number(p.bidOfferPrice) === 0 ? 0 : mathCall.setRupiah(Math.floor(Number(remainTrade)/Number(p.bidOfferPrice)))}
                                        </td>
                                        <td width="25%" className="no-wrap bg-gray-tradding">
                                            Max Buy <br/>(Lot)
                                        </td>
                                        <td width="25%" className="no-wrap bg-grey text-right">
                                            {cashOnT2 <= 0 || Number(p.bidOfferPrice) === 0 ? 0 : mathCall.setRupiah(Math.floor(Number(remainTrade)/(100*Number(p.bidOfferPrice))))}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>

                {/* <Popup content='Refresh' position='top center' trigger={
                    <button className={`col-sm-1 btn btn-primary btn-refresh-right`}
                        style={{"font-size":"12px","width":"38px","position":"fixed",
                            "display":(this.props.part=="stock") ? "none":"block"}}
                        onClick={()=>this.props.handleOnClick('refreshPage','formbuysell','form buy')}>
                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                    </button>
                }/> */}
            </div>
        );
    }
}

class PINVerify_Base extends React.PureComponent {

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'buy'/>
            </>
        );
    }
}
//************************** Context Connector **************************
const FormBuy = ContextConnector(BIPSAppContext,
    (vars,actions)=>({
        networkState:vars.networkState,
        constBL: vars.constBL,
        userProfile:vars.userProfile,
        stateOptionsMkt:vars.stateOptionsMkt,
        bidOfferCode :vars.bidOfferCode,
        iStockData:vars.iStockData,
        codeStatus:vars.codeStatus,
        haircutCode:vars.haircutCode,
        lowerAR:vars.lowerAR,
        upperAR:vars.upperAR,
        stockList:vars.stockList,
        regulerId:vars.regulerId,
        orbookStatus:vars.orbookStatus,
        bidOfferPrice:vars.bidOfferPrice,   
        bidOfferVol:vars.bidOfferVol,
        bidOfferVal:vars.bidOfferVal,
        bidOfferMkt:vars.bidOfferMkt,
        bidOfferExpire:vars.bidOfferExpire,
        order_type: vars.order_type,
        prevStatus:vars.prevStatus,
        // formBuyFooter:vars.formBuyFooter,
        transactionType:vars.transactionType,
        handleCount:(typeAction, typeCount,price)=>actions.sendAction('handleCount',{typeAction, typeCount,price}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode', {type, code}),
        // Alert
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleCount", "handleOnClick", "handleSearchCode", "handleStatusAlert3"]
)(FormBuy_Base)

const PINVerify = ContextConnector(BIPSAppContext,
(vars, actions)=>({
    networkState:vars.networkState
})
)(PINVerify_Base)

export default FormBuy;