import React from 'react';

import $ from 'jquery';
import { timeCall } from '../utils';

window.$ = window.jQuery = $;

class RangeDatePicker extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {}
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            sd.setDate(sd.getDate() - 1);
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#rangedatestart').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("onChangeRangeDate").click(); 
            });

            $('#rangedateend').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("onChangeRangeDate").click(); 
            });
        });

        // $("#btn-clear-date").click(function () {
        //     $(".date-clear").datepicker("clearDates");
        // });
    }

    handleChangeDate = () => {
        let start_date = this.refs.rangedatestart.value
        let end_date =  this.refs.rangedateend.value
        if(start_date != "" && end_date == ""){
            end_date = timeCall.getdate()
            $("#rangedateend").datepicker("setDate", end_date);
        };
        this.props.onChangeDate({start_date, end_date});
    }

    render(){
        let { startLabel="From", endLabel="To", dateValue = {start_date: "", end_date: ""}, goAction } = this.props;
        return (<>
            <input type="hidden" onClick={this.handleChangeDate} id={"onChangeRangeDate"} />
            <table>
                <tbody>
                    <tr>
                        <td className={"px-0 py-0"}>
                            <span className="input-group-addon h-35 bg-tableheader height-span-addon" 
                                style={{border: "1px solid var(--warna-d-border)"}}
                            >
                                {startLabel}
                            </span>
                        </td>
                        <td className={"px-0 py-0"}>
                            <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                <input placeholder='dd/mm/yy' id="rangedatestart" ref="rangedatestart" 
                                    className="col-sm-12 pl-0 pr-0 text-center align-self-center pl-1"
                                    value={dateValue.start_date}
                                />
                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" 
                                    style={{width: '100%'}}
                                >
                                    <span className="fa fa-calendar-alt"></span>
                                </span>
                            </div>
                        </td>
                        <td className={"px-0 py-0"}>
                            <span className="input-group-addon h-35 bg-tableheader height-span-addon" 
                                style={{border: "1px solid var(--warna-d-border)"}}
                            >
                                {endLabel}
                            </span>
                        </td>
                        <td className={"px-0 py-0"}>
                            <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                <input placeholder='dd/mm/yy' ref="rangedateend" id="rangedateend" 
                                    className="col-sm-12 pl-0 pr-0 text-center align-self-center pl-1"
                                    value={dateValue.end_date}
                                />
                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" 
                                    style={{width: '100%'}}
                                >
                                    <span className="fa fa-calendar-alt"></span>
                                </span>
                            </div>
                        </td>
                        <td>
                            <button type="submit" style={{height:'30px !important'}} 
                                className="btn btn-md btn-block btn-dark btnDatePick"
                                onClick={() => goAction()}
                            >
                                Go
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>)
    }
};

export default RangeDatePicker;