import React, { Component } from 'react';
import anychart from 'anychart';
import AnyChart from 'anychart-react';
import Select from 'react-select';

import 'anychart/dist/css/anychart-ui.min.css';
import 'anychart/dist/js/anychart-ui.min';
import 'anychart/dist/fonts/css/anychart-font.min.css';
import 'anychart/dist/js/anychart-data-adapter.min'
import 'anychart/dist/js/anychart-annotations.min';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min';

import { ContextConnector } from '../../appcontext.js';
import { BIPSAppContext } from '../../AppData.js';
import { WSConnectionAction } from '../../appnetwork.js';

import * as util from '../../utils';

import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap/dist/js/bootstrap');

let styleses = {
    display: 'flex',
    padding: '5px 10px 0px 10px',
    marginTop: '5px'
};

let marginSelection = { marginLeft: '2px'};

const customStyles = {
    control: (base, state) => ({
        ...base,
        // height: '33px',
        'min-height': '33px',
    }),
};

class EtalaseChart_Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockCodeSelected: props.COMPARE_CODE_PRODUCT || [],
            chartTheme: 'darkEarth',
            chartThemeVal: util.chartThemeList[0].theme,
            chartPeriode: '',
            typeSelected: ''
        };
    }

    componentDidMount() {
        $('#etalase-container1').css('height', '100%');
    }

    getChartData = () => {
        let stockCodeSelected = this.state.stockCodeSelected.map(d => d.value);
        if(stockCodeSelected.length < 2){
            this.props.setDataHandler({"statusAlertN": true, "msgAlert3": "Mohon isi dulu kedua pilihan"});
        } else if(stockCodeSelected.length >= 10){
            this.props.setDataHandler({"statusAlertN": true, "msgAlert3": "product tidak boleh melebihi 10"});
        // } else if(this.state.chartPeriode === ''){
        //     this.props.setDataHandler({"statusAlertN": true, "msgAlert3": "Silahkan pilih periode terlebih dahulu"});
        } else {
            this.props.setDataHandler({ COMPARE_CODE_PRODUCT: this.state.stockCodeSelected });
            this.refs.netAction.send(util.fmsg.aperd_compareProduct(stockCodeSelected, /* this.state.chartPeriode.value*/));
        }
    }

    onChangeProduct = (e) => {
        let typeSelected = this.state.typeSelected === '' ? util.refAperd.mutualFundType.find(d => d.value === e[0]['type']) : this.state.typeSelected;
        this.setState(ps => ({ ...ps, stockCodeSelected: e, typeSelected }))
    }

    onChangeTheme = (val) => {
        let nval = util.chartThemeList.find(d => d.value === val);
        this.setState({ chartThemeVal: nval.theme, chartTheme: nval.value});
    }

    resetButton = () => {
        this.props.setDataHandler({COMPARE_PRODUCT: [], COMPARE_CODE_PRODUCT: [] });
        this.setState(ps => ({ ...ps, stockCode: [], stockCodeSelected: [], chartPeriode: '', typeSelected: '', chartThemeVal: util.chartThemeList[0].theme}))
    }

    render() {
        let { COMPARE_PRODUCT=[], COMPARE_CODE_PRODUCT=[], thememode, aperdOption=[] } = this.props;
        const { stockCode, typeSelected="", stockCodeSelected, chartTheme, chartThemeVal,  chartPeriode } = this.state;

        var chart = anychart.stock();

        chart.scroller(true);
        chart.scroller().enabled(false);
        var credits = chart.credits();
        credits.enabled(false);

        anychart.onDocumentReady(function () {
            if(COMPARE_PRODUCT.length > 0) {
                anychart.theme(chartThemeVal);

                var tableObj = {}, mapObj={}, chartObj={};
                COMPARE_PRODUCT.map((item, key) => {
                    tableObj = { ...tableObj, ['tab'+key]: anychart.data.table()}
                })
                // [date, nav, navUnit]
                Object.keys(tableObj).length > 0 &&
                    Object.keys(tableObj).map((d, key) => {
                        tableObj[d].addData(COMPARE_PRODUCT[key]);
                        mapObj = { ...mapObj, 
                            ['mapObj'+key]: tableObj[d].mapAs({ 'value': 2, 'Nav': 2, 'NavUnit': 2, 'high': 2, 'low': 3, 'close': 4 })
                        }
                    });
    
                // setting chart padding to fit both Y axes
                chart.padding(10, 50, 20, 50);
    
                // create plot on the chart
                var plot = chart.plot(0);
                plot.yScale().comparisonMode('percent');
                plot.yScale({'scale': 'linear', 'indicators': {}});
                plot.yAxis().labels().format('{%Value}%');

                plot.yGrid(true).yMinorGrid(true);
                // enabled x-grid/y-grid
                plot.yGrid().enabled(true);
                plot.yGrid().stroke({color: "#555555", dash: "2 1"});
                plot.xMinorGrid().enabled(true);
                plot.xMinorGrid().stroke({color: "#555555", dash: "2 1"});
    
                // set orientation y-axis to the right side
                plot.yAxis().orientation('right');
    
                // create line series on the plot
                // var aaplSeries = plot.line(mapping);
                Object.keys(mapObj).length > 0 &&
                    Object.keys(mapObj).map((d, key) => {
                        chartObj = { ...chartObj, ['chartObj'+key]: plot['line'](mapObj[d])}
                    });

                // set series settings
                // aaplSeries.name(fundName).zIndex(chartData.length-1);
                Object.keys(chartObj).length > 0 &&
                    Object.keys(chartObj).map((d, key) => {
                        let colorType = { 
                            0: '#ff0000', 1: '#ffbf00', 2: '#00d8ff', 3: '#0000FF', 4: '#008080', 
                            5: '#FF0000', 6: '#808000', 7: '#20B2AA', 8: '#8A2BE2', 9: '#FF00FF'
                        }
                        chartObj[d].name(COMPARE_CODE_PRODUCT[key].saham.toUpperCase() || "");
                        chartObj[d].fill(colorType[key]);
                        chartObj[d].stroke(`2px ${colorType[key]}`);

                        chartObj[d].normal().fallingFill(colorType[key], 0.8);
                        chartObj[d].normal().fallingStroke(colorType[key], 0.8);
                        chartObj[d].hovered().fallingFill(colorType[key], 1);
                        chartObj[d].hovered().fallingStroke(colorType[key], 1);
                        chartObj[d].selected().fallingFill(colorType[key], 0.9);
                        chartObj[d].selected().fallingStroke(colorType[key],0.9);

                        chartObj[d].normal().risingFill(colorType[key], 0.8);
                        chartObj[d].normal().risingStroke(colorType[key], 0.8);
                        chartObj[d].hovered().risingFill(colorType[key], 1);
                        chartObj[d].hovered().risingStroke(colorType[key], 1);
                        chartObj[d].selected().risingFill(colorType[key], 0.9);
                        chartObj[d].selected().risingStroke(colorType[key], 0.9);
                    });
    
            } else {
                var tabEmpty = anychart.data.table();
                tabEmpty.addData([]);
                var mapping = tabEmpty.mapAs({ 'value': 1, 'Nav': 1, 'NavUnit': 1, 'high': 2, 'low': 3, 'close': 4 });
                // create plot on the chart
                var plot = chart.plot(0);
                var chartEmpty = plot['line'](mapping)
                chartEmpty.name("Series");
            }

            chart.draw();

            // create range picker
            var rangePicker = anychart.ui.rangePicker();
            rangePicker.render(chart);

            // create range selector
            var rangeSelector = anychart.ui.rangeSelector();
            rangeSelector.render(chart);
        });

        return (<>
            <WSConnectionAction ref="netAction" />
            <div id={"allwrap"} className="f-12" style={{position: 'relative', zIndex: '999'}}>
                <div className="row" id={"formInputIndicators"}>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <ul className="list list-unstyled list-nav my-0" id="indicatorNavPanel" style={styleses}>
                            <div className="form-group col-sm-1 px-2 pt-1 mb-0 align-self-center">
                                <h6>Type : </h6>
                            </div>
                            <div className="form-group col-sm-8 px-0 pt-1 mb-0">
                                <li style={marginSelection}>
                                <Select
                                        maxMenuHeight={200}
                                        maxMenuWidth={300}
                                        styles={customStyles}
                                        // isMulti
                                        placeholder={<div>Search..</div>}
                                        // options={this.props.relativelistOptions}
                                        options={this.props.listFundType}
                                        getOptionLabel={(stockOptions) => `${stockOptions.saham}`}
                                        id={"rc"}
                                        className="stockPageSelect text-left"
                                        theme={(theme) => util.selectSelectionTab(theme, thememode)}
                                        onChange={(event) => this.setState(ps => ({ ...ps, typeSelected: event})) }
                                        filterOption={util.customFilter}
                                        value={typeSelected}
                                    />
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row" id={"formInputIndicators"}>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <ul className="list list-unstyled list-nav my-0" id="indicatorNavPanel" style={styleses}>
                            <div className="form-group col-sm-1 px-2 pt-1 mb-0 align-self-center">
                                <h6>Product : </h6>
                            </div>
                            <div className="form-group col-sm-8 px-0 pt-1 mb-0">
                                <li style={marginSelection}>
                                    <Select
                                        maxMenuHeight={200}
                                        maxMenuWidth={300}
                                        styles={customStyles}
                                        isMulti
                                        placeholder={<div>Search..</div>}
                                        // options={this.props.relativelistOptions}
                                        options={(typeSelected === '' || typeSelected.value === undefined) ? aperdOption : (aperdOption.filter(d => d.type === typeSelected.value ))}
                                        getOptionLabel={(stockOptions) => `${stockOptions.saham}`}
                                        id={"rc"}
                                        className="stockPageSelect text-left"
                                        theme={(theme) => util.selectSelectionTab(theme, thememode)}
                                        onChange={(event) => this.onChangeProduct(event) }
                                        filterOption={util.customFilter}
                                        value={stockCodeSelected}
                                    />
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>

                {/* <div className="row" id={"formInputIndicators"} style={{ "zoom": "0.8","transform-origin": "left center" }}>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <ul className="list list-unstyled list-nav" id={"indicatorNavPanel"} style={styleses}>
                            <div className="form-group col-sm-1 px-2 pt-1 mb-0 align-self-center">
                                <h6>Periode : </h6>
                            </div>
                            <div className="form-group col-sm-8 px-0 pt-1 mb-0">
                                <li style={marginSelection}>
                                    <Select
                                        maxMenuHeight={200}
                                        maxMenuWidth={300}
                                        styles={customStyles}
                                        placeholder={<div>Search..</div>}
                                        // options={this.props.relativelistOptions}
                                        options={util.refAperd.periode}
                                        getOptionLabel={(periodeOptions) => `${periodeOptions.label}`}
                                        id={"rc3"}
                                        className="stockPageSelect text-left"
                                        onChange={(event) => this.setState(ps => ({ ...ps, chartPeriode: event})) }
                                        theme={(theme) => util.selectSelectionTab(theme, thememode)}
                                        value={chartPeriode}
                                    />
                                </li>
                            </div>
                        </ul>
                    </div>
                </div> */}

                <div className="row text-right px-3" id={"formInputIndicators"} style={{ "zoom": "0.8","transform-origin": "left center"}}>
                    <div className="col-xs-6 col-sm-6 col-md-6" style={styleses}>
                        <h3 className='float-left px-3 pt-3 my-0'>NAV/Unit Chart</h3>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6">
                        <ul className="list list-unstyled list-nav" id={"indicatorNavPanel"} style={{ ...styleses, "float":"right"}}>
                            <div className="form-inline">
                                <div className="form-group">
                                    <li style={marginSelection}>
                                        <select id={"themeSelect"} data-size="10"data-dropup-auto="false" data-width="81" data-style="btn-dark"
                                            className="select selectpicker show-tick form-control" title="Theme"
                                            onChange={(e) => this.onChangeTheme(e.target.value)}
                                            value={chartTheme}
                                        >
                                            { 
                                                util.chartThemeList.map((item, key) => 
                                                    <option key={'chrtm'+key} value={item.value} selected>{item.label}</option> 
                                                )
                                            }
                                        </select>
                                    </li>
                                </div>
                                <div className="form-group">
                                    <li style={marginSelection}>
                                        <button className="btn btn-danger" style={{marginLeft: '1px'}} id={"resetButton"}
                                            onClick={() => this.resetButton()}
                                        >Reset</button>
                                    </li>
                                </div>
                                <div className="form-group">
                                    <li style={marginSelection}>
                                        <button className="btn btn-primary" style={{marginLeft: '1px'}} id={"showButtonCompare"} 
                                            onClick={() => this.getChartData()}
                                        >Compare</button>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='card-356'  style={{padding: '10px'}}>
                <AnyChart instance={chart} id="etalase-container1" { ...chartThemeVal }/>
            </div>
        </>);
    }
}

const EtalaseChart = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        aperdOption: vars.aperdOption,
        COMPARE_CODE_PRODUCT: vars.COMPARE_CODE_PRODUCT,
        COMPARE_PRODUCT: vars.COMPARE_PRODUCT,
        listFundType: vars.listFundType,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }), ['setDataHandler']
)(EtalaseChart_Base);

export default EtalaseChart;
