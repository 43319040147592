import React from "react";
import {Table} from 'react-bootstrap';
import { Input,Popup } from 'semantic-ui-react';

/*import {Input, InputGroup, InputGroupAddon, InputGroupText, Table, UncontrolledTooltip} from "reactstrap";*/
import {AppFrameAction} from "../appframe";
import {BIPSAppContext , ConvertToRupiah, searchCode,
    sumDataArray, CurrencyModifier, GetLowerUpper} from "../AppData";
import { ContextConnector } from '../appcontext.js';

import {BuyModal, SellModal} from './../app_pages/stockPage';
import d from '../dummyData';
import $ from 'jquery';
import { thousandSeparator } from "../appFunctions";
import { colorText, strCall } from "../utils";

class TableInfoTransaction_Base extends React.PureComponent{
    
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {            
            let newCode =this.refs.codeSearch.inputRef.current.value.toUpperCase()
            let searchType = newCode.split(".")
            // check the text after the dot
            if(searchType[1] === "TN" || searchType[1] === undefined){
                let codeList = Object.keys(this.props.stockList)
                let codeResult = searchCode('code1', searchType[0], codeList) 
                if(codeResult === undefined){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code not found!',)
                }else if(newCode === this.props.bidOfferCode){
                        this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code already exists',)
                }else{
                    this.props.handleSearchCode('tableInformation', newCode);
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code not found!',)
            }
            this.refs.codeSearch.inputRef.current.value =''            
        }
    }

    render(){
        let p = this.props;
        var scode = this.props.bidOfferCode;    

        var bidData = this.props.bidData
        var offerData =this.props.offerData
        var sStockSmry = this.props.sStockSmry;
        var iStockData = this.props.iStockData;
        var cek = sStockSmry.change_price;
        
        // get average 
        var average = cek !== undefined && sStockSmry.traded_vol != 0 ? (sStockSmry.traded_val/sStockSmry.traded_vol).toFixed(2) : "0";
        
        // get lower AR and Upper AR with pre opening ?
        var prev = sStockSmry.prev_price;
        var lowerAR;
        var upperAR;                
        var preOpening = iStockData.trade_in_pre
        if(preOpening){
            var prevStatus = preOpening.includes("The stock can be traded")
            let AR = GetLowerUpper(prevStatus, sStockSmry.open_price, prev, iStockData)
            lowerAR = AR.lowerAR
            upperAR = AR.upperAR
        }

        // sum BiOffer Data 
        var sumNoBid = sumDataArray(bidData, 'object-noBidOffer');
        var sumBidVol = sumDataArray(bidData, 'object-bidOfferVol');
        var sumNoOffer = sumDataArray(offerData, 'object-noBidOffer');
        var sumOfferVol = sumDataArray(offerData, 'object-bidOfferVol'); 

        // set option type for Automatic order
        if(this.props.lotshare === "autTradeOrd"){
            let lBid = bidData.filter(d => d.bidOffer != "\xa0" ).length;
            let lOffer = offerData.filter(d => d.bidOffer != "\xa0" ).length;
            this.props.handleOnClick("trade", "actionAutoSetOptions", {lBid, lOffer})
        }

        return(
            <>
                <div className="col-sm-12 row px-0 mx-0 h-30 my-0">
                    <div className="col-sm-3-6 px-0 mx-0 ">
                        <Input label={{ color: 'bg-gold', content: this.props.haircutCode+"%" }} defaultValue='' ref="codeSearch" 
                            onKeyDown={this.handleKeyDown} labelPosition='right' 
                            placeholder={scode.length === 0 ? "Code" : scode}
                            size='mini' style={{width:'50%'}}
                            readOnly={this.props.amendOrderModalStatus== true ? true : false}/>
                    </div>

                    <div className="col-sm-3-6 px-0 mx-0">
                        <label className="col-sm-12 f-12 f-xs-14 px-0 mx-0 text-primary text-center">
                            {ConvertToRupiah(p.portfolioData.stockval)}
                            <br/><span className="text-white f-9">Investment In {this.props.transactionType === "TN" ? scode+".TN" : scode}</span>
                        </label>
                    </div>

                    <div className="col-sm-2-4 px-0 mx-0">
                        <label className="col-sm-12 f-12 f-xs-14 px-0 mx-0 text-danger text-center">
                            { p.portfolioData.perPl}
                            <br/><span className="text-white f-9">%Change</span>
                        </label>
                    </div>

                    <div className="col-sm-2-4 px-0 mx-0">
                        <Popup content={ConvertToRupiah(p.portfolioData.shares)+' Share'} position='top center' trigger={
                            <label className="col-sm-12 f-12 f-xs-14 px-0 mx-0 text-primary text-center" id={this.props.lotshare}>
                                <span id="lotShare">{ConvertToRupiah(p.portfolioData.lot)}</span>
                                <br/><span className="text-white f-9">Lot</span>
                            </label>
                        } />
                    </div>

                    <div className="col-sm-2-4 px-0 mx-0">
                        <label className="col-sm-12 f-12 f-xs-14 px-0 mx-0 text-danger text-center">
                            {ConvertToRupiah(p.portfolioData.pl)}
                            <br/><span className="text-white f-9">P/L</span>
                        </label>
                    </div>
                </div>

                <Table responsive borderless size="sm" className={`text-white d-border-table bg-dark-grey ${p.card2 || "card-120"} mb-1`
                    // this.props.lotshare === 'modalbuy' || this.props.lotshare === 'modalSell' || this.props.lotshare === 'modalamend' ?
                    // "text-white d-border-table bg-dark-grey card-112 mb-1" : "text-white d-border-table bg-dark-grey card-112 mb-1"
                    }>
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Last</td>
                        <td className={ sStockSmry.change_price < 0 ? "no-wrap text-danger d-border-tr-gray text-right py-1" 
                            : ( sStockSmry.change_price > 0  ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                                { cek !== undefined ? sStockSmry.close_price == 0 ? <i className="icofont icofont-minus"></i> : ConvertToRupiah(sStockSmry.close_price) : ""}
                            </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Previous</td>
                        <td className="no-wrap text-warning d-border-tr-gray text-right py-1">
                        {cek !== undefined ? ConvertToRupiah(sStockSmry.prev_price): ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Freq</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        {cek !== undefined ?  ConvertToRupiah(sStockSmry.traded_freq): ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Change</td>
                            {
                                sStockSmry.change_price < 0 
                                ? <td className="no-wrap text-danger d-border-tr-gray text-right py-1"><i className="icofont icofont-caret-down"></i> 
                                    { cek !== undefined ? ConvertToRupiah(sStockSmry.change_price): ""}
                                </td>
                                : (sStockSmry.change_price > 0 
                                    ? <td className="no-wrap text-success d-border-tr-gray text-right py-1"><i className="icofont icofont-caret-up"></i> 
                                        { cek !== undefined ? ConvertToRupiah(sStockSmry.change_price): ""}
                                    </td>
                                    : <td className="no-wrap text-warning d-border-tr-gray text-right py-1"> 
                                        { cek !== undefined ? ConvertToRupiah(sStockSmry.change_price): ""}
                                    </td>)
                            }   
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Open</td>
                        <td className={sStockSmry.open_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" :
                            (sStockSmry.open_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            { cek !== undefined ? ConvertToRupiah(sStockSmry.open_price) : "" }
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Vol(Lot)</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        { cek === undefined ? "" :  sStockSmry.traded_vol == 0 ? "0" :  CurrencyModifier('ifThousandMilion',Number(sStockSmry.traded_vol)/100) }
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">%</td>
                        <td className={ sStockSmry.change_price < 0 ? "no-wrap text-danger d-border-tr-gray text-right py-1" :
                            (sStockSmry.change_price > 0 ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            {cek !== undefined ? sStockSmry.change_price_pcts : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">High</td>
                        <td className={sStockSmry.high_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" : 
                            (sStockSmry.high_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            {cek !== undefined ? ConvertToRupiah(sStockSmry.high_price) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Value</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        {cek === undefined ? "" :  sStockSmry.traded_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockSmry.traded_val)}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Lower AR</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        {cek !== undefined ? ConvertToRupiah(lowerAR) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Low</td>
                        <td className={ sStockSmry.low_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" : 
                            (sStockSmry.low_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1"  )}>
                                {cek !== undefined ? ConvertToRupiah(sStockSmry.low_price): ""}
                            </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">F. Buy</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                            {cek === undefined ? "" :  sStockSmry.foreign_buy_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockSmry.foreign_buy_val)}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Upper AR</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">{ConvertToRupiah(upperAR)}</td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Avg.</td>
                        <td className={ average < prev ? "no-wrap d-border-tr-gray text-danger text-right py-1" : 
                            (average > prev ? "no-wrap d-border-tr-gray text-success text-right py-1" : "no-wrap d-border-tr-gray text-warning text-right py-1")}>
                            {cek !== undefined ? ConvertToRupiah(average) : ''}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">F. Sell</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        { cek === undefined ? "" : sStockSmry.foreign_sell_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockSmry.foreign_sell_val)}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding py-1">IEP</td>
                        <td className={`no-wrap text-right py-1 
                            ${ p.portfolioData.price !== undefined && 
                                ( p.portfolioData.price > prev ? "text-success" :  p.portfolioData.price < prev ? "text-danger" : "text-warning")}
                        `}>
                            {p.portfolioData.price !== undefined && 
                                Number(p.portfolioData.price) !== 0 &&
                                    ConvertToRupiah(Number(p.portfolioData.price))
                            }
                        </td>
                        <td className="no-wrap bg-gray-tradding py-1">IEV</td>
                        <td className="no-wrap text-right py-1">
                            {p.portfolioData.price !== undefined && 
                                Number(p.portfolioData.price) !== 0 &&
                                    ConvertToRupiah(Number(p.portfolioData.volume)/100)
                            }
                        </td>
                        <td className="no-wrap bg-gray-tradding py-1"></td>
                        <td className="no-wrap text-right py-1"></td>
                    </tr>
                    </tbody>
                </Table>

                {/*<div className="my-3"></div>*/}

                <div className="bg-dark-grey">
                    <div className="col-sm-12 row mx-0 px-0 d-border-gray-half">
                        <div className="col-sm-6 mx-0 px-0 d-border-right-half">
                            <div className="container-fluid px-0 mx-0">
                                <Table
                                    responsive
                                    bordered size="sm"
                                    id={"cmp"}
                                    className={`text-white bg-dark-grey px-0 mx-0 ${this.props.card || "card-362"} mb-0 table-hover table-striped-trans`}>
                                         {/* className={`text-white bg-dark-grey px-0 mx-0
                                            ${(this.props.lotshare == 'stockInfoBuy') ? 'card-323':
                                                (this.props.lotshare == 'stockInfoBuy2') ? 'card-356':
                                                    (this.props.lotshare == 'buyPage') ? 'card-356':
                                                        (this.props.lotshare == 'buyPageTrade') ? 'card-362':
                                                            (this.props.lotshare == 'autTradeOrd') ? 'card-362':'card-362'
                                                }
                                            mb-0 table-hover table-striped-trans`} */}
                                <thead className="d-border-top d-border-bottom bg-gray-tradding">
                                <tr>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">
                                        <Popup content='Number Of Buy' position='top center' trigger={
                                            <span>NoB</span>
                                        } />
                                    </th>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">Bid Vol</th>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">Bid</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                        this.props.bidData.length ? 
                                            (bidData.map((bid, index)=>{
                                                return <tr key={index} >
                                                        <td className="no-wrap py-1 text-right">{ConvertToRupiah(bid.noBidOffer)}</td>
                                                        <td className="no-wrap py-1 text-right">{ConvertToRupiah(bid.bidOfferVol)}</td>
                                                        <td className={Number(bid.bidOffer) < Number(prev) ? "no-wrap py-1 text-right text-danger" : 
                                                            (Number(bid.bidOffer) > Number(prev) ?  "no-wrap py-1 text-right text-success" : "no-wrap py-1 text-right text-warning" )} 
                                                            style={bid.bidOffer == "\xa0" ? {cursor:"auto"} : {cursor:"pointer"}}                                                     
                                                            onClick={bid.bidOffer == "\xa0" ? null : ()=>this.props.handleOnClick('transaction','bidOfferPrice',bid.bidOffer)}
                                                            >
                                                            {ConvertToRupiah(bid.bidOffer)}
                                                        </td>
                                                    </tr>
                                                })
                                            )
                                            : d.xBid.map((bid, index)=>{
                                            return(<tr key={index}>
                                                        <td className="no-wrap py-1 text-right">{bid.bidOffer}</td>
                                                        <td className="no-wrap py-1 text-right">{bid.bidOfferVol}</td>
                                                        <td className="no-wrap py-1 text-right">{bid.noBidOffer}</td>
                                                    </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className="d-border-top bg-gray-tradding">
                                    {bidData.length ?
                                        <tr>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumNoBid)}</th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumBidVol)}</th>
                                            <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                        </tr>
                                        :
                                        <tr>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                            <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                        </tr>                                        
                                    }
                                </tfoot>
                            </Table>
                            </div>
                        </div>
                        <div className="col-sm-6 mx-0 px-0 d-border-left-half">
                            <div className="container-fluid px-0 mx-0">
                                <Table
                                    responsive
                                    bordered size="sm"
                                    className={`text-white bg-dark-grey px-0 mx-0 ${this.props.card || "card-362"} mb-0 table-hover table-striped-trans`}>
                                    {/* ${(this.props.lotshare == 'stockInfoBuy') ? 'card-323':
                                        (this.props.lotshare == 'stockInfoBuy2') ? 'card-356':
                                            (this.props.lotshare == 'buyPage')? 'card-356':
                                                (this.props.lotshare == 'buyPageTrade') ? 'card-362':
                                                    (this.props.lotshare == 'autTradeOrd') ? 'card-362':'card-362'
                                    }
                                    mb-0 table-hover table-striped-trans`}> */}
                                <thead className="d-border-top d-border-bottom bg-gray-tradding">
                                <tr>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">Offer</th>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">Offer Vol</th>
                                    <th className="no-wrap py-3 text-center bg-gray-tradding">
                                        <Popup content='Number Of Sell' position='top center' trigger={
                                            <span>NoS</span>
                                        } />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.offerData.length ?
                                        (offerData.map((offer, index)=>{
                                            return <tr key={index}>
                                                        <td className={Number(offer.bidOffer) < Number(prev) ? "no-wrap py-1 text-right text-danger" : 
                                                            (Number(offer.bidOffer) > Number(prev) ? "no-wrap py-1 text-right text-success" : "no-wrap py-1 text-right text-warning")}
                                                            style={offer.bidOffer == "\xa0" ? {cursor:"auto"} : {cursor:"pointer"}}
                                                            onClick={offer.bidOffer == "\xa0" ? null : ()=>this.props.handleOnClick('transaction','bidOfferPrice', offer.bidOffer)}
                                                            >
                                                            {ConvertToRupiah(offer.bidOffer)}
                                                        </td>
                                                        <td className="no-wrap py-1 text-right">{ConvertToRupiah(offer.bidOfferVol)}</td>
                                                        <td className="no-wrap py-1 text-right">{ConvertToRupiah(offer.noBidOffer)}</td>
                                                    </tr>
                                        })) 
                                        : d.xBid.map((bid, index)=>{
                                            return(<tr key={index}>
                                                        <td className="no-wrap py-1 text-right">{bid.bidOffer}</td>
                                                        <td className="no-wrap py-1 text-right">{bid.bidOfferVol}</td>
                                                        <td className="no-wrap py-1 text-right">{bid.noBidOffer}</td>
                                                    </tr>
                                            )})
                                    }
                                </tbody>
                                <tfoot className="d-border-top bg-gray-tradding">
                                    {offerData.length ? 
                                        <tr>
                                            <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumOfferVol)}</th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumNoOffer)}</th>
                                        </tr>
                                    :<tr>
                                            <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                            <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                        </tr>
                                    }
                                </tfoot>
                            </Table>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<UncontrolledTooltip placement="top" target={this.props.lotshare} innerClassName="bg-primary">
                    600 Share
                </UncontrolledTooltip>*/}
            </>
        );
    }
}

class TableInfoTransactionWithButton_Base extends React.PureComponent{
    
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        if(e == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right text-white">
                    <i className="icofont icofont-close text-white text-icofont-close click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: BuyModal,
                onOpen :this.props.handleOnClick('trade', 'openTradeModal', e),
                onClose: ()=>this.props.handleOnClick('trade', 'closeTradeModal'),
            })
        }
    }

    buttonClickSell = (e) => {
        if(e == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right text-white">
                    <i className="icofont icofont-close text-white text-icofont-close click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: SellModal,
                onOpen :this.props.handleOnClick('trade', 'openTradeModal', e),
                onClose: ()=>this.props.handleOnClick('trade', 'closeTradeModal'),
            })
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var idOB = this.props.idOrderBook
            var newCodeText =this.refs.codeSearch.inputRef.current.value.toUpperCase()
            let newCode =newCodeText.split(".")  
            // check the text after the dot
            if(newCode[1] == "TN" || newCode[1] == undefined){
                var codeList = Object.keys(this.props.stockList)
                var codeResult = searchCode('code1', newCode[0], codeList)
                if(codeResult === undefined){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code not found!',)
                }else if(newCodeText == this.props.codeForOB1 || newCodeText == this.props.codeForOB2 || 
                    newCodeText == this.props.codeForOB3 ){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code already exists',)
                }else{
                    this.props.handleSearchCode('tradeOrderBook'+idOB, newCodeText);
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Code not found!',)
            }
            this.refs.codeSearch.inputRef.current.value =''
        }
    }

    render(){
        var idOB = this.props.idOrderBook
        var code , name, dataBid, dataOffer, sStockS, preOpening, cek, iSDForOB;
        if(idOB===1){            
            code = this.props.codeForOB1 
            name = this.props.stockList[strCall.code(this.props.codeForOB1)]
            dataBid = this.props.bidData1
            dataOffer = this.props.offerData1
            sStockS = this.props.sStockSForOB1;
            preOpening = this.props.iSDForOB1.trade_in_pre;            
            cek = this.props.sStockSForOB1.change_price
            iSDForOB = this.props.iSDForOB1;
        }else if(idOB===2){
            code = this.props.codeForOB2
            name = this.props.stockList[strCall.code(this.props.codeForOB2)]
            dataBid = this.props.bidData2
            dataOffer = this.props.offerData2
            sStockS = this.props.sStockSForOB2;
            preOpening = this.props.iSDForOB2.trade_in_pre
            cek = this.props.sStockSForOB2.change_price;
            iSDForOB = this.props.iSDForOB2;
        }else{
            code = this.props.codeForOB3
            name = this.props.stockList[strCall.code(this.props.codeForOB3)]
            dataBid = this.props.bidData3
            dataOffer = this.props.offerData3
            sStockS = this.props.sStockSForOB3;
            preOpening = this.props.iSDForOB3.trade_in_pre;
            cek = this.props.sStockSForOB3.change_price;
            iSDForOB = this.props.iSDForOB3;
        }

        // BiOffer Data
        var sumNoBid = sumDataArray(dataBid, 'object-noBidOffer');
        var sumBidVol = sumDataArray(dataBid, 'object-bidOfferVol');
        var sumNoOffer = sumDataArray(dataOffer, 'object-noBidOffer');
        var sumOfferVol = sumDataArray(dataOffer, 'object-bidOfferVol'); 

        // information Table
        var average = cek !== undefined && sStockS.traded_vol != 0 ? (sStockS.traded_val/sStockS.traded_vol).toFixed(0) : "0";
        var prev = sStockS.prev_price;
        var lowerAR;
        var upperAR;

        // pre opening?
        if(preOpening){
            var prevStatus = preOpening.includes("The stock can be traded");
            let AR = GetLowerUpper(prevStatus, sStockS.open_price, prev, iSDForOB)
            lowerAR = AR.lowerAR
            upperAR = AR.upperAR
        }
        return(
            
            <>                
                <AppFrameAction ref="frameAction" />
                <div className="col-sm-12 row pl-0 pr-1 mx-0 pt-0 pb-1">
                    <div className="col-sm-3 px-0 mx-0">
                        <Input ref={"codeSearch"}  onKeyDown={this.handleKeyDown} 
                            placeholder={code.length === 0 ? "Code" : code}  className={"col-md-12 px-0"}/>
                    </div>
                    <div className="col-sm-5 px-0 mx-0">
                        <Input value={name} placeholder='Code Name' className={"col-md-12 pr-0"} readOnly={true}/>
                    </div>

                    <div className="col-sm-4 align-self-center mx-0 px-0">
                        <button className="mx-1 pull-right col-sm-5 col-md-5 btn btn-sm btn-success" onClick={()=>this.buttonClickSell(code)}><span>Sell</span></button>
                        <button className="mx-1 pull-right col-sm-5 col-md-5 btn btn-sm btn-danger" onClick={()=>this.buttonClickBuy(code)}><span>Buy</span></button>
                    </div>
                </div>

                <Table responsive borderless size="sm" className="text-white d-border-table bg-dark-grey card-140 mb-1">
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Last</td>
                        <td className={ sStockS.change_price < 0 ? "no-wrap text-danger d-border-tr-gray text-right py-1" 
                            : ( sStockS.change_price > 0  ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            {/* { cek !== undefined ?  ConvertToRupiah(sStockS.close_price) : ""} */}
                            { cek !== undefined ? sStockS.close_price == 0 ? <i className="icofont icofont-minus"></i> : ConvertToRupiah( Number(sStockS.close_price)) : ""}

                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Previous</td>
                        <td className="no-wrap text-warning d-border-tr-gray text-right py-1">
                            { cek !== undefined ? ConvertToRupiah(sStockS.prev_price) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Freq</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                            { cek !== undefined ? ConvertToRupiah(sStockS.traded_freq) : ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Change</td>
                        {
                                cek !== undefined ?
                                (sStockS.change_price < 0 
                                ? <td className="no-wrap text-danger d-border-tr-gray text-right py-1"><i className="icofont icofont-caret-down"></i> 
                                    { ConvertToRupiah(sStockS.change_price)}
                                </td>
                                : (sStockS.change_price > 0 
                                    ? <td className="no-wrap text-success d-border-tr-gray text-right py-1"><i className="icofont icofont-caret-up"></i> 
                                        { ConvertToRupiah(sStockS.change_price)}
                                    </td>
                                    : <td className="no-wrap text-warning d-border-tr-gray text-right py-1"> 
                                        { ConvertToRupiah(sStockS.change_price)}
                                    </td>)) : <td className="no-wrap text-warning d-border-tr-gray text-right py-1"></td>
                            }
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Open</td>
                        <td className={sStockS.open_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" 
                            : (sStockS.open_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            { cek !== undefined ? ConvertToRupiah(sStockS.open_price) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Vol(Lot)</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                        { cek === undefined ? "" :  sStockS.traded_vol == 0 ? "0" :  CurrencyModifier('ifThousandMilion',Number(sStockS.traded_vol)/100) }
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">%</td>
                        <td className={ sStockS.change_price < 0 ? "no-wrap text-danger d-border-tr-gray text-right py-1" 
                            : (sStockS.change_price > 0 ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            { cek !== undefined ? sStockS.change_price_pcts : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">High</td>
                        <td className={sStockS.high_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" : 
                            (sStockS.high_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1")}>
                            { cek !== undefined ? ConvertToRupiah(sStockS.high_price) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Value</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                            {cek === undefined ? "" :  sStockS.traded_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockS.traded_val)}
                            {/* { cek !== undefined ? ConvertToRupiah((sStockS.traded_val/1000000000).toFixed(1))+' B' : ""} */}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Lower AR</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                            { cek !== undefined ? ConvertToRupiah(lowerAR) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Low</td>
                        <td className={ sStockS.low_price < prev ? "no-wrap text-danger d-border-tr-gray text-right py-1" : 
                            (sStockS.low_price > prev ? "no-wrap text-success d-border-tr-gray text-right py-1" 
                            : "no-wrap text-warning d-border-tr-gray text-right py-1"  )}>
                            { cek !== undefined ? ConvertToRupiah(sStockS.low_price) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">F. Buy</td>
                        <td className="no-wrap d-border-tr-gray text-right py-1">
                            {cek === undefined ? "" :  sStockS.foreign_buy_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockS.foreign_buy_val)}
                            {/* { cek !== undefined ? (sStockS.foreign_buy_val/1000000000).toFixed(1)+' B' : ""} */}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-wrap bg-gray-tradding py-1">Upper AR</td>
                        <td className="no-wrap text-right py-1">
                            { cek !== undefined ? ConvertToRupiah(upperAR) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding py-1">Avg.</td>
                        <td className={ average < prev ? "no-wrap text-danger text-right py-1" : 
                            (average > prev ? "no-wrap text-success text-right py-1" 
                            : "no-wrap text-warning text-right py-1")}>
                            { cek !== undefined ? ConvertToRupiah(average) : ""}
                        </td>
                        <td className="no-wrap bg-gray-tradding py-1">F. Sell</td>
                        <td className="no-wrap text-right py-1">
                            { cek === undefined ? "" : sStockS.foreign_sell_val == 0 ? "0" : CurrencyModifier('ifThousandMilion',sStockS.foreign_sell_val)}
                            {/* { cek !== undefined ?  (sStockS.foreign_sell_val/1000000000).toFixed(1)+' B' : ""} */}
                        </td>
                    </tr>
                    </tbody>
                </Table>

                <div className="card bg-dark-grey">
                    <div className="col-sm-12 row mx-0 px-0 d-border-gray">
                        <div className="col-sm-6 mx-0 px-0 d-border-right-half">
                            <div className="container-fluid px-0 mx-0">
                                <Table responsive bordered size="sm" className="text-white bg-dark-grey px-0 mx-0 card-310-tr table-hover table-striped-trans mb-0">
                                    <thead className="d-border-top d-border-bottom bg-gray-tradding">
                                    <tr>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">
                                            <Popup content='Number Of Buy' position='top center' trigger={
                                                <span>NoB</span>
                                            } />
                                        </th>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">Bid Vol</th>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">Bid</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataBid.length ? 
                                            dataBid.map((bid, index)=>{
                                                return(<tr key={index}>
                                                    <td className="no-wrap py-1 text-right">{ConvertToRupiah(bid.noBidOffer)}</td>
                                                    <td className="no-wrap py-1 text-right">{ConvertToRupiah(bid.bidOfferVol)}</td>
                                                    <td className={`no-wrap py-1 text-right ${colorText(bid.bidOffer, prev)}`}
                                                    // {bid.bidOffer < prev ? "no-wrap py-1 text-right text-danger" : 
                                                    //     (bid.bidOffer > prev ?  "no-wrap py-1 text-right text-success" : "no-wrap py-1 text-right text-warning" )}
                                                        > 
                                                    {ConvertToRupiah(bid.bidOffer)}
                                                    </td>
                                                </tr>)})                                            
                                            : d.xBid.map((bid, index)=>{
                                                return(<tr key={index}>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                        </tr>
                                                )})
                                            }
                                    </tbody> 
                                    <tfoot className="d-border-top bg-gray-tradding">
                                        {
                                            dataBid.length ? 
                                                <tr>
                                                    <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumNoBid)}</th>
                                                    <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumBidVol)}</th>
                                                    <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                                </tr>
                                            : <tr>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                                <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                            </tr>
                                        }
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                        <div className="col-sm-6 mx-0 px-0 d-border-left-half">
                            <div className="container-fluid px-0 mx-0">
                                <Table responsive bordered size="sm" className="text-white bg-dark-grey px-0 mx-0 card-310-tr table-hover table-striped-trans mb-0">
                                    <thead className="d-border-top d-border-bottom bg-gray-tradding">
                                    <tr>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">Offer</th>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">Offer Vol</th>
                                        <th className="no-wrap py-3 text-center bg-gray-tradding">
                                            <Popup content='Number Of Sell' position='top center' trigger={
                                                <span>NoS</span>
                                            } />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataOffer.length ? 
                                                dataOffer.map((offer, index)=>{
                                                    return(<tr key={index}>
                                                            <td className={`no-wrap py-1 text-right ${colorText(offer.bidOffer , prev)}`}
                                                            // {offer.bidOffer < prev ? "no-wrap py-1 text-right text-danger" : 
                                                            //     (offer.bidOffer > prev ? "no-wrap py-1 text-right text-success" : "no-wrap py-1 text-right text-warning")}
                                                                >
                                                                {ConvertToRupiah(offer.bidOffer)}
                                                            </td>
                                                            <td className="no-wrap py-1 text-right">{ConvertToRupiah(offer.bidOfferVol)}</td>
                                                            <td className="no-wrap py-1 text-right">{ConvertToRupiah(offer.noBidOffer)}</td>
                                                        </tr>
                                                    )
                                                })
                                            : d.xBid.map((bid, index)=>{
                                                return(<tr key={index}>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                            <td className="no-wrap py-1 text-right">&nbsp;</td>
                                                        </tr>
                                                )})
                                        }
                                    </tbody>
                                    <tfoot className="d-border-top bg-gray-tradding">
                                        {
                                            dataOffer.length ?
                                            <tr>
                                                <th className="no-wrap py-3 text-center bg-gray-tradding">Total</th>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumOfferVol)}</th>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding">{ConvertToRupiah(sumNoOffer)}</th>
                                            </tr>
                                            :<tr>
                                                <th className="no-wrap py-3 text-center bg-gray-tradding"></th>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding"></th>
                                                <th className="no-wrap py-3 text-right bg-gray-tradding">Total</th>
                                            </tr>
                                        }
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//************************** Context Connector **************************
const TableInfoTransaction = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockList:vars.stockList, 
        bidOfferCode :vars.bidOfferCode,   
        portfolioData:vars.portfolioData,
        haircutCode:vars.haircutCode,     
        bidData:vars.bidData,
        offerData:vars.offerData,
        iStockData:vars.iStockData,
        bidOfferPrice:vars.bidOfferPrice,   
        bidOfferVol:vars.bidOfferVol,
        bidOfferVal:vars.bidOfferVal,
        bidOfferMkt:vars.bidOfferMkt,
        prevStatus:vars.prevStatus,
        clientOrderId:vars.clientOrderId,
        orbookStatus:vars.orbookStatus,        
        lowerAR:vars.lowerAR,
        sStockSmry:vars.sStockSmry,
        upperAR:vars.upperAR,
        offerAlldStatus:vars.offerAlldStatus,
        regulerId:vars.regulerId,
        transactionType:vars.transactionType,
        amendOrderModalStatus:vars.amendOrderModalStatus,
        handleCount:(typeAction, typeCount,price)=>actions.sendAction('handleCount',{typeAction, typeCount,price}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode', {type, code}),
        // alert
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleCount", "handleOnClick", "handleSearchCode", "handleStatusAlert3"]
)(TableInfoTransaction_Base)

const TableInfoTransactionWithButton = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        stockList:vars.stockList,     
        codeForOB1:vars.codeForOB1,
        codeForOB2:vars.codeForOB2,
        codeForOB3:vars.codeForOB3,
        bidData1:vars.bidData1,
        bidData2:vars.bidData2,
        bidData3:vars.bidData3,
        offerData1:vars.offerData1,
        offerData2:vars.offerData2,
        offerData3:vars.offerData3,
        sStockSForOB1:vars.sStockSForOB1,
        sStockSForOB2:vars.sStockSForOB2,
        sStockSForOB3:vars.sStockSForOB3,
        iSDForOB1:vars.iSDForOB1,
        iSDForOB2:vars.iSDForOB2,
        iSDForOB3:vars.iSDForOB3,
        trxTypeForOB1:vars.trxTypeForOB1,
        trxTypeForOB2:vars.trxTypeForOB2,
        trxTypeForOB3:vars.trxTypeForOB3,
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode', {type, code}),
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleSearchCode", "handleOnClick", "handleStatusAlert3"]
)(TableInfoTransactionWithButton_Base)

export default TableInfoTransaction;
export {TableInfoTransactionWithButton};