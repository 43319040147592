import * as fg from "./appFunctions.js";

const startConfig = () => {
    return {
        nor: {
            // Header
                boxHeaderReady: 10,

            // Landing Page / My Acount Page
                // 1. Investment Board
                portfolio:ceksize("portfolio"),
                fixedIncome:ceksize('fixedIncome'),
                mutualFund: ceksize("mutualFund"),
                // 2. Stock & cash
                stockCash: ceksize('stockCash'),
                // 3. Historical : "tradeListHistory||orderHistory||cashTransactionHistory||stockTransactionHistory||stockTransactionHistory||tradePl"
                historical: ceksize('historical'),
                // 4. Fund Transfer
                fundTransferList: ceksize("fundTransferList"),
                cancel1: ceksize("cancel1"),
                cancel2: ceksize("cancel2"),                

            // Market&Statistic Page
                // 1. summary 
                summary: ceksize("summary"), 
                // 3. Sectoral atas
                sectoral: ceksize("sectoral"),
                // 5. Top Broker
                topBroker: ceksize("topBroker"),

            // Stock Page
                // 1. Stock Info
                corpAction: ceksize("corpAction"),
                // 2. Stock Wathclist
                stockWatchlist: ceksize("stockWatchlist"),
                // 3. Stock Trade History Daily : "stockTradeHistoryForeign||stockTradeHistoryBroker"
                stockTradeHistoryMain: ceksize("stockTradeHistoryDaily"),
                // "stockTradeHistoryPrice||stockTradeHistoryBuy||stockTradeHistorySell||stockTradeHistoryBrokerChartBuy
                // stockSummaryBuyer||stockTradeHistoryBrokerChartSell||stockTradeHistoryBrokerChartNet"
                stockTradeHistoryMini: ceksize("stockTradeHistoryMini"),
                // 4. Stock Trade Summary
                stockTradeSummaryMain: ceksize("stockTick"),
                stockTradeSummary: ceksize("rgTrade"),
                stockSummaryBuyer: ceksize("stockTradeSummaryMini"),
            
            // Trade page
                // 1. Order list
                orderList: ceksize("orderList"),
                tradeList: ceksize("orderListMini"),
                tradeOrderSum: ceksize("orderListMini"),
                tradeTradeSum: ceksize("orderListMini"),
                // 3. Order Book List
                tradeOrderBookList: ceksize("orderBookingList"),
                // 4. ADVERTISEMENT LIST
                tradeAdv: ceksize("advList"),
                // 5. Automatic Order Setting
                orderSetttingList: ceksize("orderSetttingList"),
                // 6. Sent Order List
                tradeSendOrder: ceksize("sentOrderList"),

            // livetrade Page
                lvtrade: ceksize("liveTrade"),

            // Footer
                boxFooterReady: 14,
            // MODAL 
                // 1.Order Detail
                orderDetailCode: 10,
            },
        init: {
                reconnect: 1000,
                
            // Header
                boxHeaderReady: fg.GetBoxHeaderReady(fd.sectoralIndexCode),

            // Sidebar
                sidebarCode: ['\xa0','\xa0','\xa0','\xa0','\xa0','\xa0','\xa0'],
                sidebarData: GetInitialState(7, fd.sidebarData),

            // My Account Page / Landing Page
                // 1. investment board
                portfolio: GetInitialState(ceksize("portfolio"), fd.portfolio),
                portofolioInfo: {balance: 0, mktVal: 0, pl: 0, plRatio: 0, stream: true, lastReq: "", refresh: true},
                fixedIncome: GetInitialState(ceksize("fixedIncome"), fd.fixedIncome),
                mutualFund: GetInitialState(ceksize('mutualFund'), fd.mutualFund),
                // 2. Stock & Cash
                stockCash: GetInitialState(ceksize('stockCash'), fd.stockCash),
                stockCashInfo: {stream: true, refresh: true, lastReq: "", user_name: "", bank_account: "", balance: 0, pl: 0, plRatio: 0, clRatio: 0, bl: 0, additionalBl: 0, liquidVal: 0, mktVal: 0},
                scSettlement: [
                    { "label": "Receiveable", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Payable", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Tax + Fee", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Penalty", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Settlement Amount", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Cash Balance", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Fund Transfer", "t0": 0, "t1": 0, "t2": 0 },
                    { "label": "Total", "t0": 0, "t1": 0, "t2": 0 },
                ],
                // 3. Historical
                tradeListHistory: GetInitialState(ceksize('historical'), fd.tradeListHistory),
                orderHistory: GetInitialState(ceksize('historical'), fd.orderHistory),
                stockTransactionHistory: GetInitialState(ceksize('historical'), fd.stockTransactionHistory),
                cashTransactionHistory: GetInitialState(ceksize('historical'), fd.cashTransactionHistory),
                // 4. Fund Transfer
                fundTransferList:  GetInitialState(ceksize('fundTransferList'), fd.fundTransferList),
                fundTransferCancel1:  GetInitialState(ceksize('cancel1'), fd.fundTransferCancel1),
                fundStockList: GetInitialState(ceksize("fundTransferList"), fd.fundStockList),
                fundTransferCancel2:  GetInitialState(ceksize('cancel2'), fd.fundTransferCancel2),
                /**Warant / Right Exercise */
                exerciseList: GetInitialState(ceksize("fundTransferList"), fd.exerciseList),
                // 5.Account Info
                accountInfo: {
                    ai1: [
                        {"label": "Account Name", "value": ""},
                        {"label": "KSEI A/C No", "value": ""},
                        {"label": "Alt Code", "value": ""},                        
                        {"label": "KSEI SID", "value": ""},
                        // {"label": "User ID", "value": ""},
                        // {"label": "A/C Status Name", "value": ""},
                        {"label": "A/C Status", "value": ""},
                        {"label": "ID Type", "value": ""},
                        {"label": "ID No", "value": ""},
                        {"label": "ID Expire Date", "value": ""},
                        {"label": "Dividend Tax", "value": ""},
                        {"label": "Commission Rate", "value": ""},
                        {"label": "Sales Commission", "value": ""},
                    ],
                    ai2: [
                        // {"label": "Tax Rate", "value": ""},
                        {"label": "Managing Branch", "value": ""},
                        {"label": "Country", "value": ""},
                        {"label": "Job", "value": ""},
                        {"label": "Opening Date", "value": ""},
                        {"label": "Opening Branch", "value": ""},

                        // {"label": "Bank Code", "value": ""},
                        // {"label": "Branch", "value": ""},
                        // {"label": "Bank Account No", "value": ""},
                        // {"label": "Bank Account Name", "value": ""},
                        // {"label": "ai2", "value": ""},
                        // {"label": "-", "value": ""},
                        
                        {"label": "ai2", "value": ""},
                        {"label": "RDI Bank", "value": ""},
                        {"label": "RDI Branch", "value": ""},
                        {"label": "RDI Account No", "value": ""},
                        {"label": "RDI Account Name", "value": ""},
                    ],
                    bankAccount: {
                        ba1: [
                            {"label": "Bank Code", "value": ""},
                            {"label": "Branch", "value": ""},
                            {"label": "Bank Account No", "value": ""},
                            {"label": "Bank Account Name", "value": ""},
                        ],
                        ba2: [
                            {"label": "Bank Code", "value": ""},
                            {"label": "Branch", "value": ""},
                            {"label": "Bank Account No", "value": ""},
                            {"label": "Bank Account Name", "value": ""},
                        ],
                        ba3: [
                            {"label": "Bank Code", "value": ""},
                            {"label": "Branch", "value": ""},
                            {"label": "Bank Account No", "value": ""},
                            {"label": "Bank Account Name", "value": ""},
                        ]
                    },
                    ci1: [
                        {"label": "Date of Birth", "value": ""},
                        {"label": "Place of Birth", "value": ""},
                        {"label": "Position", "value": ""},
                        {"label": "Company Name", "value": ""},
                        {"label": "Company Type", "value": ""},
                    ],
                    ciJob: [
                        {"label": "Unknown Addr/Phone", "value": ""},
                        {"label": "Email", "value": ""},
                        {"label": "Mobile1", "value": ""},
                        {"label": "Mobile2", "value": ""},     
                        {"label": "-", "value": ""},
                    ],
                    ciContact: [
                        {"item": "\xa0", "telno": "", "faxno": ""},
                        {"item": "\xa0", "telno": "", "faxno": ""},
                        {"item": "\xa0", "telno": "", "faxno": ""},
                    ],
                    ciAddress: [
                        {"item": "\xa0", "postno": "", "address": "", "address2": ""},
                        {"item": "\xa0", "postno": "", "address": "", "address2": ""},
                        {"item": "\xa0", "postno": "", "address": "", "address2": ""},
                    ],
                    rdi:[{"label": "RDI Bank", "value": ""},
                        {"label": "RDI Account Number", "value": ""},
                        {"label": "RDI Account Name", "value": ""},
                    ]
                },
                // 5.5 Trade PL
                tradePlInfo: {sellAmount: 0, buyAmount: 0, salesPl: 0, perSalesPl: 0},
                tradePl: GetInitialState(8, fd.tradePl),
                // 6.TC & Soe
                tradeControlSoe: GetInitialState(ceksize('cancel2'), fd.tradeControlSoe),

            // Market & Statistic Page
                // 2. market Index
                marktIndex: { RG_val: 0, NG_val: 0, TN_val: 0, BValSum: 0, RG_vol: 0,
                    NG_vol: 0, TN_vol: 0, BVolSum: 0, RG_freq: 0, NG_freq: 0, TN_freq: 0,
                    BFreqSum: 0, fbuy_val: 0, fsell_val: 0, ftotal_val: 0, fnet_val: 0, fbuy_vol : 0,
                    fsell_vol: 0, ftotal_vol: 0, fnet_vol: 0, fbuy_freq: 0, fsell_freq: 0, ftotal_freq: 0,
                    fnet_freq: 0, right_val: 0, right_vol: 0, right_freq: 0, warrant_val: 0, warrant_vol: 0, warrant_freq: 0, 
                },
                // 4. Top Broker
                topBrokerVal:  GetInitialState(ceksize("topBroker"), fd.topBrokerVal), 
                topBrokerBuy: GetInitialState(ceksize("topBroker"), fd.topBrokerBuy),
                topBrokerSell: GetInitialState(ceksize("topBroker"), fd.topBrokerSell),

                // CURRENCY & COMMODITY
                currencies: GetInitialState(16, fd.currencies),
                commodity: GetInitialState(16, fd.commodity),
                
            // Broker: Broker info, Broker trade summary, Broker trade history, top broker
                brokerInfoDetail: [
                    {"label": "Nama Member", "value": ""},
                    {"label": "No Akte", "value": ""},
                    {"label": "Modal Dasar*", "value": ""},
                    {"label": "Modal Disetor*", "value": ""},
                    {"label": "Status Perusahaan", "value": ""},
                    {"label": "Status Operasiona", "value": ""},
                    {"label": "Ijin Usaha", "value": ""},
                    // {"label": "Nilai MKBD Terakhir", "value": ""},
                    {"label": "Kantor Pusat", "value": ""},
                    {"label": "Nomor Telepon", "value": ""},
                    {"label": "Faks", "value": ""},
                    {"label": "Kode Pos", "value": ""},
                    {"label": "Situs", "value": ""}
                ],
                brokerTradeSum1: GetInitialState(ceksize("historical"), fd.brokerTradeSum1),
                brokerTradeSum2: GetInitialState(ceksize("orderList")-2, fd.brokerTradeSum2),
                brokerTradeHistory: GetInitialState(ceksize('historical')-1, fd.brokerTradeHistory),
            
            // Stock Page
                // 1. Stock Info
                corpAction: GetInitialState(ceksize("corpAction"), fd.corpAction),
                // 1.5 Stock Financial financial / Report
                stockFinancialReport : [
                    { label: "Last Statement", v1: "Book Value", v2: "EPS", v3: "PER", v4: "PBV" },
                    { label: "\xa0", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Quarter/Year", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Sales Revenue", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Gross Profit", v1: "", v2: "", v3: "", v4: "" },
                    { label: "EBITDA", v1: "", v2: "", v3: "", v4: "" },
                    { label: "NET Income", v1: "", v2: "", v3: "", v4: "" },
                    { label: "EPS", v1: "", v2: "", v3: "", v4: "" },
                    { label: "colspan", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Total Asset", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Total Liabilities", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Total Equity", v1: "", v2: "", v3: "", v4: "" },
                    { label: "colspan", v1: "", v2: "", v3: "", v4: "" },
                    { label: "PER", v1: "", v2: "", v3: "", v4: "" },
                    { label: "PBV", v1: "", v2: "", v3: "", v4: "" },
                    { label: "ROA", v1: "", v2: "", v3: "", v4: "" },
                    { label: "ROE", v1: "", v2: "", v3: "", v4: "" },
                    { label: "Book Value", v1: "", v2: "", v3: "", v4: "" }
                ],
                // 2. Stock Watchlist
                stockWatchlist: GetInitialState(ceksize("stockWatchlist"), fd.stockWatchlist),
                // 3. Stock Trade History
                stockTradeHistorySMRY: {
                    "change_price": "0.0", 
                    "close_price": "0.0",
                    "change_price_pcts": "0.0",
                    "high_price": "0.0",
                    "low_price":"0.0",
                    "traded_vol":"0",
                    "traded_val":"0.0",
                    "foreign_buy_val":"0.0",
                    "foreign_sell_val":"0.0",
                }, 
                stockTradeHistoryMain: GetInitialState(ceksize("stockTradeHistoryDaily"), fd.stockTradeHistoryMain),
                stockTradeHistoryPrice: GetInitialState(ceksize("stockTradeHistoryMini"), fd.stockTradeHistoryPrice),
                stockTradeHistoryBuy: GetInitialState(ceksize("stockTradeHistoryMini"), fd.stockTradeHistoryBuy),
                stockTradeHistorySell: GetInitialState(ceksize("stockTradeHistoryMini"), fd.stockTradeHistorySell),
                // 4. Stock Trade Summary
                stockTickMain: GetInitialState(ceksize("stockTick"), fd.stockTickMain),
                stockTradeSummary: GetInitialState(ceksize("rgTrade"), fd.stockTradeSummary),
                stockSummaryBuyer: GetInitialState(ceksize("stockTradeSummaryMini"), fd.stockSummaryBuyer),
                specialNotation: GetInitialState(ceksize("stockTradeHistoryDaily"), fd.specialNotation),

            // Trade Page
                // 1. Orderlist
                orderlist: GetInitialState(ceksize("orderList"), fd.orderList),
                tradeList: GetInitialState(ceksize("orderListMini"), fd.tradeList),            
                tradeOrderSum: GetInitialState(ceksize("orderListMini"), fd.tradeOrderSum),            
                tradeTradeSum: GetInitialState(ceksize("orderListMini"), fd.tradeTradeSum),
                // 3. ORDERBOOK LIST
                tradeOrderBookList : GetInitialState(ceksize("orderBookingList"), fd.tradeOrderBookList), 
                // 4. ADVERTISEMENT LIST
                tradeAdv: GetInitialState(ceksize("advList"), fd.tradeAdv),
                // 5. Automatic Order Setting
                orderPriceOption: [
                    {key: '14',value: 'MANUAL',text: 'Manual Input'},
                    {key: '1',value: 'BO5',text: 'Offer + 5 ticks'},
                    {key: '2',value: 'BO4',text: 'Offer + 4 ticks'},
                    {key: '3',value: 'BO3',text: 'Offer + 3 ticks'},
                    {key: '4',value: 'BO2',text: 'Offer + 2 ticks'},
                    {key: '5',value: 'BO1',text: 'Offer + 1 tick'},
                    {key: '6',value: 'BO0',text: 'Best Offer Price'},
                    // {key: '7',value: 'LP0',text: 'Last Price'},
                    {key: '8',value: 'BB0',text: 'Best Bid Price'},
                    {key: '9',value: 'BB-1',text: 'Bid - 1 tick'},
                    {key: '10',value: 'BB-2',text: 'Bid - 2 ticks'},
                    {key: '11',value: 'BB-3',text: 'Bid - 3 ticks'},
                    {key: '12',value: 'BB-4',text: 'Bid - 4 ticks'},
                    {key: '13',value: 'BB-5',text: 'Bid - 5 ticks'},
                ],
                orderSetttingList: GetInitialState(ceksize("orderSetttingList"), fd.orderSetttingList),
                setCondAutomatic: {
                    orderType: "1",
                    orderVol: 0,
                    orderPrice: "MANUAL",
                    manualPrice: 0,
                    typePrice: "LP",
                    condPrice: "L",
                    setPrice: 0,
                    date: "",
                    statusOrder: true,
                },
                // 6. SENT ORDER
                tradeSendOrder: GetInitialState(ceksize("historical"), fd.tradeSendOrder),
                autExpOrderList:  GetInitialState(ceksize("historical"), fd.autExpOrderList),
            
            // livetrade Page
                lvtrade: GetInitialState(ceksize("liveTrade"), fd.lvtrade),

            // Footer
                boxFooterReady: GetInitialState(14, fd.boxFooterReady),
            // MODAL : 
                // 1.Order Detail
                orderDetailCode: GetInitialState(6, fd.orderDetailCode),
                // 2. Trade History
                transactionHistory: GetInitialState(3, fd.transactionHistory),
        }
    }
}
// check size function
function ceksize(type, hg){
    let nheight = hg === undefined ? window.innerHeight : hg
    if(nheight <= 646){
        switch (type) {
            //landing page
                case "portfolio": return "15"; break;
                case "fixedIncome": return "15"; break;
                case "mutualFund": return "12"; break;
                case "stockCash": return "9"; break;
                //Historical untuk semua
                case "historical": return "14"; break;
                //Fund Transfer
                case "fundTransferList": return "14"; break;
                // fund transfer cancel aggrid pertama
                case "cancel1": return "10"; break;
                case "cancel2": return "10"; break;

            //Market
                case "summary": return "15"; break;
                // sectoral atas
                case "sectoral": return "9"; break;
                // mencangkup semua top broker AgGrid
                case "topBroker": return "6"; break;
            
            //Broker
                case "brokerTradeSummary": return "16";break;
                case "brokerTradeHistory": return "15";break;

            //Stock
                case "corpAction": return "5"; break;
                case "stockWatchlist": return "18"; break;

                //mencangkup daily, broker dan foreign net
                case "stockTradeHistoryDaily": return "14"; break;

                //mencangkup semua AgGrid yang berjejer di kanan
                case "stockTradeHistoryMini": return "4"; break;

                case "stockTick": return "6"; break;
                case "rgTrade": return "12"; break;

                //mencangkup semua AgGrid mini yang berada di bawah stock tick
                case "stockTradeSummaryMini": return "5"; break;

            //TradeList
                case "orderList": return "10"; break;

                //mencangkup semua AgGrid mini yang berada di bawah ordder list
                case "orderListMini": return "5"; break;
                case "orderBookingList": return "18"; break;

                //mencangkup semua AgGrid pada adv list
                case "advList": return "15"; break;

                case "orderSetttingList": return "6"; break;
                case "sentOrderList": return "19"; break;

            //LiveTrade
                case "liveTrade": return "20"; break;
        }
    }
    else if(nheight > 647 && nheight <= 694){
        switch (type) {
            //landing page
            case "portfolio": return "15"; break;
            case "fixedIncome": return "15"; break;
            case "mutualFund": return "12"; break;
            case "stockCash": return "9"; break;
            //Historical untuk semua
            case "historical": return "14"; break;
            //Fund Transfer
            case "fundTransferList": return "14"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "6"; break;
            case "cancel2": return "6"; break;

            //Broker
            case "brokerTradeSummary": return "17";break;
            case "brokerTradeHistory": return "16";break;

            //Market
            case "summary": return "15"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "6"; break;


            //Stock
            case "corpAction": return "5"; break;
            case "stockWatchlist": return "18"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "14"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "4"; break;

            case "stockTick": return "6"; break;
            case "rgTrade": return "12"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "10"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "5"; break;
            case "orderBookingList": return "19"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "15"; break;

            case "orderSetttingList": return "6"; break;
            case "sentOrderList": return "19"; break;

            //LiveTrade
            case "liveTrade": return "20"; break;
        }
    }
    else if(nheight > 695 && nheight <= 722){
        switch (type) {
            //landing page
            case "portfolio": return "16"; break;
            case "fixedIncome": return "16"; break;
            case "mutualFund": return "13"; break;
            case "stockCash": return "10"; break;
            //Historical untuk semua
            case "historical": return "15"; break;
            //Fund Transfer
            case "fundTransferList": return "15"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "6"; break;
            case "cancel2": return "6"; break;

            //Broker
            case "brokerTradeSummary": return "17";break;
            case "brokerTradeHistory": return "16";break;

            //Market
            case "summary": return "16"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "7"; break;


            //Stock
            case "corpAction": return "5"; break;
            case "stockWatchlist": return "18"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "14"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "4"; break;

            case "stockTick": return "6"; break;
            case "rgTrade": return "12"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "11"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "6"; break;
            case "orderBookingList": return "21"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "17"; break;

            case "orderSetttingList": return "8"; break;
            case "sentOrderList": return "21"; break;

            //LiveTrade
            case "liveTrade": return "21"; break;
        }
    }
    else if(nheight > 723 && nheight <= 826){
        switch (type) {
            //landing page
            case "portfolio": return "18"; break;
            case "fixedIncome": return "18"; break;
            case "mutualFund": return "14"; break;
            case "stockCash": return "11"; break;
            //Historical untuk semua
            case "historical": return "17"; break;
            //Fund Transfer
            case "fundTransferList": return "17"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "6"; break;
            case "cancel2": return "6"; break;

            //Broker
            case "brokerTradeSummary": return "21";break;
            case "brokerTradeHistory": return "20";break;

            //Market
            case "summary": return "17"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "8"; break;


            //Stock
            case "corpAction": return "6"; break;
            case "stockWatchlist": return "20"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "15"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "5"; break;

            case "stockTick": return "7"; break;
            case "rgTrade": return "13"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "12"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "6"; break;
            case "orderBookingList": return "22"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "18"; break;

            case "orderSetttingList": return "9"; break;
            case "sentOrderList": return "22"; break;

            //LiveTrade
            case "liveTrade": return "22"; break;
        }
    }
    else if(nheight > 827 && nheight <= 886){
        switch (type) {
            //landing page
            case "portfolio": return "19"; break;
            case "fixedIncome": return "19"; break;
            case "mutualFund": return "15"; break;
            case "stockCash": return "12"; break;
            //Historical untuk semua
            case "historical": return "17"; break;
            //Fund Transfer
            case "fundTransferList": return "17"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "7"; break;
            case "cancel2": return "7"; break;

            //Broker
            case "brokerTradeSummary": return "23";break;
            case "brokerTradeHistory": return "22";break;

            //Market
            case "summary": return "18"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "8"; break;


            //Stock
            case "corpAction": return "6"; break;
            case "stockWatchlist": return "21"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "16"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "5"; break;

            case "stockTick": return "8"; break;
            case "rgTrade": return "14"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "13"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "6"; break;
            case "orderBookingList": return "23"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "19"; break;

            case "orderSetttingList": return "11"; break;
            case "sentOrderList": return "23"; break;

            //LiveTrade
            case "liveTrade": return "25"; break;
        }
    }
    else if(nheight > 887 && nheight <= 976){
        switch (type) {
            //landing page
            case "portfolio": return "21"; break;
            case "fixedIncome": return "21"; break;
            case "mutualFund": return "16"; break;
            case "stockCash": return "13"; break;
            //Historical untuk semua
            case "historical": return "19"; break;
            //Fund Transfer
            case "fundTransferList": return "19"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "8"; break;
            case "cancel2": return "8"; break;

            //Market
            case "summary": return "20"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "9"; break;

            //Broker
            case "brokerTradeSummary": return "27";break;
            case "brokerTradeHistory": return "26";break;

            //Stock
            case "corpAction": return "7"; break;
            case "stockWatchlist": return "23"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "18"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "6"; break;

            case "stockTick": return "11"; break;
            case "rgTrade": return "17"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "14"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "6"; break;
            case "orderBookingList": return "25"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "21"; break;

            case "orderSetttingList": return "13"; break;
            case "sentOrderList": return "25"; break;

            //LiveTrade
            case "liveTrade": return "27"; break;
        }
    }
    else if(nheight > 977 && nheight <= 1006){
        switch (type) {
            //landing page
            case "portfolio": return "23"; break;
            case "fixedIncome": return "23"; break;
            case "mutualFund": return "18"; break;
            case "stockCash": return "15"; break;
            //Historical untuk semua
            case "historical": return "21"; break;
            //Fund Transfer
            case "fundTransferList": return "21"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "9"; break;
            case "cancel2": return "9"; break;

            //Market
            case "summary": return "23"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "11"; break;

            //Broker
            case "brokerTradeSummary": return "28";break;
            case "brokerTradeHistory": return "27";break;

            //Stock
            case "corpAction": return "9"; break;
            case "stockWatchlist": return "29"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "24"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "8"; break;

            case "stockTick": return "16"; break;
            case "rgTrade": return "22"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "16"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "7"; break;
            case "orderBookingList": return "28"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "24"; break;

            case "orderSetttingList": return "17"; break;
            case "sentOrderList": return "28"; break;

            //LiveTrade
            case "liveTrade": return "30"; break;
        }
    }
    else if(nheight > 1007 && nheight <= 1126){
        switch (type) {
            //landing page
            case "portfolio": return "25"; break;
            case "fixedIncome": return "25"; break;
            case "mutualFund": return "19"; break;
            case "stockCash": return "16"; break;
            //Historical untuk semua
            case "historical": return "23"; break;
            //Fund Transfer
            case "fundTransferList": return "23"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "10"; break;
            case "cancel2": return "10"; break;

            //Market
            case "summary": return "24"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "11"; break;

            //Broker
            case "brokerTradeSummary": return "32";break;
            case "brokerTradeHistory": return "31";break;

            //Stock
            case "corpAction": return "9"; break;
            case "stockWatchlist": return "30"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "25"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "8"; break;

            case "stockTick": return "17"; break;
            case "rgTrade": return "23"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "17"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "8"; break;
            case "orderBookingList": return "29"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "25"; break;

            case "orderSetttingList": return "18"; break;
            case "sentOrderList": return "29"; break;

            //LiveTrade
            case "liveTrade": return "32"; break;
        }
    }
    else if(nheight > 1127 && nheight < 1526){
        switch (type) {
            //landing page
            case "portfolio": return "28"; break;
            case "fixedIncome": return "28"; break;
            case "mutualFund": return "22"; break;
            case "stockCash": return "18"; break;
            //Historical untuk semua
            case "historical": return "26"; break;
            //Fund Transfer
            case "fundTransferList": return "26"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "13"; break;
            case "cancel2": return "13"; break;

            //Market
            case "summary": return "28"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "13"; break;

            //Broker
            case "brokerTradeSummary": return "46";break;
            case "brokerTradeHistory": return "45";break;

            //Stock
            case "corpAction": return "16"; break;
            case "stockWatchlist": return "46"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "44"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "15"; break;

            case "stockTick": return "33"; break;
            case "rgTrade": return "39"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "19"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "9"; break;
            case "orderBookingList": return "34"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "30"; break;

            case "orderSetttingList": return "22"; break;
            case "sentOrderList": return "34"; break;

            //LiveTrade
            case "liveTrade": return "36"; break;
        }
    }
    else if(nheight > 1527 && nheight < 1974){
        switch (type) {
            //landing page
            case "portfolio": return "41"; break;
            case "fixedIncome": return "41"; break;
            case "mutualFund": return "32"; break;
            case "stockCash": return "27"; break;
            //Historical untuk semua
            case "historical": return "39"; break;
            //Fund Transfer
            case "fundTransferList": return "39"; break;
            // fund transfer cancel aggrid pertama
            case "cancel1": return "20"; break;
            case "cancel2": return "20"; break;

            //Market
            case "summary": return "41"; break;
            // sectoral atas
            case "sectoral": return "9"; break;
            // mencangkup semua top broker AgGrid
            case "topBroker": return "18"; break;

            //Broker
            case "brokerTradeSummary": return "62";break;
            case "brokerTradeHistory": return "61";break;

            //Stock
            case "corpAction": return "23"; break;
            case "stockWatchlist": return "65"; break;

            //mencangkup daily, broker dan foreign net
            case "stockTradeHistoryDaily": return "60"; break;

            //mencangkup semua AgGrid yang berjejer di kanan
            case "stockTradeHistoryMini": return "22"; break;

            case "stockTick": return "47"; break;
            case "rgTrade": return "53"; break;

            //mencangkup semua AgGrid mini yang berada di bawah stock tick
            case "stockTradeSummaryMini": return "5"; break;

            //TradeList
            case "orderList": return "28"; break;

            //mencangkup semua AgGrid mini yang berada di bawah ordder list
            case "orderListMini": return "13"; break;
            case "orderBookingList": return "48"; break;

            //mencangkup semua AgGrid pada adv list
            case "advList": return "44"; break;

            case "orderSetttingList": return "34"; break;
            case "sentOrderList": return "48"; break;

            //LiveTrade
            case "liveTrade": return "52"; break;
        }
    }
    else{
        switch (type) {
            //landing page
                case "portfolio": return "56"; break;
                case "fixedIncome": return "56"; break;
                case "mutualFund": return "44"; break;
                case "stockCash": return "36"; break;
                //Historical untuk semua
                case "historical": return "54"; break;
                //Fund Transfer
                case "fundTransferList": return "54"; break;
                //fund transfer cancel aggrid pertama
                case "cancel1": return "28"; break;
                case "cancel2": return "28"; break;

            //Market
                case "summary": return "54"; break;
                // sectoral atas
                case "sectoral": return "9"; break;
                // mencangkup semua top broker AgGrid
                case "topBroker": return "25"; break;

            //Broker
            case "brokerTradeSummary": return "67";break;
            case "brokerTradeHistory": return "66";break;

            //Stock
                case "corpAction": return "23"; break;
                case "stockWatchlist": return "65"; break;
                //mencangkup daily, broker dan foreign net
                case "stockTradeHistoryDaily": return "60"; break;
                //mencangkup semua AgGrid yang berjejer di kanan
                case "stockTradeHistoryMini": return "22"; break;
                case "stockTick": return "47"; break;
                case "rgTrade": return "54"; break;
                //mencangkup semua AgGrid mini yang berada di bawah stock tick
                case "stockTradeSummaryMini": return "5"; break;

            //TradeList
                case "orderList": return "37"; break;

                //mencangkup semua AgGrid mini yang berada di bawah ordder list
                case "orderListMini": return "18"; break;
                case "orderBookingList": return "65"; break;

                //mencangkup semua AgGrid pada adv list
                case "advList": return "61"; break;

                case "orderSetttingList": return "53"; break;
                case "sentOrderList": return "65"; break;

            //LiveTrade
                case "liveTrade": return "69"; break;
        }
    }
}
// with params: nor, format Data,
function GetInitialState(nor, format) {
    let newData = [];
    for (var k = 1; k <= nor; k++) {
        newData.push(format);
    }
    return newData;
}

// with params: type, Data,
function GetInitialRow(nor, formatData, data){
    let dif = nor - data.length
    if(dif >= 1){
        for(let j=0; j<dif; j++){
            data.push(formatData)
        }
    }
    return data
}
// addRow or delete
// params : 1. 
function GetRowChange(type, data, size){
    let ntype = type === "stockTradeHistoryPrice" || type === "stockTradeHistoryBuy" || type === "stockTradeHistorySell" ?
        "stockTradeHistoryMini" : (type === "stockTradeHistoryForeign" || type === "stockTradeHistoryBroker" ? "stockTradeHistoryDaily" : 
        (type === "tradeListHistory" || type === "orderHistory" || type === "cashTransactionHistory" || type === "stockTransactionHistory" ? "historical" :
        (type === "tradeOrderSum" || type === "tradeTradeSum" || type === "tradeList" ? "orderListMini" : 
        (type === 'portfolio' ? "portfolio" : (type === "tradeOrderBookList" ? "orderBookingList" : type))) ))

    let nrow = ceksize(ntype, size)
    let dif = nrow - data.length
    if(dif > 0 ){
        for(let j=0; j<dif; j++){
            data.push(fd[type])
        }
    }
    return data;
}
// data format for initial state
const fd = { 
    // Header
        boxHeaderReady: { symbol: "IDXBASIC", last: "", change: 0, percentage: '0', prev_index: '0'},
        sectoralIndexCode: ["IDXBASIC", "IDXCYCLIC", "IDXENERGY", "IDXFINANCE", "IDXHEALTH", "IDXINDUST", "IDXINFRA", "IDXNONCYC", "IDXPROPERT", "IDXTECHNO", "IDXTRANS"],
        
    // Sidebar
        sidebarData: { name: "\xa0", fullname: "\xa0", last: "\xa0", change: "\xa0",  percent: "\xa0", own: "\xa0", ownPercent: "\xa0", popUp: "\xa0"},

    // My Account Page : 
        // 1. investment board
        portfolio: {code: "\xa0", stockValR: "", notation:"", avgprice: "", lastprice: "", lot: "",  slot: "", sshares: "",  amount: "", shares: "", stockval: "", pl: "", remark: "", valFormSell: "", plFormSell: "", perPlFormSell: "", action: ""},
        fixedIncome: {no: "", serial: "\xa0", nominal: "", coupon: "", couponpdate: "", duedate: "", detail: "", action: ""},
        mutualFund: {code: "\xa0", nav: "", navdate: "", currency: "", potentialpl: "", action: ""},
        // 2. Stock Cash
        stockCash: {notationR:"",codeR: "\xa0", avgpriceR: "", lastpriceR: "", plot: "", pshares: "", mktvalueR: "", plR: "", persenR: "", slot: "", sshares: "", lqValR: "", stockValR: ""},
        // 3. Historical
        tradeListHistory: {date: "", trade: "", order: "", orderType: "", code: "\xa0", cmd: "", type: "", mkt: "", vol: "", price: "", amount: "", time: ""},
        orderHistory: {order: "", marketNoC: "", code: "\xa0", cmd: "", type: "", mkt: "", vol: "", price: "", mvol: "", mprice: "", leaveVolC: "", status: "", time: "", rejectreason: "", userOrderC: "", soid:  "",  userId: "", terminal: "", method: ""},
        stockTransactionHistory: {date: "", code: "\xa0", buySell: "", inOut: ""},
        cashTransactionHistory: {date: "", debitCredit: "", desc: "\xa0", amount: ""},
        // 4. Fund Transfer
        fundTransferList: {date: "", no: "\xa0", amount:"", fee:"", bank:"", bankAcNo: "", reqDate: "", reqTime: "", status:""},
        fundStockList: {date: "\xa0", no: "", vol: "", amount: "", custodianBankDestination: "", status: ""},
        fundTransferCancel1: {cw: "\xa0", amount: "", fee: ""},
        fundTransferCancel2: {cw: "\xa0", requestdate: "", transferdate: "", amount: "", fee: ""},
        // WARRANT/RIGHT EXERCISE DISPLAY
        exerciseList: {no: "", reqdate: "", reqtime: "", exercisecode: "", exerciseprice: "", exercisefee: "", exerciseqty: "", value: "", amount: "", status: ""},
        // 6.TC & Soe
        tradeControlSoe: {order: "", settle: "", stockBuy: "", stockSell: "", amountBuy: "", amountSell: ""},

    // Market&Statistic : 
        // 1. Summary (TOP GAL)
        summary: {no: "", board: "", code: "\xa0", prev: "", last: "", change: "", persen: "", open: "", low: "", high: "", avg: "", val: "", vol: "", freq: "", fbuy: "", fsell: "", fnet: "", financial: "", action: "" },
        // CURRENCY & COMMODITY
        currenciesActive: {other: "USDIDR", name: "USD/IDR", dateTime: "", last: "", prevClose: "", change: "", percentage: ""},
        currencies: {other: "\xa0", name: "\xa0", dateTime: "", last: "", prevClose: "", change: "", percentage: ""},
        commodityActive: {name: "GOLD", code: "LGD", last: "", change: "", percentage: "", dateTime: ""},
        commodity: {name: "", code: "\xa0", last: "", change: "", percentage: "", dateTime: ""},

    // Broker
        // 1. broker trade Summary 1
        brokerTradeSum1: {stock: "\xa0", buyVol: "", buyVal: "", avgBuyPrice: "", sellVol: "", sellVal: "", avgSellPrice: "", netVol: "", netVal: ""},
        brokerTradeSum2: {price: "", freq: "", vol: "", value: "", netQty: ""},
        // 2. broker trade history
        brokerTradeHistory: {date: "", code: "", buyVol: "", buyVal: "", avgBuy: "", sellVol: "", sellVal: "", avgSell: "", netVol: "", netVal: ""},
    // Stock
        // 1. Stock Info
        corpAction: {type: "", cumdate: "", distdate: "", ratio: "", exprice: "" },
        stockTickMain: {time: "0", code: "\xa0", price: "", change: "", percent: "", vol: "",buyer: "", seller: "", board: "" },
        // 2. Stock Watchlist
        stockWatchlist: {code: "", tval: "", price: "", change: "", persen: "", tvol: "", AccForVol: "", AccForVal: "", AvgPrice: ""},
        // 3. Stock Trade History
        stockTradeHistoryMain: {change: "", percent: "", date: "", last: "", volume: "", value: "", broker: "", open: "", high: "", low: "", freq: "", buyvol: "", buyval: "", avgbuy: "", 
            sellvol: "", sellval: "", avgsell: "", netvol: "", netval: "", netvol:"", avgnet: "", fBuyVal: "", fBuyVol: "", fSellVal: "", fSellVol: "", fNetVal: ""
        },
        stockTradeHistoryDaily: {change: "", date: "", last: "", open: "", high: "", low: "", freq: "", volume: "", percent: "", value: ""},
        stockTradeHistoryForeign: {date: "", fBuyVal: "", fBuyVol: "", fSellVal: "", fSellVol: "", fNetVal: ""},
        stockTradeHistoryBroker: {broker: "", buyvol: "", buyval: "", avgbid: "", sellvol: "", sellval: "", avgsell: "", netvol: "", netval: ""},
        stockTradeHistoryPrice: {price: "", freq: "", vol: "", value: ""},
        stockTradeHistoryBuy: {buyer: "", volume: "", value:"", freq: "", avg: ""},
        stockTradeHistorySell: {seller: "", volume: "", value:"", freq: "", avg: ""},
        // stockTrade: {change, date, last, volume, value},
        stockTradeSummary: {price:"", volume:"", value:"", freq:""},
        stockSummaryBuyer: {buyer: "", volume: "", freq: "", avg: ""},
        // 4. Top Broker
        topBrokerVal: {no: "", code: "", company: "", tval: "", bval: "", sval: "", tvol: "", tfreq: ""},
        topBrokerBuy: {no: "", code: "", company: "", tval: "", bval: "", sval: "", tvol: "", tfreq: ""},
        topBrokerSell: {no: "", code: "", company: "", tval: "", sval: "", tvol: "", tfreq: ""},        
        specialNotation: {code: "", company: "", notation: "", board: ""},

    // Trade Page
        orderlistInformation: {olSelected: 0, olMatchVolLot: 0, olMatchAmount: 0, olVolLot: 0},
        orderList: {order: "", orderType: "", sReject: "", obhc: "", amountLeaveVol: "", amountMatchVol: "", checked: 0, marketorder: "", code: "\xa0", cmd: "", status: "",  remark: "", leaveVlot: "", amountLeaveVol: "",
            orderBy: "", type: "", mkt: "", vlot: "", vshares: "", price: "", mlot: "", mshares: "", avgmatchprice: "", amount: "", time: "", userId: "", terminal: "", method: "", action: ""
        },
        tradeList: {serverOrderId: "", pserverOrderId: "", marketorder: "", time: "", code: "\xa0", price: "", vol: "", val: "", buyer: "", seller: "", cmd: "", tradeId: ""},
        tradeOrderSum: {code: "", leaveVol: "", amountMatchVol: "", tradeVol: "", avgPrice: "", mshares: "", cmd: "", vol: ""},
        tradeTradeSum: {code: "", price: "", vol: "", cmd: ""},
        // Trade Page : TRADE PL
        tradePl: {code: "", buyVol: "", buyAmount: "", accAvgBuyPrice: "", sellVol: "", sellAmount: "", avgSellPrice: "", PL: "", percentage: ""},
        // Trade Page : 3. ORDERBOOK LIST
        tradeOrderBookList : {no: "", bookingNo: "", status: "", remark: "", cmd: "", mkt: "", code: "\xa0", price: "", vol: "", vollot: "", bookingDate: ""},
        // Trade Page : 4. ADVERTISEMENT LIST
        tradeAdv : {broker: "\xa0", price: "", vol: ""},
        // Trade Page : 5. Automatic Order Setting
        orderSetttingList: {ruleID: "\xa0", on: "", rule: "", typeOP: "", price: "", code: "", cmd: "", condition: "", exp: "", vol: ""},
        // Trade Page : 6. SEND ORDER
        tradeSendOrder: {date: "", time: "", ruleID: "", code: "\xa0", qty: "", cmd: "", price: "", mqty: "", mprice: "", result: "", setdate: "", dateuntil: ""},
        // EXPIRE AUTOMATIC ORDER
        autExpOrderList: {hastag: "", ruleID: "", date: "", cmd: "", code: "", condition: "", vol: "", price: "", value: "", expireDate: "", executeStatus: "", orderStatus: ""},

    // Live trade Page
        lvtrade: {time: "", code: "\xa0", price: "", change: "", percent: "", vol: "",buyer: "", seller: "",board: ""},
    
    // Footer
        boxFooterReady: { symbol: '-', last: '', change: 0, percentage: 0},
        footerEX: [
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0},
            { symbol: '-', last: '', change: 0, percentage: 0}
        ],
    // MODAl 
        // 1. Transaksi
        formBuyFooter: {cashOn: 0, remainTrade: 0, cashBuyS: 0, cashBuyL: 0, maxBuyS: 0, maxBuyL:0},
        formSellFooter: {ownQtyL: 0, ownQtyS: 0, avgprice: 0, value: 0, perPl: 0, pl: 0},
        // 2.Order Detail
        orderDetail: [
            {label: "Order Number", value: ""},
            {label: "Market Order Number", value: ""},
            {label: "Code", value: ""},
            {label: "Command", value: ""},
            {label: "Type", value: ""},
            {label: "Market", value: ""},
            {label: "Price", value: ""},
            {label: "Total Match Volume (Lot)", value: ""},
            {label: "Order Volume (Lot)", value: ""},
            {label: "Order By", value: ""},
            {label: "Status", value: ""},
        ],
        orderDetailCode: {dateO: "", timeO: "", orderType0: "", actionsO: "", statusO: "\xa0", remarksO: "", vlot: "", vshares: "", priceO: "", mlot: "", 
            mshares: "" , matchpriceO: "", amountO:"", marketOrderNo0: "", leaveVol0: "", userId0: ""},
        // 3. Trade History
        transactionHistory: { date: "", detail: "", amount: "", code: "", inOut: "", balA: "", balQ: "", fee: "", paidAmt: "", penalty: "", add: "", tradeAmt: "", wht: "", incomeTax: "", price: ""},
    // Other        
        userPreference: {
            user_id:"",
            pin_usage: "once",
            theme: "dark",
            interface_scale:"100%",
            show_balance: "show",
            timeout:300000,
            action_type: "INSERT-DB",
            push_notif: true 
        },
        userProfile: {
            user_name: "", bank_user: "", bank_code: "", bank_account: "", bank_name: "", bank_branch: "", email: "",
            status: "ACTIVE", // "SUSPEND" / "ACTIVE" / "SUSPEND BUY" / "SUSPEND SELL"
            erAlert: "", // Buy | Sell | Buy / Sell Order is not permitted
        },
        stockFinancialReport: {
            qtr: "",
            s_profit: "",
            g_profit: "",
            ebit: "",
            net_income: "",
            eps: "",
            t_Asset: "",
            t_Liabilty: "",
            t_Equity: "",
            per: "",
            pbv: "",
            roa: "",
            roe: "",
            book_value: "",
        }
        
}

var dConfig = startConfig();

export default startConfig
export {dConfig, fd, ceksize, GetInitialState, GetInitialRow, GetRowChange}
