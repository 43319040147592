import base64url from "base64url";
import { bankInfo } from "./initialData";

var fernet = require('fernet');

const setSliderLogin = (data) => {
    let change = (data.last - data.prev).toFixed(2);
    let percentage = Number(data.prev) === 0 ? 0 : ((change/data.prev)*100).toFixed(2);
    return {...data, change, percentage}
}

const CheckRespPin = (key, token) => {
    let ekey = base64url(key.replaceAll("-", ""));
    var secret = new fernet.Secret(ekey);
    var token = new fernet.Token({ secret, token, ttl: 0 });
    token.decode(); 
    return token.message; 
}

// customFilter React-Select
const customFilter  = (option, searchText) => {
    var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
    var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

    if(searchText.toLowerCase().includes(' ')){
        if(saham){
            return true;
        }
    } else {
        if (code) {
            return true;
        }
    }
};

// set color 
const colorText = (valA, valB = 0, ecolor="warning") => {
    if (Number(valA) < Number(valB))
        return "text-danger";
    else if(Number(valA) > Number(valB))
        return "text-success";
    else 
        return `text-${ecolor}`;
}

// set Icon (down, up, minus)
const updownIcon =  (valA, valB = 0) => {
    if (Number(valA) < Number(valB))
        return "icofont icofont-caret-down text-danger";
    else if(Number(valA) > Number(valB))
        return "icofont icofont-caret-up text-success";
    else 
        return "icofont icofont-minus text-warning";
}

// convert Lot To Share
function LotToShare(number) {
    if(number || number == 0){
        let numberRef =Number(number)
        let multiplier = 100;
        let share= numberRef*multiplier
        return share
    }
}

// convert Share to Lot
function ShareToLot(number) {
    if(number != 0){
        // var numberRef = parseInt(number)
        var divider = 100;
        var lot = number/divider;
        return lot;
    }else{
        return number;
    }
}

function thousandSeparator(nStr, npoint = 0, defVal) {
    if(nStr){
        let num = Number(nStr).toFixed(npoint);
        let x = num.toString().split(".");
        let x2 = x.length > 1 ? `.${x[1]}` : "";
        return x[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ x2;
    }else{
        return (nStr === "" && defVal !== undefined) ? defVal : nStr;
    }
}

const statusMessage = {
    OK: 'success', 
    success: 'success', 
    SUCCESS: 'success', 
    NOK: 'error', 
    error: 'error', 
    ERROR: 'error',
    'PASSWORD-ERROR': 'error',
    'PIN-ERROR': 'error',
};
const rdnBankList = {
    BNGA: 'Bank CIMB Niaga',
    BBCA: 'Bank Central Asia',
    BMRI: 'Bank Mandiri',
    BBNI: 'Bank BNI',
    BNLI: 'Bank Permata',
    BBRI: 'Bank Rakyat Indonesia',
    BBTN: 'Bank Tabungan Negara'
}
const strCall = {
    code: stock => stock.split('.')[0],
    codeType: stock => stock.split('.')[1],
    codeMarket: stock => (stock.includes('-R') || stock.split('.')[1] === 'TN') ? '0TN' : '0RG',
    codeExpire: stock => (stock.includes('-R') || stock.split('.')[1] === 'TN') ? 'S' : '0',
    checkBoxStock: (a='', b='', c) => {
        let code1 = a.split(".")[0], code2 = b.split(".")[0], code3 = c && c.split(".")[0];
        if(c){
            return (a==code2 || a==code3 || b==code1 || b==code3 || c==code1 ||  c==code2);
        } else {
            return a==code2
        }
    },
    cekValStatus: (data = [], val, field) => {
        let nval = data.find(d => (field ? d[field] : d) === val)
        return nval && field ? nval.text : nval;
    },
    marketType: type => (type === "0RG" || type === "RG") ? 'RG' : ((type === "0TN" || type === "TN") ? 'TN' : 'NG'),
    statusMsg: status => statusMessage[status] || status || '',
    rdnBank: bank => rdnBankList[bank] || bank || ''
    // rdnBank: bank => bank && bankInfo[bank].Nama || bank || ''
}

const mathCall = {
    div: (num, divider=100, npoint=0) => (Number(num)/Number(divider)).toFixed(npoint),
    setTens: (num) => num < 10 ? `0${num}` : num,
    setHundreds: num => Number(num) < 10 ? `00${num}` : Number(num) < 100 ? `0${num}` : num,
    toThousand: num => Number((Number(num)/1000).toFixed(0)),
    toMillion: (num, npoint=2) => (Number(num)/1000000).toFixed(npoint),
    toBillion: (num, npoint=2) => (Number(num)/1000000000).toFixed(npoint),
    toTrillion: (num, npoint=2) => (Number(num)/1000000000000).toFixed(npoint),
    setOrdinalNum: num => {
        let ords = [,'st','nd','rd'], m = Number(num)%100;
        return num ? num + ((m > 10 && m < 14) ? 'th' : ords[m%10] || 'th') : "";
    },
    setRupiah: (nStr, npoint = 0, defaultValue, defPostpend='') => {
        if(nStr){
            let num = Number(nStr).toFixed(npoint);
            let x = num.toString().split(".");
            let x2 = x.length > 1 ? `.${x[1]}` : "";
            return x[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ x2 + defPostpend;
        } else {
            return ((nStr === "" || nStr === null) && defaultValue !== undefined) ? defaultValue : (isNaN(nStr) ? '' : nStr);
        }
    },
    sumArray(data=[], field){
        return data.reduce((a, b) => a + (field ? b[field] : b), 0)
    }
}

/** TIME FUNCTION */
// Get 3Mountbefore with result 7/11/2019, m=month, d=day
function GetTimeForDateTicker(m=0, day=0) {
    let today = new Date();
    today.setMonth(today.getMonth() - m);
    today.setDate(today.getDate() - day);
    let refdate = today.toLocaleDateString('en-US').split('/')
    let ndate = mathCall.setTens(refdate[1])+'/'+mathCall.setTens(refdate[0])+'/'+mathCall.setTens(refdate[2]);
    return ndate;
}
const monthArr = { "01": "Januari", "02": "Februari", "03": "Maret", "04": "April", "05": "Mei", "06": "Juni", "07": "Juli", "08": "Agustus", "09": "September", 10: "Oktober", 11: "November", 12: "Desember"};
const timeCall = {
    getdate: (separator="/", m=0, day=0) => {
        let today = new Date();
        today.setMonth(today.getMonth() + m);
        today.setDate(today.getDate() + day);
        let refdate = today.toLocaleDateString('en-US').split('/');
        return mathCall.setTens(refdate[1]) + separator + mathCall.setTens(refdate[0]) + separator + mathCall.setTens(refdate[2]);
    },
    todate1: (val) => { // 2022-03-15 => 15 Maret 2022
        let nval = val && val.substr(0,10).split("-").reverse();
        return val ? `${nval[0]} ${monthArr[nval[1]]} ${nval[2]}` : '';
    },
    todate2: (val='', separator='T') => { // 2023-07-05T00:00:00Z => 2023-07-05
        return val.split(separator)[0];
    },
    todate3: (val="", lastSeparator="-", newSeparator="/") => { // 2008-10-24 => 24/10/2008 || "24/10/2008" => "2008-10-24"
        return val.split(lastSeparator).reverse().toString().replaceAll(",", newSeparator)
    },
    todatetime1: (val) => { // 2022-06-21T15:00:00Z => 2022-06-21 22:00:00
        if(val){
            let nval = new Date(val);
            let ndate = `${nval.getFullYear()}-${mathCall.setTens(nval.getMonth()+1)}-${mathCall.setTens(nval.getDate())}`;
            let time = `${mathCall.setTens(nval.getHours())}:${mathCall.setTens(nval.getMinutes())}:${mathCall.setTens(nval.getSeconds())}`;
            return `${ndate} ${time}`;
        } else return '';
    },
    todatetime2: (val='') => { // 2023-07-05T00:00:00Z => 2023-07-05 00:00:00
        return val.replace("T", " ").replace("Z","");
    },
}

const setTempData = {
    openExercise: (data) => ({
        id: data.exrid,
        tipe: "CANCEL",
        data: [
            {label: "Code", type: "text", value: data.right_code},
            {label: "Excersice Price", type: "nom", value: data.price},
            {label: "Excersice Quantity", type: "nom", value: data.quantity_right},
            {label: "Excersice Value", type: "nom", value: data.value},
            {label: "Excersice Fee", type: "nom", value: data.fee},
            {label: "Total Amount", type: "nom", value: data.amount},
        ],
        params: {"exr_id": data.exrid}
    }),
    createExercise: data => ({
        id: Math.random(),
        tipe: "EXERCISE",
        data: [
            {label: "Code", type: "text", value: `${data.asset_code} [${data.saham}]`},
            {label: "Exercise Ratio", type: "nom", value: data.ratio_right},                    
            {label: "Excersice Price", type: "nom", value: data.price},
            {label: "Excersice Quantity", type: "nom", value: data.qty},
            {label: "Excersice Value", type: "nom", value: data.e_value},
            {label: "Excersice Fee", type: "nom", value: data.fee},
            {label: "Total Amount", type: "nom", value: data.amount},
            {label: "Available Cash", type: "nom", value: data.a_cash},
        ],
        params: {
            "ca_id": data.caid,
            "qty": Number(data.qty),
            "right_code": data.right_code
        }
    }),
}

const debugDataFE = (title="CEK", data) => {
    console.log("DEBUG >> ", title, data)
}

export { 
    customFilter,
    colorText, updownIcon, CheckRespPin, thousandSeparator,
    ShareToLot,
    setSliderLogin,
    timeCall,
    GetTimeForDateTicker,
    mathCall,
    strCall,
    setTempData,
    debugDataFE
}