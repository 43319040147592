import React, { Component } from 'react';
import anychart from 'anychart';
import AnyChart from '../../node_modules/anychart-react/dist/anychart-react.min.js'
import '../../node_modules/anychart/dist/css/anychart-ui.min.css';
import '../../node_modules/anychart/dist/js/anychart-ui.min.js';
import '../../node_modules/anychart/dist/fonts/css/anychart-font.min.css';
import '../../node_modules/anychart/dist/js/anychart-data-adapter.min.js'
import '../../node_modules/anychart/dist/js/anychart-annotations.min.js';

import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
import '../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js';
import $ from 'jquery';

import {BIPSAppContext, GetRangeDate} from "../AppData";
import { ContextConnector } from '../appcontext.js';
import { fetchApi } from '../utils/fetchApi.js';

window.$ = window.jQuery = $;
require('../../node_modules/bootstrap/dist/js/bootstrap.js');

class StockChart_Base extends React.PureComponent {

    componentDidMount() {
        $('#chart-container').css('height', '100%');
    }

    render() {
        var chart = anychart.stock();

        chart.scroller(true);
        chart.scroller().enabled(false);
        chart.credits().enabled(false);
        let { userName, sessionID, bidOfferCode } = this.props;
        var firstDate = GetRangeDate('start', [])
        var lastDate = GetRangeDate('finish', [])
        // create stock chart
        // var chart = anychart.stock();
        // set chart padding
        chart.padding().right(60);
        // create plot on the chart
        var plot = chart.plot(0);

        // enabled x-grid/y-grid
        plot.yGrid().enabled(true);
        plot.yGrid().stroke({color: "#555555", dash: "3 4"});
        plot.xMinorGrid().enabled(true);
        plot.xMinorGrid().stroke({color: "#555555", dash: "2 4"});

        // set orientation y-axis to the right side
        plot.yAxis().orientation('right');

        // function createChart(data) {
        anychart.onDocumentReady(async function () {
            // map loaded data
            var table = anychart.data.table();

            async function getChartData(code){        
                let res = await fetchApi(`stock/chart/${code}`, { 
                    method: 'GET',
                    headers: { "Authorization": `${userName}.${sessionID}`}
                });
        
                return res.resp_code === "00" ? res.data : [];
            }
            
            let newData = bidOfferCode ? await getChartData(bidOfferCode) : []
            table.addData(newData);
            var mapping = table.mapAs();
            mapping.addField('open', 1);
            mapping.addField('high', 2);
            mapping.addField('low', 3);
            mapping.addField('close', 4);

            // create candlestick series on the plot
            var aaplSeries = plot.candlestick(mapping);
            // set series settings
            if(newData.length){
                aaplSeries.name(bidOfferCode).zIndex(50);
            }else{
                aaplSeries.name('').zIndex(50);

            }
            aaplSeries.risingFill('green', 0.5).fallingFill('red', 0.5).risingStroke('green', 0.5).fallingStroke('red', 0.5);

            // create EMA indicators with period 50 on the plot
            // var ema = plot.ema(dataTable.mapAs({
            //     'value': 4
            // }));
            // ema.series().stroke('1.5 #5FB1EE');

            // create volume series on the plot
            // var volumeSeries = plot.column(mapping);
            // // set series settings
            // volumeSeries.name('Volume').zIndex(100).maxHeight('20%').bottom(0);
            // volumeSeries.legendItem({
            //     iconEnabled: false,
            //     textOverflow: ''
            // });

            // // create a logarithmic scale
            // var customScale = anychart.scales.log();
            // // sets y-scale
            // volumeSeries.yScale(customScale);

            // // set volume rising and falling stroke settings
            // volumeSeries.risingStroke('red');
            // volumeSeries.fallingStroke('green');

            // // set volume rising and falling fill settings
            // volumeSeries.risingFill('red .5');
            // volumeSeries.fallingFill('green .5');

            var grouping = chart.grouping();

            // disable the grouping feature
            grouping.enabled(false);

            // set chart selected date/time range
            chart.selectRange(firstDate, lastDate);

            chart.background().fill("none");

            // set container id for the chart
            chart.container("chart-container");

            // initiate chart drawing
            chart.draw();

            // create range picker
            var rangePicker = anychart.ui.rangePicker();
            // init range picker
            rangePicker.render(chart);

            // create range selector
            var rangeSelector = anychart.ui.rangeSelector();
            // init range selector
            rangeSelector.render(chart);
        })

        if(this.props.chartStockData.length){
            return (
                <AnyChart instance={chart} id="chart-container" />
            );
        }else{
            return (<AnyChart instance={chart} id="chart-container" />)
        }
    }
}

const StockChart = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        userName: vars.userName,
        sessionID:vars.sessionID,
        chartStockData: vars.chartStockData,
        bidOfferCode: vars.bidOfferCode,
    })
)(StockChart_Base)

export default StockChart;
