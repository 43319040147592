import $ from 'jquery';
window.$ = window.jQuery = $;

const ResizeResponsive = () => {
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if(isFirefox){
        $(".card-448>tr>td").css("padding-top","1vh");
    }
    if($('html').width() < 1021) {
        $('.col-smb-1').css('float', 'left');
        $('.col-smb-2').css('float', 'left');
        $('.col-smb-3').css('float', 'left');
        $('.col-smb-4').css('float', 'left');
        $('.col-smb-5').css('float', 'left');
        $('.col-smb-6').css('float', 'left');
        $('.col-smb-7').css('float', 'left');
        $('.col-smb-8').css('float', 'left');
        $('.col-smb-9').css('float', 'left');
        $('.col-smb-10').css('float', 'left');
        $('.col-smb-11').css('float', 'left');
        $('.col-smb-12').css('float', 'left');

        $('.col-smb-1').css('width', '8.33333333%');
        $('.col-smb-2').css('width', '16.66666667%');
        $('.col-smb-3').css('width', '25%');
        $('.col-smb-4').css('width', '33.33333333%');
        $('.col-smb-5').css('width', '41.66666667%');
        $('.col-smb-6').css('width', '50%');
        $('.col-smb-7').css('width', '58.33333333%');
        $('.col-smb-8').css('width', '66.66666667%');
        $('.col-smb-9').css('width', '75%');
        $('.col-smb-10').css('width', '83.33333333%');
        $('.col-smb-11').css('width', '91.66666667%');
        $('.col-smb-12').css('width', '100%');
    }

        // $('.box-selection').css('font-size','14px');

    if($('html').width() > 545 && $('html').width() < 576) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 7px!important');
        $('.col-sm-tab').css('flex','0 0 9.9%');
        $('.col-sm-tab').css('max-width','9.9%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 479 && $('html').width() < 546) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6px!important');
        $('.col-sm-tab').css('flex','0 0 9.85%');
        $('.col-sm-tab').css('max-width','9.85%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 428 && $('html').width() < 480) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.5px!important');
        $('.col-sm-tab').css('flex','0 0 9.7%');
        $('.col-sm-tab').css('max-width','9.7%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 400 && $('html').width() < 429) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5px!important');
        $('.col-sm-tab').css('flex','0 0 9.6%');
        $('.col-sm-tab').css('max-width','9.6%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 364 && $('html').width() < 401) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 4.5px!important');
        $('.col-sm-tab').css('flex','0 0 9.45%');
        $('.col-sm-tab').css('max-width','9.45%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 335 && $('html').width() < 365) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 4px!important');
        $('.col-sm-tab').css('flex','0 0 9.3%');
        $('.col-sm-tab').css('max-width','9.3%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() < 366) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 3.5px!important');
        $('.col-sm-tab').css('flex','0 0 9%');
        $('.col-sm-tab').css('max-width','9%');
        $('.col-sm-tab').css('display','inline-block');
    }

    if($('html').width() > 1300){
        $('.f-livetrade').css('font-size','14px');
    } else if($('html').width() > 1200 && $('html').width() < 1301){
        $('.slidee > div').css('font-size','10px');
    } else if($('html').width() > 1000 && $('html').width() < 1201){
        $('.f-livetrade').css('font-size','9px');
    } else if($('html').width() < 1001){
        $('.f-livetrade').css('font-size','7px');
    }

    if(window.innerWidth > 1370 && window.innerWidth <= 1520) {
        $('.slidee > div').css('font-size','13px');
        $('.slidee > div').css('padding-top','5px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 1521 && window.innerWidth <= 1800){
        $('.slidee > div').css('font-size','14px');
        $('.slidee > div').css('padding-top','5px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 1801 && window.innerWidth <= 2030){
        $('.slidee > div').css('font-size','16px');
        $('.slidee > div').css('padding-top','4px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 2030 && window.innerWidth <= 2303){
        $('.slidee > div').css('font-size','17px');
        $('.slidee > div').css('padding-top','3px');
    }else if(window.innerWidth > 2303 && window.innerWidth <= 2559){
        $('.slidee > div').css('font-size','20px');
        $('.slidee > div').css('padding-top','2px');
    }else if(window.innerWidth > 2559){
        $('.slidee > div').css('font-size','22px');
        $('.slidee > div').css('padding-top','2px');
    }else{
        $('.slidee > div').css('font-size','10px');
        $('.slidee > div').css('padding-top','7px');
        $('.slidee > div').css('padding-right','0px');
        $('.slidee > div').css('padding-left','1px');
    }

    if($('html').width() > 1315) {
        $('.d-xxl-block').css('cssText', 'display: block!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 0px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '50px');
        $('.img-avatar').css('height', '50px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '11px');
        $('.f-12').css('font-size', '12px');
        $('.f-xs-14').css('font-size', '12px');
        $('.f-14').css('font-size', '14px');
        $('.f-16').css('font-size', '16px');
        $('.f-10').css('font-size', '10px');
        $('.f-8').css('font-size', '8px');
        $('.cssmenu ul li a').css('padding', '15px 25px');
        $('h5').css('cssText', 'font-size: 14px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding','0px 6.5px');
        $('.padding-15').css('padding','0px 15.3px');
        $('.padding-7').css('padding','0px 7.8px');
        $('.padding-21').css('padding','0px 21.09px');
        $('.padding-11').css('padding','0px 11.1px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1176 && $('html').width() < 1316) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '10px');
        $('.f-12').css('font-size', '11px');
        $('.f-xs-14').css('font-size', '11px');
        $('.f-14').css('font-size', '13px');
        $('.f-16').css('font-size', '15px');
        $('.f-10').css('font-size', '9px');
        $('.f-8').css('font-size', '7px');
        $('.cssmenu ul li a').css('padding', '15px 22px');
        $('h5').css('cssText', 'font-size: 13px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1116 && $('html').width() < 1177) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.9px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '38.5px');
        $('.img-avatar').css('height', '38.5px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '8px');
        $('.f-8').css('font-size', '7px');
        $('.cssmenu ul li a').css('padding', '15px 19px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1020 && $('html').width() < 1117) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.9px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '33px');
        $('.img-avatar').css('height', '33px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '8px');
        $('.f-8').css('font-size', '6px');
        $('.cssmenu ul li a').css('padding', '15px 10px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 845 && $('html').width() < 1021) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-block').css('cssText', 'display: block!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.39%');
        $('.col-sm-tab').css('max-width','10.39%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '50px');
        $('.img-avatar').css('height', '50px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '7px');
        $('.f-8').css('font-size', '5px');
        $('.cssmenu ul li a').css('padding', '15px 10px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 20px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','10px');
    }
    else if($('html').width() > 755 && $('html').width() < 846) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-block').css('cssText', 'display: block!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.285%');
        $('.col-sm-tab').css('max-width','10.285%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '40px');
        $('.img-avatar').css('height', '40px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '6px');
        $('.f-12').css('font-size', '7px');
        $('.f-xs-14').css('font-size', '7px');
        $('.f-14').css('font-size', '9px');
        $('.f-16').css('font-size', '11px');
        $('.f-10').css('font-size', '5px');
        $('.f-8').css('font-size', '3px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 9px!important');
        $('h3').css('cssText', 'font-size: 18px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','7px');
    }
    else if($('html').width() > 575 && $('html').width() < 756) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-block').css('cssText', 'display: block!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.5rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6.2px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.1%');
        $('.col-sm-tab').css('max-width','10.1%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '8px');
        $('.f-12').css('font-size', '9px');
        $('.f-xs-14').css('font-size', '9px');
        $('.f-14').css('font-size', '11px');
        $('.f-16').css('font-size', '13px');
        $('.f-10').css('font-size', '4px');
        $('.f-8').css('font-size', '3px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 11px!important');
        $('h3').css('cssText', 'font-size: 16px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','9px');
    }
    else if($('html').width() < 576) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-block').css('cssText', 'display: block!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '8px');
        $('.f-12').css('font-size', '9px');
        $('.f-xs-14').css('font-size', '9px');
        $('.f-14').css('font-size', '11px');
        $('.f-16').css('font-size', '13px');
        $('.f-10').css('font-size', '6px');
        $('.f-8').css('font-size', '4px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 11px!important');
        $('h3').css('cssText', 'font-size: 16px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','9px');
    }

    var marquee = $('html').width();
    /*marquee = marquee + 20;*/
    $('.runningText p').css('minWidth', (marquee));
    $('.runningText').css('minWidth', (marquee));

    var cardIntIndices = $('html').height() - 255;
    $('.card-intIndices').css('min-height', cardIntIndices);

    var cardCurrencies = $('html').height() - 310;
    $('.card-currencies').css('min-height', cardCurrencies);

    var heightbarchart = $('html').height() - 360;
    $('#heightValue-trigger').val(heightbarchart);
    $('#heightValue-trigger').click();

    var cardSO = $('html').height() - 235;
    $('.card-tradeSO').css('min-height',cardSO);

    var cardEO = $('html').height() - 265;
    $('.card-tradeEO').css('min-height',cardEO);

    var card406 = $('html').height() - 330;
    $('.card-406').css('min-height', card406);

    var cardBrokerInfo = $('html').height() - 275;
    $('.card-brokerInfo').css('min-height', cardBrokerInfo);


    var cardBrokerTradeSummary = $('html').height() - 220;
    $('.card-brokerTradeSummary').css('min-height', cardBrokerTradeSummary);

    var cardTradePL = $('html').height() - 333;
    $('.card-tradePL').css('min-height', cardTradePL);

    var cardTradeAdv = $('html').height() - 245;
    $('.card-tradeAdv').css('min-height', cardTradeAdv);

    var cardSpecialNotation = $('html').height() - 220;
    $('.card-specialNotation').css('min-height', cardSpecialNotation);

    var card354 = heightbarchart + 5;
    $('.card-354').css('min-height', card354);

    var height440a = $('html').height() - 295.05;
    $('.card-440-a').css('height', height440a);

    var height220a = $('html').height() - 473.5;
    $('.card-220-a').css('min-height', height220a);

    var height440ac = $('html').height() - 295.05;
    $('.card-440-ac').css('height', height440ac);

    var height695 = $('html').height();
    $('.card-695').css('min-height', (height695));
    var plogin = ($('html').height()-$('.p-login').height())/2;
    // $('.p-login').css('padding-top', (plogin));
    // $('.p-login').css('padding-bottom', (plogin));
    var height345 = $('html').height()-339.05;
    $('.card-345').css('min-height', (height345));

    var height575 = $('html').height()-84-32-5;
    $('.card-575').css('min-height', (height575));
    var height527 = height575-$('.tabheaderfill').height()-3;
    $('.card-527').css('min-height', (height527));

    var height520 = height527-7;
    $('.card-520').css('min-height', (height520));
    var height145 = (height520-63-67-100)/2;

    var height310od = (310/520)*(height520) - 43.3;
    $('.card-310-odlist').css('minHeight', (height310od));

    var height290 = (height520-63-67-100);
    $('.card-290').css('min-height', (height290));
    var height240 = (height290-50);
    $('.card-240').css('min-height', (height240));
    $('.tbodyGroupAdd').css('min-height', (height240));

    $('.card-145').css('min-height', (height145));
    var height95 = (height145-50);
    $('.card-95').css('min-height', (height95));
    $('.tbodyGroup').css('min-height', (height95));


    var height467 = height520-53;
    $('.card-467').css('min-height', (height467));

    var height482 = height527-45;
    $('.card-482').css('min-height', (height482));

    var height514 = height520-4.5+2;
    $('.card-514').css('minHeight', (height514));

    var heightStockWathclist = height520-4.5+2-30;
    $('.card-watchlistcust').css('minHeight', (heightStockWathclist));

    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
        // Do Firefox-related activities
        var height112 = (115/474)*(height520-46);
        $('.card-112').css('minHeight', (height112));
        var height362 = (358.9/474)*(height520-46);
        $('.card-362').css('minHeight', (height362));

        var height356 = ((358.9/474)*(height520-46))-7+2;
        $('.card-356').css('minHeight', (height356));
        $('#cmp.card-356').css('minHeight', (height356));


        if($('html').height() > 1099 && $('html').height() < 1200)    {
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-6;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 768 && $('html').height() < 900) ||
            ($('html').height() > 899 && $('html').height() < 1000)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-3;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 999 && $('html').height() < 1100)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-4;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 1199)&& ($('html').height() < 2000)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-7;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 2001)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-10;
            $('.card-283').css('minHeight', (height283a));
        } else{
            var height283 = (279/482)*(height482);
            $('.card-283').css('minHeight', (height283));
        }

        var height191 = (197/199)*(height482-height283)-4;
        $('.card-191').css('minHeight', (height191));
        var height161 = (169/169)*(height482-height283)-4-30-4-1;
        $('.card-161').css('minHeight', (height161));
        var height1611 = (167/169)*(height482-height283)-4-30-4-1;
        $('.card-161-1').css('minHeight', (height1611));
        var height1612 = (167/169)*(height482-height283)-4-30-4-2;
        $('.card-161-2').css('minHeight', (height1612));

        $('.card-topDT').css('minHeight', (height161/3) - 3);
        $('.tb-dailytech').css('minHeight', (height161/3) * 2);

        var height323 = (321/324)*(height482-46-height112)-2;
        $('.card-323').css('minHeight', (height323));
        
        // popup table info transaction
        var heightInfoUpper = (height520 - 31) * 0.3;
        $('.card-120').css('minHeight', (heightInfoUpper));

        var heightInfoLower = (height520 - 32) * 0.7;
        $('.card-350').css('minHeight', (heightInfoLower));
        
        var heightInfoStockUpper = 140;
        $('.card-140').css('minHeight', (heightInfoStockUpper));

        var heightInfoStock = height482 - 35 - 140;
        $('.card-310-is').css('minHeight', (heightInfoStock));

        var heightInfoTrade = height482 - 25 - 140;
        $('.card-310-tr').css('minHeight', (heightInfoTrade));      
        
        var heightInfoTradeAut = height482 - 132;
        $('.card-310-aut').css('minHeight', (heightInfoTradeAut));

        if($('html').width() > 4099){
            var height326 = ((321/324)*(height482-46-height112)-2)-20;
            $('.card-326').css('minHeight', (height326));
        } else if($('html').width() < 4100){
            var height326 = (321/324)*(height482-46-height112)-2;
            $('.card-326').css('minHeight', (height326));
        }

        var height310 = (310/520)*(height520);
        $('.card-310').css('minHeight', (height310));
        var height176 = (175/176)*(height520-height310-30);
        $('.card-176').css('minHeight', (height176));
        var height177 = (176/177)*(height520-height310-30+1);
        $('.card-177').css('minHeight', (height177));
    }else{
        var height112 = (112/474)*(height520-46);
        $('.card-112').css('minHeight', (height112));
        var height362 = (361.9/474)*(height520-46);
        $('.card-362').css('minHeight', (height362));

        var height356 = ((361.9/474)*(height520-46))-5.382+0.764+2;
        $('.card-356').css('minHeight', (height356));
        $('#cmp.card-356').css('minHeight', (height356));

        var height283 = (283/482)*(height482);
        $('.card-283').css('minHeight', (height283));
        var height191 = (199/199)*(height482-height283)-4;
        $('.card-191').css('minHeight', (height191));
        var height161 = (169/169)*(height482-height283)-4-30-4;
        $('.card-161').css('minHeight', (height161));
        var height1611 = (169/169)*(height482-height283)-4-30-4-1;
        $('.card-161-1').css('minHeight', (height1611));
        var height1612 = (169/169)*(height482-height283)-4-30-4-2;
        $('.card-161-2').css('minHeight', (height1612));


        $('.card-topDT').css('minHeight', (height161/3) - 3);
        $('.tb-dailytech').css('minHeight', (height161/3) * 2);

        var height323 = (324/324)*(height482-46-height112);
        $('.card-323').css('minHeight', (height323));
        
        var heightInfoUpper = (height520 - 31) * 0.3;
        $('.card-120').css('minHeight', (heightInfoUpper));

        var heightInfoLower = (height520 - 32) * 0.7;
        $('.card-350').css('minHeight', (heightInfoLower));
        
        var heightInfoStockUpper = 140;
        $('.card-140').css('minHeight', (heightInfoStockUpper));

        var heightInfoStock = height482 - 35 - 140;
        $('.card-310-is').css('minHeight', (heightInfoStock));

        var heightInfoTrade = height482 - 25 - 140;
        $('.card-310-tr').css('minHeight', (heightInfoTrade));      
        
        var heightInfoTradeAut = height482 - 132;
        $('.card-310-aut').css('minHeight', (heightInfoTradeAut));

        if($('html').width() > 4099){
            var height326 = ((324/324)*(height482-46-height112))-20;
            $('.card-326').css('minHeight', (height326));
        } else if($('html').width() < 4100){
            var height326 = (324/324)*(height482-46-height112);
            $('.card-326').css('minHeight', (height326));
        }

        var height310 = (310/520)*(height520);
        $('.card-310').css('minHeight', (height310));
        var height176 = (175/176)*(height520-height310-30);
        $('.card-176').css('minHeight', (height176));
        var height177 = (176/177)*(height520-height310-30+1);
        $('.card-177').css('minHeight', (height177));
    }

    if($('html').height() > 2200){
        var height234 = height527-$('.grid-294').height()-40;
        $('.card-234').css('min-height', (height234));
    } else{
        var height234 = height527-$('.grid-294').height();
        $('.card-234').css('min-height', (height234));
    }

    var height399 = height520-121;
    $('.card-399').css('min-height', (height399));
    var height344 = height399-40-15;
    $('.card-344').css('min-height', (height344));

    //c9
    if(window.innerWidth > 1370 && window.innerWidth <= 1520) {
        var marketwidth = $('html').width() * 0.5285;
    }else if(window.innerWidth > 1520 && window.innerWidth <= 1800){
        var marketwidth = $('html').width() * 0.529;
    }else if(window.innerWidth > 1800 && window.innerWidth <= 2030){
        var marketwidth = $('html').width() * 0.530;
    }else if(window.innerWidth > 2030 && window.innerWidth <= 2303){
        var marketwidth = $('html').width() * 0.531;
    }else if(window.innerWidth > 2303 && window.innerWidth <= 2559){
        var marketwidth = $('html').width() * 0.532;
    }else if(window.innerWidth > 2559){
        var marketwidth = $('html').width() * 0.534;
    }else if(window.innerWidth < 1025){
        var marketwidth = $('html').width() * 0.50;
    }else{
        var marketwidth = $('html').width() * 0.5285;
    }
    $('.addmorewidth').css("min-width", marketwidth);

    var height220 = ((height520-20)/2)-40-0.5;
    $('.card-220').css('min-height', (height220));

    var heightAutOrder = $('html').height() - 480;
    $('.card-Aut-Order').css('min-height', heightAutOrder);

    var height132 = ((height520-40)/3)-30+2;
    $('.card-132').css('min-height', (height132));
    var height152 = height132+20+0.1667;
    $('.card-152').css('min-height', (height152));
    var height92 = height132-40+0.1667;
    $('.card-92').css('min-height', (height92));
    var height76 = height132-56+0.1667;
    $('.card-76').css('min-height', (height76));

    var height452 = height527-31-37-6;
    $('.card-452').css('min-height', (height452));

    var height487 = height527-40;
    $('.card-487').css('min-height', (height487));

    var height487pe = height527-80;
    $('.card-487-pe').css('min-height', (height487pe));

    var height305 = height527/1.728;
    $('.card-305').css('min-height', (height305));
    var height221 = height527/2.385;
    $('.card-221').css('min-height', (height221));
    var height194 = height221-17-10;
    $('.card-194').css('min-height', (height194));
    var height448 = height527-77-2;
    $('.card-448').css('min-height', (height448));

    var height444 = height527-77-2-4;
    $('.card-444').css('min-height', (height444));

    var height57 = (height448/8)+1.1;
    $('.list-group-item-portofolio').css('min-height', (height57));

    var height392 = height527-62-11-25-20-17;
    $('.card-392').css('min-height', (height392));

    var heighthistorical = height527-62-11-25-20-17-13;
    $('.card-392-historical').css('min-height', (heighthistorical));

    var height478 = height527-49-1.5;
    $('.card-478').css('min-height', (height478));
    var height472 = height527-45-10;
    $('.card-472').css('min-height', (height472));
    var height381 = height472-62-29;
    $('.card-381').css('min-height', (height381));
    var height155 = (height472-62-30-10-40-20)/2;
    $('.card-155').css('min-height', (height155));
    var height233 = height527-$('.grid-294').height()-0.5;
    $('.card-233').css('min-height', (height233));
    var height202 = height527-$('.grid-294').height()-30-0.5;
    $('.card-202').css('min-height', (height202));
    var height515 = height527-12.5;
    $('.card-515').css('min-height', (height515));
    var height334 = height515-121-40-20-20-1;
    $('.card-334').css('min-height', (height334));
    var height230 = (height515-40-15)/2;
    $('.card-230').css('min-height', (height230));

    var height175b = (height527/3)-0.7;
    $('.card-175b').css('min-height', (height175b));

    var height175 = (height527/3)-0.8333;
    $('.card-175').css('min-height', (height175));

    var height138 = height175-37;
    $('.card-138').css('min-height', (height138));

    var height479 = height527-25-20-3-1;
    $('.card-479').css('min-height', (height479));
    var height406 = height479-73;
    $('.card-406').css('min-height', (height406));
    var height282 = ((height527-40)/1.727)+0.298;
    $('.card-282').css('min-height', (height282));
    var height190 = ((height527-40)/2.563)+0.183;
    $('.card-190').css('min-height', (height190));
    var height160 = height190-30;
    $('.card-160').css('min-height', (height160));
    var height156 = height160-4;
    $('.card-156').css('min-height', (height156));
    var height111 = ((height527-40-30)/4.117)+0.118;
    $('.card-111').css('min-height', (height111));
    var height158 = height156+2;
    $('.card-158').css('min-height', (height158));
    var height324 = ((height527-40-30)/1.410)+2.241;
    $('.card-324').css('min-height', (height324));
    var height321 = height324-3;
    $('.card-321').css('min-height', (height321));
    var height372 = (height527-40-40-30-45)+0.5;
    $('.card-372').css('min-height', (height372));
    var height129 = (height372+15)/3;
    $('.card-129').css('min-height', (height129));
    var height257 = height515/2;
    $('.card-257').css('min-height', (height257));
    var height475 = height515-40;
    $('.card-475').css('min-height', (height475));

    var height440 = height475+100;
    $('.card-440').css('min-height', (height440));

    var height480 = height475+5;
    $('.card-480').css('min-height', (height480));
    
    var height215 = (height440-10)/2;
    $('.card-215').css('min-height', (height215));
    var height169 = height472/2.792;
    $('.card-169').css('min-height', (height169));
    
    var height169_mf = ($('html').height() - 340) / 2;
    $('.card-169-mf').css('min-height', (height169_mf));
    
    var height113 = height472/4.176;
    $('.card-113').css('min-height', (height113));
    var height281 = height472/1.679;
    $('.card-281').css('min-height', (height281));

    var height30 = height527-height169-height92-46-37-37-116;
    $('.card-30').css('min-height', (height30));
    var height64 = (height76+24+16)-($('.box-warning-esbnreg').height()+16);
    $('.card-64').css('min-height', (height64));

    var rsccontainer = height527;
    $('.rsc-container').css('min-height', (rsccontainer));
    var rsccontent = height527-133;
    $('.rsc-content').css('min-height', (rsccontent));
    var rscscroll = height527-40;
    $('.rsc-scroll').css('min-height', (rscscroll));

    //Zoom Live Trade
    if($('html').height() > 2601)  {
        var liveTrade = 4.4;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if ($('html').height() > 2201 && $('html').height() < 2600) {
        var liveTrade = 3.5;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 2049 && $('html').height() < 2200) {
        var liveTrade = 3.1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1533 && $('html').height() < 2050) {
        var liveTrade = 2.3;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1319 && $('html').height() < 1534) {
        var liveTrade = 2.1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1100 && $('html').height() < 1320) {
        var liveTrade = 1.63;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1042 && $('html').height() < 1099) {
        var liveTrade = 1.5;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1023 && $('html').height() < 1043) {
        var liveTrade = 1.4;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 951 && $('html').height() < 1024) {
        var liveTrade = 1.31;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 875 && $('html').height() < 950) {
        var liveTrade = 1.29;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 772 && $('html').height() < 876) {
        var liveTrade = 1.14;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else{
        var liveTrade = 1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    }
}

function widthSize(){
    if(window.innerWidth > 1370 && window.innerWidth <= 1440) {
        return "s90";
    }else if(window.innerWidth > 1441 && window.innerWidth <= 1540) {
        return "s85";
    }else if(window.innerWidth > 1540 && window.innerWidth <= 1800){
        return "s80";
    }else if(window.innerWidth > 1801 && window.innerWidth <= 2030){
        return "s75";
    }else if(window.innerWidth > 2030 && window.innerWidth <= 2303){
        return "s67";
    }else if(window.innerWidth > 2303 && window.innerWidth <= 2559){
        return "s50";
    }else if(window.innerWidth > 2559){
        return "s49";
    }else{
        return "s100";
    }
}

var setElementHeightWeb = function () {
    if($('html').width() < 1021) {
        $('.col-smb-1').css('float', 'left');
        $('.col-smb-2').css('float', 'left');
        $('.col-smb-3').css('float', 'left');
        $('.col-smb-4').css('float', 'left');
        $('.col-smb-5').css('float', 'left');
        $('.col-smb-6').css('float', 'left');
        $('.col-smb-7').css('float', 'left');
        $('.col-smb-8').css('float', 'left');
        $('.col-smb-9').css('float', 'left');
        $('.col-smb-10').css('float', 'left');
        $('.col-smb-11').css('float', 'left');
        $('.col-smb-12').css('float', 'left');

        $('.col-smb-1').css('width', '8.33333333%');
        $('.col-smb-2').css('width', '16.66666667%');
        $('.col-smb-3').css('width', '25%');
        $('.col-smb-4').css('width', '33.33333333%');
        $('.col-smb-5').css('width', '41.66666667%');
        $('.col-smb-6').css('width', '50%');
        $('.col-smb-7').css('width', '58.33333333%');
        $('.col-smb-8').css('width', '66.66666667%');
        $('.col-smb-9').css('width', '75%');
        $('.col-smb-10').css('width', '83.33333333%');
        $('.col-smb-11').css('width', '91.66666667%');
        $('.col-smb-12').css('width', '100%');
    }

    if($('html').width() > 1300){
        $('.f-livetrade').css('font-size','14px');
    } else if($('html').width() > 1200 && $('html').width() < 1301){
        $('.f-livetrade').css('font-size','13px');
    } else if($('html').width() > 1000 && $('html').width() < 1201){
        $('.f-livetrade').css('font-size','9px');
    } else if($('html').width() < 1001){
        $('.f-livetrade').css('font-size','7px');
    }

    if(window.innerWidth > 1370 && window.innerWidth <= 1520) {
        $('.slidee > div').css('font-size','13px');
        $('.slidee > div').css('padding-top','5px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 1521 && window.innerWidth <= 1800){
        $('.slidee > div').css('font-size','14px');
        $('.slidee > div').css('padding-top','5px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 1801 && window.innerWidth <= 2030){
        $('.slidee > div').css('font-size','16px');
        $('.slidee > div').css('padding-top','4px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }else if(window.innerWidth > 2030 && window.innerWidth <= 2303){
        $('.slidee > div').css('font-size','17px');
        $('.slidee > div').css('padding-top','3px');
    }else if(window.innerWidth > 2303 && window.innerWidth <= 2559){
        $('.slidee > div').css('font-size','20px');
        $('.slidee > div').css('padding-top','2px');
    }else if(window.innerWidth > 2559){
        $('.slidee > div').css('font-size','22px');
        $('.slidee > div').css('padding-top','2px');
    }else{
        $('.slidee > div').css('font-size','11.5px');
        $('.slidee > div').css('padding-top','6px');
        $('.slidee > div').css('padding-right','1px');
        $('.slidee > div').css('padding-left','1px');
    }

    if($('html').width() > 545 && $('html').width() < 576) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 7px!important');
        $('.col-sm-tab').css('flex','0 0 9.9%');
        $('.col-sm-tab').css('max-width','9.9%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 479 && $('html').width() < 546) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6px!important');
        $('.col-sm-tab').css('flex','0 0 9.85%');
        $('.col-sm-tab').css('max-width','9.85%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 428 && $('html').width() < 480) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.5px!important');
        $('.col-sm-tab').css('flex','0 0 9.7%');
        $('.col-sm-tab').css('max-width','9.7%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 400 && $('html').width() < 429) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5px!important');
        $('.col-sm-tab').css('flex','0 0 9.6%');
        $('.col-sm-tab').css('max-width','9.6%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 364 && $('html').width() < 401) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 4.5px!important');
        $('.col-sm-tab').css('flex','0 0 9.45%');
        $('.col-sm-tab').css('max-width','9.45%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() > 335 && $('html').width() < 365) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 4px!important');
        $('.col-sm-tab').css('flex','0 0 9.3%');
        $('.col-sm-tab').css('max-width','9.3%');
        $('.col-sm-tab').css('display','inline-block');
    } else if($('html').width() < 366) {
        $('.fs-icon-bips').css('cssText', 'font-size: 1rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 3.5px!important');
        $('.col-sm-tab').css('flex','0 0 9%');
        $('.col-sm-tab').css('max-width','9%');
        $('.col-sm-tab').css('display','inline-block');
    }


    if($('html').width() > 1315) {
        $('.d-xxl-block').css('cssText', 'display: block!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 0px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '50px');
        $('.img-avatar').css('height', '50px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '11px');
        $('.f-12').css('font-size', '12px');
        $('.f-xs-14').css('font-size', '12px');
        $('.f-14').css('font-size', '14px');
        $('.f-16').css('font-size', '16px');
        $('.f-10').css('font-size', '10px');
        $('.f-8').css('font-size', '8px');
        $('.cssmenu ul li a').css('padding', '15px 25px');
        $('h5').css('cssText', 'font-size: 14px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding','0px 6.5px');
        $('.padding-15').css('padding','0px 15.3px');
        $('.padding-7').css('padding','0px 7.8px');
        $('.padding-21').css('padding','0px 21.09px');
        $('.padding-11').css('padding','0px 11.1px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1176 && $('html').width() < 1316) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '10px');
        $('.f-12').css('font-size', '11px');
        $('.f-xs-14').css('font-size', '11px');
        $('.f-14').css('font-size', '13px');
        $('.f-16').css('font-size', '15px');
        $('.f-10').css('font-size', '9px');
        $('.f-8').css('font-size', '7px');
        $('.cssmenu ul li a').css('padding', '15px 22px');
        $('h5').css('cssText', 'font-size: 13px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1116 && $('html').width() < 1177) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.9px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '38.5px');
        $('.img-avatar').css('height', '38.5px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '8px');
        $('.f-8').css('font-size', '7px');
        $('.cssmenu ul li a').css('padding', '15px 19px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 1020 && $('html').width() < 1117) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-block').css('cssText', 'display: block!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.9rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 5.9px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 1 auto');
        $('.col-sm-tab').css('max-width','10.49%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '33px');
        $('.img-avatar').css('height', '33px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '8px');
        $('.f-8').css('font-size', '6px');
        $('.cssmenu ul li a').css('padding', '15px 10px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 24px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','12px');
    }
    else if($('html').width() > 845 && $('html').width() < 1021) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-block').css('cssText', 'display: block!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.39%');
        $('.col-sm-tab').css('max-width','10.39%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '50px');
        $('.img-avatar').css('height', '50px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '9px');
        $('.f-12').css('font-size', '10px');
        $('.f-xs-14').css('font-size', '10px');
        $('.f-14').css('font-size', '12px');
        $('.f-16').css('font-size', '14px');
        $('.f-10').css('font-size', '7px');
        $('.f-8').css('font-size', '5px');
        $('.cssmenu ul li a').css('padding', '15px 10px');
        $('h5').css('cssText', 'font-size: 12px!important');
        $('h3').css('cssText', 'font-size: 20px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','10px');
    }
    else if($('html').width() > 755 && $('html').width() < 846) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-block').css('cssText', 'display: block!important');
        $('.d-xsm-none').css('cssText', 'display: none!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 2rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 8px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.285%');
        $('.col-sm-tab').css('max-width','10.285%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '40px');
        $('.img-avatar').css('height', '40px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '6px');
        $('.f-12').css('font-size', '7px');
        $('.f-xs-14').css('font-size', '7px');
        $('.f-14').css('font-size', '9px');
        $('.f-16').css('font-size', '11px');
        $('.f-10').css('font-size', '5px');
        $('.f-8').css('font-size', '3px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 9px!important');
        $('h3').css('cssText', 'font-size: 18px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','7px');
    }
    else if($('html').width() > 575 && $('html').width() < 756) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-block').css('cssText', 'display: block!important');
        $('.fs-icon-bips').css('cssText', 'font-size: 1.5rem!important');
        $('.fs-text-bips').css('cssText', 'font-size: 6.2px!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.col-sm-tab').css('flex','0 0 10.1%');
        $('.col-sm-tab').css('max-width','10.1%');
        $('.col-sm-tab').css('display','inline-block');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '8px');
        $('.f-12').css('font-size', '9px');
        $('.f-xs-14').css('font-size', '9px');
        $('.f-14').css('font-size', '11px');
        $('.f-16').css('font-size', '13px');
        $('.f-10').css('font-size', '4px');
        $('.f-8').css('font-size', '3px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 11px!important');
        $('h3').css('cssText', 'font-size: 16px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','9px');
    }
    else if($('html').width() < 576) {
        $('.d-xxl-none').css('cssText', 'display: none!important');
        $('.d-sml-none').css('cssText', 'display: none!important');
        $('.d-xsml-none').css('cssText', 'display: none!important');
        $('.d-xxsml-none').css('cssText', 'display: none!important');
        $('.d-xsm-block').css('cssText', 'display: block!important');
        $('.padding-t5').css('cssText', 'padding: 5px 0px 0px 0px!important');
        $('.menuscaleheader').css('cssText', 'padding: 9px 5px!important');
        $('.img-avatar').css('vertical-align', 'middle');
        $('.img-avatar').css('width', '42px');
        $('.img-avatar').css('height', '42px');
        $('.img-avatar').css('border-radius', '50%');
        $('.f-11').css('font-size', '8px');
        $('.f-12').css('font-size', '9px');
        $('.f-xs-14').css('font-size', '9px');
        $('.f-14').css('font-size', '11px');
        $('.f-16').css('font-size', '13px');
        $('.f-10').css('font-size', '6px');
        $('.f-8').css('font-size', '4px');
        $('.cssmenu ul li a').css('padding', '15px 5px');
        $('h5').css('cssText', 'font-size: 11px!important');
        $('h3').css('cssText', 'font-size: 16px!important');
        $('.padding-6').css('padding', '0px 0px');
        $('.padding-15').css('padding', '0px 0px');
        $('.padding-7').css('padding', '0px 0px');
        $('.padding-21').css('padding', '0px 0px');
        $('.padding-11').css('padding', '0px 0px');
        $('.btn').css('font-size','9px');
    }

    var marquee = $('html').width();
    /*marquee = marquee + 20;*/
    $('.runningText p').css('minWidth', (marquee));
    $('.runningText').css('minWidth', (marquee));

    var cardIntIndices = $('html').height() - 255;
    $('.card-intIndices').css('min-height', cardIntIndices);

    var cardCurrencies = $('html').height() - 310;
    $('.card-currencies').css('min-height', cardCurrencies);

    var cardCommodity = $('html').height() - 245;
    $('.card-commodity').css('min-height', cardCommodity);

    var heightbarchart = $('html').height() - 360;
    $('#heightValue-trigger').val(heightbarchart);
    $('#heightValue-trigger').click();

    var height220a = $('html').height() - 473.5;
    $('.card-220-a').css('min-height', height220a);


    var card406 = $('html').height() - 330;
    $('.card-406').css('min-height', card406);

    var cardBrokerInfo = $('html').height() - 275;
    $('.card-brokerInfo').css('min-height', cardBrokerInfo);

    var cardBrokerTradeSummary = $('html').height() - 220;
    $('.card-brokerTradeSummary').css('min-height', cardBrokerTradeSummary);

    var cardTradePL = $('html').height() - 333;
    $('.card-tradePL').css('min-height', cardTradePL);

    var cardSO = $('html').height() - 235;
    $('.card-tradeSO').css('min-height',cardSO);

    var cardEO = $('html').height() - 265;
    $('.card-tradeEO').css('min-height',cardEO);

    var cardTradeAdv = $('html').height() - 247;
    $('.card-tradeAdv').css('min-height', cardTradeAdv);

    var cardSpecialNotation = $('html').height() - 220;
    $('.card-specialNotation').css('min-height', cardSpecialNotation);

    var card354 = heightbarchart + 5;
    $('.card-354').css('min-height', card354);

    var height440a = $('html').height() - 295.05;
    $('.card-440-a').css('height', height440a);

    var height440ac = $('html').height() - 285.05;
    $('.card-440-ac').css('height', height440ac);

    var height345 = $('html').height()-348.05;
    $('.card-345').css('height', (height345));

    var height695 = $('html').height();
    $('.card-695').css('min-height', (height695));
    var plogin = ($('html').height()-$('.p-login').height())/2;
    // $('.p-login').css('padding-top', (plogin));
    // $('.p-login').css('padding-bottom', (plogin));

    var height575 = $('html').height()-84-32-5;
    $('.card-575').css('min-height', (height575));
    var height527 = height575-$('.tabheaderfill').height()-3;
    $('.card-527').css('min-height', (height527));

    var height520 = height527-7;
    $('.card-520').css('minHeight', (height520));

    var height310od = (310/520)*(height520) - 43.3;
    $('.card-310-odlist').css('minHeight', (height310od));

    var height290 = (height520-63-67-100);
    $('.card-290').css('min-height', (height290));
    var height240 = (height290-50);
    $('.card-240').css('min-height', (height240));
    $('.tbodyGroupAdd').css('min-height', (height240));

    var height145 = (height520-63-67-100)/2;
    $('.card-145').css('min-height', (height145));
    var height95 = (height145-50);
    $('.card-95').css('min-height', (height95));
    $('.tbodyGroup').css('min-height', (height95));

    var height467 = height520-53;
    $('.card-467').css('minHeight', (height467));

    var height482 = height527-45;
    $('.card-482').css('min-height', (height482));

    var height514 = height520-4.5+2;
    $('.card-514').css('minHeight', (height514));

    var heightStockWathclist = height520-4.5+2-30;
    $('.card-watchlistcust').css('minHeight', (heightStockWathclist));

    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
        // Do Firefox-related activities
        var height112 = (115/474)*(height520-46);
        $('.card-112').css('minHeight', (height112));
        var height362 = (358.9/474)*(height520-46);
        $('.card-362').css('minHeight', (height362));

        var height356 = ((358.9/474)*(height520-46))-7+2;
        $('.card-356').css('minHeight', (height356));
        $('#cmp.card-356').css('minHeight', (height356));


        if($('html').height() > 1099 && $('html').height() < 1200)    {
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-6;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 768 && $('html').height() < 900) ||
            ($('html').height() > 899 && $('html').height() < 1000)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-3;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 999 && $('html').height() < 1100)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-4;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 1199)&& ($('html').height() < 2000)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-7;
            $('.card-283').css('minHeight', (height283a));
        } else if(($('html').height() > 2001)){
            var height283 = (279/482)*(height482);
            var height283a = (279/482)*(height482)-10;
            $('.card-283').css('minHeight', (height283a));
        } else{
            var height283 = (279/482)*(height482);
            $('.card-283').css('minHeight', (height283));
        }

        var height191 = (197/199)*(height482-height283)-4;
        $('.card-191').css('minHeight', (height191));
        var height161 = (169/169)*(height482-height283)-4-30-4-1;
        $('.card-161').css('minHeight', (height161));
        var height1611 = (167/169)*(height482-height283)-4-30-4-1;
        $('.card-161-1').css('minHeight', (height1611));
        var height1612 = (167/169)*(height482-height283)-4-30-4-2;
        $('.card-161-2').css('minHeight', (height1612));

        $('.card-topDT').css('minHeight', (height161/3) - 3);
        $('.tb-dailytech').css('minHeight', (height161/3) * 2);

        var height323 = (321/324)*(height482-46-height112)-2;
        $('.card-323').css('minHeight', (height323));
        
        var heightInfoUpper = (height520 - 31) * 0.3;
        $('.card-120').css('minHeight', (heightInfoUpper));

        var heightInfoLower = (height520 - 32) * 0.7;
        $('.card-350').css('minHeight', (heightInfoLower));
        
        var heightInfoStockUpper = 140;
        $('.card-140').css('minHeight', (heightInfoStockUpper));

        var heightInfoStock = height482 - 35 - 140;
        $('.card-310-is').css('minHeight', (heightInfoStock));

        var heightInfoTrade = height482 - 25 - 140;
        $('.card-310-tr').css('minHeight', (heightInfoTrade));      
        
        var heightInfoTradeAut = height482 - 132;
        $('.card-310-aut').css('minHeight', (heightInfoTradeAut));

        if($('html').width() > 4099){
            var height326 = ((321/324)*(height482-46-height112)-2)-20;
            $('.card-326').css('minHeight', (height326));
        } else if($('html').width() < 4100){
            var height326 = (321/324)*(height482-46-height112)-2;
            $('.card-326').css('minHeight', (height326));
        }

        var height310 = (310/520)*(height520);
        $('.card-310').css('minHeight', (height310));



        var height176 = (175/176)*(height520-height310-30);
        $('.card-176').css('minHeight', (height176));
        var height177 = (176/177)*(height520-height310-30+1);
        $('.card-177').css('minHeight', (height177));
    }else{
        var height112 = (112/474)*(height520-46);
        $('.card-112').css('minHeight', (height112));
        var height362 = (361.9/474)*(height520-46);
        $('.card-362').css('minHeight', (height362));

        var height356 = ((361.9/474)*(height520-46))-5.382+0.764+2;
        $('.card-356').css('minHeight', (height356));
        $('#cmp.card-356').css('minHeight', (height356));

        // console.log("minheight 356 set to "+height356);
        var height283 = (283/482)*(height482);
        $('.card-283').css('minHeight', (height283));
        var height191 = (199/199)*(height482-height283)-4;
        $('.card-191').css('minHeight', (height191));
        var height161 = (169/169)*(height482-height283)-4-30-4;
        $('.card-161').css('minHeight', (height161));
        var height1611 = (169/169)*(height482-height283)-4-30-4-1;
        $('.card-161-1').css('minHeight', (height1611));
        var height1612 = (169/169)*(height482-height283)-4-30-4-2;
        $('.card-161-2').css('minHeight', (height1612));

        $('.card-topDT').css('minHeight', (height161/3) - 3);
        $('.tb-dailytech').css('minHeight', (height161/3) * 2);

        var height323 = (324/324)*(height482-46-height112);
        $('.card-323').css('minHeight', (height323));
        
        var heightInfoUpper = (height520 - 31) * 0.3;
        $('.card-120').css('minHeight', (heightInfoUpper));

        var heightInfoLower = (height520 - 32) * 0.7;
        $('.card-350').css('minHeight', (heightInfoLower));

        var heightInfoStockUpper = 140;
        $('.card-140').css('minHeight', (heightInfoStockUpper));

        var heightInfoStock = height482 - 35 - 140;
        $('.card-310-is').css('minHeight', (heightInfoStock));

        var heightInfoTrade = height482 - 25 - 140;
        $('.card-310-tr').css('minHeight', (heightInfoTrade));      
        
        var heightInfoTradeAut = height482 - 132;
        $('.card-310-aut').css('minHeight', (heightInfoTradeAut));

        if($('html').width() > 4099){
            var height326 = ((324/324)*(height482-46-height112))-20;
            $('.card-326').css('minHeight', (height326));
        } else if($('html').width() < 4100){
            var height326 = (324/324)*(height482-46-height112);
            $('.card-326').css('minHeight', (height326));
        }

        var height310 = (310/520)*(height520);
        $('.card-310').css('minHeight', (height310));


        var height176 = (175/176)*(height520-height310-30);
        $('.card-176').css('minHeight', (height176));
        var height177 = (176/177)*(height520-height310-30+1);
        $('.card-177').css('minHeight', (height177));
    }

    if($('html').height() > 2200){
        var height234 = height527-$('.grid-294').height()-40;
        $('.card-234').css('min-height', (height234));
    } else{
        var height234 = height527-$('.grid-294').height();
        $('.card-234').css('min-height', (height234));
    }

    var height399 = height520-121;
    $('.card-399').css('min-height', (height399));
    var height344 = height399-40-15;
    $('.card-344').css('min-height', (height344));

    //c9
    if(window.innerWidth > 1370 && window.innerWidth <= 1520) {
        var marketwidth = $('html').width() * 0.5285;
    }else if(window.innerWidth > 1520 && window.innerWidth <= 1800){
        var marketwidth = $('html').width() * 0.529;
    }else if(window.innerWidth > 1800 && window.innerWidth <= 2030){
        var marketwidth = $('html').width() * 0.530;
    }else if(window.innerWidth > 2030 && window.innerWidth <= 2303){
        var marketwidth = $('html').width() * 0.531;
    }else if(window.innerWidth > 2303 && window.innerWidth <= 2559){
        var marketwidth = $('html').width() * 0.532;
    }else if(window.innerWidth > 2559){
        var marketwidth = $('html').width() * 0.534;
    }else if(window.innerWidth < 1025){
        var marketwidth = $('html').width() * 0.50;
    }
    else{
        var marketwidth = $('html').width() * 0.5285;
    }
    $('.addmorewidth').css("min-width", marketwidth);


    var height220 = ((height520-20)/2)-40-0.5;
    $('.card-220').css('min-height', (height220));

    var heightAutOrder = $('html').height() - 480;
    $('.card-Aut-Order').css('min-height', heightAutOrder);

    var height132 = ((height520-40)/3)-30+2;
    $('.card-132').css('min-height', (height132));
    var height152 = height132+20+0.1667;
    $('.card-152').css('min-height', (height152));
    var height92 = height132-40+0.1667;
    $('.card-92').css('min-height', (height92));
    var height76 = height132-56+0.1667;
    $('.card-76').css('min-height', (height76));

    var height452 = height527-31-37-6;
    $('.card-452').css('min-height', (height452));

    var height487 = height527-40;
    $('.card-487').css('min-height', (height487));

    var height487pe = height527-80;
    $('.card-487-pe').css('min-height', (height487pe));

    var height305 = height527/1.728;
    $('.card-305').css('min-height', (height305));
    var height221 = height527/2.385;
    $('.card-221').css('min-height', (height221));
    var height194 = height221-17-10;
    $('.card-194').css('min-height', (height194));
    var height448 = height527-77-2;
    $('.card-448').css('min-height', (height448));

    var height444 = height527-77-2-4;
    $('.card-444').css('min-height', (height444));

    var height57 = (height448/8)+1.1;
    $('.list-group-item-portofolio').css('min-height', (height57));

    var height392 = height527-62-11-25-20-17;
    $('.card-392').css('min-height', (height392));

    var heighthistorical = height527-62-11-25-20-17-13;
    $('.card-392-historical').css('min-height', (heighthistorical));

    var height478 = height527-49-1.5;
    $('.card-478').css('min-height', (height478));
    var height472 = height527-45-10;
    $('.card-472').css('min-height', (height472));
    var height381 = height472-62-29;
    $('.card-381').css('min-height', (height381));
    var height155 = (height472-62-30-10-40-20)/2;
    $('.card-155').css('min-height', (height155));
    var height233 = height527-$('.grid-294').height()-0.5;
    $('.card-233').css('min-height', (height233));
    var height202 = height527-$('.grid-294').height()-30-0.5;
    $('.card-202').css('min-height', (height202));
    var height515 = height527-12.5;
    $('.card-515').css('min-height', (height515));
    var height334 = height515-121-40-20-20-1;
    $('.card-334').css('min-height', (height334));
    var height230 = (height515-40-15)/2;
    $('.card-230').css('min-height', (height230));

    var height175 = (height527/3)-0.833;
    $('.card-175').css('min-height', (height175));


    var height175b = (height527/3)-0.7;
    $('.card-175b').css('min-height', (height175b));

    var height138 = height175-37;
    $('.card-138').css('min-height', (height138));

    var height479 = height527-25-20-3-1;
    $('.card-479').css('min-height', (height479));
    var height406 = height479-73;
    $('.card-406').css('min-height', (height406));
    var height282 = ((height527-40)/1.727)+0.298;
    $('.card-282').css('min-height', (height282));
    var height190 = ((height527-40)/2.563)+0.183;
    $('.card-190').css('min-height', (height190));
    var height160 = height190-30;
    $('.card-160').css('min-height', (height160));
    var height156 = height160-4;
    $('.card-156').css('min-height', (height156));
    var height111 = ((height527-40-30)/4.117)+0.118;
    $('.card-111').css('min-height', (height111));
    var height158 = height156+2;
    $('.card-158').css('min-height', (height158));
    var height324 = ((height527-40-30)/1.410)+2.241;
    $('.card-324').css('min-height', (height324));
    var height321 = height324-3;
    $('.card-321').css('min-height', (height321));
    var height372 = (height527-40-40-30-45)+0.5;
    $('.card-372').css('min-height', (height372));
    var height129 = (height372+15)/3;
    $('.card-129').css('min-height', (height129));
    var height257 = height515/2;
    $('.card-257').css('min-height', (height257));
    var height475 = height515-40;
    $('.card-475').css('min-height', (height475));

    var height440 = height475-25;
    $('.card-440').css('min-height', (height440));
    
    var height480 = height475+5;
    $('.card-480').css('min-height', (height480));


    var height215 = (height440-10)/2;
    $('.card-215').css('min-height', (height215));
    
    var height169 = height472/2.792;
    $('.card-169').css('min-height', (height169));

    var height169_mf = ($('html').height() - 340) / 2;
    $('.card-169-mf').css('min-height', (height169_mf));

    var height113 = height472/4.176;
    $('.card-113').css('min-height', (height113));
    var height281 = height472/1.679;
    $('.card-281').css('min-height', (height281));

    var height30 = height527-height169-height92-46-37-37-116;
    $('.card-30').css('min-height', (height30));
    var height64 = (height76+24+16)-($('.box-warning-esbnreg').height()+16);
    $('.card-64').css('min-height', (height64));

    var rsccontainer = height527;
    $('.rsc-container').css('min-height', (rsccontainer));
    var rsccontent = height527-133;
    $('.rsc-content').css('min-height', (rsccontent));
    var rscscroll = height527-40;
    $('.rsc-scroll').css('min-height', (rscscroll));
};

var setElementLiveZoom = function () {
    if($('html').height() > 2601)  {
        var liveTrade = 4.4;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if ($('html').height() > 2201 && $('html').height() < 2600) {
        var liveTrade = 3.5;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 2049 && $('html').height() < 2200) {
        var liveTrade = 3.1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1533 && $('html').height() < 2050) {
        var liveTrade = 2.3;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1319 && $('html').height() < 1534) {
        var liveTrade = 2.1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1100 && $('html').height() < 1320) {
        var liveTrade = 1.63;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1042 && $('html').height() < 1099) {
        var liveTrade = 1.5;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 1023 && $('html').height() < 1043) {
        var liveTrade = 1.4;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 951 && $('html').height() < 1024) {
        var liveTrade = 1.31;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 875 && $('html').height() < 950) {
        var liveTrade = 1.29;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else if($('html').height() > 772 && $('html').height() < 876) {
        var liveTrade = 1.14;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    } else{
        var liveTrade = 1;
        $('.myLiveTrade').css({'zoom':liveTrade, '-moz-transform':'scale('+liveTrade+')'});
    }
};

var ElementStockHistory = function(){
    var height575 = $('html').height()-84-32-5;
    var height527 = height575-$('.tabheaderfill').height()-3;
    var height372 = (height527-40-40-30-45)+0.5;
    $('.card-372').css('min-height', (height372));

    var height520 = height527-7;
    $('.card-520').css('min-height', (height520));
    var height467 = height520-53;
    $('.card-467').css('min-height', (height467));

    var height290 = (height520-63-67-100);
    $('.card-290').css('min-height', (height290));
    var height240 = (height290-50);
    $('.card-240').css('min-height', (height240));
    $('.tbodyGroupAdd').css('min-height', (height240));

    var height145 = (height520-63-67-100)/2;
    $('.card-145').css('min-height', (height145));
    var height95 = (height145-50);
    $('.card-95').css('min-height', (height95));
    $('.tbodyGroup').css('min-height', (height95));
};

const changeScale = (scale) => {
    $('html').css({ zoom: scale/100 });

    /*document.body.style.setProperty('--column-col-sm-3-6', "25%");
    document.body.style.setProperty('--column-col-sm-2-4', "16.666667%");*/

    document.body.style.setProperty('--header-menu-scale', 'none');
    document.body.style.setProperty('--header-menu', 'block');

    ResizeResponsive();
}

const colType = {
    1: 'col-sm-12',
    2: 'col-sm-6',
    3: 'col-sm-4',
    4: 'col-sm-3',
    5: 'col-mn-5',
    6: 'col-sm-2',
    7: 'col-mn-7'
}

export { 
    ResizeResponsive, 
    widthSize, 
    changeScale, 
    setElementHeightWeb, 
    setElementLiveZoom, 
    ElementStockHistory, 
    colType 
}

