import React, { memo } from 'react';
import { ContextConnector } from './appcontext.js';
import { AppFrameContext } from './appframe.js';
import { Menu } from 'semantic-ui-react';

const FillHeaderTab_Base = memo((props) => {
    // expected in props:
    // instances: array of pageInstance object
    // activeInstance: current pageInstance object
    // activateFrame: (instanceName) => {} hook to activate selected frame ID
    // linkTitles: object, mapping instanceName to link title
    return (
        /*<Menu pointing secondary>*/
        <div style={{display: (props.frameActive ? "block" : "none")}}>
            <div className="cssmenu tabheaderfill">
                <ul>
                    {
                        Object.keys(props.instances).map((k) => {
                            var e = props.instances[k];
                            var tabMode = props.tabMode;
                            let instancesLeng = Object.keys(props.instances).length;
                            let colType = props.tabColumn ? props.tabColumn : (instancesLeng > 6 || instancesLeng === 5 ? "mn-"+instancesLeng : "sm-"+12/instancesLeng);
                            let hideMenu = (tabMode === "manual" && e.instanceName.search('Aut') >= 0) ||
                                (tabMode === "auto" && e.instanceName.search('tradePage') >= 0) ||
                                (tabMode === "beforeregister" && e.instanceName.search('EsbnxPage') >= 0) ||
                                (tabMode === "afterregister" && e.instanceName.search('EsbnPage') >= 0 ) ||
                                (tabMode === "beforeregister" && e.instanceName.search('MutualFundxPage') >= 0) ||
                                (tabMode === "afterregister" && e.instanceName.search('MutualRegistPage') >= 0 ) ? "d-none" : "";
                                
                            return (
                                <li key={e.instanceName}
                                    className=
                                        {
                                            props.activeInstance === e ? /** active */ 
                                                `active col-${colType} bg-blue-white click-pointer text-center bg-blue-white ${hideMenu}`
                                            : //non-active
                                                `col-${colType} click-pointer text-center d-border-right ${hideMenu}`
                                        }
                                    name={e.instanceName}
                                    active={props.activeInstance === e}
                                    onClick={
                                        () => props.activateFrame(e.instanceName)
                                    }
                                >
                                    <a>
                                    <span className="f-12">
                                        {
                                            props.linkTitles[e.instanceName] || e.title
                                        }
                                    </span>
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    )
});

function _connectFrameTree(vars, actions, ownProps) {
    var tree = vars.instanceTreeIndexes[ownProps.treeName]
    if (!tree) {
        return {
            frameActive: false,
            instances: {},
            activeInstance: null,
            activateFrame: (instanceName) => actions.sendAction('switchPage', {treeName: ownProps.treeName, instanceName})
        }
    } else {
        return {
            frameActive: tree.frameActive,
            instances: tree.pageInstances,
            activeInstance: tree.activeInstance,
            activateFrame: (instanceName) => actions.sendAction('switchPage', {treeName: ownProps.treeName, instanceName})
        }
    }
}

const FillHeaderTab = ContextConnector(AppFrameContext,
    (v, act, props) => (
        v.useInstanceTree ? _connectFrameTree(v, act, props) : {
            frameActive: v.frameActive,
            instances: v.pageInstances,
            activeInstance: v.activeInstance,
            activateFrame: (instanceName) => act.sendAction('switchPage', {instanceName})
        }
    )
)(FillHeaderTab_Base);

export default FillHeaderTab;