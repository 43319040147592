import React from "react";
import { Tab } from 'semantic-ui-react';

import user_avatar from './../img/man.png';
import { ContextConnector } from "../appcontext";
import { BIPSAppContext } from "../AppData";
import { WSConnectionAction } from "../appnetwork";
import { fmsg, ResizeResponsive, changeScale } from "../utils";

const stateLanguages = [
    { key: '1', value: 'eng', text: 'English' },
    { key: '2', value: 'ina', text: 'Indonesian' },
];

const stateTimeZone = [
    { key: '1', value: 'gmt-12', text: '(GMT-12:00) International' },
    { key: '2', value: 'gmt-11', text: '(GMT-11:00) Midway Island' },
];

const updatePixelRatio = (val) => {
    let pr = window.devicePixelRatio;
    console.log(pr, val/100)
    // window.;

    console.log( matchMedia(`(resolution: ${val/100}dppx)`).matches)
    matchMedia(`(resolution: ${pr}dppx)`)
        .addEventListener("change", (e) => {
            console.log("aa")
            if (e.matches) {
                console.log(" devicePixelRatio >= ", pr)
                /* devicePixelRatio >= 2 */
            } else {
                console.log(" devicePixelRatio < ", pr)
                /* devicePixelRatio < 2 */
            }
        }, { once: true });
}

const panes = [
    { menuItem: 'Appearance', render: () => <Tab.Pane><TabAppearance/></Tab.Pane> },
    { menuItem: 'Security', render: () => <Tab.Pane><TabPrivacy/></Tab.Pane> },
//   { menuItem: 'Notification', render: () => <Tab.Pane><TabNotification/></Tab.Pane> },
]
class ModalSetting_Base extends React.PureComponent {
    constructor(props){ 
        super(props);
    }

    state = {tab: 0};

    onTabChange = (activeIndex) => {
        this.setState({tab: activeIndex});
        let scale = this.props.userPreference.interface_scale.split("%")[0];
        changeScale(Number(scale)); 
        this.props.setDataHandler({"thememode": this.props.userPreference.theme === "dark" ? true : false})
    }

    saveSetting = (val) => {
        let { userPreference, setDataHandler, handleResize } = this.props;
        let checkData = Object.keys(userPreference).map(d=>userPreference[d] === val[d]).filter(d=>d===false)
        if(checkData.length > 0){
            setDataHandler({ settingModalStatus: true });
            this.refs.netActionAux.send(fmsg.x_userPreference(val)); 
            // handleResize({resize: val.interface_scale.split("%")[0]})
            // (userPreference.interface_scale != val.interface_scale) && updatePixelRatio(val.interface_scale.split("%")[0])

            // console.log("windowDvice", window.devicePixelRatio);
        }else{
            setDataHandler({"statusAlertN": true, "msgAlert3": "You have not made changes"});
        }
    }

    render() {
        let p = this.props;
        const grey = 'gray';
        return (
            <>
                <WSConnectionAction ref="netActionAux" socketID="aux" />
                <div className="text-white f-12">
                <Tab
                    menu={{grey, fluid: true, vertical: true }}
                    menuPosition='left'
                    panes={ [
                        { 
                            menuItem: 'Appearance', 
                            render: () => <Tab.Pane> <TabAppearance data={p.userPreference} saveSetting={(val) => this.saveSetting(val)} /> </Tab.Pane> 
                        },{ 
                            menuItem: 'Security', 
                            render: () => <Tab.Pane> <TabPrivacy data={p.userPreference} saveSetting={(val)=>this.saveSetting(val)} /> </Tab.Pane> 
                        // },{ 
                        //     menuItem: 'Notification', 
                        //     render: () => <Tab.Pane><TabNotification/></Tab.Pane> 
                        }
                    ]}
                    grid={{paneWidth: 12, tabWidth: 3}}
                    activeIndex= {this.state.tab}
                    onTabChange={(e, { activeIndex }) => this.onTabChange(activeIndex)}
                />
                </div>
            </>
        );
    }
}

class TabAppearance_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    state = {
        valueBalance : "0",
        ...this.props.data
    }

    onChangeScale = (scale) => {
        changeScale(scale)
        this.setState(ps=> ({ ...ps, interface_scale: scale+"%" }) )
    }

    render() {
        let s = this.state;
        ResizeResponsive();
        return (
            <div>
                <div className="col align-item-center"> 
                    <div className="text-white setting align-items-center">
                        {/* Time and language Settings */}
                        {/* <div className="border-bottom"> */}
                            {/* <div className="form-group row mb-0">
                                <div className="col-sm-5 mx-0 mb-2 ">
                                    <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                        Language
                                    </div>
                                </div>

                                <div className="col-sm-5 mx-0 mb-2 ">
                                    <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                        Time zone
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mb-0">
                                <div className="col-sm-5 mx-0 mb-2 ">
                                    <div className="ui small input col-sm-12 f-12 text-center align-self-center">
                                        <Dropdown placeholder='Choose' search selection options={stateLanguages} className="col-sm-12 f-12"/>                                                        
                                    </div>
                                </div>
                                <div className="col-sm-5 mx-0 mb-2 ">
                                    <div className="ui small input col-sm-12 f-12 text-center align-self-center">
                                        <Dropdown placeholder='Choose' search selection options={stateTimeZone} className="col-sm-12 f-12"/>                                                                                        
                                    </div>
                                </div>
                            </div> */}
                        {/* </div> */}

                        {/* Theme Settings */}
                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-14 text-center align-self-center text-white">
                                    Theme Settings
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-5 f-12 text-center align-self-center" >
                                    <input type="radio" className="radio_item" value="" name="itemTheme" id="radio1" onClick={
                                        (e) => { 
                                            this.setState(ps => ({ ...ps, theme: "dark" }) );
                                            this.props.isNight(true);
                                        }
                                    } checked={s.theme === "dark" ? true : false}/>
                                    <label className="label_item" htmlFor="radio1"> <i className="logo-dark-theme"/> </label>
                                </div>
                                <div className="ui small input col-sm-6 f-12 text-center align-self-center">
                                    <input type="radio" className="radio_item" value="" name="itemTheme" id="radio2" onClick={
                                        (e) => {
                                            this.setState(ps => ({ ...ps, theme: "light" }) );
                                            this.props.isNight(false);
                                        }
                                    } checked={s.theme === "light" ? true : false} />
                                    <label className="label_item" htmlFor="radio2"> <i className="logo-light-theme"/> </label>
                                </div>
                            </div>
                        </div>

                        <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>

                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Interface Scale
                                </div>
                                <div className="ui small input col-sm-12 f-9 text-center align-self-center danger-text">
                                    *) Not compatible in Mozilla Firefox
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mb-0 pl-4">
                            {
                                [
                                    {id: "80%", value: 80, label: "80 %"},
                                    {id: "90%", value: 90, label: "90 %"},
                                    {id: "100%", value: 100, label: "100 %"},
                                    {id: "110%", value: 110, label: "110 %"},
                                    {id: "120%", value: 120, label: "120 %"}
                                ].map(d => 
                                    <div className="col-sm-2 mx-0 mb-2" key={d.id}>
                                        <div className="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-100">
                                            <input className="magic-radio" type="radio" name="scale" id={d.id} value="option" 
                                                onClick={() => this.onChangeScale(d.value)} checked={d.id === s.interface_scale ? true : false} />
                                            <label htmlFor={d.id} className="text-white">
                                                {d.label}
                                            </label>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>

                        {/* <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Balance/Limit displayed at the top
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="form-group row mb-0 pl-4">
                            <div className="col-sm-4 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-300">
                                <input className="magic-radio" type="radio" name="balance" id="a" value="option" onClick={
                                    (e) => {
                                        this.setState({
                                            valueBalance: "0"
                                        });
                                    }
                                } checked={this.state.valueBalance === "0" ? true : false}/>
                                    <label htmlFor="a" className="text-white f-10-center">
                                    Always show the "Total" amount
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-300">
                                <input className="magic-radio" type="radio" name="balance" id="b" value="option" onClick={
                                    (e) => {
                                        this.setState({
                                            valueBalance: "1"
                                        });
                                    }
                                } checked={this.state.valueBalance === "1" ? true : false}/>
                                    <label htmlFor="b" className="text-white f-10-center">
                                    Hide account balance/limit
                                    </label>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}
                    </div>
                    <div className="form-group row mb-0">
                        <div className="col-sm-12 mx-0 mb-2 pl-5">
                            <button type="submit" className="btn btn-primary pull-right" onClick={() => this.props.saveSetting(this.state)}>
                                <i className="logo-btn-save"></i> 
                                Save Setting 
                            </button>                           
                        </div>
                    </div>
                </div> 
            </div> 
        );
    }
}

class TabPrivacy extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    state = {
        ImgProfil:"",
        ...this.props.data
    }
    onChangeAvatar = (e) => {
        // var uploadFile = document.getElementById("uploadBtnID").value;
        // document.getElementById("uploadFileID").value = uploadFile;

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                ImgProfil: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    render() {
        let s = this.state;
        const imgdisplay = {
            display: 'inline-flex',
            paddingTop: '3px'
        };
        
        const paddingParagraph = {
            padding: '10px'
        }
        
        const divMargin = {
            marginBottom: '15px'
        }
        
        const imgUser = {
            margin: 'auto',
            backgroundColor: '#3c3c3c',
            borderBottom: '2px solid #1A1A1A'
        }

        const avatar = (this.state.ImgProfil)?this.state.ImgProfil:user_avatar;
        return (
            <div>
                {/* <WSConnection ref="netActionAux" socketID="aux" /> */}
                <div className="col align-item-center"> 
                    <div className="text-white setting align-items-center">
                        <div className="form-group row mb-0">
                            <div className="col-sm-5 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-14 text-center align-self-center text-white">
                                Privacy Settings
                                </div>
                            </div>
                        </div>                    
                        {/* <div className="form-group row mb-0">
                            <div className="col-sm-5 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Email
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-sm-5 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center">
                                <input type="text"/>
                                </div>
                            </div>
                            <div className="col-sm-5 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center">
                                    <button className="btn btn-md btn-primary">Changes</button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-sm-5 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-9 text-right">
                                    <i className="text-blue">Current email</i>
                                </div>
                            </div>
                        </div> */}
                        {/* </div> */}

                        {/* <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>

                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Profile Photo
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="form-group row mb-0">
                            <div className="col-sm-1 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center">
                                    <div className="col-md-12" style={imgdisplay}>
                                        <img src={avatar} alt="User" className="img-avatar d-border mr-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center ver-center"> */}
                                    {/* <button className="btn btn-md btn-primary">Upload</button> */}
                                    {/* <div className="fileUpload btn btn-primary">
                                        <span>Browse</span>
                                        <input id="uploadBtnID" type="file" className="upload" accept="image/*" onChange={this.onChangeAvatar}/>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>*/}

                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Pin Usage
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mb-0 pl-4">
                            <div className="col-sm-4 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-300">
                                <input className="magic-radio" type="radio" name="balance" id="a" value="option" 
                                    onClick={() => this.setState(ps=> ({...ps, pin_usage: "always"}) ) }
                                    checked={s.pin_usage === "always" ? true : false}/>
                                    <label htmlFor="a" className="text-white f-12-center">
                                        Always use PIN
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-300">
                                <input className="magic-radio" type="radio" name="balance" id="b" value="option" 
                                    onClick={() => this.setState(ps=> ({...ps, pin_usage: "once"}) ) }
                                    checked={s.pin_usage === "once" ? true : false}/>
                                    <label htmlFor="b" className="text-white f-12-center">
                                        Once PIN
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <div className="col-sm-6 mx-0 mb-2 ">
                                <div className="ui small input col-sm-12 f-9 text-center align-self-center danger-text">
                                    *) Only use PIN for 1st transaction each Login
                                </div>
                            </div>
                        </div>
                        <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>
                        <div className="form-group row mb-0">
                            <div class="col-sm-6 mx-0 mb-2 ">
                                <div class="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                    Session Timeout
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mb-0 pl-4">
                            {
                                [
                                    // {id: "STO05", value: 300000, label: "5 Minute"},
                                    // {id: "STO10", value: 600000, label: "10 Minute"},
                                    {id: "STO15", value: 900000, label: "15 Minute"},
                                    {id: "STO20", value: 12000000, label: "20 Minute"},
                                    {id: "STO30", value: 18000000, label: "30 Minute"},
                                    {id: "STON", value: 0, label: "Never Timeout"},
                                ].map(d => 
                                    <div class="col-mn-5 mx-0 mb-2" key={d.id}>
                                        <div class="ui small input col-sm-12 f-12 text-center align-self-center border-gray-tradding p-2 w-300">
                                        <input class="magic-radio" type="radio" name="radioSTO" id={d.id} value="option" 
                                            onClick={() => this.setState(ps=> ({...ps, "timeout": d.value}))} checked={d.value === s.timeout ? true : false}/>
                                            <label htmlFor={d.id} className="text-white f-12-center">
                                                {d.label}
                                            </label>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <div className="col-sm-12 mx-0 mb-2 pl-5">
                            <button type="submit" className="btn btn-primary pull-right" onClick={() => this.props.saveSetting(this.state)}>
                                <i className="logo-btn-save"></i> Save Setting 
                            </button>                           
                        </div>
                    </div>
                </div>
            </div>  
        );
    }
}

class TabNotification extends React.PureComponent {
    render() {
        return (
            <div>
            <div className="col align-item-center"> 
                <div className="text-white setting align-items-center">
                    <div className="form-group row mb-0">
                        <div className="col-sm-5 mx-0 mb-2 ">
                            <div className="ui small input col-sm-12 f-14 text-center align-self-center text-white">
                                Notification Settings
                            </div>
                        </div>
                    </div>                        
                    <div className="form-group row mb-0">
                        <div className="col-sm-5 mx-0 mb-2 ">
                            <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                
                            </div>
                        </div>
                    </div>

                    <div className="form-group row mb-0">
                        <div className="col-sm-5 mx-0 mb-2 ">
                            <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                                NOTIFICATION ON THE PLATFORM
                            </div>
                        </div>
                    </div>
        
                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-2 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif1" id="notif1" value="option"/>
                                <label htmlFor="notif1" className="text-white f-12-center">
                                Notify me when my Forex/CFD position is about to close
                                </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-2 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif2" id="notif2" value="option"/>
                                <label htmlFor="notif2" className="text-white f-12-center">
                                Notify me of my new position in the rating this week
                                </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-2 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif3" id="notif3" value="option"/>
                                <label htmlFor="notif3" className="text-white f-12-center">
                                Price Alert Set
                                </label>
                        </div>
                    </div>
                </div>

                <div className="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div>
               
                <div className="form-group row mb-0">
                    <div className="col-sm-5 mx-0 mb-2 ">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center text-white">
                            IN-BROWSER NOTIFICATION
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif4" id="notif4" value="option"/>
                                <label htmlFor="notif4" className="text-white f-12-center">
                                Background Push Notifications
                                </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 pl-5">
                        <div className="ui small input col-sm-12 f-9 text-center align-self-center text-gray-tradding pl-5">
                            &nbsp;&nbsp;Account Activity
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif5" id="notif5" value="option"/>
                                <label htmlFor="notif5" className="text-white f-12-center">
                                Closed trades
                                </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 pl-5">
                        <div className="ui small input col-sm-12 f-9 text-center align-self-center text-gray-tradding pl-5">
                            &nbsp;&nbsp;Receive the resuilt of trades even while you are away
                        </div>
                    </div>
                </div>


                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif6" id="notif6" value="option"/>
                                <label htmlFor="notif6" className="text-white f-12-center">
                                Successful withdrawal
                                </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 pl-5">
                        <div className="ui small input col-sm-12 f-9 text-center align-self-center text-gray-tradding pl-5">
                            &nbsp;&nbsp;We Will let you know once the request is appoved
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif7" id="notif7" value="option"/>
                                <label htmlFor="notif7" className="text-white f-12-center">
                                Pending orders
                                </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 pl-5">
                        <div className="ui small input col-sm-12 f-9 text-center align-self-center text-gray-tradding pl-5">
                            &nbsp;&nbsp;Receive notifications when your pending orders get executed or canceled
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 p-2">
                        <div className="ui small input col-sm-12 f-12 text-center align-self-center pl-5">
                            <input className="magic-checkbox" type="checkbox" name="notif8" id="notif8" value="option"/>
                                <label htmlFor="notif8" className="text-white f-12-center">
                                Margin trading notifications
                                </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-0 pl-5">
                        <div className="ui small input col-sm-12 f-9 text-center align-self-center text-gray-tradding pl-5">
                            &nbsp;&nbsp;Margin trading notifications
                        </div>
                    </div>
                </div>

            </div>
            <div className="form-group row mb-0">
                    <div className="col-sm-12 mx-0 mb-2 pl-5">
                        <button type="submit" className="btn btn-primary pull-left"> <i className="logo-btn-save"></i> Save Setting </button>                           
                    </div>
                </div>
            </div>
        </div>
        
            
        );
    }
}

//************************** Context Connector **************************
const ModalSetting = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        userPreference: vars.userPreference,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        handleResize:(size) => actions.sendAction('handleResize',{size}),
        handleOnClick: (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ['handleOnClick', "setDataHandler"]
)(ModalSetting_Base)

const TabAppearance = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        isNight : (thememode)=> {actions.sendAction('isNight', {thememode})},
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ['isNight', 'changeScale','handleOnClick']
)(TabAppearance_Base);

export default ModalSetting;