import React from 'react';
import {AgGridReact} from "ag-grid-react";
import { Dropdown, Input, Popup, Radio, Form, Checkbox } from 'semantic-ui-react';
import {Table} from "react-bootstrap";
import '../bootstrap-3.3.7/bootstrap-datepicker.min.css';
import $ from "jquery";
import Select from "react-select";
import 'ag-grid-enterprise';

import { AppFrame, AppFrameAction} from "../appframe";
import { WSConnectionAction } from '../appnetwork.js';
import { ContextConnector } from '../appcontext.js';
import {BIPSAppContext, ConvertToRupiah, ConvertToDateTime, ConvertToTime, GetStockWathclist, searchCode} from "../AppData";

import {RegisterAmendModal, BuyPage, } from "./stockPage";
import FillHeaderTab from "../tabheaderfill";
import {TableInfoTransactionWithButton} from './../app_transaction/tableInfoTransaction';
import TableInfoTransaction from "../app_transaction/tableInfoTransaction";
import ModalAmend from "../app_modals/modal_amend";
import VerifyPIN, {detailWithdrawModal} from "./verifyPin";
import ModalOrderDetail from "../app_modals/modal_order_detail";
import {VerifyPINPortofolio} from "./landingPage";
import { ResizeResponsive, widthSize, GetTimeForDateTicker, comparatorType, mathCall } from "../utils";
import FormAmend from "../app_transaction/form_amend";
import { CustomTooltip } from "../app_components";


import d from '../dummyData';
import { Calculator } from '../appFunctions';

class RadioButt extends React.PureComponent {
    state = {}
    handleChange = (e, { value }) => this.setState({ value })

    render() {
        return (
            <Form>
                <Form.Field>
                    <Radio
                        label='Choose this'
                        name='radioGroup'
                        value='this'
                        checked={this.state.value === 'this'}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label='Or that'
                        name='radioGroup'
                        value='that'
                        checked={this.state.value === 'that'}
                        onChange={this.handleChange}
                    />
                </Form.Field>
            </Form>
        )
    }
}
//============= Sub header of Trade Page =============
// 1.Order List, 2.Order Book, 3.Watchlist 4.Order Setting, 5.Send Order
class CustomFrameHeaderTrade_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    toAutomatic = () => {
        if(!this.props.agreementAutomaticStatus){
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-white text-center pb-5">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer pull-right"
                        onClick={()=>this.refs.frameAction.closeModal(100)}>
                    </i>
                    <h3 className="text-center">Information</h3>
                </div>,
                size: 'large',
                contentClass: Modal_automatic_order,
                onClose: (result) => {console.log('Modal 1 result = ', result)}
            })    
        }else{
            this.props.handleManual(this.props.isManual)
        }
    }

    render() {
        let props = this.props;
        return(
            <>
                {/* <BIPSAppProvider> */}
                <div>
                    <WSConnectionAction />
                    <AppFrameAction ref="frameAction"/>
                    <div className={props.isManual ? "d-block" : "d-none"}>
                        <div className="col-sm-12 px-0 mx-0 align-self-center row">
                            <div className="col-sm-10 px-0 mx-0 d-border-bottom">
                                <FillHeaderTab tabMode="manual" tabColumn="sm-3" treeName="/tradePage" linkTitles={
                                    {
                                        tradePageManOrderbook : 'ORDERLIST',
                                        tradePagePL : 'TRADE P/L',
                                        tradePageManWatchlist: 'BID/OFFER',
                                        tradePageOrderBookList : 'ORDER BOOKING LIST',
                                        tradePageAdvertisement : 'TRADEADV',
                                    }
                                } />
                            </div>
                            <div className="col-sm-2 pr-4 mx-0 text-right d-border-bottom d-border-left cssmenu">
                                {/* <button disabled={true} className="my-2 mx-0 col-sm-12 btn btn-md btn-dark">{"Manual Order"}</button> */}
                                <Popup content='Switch to Automatic Order' position='bottom center' trigger={
                                    <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark" onClick={()=> this.toAutomatic()}>{"Manual Order"}</button>
                                } />
                            </div>
                        </div>
                    </div>
                    <div className={props.isManual? "d-none" : "d-block"}>
                        <div className="col-sm-12 px-0 mx-0 align-self-center row">
                            <div className="col-sm-10 px-0 mx-0 d-border-bottom">
                                <FillHeaderTab tabMode="auto" tabColumn="sm-4" treeName="/tradePage" linkTitles={
                                    {
                                        AutOrderSetting: 'AUTOMATIC ORDER SETTING',
                                        AutExpOrder: 'EXPIRED AUTOMATIC ORDER',
                                        AutSentOrder : 'SENT ORDER',
                                    }
                                } />
                            </div>
                            <div className="col-sm-2 pr-4 mx-0 text-right d-border-bottom d-border-left cssmenu">
                                <Popup content='Switch to Manual Order' position='bottom center' trigger={
                                        <button className="my-2 mx-0 col-sm-12 btn btn-md btn-dark" onClick={() => {
                                            props.handleManual(props.isManual)
                                        }}>{"Automatic Order"}</button>
                                    } />
                            </div>
                        </div>
                    </div>
                </div>
                <AppFrame treeName="/tradePage" headerComponent={TradeFrameHeader}/>
                {/* </BIPSAppProvider> */}
            </>
        );
    }
}
const TradeFrameHeader = (props) => {
    return (
        <></>
    );
}
class Trade extends React.PureComponent {
    //hanya memanggil headernya saja
    render () {
        return (
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}

//============= 1. Order List Display =============
class TradeWatchlist_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        };
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickAmend = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: AmendPage,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    buttonClickWithdraw = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'mini',
            contentClass: WithdrawModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    buttonClickOrderDetail = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div className="col-sm-12 px-0 mx-0 text-right">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                </div>,
            size: 'large',
            contentClass: OrderDetailModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    render() {
        let p = this.props;
        let oldata = p.orderlistInformation
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <WSConnectionAction ref="wsAction"/> {/* websocket connection component */}
                <AppFrameAction ref="frameAction"/>
                <div id={"orderListPin"} className={`${(p.verifyPinStatus || p.pinPage === "orderListPage") ? "d-none" : "d-block"}`}>
                    <VerifyPINPortofolio pos="orderList" onClose={() => null} />
                </div>
                <div className={`row f-12 ${(p.verifyPinStatus || p.pinPage === "orderListPage") ? "" : "d-none"}`}>
                    <div className={"col-md-2 pl-5 text-left align-self-center"}>
                        <span>{oldata.olSelected}</span> Order(s) Selected
                    </div>
                    <div className={"col-md-3 pl-5 text-left align-self-center"}>
                        Total Match Volume(Lot): <span>{ConvertToRupiah(oldata.olMatchVolLot)}</span>
                    </div>
                    <div className={"col-md-3 pl-5 text-left align-self-center"}>
                        Total Match Amnt: <span>{ConvertToRupiah(oldata.olMatchAmount)}</span>
                    </div>
                    <div className={"col-md-3 pl-5 text-left align-self-center"}>
                        Total Volume(Lot): {ConvertToRupiah(oldata.olVolLot)}
                    </div>
                    <div className={"col-md-1 pb-2 text-right"}>
                        <Popup content='Refresh' position='top center' trigger={
                            <button
                                onClick={()=>this.props.handleOnClick("refreshPage","Ordelist","none")}
                                className={`btn btn-primary`}
                                style={{"font-size": "14px", "width": "38px", "margin-top": "2px"}}>
                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                            </button>
                        }/>
                    </div>
                </div>
                <div className={`${(p.verifyPinStatus || p.pinPage === "orderListPage") ? "" : "d-none"} col-sm-12 px-0 card-310-odlist`}>
                    <OrderListAgGrid
                        size={widthSize()}
                        clickorderdetail={this.buttonClickOrderDetail}
                        clickamend={this.buttonClickAmend}
                        clickwithdraw={this.buttonClickWithdraw}/>
                </div>
                <div className={`${(p.verifyPinStatus || p.pinPage === "orderListPage") ? "" : "d-none"} col-sm-12 row px-0 ml-0 mt-0 pt-1`} style={{'width':'100%'}}>
                    <div className="col-sm-6 pl-0 pr-2">
                        <div className="col-sm-12 pl-0 pr-0">
                            <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                                <div className="col-sm-12 px-0 mx-0 text-center pt-3 pb-2 h-30 f-12 bg-tableheader">TRADE LIST</div>
                            </div>
                            <TradeListOrderListAgGrid size={widthSize()}/>
                        </div>
                    </div>
                    <div className="col-sm-6 pl-2 pr-0">
                        <div className="cssmenu d-border-bottom d-border-top d-border-left small h-30">
                            <ul className="ul-menu h-30">
                                <li name="stockDaily"
                                    className={`col-sm-6 click-pointer d-border-right text-center${(this.state.activeTab == 1) ? ' active' : ''}`} onClick={() => this.setState({activeTab: 1})}>
                                    <a className="linkCustomStockTab h-30">
                                                    <span
                                                        className="f-12">ORDER SUMMARY</span></a></li>
                                <li name="stockPage"
                                    className={`col-sm-6 click-pointer d-border-right text-center${(this.state.activeTab == 2) ? ' active' : ''}`} onClick={() => this.setState({activeTab: 2})}>
                                    <a className="linkCustomStockTab h-30">
                                                    <span
                                                        className="f-12">TRADE SUMMARY</span></a></li>
                            </ul>
                            <div style={{display: this.state.activeTab == 1 ? "block" : "none"}}>
                                <TradeOrderSummaryAgGrid size={widthSize()}/>

                            </div>
                            <div style={{display: this.state.activeTab == 2 ? "block" : "none"}}>
                                <TradeTradeSummaryAgGrid size={widthSize()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
// tabel order list bagian atas
class OrderListAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "-", headerName: "#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 35, 
                    minWidth: 35,
                    // setSelected :(params)=>params.data.order.includes(self.props.orderlistSelected) ?  params.node.setSelected(true) : params.node.setSelected(false),                    
                    checkboxSelection:(params)=>params.data.code === "\xa0" ? false : true,
                    headerCheckboxSelectionFilteredOnly: true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center text-primary f-12 click-pointer";
                    },
                    cellRendererFramework: function(params){
                        if(params.data.code === "\xa0"){
                            return ''
                        }else{
                            // params.data.order.includes(self.props.orderlistSelected) ?  params.node.setSelected(true) : params.node.setSelected(false)
                            return <>&nbsp</>;
                        }
                    },                    
                    suppressSizeToFit: true
                },                
                { field: "time", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true, sort:'desc',
                    width: s=="s49"?150:100, minWidth:100,
                    // valueFormatter: currencyDateTime, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left  f-12";
                    },
                },
                { field: "order", headerName: "Order#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:85, minWidth: 85,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center text-primary f-12 click-pointer";
                    },
                    cellRenderer : function (params) {                                               
                        var order = params.data.order;
                        var eDiv = document.createElement('div');
                        if(params.data.code === "\xa0"){
                            eDiv.innerHTML = '';
                            return eDiv
                        }else{ 
                            eDiv.innerHTML = '<span class="btn-cellorder px-1">' +order+ '</span>';
                            var aButton = eDiv.querySelectorAll('.btn-cellorder')[0];

                            function closeClick(){                            
                                self.refs.frameAction.closeModal(1000)
                            }
                            // memanggil fungsi amend
                            function clickOrderDetail() {
                                self.refs.frameAction.showModal({
                                    headerClass: () =>
                                        <div className="col-sm-12 px-0 mx-0 text-right">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={closeClick}></i>
                                        </div>,
                                    size: 'large',
                                    contentClass: OrderDetailModal,
                                    onOpen :self.props.handleOnClick('trade', 'openModalOrderDetail',params.data.order),
                                    onClose: (result) => {console.log('Modal 1 result = ', result)}
                                })
                            }

                            aButton.addEventListener('dblclick',clickOrderDetail);
                            return eDiv
                        }
                    },
                    suppressSizeToFit: true
                },
                { field: "marketorder", headerName: "Market Order#", sortable: true,
                    comparator: integerComparator,
                    resizable: true, width: 130, minWidth: 130,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },
                { field: "code", headerName: "Code", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width: 80, minWidth: 80,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left text-primary f-12";
                    },
                    cellRenderer:function(params){
                        if(params.data.code === "\xa0"){
                            return ''
                        }else{
                            return params.data.code
                        }
                    }
                },
                { field: "cmd", headerName: "Cmd", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width: 70, minWidth: 70,
                    cellClass : function (params) {
                        var cmd = params.data.cmd.toUpperCase();
                        return cmd.includes('BUY') === true ? " text-center text-danger grid-table d-border-aggrid-right f-12" :
                            " text-center text-success grid-table d-border-aggrid-right f-12";
                    },
                },
                // { field: "orderType", headerName: "Order Type", sortable: true, resizable: true,  comparator: stringComparator,
                //     resizable: true, width: 130, minWidth: 130,
                //     cellClass : function (params) {
                //         return " grid-table d-border-aggrid-right text-left f-12";
                //     },
                // },
                { field: "status", headerName: "Status", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width:85, minWidth: 85,
                    tooltipField: 'status',
                    tooltipComponentParams: { type: 'status' },
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right f-12";
                    },
                },
                { field: "remark", headerName: "Remark", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width: 95, minWidth:95,
                    tooltipField: 'remark',
                    tooltipComponentParams: { type: 'remark' },
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer : function (params) {
                        let status = params.data.status.toUpperCase();
                        let remark = params.data.remark.toUpperCase();
                        let sreject = params.node.data.sReject.toUpperCase();
                        if(params.data.code === "\xa0"){
                            return ''
                        } else{
                            return status.includes('SENDING') === true || status.includes('ACCEPTED') === true  ? 
                                '<i class="fas fa-hourglass-half text-tosca"></i>&nbsp;&nbsp;'+ params.data.remark :
                                    sreject.includes("REJECT")|| status.includes("REJECT")|| status.includes("ERROR") || status.includes("FAILED") || 
                                    remark.includes('CANCEL') || status.includes('CANCEL') || remark.includes('NOT')  ? 
                                    '<i class="fa fa-times text-danger"></i> &nbsp;&nbsp;'+ params.data.remark :
                                    '<i class="fa fa-check text-success"></i> &nbsp;&nbsp;'+ params.data.remark;
                        }
                    }
                },
                { field: "type", headerName: "Exp", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width: 70, minWidth: 70,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right f-12 text-center";
                    },
                },
                { field: "mkt", headerName: "Mkt", sortable: true, resizable: true,
                    comparator: stringComparator,
                    width: 70, minWidth:70,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right f-12 text-center";
                    },
                },
                { field: "vlot", headerName: "Vol. Lot", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 100, minWidth: 100,
                    valueFormatter: currencyFormatter, 
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "vshares", headerName: "Vol. Shares", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width:120, minWidth:120,  minWidth:120,
                    valueFormatter: currencyFormatter, 
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 86, minWidth: 86,
                    valueFormatter: currencyFormatter,  
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "mlot", headerName: "Match Vol. Lot", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width:130, minWidth:130,
                    valueFormatter: currencyFormatter,  
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "mshares", headerName: "Match Vol. Shares", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: 140, minWidth:140,
                    valueFormatter: currencyFormatter,  
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "avgmatchprice", headerName: "Avg. Match Price", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?140:135, minWidth:135,
                    valueFormatter: currencyFormatter,  
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?120:100, minWidth:100,
                    valueFormatter: currencyFormatter,  
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "userId", headerName: "UserID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:100, minWidth:100, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "method", headerName: "Method", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:100, minWidth:100, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "terminal", headerName: "Terminal", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:100, minWidth:100, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "action", headerName: "Action", sortable: true, filter: "agTextColumnFilter",
                    width:130, minWidth:130,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.code === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            // memanggil fungsi amend
                            var status = params.node.data.status.toUpperCase()
                            let nmkt = params.node.data.mkt.toUpperCase()
                            if(status === "DONE" || status.includes("CANCEL") || status.includes("REJECT") || status.includes("SENDING") || nmkt == "NG"){
                                eDiv.innerHTML = '<span class="px-1">' + '<button class="btn btn-sm btn-primary mx-1 f-9 w-50 disabled">Amend</button>' +
                                '<button class="btn btn-sm btn-brown mx-1 f-9 disabled">Withdraw</button>'+'</span>'
                            }else{
                                eDiv.innerHTML = '<span class="px-1">' + '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9 w-50">Amend</button>' +
                                '<button class="btn-cellwithdraw btn btn-sm btn-brown mx-1 f-9">Withdraw</button>'+'</span>'

                                var aButton = eDiv.querySelectorAll('.btn-cellamend')[0];
                                var wButton = eDiv.querySelectorAll('.btn-cellwithdraw')[0];

                                function closeClick(){                            
                                    self.refs.frameAction.closeModal(1000)
                                }

                                // memanggil fungsi amend
                                function amendFunction () {
                                    self.refs.frameAction.showModal({
                                        headerClass: () => <div className="text-right text-white">
                                            <i className="icofont icofont-close text-white text-icofont-close click-pointer"
                                            onClick={closeClick}></i></div>,
                                        size: 'large',
                                        contentClass: AmendModal,
                                        onOpen :self.props.handleOnClick('trade', 'openModalAmend',params.data.order),
                                        onClose:()=>{self.props.handleOnClick('trade', 'closeModalAmend',params.data.code)},
                                    })
                                }

                                // fungsi bagian Withdraw
                                function withdrawFunction() {
                                    self.refs.frameAction.showModal({
                                        // headerClass: () => <div className="text-right text-white">
                                        //     <i className="icofont icofont-close text-white text-icofont-close click-pointer"
                                        //     onClick={closeClick}></i></div>,
                                        size: 'mini',
                                        contentClass: detailWithdrawModal,
                                        // contentClass: WithdrawModal,
                                        onOpen :self.props.handleOnClick('trade', 'openModalWithdraw',params.data.order),
                                        onClose: (result) => {console.log('Modal 1 result = ', result)}
                                    })
                                }

                                aButton.addEventListener('click', amendFunction);
                                wButton.addEventListener('click', withdrawFunction);
                            }
                        }

                        return eDiv;
                    }, suppressSizeToFit: true
                },
            ],

            defaultColDef: {
                sortable: true,
                filter: true,
                headerCheckboxSelection: isFirstColumn,
                tooltipComponent: 'customTooltip',
                // checkboxSelection: isFirstColumn,
            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            rowSelection: "multiple",
            getRowHeight : function(params){
                return 28;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
            getRowNodeId: function(data) {
                return data.order;
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.forEachNode((node) => {
            let dref = this.props.orderlistSelected
            let nd = node.data.order.includes(dref)
            if (nd) {
                node.setSelected(true);
            }
        });

        let immutableStore = [];
        immutableStore = this.props.orderList;
        params.api.updateRowData(immutableStore);
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    // function for onclick Selection
    onRowSelected(event) {
        // versi 1
        // let nchecked = event.node.data.checked
        // let data = event.node.data.order
        // if(nchecked == 1){
        //     this.props.handleOnClick('trade', 'selected', 0+'-'+data)
        // }else{
        //     this.props.handleOnClick('trade', 'selected', 1+"-"+data)
        // }
    }
    onSelectionChanged(event) {
        let selectedRowData = this.gridApi.getSelectedRows();
        // versi 2
        this.props.handleOnClick('trade', 'selected', selectedRowData.filter(d => d.code != "\xa0"))
    }
    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-310-odlist ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        // rowData={d.orderlist}
                        rowData={this.props.orderList}
                        // deltaRowDataMode={true}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        rowSelection={this.state.rowSelection}
                        onRowSelected={this.onRowSelected.bind(this)}
                        suppressRowClickSelection={true}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        getRowNodeId={this.state.getRowNodeId}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        groupSelectsFiltered={true}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// tabel order list bagian kiri bawah
class TradeListOrderListAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "time", headerName: "Time", filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?172:s=="s50"?170:s=="s67"?140:s=="s75"?120:s=="s80"?105:s=="s85"?105:s=="s90"?90:80, minWidth: 80,
                    sort: "desc",
                    comparator: stringComparator,
                    // valueFormatter: timeFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer:(params)=>params.data.time.substr(0,8)
                },{ field: "code", headerName: "Code", resizable: true,
                    width: s=="s49"?205:s=="s50"?200:s=="s67"?140:s=="s75"?120:s=="s80"?105:s=="s85"?90:s=="s90"?90:78, minWidth:78, comparator: stringComparator,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code
                },{ field: "price", headerName: "Price", filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?245:s=="s50"?240:s=="s67"?100:s=="s75"?95:s=="s80"?105:s=="s85"?90:s=="s90"?90:86, minWidth: 86,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter, 
                    cellClass : function (params) {
                        return " grid-table text-right d-border-aggrid-right f-12";
                    },
                },{ field: "vol", headerName: "Vol (Lot)", filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s67"?190:s=="s75"?90:s=="s80"?105:s=="s85"?70:s=="s90"?70:60, minWidth: 60,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter, 
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "val", headerName: "Val", filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?125:s=="s67"?120:s=="s75"?100:s=="s80"?80:s=="s85"?70:s=="s90"?70:60, minWidth: 60,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter, 
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "buyer", headerName: "Buyer", filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?105:s=="s67"?100:s=="s75"?100:s=="s80"?95:s=="s85"?90:s=="s90"?90:86, minWidth: 86,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "seller", headerName: "Seller", filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?102:s=="s67"?100:s=="s75"?100:s=="s80"?90:s=="s85"?85:86, minWidth: 86,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "tradeId", headerName: "TradeID", filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?173:s=="s50"?175:s=="s67"?170:s=="s75"?167:s=="s80"?100:s=="s85"?90:90, minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
            ],
            defaultColDef: {
                // sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-177 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeListMain}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// tabell orderlist bagian kanan bawah part 1
class TradeOrderSummaryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code",resizable: true,
                    width:85, minWidth: 85,
                    // sort:"desc",    
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },
                { field: "vol", headerName: "Vol (Lot)",filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?152:s=="s50"?150:s=="s67"?90:90, minWidth: 90,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "leaveVol", headerName: "Leave Vol (Lot)",filter: "agNumberColumnFilter", resizable: true,
                    width:s=="s49"?172:s=="s50"?170:130, minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "tradeVol", headerName: "Match Vol (Lot)",filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?252:s=="s50"?250:s=="s67"?150:130, minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },                
                { field: "avgPrice", headerName: "Avg. Match Price",filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?302:s=="s50"?300:s=="s67"?250:s=="s75"?245:s=="s80"?205:s=="s85"?135:s=="s90"?185:130, minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "cmd", headerName: "Cmd",resizable: true,
                    width: s=="s49"?220:s=="s67"?230:s=="s75"?225:s=="s80"?210:s=="s85"?130:s=="s90"?130:80, minWidth: 80,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        var cmd = params.data.cmd.toUpperCase();
                        return cmd.includes('BUY') === true ? " text-center text-danger grid-table d-border-aggrid-right f-12" :
                            " text-center text-success grid-table d-border-aggrid-right f-12";
                    },},
            ],
            defaultColDef: {
                // sortable: true,
                filter: true,
                rowSelection: "multiple",
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-176 ag-theme-balham-dark ag-bordered ag-striped-odd d-border d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeOrderSum}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// tabel orderlist bagian kanan bawah part 1
class TradeTradeSummaryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code",resizable: true,
                    width: s=="s49"?340:s=="s50"?310:s=="s67"?250:s=="s85"?155:155, minWidth: 155,
                    // sort:"asc",
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "cmd", headerName: "Cmd",resizable: true,
                    width: s=="s49"?340:s=="s50"?310:s=="s67"?125:s=="s85"?150:150, minWidth: 150,
                    comparator: stringComparator,
                    cellClass : function (params) {
                    var cmd = params.data.cmd.toUpperCase();
                    return cmd.includes('BUY') === true ? " text-center text-danger grid-table d-border-aggrid-right f-12" :
                        " text-center text-success grid-table d-border-aggrid-right f-12";
                },
                },{ field: "price", headerName: "Price",filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?440:s=="s50"?410:s=="s67"?355:s=="s75"?335:s=="s80"?277:s=="s85"?200:s=="s90"?227:160, minWidth: 160,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter, 
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vol", headerName: "Vol (Lot)",filter: "agNumberColumnFilter",
                    width: s=="s49"?400:s=="s50"?350:s=="s67"?340:s=="s75"?335:s=="s80"?290:s=="s85"?190:s=="s90"?250:160,
                    minWidth: 160,  resizable: true,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter, 
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: false,
                filter: true,
                rowSelection: "multiple",
            },            
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-176 ag-theme-balham-dark ag-striped-odd ag-bordered d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeTradeSum}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        rowSelection={this.state.rowSelection}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 2. Order Book Display =============
class OrderbookPage_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickAmendRegister = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                   onClick={this.closeClick}></i></div>,
            size: 'tiny',
            contentClass: RegisterAmendModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        var p = this.props
        return (
            <div className="col-sm-12 px-2 mx-0 pt-1">
                <WSConnectionAction ref="wsAction" /> {/* websocket connection component */}
                <div className="bg-black-trading f-12 mt-1">
                    <AppFrameAction ref="frameAction" />
                    <div className="card-515 col-sm-12 pt-3 pr-2 mr-0 mt-0 row">
                        <div className="col-sm-4 pl-4 pr-0">
                            <div className={p.box1 === 1 ? "bg-grey pt-1 d-active" : "bg-grey pt-1"} 
                                onClick={()=>p.handleOnClick('trade', 'tradeBox', '1-0-0')}>
                                <TableInfoTransactionWithButton idOrderBook={1}/>
                            </div>
                        </div>
                        <div className="col-sm-4 pl-4 pr-0">
                            <div className={p.box2 === 1 ? "bg-grey pt-1 d-active" : "bg-grey pt-1"} 
                                onClick={()=>p.handleOnClick('trade', 'tradeBox', '0-1-0')}>
                                <TableInfoTransactionWithButton idOrderBook={2}/>
                            </div>
                        </div>
                        <div className="col-sm-4 pl-4 pr-0">
                            <div className={p.box3 === 1 ? "bg-grey pt-1 d-active" : "bg-grey pt-1"} 
                                onClick={()=>p.handleOnClick('trade', 'tradeBox', '0-0-1')}>
                                <TableInfoTransactionWithButton idOrderBook={3}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//============= 3. TRADE P/L Display  =============
class TradePL extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        };
    }
    render() {
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <WSConnectionAction ref="wsAction"/> {/* websocket connection component */}
                <AppFrameAction ref="frameAction"/>
                <div className="row f-12">
                    <div className="col-md-12">
                        <div className="col-md-12 p-3">
                            <div className="row p-3">
                                <div className="col-md-3 mt-3 mb-5">
                                    Sell Amount
                                </div>
                                <div className="col-md-3">
                                    <Input readonly defaultValue='0,00.' placeholder='SellAmount' 
                                    className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                </div>
                                <div className="col-md-3 mt-3">
                                    Sales PL
                                </div>
                                <div className="col-md-3">
                                    <Input readonly defaultValue='0,00.' placeholder='SellAmount' 
                                    className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                </div>
                                <div className="col-md-3 mt-3">
                                    Buy Amount
                                </div>
                                <div className="col-md-3">
                                    <Input readonly defaultValue='0,00.' placeholder='SellAmount' 
                                    className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                </div>
                                <div className="col-md-3 mt-3">
                                    Sales PL(%)
                                </div>
                                <div className="col-md-3">
                                    <Input readonly defaultValue='0,00.' placeholder='SellAmount' 
                                    className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>                                
                <div className="col-sm-12 px-0">
                    <TradePLAgGrid
                        size={widthSize()}/>
                </div>
            </div>
        )
    };
}
class TradePLAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code", sortable: true, resizable: true,comparator: stringComparator,
                    width: s=="s49"?210:s=="s50"?195:s=="s67"?180:s=="s75"?160:s=="s80"?135:s=="s90"?110:s=="s100"?105:100,
                    minWidth:140,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "buyVol", headerName: "Buy Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?290:s=="s50"?255:s=="s67"?230:s=="s75"?210:s=="s80"?180:s=="s90"?165:s=="s100"?160:150, minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "buyAmount", headerName: "Buy Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?230:s=="s75"?210:s=="s80"?200:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "sellVol", headerName: "Sell Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?210:s=="s50"?195:s=="s67"?170:s=="s75"?160:s=="s80"?140:s=="s90"?115:s=="s100"?110:100,
                    minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "sellAmount", headerName: "Sell Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?310:s=="s50"?280:s=="s67"?240:s=="s75"?220:s=="s80"?200:s=="s90"?170:s=="s100"?160:150, minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "feeTax", headerName: "Fee & Tax", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?310:s=="s50"?270:s=="s67"?230:s=="s75"?230:s=="s80"?200:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "avgPrice", headerName: "Avg. Price", sortable: true, filter: "agTextColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?255:s=="s75"?240:s=="s80"?200:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "PL", headerName: "P/L(+/-)", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?290:s=="s50"?260:s=="s67"?220:s=="s75"?220:s=="s80"?185:s=="s90"?160:s=="s100"?150:140,
                    minWidth: 140,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "percentage", headerName: "(%)", sortable: true, filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?160:s=="s50"?150:s=="s67"?140:s=="s75"?135:s=="s80"?120:s=="s90"?110:s=="s100"?100:95,
                    minWidth: 140,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-tradePL ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradePL}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 4. ADVERTISEMENT LIST Display =============
class TradePageAdv_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        };

    }
    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });
    render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        let selectedData = searchCode('selected', this.props.codeSearchTradeAdv, this.props.stocklistOptions)
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <WSConnectionAction ref="wsAction"/> {/* websocket connection component */}
                <AppFrameAction ref="frameAction"/>
                <div className="row f-12">
                    <div className={"col-md-4 text-left align-self-center"}>
                            <Select
                                getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                filterOption={customFilter} isSearchable={true}
                                maxMenuHeight={155}
                                styles={{customStyles}}
                                placeholder={<div>Search..</div>}
                                options={this.props.stocklistOptions} className="stockPageSelect text-left"
                                theme={this.selectSelectionTab}
                                onChange={(e)=>this.props.handleSearchCode('tradePageAdv', e.value)}
                                value={selectedData}
                            />
                    </div>
                    <div className={"col-md-8 text-right"}>
                        <Popup content='Refresh' position='top center' trigger={
                            <button
                                className={`btn btn-primary`}
                                tradePageAdv
                                style={{"font-size": "14px", "width": "38px", "margin-top": "2px"}}
                                onClick={()=>this.props.handleOnClick('refreshPage','tradePageAdv','none')}>
                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                            </button>
                        }/>
                    </div>
                </div>
                <div className={"row pl-4 pr-4 mt-2"}>
                    <div className="col-sm-6 pl-0 pr-1 mb-3">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center row bg-tableheader">
                            <div className="col-sm-12 px-0 mx-0 text-center pt-3 pb-2 h-30 f-12 bg-tableheader">
                                BID
                            </div>
                        </div>
                        <TradeAdvBidAgGrid size={widthSize()}/>
                    </div>

                    <div className="col-sm-6 pl-1 pr-0 mb-3">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center row bg-tableheader">
                            <div className="col-sm-12 px-0 mx-0 text-center pt-3 pb-2 h-30 f-12 bg-tableheader">
                                OFFER
                            </div>
                        </div>
                        <TradeAdvOfferAgGrid size={widthSize()}/>
                    </div>
                </div>


            </div>
        )
    };
}
class TradeAdvBidAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "broker", headerName: "Broker", sortable: true, resizable: true,
                    width: s=="s49"?295:s=="s50"?263:s=="s67"?235:s=="s75"?215:s=="s80"?190:s=="s85"?170:s=="s90"?160:s=="s100"?155:145,
                    minWidth:145,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer:(params)=>params.data.broker == "\xa0" ? "" : params.data.broker
                },{ field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?225:s=="s80"?190:s=="s85"?165:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vol", headerName: "Vol(SHR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?220:s=="s80"?195:s=="s85"?180:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },},
                { field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?220:s=="s80"?195:s=="s85"?180:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },},
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-tradeAdv ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeAdvBid}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
class TradeAdvOfferAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "broker", headerName: "Broker", sortable: true, resizable: true,
                    width: s=="s49"?295:s=="s50"?263:s=="s67"?235:s=="s75"?215:s=="s80"?190:s=="s85"?175:s=="s90"?160:s=="s100"?155:145,
                    minWidth:145,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer:(params)=>params.data.broker == '-' ? "" : params.data.broker
                },{ field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?225:s=="s80"?190:s=="s85"?175:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vol", headerName: "Vol(SHR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?220:s=="s80"?195:s=="s85"?172:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?295:s=="s50"?265:s=="s67"?235:s=="s75"?220:s=="s80"?195:s=="s85"?170:s=="s90"?165:s=="s100"?155:145,
                    minWidth: 145,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-tradeAdv ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeAdvOffer}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= DELETE Watchlist Display =============
class TradeWatchlistAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code",  resizable: true, filter: "agNumberColumnFilter", 
                    width: s=="s49"?110:80, minWidth: 80, lockPosition:true, lockVisible:true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { field: "price", headerName: "Price",  resizable: true, filter: "agNumberColumnFilter", 
                    width: s=="s49"?110:80, minWidth: 80,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" : 
                            "text-warning text-right grid-table d-border-aggrid-right f-12");
                    }},
                { field: "change", headerName: "Change",  resizable: true, filter: "agNumberColumnFilter", 
                    width: 90, minWidth: 90,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" : 
                            "text-warning text-right grid-table d-border-aggrid-right f-12");
                    } },
                { field: "persen", headerName: "(%)",  resizable: true, filter: "agNumberColumnFilter", 
                    width: 70, minWidth: 70,
                    comparator: integerComparator, 
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" : 
                            "text-warning text-right grid-table d-border-aggrid-right f-12");
                    } },
                { field: "tvol", headerName: "T. Vol",  resizable: true, filter: "agNumberColumnFilter", 
                    width: 100, minWidth: 100,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? "text-danger text-right  grid-table d-border-aggrid-right f-12":
                            (change > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12" : 
                            "text-warning text-right grid-table d-border-aggrid-right f-12");
                    } },
                { field: "accumulated", headerName: "Accumulated Foreign Vol.", filter: "agNumberColumnFilter", 
                    resizable: true, width: 200, minWidth: 200,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    } },
                { field: "avgprice", headerName: "Avg. Price",  resizable: true, filter: "agNumberColumnFilter", 
                    width: 100, minWidth: 100,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    } },
            ],
            defaultColDef: {
                sortable: false,
                filter: true,
            },
            getRowHeight : function(params){
                return 27.5;
            },           
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        if(this.props.watchlistCode.length){
            var data = GetStockWathclist('tradePage',this.props.dataWatchlist)
        }else{
            var data = d.stockWatchlist
        }

        return (
            <>
                <div
                    className="card-514 ag-theme-balham-dark ag-header-border d-border ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={data}
                        // rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
class TableInfoTransactionLayout extends React.PureComponent{
    constructor(props) {
        super(props);

    }
    render(){
        return(
            <>
                <TableInfoTransaction lotshare="autTradeOrd" card="card-310-aut" card2="card-140" />
            </>
        );
    }
}

//============= 4. ORDER BOOKING LIST Display =============
class TradeOrderBookList_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        };

    }
    render() {
        let p = this.props;
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <WSConnectionAction ref="wsAction"/> {/* websocket connection component */}
                <AppFrameAction ref="frameAction"/>

                <div id={"orderListPinHist"} className={`${(p.verifyPinStatus || p.pinPage === "ordeBookListPage") ? "d-none" : "d-block"}`}>
                    <VerifyPINPortofolio pos="orderList" onClose={() => null} />
                </div>
                <div className={`${(p.verifyPinStatus || p.pinPage === "ordeBookListPage") ? "d-block" : "d-none"} col-sm-12 px-0 card-310`}>
                    <TradeOrderBookListAgGrid
                        size={widthSize()}/>
                </div>
            </div>
        )
    };
}

class TradeOrderBookListAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: false, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?140:s=="s50"?130:s=="s67"?120:s=="s75"?110:s=="s80"?100:s=="s85"?80:s=="s90"?60:s=="s100"?60:60,
                    minWidth:60,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.rowIndex + 1
                },{ field: "bookingNo", headerName: "Booking No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?210:s=="s50"?200:s=="s67"?175:s=="s75"?170:s=="s80"?150:s=="s85"?130:s=="s90"?140:s=="s100"?130:120,
                    minWidth:120,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "status", headerName: "Status", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?210:s=="s50"?200:s=="s67"?170:s=="s75"?160:s=="s80"?140:s=="s85"?130:s=="s90"?120:s=="s100"?120:120,
                    minWidth:120,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "remark", headerName: "Remark", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?210:s=="s75"?190:s=="s80"?170:s=="s90"?160:s=="s100"?150:140,
                    minWidth: 140,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "cmd", headerName: "Cmd", sortable: true, resizable: true,
                    width: s=="s49"?210:s=="s50"?190:s=="s67"?170:s=="s75"?160:s=="s80"?140:s=="s85"?130:s=="s90"?110:s=="s100"?90:80,
                    minWidth:80,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "mkt", headerName: "Mkt", sortable: true, resizable: true,
                    width: s=="s49"?210:s=="s50"?180:s=="s67"?170:s=="s75"?160:s=="s80"?140:s=="s85"?120:s=="s90"?100:s=="s100"?90:80,
                    minWidth:80,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },
                { field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?140:s=="s85"?113:s=="s90"?100:s=="s100"?90:80,
                    minWidth:80,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code
                },
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?190:s=="s75"?180:s=="s80"?150:s=="s85"?140:s=="s90"?140:s=="s100"?140:140,
                    minWidth: 140,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vol", headerName: "Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?180:s=="s75"?170:s=="s80"?140:s=="s85"?130:s=="s90"?130:s=="s100"?130:120,
                    minWidth: 120,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vollot", headerName: "Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?180:s=="s75"?160:s=="s80"?150:s=="s85"?130:s=="s90"?130:s=="s100"?120:110,
                    minWidth: 110,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "bookingDate", headerName: "Booking Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?210:s=="s75"?190:s=="s80"?170:s=="s85"?160:s=="s90"?160:s=="s100"?150:140,
                    minWidth: 140,
                    sort:'desc',
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                }, { field: "action", headerName: "Action", sortable: true, filter: "agTextColumnFilter", width:140,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.code === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            let status = params.node.data.status.toUpperCase();
                            if(status === "PENDING"){
                                eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9">Cancel</button>'+
                                '</span>';
                                var aButton = eDiv.querySelectorAll('.btn-cellamend')[0];
                                
                                function closeClick(){
                                    self.refs.frameAction.closeModal(100);
                                }
    
                                function cancelModal(){
                                    self.refs.frameAction.showModal({
                                        headerClass: () => <div className="text-right">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                                                onClick={closeClick}></i></div>,
                                        size: 'mini',
                                        contentClass: DetailTradeOrderBook,
                                        onOpen :self.props.handleOnClick('trade', 'cancelOrderbook|openModal',params.data.bookingNo),
                                    })
                                }
    
                                aButton.addEventListener('click', cancelModal);
                            }else{
                                eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9 disabled">Cancel</button>'+
                                '</span>';
                            }                  
                        }                   
                        return eDiv;
                    }, suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }
    buttonClickAmend = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                   onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: AmendPage,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }
   
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-520 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeOrderBookList}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 5. Automatic Order Setting Display =============
let setCondAutomatic = {
    orderType: "1",
    orderVol: 0,
    orderPrice: "MANUAL",
    manualPrice: 0,
    typePrice: "LP",
    condPrice: "L",
    setPrice: 0,
    date: "",
    statusOrder: true,
};
class OrderSetting_Base extends React.PureComponent {
    constructor(props) {
        super(props);

    }
    render(){
        var p = this.props;
        return(
            <>
                <AppFrameAction ref="frameAction"/>
                <div className="col-sm-12">

                <div id={"AutOrderPIN"} className={`${(p.verifyPinStatus || p.pinPage === "orderSetting") ? "d-none" : "d-block"} col-sm-12`}>
                        <VerifyPINPortofolio pos="AutOrder" onClose={() => null} />
                    </div>
                    <div id="ContentAutOrder" className={`${(p.verifyPinStatus || p.pinPage === "orderSetting") ? "d-block" : "d-none"}`}>
                        <div className="pt-1 row" >
                            <div className="col-sm-4 pr-3 pl-3 f-12">
                                <TableInfoTransactionLayout/>
                            </div>
                            <div className="col-sm-8 pr-3 pl-0 mt-0">
                                <TableInfoTransactionLayout2/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
class TableInfoTransactionLayout2_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = setCondAutomatic;
    }
    handleChange = (type, val) => {
        if(type === "typePrice"){ this.setState({ typePrice: val }); }
        if(type === "condPrice"){ this.setState({ condPrice: val }); }
        if(type === "statusOrder"){ this.setState({ statusOrder: val }); }
        if(type === "orderType"){ this.setState({ orderType: val }); }
        if(type === "orderPrice"){ this.setState({ orderPrice: val, manualPrice: 0}); }
        if(type === "manPrice"){ this.setState({ manualPrice: val.replace(/[^0-9]/g, '') }); }
        if(type === "plusManPrice"){ this.setState({ manualPrice: Calculator("addR", val)}); }
        if(type === "minusManPrice" && val > 0){ this.setState({ manualPrice: Calculator("subR", val)}); }
        if(type === "setVol"){ this.setState({ orderVol: Number(val.replace(/[^0-9]/g, '')) }); }
        if(type === "plusVol"){ this.setState({ orderVol: val + 1}); }
        if(type === "minusVol" && val > 0){ this.setState({ orderVol: val - 1}); }

    }
    handleChangeDate = () => this.setState({ date: document.getElementById('datepickerAut').value});
    // TermsCondition / save role 
    saveDisclaimer = () => {
        let data = this.state;
        if(this.props.setPriceAutomatic == 0){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'setPrice can not be zero.',)
        }else if(data.orderVol == 0){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'Vol can not be zero.',)
        }else if(data.orderVol > 50000){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'Order Volume Can Not more than 50,000 lot',)
        }else if(data.date == ""){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'Please insert expire date.',)
        }else if(this.state.orderPrice == "MANUAL" && this.state.manualPrice == 0){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'Order price can not be zero.',)
        }else if(Number(this.props.roleWorking) >= 20){
            this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'You have reached maximum active rules (20)',)
        }else if(this.props.userProfile.status != "ACTIVE"){
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-white text-center">
                    <h3 className="text-center pt-1">Create Rule is not permitted</h3></div>,
                    size: 'mini',
                contentClass: ModalRestricted,
                // onClose: (result) => {console.log('Modal 1 result = ', result)}
            });    
            // this.props.handleStatusAlert3('noConfirm', this.props.statusAlertN, 'Can not create rules due to account status. Please contact our help desk')
        }else{
            this.props.handleOnClick("trade", "actionAutoSaveTemp", this.state)
            this.setState(this.state = setCondAutomatic);
            $("#datepickerAut").datepicker("setDate", "");
            //save disclaimer
            // this.refs.frameAction.showModal({
            //     headerClass: () => <div className="text-white text-center"><h2>Disclaimer</h2></div>,
            //     closeIcon: false,
            //     contentClass: saveDisclaimerCard,
            //     size: "tiny",
            //     onClose: (result) => {console.log('Modal 1 result = ', result)}
            // })
            if(this.props.termsConditionStatus){
                this.refs.frameAction.showModal({
                    headerClass: () => <div className="text-white text-center pb-5">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer pull-right"
                            onClick={this.closeClick}>
                        </i>
                        <h3 className="text-center">Terms and Conditions</h3>
                    </div>,
                    size: 'large',
                    contentClass: Modal_terms_condition,
                    onClose: (result) => {console.log('Modal 1 result = ', result)}
                });
            }else{
                this.props.handleOnClick("trade", "actionAutoSaveOK");
            }
        }
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickEdit = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center pb-5">
                <i className="icofont icofont-close text-icofont-close text-white click-pointer pull-right"
                    onClick={this.closeClick}>
                </i>
                <h2 className="text-center">Edit Automatic Order</h2>
            </div>,
            size: 'large',
            contentClass: EditPage,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    componentDidMount() {
        $(document).ready(function() {
            var isRtl = $('html').attr('dir') === 'rtl';

            // Zaky Update
            $('#datepickerAut').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: GetTimeForDateTicker(0, -1),
                endDate: GetTimeForDateTicker(0, -30),
                autoclose: true,
                todayHighlight: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateAut").click(); 
            });
        });
    }
    
    render(){
        let data = this.state;
        let p = this.props;
        return(
            <>
                <AppFrameAction ref="frameAction"/>
                <input type="hidden" onClick={()=>this.handleChangeDate()} id={"handleDateAut"} />

                <div className="bg-dark-grey d-border card-520">
                    <div className="row">
                        <div className="col-sm-6 f-12 pt-3">
                            <div className="col-sm-12 row mb-2 pr-0">
                                <div className="col-sm-12">
                                    <label>Set Condition</label>
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <Dropdown placeholder='Last Price'
                                        search selection
                                        defaultValue={p.rulePrice}
                                        options={
                                            [
                                                {key:'1',value:'LP',text: 'Last Price'},
                                                {key:'2',value:'BO',text: 'Best Offer Price'},
                                                {key:'3',value:'BB',text: 'Best Bid Price'},
                                            ]
                                        }
                                        onChange={(e, data) => p.handleOnClick("trade","actionAutSetRulePrice", data.value)}
                                        value={p.rulePrice}
                                        className={"f-12 text-center align-self-center col-sm-12"}
                                    />
                                </div>
                                <div className="col-sm-2 pr-0 pl-0">
                                    <Dropdown placeholder='Type'
                                        defaultValue={data.condPrice}
                                        search selection options={
                                            [
                                                {key:'1',value:'L',text: '<='},
                                                {key:'2',value:'G',text: '>='},
                                            ]
                                        }
                                        onChange={(e, data)=>this.handleChange("condPrice",data.value)}
                                        value={data.condPrice}
                                        className={"f-12 text-center align-self-center col-sm-12"}
                                    />
                                </div>
                                <div className="col-sm-4 pr-0">
                                    {/* <NumberInput idclassname="tradeAtSetCondition" defaultValue={"0"}/> */}
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <button type="button" onClick={() => p.handleOnClick("trade","actionAutMinusSetPrice", Number(p.setPriceAutomatic))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                <i className="icofont icofont-minus f-9"></i>
                                            </button>
                                        </span>
                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                            className="form-control f-12" defaultValue='0' value={ConvertToRupiah(p.setPriceAutomatic)}  placeholder="0"
                                            onChange={(e) => p.handleOnClick("trade","actionAutSetPrice",e.target.value)} 
                                            onFocus={(e) => e.target.select()} />
                                        <span className="input-group-btn">
                                            <button type="button" onClick={() => p.handleOnClick("trade","actionAutPlusSetPrice", Number(p.setPriceAutomatic))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                <span className="icofont icofont-plus f-9"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                <label htmlFor="">Expired Date (max day 30)</label>
                                </div>
                                <div className="col-sm-12 ui input mb-3" style={{paddingRight:'53px'}}>
                                <Input placeholder='dd/mm/yy' value={data.date} id="datepickerAut" className="col-sm-12 pl-0 pr-0 text-center align-self-center"/>
                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}><span
                                className="fa fa-calendar-alt"></span></span>
                                </div>
                                <div className="col-sm-12">
                                    <Form>
                                        <Form.Field>
                                            <Radio
                                            label='Disabled'
                                            name='statusOrder'
                                            value= {false}
                                            checked={!data.statusOrder ? true : false}
                                            onChange={() => this.handleChange("statusOrder", false)}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Radio
                                                label='Enabled'
                                                name='statusOrder'
                                                value={true}
                                                checked={data.statusOrder ? true : false}
                                                onChange={() => this.handleChange("statusOrder", true)}
                                            />
                                        </Form.Field>
                                    </Form>
                                    {/*<Checkbox label='Order Price'/>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 f-12 pt-3">
                            <div className="col-sm-12">
                                <label>Action</label>
                            </div>
                            <div className="col-sm-6">
                                <Dropdown placeholder='Buy'
                                    defaultValue={data.orderType}
                                    search selection 
                                    options={[
                                        {key:'Buy',value: "1",text: 'Buy'},
                                        {key:'Sell',value: "2",text: 'Sell'}
                                    ]}
                                    onChange={(e, data) => this.handleChange("orderType",data.value)}
                                    value={data.orderType}
                                    className={"f-12 text-center align-self-center col-sm-12"}
                                />
                            </div>
                            <div className="col-sm-12 row pt-3 pr-0">
                                <div className="col-sm-12">
                                    <label htmlFor="">Order Vol</label>
                                </div>
                                <div className="col-sm-5 mb-3">
                                    {/* <NumberInput idclassname="tradeAtOrderVol" defaultValue={"0"} /> */}
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <button type="button"  
                                                onClick={()=>this.handleChange("minusVol", Number(data.orderVol))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                <i className="icofont icofont-minus f-9"></i>
                                            </button>
                                        </span>
                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                            className="form-control f-12" defaultValue='0' value={ConvertToRupiah(data.orderVol)}  placeholder="0"
                                            onChange={(e)=>this.handleChange("setVol", e.target.value)} 
                                            onFocus={(e) => e.target.select()} />
                                        <span className="input-group-btn">
                                            <button type="button"   
                                                onClick={()=>this.handleChange("plusVol", Number(data.orderVol))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                <span className="icofont icofont-plus f-9"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-2 f-16 pt-3">
                                    Lot
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <label>Order Price</label>
                            </div>
                            <div className="col-sm-12 mb-2">
                                <Dropdown placeholder='Offer + 5 ticks'
                                    defaultValue={data.orderPrice}
                                    search
                                    selection
                                    options={ p.orderPriceOption }
                                    onChange={(e, data) => this.handleChange("orderPrice",data.value)}
                                    value={data.orderPrice}
                                    className={"f-12 text-center align-self-center col-sm-12"}
                                />

                            </div>
                            <div className="col-sm-12 row mb-3 pr-0">
                                <div className="col-sm-2 f-16 pt-3">
                                    IDR
                                </div>
                                <div className="col-sm-7 pr-0">
                                    {/* <NumberInput
                                        status={data.orderPrice == "Manual" ? true : false}
                                        idclassname="tradeAtIdr" defaultValue="0"/> */}
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <button type="button" disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} 
                                                onClick={()=>this.handleChange("minusManPrice", Number(data.manualPrice))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                <i className="icofont icofont-minus f-9"></i>
                                            </button>
                                        </span>
                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                            disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} 
                                            className="form-control f-12" defaultValue='0' value={ConvertToRupiah(data.manualPrice)}  placeholder="0"
                                            onChange={(e)=>this.handleChange("manPrice", e.target.value)} 
                                            onFocus={(e) => e.target.select()} />
                                        <span className="input-group-btn">
                                            <button type="button" disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""}  
                                                onClick={()=>this.handleChange("plusManPrice", Number(data.manualPrice))} 
                                                className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                <span className="icofont icofont-plus f-9"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 row mb-3 pr-0">
                                <div className="col-sm-2 f-16 pt-3">
                                    Value
                                </div>
                                <div className="col-sm-7 pr-0">
                                    <Input placeholder='0' id="tradeValue" className="col-sm-12 px-0" 
                                        style={{textAlign:"right !important"}} readOnly={true}
                                        value={ConvertToRupiah(data.manualPrice*data.orderVol*100)} 
                                        disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} />
                                </div>
                                <div className="col-sm-3 pl-1" style={{marginTop:"1px"}}>
                                    <button className="btn btn-primary" onClick={()=>this.saveDisclaimer()}>
                                        Save Settings
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrderSettingListAgGrid
                        clickedit={this.buttonClickEdit}
                        size={widthSize()}/>
                </div>
            </>
        );
    }
}
class OrderSettingListAgGrid_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            list: [42, 33, 68],
            columnDefs: [
                { field: "on", headerName: "#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:s=="s67"?130:s=="s75"?110:80, lockPosition:true, lockVisible:true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    },
                    cellRendererFramework: function(params) {
                        var cmd = params.data.on;
                        var ruleID = params.data.ruleID;
                        if(ruleID === "\xa0"){
                            return ""
                        }else{
                            var statusC = params.node.data.status.toUpperCase();
                            return <button className={`btn-cellOn btn btn-sm mx-1 f-9 ${statusC === "EXECUTED" && "disabled"} ${cmd ? "btn-primary" : "btn-dark" }`} 
                                    onClick={() => statusC === "WORKING" && self.props.handleOnClick("trade", "actionAutoStatus", {"rule_id": params.data.ruleID, 'rule': params.data.rule})}> 
                                        {cmd ? "Enabled" : "Disabled"} 
                                    </button>
                        }
                    },
                    suppressSizeToFit: true,
                },
                { field: "ruleID", headerName: "#Rule.ID", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?175:s=="s50"?160:s=="s67"?140:s=="s75"?120:100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { 
                    field: "createdDate", headerName: "Created Date", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?190:s=="s75"?185:s=="s80"?165:s=="s90"?155:135, comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }, suppressSizeToFit: true},
                { field: "cmd", headerName: "Cmd.", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?160:s=="s50"?150:s=="s67"?130:s=="s75"?120:100,
                    cellClass : function (params) {
                        return params.data.cmd.toUpperCase() == "BUY" ? "text-center text-danger grid-table d-border-aggrid-right f-12 locked-col locked-visible"
                            : "text-center text-success grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { field: "code", headerName: "Code", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?175:s=="s50"?160:s=="s67"?140:s=="s75"?120:100,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { field: "condition", headerName: "Condition", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:180, comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    },
                    cellRenderer: (params) => {
                        if(params.data.ruleID === "\xa0"){
                            return "";
                        }else{
                            let ncond = params.data.condition.split("#");
                            return ncond[0] + " " + ncond[1] + " " + ConvertToRupiah(ncond[2]);
                        }
                    },
                    suppressSizeToFit: true},
                { field: "vol", headerName: "Vol. (Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?200:s=="s67"?175:s=="s75"?170:s=="s80"?150:100, comparator: integerComparator, 
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?195:s=="s75"?190:s=="s80"?160:120, 
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true,
                    cellRenderer: (params) => {
                        var typeOP = params.data.typeOP
                        if(typeOP.includes("BB") || typeOP.includes("BO") || typeOP.includes("LP") || typeOP == ""){
                            return params.data.price;
                        }else{
                            return ConvertToRupiah(params.data.price);
                        }
                    }},
                { field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?195:s=="s75"?190:s=="s80"?160:120, comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    }, suppressSizeToFit: true},
                { field: "exp", headerName: "Expired Date", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?220:s=="s67"?190:s=="s75"?185:s=="s80"?165:s=="s90"?155:135, comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    } },
                { field: "status", headerName: "Status", sortable: true, resizable: true,
                    width: s=="s49"?280:s=="s50"?250:s=="s67"?195:s=="s75"?190:s=="s80"?160:120, comparator: integerComparator,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible ${params.data.status == "Working" && "text-success"}`;
                    }, suppressSizeToFit: true},
                { field: "action", headerName: "Action", sortable: true, filter: "agTextColumnFilter", width:105, pinned: "right", lockPosition: true, lockVisible: true, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.ruleID === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            var status = params.node.data.status.toUpperCase();
                            if(status === "EXPIRED" || status === "EXECUTED"){
                                eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9 disabled">Edit</button>'+
                                '<button class="btn-cellwithdraw btn btn-sm btn-danger mx-1 f-9 disabled">Cancel</button>' +
                                '</span>';
                            }else{
                                eDiv.innerHTML = '<span class="px-1">' +
                                    '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9">Edit</button>'+
                                    '<button class="btn-cellwithdraw btn btn-sm btn-danger mx-1 f-9">Cancel</button>' +
                                    '</span>';
                                var aButton = eDiv.querySelectorAll('.btn-cellamend')[0];
                                var wButton = eDiv.querySelectorAll('.btn-cellwithdraw')[0];
    
                                function closeClick() {
                                    self.refs.frameAction.closeModal(100);
                                }
                            
                                function buttonClickEdit() {
                                    self.refs.frameAction.showModal({
                                        headerClass: () => <div className="text-white text-center pb-5">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer pull-right"
                                                onClick={closeClick}>
                                            </i>
                                            <h1 className="text-center">Edit Automatic Order</h1>
                                        </div>,
                                        size: 'large',
                                        contentClass: EditPage,
                                        onOpen: self.props.handleOnClick("trade", "actionAutoShowEdit", params.data.ruleID),
                                        onClose: (result) => {console.log('Modal 1 result = ', result)}
                                    })
                                }
        
                                aButton.addEventListener('click', buttonClickEdit);
                                wButton.addEventListener('click', () => self.props.handleOnClick("trade", "actionAutoDelete",  {"rule_id": params.data.ruleID, 'rule': params.data.rule }));
                            }
                        }
                        return eDiv;
                    }, suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    updateStateOn = params => {
        this.setState(state => {
            const rowData = state.rowData.map((item, j) => {
                if(item.orderId == params){
                    return item.on = !item.on;
                }else{
                    return item;
                }
            });
            return  { rowData, };
        })
    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    render() {
        return (
            <>
                <AppFrameAction ref="frameAction"/>
                <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                <div className="bg-tableheader col-sm-12 px-0 mx-0 text-center py-2 f-16">ORDER SETTINGS LIST</div>
                </div>
                <div
                    className="card-Aut-Order ag-theme-balham-dark ag-header-border d-border ag-striped-odd"
                    style={{
                        width: 'auto' }}>

                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        // rowData={this.state.rowData}
                        rowData={this.props.orderSetttingList}                        
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

class EditPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = props.setCondAutomatic;
    }
    handleChange = (type, val) => {
        if(type === "typePrice"){ this.setState({ typePrice: val }); }
        if(type === "condPrice"){ this.setState({ condPrice: val }); }
        if(type === "statusOrder"){ this.setState({ statusOrder: val }); }
        if(type === "orderType"){ this.setState({ orderType: val }); }
        if(type === "orderPrice"){ this.setState({ orderPrice: val, manualPrice: 0}); }
        if(type === "manPrice"){ this.setState({ manualPrice: val.replace(/[^0-9]/g, '') }); }
        if(type === "plusManPrice"){ this.setState({ manualPrice: Calculator("addR", val)}); }
        if(type === "minusManPrice" && val > 0){ this.setState({ manualPrice: Calculator("subR", val)}); }
        if(type === "setVol"){ this.setState({ orderVol: Number(val.replace(/[^0-9]/g, '')) }); }
        if(type === "plusVol"){ this.setState({ orderVol: val + 1}); }
        if(type === "minusVol" && val > 0){ this.setState({ orderVol: val - 1}); }

    }
    handleChangeDate = () => this.setState({ date: document.getElementById('datepickerAutEdit').value});
    saveSetting = () => {
        this.props.handleOnClick("trade", "actionAutoUpdate", this.state)
        this.setState(this.state = setCondAutomatic);
        $("#datepickerAutEdit").datepicker("setDate", "");
        this.refs.frameAction.closeModal(100);
    }

    componentDidMount() {
        $(document).ready(function() {
            var isRtl = $('html').attr('dir') === 'rtl';

            // Zaky Update
            $('#datepickerAutEdit').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: GetTimeForDateTicker(0, -1),
                endDate: GetTimeForDateTicker(0, -30),
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateAutEdit").click(); 
            });
        })
    }
    
    render(){
        let data = this.state;
        let p = this.props;
        let manualPrice = data.orderPrice == "MANUAL" ? data.manualPrice : 0;
        return(
            <div className="bg-dark-grey d-border">
                <AppFrameAction ref="frameAction"/>
                <input type="hidden" onClick={()=>this.handleChangeDate()} id={"handleDateAutEdit"} />

                <div className="row">
                    <div className="col-sm-6 f-12 pt-3">
                        <div className="col-sm-12 row mb-2 pr-0">
                            <div className="col-sm-12">
                                <label>Set Condition</label>
                            </div>
                            <div className="col-sm-6 mb-3">
                                <Dropdown placeholder='Last Price'
                                    search selection
                                    defaultValue={p.rulePrice}
                                    options={
                                        [
                                            {key:'1',value:'LP',text: 'Last Price'},
                                            // {key:'2',value:'BO',text: 'Best Offer Price'},
                                            // {key:'3',value:'BB',text: 'Best Bid Price'},
                                        ]
                                    }
                                    onChange={(e, data) => p.handleOnClick("trade","actionAutSetRulePrice", data.value)}
                                    value={p.rulePrice}
                                    className={"f-12 text-center align-self-center col-sm-12"}
                                />
                            </div>
                            <div className="col-sm-2 pr-0 pl-0">
                                <Dropdown placeholder='Type'
                                    defaultValue={data.condPrice}
                                    search selection options={                                        
                                        [
                                            {key:'1',value:'L',text: '<='},
                                            {key:'2',value:'G',text: '>='},
                                        ]
                                    }
                                    onChange={(e, data)=>this.handleChange("condPrice",data.value)}
                                    value={data.condPrice}
                                    className={"f-12 text-center align-self-center col-sm-12"}
                                />
                            </div>
                            <div className="col-sm-4 pr-0">
                                {/* <NumberInput idclassname="tradeAtSetCondition" defaultValue={"0"}/> */}
                                <div className="input-group">
                                    <span className="input-group-btn">
                                        <button type="button" onClick={() => p.handleOnClick("trade","actionAutMinusSetPrice", Number(p.setPriceAutomatic))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                            style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                            <i className="icofont icofont-minus f-9"></i>
                                        </button>
                                    </span>
                                    <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                        className="form-control f-12" defaultValue='0' value={ConvertToRupiah(p.setPriceAutomatic)}  placeholder="0"
                                        onChange={(e) => p.handleOnClick("trade","actionAutSetPrice",e.target.value)} 
                                        onFocus={(e) => e.target.select()} />
                                    <span className="input-group-btn">
                                        <button type="button" onClick={() => p.handleOnClick("trade","actionAutPlusSetPrice", Number(p.setPriceAutomatic))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                            style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                            <span className="icofont icofont-plus f-9"></span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <label htmlFor="">Expired Date (max day 30)</label>
                            </div>
                            <div className="col-sm-12 ui input mb-3" style={{paddingRight:'53px'}}>
                                <Input placeholder='dd/mm/yy' value={data.date} id="datepickerAutEdit" className="col-sm-12 pl-0 pr-0 text-center align-self-center"/>
                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}><span
                                    className="fa fa-calendar-alt"></span></span>
                            </div>
                            <div className="col-sm-12">
                                <Form>
                                    <Form.Field>
                                        <Radio
                                            label='Disable'
                                            name='statusOrder'
                                            value= {false}
                                            checked={!data.statusOrder ? true : false}
                                            onChange={() => this.handleChange("statusOrder", false)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label='Enable'
                                            name='statusOrder'
                                            value={true}
                                            checked={data.statusOrder ? true : false}
                                            onChange={() => this.handleChange("statusOrder", true)}
                                        />
                                    </Form.Field>
                                </Form>
                                {/*<Checkbox label='Order Price'/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 f-12 pt-3">
                        <div className="col-sm-12">
                            <label>Set Condition</label>
                        </div>
                        <div className="col-sm-6">
                            <Dropdown placeholder='Buy'
                                    disabled
                                    defaultValue={data.orderType}
                                    search selection options={[
                                        {key:'Buy',value: "1",text: 'Buy'},
                                        {key:'Sell',value: "2",text: 'Sell'}
                                    ]}
                                    onChange={(e, data) => this.handleChange("orderType",data.value)}
                                    value={data.orderType}
                                    className={"f-12 text-center align-self-center col-sm-12"}
                            />
                        </div>
                        <div className="col-sm-12 row pt-3 pr-0">
                            <div className="col-sm-12">
                                <label htmlFor="">Order Vol</label>
                            </div>
                            <div className="col-sm-5 mb-3">
                                {/* <NumberInput idclassname="tradeAtOrderVol" defaultValue={"0"} /> */}
                                <div className="input-group">
                                    <span className="input-group-btn">
                                        <button type="button"  
                                            onClick={()=>this.handleChange("minusVol", Number(data.orderVol))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                            style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                            <i className="icofont icofont-minus f-9"></i>
                                        </button>
                                    </span>
                                    <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                        className="form-control f-12" defaultValue='0' value={ConvertToRupiah(data.orderVol)}  placeholder="0"
                                        onChange={(e)=>this.handleChange("setVol", e.target.value)} 
                                        onFocus={(e) => e.target.select()} />
                                    <span className="input-group-btn">
                                        <button type="button"   
                                            onClick={()=>this.handleChange("plusVol", Number(data.orderVol))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                            style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                            <span className="icofont icofont-plus f-9"></span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-2 f-16 pt-3">
                                Lot
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label>Order Price</label>
                        </div>
                        <div className="col-sm-12 mb-2">
                            <Dropdown placeholder='Offer + 5 ticks'
                                defaultValue={data.orderPrice}
                                search
                                selection
                                options={ p.orderPriceOption }
                                onChange={(e, data) => this.handleChange("orderPrice",data.value)}
                                value={data.orderPrice}
                                className={"f-12 text-center align-self-center col-sm-12"}
                            />

                        </div>
                        <div className="col-sm-12 row mb-3 pr-0">
                            <div className="col-sm-2 f-16 pt-3">
                                IDR
                            </div>
                            <div className="col-sm-7 pr-0">
                                {/* <NumberInput
                                    status={this.state.value == "Manual" ? false:true}
                                    idclassname="tradeAtIdr" defaultValue="0"/> */}
                                <div className="input-group">
                                    <span className="input-group-btn">
                                        <button type="button" disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} 
                                            onClick={()=>this.handleChange("minusManPrice", Number(data.manualPrice))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                            style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                            <i className="icofont icofont-minus f-9"></i>
                                        </button>
                                    </span>
                                    <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                        disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} 
                                        className="form-control f-12" defaultValue='0' value={ConvertToRupiah(manualPrice)}  placeholder="0"
                                        onChange={(e)=>this.handleChange("manPrice", e.target.value)} 
                                        onFocus={(e) => e.target.select()} />
                                    <span className="input-group-btn">
                                        <button type="button" disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""}  
                                            onClick={()=>this.handleChange("plusManPrice", Number(data.manualPrice))} 
                                            className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                            style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                            <span className="icofont icofont-plus f-9"></span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 row mb-3 pr-0">
                            <div className="col-sm-2 f-16 pt-3">
                                Value
                            </div>
                            <div className="col-sm-7 pr-0">
                                <Input placeholder='0' id="tradeValue" className="col-sm-12 px-0" 
                                    style={{textAlign:"right !important"}} readOnly={true}
                                    value={ConvertToRupiah(data.manualPrice*data.orderVol*100)} 
                                    disabled={data.orderPrice.toUpperCase() != "MANUAL" ? "disable" : ""} />
                            </div>
                            <div className="col-sm-3 mb-3 text-right">
                                <button className="btn btn-primary" onClick={()=>this.saveSetting()}>Save Settings</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class saveDisclaimerCard_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    closeClick = (e) => {
        this.props.handleOnClick("trade", "actionAutoSaveCancel");        
        this.refs.frameAction.closeModal(100);
    }
    confirmClick = (e) => {
        this.props.handleOnClick("trade", "actionAutoSaveOK");
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white">
                    <div className="col-sm-12 f-15 py-2">
                        Automatic order ini bekerja untuk evaluasi kondisi di market tetapi bisa terjadi "literacy" 
                        sehingga eksekusi ini tidak menjamin terjadi trade.<br/>
                        <ul className="pl-4 mt-3 f-12">
                            <li>
                                Order ter-<i>save</i> tidak menjamin automatic order langsung terjadi match.
                            </li>
                            <li>
                                Order terkirim tidak masuk ke dalam blank auction.
                            </li>
                            <li>Order yang tersimpan akan tetapi tereksekusi jika Bid/Offer 
                                dalam kondisi kosong dan sesuai bata waktu yang dipililih (max date 30 hari).
                            </li>
                        </ul>
                    </div>

                    <div className="text-center pt-5">
                        <button className="btn btn-danger col-sm-3 mr-2" onClick={this.closeClick}>Close</button>
                        <button className="btn btn-primary col-sm-3" onClick={this.confirmClick}>OK</button>
                    </div>
                </div>
            </>
        );
    }
}

//============= EXPIRE AUTOMATIC ORDER Display =============
class ExpOrder_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartEO').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDatepickerEO").click(); 
            });
            $('#datepickerendEO').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDatepickerEO").click(); 
            });


        });
    }
    handleChangeDate = () => {
        let nStartDate = document.getElementById('datepickerstartEO').value
        let nEndDate =  document.getElementById('datepickerendEO').value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('datepickerendEO').value = GetTimeForDateTicker(0,+1);
            nEndDate = GetTimeForDateTicker(0,+1)
            $("#datepickerendEO").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to:nEndDate}
        this.props.handleOnClick('datepicker','AutExpireOrder', nDatepicker)
    }
    render(){
        let p = this.props;
        return(
            <>
                <AppFrameAction ref="frameAction"/>
                <div className="col-sm-12">
                    <div id={"AutExpOrderPIN"} className={`col-sm-12 ${(p.verifyPinStatus) ? "d-none" : "d-block"}`}>
                        <VerifyPINPortofolio pos="AutExpOrder" onClose={() => null} />
                    </div>
                    <div id="ContentExpAutOrder" className={`${!p.verifyPinStatus && "d-none"}`}>
                    <div className="col-sm-12 h-62">
                        <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                            <input type="hidden" onClick={this.handleChangeDate} id={"handleDatepickerEO"} />
                            <table>
                                <tr>
                                    <td className={"px-0"}>
                                        <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                    </td>
                                    <td className={"px-0"}>
                                        <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                            <Input placeholder='dd/mm/yy' id="datepickerstartEO" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                            <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                            <span
                                                className="fa fa-calendar-alt"></span>
                                        </span>
                                        </div>
                                    </td>
                                    <td className={"px-0"}>
                                        <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                    </td>
                                    <td className={"px-0"}>

                                        <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                            <Input placeholder='dd/mm/yy' id="datepickerendEO" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                            <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                            <span
                                                className="fa fa-calendar-alt"></span>
                                        </span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                            onClick={()=>p.handleOnClick('trade','GoAutExpireOrder','none')}>Go</button>
                                    </td>
                                </tr>
                            </table>



                        </div>
                    </div>

                    <div className="col-sm-12 pl-2 pr-3">
                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                            <div className="col-sm-12 px-0 mx-0 text-center pt-3 pb-2 h-30 f-12 bg-tableheader">Rule Expired Automatic Order</div>
                        </div>
                        <ExpireOrderAgGrid size={widthSize()}/>
                    </div>
                </div>
                </div>
            </>
        )
    }

}
class ExpireOrderAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "hastag", headerName: "#", sortable: true, filter: "agTextColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?130:50, minWidth: 50, lockPosition:true, lockVisible:true,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    },},
                { field: "ruleID", headerName: "#Rule.ID", sortable: true, filter: "agTextColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?155:s=="s75"?160:s=="s80"?140:s=="s85"?130:s=="s90"?120:100, minWidth: 110,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    },},
                { field: "date", headerName: "Created Date", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?140:s=="s50"?130:s=="s67"?120:s=="s75"?150:s=="s80"?130:s=="s85"?120:s=="s90"?125:125, minWidth: 125,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "cmd", headerName: "Cmd.", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?110:s=="s75"?110:s=="s80"?90:s=="s85"?80:70, minWidth: 70,
                    cellClass : function (params) {
                        var cmd = params.data.cmd;
                        return cmd.includes('buy') === true ? "text-left text-danger grid-table d-border-aggrid-right f-12" :
                            "text-left text-success grid-table d-border-aggrid-right f-12";
                    },},
                { field: "code", headerName: "Code", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?110:s=="s75"?110:s=="s80"?90:s=="s85"?80:70, minWidth: 70,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                }, },
                { field: "condition", headerName: "Condition", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?180:s=="s50"?170:s=="s67"?160:s=="s75"?180:s=="s80"?170:s=="s85"?160:s=="s90"?150:130, minWidth: 130,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "vol", headerName: "Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?190:s=="s50"?180:s=="s67"?170:s=="s75"?170:s=="s80"?160:s=="s85"?120:s=="s90"?110:100, minWidth: 100,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?200:s=="s75"?170:s=="s80"?150:s=="s85"?140:113, minWidth: 113,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?220:s=="s50"?210:s=="s67"?200:s=="s75"?180:s=="s80"?130:s=="s85"?120:s=="s90"?110:100, minWidth: 100,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "expireDate", headerName: "Expired Date", sortable: true, filter: "agNumberColumnFilter", comparator: stringComparator,
                    resizable: true,
                    width: s=="s49"?220:s=="s50"?200:s=="s67"?190:s=="s75"?150:s=="s80"?130:s=="s85"?120:s=="s90"?110:120, minWidth: 120,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "executeStatus", headerName: "Executed Status", sortable: true, filter: "agTextColumnFilter", comparator: dateComparator,
                    resizable: true,
                    width: s=="s49"?220:s=="s50"?200:s=="s67"?200:s=="s75"?170:s=="s80"?140:s=="s85"?140:s=="s90"?140:130, minWidth: 130,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "orderStatus", headerName: "Order Status", sortable: true, filter: "agTextColumnFilter", comparator: dateComparator,
                    resizable: true,
                    width: s=="s49"?330:s=="s50"?300:s=="s50"?300:s=="s67"?200:s=="s75"?150:s=="s80"?140:s=="s85"?130:s=="s90"?140:130, minWidth: 130,  
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }, },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                //     headerCheckboxSelection: isFirstColumn,
                //     checkboxSelection: isFirstColumn,
            },
            rowSelection: "multiple",
            getRowHeight : function(params){
                return 27;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }

        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card-tradeEO ag-theme-balham-dark d-border bg-dark-grey ag-bordered ag-striped-odd d-border"
                    >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.autExpOrderList}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        rowSelection={this.state.rowSelection}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

//============= 6. Send Order Display =============
class SentOrder_Base extends React.PureComponent{
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartSO').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateSentOrder").click(); 
            });
            $('#datepickerendSO').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateSentOrder").click(); 
            });
        });
    }

    handleChangeDate = () => {
        let nStartDate = document.getElementById("datepickerstartSO").value
        let nEndDate =  document.getElementById("datepickerendSO").value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById("datepickerendSO").value = GetTimeForDateTicker(0, 0);
            nEndDate = GetTimeForDateTicker(0, 0)
            $("#datepickerendSO").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start: nStartDate, to: nEndDate};
        this.props.handleOnClick('datepicker', 'tradeSentOrder', nDatepicker)
    }

    render(){
        var p = this.props;
        return(
            <>
                <WSConnectionAction ref="wsAction"/> {/* websocket connection component */}
                <AppFrameAction ref="frameAction"/>
                    <input type="hidden" onClick={()=>this.handleChangeDate()} id={"handleDateSentOrder"} />
                    <div className="col-sm-12">
                        <div id={"AutSentOrderPIN"} className={`${(p.verifyPinStatus || p.pinPage === "sentOrder") ? "d-none" : "d-block"} col-sm-12`}>
                            <VerifyPINPortofolio pos="AutSentOrder" onClose={() => null} />
                        </div>
                        <div id="ContentSentAutOrder" className={`${(p.verifyPinStatus || p.pinPage === "sentOrder") ? "d-block" : "d-none"}`}>
                            <div className="col-sm-12 h-62">
                                <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                    <table>
                                        <tr>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' id="datepickerstartSO" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                            </td>
                                            <td className={"px-0"}>

                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' id="datepickerendSO" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                    onClick={()=>p.handleOnClick('trade', 'GoTradeSentOrder','none')}>Go</button>
                                            </td>
                                        </tr>
                                    </table>



                                </div>
                            </div>

                            <div className="col-sm-12 pl-2 pr-3">
                                <OrderHistoryAgGrid size={widthSize()}/>
                            </div>
                        </div>
                    </div>
            </>
        )
    }

}
//Sent Order
class OrderHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const s = props.size;
        const self = this;
        this.state = {
            columnDefs: [
                { 
                    field: "date", headerName: "Created Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?180:120, lockPosition:true, lockVisible:true,
                    comparator: comparatorType['text'],
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "time", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 80,
                    comparator: comparatorType['text'], sort:'desc',   
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "rule_id", headerName: "#Rule.ID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?200:s=="s67"?155:s=="s75"?150:135,
                    comparator: comparatorType['text'],
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "market_ord_id", headerName: "#Market Order.ID", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType['text'],
                    width: s=="s49"?220:s=="s50"?200:s=="s67"?170:s=="s75"?160:160,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "server_ord_id", headerName: "#Server Order.ID", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType['text'],
                    width: s=="s49"?210:s=="s50"?200:s=="s67"?155:s=="s75"?150:135,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "symbol", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?200:s=="s50"?200:s=="s67"?100:s=="s75"?80:75,
                    comparator: comparatorType['text'],
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "side", headerName: "Cmd", sortable: true, resizable: true,
                    width: s=="s49"?200:s=="s50"?250:s=="s67"?110:s=="s75"?100:80,
                    comparator: comparatorType['text'],
                    cellClass : ({ value = '' }) => `text-center grid-table d-border-aggrid-right f-12 ${value.toUpperCase() === 'BUY' ? 'text-danger' : 'text-success'}`
                }, { 
                    field: "order_vol", headerName: "Qty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?150:s=="s67"?110:s=="s75"?100:80,
                    comparator: comparatorType['integer'],
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "order_price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?190:s=="s67"?170:s=="s75"?160:140,
                    comparator: comparatorType['integer'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "matched_vol", headerName: "M Qty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s67"?170:s=="s75"?130:110,
                    comparator: comparatorType['integer'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "matched_price", headerName: "M Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s67"?235:s=="s75"?260:s=="s80"?173:s=="s85"?120:120,
                    comparator: comparatorType['integer'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                }, { 
                    field: "status", headerName: "Status", sortable: true,
                    resizable: true,
                    width: s=="s49"?200:s=="s50"?240:s=="s67"?250:s=="s75"?240:s=="s80"?200:s=="s85"?130:120,
                    comparator: comparatorType['text'],
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12"
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                //     headerCheckboxSelection: isFirstColumn,
                //     checkboxSelection: isFirstColumn,
            },
            rowSelection: "multiple",
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                { date: "28/11/2018",
                    time: "08:21:33",
                    order : "001",
                    code: "AALI",
                    qty: "2",
                    cmd: "BUY",
                    price: "12.000",
                    mqty: "2",
                    mprice: "3.000",
                    result: "Match",
                    setdate: "12/11/2019",
                    dateuntil: "13/11/2019",
                },{ date: "28/11/2018",
                    time: "08:21:33",
                    order : "001",
                    code: "AALI",
                    qty: "2",
                    cmd: "SELL",
                    price: "12.000",
                    mqty: "2",
                    mprice: "3.000",
                    result: "Match",
                    setdate: "12/11/2019",
                    dateuntil: "13/11/2019",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }

        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                {/*senttttt*/}
                <div
                    className="card-tradeSO ag-theme-balham-dark d-border bg-dark-grey ag-bordered ag-striped-odd d-border"
                    >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeSendOrder}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        rowSelection={this.state.rowSelection}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

class SettingInWatchlist extends React.PureComponent{
    render(){
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="row col-sm-12 card-527 px-2 mx-0 pt-2 pb-0">
                    <div className="col-sm-4 px-0 mx-0 card-514">
                        <TradeWatchlistAgGrid size={widthSize()}/>
                    </div>
                    <div className="col-sm-8 pl-3 pr-0 mx-0 card-514">
                        <div className="col-sm-12 px-0 pt-0">
                            <BuyPage/>
                        </div>
                    </div>
                    {/* <div className="col-md-8 pl-1">
                        <SettingOrder
                            type="full"
                            lpSetting="setWatchlist"
                            volSetting="volsetWatchlist"
                            opSetting="opsetWatchlist"
                            slpSetting="ssetWatchlist" svolSetting="svolsetWatchlist" sopSetting="sopsetWatchlist"/>
                    </div> */}
                </div>

            </>
        );
    }

}

//oooooooo
let timer = 0;
let delay = 200;
let prevent = false;


//************************** Modal**************************
class Modal_automatic_order_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: !this.props.agreementAutomaticStatus,
        }
    }
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }
    simpan = () => {
        if(this.state.isChecked){
            this.props.handleOnClick("trade", "actionAutInformation", this.state.isChecked)
        }else{
            this.refs.frameAction.closeModal(100);
        }
    }

    onChangeCheckbox = () => {this.setState((prevState) => ({ isChecked: !prevState.isChecked }))}

    render() {
        let p = this.props;
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white f-13 text-center bg-dark-grey">
                    <div className="form-group row">
                        <div className="col-sm-6 text-left rborder-blue">
                        <ul>
                                <li><b>Automatic Order</b> adalah fitur aplikasi DXTrade untuk secara otomatis mengirimkan order bila mana kondisi / nilai parameter pasar telah sesuai dengan kondisi / nilai parameter yang ditetapkan oleh pengguna.</li>
                                <li>Aplikasi <b>DXTrade</b> akan mengevaluasi parameter kondisi yang telah ditetapkan dengan membandingkannya terhadap nilai parameter yang terjadi di pasar.</li>
                                <li>Ketepatan evaluasi terhadap nilai parameter pasar sangat bergantung pada kecepatan dan keakuratan data yang diterima dari bursa yang sangat bergantung pada kecepatan dan kualitas jaringan transmisi data.</li>
                                <li>Bila mana teridentifikasi terjadinya keanehan perilaku aplikasi dalam melakukan evaluasi nilai parameter dan atau berulangnya pengiriman order yang ter-generate secara otomatis maka BAHANA SEKURITAS berhak menghentikan fitur <b>Automatic Order</b> pada aplikasi DXTRADE sampai terselesaikannya permasalahan yang terjadi.</li>
                                <li>Parameter kondisi <b>Automatic order</b> yang belum tereksekusi sebelum terjadinya masalah akan dilanjutkan setelah fitur <b>Automatic Order</b> diaktifkan kembali oleh <b>BAHANA SEKURITAS</b>.</li>
                                <li>Pengguna mengerti dan menyanggupi untuk tidak menggunakan <b>Automatic Order</b> untuk memanipulasi pasar.</li>
                                <li>Fitur <b>Automatic order</b> tidak menjamin akan menjadi trade (match) ketika order terkirimkan secara otomatis dengan harga dan volume yang telah ditetapkan.</li>
                                <li>Diskripensi data yang dapat diakibatkan oleh kondisi jaringan dan lainnya dapat menyebabkan order tidak terkirim atau terlambat terkirim.</li>
                            </ul>
                        </div>
                        <div className="col-sm-6 text-left">
                            <ul>
                                <li><b>Automatic Order</b> is <b>DXTrade's</b> feature to trigger and send orders automatically when evaluated -market-parameters have met with condition / parameter set by the user.</li>
                                <li><b>DXTRADE</b> Application will evaluate the condition / parameters value that have been set by user, comparing them with the actual market value received from the exchange.</li>
                                <li>The precision of parameter value evaluation really depends on the speed and accuration of received data from the exchange the correlates with quality of transmission link.</li>
                                <li>If we identify some unintentional of abnormal application behaviour, Bahana Sekuritas has fully right to stop the Automatic Order Feature, and it will be resumed when the problem has been resolved.</li>
                                <li>Parameters of the <b>Automatic Order</b> condition that have not been executed before the problem occurs will be resumed after the <b>Automatic Order</b> feature is reactivated by BAHANA SEKURITAS.</li>
                                <li>User undertakes not use <b>Automatic Orders</b> to manipulate the market.</li>
                                <li><b>Automatic Order</b> does not guarantee to be a trade(match).</li>
                                <li>Data discrepancies that can be caused by network conditions and other can cause orders not to be sent or sent late.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 f-13">
                    <Checkbox label='Saya memahami dan menyetujui / I understand and agree' 
                        checked={this.state.isChecked} 
                        name={"agree"} 
                        onChange={this.onChangeCheckbox}/>
                        <br/>
                    <Checkbox label='Tidak setuju / disagree' 
                        checked={!this.state.isChecked} 
                        name={"disagree"} 
                        onChange={this.onChangeCheckbox}/>
                </div>
                <div className="text-center col-sm-12">
                    <button className="btn btn-primary btn-sm col-sm-2" onClick={()=>this.simpan()}>OK</button>
                </div>
            </>
        );
    }
}
class Modal_terms_condition_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.termsConditionStatus,
        }
    }
    closeClick = (e) => {
        this.props.handleOnClick("trade", "actionAutoSaveCancel", this.state.isChecked);        
        this.refs.frameAction.closeModal(100);
    }
    confirmClick = (e) => {
        this.props.handleOnClick("trade", "actionAutoSaveOK", this.state.isChecked);
        this.refs.frameAction.closeModal(100);
    }
    onChangeCheckbox = () => {this.setState((prevState) => ({ isChecked: !prevState.isChecked }))}

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="text-white f-13 text-center bg-dark-grey">
                    <div className="form-group row">
                        <div className="col-sm-6 text-left rborder-blue">
                            <ul>
                                <li>Fitur <i>Automatic Order</i> tidak boleh dipergunakan dalam kegiatan memanipulasi harga pasar.</li>
                                <li>Maksimum parameter setting <i>Automatic Order</i> yang aktif adalah 20 set.</li>
                                <li>Maksimum masa aktif <i>automatic order </i> yang belum tereksekusi adalah sampai satu hari sebelum expired date atau selambat-lambatnya adalah 30 hari kalender.</li>
                                <li>Setting parameter <i>automatic order</i> yang sudah tereksekusi akan otomatis menjadi non aktif dan tidak dapat diaktifkan kembali.</li>
                                <li>Dalam kondisi aplikasi berjalan normal order akan terkirim bilamana kondisi parameter terpenuhi.</li>
                                <li>Pada saat order terkirim yang disebabkan oleh terpenuhinya parameter yang ditetapkan belum tentu memiliki kesamaan data market dengan saat evaluasi parameter. <i>Automatic order</i> tidak menjamin akan menjadi trade(match).</li>
                                <li>Diskripensi data yang dapat diakibatkan oleh kondisi jaringan dan lainnya dapat menyebabkan order tidak terkirim atau terlambat terkirim.</li>
                                <li>Dalam hal ditemukan keanehan perilaku aplikasi dan atau ketidak normalan berjalannya aplikasi, eksekusi automatic order akan dihentikan atau fitur automatic order akan dinonaktifkan.</li>
                            </ul>
                        </div>

                        <div className="col-sm-6 text-left">
                            <ul>
                                <li>The Automatic Order feature may not be used in the activity of manipualting market prices.</li>
                                <li>The maximum parameter setting of the active automatic order is 20 sets.</li>
                                <li>The maximum active period for an automatic order that has not been executed is up to one day before the expiration date or 30 calendar days at the latest.</li>
                                <li>Automatic order parameter settings that have been executed will automatically become inactive and cannot be reactivated.</li>
                                <li>In normal application conditions, the order will be sent if the paramete conditions are met.</li>
                                <li>When the order is sent, which is caused by the fulfillment of the specified parameters, it does not necessarily have the same market data as when evaluating the parameters. </li>
                                <li>Automatic orders do not guarantee to be a trade(Match).</li>
                                <li>Data discrepancies that can be caused by network conditions and others can cause orders not to be sent or sent late.</li>
                                <li>In the event that strange application behaviour is found and or abnormal applicatoin runs, the automatic order execution will be stopped or the automatic order feature will be deactivated.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 f-13">
                    
                    <Checkbox 
                        label='Apakah anda ingin menampilkan di kemudian hari syarat dan ketentuan ini? /
                            Do you want to display at a later date these terms and conditions?' 
                        // checked={this.props.termsConditionStatus} 
                        checked={this.state.isChecked} 
                        name={"agree"} 
                        onChange={this.onChangeCheckbox}/>
                        {/* onChange={()=>this.props.handleOnClick("trade", "actionAutTermCondition")}/> */}
                        <br/>
                </div>
                <div className="text-center col-sm-12">
                    <button className="btn btn-primary btn-sm col-sm-1" onClick={this.confirmClick}>OK</button>&nbsp;&nbsp;
                    <button className="btn btn-danger btn-sm col-sm-1" onClick={this.closeClick}>Cancel</button>
                </div>
            </>
        );
    }
}

class AmendModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <ModalAmend/>
            </>
        );
    }
}
class AmendPage_Base extends React.PureComponent{
    componentDidMount(){
        ResizeResponsive();
    }

    render(){
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <div className="text-white f-12">
                    <div className="col sm-12 px-0 mx-0 row">
                        <div className="col-sm-6 px-2 mt-0">
                            <TableInfoTransaction lotshare="modalamend" card="card-350" />
                        </div>
                        <div className="col-sm-6 mt-0 d-border-active bg-amend card-520 d-border">
                            <FormAmend idPrice="modalAmendPrice" idVol="modalAmendVol" idValue="modalAmendValue" columnSm="col-sm-11"/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class WithdrawModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'withdraw'/>
            </>
        );
    }
}

class OrderDetailModal extends React.PureComponent {

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <ModalOrderDetail/>
            </>
        );
    }
} 
// cancel modal order book
class DetailTradeOrderBook_Base extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        $(document).keypress(function(e) {
            if(e.which == 13) {
                $("#enter-ok").click();
            }
        });
    }
    render() {
        let data = this.props.cancelOrderBook
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="col-sm-12 text-white px-0 mx-0 f-12">
                    <div className="col-sm-12 mx-0 px-0">
                        <Table size="sm" responsive borderless className="text-white">
                            <thead className="d-border-table">
                            <tr>
                                <th
                                    className="text-center bg-brown text-white" colSpan="3">ORDER BOOKING DETAIL</th>
                            </tr>
                            </thead>
                            <tbody className="no-wrap d-border-table">
                            <tr>
                                <td>Booking No</td>
                                <td>:</td>
                                <td>{data.bookingNo}</td>
                            </tr>
                            <tr>
                                <td>Code</td>
                                <td>:</td>
                                <td>{data.code}</td>
                            </tr>
                            <tr>
                                <td>Market</td>
                                <td>:</td>
                                <td>{data.mkt}</td>
                            </tr>
                            <tr>
                                <td>Booking Date</td>
                                <td>:</td>
                                <td>{data.bookingDate}</td>
                            </tr>

                            </tbody>
                            </Table>
                    </div>
                </div><br/>
                <div className="col-sm-12 text-center mx-0 px-0 f-12">Do you want to cancel the order ?</div><br/>
                <div className="col-sm-12 row mx-0 px-0">
                    <div className="col-sm-12 text-center">
                        <button className="btn btn-danger col-sm-6" id="enter-ok" onClick={
                            () => this.props.handleOnClick('trade', 'cancelOrderbook|cancelOrder',)
                            // () => {
                            //     alert('Order canceled!');
                            //     var refs = this.refs;
                            //     refs.frameAction.closeModal(100)
                            // }
                        }>Cancel Order</button>
                    </div>
                    <div className="col-sm-12 text-center">
                        <button className="btn btn-trans col-sm-6" onClick={
                            () => {
                                var refs = this.refs;
                                refs.frameAction.closeModal(100)
                            }
                        }>Cancel</button>
                    </div>
                </div>
            </>
        );
    }
}

class ModalRestricted extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="text-white f-12 text-center bg-dark-grey">
                    <div className="form-group">
                        <p className="col-sm-12 px-5 py-2 col-form-label">
                                Cannot create rules due to account status.<br/>
                                Please contact our helpdesk center 14009.
                        </p>
                    </div>
                </div>
                <div className="text-center">
                        <button className="btn btn-primary btn-sm col-sm-3" onClick={this.closeClick}>OK</button>
                    </div>
            </>
        );
    }
}


//************************** Context Connector **************************

const CustomFrameHeaderTrade = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        isManual:vars.isManual,
        agreementAutomaticStatus: vars.agreementAutomaticStatus,
        handleManual:(isManual)=>{actions.sendAction('handleManual',{isManual})}
    }),
    ["handleManual"]
)(CustomFrameHeaderTrade_Base);

// subHeader : OrderList  
const TradeWatchlist = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        orderlistInformation:vars.orderlistInformation,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(TradeWatchlist_Base);

const OrderListAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        orderList:vars.orderList,
        orderlistSelected:vars.orderlistSelected,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(OrderListAgGrid_Base)

const TradeListOrderListAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        tradeListMain: vars.tradeListMain,
    })
)(TradeListOrderListAgGrid_Base)

const TradeOrderSummaryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        tradeOrderSum:vars.tradeOrderSum,
    })
)(TradeOrderSummaryAgGrid_Base)

const TradeTradeSummaryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        tradeTradeSum:vars.tradeTradeSum,
    })
)(TradeTradeSummaryAgGrid_Base)

// subHeader : OrderBook
const OrderbookPage = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        box1:vars.box1,
        box2:vars.box2,
        box3:vars.box3,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(OrderbookPage_Base)

// 2.5 TRADE PL
// const TradePL = ContextConnector(BIPSAppContext,
//     (vars, actions)=>({
//     }),
// )(TradePL_Base)

// 2.5 TRADE PL AG-GRID
const TradePLAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        tradePL:vars.tradePL,
    }),
)(TradePLAgGrid_Base)

// 3. ORDER BOOK LIST Display
const TradeOrderBookList = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
    }),
)(TradeOrderBookList_Base)
const TradeOrderBookListAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        tradeOrderBookList:vars.tradeOrderBookList,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(TradeOrderBookListAgGrid_Base)

const DetailTradeOrderBook = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        cancelOrderBook:vars.cancelOrderBook,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(DetailTradeOrderBook_Base)

const AmendPage = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(AmendPage_Base)


// 4. ADVERTISEMENT LIST Display
const TradePageAdv = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        stocklistOptions:vars.stocklistOptions,
        codeSearchTradeAdv:vars.codeSearchTradeAdv,
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode',{type, code}),
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleSearchCode","handleOnClick"]
)(TradePageAdv_Base);

const TradeAdvBidAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        tradeAdvBid:vars.tradeAdvBid,
    })
)(TradeAdvBidAgGrid_Base)

const TradeAdvOfferAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        tradeAdvOffer:vars.tradeAdvOffer,
    })
)(TradeAdvOfferAgGrid_Base)

// 5. automatic order setting
const OrderSetting = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
    })
)(OrderSetting_Base)
const OrderSettingListAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        orderSetttingList: vars.orderSetttingList,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(OrderSettingListAgGrid_Base)
const TableInfoTransactionLayout2 = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        userProfile: vars.userProfile,
        roleWorking: vars.roleWorking,
        termsConditionStatus: vars.termsConditionStatus,
        setPriceAutomatic: vars.setPriceAutomatic,
        rulePrice: vars.rulePrice,
        orderPriceOption: vars.orderPriceOption,
        statusAlertN: vars.statusAlertN,
        handleStatusAlert3: (type, statusAlert, msg, data) => actions.sendAction('handleStatusAlert3', {type, statusAlert, msg, data}),
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(TableInfoTransactionLayout2_Base)
const EditPage = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        setPriceAutomatic: vars.setPriceAutomatic,
        setCondAutomatic: vars.setCondAutomatic,
        rulePrice: vars.rulePrice,
        orderPriceOption: vars.orderPriceOption,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(EditPage_Base)
const saveDisclaimerCard = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        handleOnClick:(pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(saveDisclaimerCard_Base)

//== EXPIRE AUTOMATIC ORDER Display 
const ExpOrder = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        verifyPinStatus: vars.verifyPinStatus,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(ExpOrder_Base)
const ExpireOrderAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        autExpOrderList: vars.autExpOrderList
    }),
)(ExpireOrderAgGrid_Base)

// 6. SEND ORDER Display
const SentOrder = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        rangetradeSendOrder: vars.rangetradeSendOrder,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(SentOrder_Base)
const OrderHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        tradeSendOrder:vars.tradeSendOrder,
    }),
)(OrderHistoryAgGrid_Base)

// subHeader : watchlist
const TradeWatchlistAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        dataWatchlist:vars.dataWatchlist,
        watchlistCode:vars.watchlistCode,
    })
)(TradeWatchlistAgGrid_Base)

const Modal_terms_condition = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        termsConditionStatus: vars.termsConditionStatus,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),["handleOnClick"]
)(Modal_terms_condition_Base)

const Modal_automatic_order = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        agreementAutomaticStatus: vars.agreementAutomaticStatus,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),["handleOnClick"]
)(Modal_automatic_order_Base)

//************************** Value Formatter & Filter without blank For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}
// >>>>>>> Standart Avg (0.000) Formatter <<<<<<<
const AvgFormatter = (params) => {
    return ConvertToRupiah(params.value, 'avg');
}
// >>>>>>> Standart TimeDate Formatter <<<<<<<
const currencyDateTime = (params) => {
    return ConvertToDateTime(params.value,"v1");
}
// >>>>>>> Standart Time Formatter <<<<<<<
const timeFormatter = (params) => {
    return ConvertToTime(params.value);
}
// >>>>>>> Standart Filter for Aggrid <<<<<<<
function stringComparator(valueA, valueB){
    if(valueA !== null && valueB !== null){
        if(valueA.length < 2){
            return null;
        }else if(valueB.length < 2){
            return null;
        }else{
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    }
}
function integerComparator(valueA, valueB){
    if(valueA == ""){
        return null;
    }else if(valueB == ""){
        return null;
    }else{
        return valueA - valueB;
    }
}
function dateComparator(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return date1;
    }
    if (date1Number === null) {
        return date1;
    }
    if (date2Number === null) {
        return date2;
    }
    return date1Number - date2Number;
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

export {CustomFrameHeaderTrade, Trade,
    OrderbookPage, TradeWatchlist, SettingInWatchlist,
    OrderSetting,SentOrder,TradePL, TradePageAdv, TradeOrderBookList,
    OrderSettingListAgGrid, ExpOrder, 
};
