// system libraries
import React from 'react';

// internal framework libraries
import { AppFrameProvider, AppModal } from './appframe.js';
import { NetAppProvider, WSConnection} from './appnetwork.js';
import { NotificationContainer } from 'react-notifications';

// application-logic libraries
import { BIPSAppProvider } from './AppData.js';

// application-UI-pages goes here
import * as pages from './app_pages';

const initFramePage = [
    /** MY ACCOUNT */
    { className: 'Landing', title: <pages.CustomFrameHeaderLanding />, instanceName: 'landingPage' },
    { className: 'PortfolioPage', title: '', instanceName: 'portfolioPage' },
    { className: 'StockSummaryPage', title: 'STOCK SUMMARY', instanceName: 'stockSummaryPage' },
    { className: 'StockFinancialStatement', title: 'FINANCIAL STATEMENT', instanceName: 'stockFinancialStatement' },
    { className: 'FixedIncomePage', title: 'FIXED INCOME', instanceName: 'fixedIncomePage' },
    { className: 'StockPage', title: 'STOCK', instanceName: 'stockAPage' },
    { className: 'AccountAPage', title: 'ACCOUNT A', instanceName: 'accountAPage' },
    { className: 'AccountBPage', title: 'ACCOUNT B', instanceName: 'accountBPage' },
    //tree 1 In Landing
    { className: 'LandingPage', title: '', instanceName: 'landingPageInvboard' },
    { className: 'StockCash', title: '', instanceName: 'stockCashPageInvboard' },
    { className: 'TradeListHistory', title: '', instanceName: 'tradeListHistoryPageInvboard' },
    { className: 'FundTransfer', title: '', instanceName: 'fundTransferPageInvboard' },
    { className: 'WarrantRight', title: '', instanceName: 'WarrantRightPageInvboard' },
    { className: 'InquryAccount', title: '', instanceName: 'inquryAccountPageInvboard' },
    { className: 'TradePLPage', title: '', instanceName: 'tradePLPageInvboard' },
    // { className: 'tcAndSoa', title: '', instanceName: 'InvboardTcAndSoa' },

    /** MARKET & STATISTIC PAGE */
    { className: 'MarketStatistik', title: <pages.CustomFrameHeaderMarketStatistik />, instanceName: 'marketstatistikPage' },
    //tree 2 In MarketStatistik
    { className: 'MarketStatistikPage', title: '', instanceName: 'marketStatistikPage' },
    { className: 'StatisticMarketStatistikPage', title: '', instanceName: 'statisticMarketStatistikPage' },
    { className: 'NonSectoralStatistikPage', title: '', instanceName: 'nonSectoralStatistikPage' },
    { className: 'IndiceMarketStatistikPage', title: '', instanceName: 'indiceMarketStatistikPage' },
    { className: 'IndiceMarketSecondStatistikPage', title: '', instanceName: 'indiceMarketSecondStatistikPage' },
    { className: 'TopBrokerMarketStatistikPage', title: '', instanceName: 'topBrokerMarketStatistikPage' },
    { className: 'IntIndicesMarketStatistikPage', title: '', instanceName: 'intIndicesMarketStatistikPage' },
    { className: 'CurrencyCommodityMarketStatistikPage', title: '', instanceName: 'currencyCommodityMarketStatistikPage' },
    //tree 3 In MarketStatistik
    { className: 'GeneralNewResearchPage', title: '', instanceName: 'newsGeneral' },
    { className: 'StockNewResearchPage', title: '', instanceName: 'newsStock' },
    { className: 'MutualNewResearchPage', title: '', instanceName: 'newsMutualFund' },

    /** BROKER PAGE */
    { className: 'BrokerPage', title: <pages.CustomFrameHeaderBrokerPage/>, instanceName: 'brokerPage' },
    { className: 'BrokerInfo', title: 'Broker Info', instanceName: 'brokerInfo' },
    { className: 'BrokerTradeSummary', title: 'Broker Trade Summary', instanceName: 'brokerTradeSummary' },
    { className: 'BrokerTradeHistory', title: 'Broker Trade History', instanceName: 'brokerTradeHistory' },
    { className: 'BrokerTopListPage', title: 'TOP Broker', instanceName: 'brokerTopListPage' },

    /** STOCK PAGE */
    { className: 'Stocks', title: <pages.CustomFrameHeaderStock/>, instanceName: 'stockPage' },
    //tree 2 In Stock
    { className: 'StockPage', title: '', instanceName: 'stockInfoPage' },
    { className: 'StockFinancialStatement', title: '', instanceName: 'stockFinancialStatement' },
    { className: 'StockWatchlist', title: '', instanceName: 'stockWatchlistPage' },
    { className: 'StockHistoryPage', title: '', instanceName: 'stockHistoryPage' },
    { className: 'StockTradeSummaryPage', title: '', instanceName: 'stockTradeSummaryPage' },
    { className: 'StockSpecialNotation', title: '', instanceName: 'stockSpecialNotation' },
    { className: 'StockCorpAction', title: '', instanceName: 'stockCorpAction' },
    //tree 3 In Stock
    { className: 'TableStockInfo', title: '', instanceName: 'stockInfoTable' },
    { className: 'TableDailyTechnical', title: '', instanceName: 'dailyTechnicalTable' },

    /** TRADE PAGE */
    { className: 'Trade', title: <pages.CustomFrameHeaderTrade />, instanceName: 'tradePage' },
    // Manual
    { className: 'TradeWatchlist', title: 'WATCHLIST PAGES', instanceName: 'tradePageManOrderbook' },
    { className: 'OrderbookPage', title: 'ORDERBOOK PAGE', instanceName: 'tradePageManWatchlist' },
    { className: 'TradePageAdv', title: 'ADVERTISEMENT LIST', instanceName: 'tradePageAdv' },
    { className: 'TradeOrderBookList', title: 'ORDER BOOKING LIST', instanceName: 'tradePageOrderBookList' },
    // Automatic Order
    { className: 'OrderSetting', title: 'ORDER SETTING', instanceName: 'AutOrderSetting' },
    { className: 'ExpOrder', title: 'EXPIRE AUTOMATIC ORDER', instanceName: 'AutExpOrder' },
    { className: 'SentOrder', title: 'SEND ORDER', instanceName: 'AutSentOrder' },

    /** ANALITIC PAGE */
    { className: 'Analytic', title: <pages.CustomFrameHeaderAnalytic />, instanceName: 'analyticPage' },
    //tree 2 In Analytic
    { className: 'StockAnalyticPage', title: 'STOCK CHART PAGE', instanceName: 'StockAnalyticPage' },
    { className: 'AnalyticPage', title: 'MULTI CHART PAGE', instanceName: 'AnalyticPage' },
    { className: 'IndiceAnalyticPage', title: 'INDICE CHART PAGE', instanceName: 'IndiceAnalyticPage' },
    { className: 'RelativePerformanceAnalyticPage', title: 'RELATIVE PERFORMANCE PAGE', instanceName: 'RelativePerformanceAnalyticPage' },

    /** LIVE TRADE PAGE */
    { className: 'LiveTradePage', title: '', instanceName: 'livetradePage' },

    /** E-SBN PAGE */
    { className: 'Esbn', title: <pages.CustomFrameHeaderEsbn />, instanceName: 'esbnPage' },
    //tree 2 In ESBN
    { className: 'EsbnPage', title: 'REGISTER TO SBN', instanceName: 'EsbnPage' },
    { className: 'PortfolioEsbn', title: 'PORTFOLIO', instanceName: 'EsbnxPagePortfolio' },
    { className: 'TransactionEsbn', title: 'TRANSACTION', instanceName: 'EsbnxPageTransaction' },
    { className: 'HistoricalEsbn', title: 'HISTORICAL SBN', instanceName: 'EsbnxPageHistorical' },
    //tree 3 In ESBN
    { className: 'SubscribeEsbn', title: 'SUBSCRIBE SBN', instanceName: 'EsbnTransSubscribe' },
    { className: 'HistoricalOrderEsbn', title: 'HISTORICAL ORDER', instanceName: 'EsbnTransHistorical' },

    /** MUTUALFUND PAGE */
    { className: 'MutualFund', title: <pages.CustomFrameHeaderMutualFund/>, instanceName: 'mutualfundPage' },
    //tree 2 In Mutual Fund
    { className: 'MutualRegistPage', title: 'REGISTER TO MUTUAL FUND', instanceName: 'MutualRegistPage' },
    { className: 'MutualFundPage', title: 'ETALASE PRODUCT', instanceName: 'MutualFundxPageEtalase' },
    { className: 'MutualFundPageCompare', title: 'COMPARE PRODUCT', instanceName: 'MutualFundxPageCompare' },
    { className: 'MutualFundPagePortfolio', title: 'PORTFOLIO', instanceName: 'MutualFundxPagePortfolio' },
    { className: 'MutualFundPageHistoris', title: 'HISTORIS TRANSAKSI', instanceName: 'MutualFundxPageHistoris' },
    // { className: 'MutualFundPageRoboAdvisor', title: 'ROBO ADVISOR', instanceName: 'MutualFundxPageRoboAdvisor' },
    // { className: 'MutualFundPageBasket', title: 'BASKET', instanceName: 'MutualFundxPageBasket' },
    //tree 3 In Mutual Fund
    // { className: 'MutualFundPageMyBasket', title: 'MY BASKET', instanceName: 'xMutualFundMyBasket' },
    // { className: 'MutualFundPageHistoris', title: 'HISTORIS TRANSAKSI', instanceName: 'xMutualFundHistoryTrans' },

    /** CHAT & SUPPORT PAGE*/
    { className: 'ChatSupportPage', title: <pages.CustomFrameHeaderChatSupportPage />, instanceName: 'chatsupportPage' },
    { className: 'ChatUserPage', title: 'Chat User', instanceName: 'ChatUserPage' },
    { className: 'ChatSuppPage', title: 'Chat Support', instanceName: 'ChatSuppPage' },
    { className: 'ChatCommentPage', title: 'Chat Comment', instanceName: 'ChatCommentPage' },
];

const initTreeData = [
    { // MY ACCOUNT
        name: 'landingPage',
        pages : [
            'landingPageInvboard',
            'stockCashPageInvboard',
            'tradeListHistoryPageInvboard',
            'fundTransferPageInvboard',
            'WarrantRightPageInvboard',
            'inquryAccountPageInvboard',
            'tradePLPageInvboard',
            // 'InvboardTcAndSoa'
        ]
    }, { // MARKET & STATISTIC PAGE
        name: 'marketstatistikPage',
        pages : [
            'marketStatistikPage',
            'statisticMarketStatistikPage',
            'indiceMarketStatistikPage',
            'nonSectoralStatistikPage',
            // 'indiceMarketSecondStatistikPage',
            // 'topBrokerMarketStatistikPage',
            'intIndicesMarketStatistikPage',
            'currencyCommodityMarketStatistikPage',
        ]
    }, { // BROKER PAGE
        name: 'brokerPage',
        pages: [
            // 'brokerInfo',
            'brokerTradeSummary',
            'brokerTradeHistory',
            'brokerTopListPage',
        ]
    }, { // STOCK PAGE
        name: 'stockPage',
        pages : [
            {
                name: 'stockInfoPage',
                pages : [
                    'stockInfoTable',
                    'dailyTechnicalTable',
                ]
            },
            'stockFinancialStatement',
            'stockWatchlistPage',
            'stockHistoryPage',
            'stockTradeSummaryPage',
            'stockSpecialNotation',
            'stockCorpAction',
        ]
    }, { //TRADE PAGE
        name: 'tradePage',
        pages : [
            'tradePageManOrderbook',
            'tradePageManWatchlist',
            'tradePageOrderBookList',
            'tradePageAdv',
            'AutOrderSetting',
            'AutExpOrder',
            'AutSentOrder'
        ]
    }, { // ANALITIC PAGE
        name: 'analyticPage',
        pages: [
            'StockAnalyticPage',
            'AnalyticPage',
            'IndiceAnalyticPage',
            'RelativePerformanceAnalyticPage'
        ]
    },
    'livetradePage',
    { // E-SBN PAGE
        name : 'esbnPage',
        pages : [
            'EsbnPage',
            // 'EsbnxPageTransaction',
            {
                name: 'EsbnxPageTransaction',
                pages : [
                    'EsbnTransSubscribe',
                    'EsbnTransHistorical',
                ]
            },
            'EsbnxPagePortfolio',
            /*'EsbnxPageHistorical'*/
        ]
    },
    { // MUTUAL FUND
        name : 'mutualfundPage',
        pages : [
            'MutualRegistPage',
            'MutualFundxPageEtalase',
            'MutualFundxPageCompare',
            // 'MutualFundxPageRoboAdvisor',
            'MutualFundxPagePortfolio',
            'MutualFundxPageHistoris',
            // {
            //     name :'MutualFundxPageBasket',
            //     pages : [
            //         'xMutualFundMyBasket',
            //         'xMutualFundHistoryTrans'
            //     ]
            // }
        ]
    },
    /*{ // CHAT & SUPPORT PAGE
        name: 'chatsupportPage',
        pages : [
            'ChatUserPage',
            'ChatSuppPage',
            'ChatCommentPage'
        ]
    }*/
]

class App extends React.PureComponent {
    /*
        Important system components:
        WSConnection: web socket connection
    */
    
    render() {
        return (
            <AppFrameProvider 
                initialClasses={ pages }
                initialFrames={ initFramePage }
                treeData={ initTreeData }
                initActions={[ ['switchPage', {instanceName: 'landingPage'}] ]}
            >
                {
                /*
                    Remember that internal-framework Providers (like NetAppProvider) must be put FIRST before
                    application-level Providers because the application-level providers may subscribe to the context of
                    internal-framework Providers
                */
                }
                <NetAppProvider>
                    <BIPSAppProvider>
                        <WSConnection />
                        <WSConnection socketID="aux" />
                        <pages.MainPage />
                        <AppModal />
                        <NotificationContainer/>
                        <pages.PushNoification />
                        <pages.CarouselNotif />
                    </BIPSAppProvider>
                </NetAppProvider>
            </AppFrameProvider>
        )
    }
}

export const NoIframe = () => {
    return (
        <div class="container-fluid text-center mt-9">
            <h1>404</h1>
            <h3>Page Not Found</h3>
        </div>
    )
};

export default App;