import React from 'react';
import WindowSizeListener from 'react-window-size-listener';
import { Popup } from "semantic-ui-react";

// internal framework libraries
import { AppFrame, AppFrameAction } from '../appframe.js';

// application-logic libraries
import { BIPSAppContext, ConvertToRupiah } from '../AppData.js';

// application-common-UI libraries goes here
import UISelectionTab from '../selectiontab.js';
import { ContextConnector } from '../appcontext.js';
import { ResizeResponsive, setElementHeightWeb, setElementLiveZoom, ElementStockHistory, setThemeMode } from '../utils';

// application-UI-pages goes here
import { SideBar } from "../app_layout";
import { LoginUserPage, Disconnect, VerifyPIN } from './index';
import { ModalAlertN, ModalAlert, ModalAlertC, ModalChangePassPin, ModalTrial, ModalLoading } from '../app_modals';

import startConfig from '../appConfig.js';

import $ from 'jquery';
window.$ = window.jQuery = $;

var lastEventTime, nextTimeoutTime, currentTimer, currentPinTime, nextTimeoutPin;

const CustomFrameHeader = (props) => {
    return (
        <div className="bg-black-trading f-12">
            <h2>
                {props.title}
            </h2>
        </div>
    );
}

class MainPage_Base extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state ={
            // reconnect:'belum',
        }
    }

    componentDidMount(){
        var isFirefox = typeof InstallTrigger !== 'undefined';
        if(isFirefox){
            $(".card-448>tr>td").css("padding-top","1vh");
        }

        $(document).ready(function () {
            setElementHeightWeb();
            setElementLiveZoom();
        }).resize();

        $(window).on("resize", function () {
            setElementHeightWeb();
            setElementLiveZoom();
        }).resize();
        
        $(window).on("click", function () {
            ElementStockHistory();
        }).resize();

        // $(window).on("click", function () {
        //     setElementHeightWeb();
        //     setElementLiveZoom();
        // }).resize();
    }

    doLogin = (userID, password) => {
        startConfig()
        this.props.doLogin(userID, password);
    }

    openContentFullscreen = () => {
        /* let elem = document.querySelector('.content'); */
        /* Access the element of "full screen" div: */
        /*const elem = this.fullscreenModal.current;*/
        var elem = document.getElementById("bipsFullscreen");

        /* Interact with it as a normal DOM element: */
        if (elem.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            document.documentElement.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { //Chrome, Safari & Opera
            document.documentElement.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { //IE/Edge
            document.documentElement.msRequestFullscreen();
        }

        ResizeResponsive();

        this.props.handleFullScreenMode()
    }

    closeContentFullscreen = () => {
        /* let elem = document.querySelector('.content'); */
        /* Access the element of "full screen" div: */
        /*const elem = this.fullscreenModal.current;*/

        /* Interact with it as a normal DOM element: */
        if (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            // can use exitFullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }

            ResizeResponsive();
            this.props.handleFullScreenMode()
        } else {
            var elem = document.getElementById("bipsFullscreen");

            /* Interact with it as a normal DOM element: */
            if (elem.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { //Chrome, Safari & Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { //IE/Edge
                document.documentElement.msRequestFullscreen();
            }

            ResizeResponsive();
            this.props.handleFullScreenMode()
        }

    }

    buttonCheckPin = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"></div>,
            contentClass: PINVerify, 
            onClose: (result) => {console.log('Modal 1 result = ', result)},
            size: "mini"
        })
    }
    // Alert Automatic Order
    clickConfirmation = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center"><h3 className="text-center pt-1">Alert Information</h3></div>,
            size: 'mini',
            contentClass: ModalAlert,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    // change password
    buttonChangePassPin = (e)=>{
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-border click-pointer"
                onClick={()=>this.props.handleOnClick("setting","closeModalChangePassword")}></i></div>,
                // onClick={()=>this.refs.frameAction.closeModal(100)}></i></div>,                
            size: 'mini',
            contentClass: ModalChangePassPin,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }
    // modul trail
    clickModalMigrate = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => "",
            size: 'large2',
            contentClass: ModalTrial,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }
        
    startDetection=(e)=>{
        window.addEventListener('mousedown', this.onMouseDownClick)
        this.props.handleOnClick('activeAction','cactivateStatus',true)
        lastEventTime = Date.now()
        nextTimeoutPin = lastEventTime + this.props.activateTimeout.session
        currentPinTime = setTimeout(this.onTimeout, this.props.activateTimeout.session)
    }

    stopDetection = () => clearTimeout(currentPinTime);

    onMouseDownClick=(e)=>{
        lastEventTime = Date.now()
        if (this.props.activateTimeout.session !== 0 && (nextTimeoutPin - lastEventTime) < this.props.activateTimeout.session) {
            if (currentPinTime)
                clearTimeout(currentPinTime)
            nextTimeoutPin = lastEventTime + this.props.activateTimeout.session;
            currentPinTime = setTimeout(this.onTimeout, this.props.activateTimeout.session);
        }        
    }

    // set refresh Pin
    onTimeout=(e)=>{
        if(nextTimeoutPin)
            clearTimeout(nextTimeoutPin)
        if(nextTimeoutPin - lastEventTime >= this.props.activateTimeout.session){
            // setTimeout logout otomatis
            nextTimeoutTime = lastEventTime + this.props.activateTimeout.logout
            currentTimer = setTimeout(this.onTimeout2, this.props.activateTimeout.logout) 

            this.props.setDataHandler({"refreshPin": true, "verifyPinStatus": false})
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"></div>,
                contentClass: PINVerify, 
                onClose: (result) => {
                    this.props.setDataHandler({"refreshPin": false})
                    clearTimeout(currentTimer)
                },
                size: "mini"
            })
        } else {
            var currentTimeoutTime = nextTimeoutPin
            nextTimeoutPin = lastEventTime + this.props.activateTimeout.session
            currentPinTime = setTimeout(this.onTimeout, nextTimeoutPin - currentTimeoutTime)
        }
    }

    // set logout
    onTimeout2 = (e)=>{
        if (currentTimer)
            clearTimeout(currentTimer)
        if (nextTimeoutTime - lastEventTime >= this.props.activateTimeout.logout) {
            console.log('TIMEOUT DETECTED')
            this.props.handleLogout()
        } else {
            var currentTimeoutTime = nextTimeoutTime
            nextTimeoutTime = lastEventTime + this.props.activateTimeout.logout
            currentTimer = setTimeout(this.onTimeout2, nextTimeoutTime - currentTimeoutTime)
        }        
    }

    render () {
        setThemeMode(this.props.thememode);
        var props = this.props;
        
        return (
            <>
                <div>
                    <div style={{display: !props.networkState ? "block" : "none"}}>
                        <h1><Disconnect /></h1>
                    </div>
                    <div id="login-state" style={{display: props.networkState && !props.loginState ? "block" : "none"}}>
                        <LoginUserPage onLogin={this.doLogin}/>
                    </div>
                    <div style={{display: props.loginState ? "block" : "none"}}>
                        <AppFrameAction ref="frameAction" />
                        {/* pin verification & changePassPin */}
                        <button id="bActivate" onClick={this.startDetection} className="hidden">bactive</button>
                        <button id="bDeActivate" onClick={this.stopDetection} className="hidden">bDeactive</button>
                        <button id="pin-click-verification" onClick={this.buttonCheckPin} className="hidden">Show First Modal</button>
                        <button id="change-passpin-verification" onClick={this.buttonChangePassPin} className="hidden">Show First change</button>
                        <span onClick={this.clickModalMigrate} id={"triggerMigrate"}></span>
                        <span onClick={this.clickConfirmation} id={"triggerAlert"}></span>
                        
                        {/* sweat Alert */}
                        <ModalAlertN />
                        <ModalAlertC />
                        <ModalLoading />
                        {/* header */}
                        <WindowSizeListener onResize={(windowSize) => this.props.handleResize(windowSize)} >
                            <UISelectionTab treeName="/" linkTitles={
                                {
                                    landingPage:
                                        <Popup content='MY ACCOUNT'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center padding-t5">
                                                    <i className="icon-icon-investment-board fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips">MY ACCOUNT</span>
                                                </div>
                                            } />,
                                    marketstatistikPage:
                                        <Popup content='MARKET & STATISTIC'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center">
                                                    <i className="icon-icon-market-statistic fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-6">MARKET &</span><br/>
                                                    <span className="fs-text-bips">STATISTIC</span>
                                                </div>
                                            } />,
                                    brokerPage:
                                        <div className="text-align-center">
                                            <i className="iconbi-broker-icon fs-icon-bips"></i> <br/><br/>
                                            <span className="fs-text-bips padding-11">BROKER</span><br/>
                                        </div>
                                        ,
                                    stockPage:
                                        <Popup content='STOCK'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center padding-t5">
                                                    <i className="icon-icon-stock-page fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-15">
                                                        STOCK
                                                    </span>
                                                </div>
                                                } />,
                                    tradePage:
                                        <Popup content='TRADE'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center padding-t5">
                                                    <i className="icon-icon-trade-page fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-15">
                                                        TRADE
                                                    </span>
                                                </div>
                                            } />,
                                    analyticPage:
                                        <Popup content='ANALYTIC'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center padding-t5">
                                                    <i className="icon-icon-analytic_page fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-7">ANALYTIC</span>
                                                </div>
                                            } />,
                                    livetradePage:
                                        <Popup content='LIVE TRADE'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center">
                                                    <i className="icon-icon-live-trade fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-21">LIVE</span><br/>
                                                    <span className="fs-text-bips">TRADE</span>
                                                </div>
                                            } />,
                                    esbnPage:
                                        <Popup content='E-SBN'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center padding-t5">
                                                    <i className="icon-icon-fund fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-15">
                                                        E-SBN
                                                    </span>
                                                </div>
                                            } />,
                                    mutualfundPage:
                                        <Popup content='MUTUAL FUND'
                                            mouseEnterDelay={900}
                                            mouseLeaveDelay={100}
                                            position='bottom center'
                                            trigger={
                                                <div className="text-align-center">
                                                    <i className="icon-icon-inquiry fs-icon-bips"></i> <br/><br/>
                                                    <span className="fs-text-bips padding-11">MUTUAL</span><br/>
                                                    <span className="fs-text-bips">FUND</span>
                                                </div>
                                            } />,
                                }
                            }/>

                            <div className="d-sidebar-landscape">
                                {/* <MarqueePage /> */}
                                <MarqueeMac />
                            </div>
                            <div className="col-sm-12 px-0 mx-0 card-575">
                                <SideBar/>
                                <div className="col-sm-contentbar px-0 mx-0 d-border-top d-border-bottom card-575">
                                    <AppFrame treeName="/" headerComponent={CustomFrameHeader}/>
                                </div>
                            </div>
                            <div className="d-sidebar-potrait">
                                {/* <MarqueePage /> */}
                            <MarqueeMac />
                            </div>
                            {
                                ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) ? '' :
                                    <i onClick={this.props.fullscreenmode == false ? this.openContentFullscreen : this.closeContentFullscreen}
                                    className={this.props.fullscreenmode == false ? "icon-icon-fullscreen-in myBtn" : "icon-exit-fullscreen myBtn"}></i>
                            }
                        </WindowSizeListener>
                    </div>
                </div>
            </>
        );
    }
}

class MarqueeMac_Base extends React.PureComponent{
    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 5000);
    }

    tick() { this.props.timerFooterHandler() }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render(){
        var p = this.props
        const colorLabelFront = (props) => {
            if(props < 0){
                return "bg-red-dark-grad"
            }if(props > 0){
                return "bg-green-dark-grad"
            }else{
                return "bg-yellow-red-grad"
            }
        }
        const colorIcon = (props) => {
            if(props < 0){
                return "icofont icofont-caret-down"
            }if(props > 0){
                return "icofont icofont-caret-up"
            }else{
                return ""
            }
        }
        return(
            <div className="h-32 runningTextMac">
                <div className={"slidee slideSatu"}>
                    <div className={"show-box1 " +colorLabelFront(p.boxReadyFooter[0].change)}>
                        <kbd>{p.boxReadyFooter[0].symbol}</kbd>&nbsp;                        
                        {
                            p.boxReadyFooter[0].last == 0 ? <i className="icofont icofont-minus"></i> :
                                <>
                                    <text
                                        className={" "}>
                                        {ConvertToRupiah(p.boxReadyFooter[0].last)} &nbsp;
                                    </text>
                                    <i className={colorIcon(p.boxReadyFooter[0].change)}></i>
                                    {p.boxReadyFooter[0].change}&nbsp;({p.boxReadyFooter[0].percentage}%) &nbsp;
                                </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[7].change)+" "+(p.flippedFooterStatus ===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[7].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[7].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[7].last == 0 ? '-' :p.boxReadyFooter[7].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[7].change)}></i>
                                {p.boxReadyFooter[7].change}&nbsp;({p.boxReadyFooter[7].percentage}%) &nbsp;
                            </>
                        }
                        
                    </div>
                </div>
                <div className={"slidee slideDua"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[1].change )}>
                        <kbd>{p.boxReadyFooter[1].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[1].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[1].last == 0 ? '-' :p.boxReadyFooter[1].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[1].change)}></i>
                                {p.boxReadyFooter[1].change}&nbsp;({p.boxReadyFooter[1].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[8].change )+" "+(p.flippedFooterStatus ===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[8].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[8].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[8].last == 0 ? '-' :p.boxReadyFooter[8].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[8].change)}></i>
                                {p.boxReadyFooter[8].change}&nbsp;({p.boxReadyFooter[8].percentage}%) &nbsp;
                            </>
                        }                            
                    </div>
                </div>
                <div className={"slidee slideTiga"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[2].change)}>
                        <kbd>{p.boxReadyFooter[2].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[2].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                className={" "}>
                                {ConvertToRupiah(p.boxReadyFooter[2].last == 0 ? '-' :p.boxReadyFooter[2].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[2].change)}></i>
                                {p.boxReadyFooter[2].change}&nbsp;({p.boxReadyFooter[2].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[9].change)+" "+(p.flippedFooterStatus ===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[9].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[9].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[9].last == 0 ? '-' :p.boxReadyFooter[9].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[9].change)}></i>
                                {p.boxReadyFooter[9].change}&nbsp;({p.boxReadyFooter[9].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                </div>
                <div className={"slidee slideEmpat"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[3].change)}>
                        <kbd>{p.boxReadyFooter[3].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[3].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[3].last == 0 ? '-' :p.boxReadyFooter[3].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[3].change)}></i>
                                {p.boxReadyFooter[3].change}&nbsp;({p.boxReadyFooter[3].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[10].change)+" "+(p.flippedFooterStatus ===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[10].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[10].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[10].last == 0 ? '-' :p.boxReadyFooter[10].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[10].change)}></i>
                                {p.boxReadyFooter[10].change}&nbsp;({p.boxReadyFooter[10].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                </div>
                <div className={"slidee slideLima"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[4].change)}>
                        <kbd>{p.boxReadyFooter[4].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[4].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[4].last == 0 ? '-' :p.boxReadyFooter[4].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[4].change)}></i>
                                {p.boxReadyFooter[4].change}&nbsp;({p.boxReadyFooter[4].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[11].change)+" "+(p.flippedFooterStatus ===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[11].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[11].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[11].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[11].change)}></i>
                                {p.boxReadyFooter[11].change}&nbsp;({p.boxReadyFooter[11].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                </div>
                <div className={"slidee slideEnam"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[5].change)}>
                        <kbd>{p.boxReadyFooter[5].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[5].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[5].last == 0 ? '-' :p.boxReadyFooter[5].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[5].change)}></i>
                                {p.boxReadyFooter[5].change}&nbsp;({p.boxReadyFooter[5].percentage}%) &nbsp;
                            </> 
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[12].change)+" "+(p.flippedFooterStatus===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[12].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[12].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[12].last == 0 ? '-' : p.boxReadyFooter[12].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[12].change)}></i>
                                {p.boxReadyFooter[12].change}&nbsp;({p.boxReadyFooter[12].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                </div>
                <div className={"slidee slideTujuh"}>
                    <div className={"show-box " +colorLabelFront(p.boxReadyFooter[6].change)}>
                        <kbd>{p.boxReadyFooter[6].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[6].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[6].last == 0 ? '-' :p.boxReadyFooter[6].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[6].change)}></i>
                                {p.boxReadyFooter[6].change}&nbsp;({p.boxReadyFooter[6].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                    <div className={"hid-box "+colorLabelFront(p.boxReadyFooter[13].change)+" "+(p.flippedFooterStatus===true ? 'active' : '')}>
                        <kbd>{p.boxReadyFooter[13].symbol}</kbd>&nbsp;
                        {
                            p.boxReadyFooter[13].last == 0 ?  <i className="icofont icofont-minus"></i> :
                            <>
                                <text
                                    className={" "}>
                                    {ConvertToRupiah(p.boxReadyFooter[13].last == 0 ? '-' : p.boxReadyFooter[13].last)} &nbsp;
                                </text>
                                <i className={colorIcon(p.boxReadyFooter[13].change)}></i>
                                {p.boxReadyFooter[13].change}&nbsp;({p.boxReadyFooter[13].percentage}%) &nbsp;
                            </>
                        }
                    </div>
                </div>
            </div>
            )
    }
}

class PINVerify_Base extends React.PureComponent {

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'checkPinAja'/>
            </>
        );
    }
}

//************************** Context Connector **************************
const MainPage = ContextConnector(BIPSAppContext, 
    (vars, actions) => ({
        loginState: vars.loginState,
        networkState: vars.networkState,
        fullscreenmode:vars.fullscreenmode,
        thememode : vars.thememode,
        activateTimeout:vars.activateTimeout,

        doLogin: (userID, password) => {actions.sendAction('doLogin', {userID, password})},
        handleResize:(size) => actions.sendAction('handleResize',{size}),
        handleFullScreenMode:() => actions.sendAction('handleFullScreenMode'),
        handleOnClick : (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type,data}),  
        handleLogout:() => actions.sendAction('handleLogout'),
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}), 
    }), ["doLogin", "setDataHandler", "handleLogout", "handleResize","handleFullScreenMode", "handleOnClick"]
)(MainPage_Base);

const MarqueeMac = ContextConnector(BIPSAppContext, 
    (vars, actions) => ({
        boxReadyFooter:vars.boxReadyFooter,
        flippedFooterStatus:vars.flippedFooterStatus,
        timerFooterHandler:()=>actions.sendAction('timerFooterHandler'),
    }), 
    ["timerFooterHandler"]
)(MarqueeMac_Base);

const PINVerify = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState
    }),
)(PINVerify_Base)

export default MainPage;