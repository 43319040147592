import { ShareToLot, mathCall } from './generalFunction';
import { AperdFundType, fieldName } from './initialData';
import { fmsg } from './MessageFormat';
import { addRowEmpty } from './aggridSetting';
import { dummy } from './dummy';


/** TRADING STATUS */
// dataRef = {status: "3", msg: "Begin first session "} / ["UPDATE", "TRDSTATUS","8","Begin Pre-Opening"]
function GetTradingStatus(data){
    let status = data.status || data[2];
    let msg = data.msg || data[3];
    let tStatus = {
        '1': 'Closed', 
        '2': msg,
        '3': "First Session",
        '4': "Break",
        '5': "Second Session",
        '6': "Trade Dissemination",
        '8': "Pre-Opening",
        '7': 'Closed',
        '9': "End Preopening",
        'a': "Pre-Closing",
        'b': 'End pre-closing',
        'c': "Post-trading",
        'd': 'Closed',
        'e': "Trading Suspension",
        'f': "Trading Activation",
        'g': "Board Suspension",
        'h': "Board Activation",
        'i': "Instrument Suspension",
        'j': "Instrument Activation",
        'k': "Market Suspension",
        'l': "Market Activation"
    }
    return tStatus[status] || msg;
}

/** STATUS */
function GetOrderbookStatus (strading, scode = false, stockData={}){
    let { board_pos="" } = stockData;
    if(board_pos.includes("Watch-list") || (scode && strading == "Pre-Opening")){
        return false
    }else{
        let idx = ["Break", "Pre-Opening", "Trade Dissemination", "End Preopening", "Closed"].findIndex(s => s === strading)
        return idx === -1 ? false : true;
    }
}
// note: USER PROFILE
function GetUserProfile(data){
    let { custstatus="", custname, cbaccname, bncode, cbaccno, cbbranch, email } = data;
    let mapingStatus  = {
        "A": {statusName: "ACTIVE", erAlert: ""},
        "B": {statusName: "SUSPEND BUY", erAlert: "Buy Order is not permitted"},
        "S": {statusName: "SUSPEND SELL", erAlert: "Sell Order is not permitted"},
        "T": {statusName: "SUSPEND", erAlert: "Buy / Sell Order is not permitted"},
        "M": {statusName: "TRIAL", erAlert: "Buy / Sell Order is not permitted"},
        "W": {statusName: "TRIAL", erAlert: "Buy / Sell Order is not permitted"},
    };    
    let { statusName=custstatus, erAlert="" } = mapingStatus[custstatus] || {};
    return {
        "user_name": custname,
        "bank_user": cbaccname,
        "bank_code": bncode,
        "bank_account": cbaccno,
        "bank_name": bncode,
        "bank_branch": cbbranch,
        "email": email,
        "status": statusName,
        "code_status": custstatus,
        erAlert,      
    }
}
function GetStockProfile(stock=[], listHc=[], listPrice=[], debug="init"){
    let z =[], cekprice, haircut, lastprice, mktVal, lqVal, pl, persenPl;
    stock.map(item => {
        cekprice = listPrice.find(d => d.stock_code == item.stockcode) || {close_price: 0, prev_price: 0};
        haircut = getHaircut(listHc, item.stockcode)/100; 
        lastprice = cekprice.close_price == 0 ? cekprice.prev_price : cekprice.close_price;
        mktVal = item.totalqty * lastprice;
        lqVal = (1-haircut) * mktVal;
        pl = (lastprice - item.averageprice) * item.totalqty;
        persenPl =  (lastprice - item.averageprice) / item.averageprice;      
        z.push({...item, lastprice, mktVal, lqVal, haircut, pl, persenPl});
    });
    // console.log(`DEBUGGG: >> ${debug} <<`, ' Z', z, z.reduce((a, b) => a + b.lqVal, 0) )
    return z
}

/** ============= WATCHLIST CODE [sidebar, modal myWatchlist] ============= */
function cekCrudWatchlist(user, oldData, newData){
    let message = [];
    newData.map(nd => { //insert & update group
        let cdata = oldData.find(od => od.group_name === nd.group_name);
        if(cdata){
            if(nd.stocks.length === 0){
                message.push(fmsg.x_deleteWatchlist(user, nd.group_name))
            }else{
                let cLeng = nd.stocks.length !== cdata.stocks.length;
                let cCode = cdata.stocks.filter(st => nd.stocks.indexOf(st) == -1).length;
                (cLeng || cCode > 0) && message.push(fmsg.x_updateWatchlist(user, nd.group_name, nd.stocks));
            }
        }else{
            message.push(fmsg.x_insertWatchlist(user, nd.group_name, nd.stocks));
        }
    });
    oldData.map(od => { // delete group
        let cdata = newData.some(nd => nd.group_name === od.group_name);
        !(cdata) && message.push(fmsg.x_deleteWatchlist(user, od.group_name));
    });
    return message;
}

/** ============= INVESTMENT BOARD ============= */
const OpenExercise = (data) => ({
    id: data.exrid,
    tipe: "CANCEL",
    data: [
        {label: "Code", type: "text", value: data.right_code},
        {label: "Excersice Price", type: "nom", value: data.price},
        {label: "Excersice Quantity", type: "nom", value: data.quantity_right},
        {label: "Excersice Value", type: "nom", value: data.value},
        {label: "Excersice Fee", type: "nom", value: data.fee},
        {label: "Total Amount", type: "nom", value: data.amount},
    ],
    params: {"exr_id": data.exrid}
});

const CreateExercise = data => ({
    id: Math.random(),
    tipe: "EXERCISE",
    data: [
        {label: "Code", type: "text", value: `${data.asset_code} [${data.saham}]`},
        {label: "Exercise Ratio", type: "nom", value: data.ratio_right},                    
        {label: "Excersice Price", type: "nom", value: data.price},
        {label: "Excersice Quantity", type: "nom", value: data.qty},
        {label: "Excersice Value", type: "nom", value: data.e_value},
        {label: "Excersice Fee", type: "nom", value: data.fee},
        {label: "Total Amount", type: "nom", value: data.amount},
        {label: "Available Cash", type: "nom", value: data.a_cash},
    ],
    params: {
        "ca_id": data.caid,
        "qty": Number(data.qty),
        "right_code": data.right_code
    }
});

const SetExerciseList = data => {
    let ndata = data.map((d, id)=>({ ...d,
        no: id+1,
        reqdate: d.request_date,
        value: d.price * d.quantity_stock,
        fee:  d.price * d.quantity_stock * d.fee,
        status: d.status === "N" ? "New" : (d.status === "P" ? "Proses" : 
            (d.status === "X" ? "Canceled" : (d.status === "R" ? "Rejected" : "Completed")))
    }));
    return addRowEmpty("card-381", ndata);
}

function GetSCSettlement(type, data, nset){
    if(type == "get"){
        let rec0 = Number(data.rec0)
        let pay0 = Number(data.pay0)
        let setRec0 = rec0 - pay0
        let total0 = Number(data.cashonhand) + Number(setRec0) - Number(data.penalty) - Number(data.fundtrans0)
        let setRec1 = Number(data.rec1)-Number(data.pay1)
        let total1 = Number(total0) + Number(setRec1) - Number(data.penalty1) - Number(data.fundtrans1)
        let npay2 = Number(data.pay2)
        let nrec2 =Number(data.rec2)
        let ntaxfee2 = (((nset.custfee/100)*npay2) + (((nset.custfee + 0.1)/100) * nrec2)).toFixed(2)
        let setRec2 = nrec2-npay2-ntaxfee2
        let total2 = Number(total1) + Number(setRec2) - Number(data.penalty2) - Number(data.fundtrans2)
        
        return [
            {"label": "Receiveable", "t0": rec0, "t1": data.rec1, "t2": nrec2},
            {"label": "Payable", "t0": pay0, "t1": data.pay1, "t2": npay2},
            {"label": "Tax + Fee", "t0": 0, "t1": 0, "t2": ntaxfee2},
            {"label": "Penalty", "t0": data.penalty, "t1": data.penalty1, "t2": data.penalty2},
            {"label": "Settlement Amount", "t0": setRec0, "t1": setRec1, "t2": setRec2},
            {"label": "Cash Balance", "t0": data.cashonhand, "t1": total0, "t2": total1},
            {"label": "Fund Transfer + Exercise + Mutual fund", "t0": data.fundtrans0, "t1": data.fundtrans1, "t2": data.fundtrans2},
            {"label": "Total", "t0": total0, "t1": total1, "t2": total2},
        ]
    }else{
        let rec0 = data[0].t0+Number(nset.receivable0)
        let pay0 = data[1].t0+Number(nset.payable0)
        let setRec0 = rec0 - pay0 - Number(nset.taxfee0)
        let total0 = data[5].t0 + Number(setRec0) - data[3].t0 - Number(data[6].t0)
        let setRec1 = data[0].t1 - data[1].t1
        let total1 = total0 + Number(setRec1) - data[3].t1 - Number(data[6].t1)
        let npay2 = Number(nset.payable2) == 0 ? data[1].t2 : Number(nset.payable2)
        let nrec2 = Number(nset.receivable2) == 0 ? data[0].t2 : Number(nset.receivable2)
        let ntaxfee2 = Number(nset.taxfee2) /** (((nset.custfee/100)*npay2) + (((nset.custfee + 0.1)/100) * nrec2)).toFixed(0) */
        let setRec2 = nrec2-npay2-ntaxfee2
        let total2 = total1 + Number(setRec2) - data[3].t2 - Number(data[6].t2)

        return [
            {"label": "Receiveable", "t0": rec0, "t1": data[0].t1, "t2": nrec2},
            {"label": "Payable", "t0": pay0, "t1": data[1].t1, "t2": npay2},
            {"label": "Tax + Fee", "t0": nset.taxfee0, "t1": 0, "t2": ntaxfee2},
            {"label": "Penalty", "t0": data[3].t0, "t1": data[3].t1, "t2": data[3].t2},
            {"label": "Settlement Amount", "t0": setRec0, "t1": setRec1, "t2": setRec2},
            {"label": "Cash Balance", "t0": data[5].t0, "t1": total0, "t2": total1},
            {"label": "Fund Transfer + Exercise + Mutual fund", "t0": data[6].t0, "t1": data[6].t1, "t2": data[6].t2},
            {"label": "Total", "t0": total0, "t1": total1, "t2": total2},
        ]
    }
}
// TRADE PL
const GetTradePL = (data) => {
    let { stock_code=[], buy_amount, buy_vol, sell_amount, sell_vol, avg_sell_price, acc_buy_amount, acc_avg_buy, pl, pl_perc } = data;
    let tradePl = [];
    stock_code.map(item => Number(sell_amount[item]) != 0 && tradePl.push({
        "stock": item,
        "buy_amount": buy_amount[item] || 0,
        "buy_vol": buy_vol[item] || 0,
        "sell_amount": sell_amount[item] || 0,
        "sell_vol":sell_vol[item] || 0,
        "sell_avg_price": avg_sell_price[item] || 0,
        "accumulation_amount": acc_buy_amount[item] || 0,
        "accumulation_avg_price": acc_avg_buy[item] || 0,
        "pl": pl[item] || 0,
        "pl_percentage": pl_perc[item] || 0
    }));

    let sumModalBuy = tradePl.reduce((a, b) => a + (Number(b.sell_vol)*Number(b.accumulation_avg_price)), 0);
    let salesPl = tradePl.reduce((a, b) => a + Number(b.pl), 0);
    let tradePlInfo = {
        sellAmount: tradePl.reduce((a, b) => a + Number(b.sell_amount), 0), 
        buyAmount: tradePl.reduce((a, b) => a + Number(b.buy_amount), 0), 
        salesPl: salesPl.toFixed(2), 
        perSalesPl: tradePl.length > 0 ? ((salesPl/sumModalBuy)*100).toFixed(2) : 0
    }
    return {tradePl: addRowEmpty("card-tradePL", tradePl), tradePlInfo};
}

/** ============= MARKET & STATISTIC ============= */
const TOPGAL = [
    "TOP_ACTIVE_VAL", "TOP_ACTIVE_VOL", "TOP_ACTIVE_FREQ", 
    "TOP_GAINER_VAL", "TOP_GAINER_PCTS", 
    "TOP_LOSER_VAL", "TOP_LOSER_PCTS"
];

// summary Table: a.top Gainer, b.top Active, c.top Loser
function GetTopGAL(data, type, sub_type){
    let jrow = {[type]: type !== "card-202" ? "card-478" : "card-202"};
    let ndata = Object.values(data).map(d => ({ ...d,
        traded_val: d.traded_val * 1,
        traded_vol_lot: (d.traded_vol * 1/100),
        persen: Number(d.prev_price) === 0 ? "0.00" : (d.change_price*100/d.prev_price).toFixed(2),
        avg: Number(d.traded_vol) != 0 ? ((d.traded_val * 1)/(d.traded_vol * 1)).toFixed(4) : '0.0000',
        fnet: (d.foreign_buy_val * 1) - (d.foreign_sell_val * 1) 
    }));
    let z;
    if(type === "topgainers"){
        z = ndata.filter(d => Number(d.change_price) > 0);
        sub_type === "TOP_GAINER_VAL" && z.sort((a, b) => b.traded_val - a.traded_val);
    } else if(type === "toplosers"){
        z = ndata.filter(d => Number(d.change_price) <= 0);
        sub_type === "TOP_LOSER_VAL" && z.sort((a, b) => b.traded_val - a.traded_val);
    } else {
        z = ndata;
    }

    return addRowEmpty(jrow[type], z);
}

const SetSectoralIndex = (data=[]) => {
    let ndata = data.reduce((acc, cur) => ({ ...acc, [cur]: {emptyRow: ""} }), {})
    return ndata;
}

// SECTORAL INDEX
const GetIndexSummary = (type, data) => {
    if(type === 'subscription'){
        let z = {};
        fieldName.index.map((item, id) => {
            z = {...z, [item]: data[id+2]}
        });
        data = z;
    }
    let change = (Number(data.index) - Number(data.prev_index)).toFixed(2)
    let persen = (change*100/Number(data.prev_index)).toFixed(2)
    return { ...data, change, persen, 
        sector: data.index_code,
        vol: ShareToLot(data.vol),
        fnet: Number(data.f_buy_val) - Number(data.f_sell_val)
    }
}
// GET INTERNATIONAL INDEX
function GetIntIndex (data){
    let z = Object.keys(data).map(d => ({symbol: d, name: data[d]}) );
    return addRowEmpty("card-intIndices", z);
}
// GET INDICES FUTURES
function GetIntIndexFutures(data) {
    let i = 0, x = Object.keys(data), l = x.length, 
    z = [];
    for (i; i < l; i++){
        z.push({index: x[i], month: "", last: "", high: "", low: "", change: "", changePercent: "", time: ""})
    }
    return addRowEmpty("card-intIndices", z);
}
// GET CURRENCIES
function GetCurrencies (data){
    let x = Object.keys(data);
    let y = Object.values(data);
    let xleng = x.length, z = [], i = 0;
    for (i; i < xleng; i++){
        z.push({ other: x[i], name: y[i]})
    }
    return z;
}

/** ============= BROKER ============= */
/** BROKER TRADE HISTORY
 * fieldDataInput = {buy: [stock, buy_vol, buy_val], sell: [stock, sell_vol, sell_val]}
 * fielDataOutput = {History: [{stock, sell_vol, sell_val, avg_sell, avg_buy, net_vol, net_val}, HisInfo: {tBuyVal, tSellVal, tVal}]
 */
function GetBrokerTradeHistory(data = {buy: [], sell: []}){
    let z = [], tBuyVal=0, tSellVal=0;
    data.buy.forEach((item) => {
        let i = z.findIndex(o => o.stock === item.stock);
        tBuyVal += item.buy_val;
        if(i <= -1){
            z.push({ ...item, sell_vol: 0, sell_val: 0, avg_sell: 0,
                avg_buy: item.buy_val/item.buy_vol,
                net_vol: item.buy_vol,
                net_val: item.buy_val,
            });
        }else{
            z[i] = {...z[i], 
                buy_vol: item.buy_vol, 
                buy_val: item.buy_val, 
                avg_buy: item.buy_val/item.buy_vol,
                net_vol: item.buy_vol - z[i].sellVol,
                net_val: item.buy_val - z[i].sellVal
            }
        }
    })
    data.sell.forEach((item) => {
        let i = z.findIndex(o => o.stock === item.stock);
        tSellVal += item.sell_val;
        if(i <= -1){
            z.push({ ...item, buy_vol: 0, buy_val: 0, avg_buy: 0,
                avg_sell: item.sell_val/item.sell_vol,
                net_vol: -(item.sell_vol),
                net_val: -(item.sell_val)
            });
        }else{
            z[i] = { ...z[i], 
                sell_vol: item.sell_vol,
                sell_val: item.sell_val,
                avg_sell: item.sell_val/item.sell_vol,
                net_vol: z[i].buy_vol - item.sell_vol,
                net_val: z[i].buy_val - item.sell_val
            }
        }
    });
    return {
        brokerTradeHistory: addRowEmpty('card-brokerInfo', z, 27),
        brokerTradeHisInfo: { tBuyVal, tSellVal, tVal: tBuyVal + tSellVal }
    }
}

// SubHeader 4 : Top Broker
// data format for Top Broker : a.Top Broker, b.Top Broker Buyer, c.Top Broker Seller
// T.val, B.val, S.val : in Billion units  T.vol(Share)
function GetTopBroker(data){
    let ndata = Object.values(data).map(d => ({ ...d,
        total_val: Number(d.total_val)/1000,
        buy_val: Number(d.buy_val)/1000,
        total_vol: ShareToLot(d.total_vol),
        sell_val: Number(d.sell_val)/1000
    }));
    return addRowEmpty("card-138", ndata);
} 

/** ============= STOCK PAGE ============= */
const getStockSMRY = (data) => {
    let z = {};
    fieldName.stocksmry.map((item, id) => {
        z = {...z, [item]: data[id+2]}
    });
    return { ...z, change_price_pcts: Number(z.change_price_pcts).toFixed(2)}
}

function GetSpecialNotation(newData, lastData) {
    let ndata, fLastData = lastData.filter(d => d.code).map(d => d.code);
    if(fLastData.length === 0){
        ndata = Object.keys(newData).map(d => ({
            company: newData[d], code: d, notation: "", board: "", notationDes: ""
        }))
    }else{
        let cekData = Object.keys(newData).filter(d => 
            (fLastData.indexOf(d) < 0)).map(d => ({
                company: newData[d], code: d, notation: "", board: "", notationDes: ""
            })
        );
        ndata = [ ...lastData, ...cekData]
    }
    return ndata;
}

function GetStockFinancialReport(data, lastData, thn){
    data.sort((a, b) => a.quarter - b.quarter);
    let { NUM_BVPS = "", NUM_EPS = "", NUM_PER = "", NUM_PBV = "" } =  lastData.length > 0 ? lastData[0].report_data : {};
    let nlastStat = lastData.length > 0 ? mathCall.setOrdinalNum(lastData[0].quarter)+" Qtr "+lastData[0].year : "\xa0";
    let ndata = data.map(d => ({ ...d.report_data,
        QTR: mathCall.setOrdinalNum(d.quarter) + " Qtr " + (d.year || thn),
        EQUITY: d.report_data.EQUITY_SHOLDER - d.report_data.EQUITY_MINOR,
    }));
    let diff = 4 - data.length;
    if(diff >= 1){
        for(let j=0; j<diff; j++){
            ndata.push({})
        }
    }
    return [
        { label: "Last Statement", v1: "Book Value", v2: "EPS", v3: "PER", v4: "PBV" },
        { label: nlastStat, v1: NUM_BVPS, v2: NUM_EPS, v3: NUM_PER, v4: NUM_PBV },
        { label: "Quarter/Year", v1: ndata[0].QTR || "", v2: ndata[1].QTR || "", v3: ndata[2].QTR || "", v4: ndata[3].QTR || "" },
        { label: "Sales Revenue", v1: ndata[0].PNL_SALES || "", v2: ndata[1].PNL_SALES || "", v3: ndata[2].PNL_SALES || "", v4: ndata[3].PNL_SALES || "" },
        { label: "Gross Profit", v1: ndata[0].PNL_GROSS || "", v2: ndata[1].PNL_GROSS || "", v3: ndata[2].PNL_GROSS || "", v4: ndata[3].PNL_GROSS || "" },
        { label: "EBITDA", v1: ndata[0].PNL_EBITDA || "", v2: ndata[1].PNL_EBITDA || "", v3: ndata[2].PNL_EBITDA || "", v4: ndata[3].PNL_EBITDA || "" },
        { label: "NET Income", v1: ndata[0].PNL_NET || "", v2: ndata[1].PNL_NET || "", v3: ndata[2].PNL_NET || "", v4: ndata[3].PNL_NET || "" },
        { label: "EPS", v1: ndata[0].NUM_EPS || "", v2: ndata[1].NUM_EPS || "", v3: ndata[2].NUM_EPS || "", v4: ndata[3].NUM_EPS || "" },
        { label: "colspan", v1: "", v2: "", v3: "", v4: "" },
        { label: "Total Asset", v1: ndata[0].ASSET_TOTAL || "", v2: ndata[1].ASSET_TOTAL || "", v3: ndata[2].ASSET_TOTAL || "", v4: ndata[3].ASSET_TOTAL || "" },
        { label: "Total Liabilities", v1: ndata[0].LIAB_TOTAL || "", v2: ndata[1].LIAB_TOTAL || "", v3: ndata[2].LIAB_TOTAL || "", v4: ndata[3].LIAB_TOTAL || "" },
        { label: "Total Equity", v1: ndata[0].EQUITY || "", v2: ndata[1].EQUITY || "", v3: ndata[2].EQUITY || "", v4: ndata[3].EQUITY || "" },
        { label: "colspan", v1: "", v2: "", v3: "", v4: "" },
        { label: "PER", v1: ndata[0].NUM_PER || "", v2: ndata[1].NUM_PER || "", v3: ndata[2].NUM_PER || "", v4: ndata[3].NUM_PER || "" },
        { label: "PBV", v1: ndata[0].NUM_PBV || "", v2: ndata[1].NUM_PBV || "", v3: ndata[2].NUM_PBV || "", v4: ndata[3].NUM_PBV || "" },
        { label: "ROA", v1: ndata[0].R_ROA || "", v2: ndata[1].R_ROA || "", v3: ndata[2].R_ROA || "", v4: ndata[3].R_ROA || "" },
        { label: "ROE", v1: ndata[0].R_ROE || "", v2: ndata[1].R_ROE || "", v3: ndata[2].R_ROE || "", v4: ndata[3].R_ROE || "" },
        { label: "Book Value", v1: ndata[0].NUM_BVPS || "", v2: ndata[1].NUM_BVPS || "", v3: ndata[2].NUM_BVPS || "", v4: ndata[3].NUM_BVPS || "" }
    ]
}
/** STOCK TRADE HISTORY
 * 1. daily = STOCK - STOCK TRADE HISTORY - DAILY 
 *    with fieldData = ["date", "open_price", "high_price", "low_price", "close_price", "change_price", "change_price_pcts", "traded_vol", "traded_val", "traded_freq"]
 * 2. brokerSummary = STOCK - STOCK TRADE HISTORY - BROKER SUMMARY
 *    with fieldData = ["broker", "b_val", "b_vol", "b_avg", "s_val", "s_vol", "s_avg", "n_val", "n_vol"]
 * 3. foreignNet = STOCK - STOCK TRADE HISTORY - FOREIGN NET
 *    with fieldData = ["date", "foreign_buy_val", "foreign_buy_vol", "foreign_sell_val", "foreign_sell_vol", "foreign_net_val", "foreign_net_vol"]
 * 4. topPrice - PRICE = STOCK - STOCK TRADE HISTORY - tabel kanan Atas ['price', 'val', 'vol', 'freq']
 * 5. topBuyerSeller - BUY = STOCK - STOCK TRADE HISTORY - tabel kanan tengah ['buyer_code', 'val', 'vol', 'freq', 'avg']
 * 6. topBuyerSeller - SELL = STOCK - STOCK TRADE HISTORY - tabel kanan bawah ['seller_code', 'val', 'vol', 'freq', 'avg']
 * 7. topBuyerChart - topSellerChart - topNetChart = STOCK - STOCK TRADE HISTORY - BROKER CHART
 */
function GetStockTradeHistory(tradeType, data=[]){
    if(tradeType === 'daily'){
        return addRowEmpty("card-372", data, 27.5);
    }else if(tradeType === 'brokerSummary'){ 
        let ndata = data.sort((a, b) => b.n_vol - a.n_vol).map(d => ({ ...d,
            b_val: mathCall.toThousand(d.b_val),
            s_val: mathCall.toThousand(d.s_val),
            n_val: mathCall.toThousand(d.n_val),
        }));
        return addRowEmpty("card-372", ndata, 27.5);
    }else if(tradeType === 'foreignNet'){
        let ndata = data.map(d => ({ ...d,
            foreign_buy_vol: d.foreign_buy_vol/100 || 0,
            foreign_sell_vol: d.foreign_sell_vol/100 || 0,
        }));
        return addRowEmpty("card-372", ndata, 27.5);
    }else if(tradeType === 'topPrice'){
        let ndata = data.sort((a, b) => b.price - a.price).map(d => ({
            ...d, vol: d.vol/100 || 0
        }));
        return addRowEmpty("card-192", ndata, 27);
    }else if(tradeType === 'topBuyerSeller'){
        let ndata = data.sort((a, b) => b.vol - a.vol).map(d => ({...d,
            vol: d.vol/100 || 0,
            avg: d.val/d.vol
        }));
        return addRowEmpty("card-192", ndata, 27);
    }else if(tradeType === 'topBuyerChart'){
        return data.map(d => ([d.buyer_code, d.vol, d.val, d.freq]));
    }else if(tradeType === 'topSellerChart'){
        return data.map(d => ([d.seller_code, d.vol, d.val, d.freq]));
    }else if(tradeType === 'topNetChart'){
        return data.map(d => ([d.broker, d.n_vol, d.value, d.n_val]));
    }
}

/** ============= TRADE PAGE ============= */
function GetSentOrderList(data=[]) {
    let ndata = data.map((d, i) => ({
        ...d, 
        date: d.order_date.split(" ")[0] || '', 
        time: d.order_date.split(" ")[1] || '',
        status: GetStatusOrder(d.fill_status, d.chain_status, d.request_status, d.remark).nstatus || ''
    }));
    return addRowEmpty("card-tradeSO", ndata);
}

/** ============= TRANSAKSI SAHAM ============= */
const expireType = [
    { key: 'day', value: '0', text: 'Day' }, 
    { key: 'session', value: 'S', text: 'Session' },
    { key: 'fok', value: '3', text: 'FOK' },
    { key: 'fak', value: '4', text: 'FAK' },
];

const orderType = [
    { key: "limit", value: "7", text: "Limit" },
    { key: "market", value: "1", text: "Market" },
];

const getExpireOption = (ordeType, mktType) => {
    switch (`${ordeType}|${mktType}`) {
        case "7|0TN": return [{key: 'session', value: 'S', text: 'Session' }];
        case "1|0TN": return [{key: 'session', value: 'S', text: 'Session' }];
        case "7|0RG": return [{ key: 'day', value: '0', text: 'Day' }, 
            { key: 'session', value: 'S', text: 'Session' }];
        default: return expireType;
    }
}

const getHaircut = (ref=[], code="") => {
    let result = ref.find(hc => hc.stock_code === code) || {haircut: 0};
    return result.haircut;
}

const getExpireValue = val => expireType.find(d => d.value === val).text;

const getOrderTypeValue = val => orderType.find(d=>d.value === val).text;

function GetStatusOrder(fill_status = null, chain_status = null, request_status = null, remark = ""){
    let tFillStatus = {null: 'Rejected By Market', 0: 'Open', 1: 'Partially Done', 2: 'Done'};
    let tChainStatus = {O: '', A: 'Amended', C: 'Cancelled'};
    let tRequestStatus = {1: '', 2: 'rejected'};
    if (chain_status === 'R'){
        return {nstatus: "Rejected By Server", nremark: remark}
    }else if(fill_status === null && request_status === null){
        return {nstatus: "Sending To Server", nremark: chain_status=== "A" ? "Amending" : ""}
    }else if(request_status === '2'){
        return {
            nstatus: tFillStatus[fill_status], 
            nremark: (fill_status ? `${tChainStatus[chain_status]} ${tRequestStatus[request_status]}: ` : '') + remark
        }
    }else {
        return {
            nstatus: tFillStatus[fill_status], 
            nremark: tChainStatus[chain_status] + " " +tRequestStatus[request_status]
        }
    }
    // let type = fill_status+"|"+chain_status+"|"+request_status;
    // switch (type) {
    //     //New Order sending to Market & rejected by Market
    //     case "null|null|null": return { nstatus: "Sending To Server", nremark: ""}; break;
    //     case "null|O|null": return { nstatus: "Sending To Server", nremark: ""}; break;
    //     case "null|O|2": return { nstatus: "Rejected By Market", nremark: remark}; break;

    //     case "null|R|null": return { nstatus: "Rejected By Server", nremark:  remark}; break;

    //     case "null|A|null": return { nstatus: "Sending To Server", nremark: "Amending"}; break;
    //     case "null|A|2": return { nstatus: "Rejected By Market", nremark: remark}; break;
        
    //     case "null|C|null": return { nstatus: "Sending To Server", nremark: ""}; break;
    //     case "null|C|2": return { nstatus: "Rejected By Market", nremark: remark}; break;
        
    //     //New Order Open,  Partially Matched, Full Matched
    //     case "0|O|1": return { nstatus: "Open", nremark: ""}; break;
    //     case "1|O|1": return { nstatus: "Partially Done", nremark: ""}; break;
    //     case "2|O|1": return { nstatus: "Done", nremark: ""}; break;

    //     case "0|A|2": return { nstatus: "Open", nremark: "Amended rejected : "+remark}; break;
    //     case "0|A|1": return { nstatus: "Open", nremark: "Amended"}; break;
    //     case "1|A|1": return { nstatus: "Partially Done", nremark: "Amended"}; break;
    //     case "1|A|2": return { nstatus: "Partially Done", nremark: "Amended rejected : "+remark}; break;
    //     case "2|A|1": return { nstatus: "Done", nremark: "Amended"}; break;   
        
    //     case "0|C|1": return { nstatus: "Cancelled", nremark: ""}; break;
    //     case "0|C|2": return { nstatus: "Open", nremark: "Cancelled rejected : "+remark}; break;
    //     case "1|C|1": return { nstatus: "Cancelled", nremark: "Partially Done"}; break;        
    //     case "1|C|2": return { nstatus: "Partially Done", nremark: "Cancel rejected : "+remark}; break;
    // }
}

function GetStatusOrderDetail(fill_status = null, chain_status = null, des = ""){
    let type = fill_status+"|"+chain_status
    let ndes = des.includes("Order amend") ? "Amend" : des.includes("Order cancel") ? "Cancel" :  "New Order";
    let nstat = des.includes("created") ? "Sending To Server" : "" 
    switch (type) {
        //New Order sending to Market & rejected by Market
        case "null|null": return { nstatus: nstat, naction: ndes}; break;
        case "null|O": return { nstatus: "Sending To Server", naction: "New Order"}; break;
        case "null|O": return { nstatus: "Rejected", naction: "New Order"}; break;
        case "null|R": return { nstatus: "Rejected", naction: "New Order"}; break;

        case "null|A": return { nstatus: "Sending To Server", naction: "Amend"}; break;
        case "null|A": return { nstatus: "Open", naction: "Amend"}; break;
        
        case "null|C": return { nstatus: "Sending To Server", naction: "Cancel"}; break;
        case "null|C": return { nstatus: "Open", naction: "Cancel"}; break;
        
        //New Order Open,  Partially Matched, Full Matched
        case "0|O": return { nstatus: "Open", naction: "New Order"}; break;
        case "1|O": return { nstatus: "Partially Done", naction: "New Order"}; break;
        case "2|O": return { nstatus: "Done", naction: "New Order"}; break;

        case "0|A": return { nstatus: "Open", naction: "Amend"}; break;
        case "1|A": return { nstatus: "Partially Done", naction: "Amend"}; break;
        case "2|A": return { nstatus: "Done", naction: "Amend"}; break;
        
        case "0|C": return { nstatus: "Cancelled", naction: "Cancel"}; break;
        case "1|C": return { nstatus: "Cancelled", naction: "Cancel"}; break;        
    }
}

/** Urutan menghitung Trading Limit / Buying Limit
 * 1. Hitung Trading limit berdasarkan Margin Ratio (Margin Call) & Haircut saham
 * 2. cek trading Limit vs Approve Limit [current BL = BL > approvelimit ? approvelimit : BL]
 * 3. cek Status & HC terkonsentrasi [concentration_flag === 'T' && (1-concentration_margin)*current BL ]
 */
function GetBuyLimit({Lqval=0, obhc=0, OB=0, Ct2=0, divider=0.5, hc=0, approvelimit=0, margincall=50, concentration_flag='F', concentration_margin=0}, debug="init"){
    // margincall = 50; concentration_flag='F'; approvelimit = 10000000000000000000000000000000000000000000000;
    let nLqval = Number(Lqval), nobhc = Number(obhc), nOB = Number(OB), nCt2= Number(Ct2), ndivider= Number(divider);
    let nhc = Number(hc)/100, nMC = Number(margincall)/100, nCM = Number(concentration_margin)/100;
    let BL = (1/2*(nLqval+nobhc)+nCt2-nOB)/(1-(nMC*(1-nhc)));
    let currentBL = BL;
    if(BL > approvelimit){
        let cashOnnHand = nCt2 - nOB;
        currentBL = cashOnnHand + (concentration_flag === 'T' ? (1 - nCM) * approvelimit : approvelimit)
    } else {
        if(concentration_flag === 'T'){
            currentBL = (1 - nCM) * currentBL;
        }
    }
    // console.log({
    //     nLqval, nobhc, nOB, currentBL, BL, divider, nCt2, nhc, approvelimit, 
    //     concentration_flag, concentration_margin
    // })
    // return ((1/2*(nLqval+nobhc)+nCt2-nOB)/ndivider).toFixed(0);
    return currentBL;
}

function UpdatePortfolioData(key, data, ref) {
    if(key === "create"){
        let lastprice = data ? (Number(ref.close_price) == 0 ? Number(ref.prev_price) : Number(ref.close_price)) : 0;
        let avgprice = data ? data.averageprice.toFixed(4) : 0;
        let pl =  data ? ((Number(lastprice)-Number(avgprice))*Number(data.totalqty)).toFixed(0) : 0;
        let perPl =  data ? (((Number(lastprice)-Number(avgprice))/Number(avgprice))*100).toFixed(2) : 0;
        let valFormSell = data ? data.sellableqty * Number(avgprice) : 0;
        let plFormSell = data ? ((lastprice-Number(avgprice))*data.sellableqty).toFixed(2) : 0;
        return {code: ref.code, avgprice, pl, perPl,
            lastprice: lastprice,
            lot: data ? ShareToLot(data.totalqty) : 0,  
            shares: data ? data.totalqty : 0, 
            slot: data ? ShareToLot(data.sellableqty) : 0, 
            sshares: data ? data.sellableqty : 0,
            stockval: data ? (Number(data.totalqty)*Number(lastprice)).toFixed(0) : 0, 
            stockvalR: data ? (Number(avgprice)*Number(data.totalqty)).toFixed(0) : 0, 
            valFormSell, plFormSell, 
            perPlFormSell: data ? ((plFormSell/valFormSell)*100).toFixed(2) : 0,  
        }
    } else if(key === "stock") {
        let npl = ((Number(data.lastprice)-Number(ref.averageprice))*Number(ref.totalqty)).toFixed(0)
        let nperPl = (((Number(data.lastprice)-Number(ref.averageprice))/Number(ref.averageprice))*100).toFixed(2)
        let valFormSell = ref.sellableqty * ref.averageprice;
        let plFormSell = ((data.lastprice - ref.averageprice) * ref.sellableqty).toFixed(2);
        return {...data, valFormSell, plFormSell, 
            stockval: data.lastprice*ref.totalqty,
            avgprice: ref.averageprice,
            lot: ShareToLot(ref.totalqty),
            shares: ref.totalqty,
            stockValR: ref.averageprice * ref.totalqty,
            pl: npl,
            perPl: nperPl,
            slot: ShareToLot(ref.sellableqty), 
            sshares: ref.sellableqty,
            perPlFormSell: ((plFormSell/valFormSell)*100).toFixed(2)
        };
    } else { /** "lastPrice" */
        let lastprice = ref.close_price == 0 ? ref.prev_price : ref.close_price;
        let npl = ( (lastprice - Number(data.avgprice)) * Number(data.shares) ).toFixed(0);
        let nperPl = ( ((lastprice - Number(data.avgprice)) / Number(data.avgprice)) * 100 ).toFixed(2);
        let plFormSell = ((lastprice - data.avgprice)* data.sshares).toFixed(2);
        return { ...data, lastprice, plFormSell,
            stockval: Number(data.shares) * lastprice,
            pl: npl, perPl: nperPl,
            perPlFormSell: ((plFormSell / data.valFormSell) * 100).toFixed(2)
        };
    }
}

/** ============= ESBN ============= */
const setSisaPencarian = (amount=0, remAmount=0, denom=1000000) => (
    remAmount < amount ? 0 : 0.5 * remAmount
    // remAmount < amount ? 0 : Math.floor((0.5 * remAmount)/denom)*denom
);
const getSbnHistory = (data, redeemOffer=[], filter={}) => {
    let { Seri = "all", Status = "all" } = filter;
    if(Seri !== "all" && Status !== "all")
        data = data.filter(d => d.Seri === Seri && d.Status === Status);
    else if(Seri !== "all" && Status === "all")
        data = data.filter(d => d.Seri === Seri);
    else if(Seri === "all" && Status !== "all")
        data = data.filter(d => d.Status === Status);

    let SBN_HISTORY = data.map(d => ({...d, 
        // earlyReedem: redeemOffer.findIndex(x => x.Seri === d.Seri),
        TglPemesanan: d.TglPemesanan.replace("T"," ").replace("Z", "")
    }) );
    
    return addRowEmpty("card-344", SBN_HISTORY);
}
const getPortfolioSBN = (data) => {
    let z = [], sisa_pencairan;
    data.map(d => 
        d.detail.map( x => {
            sisa_pencairan = x.sisa_pencairan === "" ? 0.5 * Number(x.kepemilikan_sisa) : x.sisa_pencairan ;
            // sisa_pencairan = setSisaPencarian(Number(x.nominal), Number(x.kepemilikan_sisa), d.min_redeem);
            z.push({ ...x, 
                btnDisable: Number(sisa_pencairan) < Number(d.kelipatan_redeem),
                "tgl_jatuh_tempo": d.tgl_jatuh_tempo,
                "kelipatan_redeem": d.kelipatan_redeem,
                "tgl_akhir_redeem": d.tgl_akhir_redeem,
                "seri": d.seri,
                "tgl_kupon": d.tgl_kupon,
                "tgl_mulai_redeem": d.tgl_mulai_redeem,
                "id_seri": d.id_seri,
                "min_redeem": d.min_redeem,
                "earlyReedem": d.earlyReedem,
                sisa_pencairan,
        })} 
    ) );
    return addRowEmpty("card-intIndices", z);
}

/** ============= APERD ============= */
const getFundType = (data) => {
    data = data.result ? data.result : AperdFundType;
    return data.map((d) => ({
        ...d,
        value: d.fund_type_name,
        code: d.fund_type_code,
        saham:  d.fund_type_name
    }));
};
export const getListIM = (data=[]) => {
    let arr = [{ value: "all", text: "All" }];
    data.forEach(el => {
        arr.push({
        ...el, value: el.im_code, text: `${el.im_code} - ${el.im_name}`
        });
    });
    return arr;
}
const setNavChartAperd = (data=[]) => {
    let z = [];
    data.filter(d => Number(d.Nav) > 0).map(d => z.push([d.NavDate, Number(d.Nav).toFixed(2), Number(d.NavUnit).toFixed(2)]));
    return z
}

const setCompareChartAperd = (data=[]) => {
    let nz=[];
    data.map(d => {
        let z = [];
        d !== null && d.map(nd => z.push([nd.NavDate, Number(nd.Nav).toFixed(2), Number(nd.NavUnit).toFixed(2)]))
        nz.push(z)
    })
    return nz
}

const GetTradePL_OLD = (data) => {
    let tradePl = Object.values(data);
    let sumModalBuy = tradePl.reduce((a, b) => a + (Number(b.sell_vol)*Number(b.accumulation_avg_price)), 0);
    let salesPl = tradePl.reduce((a, b) => a + Number(b.pl), 0);
    let tradePlInfo = {
        sellAmount: tradePl.reduce((a, b) => a + Number(b.sell_amount), 0), 
        buyAmount: tradePl.reduce((a, b) => a + Number(b.buy_amount), 0), 
        salesPl, 
        perSalesPl: sumModalBuy === 0 ? 0 : ((salesPl/sumModalBuy)*100).toFixed(2)
    }
    return {tradePl: addRowEmpty("card-tradePL", tradePl), tradePlInfo};
}

export { 
    GetTradingStatus,
    GetOrderbookStatus, GetUserProfile, GetStockProfile,
    addRowEmpty, 
    cekCrudWatchlist,
    OpenExercise, CreateExercise, SetExerciseList,
    GetSCSettlement, 
    GetTradePL,
    TOPGAL, GetTopGAL, 
    SetSectoralIndex, GetIndexSummary, getStockSMRY,
    GetIntIndex, GetCurrencies, GetIntIndexFutures,
    GetBrokerTradeHistory, GetTopBroker,
    GetStockFinancialReport, GetSpecialNotation, GetStockTradeHistory, 
    GetSentOrderList,
    expireType, 
    getHaircut,
    getExpireOption, 
    getExpireValue,
    orderType, 
    getOrderTypeValue,
    GetStatusOrder,
    GetStatusOrderDetail,
    GetBuyLimit,
    UpdatePortfolioData,
    getSbnHistory, getPortfolioSBN,
    getFundType, setNavChartAperd, setCompareChartAperd
};