import React, { memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ContextConnector } from '../../appcontext';
import { BIPSAppContext } from '../../AppData';
import { WSConnectionAction } from '../../appnetwork'
import { fmsg } from '../../utils';

export const ContentPushNotif = memo(({content_url, content_data}) => {
   return (<>
      {content_url !== "" && 
         <div className="w-100 text-center">
            <img src={content_url} alt="img_notif" className="img-fluid" />
         </div>
      }
      <span dangerouslySetInnerHTML={{ __html: content_data }}/>
   </>)
})

class PushNoification_Base extends React.PureComponent {

   onConfirm = () => {
      let { pushNotif={}, userInfo } = this.props;
      this.refs.netAction.send(fmsg.push_notif_feedback(pushNotif.behaviour_id, userInfo.custcode)); 
      this.props.setDataHandler({ pushNotif: {} });
   }

   render() {
      let { pushNotif={}, setDataHandler} = this.props;
      let { status=false, headline, content_data, content_url } = pushNotif;

      return (<>
         <WSConnectionAction ref="netAction" />
         <SweetAlert
            show={status}
            text="SweetAlert in React"
            title={<span className="text-white py-3 my-4">{headline}</span>}
            style={{'color':'var(--text-white)', "background-color": "var(--warna-bg-dark-grey)" }}
            showConfirm={true}
            confirmBtnText="Don't Show Again"
            onConfirm={this.onConfirm}
            confirmBtnCssClass="btn btn-danger text-white text-bold"
            showCancel={true}
            cancelBtnText="Ok"
            onCancel={() => setDataHandler({ pushNotif: {} })}
            cancelBtnCssClass="btn btn-primary text-white text-bold"
            // customClass={"bg-dark-grey"}
         >
            <div className="card card-body row mx-0 pt-4" style={{ maxHeight: "70vh", overflowX: "auto" }}>
               <ContentPushNotif 
                  content_url={content_url}
                  content_data={content_data}
               />
            </div>
         </SweetAlert>
      </>
      )
   }
}

const PushNoification = ContextConnector(BIPSAppContext,
   (vars, act) => ({
      userInfo: vars.userInfo,
      pushNotif: vars.pushNotif,
      setDataHandler: (newVars) => act.sendAction('setDataHandler', {newVars}),
   }), 
   ["setDataHandler"]
)(PushNoification_Base)

export default PushNoification;