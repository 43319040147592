import React from "react";
import {Button, Table} from "react-bootstrap";
import { List, Checkbox, Dropdown, Popup} from "semantic-ui-react";

import {AppFrameAction} from "./../appframe";
import { ContextConnector } from "../appcontext"; 
import {BIPSAppContext, ConvertToRupiah } from "../AppData"; 
import VerifyPIN, {tanggal, detailAmendModal} from "../app_pages/verifyPin";
import { ModalRestricted } from "../app_modals";
import { mathCall } from "../utils";

const stateOptionsExp = [
    { key: 'day', value: 'Day', text: 'Day' },
    { key: 'session', value: 'Session', text: 'Session' },
];

class FormAmend_Base extends React.PureComponent{
    
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }
    // handle onchange on Price / Vol
    onChange=(type, e)=>{
        const price = e.target.value
        if(type === 'price'){
            // const name = this.input.value
            this.props.handleCount('sell','onChange', price)
            // console.log('ini ganti harga', price)
        }else if(type === 'sellVol'){
            // const price = e.target.value
            // const name = this.input.value
            // console.log('ini ganti Vol', price)
            this.props.handleCount('sellVol','onChange', price)
        }
    }
    // handle checkbox : all, preve same order
    handleCheckbox=(e, data)=>{
        // console.log(data.value)
        if(data.label === 'All'){
            var offerPrice = Number(this.props.offerPrice)
            if(offerPrice === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Price can not be zero.',)
            }else{ 
                this.props.handleOnClick('sell','all','none')
            }
        }
        // check order book
        else if(data.label === 'Order Booking'){
            this.props.handleOnClick('transaction','order_book','none')
        }
    } 

    buttonClickPIN = (e) => {
        let { amend, bidOfferPrice, bidOfferVol, userProfile, statusAlertN, handleStatusAlert3 } = this.props;
        if(amend.price == bidOfferPrice && amend.vlot == bidOfferVol ){
            handleStatusAlert3('noConfirm', statusAlertN, 'Please change price or/and volume for amending');
        }else if(userProfile.status === "TRIAL" || userProfile.status === "SUSPEND" || 
            (amend.cmd === 'Sell' && userProfile.status === "SUSPEND SELL") || 
            (amend.cmd === 'Buy' && userProfile.status === "SUSPEND BUY")
        ){
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-white text-center">
                        <h3 className="text-center pt-1">Amend is not permitted</h3>
                    </div>,
                size: 'mini',
                contentClass: ModalRestricted
            })
        }else{
            var frameAction = this.refs.frameAction;
            frameAction.showModal({
                size: "small",
                headerClass: () =>
                    <div className="row col-sm-12 px-0 mx-0">
                        <div className="col-sm-6 px-0 mx-0 text-white f-14">Order Information</div>
                        <div className="col-sm-6 px-0 mx-0 text-white f-12 text-right">
                            <i className="fa fa-calendar-alt"></i> &nbsp; {tanggal()}
                        </div>
                    </div>,
                contentClass: detailAmendModal
            });
        }
    }

    render(){
        let p = this.props
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }

        // var name = this.props.stockList[],
        var iStockData = this.props.iStockData;
        let stateOptionsMkt = p.bidOfferCode.includes("-R") || p.bidOfferMkt.includes("TN") ? 
                [{ key: 'tn', value: '0TN', text: 'TN' }] 
            : (p.orbookStatus ? [{ key: 'rg', value: '0RG', text: 'RG' }] : p.stateOptionsMkt);
                
        return(
            <div className="f-12">
                <AppFrameAction ref="frameAction" />
                <Table borderless className="card-475 mb-0">
                    <tbody>
                    <tr>
                        <td className="py-0">
                            <div className="row mb-2">
                                <div className="col-sm-6 f-18 text-success">
                                    {/*<span className="text-primary">Amend</span> [Sell]*/}
                                </div>
                                <div className="col-sm-6 text-right text-form">
                                    <i className="fa fa-calendar-alt"></i> {tanggal()}
                                </div>
                                <div className="col-sm-2 f-18 text-success"></div>
                                <div className="col-sm-10 f-15">
                                    {
                                        p.iStockData.board_pos === "Acceleration Board" ? 
                                            <div className="pb-0 text-form">
                                                <i className="icofont icofont-warning text-danger"></i>
                                                &nbsp; <b>Member of acceleration board</b>
                                            </div>
                                        : (p.iStockData.board_pos && p.iStockData.board_pos.includes("Watch-list")) ?
                                            <div className="pb-0 text-form">
                                                <i className="icofont icofont-warning text-danger"></i>
                                                &nbsp; <b>Watchlist - Call Auction Stock</b>
                                            </div>
                                        
                                        : ""
                                    }
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-2 text-form">Code</div>
                                <div className="col-sm-10 row pr-0">
                                    <div className="col-sm-4 pr-0 text-center align-self-center">
                                        <input type="text" value={p.bidOfferCode}  readOnly={true}
                                            defaultValue='' className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                    </div>
                                    <div className="input-group col-sm-8 align-self-center pl-4">
                                    <span className="input-group-btn">
                                        <label className="form-control ui icon button bg-gold px-2 py-3" style={{"margin": "0px","border-bottom-left-radius": ".28571429rem","border-top-left-radius": ".28571429rem", "border-bottom-right-radius": "0px", "cursor": "default"}}>
                                            {p.haircutCode+"%"}
                                        </label>
                                    </span>
                                        <input type="text" placeholder="Name" value={iStockData.name} className="form-control f-12" style={{"borderRadius": "0px", }}/>
                                    </div>
                                    <div className="col-sm-12">
                                        <Checkbox label='Prevent same order' className="f-12 text-white my-3 align-self-center text-form" checked={this.props.prevStatus} />
                                    </div>
                                    {p.bidOfferCode != "" && iStockData.special_notation && <div className="pb-0 text-form">
                                        <b>
                                            <Popup 
                                                content={
                                                    <List>
                                                        {p.bidOfferCode != "" && iStockData.special_notation && iStockData.special_notation_desc.split("^").map((item, id) => (
                                                            <List.Item key={"snd"+id}>
                                                                <List.Content>{item}</List.Content>
                                                            </List.Item>
                                                        ))}
                                                    </List>
                                                }
                                                mouseEnterDelay={100}
                                                mouseLeaveDelay={100}
                                                header="Special Notation Stock"
                                                position='left center'
                                                trigger={
                                                    <i className="fa fa-info-circle text-danger"></i>
                                            } />
                                            &nbsp; Special Notation Stock
                                        </b>
                                    </div>}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Price</div>
                                    <div className="col-sm-9">
                                        {/* <NumberInput idclassname={this.props.idPrice} name="sell_price" placeholder="Price" size="small" defaultValue={"12650"} className="col-sm-12 px-0 f-12 text-center align-self-center" /> */}
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>this.props.handleCount('sell','minus', this.props.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={ConvertToRupiah(this.props.bidOfferPrice)} value={ConvertToRupiah(this.props.bidOfferPrice)}
                                                onChange={(e)=>this.onChange('price',e)} 
                                                onFocus={(e) => e.target.select()} />
                                            <span className="input-group-btn">
                                                <button type="button" onClick={()=>this.props.handleCount('sell','plus',this.props.bidOfferPrice)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    {/*<Checkbox label='Auto Last' className="f-12 text-white col-sm-11 px-4 my-0 align-self-center" />*/}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Vol</div>
                                    <div className="col-sm-9">
                                        {/* <NumberInput idclassname={this.props.idVol} name="sell_vol" placeholder="Vol" size="small" defaultValue={"10"} className="col-sm-12 px-0 f-12 text-center align-self-center" /> */}
                                        {/* Without button + - */}
                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                            className="col-sm-12 f-12 text-center align-self-center form-control" 
                                            // readOnly={true}
                                            defaultValue={ConvertToRupiah(this.props.bidOfferVol)} value={ConvertToRupiah(this.props.bidOfferVol)} 
                                            onChange={(e)=>this.onChange('sellVol',e)}
                                            onFocus={(e) => e.target.select()}/>
                                        {/* with button + - */}
                                        {/* <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" 
                                                    // onClick={()=>this.props.handleCount('buyVol','minus', this.props.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                    <i className="icofont icofont-minus f-9"></i>
                                                </button>
                                            </span>
                                            <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                className="form-control f-12" defaultValue={ConvertToRupiah(this.props.bidOfferVol)} value={ConvertToRupiah(this.props.bidOfferVol)} 
                                                readOnly={true}
                                                // onChange={(e)=>this.onChange('sellVol',e)}
                                                />
                                            <span className="input-group-btn">
                                                <button type="button" 
                                                    // onClick={()=>this.props.handleCount('buyVol','plus', this.props.bidOfferVol)} 
                                                    className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                    <span className="icofont icofont-plus f-9"></span>
                                                </button>
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-sm-4 pl-5 row text-center align-middle align-self-center pr-0">
                                    <label className="col-sm-5 pr-4 pl-0 mb-0 bg-amend py-2 align-self-center text-left text-form">Lot</label>
                                    <div className="col-sm-7 px-0 mx-0 py-2 align-self-center text-left">
                                        <Checkbox label='All' className="f-12 text-white my-0 align-self-center text-form" />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row py-form mb-2">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 text-form">Mkt.</div>
                                    <div className="col-sm-9">
                                        <Dropdown placeholder='Mkt' search selection options={stateOptionsMkt} 
                                            // onChange={(e, data)=>this.props.handleOnClick('buy','mkt',data.value)}  
                                            className={"f-12 text-black text-center align-self-center col-sm-12"} 
                                            defaultValue={`0${p.bidOfferMkt}`}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 align-middle align-self-center">
                                    <div className="col-sm-12 px-0">
                                        <Checkbox label='Order Booking' className="f-12 text-white px-0 py-2 my-0 align-self-center text-form" 
                                            onChange={(e, data) => this.handleCheckbox(e,data)} 
                                            checked={this.props.orbookStatus}/>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row">
                                <div className="col-sm-8 ml-0 px-0 row">
                                    <div className="col-sm-3 mb-3 py-form text-form">Expire</div>
                                    <div className="col-sm-9 mb-3 py-form">
                                        <Dropdown placeholder='Expire' search selection options={stateOptionsExp} 
                                            className={"f-12 text-black text-center align-self-center col-sm-12"} 
                                            // onChange={(e, data)=>this.props.handleOnClick('buy','expire',data.value)} 
                                            defaultValue={this.props.bidOfferExpire} disabled/>
                                    </div>

                                    <div className="col-sm-3 mb-3 py-form text-form">Value</div>
                                    <div className="col-sm-9 mb-3 py-form">
                                        <input type="text" id={this.props.idValue} name="amend_value" placeholder="Value" size="small" value={ConvertToRupiah(this.props.bidOfferVal)}
                                            className="col-sm-12 f-12 text-center align-self-center form-control"
                                            style={{"borderRadius": "0px", "text-align": "right"}} readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-sm-4 text-center align-middle align-self-center">
                                    <Button size="sm" className="btn btn-lg btn-primary col-sm-8" onClick={this.buttonClickPIN}>
                                        <i className="icon-icon-amend-btn fa-2x"></i>
                                        <br/>Amend
                                    </Button>
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            className={`col-sm-4 btn btn-primary btn-refresh-2-right`}
                                            style={{"font-size":"12px","width":"38px"
                                            }}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    } />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-0">
                            <div className="row px-4 py-form">
                                <Table responsive borderless size="sm" className="text-white pb-0 mb-0 d-border-table">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td className="bg-gray-tradding">Cash On <br/> T+2</td>
                                        <td className="bg-grey d-border-tr-gray text-right">
                                            {mathCall.setRupiah(p.formAmendFooter.cashOn)}
                                        </td>
                                        <td className="bg-gray-tradding">Remain <br/> Trade Limit</td>
                                        <td className="bg-grey d-border-tr-gray text-right">
                                            {mathCall.setRupiah(p.formAmendFooter.remainTrade)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}

class PINVerify_Base extends React.PureComponent {
    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'amend'/>
            </>
        );
    }
}

//************************** Context Connector **************************
const FormAmend = ContextConnector(BIPSAppContext,
    (vars,actions)=>({
        userProfile: vars.userProfile,
        haircutCode:vars.haircutCode,
        networkState:vars.networkState,
        bidOfferCode :vars.bidOfferCode,
        iStockData:vars.iStockData,
        stateOptionsMkt:vars.stateOptionsMkt,
        bidOfferPrice:vars.bidOfferPrice,   
        bidOfferVol:vars.bidOfferVol,
        bidOfferVal:vars.bidOfferVal,
        bidOfferMkt:vars.bidOfferMkt,
        bidOfferExpire:vars.bidOfferExpire,
        prevStatus:vars.prevStatus,
        orbookStatus:vars.orbookStatus,
        formAmendFooter:vars.formAmendFooter,
        transactionType:vars.transactionType,
        handleCount:(typeAction, typeCount,price)=>actions.sendAction('handleCount',{typeAction, typeCount,price}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        // Alert
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
        // last amend data
        amend:vars.amend,
    }),
    ["handleCount", "handleOnClick", "handleStatusAlert3"]
)(FormAmend_Base)

const PINVerify = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState
    })
)(PINVerify_Base)

export default FormAmend;