import React from "react";

import { AppFrameAction } from "./../appframe";

class ModalRestricted extends React.PureComponent {
    
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (<>
            <AppFrameAction ref="frameAction" />
            <div className="text-white f-12 text-center bg-dark-grey">
                <div className="form-group">
                    <p className="col-sm-12 px-5 py-2 col-form-label">
                            Please contact our helpdesk center 14009.
                    </p>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary btn-sm col-sm-3" 
                    onClick={ this.closeClick }
                >
                    OK
                </button>
            </div>
        </>);
    }
}

export default ModalRestricted;