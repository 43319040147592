import React from "react";
import {AppFrameAction} from "./../appframe";
import {Table} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";

import {BIPSAppContext , ConvertToRupiah,} from "../AppData";
import { ContextConnector } from '../appcontext.js';
import { CustomTooltip } from "../app_components";

class ModalOrderDetail_Base extends React.PureComponent{
    render() {
        let p = this.props
        return (
            <>
                <style>{'' +
                'thead.orderdetail th {' +
                '    background-color: var(--warna-header-card)!important;' +
                '}' +
                ''}
                </style>
                <div className="col-sm-12 text-white px-0 mx-0 py-2 f-12">
                    <div className="col-sm-12 row mx-0 px-0">
                        <Table size="sm" responsive bordered className="text-white">
                            <thead></thead>
                            <tbody className="f-12">
                            <tr>
                                <td colSpan="2" className="f-14 bg-gray-tradding text-center font-weight-bold py-2">ORDER DETAIL</td>
                            </tr>
                            {
                                p.orderDetail.map((d, index)=>{
                                    return(<tr key={d}>
                                                <td className="pt-2 pb-2">{d.label}</td>
                                                <td className={index%2 === 0 ? "bg-black-trading hover-tables pt-2 pb-2" : "hover-tables pt-2 pb-2"}>
                                                    {d.label == "Price" || d.label == "Order Volume (Lot)" ||  d.label == "Total Match Volume (Lot)" 
                                                        ? ConvertToRupiah(d.value) : d.value === "buy" || d.value === "Buy" ? <span className="text-danger">{d.value}</span> 
                                                        : d.value === "sell" || d.value === "Sell" ? <span className="text-success">{d.value}</span> : d.value }
                                                </td>
                                            </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="col-sm-12 text-white px-0 mx-0 py-2">
                    <div className="card bg-grey">
                        <div className="card-body mx-0 px-0 my-0 py-0">
                            <OrderLictCodeAgGrid/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class OrderLictCodeAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: "dateTop", headerName: "", sortable: true,
                    filter: "agTextColumnFilter", resizable: true,
                    width: 69, minWidth: 69, lockPosition: true, lockVisible: true, lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                    }, suppressSizeToFit: true, children: [{
                        field: "dateO", headerName: "Date", sortable: true,
                        filter: "agTextColumnFilter", resizable: true,
                        width: 69, minWidth: 69, lockPosition: true, lockVisible: true,
                        cellClass : function (params) {
                            return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                        }, suppressSizeToFit: true
                    },]},
                { field: "timeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "timeO", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 95, minWidth: 95, 
                            sort:'desc',
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            },
                            cellRenderer:(params)=>params.data.timeO.substr(0,8)
                        },
                    ],
                },
                { field: "marketOrderTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "marketOrderNo0", headerName: "Market Order No.", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 140, minWidth: 140,
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "actionsTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width:160,
                    cellClass : function (params) {
                        var pl = params.data.actionsO;
                        return pl.includes('-') === true ? "text-danger text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                            "text-success text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                        }, children: [{ field: "actionsO", headerName: "Action", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                var pl = params.data.actionsO;
                                return pl === 'Amend' ? "text-primary text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'Cancel' ? "text-warning text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                    "text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                        }
                    },],
                },
                // { field: "orderTypeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: 130,
                //     cellClass : function (params) {
                //         return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                //     }, children: [
                //         { field: "orderType0", headerName: "Order Type", sortable: true, filter: "agTextColumnFilter", resizable: true,
                //             width: 130, minWidth: 130,
                //             cellClass : function (params) {
                //                 return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                //             }
                //         },
                //     ],
                // },
                { field: "statusTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 130,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "statusO", headerName: "Status", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 130, minWidth: 130,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            },
                            cellRenderer: (params)=>params.data.statusO === "\xa0" ? "" : params.data.statusO
                        },
                    ],
                },
                { field: "remaksTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 230, suppressSizeToFit: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "remarksO", headerName: "Remark", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            suppressSizeToFit: true,tooltipField: 'remarksO',
                            tooltipComponentParams: { type: 'remarksO' },    
                            width: 230, minWidth: 230,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            }
                        },
                    ],
                },
                { field: "vol", headerName: "Vol", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 260,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "vlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ]
                },
                { field: "priceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: this.props.gridView == 'grid' ? 94 : 206,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "priceO", headerName: "Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 100, minWidth: 100,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "matchvol", headerName: "Match Vol", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "mlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                        { field: "mshares", headerName: "Shares", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            },
                        }
                    ]
                },
                { field: "matchpriceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "matchpriceO", headerName: "Match Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "leaveVolTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "leaveVol0", headerName: "Leave Vol(Lot)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 150, minWidth: 150,
                            valueFormatter: currencyFormatter, 
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "userIdTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right ";
                    }, children: [
                        { field: "userId0", headerName: "User ID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            cellClass : function (params) {
                                return "grid-table f-12 d-border-aggrid-right text-center";
                            }
                        },
                    ],
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',
            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-233 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.orderDetailCode}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//************************** Context Connector **************************
const ModalOrderDetail = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        orderDetail:vars.orderDetail,
    }),
)(ModalOrderDetail_Base)

const OrderLictCodeAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        orderDetailCode:vars.orderDetailCode,
    }),
)(OrderLictCodeAgGrid_Base)

//************************** Value Formatter For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}

export default ModalOrderDetail;