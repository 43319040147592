import moment from 'moment';
import { timeCall } from './generalFunction';
import { bankInfo, isDev } from './initialData';

/**
 *  s = subscribe, u = unsubscribe, i = inquiry, q = query, 
 *  x = aux = port 5050, without x = port 12000
 *  sx_ = subscribe to port 5050, q_ = query to port 12000
 */

// mm/dd/yyyy => 2019-12-25
const setTime = data => data.split("/").reverse().toString().replaceAll(",", "-"); 
const fmsg = {
    /** ACCOUNT */
    login: (user, password) => ({text:  // ✅
        JSON.stringify({ 
            action_type: 'LOGIN', user, password, terminal: "web" 
        }) 
    }),
    x_login: (user, session_id) => ({text: // ✅
        JSON.stringify({
            user, session_id, stringify: "true"
        })
    }),
    logout: (session_id) => ({text: 
        JSON.stringify({ 
            action_type: "LOGOUT", session_id 
        }) 
    }), 
    reconnect: (session_id) => ({text:
        JSON.stringify({ session_id })
    }),
    changePwd: (current_password, new_password) => ({text:  // ✅
        JSON.stringify({
            action_type: "ACCOUNT",
            sub_type: "CHANGE-PASSWORD",
            data: { current_password, new_password }
        })
    }),
    checkPin: (pin, ops_id) => ({text: // ✅
        JSON.stringify({
            action_type: "PIN",
            sub_type: "XCHECK" /** "XCHECK" */,
            data: {pin, ops_id}
        })
    }),
    changePin: (current_pin, new_pin) => ({text: // ✅
        JSON.stringify({
            action_type: "PIN",
            sub_type: "CHANGE-PIN",
            data: { current_pin, new_pin }
        })
    }),
    migrateAccount: (user_id) => ({text:
        JSON.stringify({
            action_type: 'UPDATE-DB',
            sub_type: 'MIGRATION',
            params: { user_id }
        })
    }),
    x_userPreference: (params) => ({text:
        JSON.stringify({
            action_type: params.action_type, /** "UPDATE-DB" || "INSERT-DB" */
            sub_type: "USER_PREFERENCE", 
            params
        })
    }),
    q_userProfile: (custcode) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "user",
            params: { custcode }
        })
    }),
    q_stockProfile: (custcode) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "stock",
            params: { custcode }
        })
    }),    
    q_cashProfile: (custcode) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "cash",
            params: { custcode }
        })
    }),
    q_cashBalance: (custcode) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "cash_balance",
            params: { custcode }
        })
    }),
    q_balanceLimit: (custcode) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "balance_limit",
            params: { custcode }
        })
    }),
    q_settlement: (client_id) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PORTFOLIO",
            variable: "settlement",
            params: { client_id }
        })
    }),
    q_stockHaircut: {text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "STOCK_HAIRCUT"
        })
    },
    q_stockHaircutByCode: (stock_code) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "STOCK_HAIRCUT", 
            params: { stock_code }
        })
    }),
    /** WATCHLIST */
    x_insertWatchlist: (username='dxtest20', group_name='WC1', stocks=["AALI", "ANTM"]) => ({text:
        JSON.stringify({
            action_type: "INSERT-DB",
            sub_type: "WATCHLIST",
            params: { username, group_name, stocks }
        })
    }),
    x_updateWatchlist: (username='dxtest20', group_name='WC1', stocks=["AALI", "ACES", "ANTM"], new_group_name) => ({text:
        JSON.stringify({
            action_type: "UPDATE-DB",
            sub_type: "WATCHLIST",
            params: { username, group_name, stocks, new_group_name }
        })
    }),
    x_deleteWatchlist: (username='dxtest20', group_name='WC2') => ({text:
        JSON.stringify({
            action_type: "DELETE-DB",
            sub_type: "WATCHLIST",
            params: { username, group_name }
        })
    }),
    /** ORDER */
    newOrder: (data) => ({text: 
        JSON.stringify({
            action_type: "ORDER",
            sub_type: "NEW-ORDER",
            data
        })
    }),
    amendOrder: (data) => ({text: 
        JSON.stringify({
            action_type: "ORDER",
            sub_type: "AMEND-ORDER",
            data
        })
    }),
    cancelOrder: (soid) => ({text: 
        JSON.stringify({
            action_type: "ORDER",
            sub_type: "CANCEL-ORDER",
            data: { soid }
        })
    }),
    q_orderList: (client_id) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "ORDERLIST",
            client_id
        })
    }),
    q_ordersDetail: (server_order_id) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "ORDERS",
            variable: "orders_detail",
            params: { server_order_id }
        })
    }),
    q_ordersHistory: (client_id, start_date, end_date) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "ORDERS",
            variable: "orders_history",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    q_ordersHistDetail: (server_order_id) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "ORDERS",
            variable: "orders_hist_detail",
            params: { server_order_id }
        })
    }),
    q_trades: (client_id) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "TRADES",
            variable: "trades",
            params: { client_id }
        })
    }),
    q_tradesHistory: (client_id, start_date, end_date) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "TRADES",
            variable: "trades_history",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    /** ORDER BOOKING */
    q_orderBookingList: (client_id) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "ORDER-BOOKING",
            params: { client_id }
        })
    }),
    q_cancelBooking: (client_id, client_order_id) => ({text:
        JSON.stringify({
            action_type: "ORDER",
            sub_type: "CANCEL-BOOKING",
            data: { client_id, client_order_id }
        })
    }),
    q_cashHistory: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "TRANSACTIONS",
            variable: "history_cash",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    q_stockHistory: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "TRANSACTIONS",
            variable: "history_stock",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    q_tradePL: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "PL",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    /** AUTOMATIC ORDER  */
    ao_createRule: (data) => ({text: 
        JSON.stringify({
        action_type: "AUTOMATIC-ORDER",
            sub_type: "CREATE-RULE",
            data
        })
    }),
    ao_updateRule: (data) => ({text: 
        JSON.stringify({
        action_type: "AUTOMATIC-ORDER",
            sub_type: "UPDATE-RULE",
            data
        })
    }),
    ao_switchRule: (data) => ({text: 
        JSON.stringify({
        action_type: "AUTOMATIC-ORDER",
            sub_type: "SWITCH-RULE",
            data
        })
    }),
    ao_deleteRule: (data) => ({text: 
        JSON.stringify({
            action_type: "AUTOMATIC-ORDER",
            sub_type: "DELETE-RULE",
            data
        })
    }),
    ao_listRule: (client_id) => ({text: 
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "AUTO_ORDER",
            variable: "list",
            params: { client_id }
        })
    }),
    ao_expiredRule: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "AUTO_ORDER", 
            variable: "expired", 
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ao_executedRule: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "AUTO_ORDER",
            variable: "executed",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ao_updateStatus: {text: 
        JSON.stringify({
            action_type: "AUTOMATIC-ORDER", 
            sub_type: "AGREEMENT"
        })
    }, 
    /** FUND TRANSFER */
    ft_request: (data) => ({text: // ✅
        JSON.stringify({
            action_type: "FUND",
            sub_type: "TRANSFER",
            data
        })
    }),
    ft_cancel: (message_id, ref_no) => ({text: // ✅
        JSON.stringify({
            action_type: "FUND",
            sub_type: "CANCEL",
            data: { message_id, ref_no }
        })
    }),
    q_fundTransferlist: (client_id, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: "QUERY",
            sub_type: "FUND_TRANSFER",
            method: "GET",
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date)}
        })
    }),
    /** EXERCISE RIGHT */
    rightIssue: ({tipe, params}) => ({text: // ✅
        JSON.stringify({
            action_type: "RIGHT-ISSUE", 
            sub_type: tipe,
            params
        })
    }),
    q_exerciseRightList: (client_id) => ({text: // ✅
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "EXERCISE-RIGHT", 
            variable: "list",
            params: { client_id }
        })
    }),
    q_exerciseRightRequest: (params) => ({text: // ✅
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "EXERCISE-RIGHT", 
            variable: "request",
            params
        })
    }),
    /** STOCK TRANSFER, RECEIVER, TRANSFER */
    stock_transfer: (data) => ({text: 
        JSON.stringify({
            action_type: 'STOCK',
            sub_type: 'TRANSFER',
            data
        })
    }),
    stock_cancelTransfer: (data) => ({text:
        JSON.stringify({
            action_type: 'STOCK',
            sub_type: 'CANCEL-TRANSFER',
            data
        })
    }),
    q_stockTransfer: (client_id, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: 'QUERY', 
            sub_type: 'STOCK', 
            variable: 'transfer', 
            params: { client_id, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    stock_receive: (data) => ({text:
        JSON.stringify({
            action_type: 'STOCK',
            sub_type: 'RECEIVE',
            data
        })
    }),
    /** BID OFFER */
    sx_bid: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'BIDOFFER.BID',
            code
        })
    }),
    ux_bid: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'BIDOFFER.BID',
            code
        })
    }),
    sx_offer: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'BIDOFFER.OFFER',
            code
        })
    }),
    ux_offer: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'BIDOFFER.OFFER',
            code
        })
    }),
    ix_tradeAdv: (code, type) => ({text: /** type = BID, OFFER */ // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: `ORDER.NG.${type}`,
            code
        })
    }),
    /** TRADE Data */
    sx_tradingStatus: {text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'TRDSTATUS'
        }
    )},
    ux_tradingStatus: {text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'TRDSTATUS'
        }
    )},
    ix_tradingStatus: {text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'TRDSTATUS'
        }
    )},
    sx_rtrade: {text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'RTRADE'
        })
    },
    ux_rtrade: {text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'RTRADE'
        })
    },
    sx_rtradeS: (code, type = "") => ({text: /** type [ALL / TN / RG], */ // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: `RTRADES${type}`,
            code
        })
    }),
    ux_rtradeS: (code, type = "") => ({text: /** type [ALL / TN / RG], */ // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: `RTRADES${type}`,
            code
        })
    }),
    ix_tradeAll: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'all',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_tradeBuy: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'buy',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_tradeSell: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'sell',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_tradePrice: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'price',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_tradeDaily: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'daily',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_tradeForeign: (stock, start_date, end_date) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'H_TRADE',
            variable: 'foreign',
            params: { stock, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    /** STOCK DATA */
    ix_stockWatchlist: {text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKWATCHLIST'
        })
    },
    ix_stockList: {text: // ✅
        JSON.stringify({
            action_type: "INQUIRY", 
            sub_type: "STOCKLIST" 
        })
    },
    sx_stockSummary: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'STOCKSMRY',
            code
        })
    }),
    ux_stockSummary: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'STOCKSMRY',
            code
        })
    }),
    ix_stockSummary: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKSMRY',
            code
        })
    }),
    sx_stockSummaryXS: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'STOCKSMRYXS',
            code
        })
    }),
    ux_stockSummaryXS: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'STOCKSMRYXS',
            code
        })
    }),
    ix_stockSummaryXS: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKSMRYXS',
            code
        })
    }),
    ix_stockChart: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'PERSISTENT.CHART.STOCKSHORT',
            // sub_type: 'PERSISTENT.CHART.STOCK',
            code
        })
    }),
    ix_stockData: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKDATA',
            code
        })
    }),
    ix_stockDataSN: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKDATASN',
            code
        })
    }),
    sx_stockiepv: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE', 
            sub_type: "STOCKIEPV", 
            code
        })
    }),
    ux_stockiepv: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE', 
            sub_type: "STOCKIEPV", 
            code
        })
    }),
    sx_stockPrice: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'STOCKPRICE',
            code
        })
    }),
    ux_stockPrice: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'STOCKPRICE',
            code
        })
    }),
    ix_stockPrice: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKPRICE',
            code
        })
    }),
    ix_stockBrokerBuy: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKBROKERBUY',
            code
        })
    }),
    ix_stockBrokerSell: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'STOCKBROKERSELL',
            code
        })
    }),
    /** INDEX DATA */
    ix_indexList: {text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'INDEXLIST'
        })
    },
    ix_indexMember: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'INDEX.MEMBER',
            code
        })
    }),
    sx_index: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: "INDEX",
            code
        })
    }),
    ux_index: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: "INDEX",
            code
        })
    }),
    ix_index: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'INDEX',
            code
        })
    }),
    sx_indexS: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'INDEXS',
            code
        })
    }),
    ux_indexS: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'INDEXS',
            code
        })
    }),
    sx_indexPeriodic: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'SUBSCRIBE',
            sub_type: 'INDEXPERIODIC',
            code
        })
    }),
    ux_indexPeriodic: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'UNSUBSCRIBE',
            sub_type: 'INDEXPERIODIC',
            code
        })
    }),
    ix_indexPeriodic: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'INDEXPERIODIC',
            code
        })
    }),
    ix_indexStockVal: (code) => ({text: // ✅
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'INDEXSTOCKVAL',
            code
        })
    }),
    /** BROKER DATA */
    ix_brokerList: {text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'BROKERLIST'
        })
    },
    ix_brokerInfo: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'BROKERINFO',
            code
        })
    }),
    ix_brokerStockBuy: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'BROKERSTOCKBUY',
            code
        })
    }),
    ix_brokerStockSell: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'BROKERSTOCKSELL',
            code
        })
    }),
    ix_brokerHistory: (broker_code, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY-DB',
            sub_type: 'BROKER-HISTORY',
            params: { broker_code, start_date: setTime(start_date), end_date: setTime(end_date) }
        })
    }),
    ix_globalIDXList: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "GLOBALIDXLIST"
        })
    },
    ix_globalIDX: (code) => ({text:
        JSON.stringify({
            action_type: "INQUIRY", 
            sub_type: "GLOBALIDX", 
            code
        })
    }),
    ix_forexList: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "FOREXLIST"
        })
    },
    ix_forex: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'FOREX',
            code
        })
    }),
    ix_futureList: {text: 
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "FUTURELIST"
        })
    },
    ix_future: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'FUTURE',
            code
        })
    }),
    ix_comodityList: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "COMMODITYLIST"
        })
    },
    ix_comodity: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'COMMODITY',
            code
        })
    }),
    ix_custodyList: {text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'CUSTODYLIST'
        })
    },
    ix_t1t2: {text:
        JSON.stringify({
            action_type: "INQUIRY", 
            sub_type: "T1T2"
        })
    },
    q_corpAction: (start_date, end_date) => ({text: 
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "CA_LIST", // CORPORATE_ACTION_LIST
            params: { start_date: setTime(start_date), end_date: setTime(end_date) },
        })
    }),
    q_corpActionDetail: (caid) => ({text: 
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "CA_DETAIL", // CORPORATE_ACTION_DETAIL
            params: { caid },
        })
    }),
    q_financialReport: (params) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type:  "FINANCIAL_REPORT", 
            params
        })
    }),
    sx_news: {text: 
        JSON.stringify({
            action_type: 'SUBSCRIBE', 
            sub_type: 'NEWS'
        })
    },
    ux_news: {text: 
        JSON.stringify({
            action_type: 'UNSUBSCRIBE', 
            sub_type: 'NEWS'
        })
    },
    ix_topactive_value: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_ACTIVE_VAL", 
        })
    },
    ix_topactive_volume: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_ACTIVE_VOL",
        })
    },
    ix_topactive_frequency: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_ACTIVE_FREQ",
        })
    },
    ix_toplosers_value: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_LOSER_VAL",
        })
    },
    ix_toplosers_percentage: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_LOSER_PCTS", 
        })
    },
    ix_topgainers_value: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_GAINER_VAL",
        })
    },
    ix_topgainers_percentage: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_GAINER_PCTS", 
        })
    },
    ix_marketStat: {text:
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "MARKETSTAT"
        })
    },
    ix_topBrokerVal: {text: 
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_BROKER_VAL"
        }) 
    },
    ix_topBrokerVol: {text: 
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_BROKER_VOL"
        }) 
    },
    ix_topBrokerBuy: {text: 
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_BROKER_BUY"
        })
    },
    ix_topBrokerSell: {text: 
        JSON.stringify({
            action_type: "INQUIRY",
            sub_type: "TOP_BROKER_SELL"
        })
    },
    ix_techDay: (code) => ({text:
        JSON.stringify({
            action_type: 'INQUIRY',
            sub_type: 'TECHDAY',
            code
        })
    }),
    /** ESBN */
    sbn_registration: (sid, custcode, custname, user_id) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_REQ_REGISTRATION", 
            params: { sid, custcode, custname, user_id, request_time: moment().format("YYYY-MM-DD hh:mm:ss") },
        })
    }),
    sbn_createFundAcc: (params) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_CREATE_FUND_ACC", 
            params,
        })
    }),
    sbn_account: (SID) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_ACCOUNT", 
            params: { SID },
        })
    }),
    sbn_list: {text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_LIST", 
        })
    },    
    sbn_redeemOffer: {text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_REDEEM_OFFER", 
        })
    },
    sbn_redeemOfferBySeriId: (seri_id) => ({text:
        JSON.stringify({
            "action_type":"QUERY",
            "sub_type":"SBN_REDEEM_OFFER",
            "params": {seri_id}
        })
    }),
    sbn_getEarlyRedemptionByOrderCode: (order_code) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_GET_EARLY_REDEMPTION_BY_ORDER_CODE", 
            params: { order_code },
        })
    }),
    sbn_showKuotaSeriBySid: (seri_id, sid='') => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_SHOW_KUOTA_SERI_BY_ID", 
            params: { seri_id, sid },
        })
    }),
    sbn_order: (params) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_ORDER", 
            params,
        })
    }),
    sbn_orderEarlyRedemption: (params) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_ORDER_EARLY_REDEMPTION", 
            params,
        })
    }),
    sbn_getAllOrderBySid: (sid, start_date, end_date) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_GET_ALL_ORDER_SERI_BY_SID", 
            params: { sid, start_date, end_date },
        })
    }),
    sbn_historyOrderByOrderCode: (order_code) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_HISTORY_ORDER_BY_ORDER_CODE", 
            params: { order_code },
        })
    }),
    sbn_consolidatePortfolio: (sid) => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_CONSOLIDATE_PORTFOLIO", 
            params: { sid },
        })
    }),
    sbn_showPortfolio: (sid="IDD888800004488", id_seri="362") => ({text:
        JSON.stringify({
            action_type: "QUERY", 
            sub_type: "SBN_SHOW_PORTFOLIO", 
            params: { sid, id_seri },
        })
    }),
    /** APERD */
    aperd_custStatus: (sid="UP0002HG") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_CUST_STATUS',
            params: { sid }
        })
    }),
    aperd_arialProvince: ({text:
        JSON.stringify({
            action_type: "APERD",
            sub_type:"APERD_ARIAL_PROVINCE"
        })
    }),
    aperd_cityCodeByProvince: (prov_id="12") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_CITY_CODE_BY_PROVINCE',
            params: { prov_id }
        })
    }),
    aperd_riskProfileQuestion: {text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_RISK_PROFILE_ASSESSMENT'
        })
    },
    aperd_riskProfileResult: (sid, risk_profile_result) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_RISK_PROFILE_RESULT',
            params: { sid, risk_profile_result }
        })
    }),
    aperd_register: (params) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_PERSONAL_REGIST_KYC',
            params /** : {
                "sid": "DX11111",
                "type":"1",
                "sid": "DX11111",
                "sa_code":"",
                "first_name":"Wahyu",
                "nationality":"ID",
                "id_no":"32640000000000",
                "birth_place":"Bandung",
                "birth_date":"20211231",
                "gender":"1",
                "education":"7",
                "religion":"1",
                "occupation":"3",
                "income_level":"4",
                "marital_status":"1",
                "obj_invest":"1",
                "fund_source":"2",
                "correspond_address":"",
                "correspond_city_code":"",
                "statement_type":""
            } */
        })
    }),
    aperd_getFundType: {text: JSON.stringify({
        "action_type":"APERD",
        "sub_type":"APERD_GET_FUND_TYPE"
    })},
    aperd_getIM: {text: JSON.stringify({
        "action_type": "APERD", 
        "sub_type": "GET_IM"
    })},
    aperd_listFundByIM: (IMCode = "TRIM") => ({text: 
        JSON.stringify({
            "action_type": "APERD", 
            "sub_type": "FILTER_BY_INVESTMENT", 
            "params": { IMCode }
        })
    }),
    aperd_listMutualFund: (offset="1", limit="20") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_GET_LIST_MUTUAL_FUND',
            params: { offset, limit }
        })
    }),
    aperd_fundDetail: (fund_code="MF0991HG") => ({text: 
        JSON.stringify({
            action_type: "APERD",
            sub_type: "APERD_FUND_DETAIL",
            params: { fund_code }
        })
    }),
    aperd_getMutualFundByFundCode: (fund_code="A1988HG") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_FUND_BY_FUND_CODE',
            params: { fund_code }
        })
    }),
    aperd_getMutualFundByIMCode: (im_code="356754885698") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_FUND_BY_IM_CODE',
            params: { im_code }
        })
    }),
    aperd_companyProfileByIMCode: (im_code="356754885698") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_COMPANY_PROFILE_BY_IM_CODE',
            params: { im_code }
        })
    }),
    aperd_navByPeriodeAndFundCode: (fund_code="DX002MXCDANINF00", periode="1Y", date_today="30/12/2021") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_NAV_BY_PERIODE',
            params: { date_today: setTime(timeCall.getdate('/',0,0)), periode, fund_code }
        })
    }),
    aperd_generalInfoByImCode: (im_code="356754885698", fund_code="MF0990HG") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_GENERAL_INFO_BY_IM_CODE',
            params: { im_code, fund_code }
        })
    }),
    aperd_getStakeholderByImCode: (im_code="356754885698") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_STAKEHOLDER_BY_IM_CODE',
            params: { im_code }
        })
    }),
    aperd_compareProduct: (fund_code=["MF0991HG","MF0990HG","A0988HG"], periode="1Y") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_COMPARE_PRODUCT',
            params: { periode, fund_code}
        })
    }),
    aperd_cbByRdnFund: (fund_code, rdn_bank_code="") => ({text:
        JSON.stringify({
            action_type:"APERD",
            sub_type:"APERD_CB_BY_RDN_N_FUND",
            params: { fund_code, rdn_bank_code }
        })
    }),
    aperd_custodianBank: ({text: 
        JSON.stringify({
            action_type: "APERD",
            sub_type: "APERD_CUSTODIAN_BANK"
        })
    }),
    aperd_convertCurrency: (currency_from="IDR", currency_to="IDR") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_CONVERT_CURRENCY',
            params: { currency_from, currency_to }
        })
    }),
    aperd_checkFee: (fund_code, side, amount) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_CHECK_FEE',
            params: { fund_code, side, amount }
        })
    }),
    aperd_getBankChargeData: (rdn_code="BMRIIDJA", cb_code="BNIAIDJA", qtyAmount=500000001, fee=0) => ({text:
        JSON.stringify({
            "action_type": "APERD",
            "sub_type": "APERD_GET_BANK_CHARGE_DATA",
            "params": {
                rdn_code: bankInfo[rdn_code].bank_code || rdn_code+"IDJA", 
                cb_code, 
                amount: (qtyAmount + fee).toString() 
            }
        })
    }),
    aperd_subscription: (data) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_SUBSCRIPTION',
            params: { 
                data/**: [
                    {
                        "TransDate": moment().format("YYYY-MM-DD HH:mm:ss"),
                        "Trans_type": "1",
                        "FundCode": "A1988HG",
                        "sa_code": "DX069",
                        "SID": "UP0001HG",
                        "QtyAmount": "500000",
                        "QtyUnit": "5000",
                        "LastNav": "10000",
                        "Fee_nominal": "5000",
                        "fee_persen": "5",
                        "redm_payment_acc_seq_code": "",
                        "redm_payment_bic_code": "",
                        "redm_payment_acc_no": "",
                        "rdn_acc_no": "0027388722",
                        "rdn_bank_code": "BBRI",
                        "rdn_bank_name": "a/n Agus",
                        "cb_acc_no": "0026576876",
                        "cb_bank_code": "BBRI",
                        "cb_bank_name": " a/n Bahana Liquid",
                        "payment_date": moment().format("YYYY-MM-DD HH:mm:ss"),
                        "transfer_type": "1",
                        "transaction_type": "RTGS",
                        "bank_charge": "3500"
                    },
                ] */
            }
        })
    }),
    aperd_redemption: (data) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_REDEMPTION',
            params: { 
                data /**: [
                    {
                        "TransDate": moment().format("YYYY-MM-DD hh:mm:ss"),
                        "Trans_type": "2",
                        "FundCode": "DX002EQC01DANP00",
                        "sa_code": "DX069",
                        "SID": "IDD888800002188",
                        "QtyAmount": "1000000",
                        "QtyUnit": "100",
                        "LastNav": "1000",
                        "Fee_nominal": "1000",
                        "fee_persen": "10",
                        "redm_payment_acc_seq_code": "",
                        "redm_payment_bic_code": "",
                        "redm_payment_acc_no": "",
                        "payment_date": moment().format("YYYY-MM-DD hh:mm:ss"),
                        "transfer_type": "2"
                    }
                ]  */
            }
        })
    }),
    aperd_switching: (data) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_SWITCHING',
            params: { 
                data /** : [
                    {
                        "TransDate": moment().format("YYYY-MM-DD hh:mm:ss"),
                        "Trans_type": "3",
                        "FundCode_out": "MF0991HG",
                        "sa_code": "DX069",
                        "SID": "UP0001HG",
                        "QtyAmount": "500000",
                        "QtyUnit": "500",
                        "LastNav": "1000",
                        "Fee_nominal": "5000",
                        "fee_persen": "5",
                        "switching_fee_charge": "1",
                        "payment_date": moment().format("YYYY-MM-DD hh:mm:ss"),
                        "transfer_type": "3",
                        "FundCode_in": "MF0990HG"
                    }
                ] */
            }
        })
    }),
    aperd_orderListBySid: (sid="UP0002HG") => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_ORDER_LIST_BY_SID',
            params: { sid }
        })
    }),
    aperd_getPortfolio: (sid="UP0002HG") => ({text: 
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_GET_PORTFOLIO',
            params: { sid }
        })
    }),
    aperd_historyTransaction: (sid="UP0002HG", {start_date, end_date}) => ({text:
        JSON.stringify({
            action_type: 'APERD',
            sub_type: 'APERD_TRANSACTION_HISTORY',
            params: { sid, start_date, end_date }
        })
    }),
    aperd_transactionSummary: (trans_date="2021-01-09", trans_type="1") => ({text:
        JSON.stringify({
            action_type: "APERD",
            sub_type: "APERD_TRANSACTION_SUMMARY",
            params: { trans_date, trans_type }
        })
    }),

    // PUSH NOTIF
    push_notif_feedback: (behaviour_id, customer_code) =>  ({text:
        JSON.stringify({
            "action_type": "PUSH_NOTIF",
            "sub_type": "FEEDBACK_NOTIF",
            params: { behaviour_id, customer_code, feedback_status_id: 6 }
        })
    }),
    push_notif_after_login: (customer_code) => ({text:
        JSON.stringify({
            action_type: "PUSH_NOTIF",
            sub_type: "NOTIF_AFTER_LOGIN", 
            params : {
                channel_id: 2,
                customer_code
            }
        })
    })
}

export { fmsg };
