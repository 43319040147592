import {dConfig, GetInitialRow, fd} from './appConfig.js'

const nor = dConfig.nor
const init= dConfig.init

// console.log('ini cek size',ceksize('fixedIncome'))

// format waktu untuk Order
function getDate2(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10)
    {
        dd='0'+dd;
    }  
    if(mm<10)
    {
        mm='0'+mm;
    }
    today = yyyy+''+mm+''+dd;
    return parseInt(today);
}

// convert Share to Lot
const ShareToLot = (number) => {
    if(number){
        // var numberRef = parseInt(number)
        var divider = 100;
        var lot = number/divider;
        return lot;
    }
}

// convert Lot To Share
const LotToShare = (number) => {
    if(number){
        var numberRef =Number(number)
        var multiplier = 100;
        var share= numberRef*multiplier
        return share
    }
  }

// Add row kosong
function addRow(type,data,format,jmlhbaris,jmlhData){
    var dif = jmlhbaris - data.length
    // console.log("ini difnya", dif, 'jumlah baris',jmlhbaris,"jumlah data saat ini",data.length, 'jumlah data isi',jmlhData)
    if(type === "portfolio"){
      // jika dif > 0
    if(dif >= 1 && dif <= jmlhbaris){
        for(let j=0; j<dif; j++){
        data.push(format)
        }
    }
    // jika dif <0
    else if(dif < 0) {
        let dif2 = jmlhbaris - jmlhData
        if(dif2 > 0 ){
        data =[...data.slice(0,data.length+dif)]
        }else {
        data = [...data.slice(0,jmlhData)]
        }
    }
    // jika dif =0
    else {
        data = data
        }
    }
    return data;
}

// funtion untuk cek Remark
function cekRemark1(type,data){
    // untuk kode 1 & 2
    if(type ==='f1'){
        if(data === '--'){ return ''
        }else if(data === 'CD'){ return 'Cum Dividend'
        }else if(data === 'CB'){ return 'Cum Bonus'
        }else if(data === 'CR'){
            return 'Cum Right'
        }else if(data === 'CA'){
            return 'Cash dividend, stock dividend and/or stock bonus'
            // return 'Any Combination of cum remarks (Cash dividend, stock dividend and/or stock bonus)'
        }else if(data === 'XD'){
            return 'Ex Dividend'
        }else if(data === 'XB'){
            return 'Ex Bonus'
        }else if(data === 'CB'){
            return 'Cum Bonus'
        }else if(data === 'XR'){
            return 'Ex Right'
        }else if(data === 'XA'){
            return 'Cash dividend, stock dividend and/or stock bonus'
            // return 'Any combination of Ex remarks (Cash dividend, stock dividend and/or stock bonus)'
        }else if(data === 'RS'){
            return 'Reverse Stock'
        }else if(data === 'SS'){
            return 'Reverse Stock'
        }
    }
    // untuk kode 3 & 4
    else if(type ==='f2'){
        if(data === 'M'){
            return 'Marginable Securities'
        }else if(data === 'S'){
            return 'Marginable Securities and Short selling'
        }else if(data === 'U'){
            return 'Unmarginable Securities'
        }else if(data === 'D'){
            return 'Designated Stock'
        }else if(data === '1'){
            return 'The stock is constituent of Agriculture sectoral index'
        }else if(data === '2'){
            return 'The stock is constituent of Mining sectoral index'
        }else if(data === '3'){
            return 'The stock is constituent of Basic Industry and Manufacture sectoral index'
        }else if(data === '4'){
            return 'The stock is constituent of Miscellaneous and Manufacture sectoral index'
        }else if(data === '5'){
            return 'The stock is constituent of Consumer Good and Manufacture sectoral index'
        }else if(data === '6'){
            return 'The stock is constituent of Property sectoral index'
        }else if(data === '7'){
            return 'The stock is constituent of Infrastructure sectoral index'
        }else if(data === '8'){
            return 'The stock is constituent of Finance sectoral index'
        }else if(data === '9'){
            return 'The stock is constituent of Trade sectoral index'
        }
    }
    // untuk kode 5 & 6
    else if(type ==='f3'){
        if(data === '--'){
            return 'The stock not included in Jakarta Islamic Index, LQ45 Index, and Kompas100 Index.'
        }else if(data === 'I-'){
            return 'The stock is constituent of Jakarta Islamic Index'
        }else if(data === 'L-'){
            return 'This stock is constituent of the LQ45 Index'
        }else if(data === 'K-'){
            return 'The stock is constituent of Jakarta Islamic Index and KOMPAS100'
        }else if(data === 'IL'){
            return 'The stock is constituent of Jakarta Islamic Index and LQ45 Index.'
        }else if(data === 'LK'){
            return 'The stock is constituent of LQ45 and KOMPAS100'
        }else if(data === 'XX'){
            return 'The stock included in the Jakarta Islamic Index, LQ45 Index and Kompas100 Index.'
        }else if(data === 'IB'){
            return 'This Stock included in the Jakarta Islamic Index and BISNIS-27 Index.'
        }else if(data === 'B1'){
            return 'This Stock included in LQ45 Index, the Jakarta Islamic Index and BISNIS-27 Index.'
        }else if(data === 'B2'){
            return 'This Stock included in the Jakarta Islamic Index, Kompas100 Index and BISNIS-27 Index.'
        }else if(data === 'BX'){
            return 'This Stock included in the Jakarta Islamic Index, LQ45 Index, Kompas100 Index and BISNIS-27 Index.'
        }else if(data === 'LB'){
            return 'This Stock included in LQ45 Index and Business27 Index.'
        }else if(data === 'KB'){
            return 'This Stock included in Kompas100 Index and BISNIS-27 Index.'
        }else if(data === 'B-'){
            return 'This Stock included in BISNIS-27 Index.'
        }
    }
    // untuk kode 7& 8
    else if(type ==='f4'){
        if(data === '-'){
            return 'The stock cannot be traded in pre opening session'
        }else if(data === 'O'){
            return 'The stock can be traded in pre opening session'
        }else if(data === '1'){
            return 'The stock belongs to the Main Board and is constituent of MBX index'
        }else if(data === 'K-'){
            return 'The stock belongs to the Development Board and is constituent of DBX index'
        }
    }
}


const d = {
    // My Account Panel
    // 2. Stock & cash
    scSettlement:[
        {"label":"Receiveable","t0":"0","t1":"0","t2":"0"},
        {"label":"Payable","t0":"0","t1":"0","t2":"0"},
        {"label":"Tax + Fee","t0":"0","t1":"0","t2":"0"},
        {"label":"Penalty","t0":"0","t1":"0","t2":"0"},
        {"label":"Settlement Amount","t0":"0","t1":"0","t2":"0"},
        {"label":"Cash Balance","t0":"0","t1":"0","t2":"0"},
        {"label":"Total","t0":"0","t1":"0","t2":"0"},
    ],
    // 5.Account Info
    ai:{
        ai1:[{"label":"KSEI A/C No","value":""},
            {"label":"Alt Code","value":""},
            {"label":"KSEI SID","value":""},
            {"label":"User ID","value":""},
            {"label":"A/C Status Name","value":""},
            {"label":"ID Type","value":""},
            {"label":"ID No","value":""},
            {"label":"ID Expire Date","value":""},
            {"label":"Customer Type","value":""},
            {"label":"Country","value":""},
            {"label":"Mother's Name","value":""},
            {"label":"Sub Name","value":""},
            {"label":"Job","value":""},
            {"label":"Opening Date","value":""},
            {"label":"A/C Closing Date","value":""},
            {"label":"Opening Branch","value":""},
        ],
        ai2:[{"label":"Withholding Tax","value":""},
            {"label":"Dividend Tax","value":""},
            {"label":"Commission Type","value":""},
            {"label":"Bank Code","value":""},
            {"label":"Account No","value":""},
            {"label":"Account Name","value":""},
            {"label":"Branch","value":""},
            {"label":"Auto Transfer","value":""},
            {"label":"Virtual Account No","value":""},
            {"label":"Penalty Type","value":""},
            {"label":"No. of PIN Error","value":""},
            {"label":"PIN Code Initialize","value":""},
            {"label":"Managing Branch","value":""},
            {"label":"Tax Rate","value":""},
            {"label":"Initial Deposit Amount","value":""},
            {"label":"Order Permit","value":""},
        ],
        ci1:[{"label":"Date of Birth","value":""},
            {"label":"Place of Birth","value":""},
            {"label":"Marriage","value":""},
            {"label":"Spouse's Name","value":""},
            {"label":"Manager","value":""},
            {"label":"Recommender","value":""},
        ],
        ciJob:[{"label":"Position","value":""},
            {"label":"Company Name","value":""},
            {"label":"Company Officer","value":""},
            {"label":"Company Type","value":""},
            {"label":"Interest Type","value":""},
            {"label":"Affiliatied Co","value":""},
            {"label":"Unknown Addr/Phone","value":""},
            {"label":"Email","value":""},
            {"label":"Mobile1","value":""},
            {"label":"Mobile2","value":""},
        ],
        ciContact:[
            {"item":"\xa0","telno":"","faxno":""},
            {"item":"\xa0","telno":"","faxno":""},
            {"item":"\xa0","telno":"","faxno":""},
        ],
        ciAddress:[
            {"item":"\xa0","postno":"","address":"","address2":""},
            {"item":"\xa0","postno":"","address":"","address2":""},
            {"item":"\xa0","postno":"","address":"","address2":""},
        ],
        rdi:[{"label":"RDI Bank","value":""},
            {"label":"RDI Account Number","value":""},
            {"label":"RDI Account Name","value":""},
        ]
    },
    stockTradeSummary:[
        // {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        // {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        // {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        // {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
        {price:"\xa0", volume:"\xa0", value:"\xa0", freq:"\xa0"},
    ],
    footerEX:[{ symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
    ],
    barReady: [
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
        { symbol: '-', last: '', change: 0, percentage: 0,},
    ],
    // sidebar
    sdcode:['\xa0','\xa0','\xa0','\xa0','\xa0','\xa0','\xa0'],
    // dummy data from investment board
    // 1. portfolio
    fPortfolio:{ code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", avgprice: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", lastprice: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", lot: "",  amount: "", shares: "", stockval: "", pl: "",remark: "",action:""},  
    // 2. Fixed Income
    fFixedIncome:{ no: "", serial: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", nominal: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", coupon: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", couponpdate: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", duedate: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", detail: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", action: ""},
    
    // 3.Mutual Fund
    fMutualFund:{ code: "", nav: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", navdate: "", currency: "", potentialpl: "", action:""},    
    
    topGal: [
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
        { no : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", code : "", prev : "", last : "", change : "", persen : "", open : "", low : "", high : "", avg : "", val : "", vol : "", freq : "", fbuy : "", fsell : "", fnet : "", financial : "", action:""},
    ],
    // dummy data from stock & cash
    // 2. stock & cash
    fStockCash:{ codeR: "", avgpriceR: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", lastpriceR: "", plot: "", pshares: "", mktvalueR: "", plR: "", persenR: "", slot: "", sshares: "", lqValR: "", stockValR:""},
    
    // 3.2 transaction historycal
    fTransHistory:{ date: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", detail: "", amount: "", code: '', inOut: '', balA: '', balQ: '', fee: '', paidAmt: '', penalty: '', add: '', tradeAmt: '', wht: '', incomeTax: '', price: '',},
    transHistory:[
        { date: "22/06/2019", detail: "Buy TS", amount: "34,500", code: 'AALI', inOut: '100', balA: '82,232', balQ: '200', fee: '50', paidAmt: '0', penalty: '0', add: '0', tradeAmt: '23,111', wht: '10', incomeTax: '0', price: '100',},
        { date: "22/06/2019", detail: "Sell TS", amount: "34,500", code: 'AALI', inOut: '130', balA: '88,232', balQ: '10', fee: '100', paidAmt: '0', penalty: '0', add: '0', tradeAmt: '21,111', wht: '15', incomeTax: '0', price: '200',},
    ],
    // 3.1 trade list histiry
    fmaTradeList:{   date: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", trade: "", order: "", code: "", cmd: "", type: "", mkt: "", vol: ""   , price:""  , amount:"", time:""},
    // 4.2 F/T List
    fFtlist:{ date: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", no: "", amount: '', fee: "", bank: "", bankAcNo: "", reqData: "", reqTime: "", status:"",},
    stockWatchlist:[
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
        { code: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price: "", change: "", persen: "", tvol: ""},
    ],
    // 6.1 trade control
    fTradeControl:{ order: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", settle: "", stockBuy: "", stockSell: "", amountBuy: "", amountSell: "",},
    stockTradeTick:[{ time : "0", persen : "", price: "", change: "", vol :"", buyer :"", seller :"",
    }],
    tradeOrderSum:[
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},        
        { code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", leaveVol :"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", tradeVol : "", avgPrice : "", cmd :"",vol:""},        
    ],
    tradeTradeSum:[
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},
        {code : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0",price :"",vol : "", cmd :"",},        
    ],
    xBid:[
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''},
        {bidOffer: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", noBidOffer: "", bidOfferVol: ''}
    ],
    sidebarStatusGroup:'A',
    fLiveTrade :{ time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
    liveTrade:[
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
    ],
    stockTradeSummaryMain:[
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
        { time:"0", code:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0", price:"", change:"", percent:"", vol:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0",buyer:"",seller:"",board:"\xa0\xa0\xa0\xa0\xa0\xa0\xa0" },
    ],
    summaryData: [
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
        { no : "\xa0", code : "\xa0", prev : "\xa0", last : "\xa0", change : "\xa0", persen : "\xa0", open : "\xa0", low : "\xa0", high : "\xa0", avg : "\xa0", val : "\xa0", vol : "\xa0", freq : "\xa0", fbuy : "\xa0", fsell : "\xa0", fnet : "\xa0", financial : "\xa0", action: "" },
    ],
}

const rAd = {
    // fixedIncome:addRow('portfolio',d.fixedIncome,d.fFixedIncome,Number(ceksize('fixedIncome')),7),
    // mutualFund:addRow('portfolio',d.mutualFund,d.fMutualFund,Number(ceksize('mutualFund')),6),
    // stockCash:addRow('portfolio',d.stockCash,d.fStockCash,Number(ceksize('sca')),7),
    // transHistory:addRow('portfolio',d.transHistory,d.fTransHistory,Number(ceksize('tma')),2),
    // maTradeList:addRow('portfolio',d.maTradeList,d.fmaTradeList,Number(ceksize('tma')),5),
    // FtList:addRow('portfolio',d.FtList,d.fFtList,Number(ceksize('tma')),2),
    // TradeControl:addRow('portfolio',d.TradeControl,d.fTradeControl,Number(ceksize('tma')),3),
    // liveTrade:addRow('portfolio',d.liveTrade,d.fliveTrade,Number(ceksize('lvtrade'))),
}

export default d
export {rAd, cekRemark1}