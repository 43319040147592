import React from "react";
import {AgGridReact} from "ag-grid-react";

import { ContextConnector } from "../../appcontext";
import { BIPSAppContext } from "../../AppData";
import { comparatorType, mathCall, timeCall } from '../../utils';
import { CustomTooltip } from '../../app_components';

class ModalSbnCodeDetail_Base extends React.PureComponent{
    render() {
        let p = this.props;
        // let totalRedeem = 0
        // totalRedeem = p.earlyRedemptiondByOrderCode.reduce((a,b) => a + Number(b.nominal), 0)
        return (
            <>
                <style>{'' +
                'thead.orderdetail th {' +
                '    background-color: var(--warna-header-card)!important;' +
                '}' +
                ''}
                </style>
                <div className="col-sm-12 text-white px-0 mx-0 py-2 f-12">
                    <div className="col-sm-12 row mx-0 px-0 bg-gray-tradding py-2 my-2">
                        <div className="col-sm-3 row mx-0 px-0 mb-3">
                            <div className="col-sm-12">
                                KODE PEMESANAN
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                                {p.kode_pemesanan || p.KodePemesanan}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0 mb-3">
                            <div className="col-sm-12">
                                NOMINAL KEPEMILIKAN{/* TOTAL JUMLAH PORTFOLIO  */}
                                </div>
                            <div className="col-sm-12 font-weight-bold">
                                IDR {mathCall.setRupiah(p.nominal || p.Nominal)}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0 mb-3">
                            <div className="col-sm-12">
                                TANGGAL PEMESANAN
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                                {p.tgl_pemesanan || p.TglPemesanan}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0 mb-3">
                            <div className="col-sm-12">
                                PENCAIRAN AWAL
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                            <i className={`far ${p.earlyReedem.Id ? "fa-check-square" : "fa-window-close"}`}></i> 
                                {p.earlyReedem.Id ? " YA" : " TIDAK"}
                            </div>
                        </div>

                        <div className="col-sm-3 row mx-0 px-0">
                            <div className="col-sm-12">
                                KODE BILLING
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                                {p.kode_billing || p.KodeBilling}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0">
                            <div className="col-sm-12">
                                TINGKAT KUPON(%)
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                                {mathCall.setRupiah((p.tingkat_kupon || p.TingkatKupon), 3, '0.000 %', ' %')}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0">
                            <div className="col-sm-12">
                                TANGGAL JATUH TEMPO
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                                {timeCall.todate2(p.tgl_jatuh_tempo || p.TglJatuhTempo , " ")}
                            </div>
                        </div>
                        <div className="col-sm-3 row mx-0 px-0">
                            {/* <div className="col-sm-12">
                                NOMINAL KUPON PERTAMA
                            </div>
                            <div className="col-sm-12 font-weight-bold">
                            IDR {mathCall.setRupiah(p.nominal_kupon_pertama || p.NominalKuponPertama)}
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 text-white px-0 mx-0 py-2">
                    <div className="card bg-grey">
                        <div className="card-body mx-0 px-0 my-0 py-0">
                            <div id="exTab" className="container-fluid mx-0" style={{ paddingTop : "10px" }}>
                                {/* <div className="row mb-2">
                                    <div className={"col-sm-12 px-0"}>
                                        <ul className="nav nav-pills">
                                            <li>
                                                <a href="#1b" data-toggle="tab">HISTORICAL COUPON PAYMENT</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content clearfix">
                                            <div className="tab-pane active py-2" id="1b">
                                                <div className="col-sm-12 row mx-0 px-0 bg-gray-tradding py-3">
                                                    <div className="col-sm-3">
                                                        TOTAL COUPON AMOUNT
                                                    </div>
                                                    <div className="col-sm-3 font-weight-bold">IDR {p.NominalKupon}</div>
                                                </div>
                                                <div className="col-sm-12 mx-0 px-0 py-1">
                                                    <HistCouponPaymentAgGrid/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {p.earlyRedemptiondByOrderCode && p.earlyReedem.Id &&
                                    <div id="early-show" className="row mb-2">
                                        <div className={"col-sm-12 px-0"}>
                                            <ul className="nav nav-pills">
                                                <li>
                                                    <a href="#2b" data-toggle="tab" className="bg-black-trading">
                                                        RIWAYAT PENCAIRAN AWAL
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content clearfix bg-black-trading">
                                                <div className="tab-pane active py-2" id="2b">
                                                    {/* <div className="col-sm-12 row mx-0 px-0 bg-gray-tradding py-3">
                                                        <div className="col-sm-4 row mx-0 px-0">
                                                            <div className="col-sm-6">
                                                                TOTAL JUMLAH PENCAIRAN
                                                            </div>
                                                            <div className="col-sm-6 font-weight-bold">
                                                                IDR {mathCall.setRupiah(totalRedeem)}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 row mx-0 px-0">
                                                            <div className="col-sm-6">
                                                                SISA NOMINAL PENCAIRAN
                                                            </div>
                                                            <div className="col-sm-6 font-weight-bold">
                                                                IDR {mathCall.setRupiah(p.kepemilikan_sisa || p.SisaKepemilikan)}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 row mx-0 px-0">
                                                            <div className="col-sm-6">
                                                                SISA NOMINAL PORTFOLIO
                                                            </div>
                                                            <div className="col-sm-6 font-weight-bold">
                                                                IDR {mathCall.setRupiah(p.nominal || p.Nominal)}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-12 mx-0 px-0 py-1">
                                                        <HistEarlyRedemptionAgGrid data={p.earlyRedemptiondByOrderCode /**p.detail */}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class HistCouponPaymentAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: "paymentdate", headerName: "Payment Date", sortable: true,
                    filter: "agTextColumnFilter", resizable: true,
                    width: 120, minWidth: 100, lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                    }, suppressSizeToFit: true
                },
                { field: "couponno", headerName: "Coupon No", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 85,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    },
                },
                { field: "couponamount", headerName: "Coupon Amount (IDR)", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 170,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    },
                },
                { field: "bankaccountno", headerName: "Bank Account No", sortable: true, filter: "agTextColumnFilter", resizable: true, width:180,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    },
                },
                { field: "bankaccountname", headerName: "Bank Account Name", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 190,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    },
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',

            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            rowData: [
                { paymentdate: "05 SEP 2021",
                    couponno: "1",
                    couponamount: "3.031",
                    bankaccountno: "9234231234",
                    bankaccountname: "Lailan Nimah",
                },
                { paymentdate: "06 SEP 2021",
                    couponno: "2",
                    couponamount: "3.091",
                    bankaccountno: "9234231234",
                    bankaccountname: "Lailan Nimah",
                }
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-129 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
class HistEarlyRedemptionAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                // { field: "kode_pemesanan", headerName: "Kode Pemesanan", sortable: true,
                //     filter: "agTextColumnFilter", resizable: true,
                //     width: 110, minWidth: 110, lockPosition: true, lockVisible: true,
                //     cellClass : function (params) {
                //         return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                //     }, suppressSizeToFit: true
                // },
                { field: "tgl_redeem", headerName: "Tanggal Pencairan", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    minWidth: 120, ortable: true, comparator: comparatorType.text,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    },
                },
                { field: "kode_redeem", headerName: "Kode Pencairan", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    minWidth: 120, ortable: true, comparator: comparatorType.text,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    },
                },
                // { field: "tgl_setelmen", headerName: "tanggal Pemesanan", sortable: true, filter: "agTextColumnFilter", resizable: true, width:175,
                //     cellClass : function (params) {
                //         return "text-center grid-table f-12 d-border-aggrid-right";
                //     },
                // },
                { field: "nominal", headerName: "Nominal Pencairan (IDR)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    minWidth: 110,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    },
                    valueFormatter: ({value}) => value && mathCall.setRupiah(value),
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',

            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            rowData: [
                { ordercode: "349900334244",
                    earlyredemptiondate: "10 SEP 2021",
                    redemptioncode: "ACD12334",
                    settlementdate: "11 SEP 2021",
                    redemptionamount: "1.000.000",
                },
                { ordercode: "349900334244",
                    earlyredemptiondate: "11 SEP 2021",
                    redemptioncode: "ACD12335",
                    settlementdate: "12 SEP 2021",
                    redemptionamount: "2.000.000",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    createRow = () => {
        let data = this.props.data;
        let maxHeight = (window.innerHeight - 700)/25 - data.length;
        let nrow = [];
        if(maxHeight > 0) {for(let i=0; i<= maxHeight; i++){ nrow.push({kode_redeem: "" }) }}
        return [...data, ...nrow];
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-129 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.createRow()}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

const ModalSbnCodeDetail = ContextConnector(BIPSAppContext,
    (vars) => ({
        earlyRedemptiondByOrderCode: vars.earlyRedemptiondByOrderCode
    }),[]
)(ModalSbnCodeDetail_Base);     

export default ModalSbnCodeDetail;
