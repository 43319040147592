const onGridReady = params => {
    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function() {
        setTimeout(function() {
            params.api.sizeColumnsToFit();
        });
    });

    params.api.sizeColumnsToFit();
};

const onFirstDataRendered = (params) => params.api.sizeColumnsToFit();

const comparatorType = {
    "text": (valA, valB) => valA === undefined || valB === undefined ? null : (valA || '').toLowerCase().localeCompare((valB || '').toLowerCase()),
    "integer": (valA, valB) => valA === undefined || valB === undefined ? null : valA - valB
}

/** ============= ROW AG-GRID [max heigh 757 for 100%]============= */
let maxHeight = (num, divider=32) => Math.floor((window.innerHeight - num)/divider);

const totalRow = (key, maxh) => {
    switch (key) {
        case 'card-tradePL': return  maxHeight(393); break;
        case "card-478": return maxHeight(274); break;
        case "CORPORATE_ACTION_LIST": return maxHeight(330); break;
        case "card-129": return maxHeight(661, maxh); break;
        case "card-138": return maxHeight(642, 23); break;
        case "card-192": return maxHeight(630, maxh); break;
        case "card-202": return maxHeight(565); break;
        case "grid-294": return maxHeight(532); break;
        case "card-381": return maxHeight(373); break;
        case "card-372": return maxHeight(300, maxh); break; // 14 baris
        case "card-344": return maxHeight(382, 30); break; // 12 baris
        case "card-472": return maxHeight(213); break; // 17 baris
        case "card-intIndices": return maxHeight(309); break;
        case "card-specialNotation": return maxHeight(282); break;
        case "card-tradeSO": return maxHeight(271, 27); break;
        case "card-brokerInfo": return maxHeight(271, maxh); break;
        
        default: return 0; break;
    }
}

const addRowEmpty = (sub_type, data=[], maxh=32) => {
    let dif = totalRow(sub_type, maxh) - data.length;
    if(dif >= 1){
        for(let j = 0; j < dif; j++){
            data.push({emptyRow: ""})
        }
    }
    return data;
}

export { onGridReady, onFirstDataRendered, comparatorType, addRowEmpty }