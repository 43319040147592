import React, { memo } from "react";
import {AgGridReact} from "ag-grid-react";
import anychart from 'anychart';
import AnyChart from 'anychart-react';
import { Popup } from 'semantic-ui-react';

import { ContextConnector } from "../../appcontext";
import { BIPSAppContext } from "../../AppData";
import { WSConnectionAction } from "../../appnetwork";

import { CustomTooltip } from '../../app_components';
import * as util from '../../utils';

import 'anychart/dist/css/anychart-ui.min.css';
import 'anychart/dist/js/anychart-ui.min';
import 'anychart/dist/fonts/css/anychart-font.min.css';
import 'anychart/dist/js/anychart-data-adapter.min'
import 'anychart/dist/js/anychart-annotations.min';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min';
import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap/dist/js/bootstrap');

const menuModal = [
    { id: 1, title: "Fund Information" },
    // { id: 2, title: "Company Info" },
    { id: 3, title: "Terms & Condition" },
]

class ModalEtalaseFund_Base extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            subTabNumber: 1,
            startRow: 0,
        }
    }

    setTabNumber = (tabNumber) => {
        /** disini tambahkan action saat pindah tab */
        let { IMCode } = this.props.dataTable;
        this.refs.netAction.send(util.fmsg.aperd_generalInfoByImCode(IMCode));
        this.refs.netAction.send(util.fmsg.aperd_getStakeholderByImCode(IMCode));
        
        this.setState({ tabNumber });
    }

    setSubTabNumber = (subTabNumber) => {
        /** disini tambahkan action saat pindah sub tab */
        this.setState({ subTabNumber });
    }


    render() {
        let { dataTable } = this.props;
        return (<>
            <WSConnectionAction ref="netAction" />
            <div className="col-sm-12 text-white px-0 mx-0 py-2 f-12">
                <div className="col-sm-12 row mx-0 px-0">
                    <div className="col-sm-12 px-0 mt-3 f-13 cssmenu d-border-bottom d-border-top d-border-left mb-1 small h-30">
                        <ul class="ul-menu h-27">
                            {menuModal.map(item => 
                                <li key={"smme"+item.id} 
                                    className={`col-sm-${12/menuModal.length} click-pointer d-border-right text-center ${this.state.tabNumber === item.id ? "active" : ""}`} 
                                    onClick={() => this.setTabNumber(item.id) }
                                >
                                    <a className="linkCustomStockTab h-27">
                                        <span className="f-12">{item.title}</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                    {
                        this.state.tabNumber === 1 ?
                            <FundInfo dataTable={dataTable} />
                        // : this.state.tabNumber === 2 ?
                        //     <CompanyInfo
                        //         subTabNumber={this.state.subTabNumber} 
                        //         setSubTabNumber={(tab) => this.setSubTabNumber(tab)} 
                        //     />
                        : 
                            <TempAndCondition />
                    }
                </div>
            </div>
        </>);
    }
}

/** FUND INFO */
const FundInfo_Base = memo(({ dataTable, cbByRdnNFund=[], FUND_DETAIL={}, NAV_BY_PERIODE=[] }) => {

    let custBank = cbByRdnNFund.find(d => d.FundCode === dataTable.FundCode) || {cb_name: ''};

    return (
        <div className="col-sm-12 px-0 pt-4 card-530">
            <div className="row">
                {/* <div className="col-sm-10">
                    <h3>Fund Info</h3>
                </div> */}
                {/* <div className="col-sm-2">
                    <a href={FUND_DETAIL.Fundfact} target="_blank" className="btn btn-dark btn-sm text-right mx-auto">
                        <i className="fa fa-download"></i> Fund Fact Sheet
                    </a>
                </div> */}
            </div>
            <div className="row d-border-top mt-2 pt-2">
                <div className="col-sm-3">
                    <span className="d-bold">Fund Type</span><br/>
                    <span>{FUND_DETAIL.Fund_type}</span>
                </div>
                <div className="col-sm-3">
                    <span className="d-bold">Inception Date</span><br/>
                    <span>{util.timeCall.todate1(FUND_DETAIL.Begin_Date_Offer)}</span>
                </div>
                <div className="col-sm-3">
                    <span className="d-bold">Fund Manager</span><br/>
                    <span>{dataTable.IMName}</span>
                </div>
                <div className="col-sm-3">
                    <span className="d-bold">Custodian Bank</span><br/>
                    <span>{custBank.cb_name}</span>
                </div>
            </div>
            <div className="row d-border-top mt-2 pt-2">
                <div className="col-sm-3">
                    <span className="">Nav/Unit</span>&nbsp;
                        <Popup content={`(As of ${util.timeCall.todate3(FUND_DETAIL.NAV_Date)})`}
                            mouseEnterDelay={150}
                            mouseLeaveDelay={100}
                            position='top center'
                            trigger={
                                <i className="fa fa-info-circle text-dark"
                                    style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                ></i>
                            } 
                        />
                    <br/>
                    <span className="d-bold mx-2">
                        {util.mathCall.setRupiah(FUND_DETAIL.NAV_Unit)} {FUND_DETAIL.Currency}
                    </span><br/>
                </div>
                <div className="col-sm-3">
                    <span className="">AUM</span>&nbsp; 
                        <Popup content={`(As of ${util.timeCall.todate3(FUND_DETAIL.AUM_Date)})`}
                            mouseEnterDelay={150}
                            mouseLeaveDelay={100}
                            position='top center'
                            trigger={
                                <i className="fa fa-info-circle text-dark"
                                    style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                ></i>
                            } 
                        />
                    <br/>
                    <span className="d-bold mx-2">
                        {util.mathCall.setRupiah(FUND_DETAIL.NAV)} {FUND_DETAIL.Currency}
                    </span><br/>
                </div>
                <div className="col-sm-3">
                    <a href={FUND_DETAIL.Fundfact} target="_blank" className="btn btn-dark f-12 mt-3">
                        <i className="fa fa-download"></i>&nbsp;Fund Fact Sheet
                    </a>
                </div>
                <div className="col-sm-3">
                    <a href={FUND_DETAIL.Prospectus} target="_blank" className="btn btn-dark f-12 mt-3">
                        <i className="fa fa-download"></i>&nbsp;Prospectus
                    </a>
                </div>

            </div>
            <div className="row d-border-top mt-2 pt-2">
                <div className="col-sm-12 card-430">
                    <div className="py-2">AUM Chart(5 Years)</div>
                    <EtalaseFundChart idName='chart-aum-container'
                        chartData={NAV_BY_PERIODE} 
                        fundName={FUND_DETAIL.FundName} 
                        valId={1} 
                    />
                </div>
                {/* <div className="col-sm-12 pt-3 card-430">
                    NAV Unit Chart 5 Year
                    <EtalaseFundChart idName='chart-nav-container'
                        chartData={NAV_BY_PERIODE} 
                        fundName={FUND_DETAIL.FundName} 
                        valId={2} 
                    />
                </div> */}
                    {/* <EtalaseAumChart/> */}
            </div>
        </div>
    )
})

/** CHART */
class EtalaseFundChart extends React.PureComponent {
    constructor(props){
        super(props);
    }
    
    componentDidMount() {
        $('#chart-aum-container').css('height', '100%');
        // $(this.props.idName).css('height', '100%');
    }

    render() {
        let { chartData=[], fundName='', valId=1 } = this.props;
        let firstDate =  chartData.length > 0 ? chartData[chartData.length-1][0] : '';
        let lastDate = chartData.length > 0 ? chartData[0][0] : '';

        var chart = anychart.stock();

        chart.scroller(true);
        chart.scroller().enabled(false);
        var credits = chart.credits();
        credits.enabled(false);

        anychart.onDocumentReady(function () {
            var table = anychart.data.table();
            table.addData(chartData);
            var mapping = table.mapAs({x: 0, value: valId,});    

            chart.padding().right(45);
            chart.padding().top(0);
            chart.padding().left(15);
            chart.padding().bottom(10);

            // create plot on the chart
            var plot = chart.plot(0);
            // enabled x-grid/y-grid
            plot.yGrid().enabled(true);
            plot.yGrid().stroke({color: "#555555", dash: "2 1"});
            plot.xMinorGrid().enabled(true);
            plot.xMinorGrid().stroke({color: "#555555", dash: "2 1"});

            // set orientation y-axis to the right side
            plot.yAxis().orientation('right');

            // create line series on the plot
            var aaplSeries = plot.line(mapping);
            // set series settings
            aaplSeries.name(fundName).zIndex(50);
            aaplSeries.stroke("#007bff");
            
            chart.selectRange(firstDate, lastDate);

            chart.background().fill("none");

            chart.draw();
        });

        return (
            // <div id="container" style={chartDisplay}></div>
            <AnyChart instance={chart} id={this.props.idName} />
        );
    }
}
class EtalaseAumChart extends React.PureComponent {

    componentDidMount() {
        $('#chart-aum-container').css('height', '100%');
    }

    render() {

        anychart.onDocumentReady(function () {
            // The data used in this sample can be obtained from the CDN
            anychart.data.loadCsvFile('aapl-daily.csv', function (data) {
                // create data table on loaded data
                createChart(data);
            });

        });

        var chart = anychart.stock();

        chart.scroller(true);
        chart.scroller().enabled(false);
        var credits = chart.credits();
        credits.enabled(false);

        function createChart(data) {
            var table = anychart.data.table();
            table.addData(data);
            var mapping = table.mapAs({x: 0, value: 1,});    

            chart.padding().right(45);
            chart.padding().top(0);
            chart.padding().left(15);
            chart.padding().bottom(10);

            // create plot on the chart
            var plot = chart.plot(0);
            // enabled x-grid/y-grid
            plot.yGrid().enabled(true);
            plot.yGrid().stroke({color: "#555555", dash: "2 1"});
            plot.xMinorGrid().enabled(true);
            plot.xMinorGrid().stroke({color: "#555555", dash: "2 1"});

            // set orientation y-axis to the right side
            plot.yAxis().orientation('right');

            // create line series on the plot
            var aaplSeries = plot.line(mapping);
            // set series settings
            aaplSeries.name('AAPL').zIndex(50);
            aaplSeries.stroke("#00ff08");
            
            chart.selectRange('2016-07-01', '2016-12-30');

            chart.background().fill("none");

            chart.draw();
        }

        return (
            // <div id="container" style={chartDisplay}></div>
            <AnyChart instance={chart} id="chart-aum-container" />
        );
    }
}
class EtalaseUnitChart extends React.PureComponent {

    componentDidMount() {
        $('#chart-unit-container').css('height', '100%');
    }

    render() {

        anychart.onDocumentReady(function () {
            // The data used in this sample can be obtained from the CDN
            anychart.data.loadCsvFile('aapl-daily.csv', function (data) {
                // create data table on loaded data
                createChart(data);
            });

        });

        var chart = anychart.stock();

        chart.scroller(true);
        chart.scroller().enabled(false);
        var credits = chart.credits();
        credits.enabled(false);

        function createChart(data) {
         
            var table = anychart.data.table();
            table.addData(data);
            var mapping = table.mapAs({x: 0, value: 1,});    

            chart.padding().right(45);
            chart.padding().top(0);
            chart.padding().left(15);
            chart.padding().bottom(10);

            // create plot on the chart
            var plot = chart.plot(0);
            // enabled x-grid/y-grid
            plot.yGrid().enabled(true);
            plot.yGrid().stroke({color: "#555555", dash: "2 1"});
            plot.xMinorGrid().enabled(true);
            plot.xMinorGrid().stroke({color: "#555555", dash: "2 1"});

            // set orientation y-axis to the right side
            plot.yAxis().orientation('right');

            // create line series on the plot
            var aaplSeries = plot.line(mapping);
            // set series settings
            aaplSeries.name('AAPL').zIndex(50);
            aaplSeries.stroke("#00ff08");
            
            chart.selectRange('2016-07-01', '2016-12-30');

            chart.background().fill("none");

            chart.draw();
        }

        return (
            // <div id="container" style={chartDisplay}></div>
            <AnyChart instance={chart} id="chart-unit-container" />
        );
    }
}

/** COMPANY INFO */
const subMenuCompanyProfile = [
    { id: 1, title: "General Info"},
    { id: 2, title: "Company Profile"},
    { id: 3, title: "Management Team"},
]

const CompanyInfo = memo(({ subTabNumber, setSubTabNumber, ...p }) => {

    return (
        <div className="col-sm-12 px-0 pt-0 cssmenu no-bg-image card-530">
            <ul className="ul-menu h-27">
                {subMenuCompanyProfile.map(item => 
                    <li key={"sbmcp"+item.id}
                        className={`col-sm-4 click-pointer d-border-right text-center ${subTabNumber === item.id ? "active" : ""}`} 
                        onClick={() => setSubTabNumber(item.id)}
                    >
                        <a className="linkCustomStockTab h-27">
                        <span className="f-12">{item.title}</span></a>
                    </li>
                )}
            </ul>
            {
                subTabNumber === 1 ?
                    <GeneralInfo />
                : subTabNumber === 2 ?
                    <CompanyProfile />
                :   
                    <ManagementTeam />
            }
        </div>
    )
})

const GeneralInfo_Base = memo(({GENERAL_INFO=util.dummy.APERD_GENERAL_INFO_BY_IM_CODE.data}) => {
    return (
        <div className="col-sm-12 px-0 pt-4">
            <div className="row">
                <div className="col-sm-6">
                    <h4>General Info</h4>
                    <table className="table table-responsive mb-0">
                        <tr>
                            <td className="d-bold py-1">Nama MI</td>
                            <td className="py-1">{GENERAL_INFO.IMName}</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Sebelumnya</td>
                            <td className="py-1">-</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Kode MI</td>
                            <td className="py-1">{GENERAL_INFO.IMCode}</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Latest AUM</td>
                            <td className="py-1">32.331.258.222.123.442 IDR(per: 30-Jun-2021)</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Pengelolaan</td>
                            <td className="py-1">Kelola Reksa Dana</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Kepemilikan</td>
                            <td className="py-1">Patungan</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Status</td>
                            <td className="py-1">Aktif</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Modal Dasar</td>
                            <td className="py-1">50.000.000.000 IDR</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1">Modal Disetor</td>
                            <td className="py-1">50.000.000.000 IDR</td>
                        </tr>
                        <tr>
                            <td className="d-bold py-1" colspan="2">Izin Usaha</td>
                        </tr>
                        <tr>
                            <td className="py-1 pl-4">PPE</td>
                            <td className="py-1"></td>
                        </tr>
                        <tr>
                            <td className=" py-1 pl-4">PEE </td>
                            <td className="py-1"></td>
                        </tr>
                        <tr>
                            <td className="py-1 pl-4">MI</td>
                            <td className="py-1">Kep-06/PMM/1994(1994-06-21)</td>
                        </tr>
                    </table>
                </div>
                <div className="col-sm-6">
                    <h4>StakeHolders</h4>
                    <table className="table table-responsive mb-0">
                        <tr>
                            <td className="d-bold py-1">Nama</td>
                            <td className="d-bold py-1">Kepemilikan(IDR)</td>
                            <td className="d-bold py-1">Prosentase</td>
                        </tr>
                        <tr>
                            <td className="py-1">PT. Bahana Pembinaan Indonesia</td>
                            <td className="py-1">30.000.000.000</td>
                            <td className="py-1">60%</td>
                        </tr>
                        <tr>
                            <td className="py-1">TCW Capital Investment</td>
                            <td className="py-1">20.000.000.000</td>
                            <td className="py-1">40%</td>
                        </tr>
                    </table>
                    <h4>Boards Of Directors</h4>
                    <table className="table table-responsive mb-0">
                        <tr>
                            <td className="d-bold py-1">Nama</td>
                            <td className="d-bold py-1">Jabatan</td>
                        </tr>
                        <tr>
                            <td className="py-1">Edward Parlindung Lubis</td>
                            <td className="py-1">Direktur Utama</td>
                        </tr>
                        <tr>
                            <td className="py-1">Budi Hikmat</td>
                            <td className="py-1">Direktur</td>
                        </tr>
                        <tr>
                            <td className="py-1">Rukmi Proborini</td>
                            <td className="py-1">Direktur</td>
                        </tr>
                        <tr>
                            <td className="py-1">Soni K. Wibowo</td>
                            <td className="py-1">Direktur</td>
                        </tr>
                    </table>
                    <h4>Commissioners</h4>
                    <table className="table table-responsive mb-0">
                        <tr>
                            <td className="d-bold py-1">Nama</td>
                            <td className="d-bold py-1">Jabatan</td>
                        </tr>
                        <tr>
                            <td className="py-1">Dwina Septiani K</td>
                            <td className="py-1">Komisaris Utama</td>
                        </tr>
                        <tr>
                            <td className="py-1">Marc Irwin Stern</td>
                            <td className="py-1">Komisaris</td>
                        </tr>
                        <tr>
                            <td className="py-1">Tetty Irawati Siregar</td>
                            <td className="py-1">Komisaris</td>
                        </tr>
                        <tr>
                            <td className="py-1">Stanislas Lucien Roger Debreu</td>
                            <td className="py-1">Komisaris</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
});

const CompanyProfile = memo((props) => {
    return (
        <div className="col-sm-12 px-0 pt-4">
            <h4>
                Bahana TCW Investment Management
            </h4>

            Tentang Bahana TCW<br/><br/>

            PT. Bahana TCW Investment Management(Bahana TCW) merupakan perusahaan patungan antara Perusahaan Perseroan(Persero) PT. Bahana Pembinaan Usaha Indonesia dengan 
            Trust Company of the West(TCW), sebuah perusahaan manajer investasi terkemuka yang bermarkas di Los Angeles, Amerika Serikat.<br/><br/>

            PT. Bahana TCW Investment Management didirikan tahun 1994 dan merupakan Perusahaan manajemen investasi dan penasehat investasi 
            yang telah mendapatkan ijin Badan Pengawasan Pasar Modal(Bapepam) di tahun 1995.<br/><br/>

            Pengalaman di bidang pengelolaan investasi selama lebih dari 12 tahun telah membawa PT. Bahana TCW Investment Management mendapatkan 
            kepercayaan dari berbagai nasabah baik institusi maupun individu dengan total dana kelolaan lebih dari Rp. 18,1 trililun(Desember 2010), 
            yang tersebar ke berbagai portofolio investasi dan reksadan yang disesuaikan dengan profil dan kebutuhan masing-masing nasabah. Reputasi 
            PT. Bahana TCW Investment Management juga telah meraih berbagai penghargaan baik domestik maupun internasional.<br/><br/>

            Berbekal dukungan dan kerjasama strategis dari Bahana Group dan RCS. PT Bahana TWC Investment Management akan terus berkembang dan melakukan invoasi produk serta layanan investasi yang terbaik bagi para nasabahnya.
            <br/><br/>

            Metode dan Proses<br/>
            Metode Investasi yang kami lakukan senantiasa menganut azas kepatuhan, ketelitian, dan kehati-hatian yang meliputi proses-proses sebagai berikut:<br/><br/>

            Keputusan investasi dilakukan dengan pendekatan bottom-up dan top-down dalam komite investasi yang terdiri dari professional di bidang pasar modal.<br/>
            Mengutamakan efek dengan nilai kapitalisasi pasar yang besar, salah satunya untuk menekan resiko likuiditas di pasar.<br/>
            Lebih menitik beratkan pada analisis fundamental dan makro ekonomi dibandingkan pemanfaatan fluktuasi pasar.<br/> 
            Untuk memberikan keamanan investasi bagi para nasabah kami, kami menerapkan standar tertinggi untuk manajemen resiko dan kepatuhan pada aktifitas investasi.
        </div>
    )
});

const ManagementTeam = memo((props) => {
    return (
        <div className="col-sm-12 px-0 pt-4">
            <h4>Bahana TCW Investment Management</h4>
            <span className="d-bold">Management Team</span><br/>
            <div className="pl-5">
                <i>
                    "Kegiatan manajemen Investasi BAHANA dikelola oleh tim investasi yang
                    profesional dan berpengalaman, baik dalam strategi maupun proses investasi.
                    Tim kami akan terus melakukan inovasi dalam pengelolaan investasi terus mempertahankan
                    kepercayaan yang telah kami raih."
                </i><br/><br/>
            </div>

            <span className="d-bold">Edward P. Lubis - Presiden Direktur</span><br/>
            Bertanggung jawab pada seluruh kegiatan pemasaran, operasional, dan kepatuhan.
            Edward memiliki 10 tahun pengamalan di bidang pasar modal, khususnya perdagangan efek surat hutang.
            Edwar bergabung dengan BAHANA pada tahun 2003 dan memiliki izin manajer investasi (KEP-94/PM/IP/WMI/1997/)
            dan izin penjamin emisi(KEP-04/OM/IP/PEE/1997) dari Bapepam. Edward mendapatkan gelar Master of Business Administration dari University of Hawaii USA.<br/><br/>

            <span className="d-bold">Budi Hikmat - Direktur</span><br/>
            Budi Hikmat merupakan penanggung jawab dari seluruh kegiatan research dan investasi,
            serta mendapatkan penghargaan sebagai indonesia Best Domestic Macroeconomist dari Asiamoney pada tahun 2003.
            Budi bergabung dengan BAHANA pada tahun 1997 dan memiliki izin manajer investasi (KEP-104/PM/PI/WMI/2004)
            dari Bapepam. Budi mendapatkan gelar sarjana ekonomi dari Universitas Indonesia dan gelar Master di bidang ekonomi dari National University of Singapore.
            Budi juga merupakan pengajar tetap dan peneliti senior pada Lembaga Penelitian Ekonomi dan Sosial Universitas Indonesia.<br/><br/>

            <span className="d-bold">Rukmi Proborini - Direktur</span><br/>
            Bertanggung jawab pada kegiatan pemasaran dan pengembangan produk. Rukmi memiliki 7 tahun pengalaman di bidang pasar modal khususnya investment banking.
            Rukmi bergabung dengan BAHANA pada tahun 1999 dan memiliki izin manajer investasi dari Bapepam (KEP23/PM/PI/WMI/2006). 
            Rukmi mendapatkan gelar Master of Business Administartion dari University of Edinburgh UK dadn Master of Science bidang Investment & banking dari University of Reading UK.<br/><br/>

        </div>
    )
});

/** TERM & CONDITION */
const TempAndCondition = memo(({ fundDetail }) => {
    let { termAndCondition={} } = util.refAperd;
    return (
        <div className="col-sm-12 px-0 pt-4 card-530">
            {
                Object.keys(termAndCondition).map(term => 
                    <>
                        <h3>{term}</h3>
                        <ul>
                            {termAndCondition[term].map((item, id) => 
                                <li key={"termandcond"+id}>
                                    {item}
                                </li>
                            )}
                        </ul>
                    </>
                )
            }
        </div>
    )
})

class OrderLictCodeAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: "dateTop", headerName: "", sortable: true,
                    filter: "agTextColumnFilter", resizable: true,
                    width: 69, minWidth: 69, lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                    }, suppressSizeToFit: true, children: [{
                        field: "dateO", headerName: "Date", sortable: true,
                        filter: "agTextColumnFilter", resizable: true,
                        width: 69, minWidth: 69, lockPosition: true, lockVisible: true,
                        cellClass : function (params) {
                            return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                        }, suppressSizeToFit: true
                    },]},
                { field: "timeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "timeO", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "marketOrderTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 150,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "marketOrderNo0", headerName: "Market Order No.", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 140, minWidth: 140,
                            cellClass : function (params) {
                                return "text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "actionsTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width:160,
                    cellClass : function (params) {
                        var pl = params.data.actionsO;
                        return pl.includes('-') === true ? "text-danger text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                            "text-success text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                    }, children: [{ field: "actionsO", headerName: "Action", sortable: true, filter: "agTextColumnFilter", resizable: true,
                        width: 80, minWidth: 80,
                        cellClass : function (params) {
                            var pl = params.data.actionsO;
                            return pl === 'buy' ? "text-danger text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'sell' ? "text-success text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'amend' ? "text-primary text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                pl === 'withdraw' ? "text-warning text-center grid-table f-12 d-border-aggrid-right text-uppercase" :
                                    "text-center grid-table f-12 d-border-aggrid-right text-uppercase";
                        }
                    },],
                },
                { field: "orderTypeTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 130,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "orderType0", headerName: "Order Type", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 130, minWidth: 130,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            }
                        },
                    ],
                },
                { field: "statusTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                width: 130,
                cellClass : function (params) {
                    return "text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                }, children: [
                    { field: "statusO", headerName: "Status", sortable: true, filter: "agTextColumnFilter", resizable: true,
                        width: 130, minWidth: 130,
                        cellClass : function (params) {
                            return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                        }
                    },
                ],
            },
                { field: "remaksTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 230, suppressSizeToFit: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                    }, children: [
                        { field: "remarksO", headerName: "Remark", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            suppressSizeToFit: true,tooltipField: 'remarksO',
                            tooltipComponentParams: { type: 'remarksO' },
                            
                            width: 230, minWidth: 230,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right text-capitalize";
                            }
                        },
                    ],
                },
                { field: "vol", headerName: "Vol", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 260,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "vlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },

                    ]
                },
                { field: "priceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: this.props.gridView == 'grid' ? 94 : 206,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "priceO", headerName: "Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 100, minWidth: 100,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "matchvol", headerName: "Match Vol", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "mlot", headerName: "Lot", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                        { field: "mshares", headerName: "Shares", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            },
                        }
                    ]
                },
                { field: "matchpriceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "matchpriceO", headerName: "Match Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "leaveVolTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "leaveVol0", headerName: "Leave Vol(Lot)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 150, minWidth: 150,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "userIdTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true, width: 110,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "userId0", headerName: "User ID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                            width: 110, minWidth: 110,
                            cellClass : function (params) {
                                return " text-center grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                tooltipComponent: 'customTooltip',

            },
            tooltipShowDelay: 0,
            frameworkComponents: {customTooltip: CustomTooltip},
            rowData: [
                { dateO: "04/07/2019",
                    timeO: "11:22:17",
                    actionsO: "withdraw",
                    orderType0: "Limit",
                    statusO: "done",
                    remarksO: "Order Amend Request Confirmed 123123123",
                    vlot: "10",
                    vshares: "1,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"12,650,000" ,
                    marketOrderNo0: "MKT0001",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:12:10",
                    actionsO: "",
                    orderType0: "Market",
                    statusO: "partial",
                    remarksO: "Order Amend Request Confirmed",
                    vlot: "30",
                    vshares: "3,000",
                    priceO: "12,650",
                    mlot: "20",
                    mshares: "2,000"   ,
                    matchpriceO:"12,600",
                    amountO:"25,300,000",
                    marketOrderNo0: "MKT0002",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:10:17",
                    actionsO: "amend",
                    orderType0: "Limit",
                    statusO: "done",
                    remarksO: "",
                    vlot: "30",
                    vshares: "3,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"37,950,000",
                    marketOrderNo0: "MKT0003",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
                { dateO: "04/07/2019",
                    timeO: "11:02:55",
                    actionsO: "",
                    statusO: "partial",
                    remarksO: "patial match",
                    vlot: "100",
                    vshares: "10,000",
                    priceO: "12,650",
                    mlot: "50",
                    mshares: "5,000"   ,
                    matchpriceO:"12,600",
                    amountO:"63,250,000",
                    marketOrderNo0: "MKT0004",
                    leaveVol0: "10",
                    userId0: "johndide",},
                { dateO: "04/07/2019",
                    timeO: "11:00:23",
                    actionsO: "buy",
                    statusO: "done",
                    remarksO: "",
                    vlot: "100",
                    vshares: "10,000",
                    priceO: "12,650",
                    mlot: "",
                    mshares: ""   ,
                    matchpriceO:"",
                    amountO:"126,500,000",
                    marketOrderNo0: "MKT0005",
                    leaveVol0: "10",
                    userId0: "johndide",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-233 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

/** CONTEXT CONNECTOR  */
const ModalEtalaseFund = ContextConnector(BIPSAppContext,
    (vars) => ({
        COMPANY_PROFILE: vars.COMPANY_PROFILE,
        FUND_DETAIL: vars.FUND_DETAIL,
    }),
)(ModalEtalaseFund_Base);

const GeneralInfo = ContextConnector(BIPSAppContext,
    (vars) => ({
        GENERAL_INFO: vars.GENERAL_INFO,
    }),
)(GeneralInfo_Base);

const FundInfo = ContextConnector(BIPSAppContext,
    (vars) => ({
        NAV_BY_PERIODE: vars.NAV_BY_PERIODE,
        FUND_DETAIL: vars.FUND_DETAIL,
        cbByRdnNFund: vars.cbByRdnNFund
    }),
)(FundInfo_Base);

export default ModalEtalaseFund;