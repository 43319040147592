import React from 'react';
import { Input, Checkbox } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import {AgGridReact} from "ag-grid-react";
import Select from "react-select";
import anychart from 'anychart';
import $ from "jquery";
import {Table} from "react-bootstrap";

import { AppFrame, AppFrameAction } from "../appframe";
import { BIPSAppContext, ConvertToRupiah, ColorLabel, IconChange, searchCode, GenerateId } from '../AppData.js';
import { ContextConnector } from '../appcontext.js';

import FillHeaderTab from "../tabheaderfill";
import { ModalBuy, ModalSell, ModalOrderDetail } from '../app_modals';
import { SpecialNotationModal } from "./stockPage";
import VerifyPIN, {tanggal} from "./verifyPin";
import { ResizeResponsive, widthSize, comparatorType, setTempData, thousandSeparator, mathCall, GetTimeForDateTicker, selectSelectionTab } from '../utils';

import AnyChart from '../../node_modules/anychart-react/dist/anychart-react.min.js'
import user_avatar from "../img/man.png";
import '../bootstrap-3.3.7/bootstrap-datepicker.min.css';

const stateOptionsLp = [
    { key: 'lastprice', value: 'lastprice', text: 'Last Price' },
    { key: 'bestofferprice', value: 'bestofferprice', text: 'Best Offer Price' },
    { key: 'bestbidprice', value: 'bestbidprice', text: 'Best Bid Price' },
];

const stateOptionsOperator = [
    { key: 'lebihkecil', value: 'lebihkecil', text: '< =' },
    { key: 'lebihbesar', value: 'lebihbesar', text: '> =' },
];

//============= Sub header of Stock Page =============
// 1.Investment Board, 2.Stock & cash, 3.Historicals, 4.Fund transfer, 5.TC & SOE
const CustomFrameHeaderLanding = (props) =>{
    return (
        <div>
            <div className="row col-sm-12 px-0 mx-0 align-self-center">
                <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                    <FillHeaderTab treeName="/landingPage" linkTitles={
                        {
                            landingPageInvboard: 'INVESTMENT BOARD',
                            stockCashPageInvboard: 'STOCK & CASH',
                            tradeListHistoryPageInvboard: 'HISTORICAL',
                            fundTransferPageInvboard: 'FUND TRANSFER',
                            WarrantRightPageInvboard: 'WARRANT/RIGHT EXERCISE',
                            inquryAccountPageInvboard: 'ACCOUNT INFO',
                            tradePLPageInvboard: 'TRADE P/L',
                            // InvboardTcAndSoa: 'TC & SOA',
                        }
                    }/>
                </div>
            </div>
            <AppFrame treeName="/landingPage" headerComponent={LandingFrameHeader}/>
        </div>
    );
}

const LandingFrameHeader = (props) => {
    return (
        <>
        </>
    );
}

class Landing extends React.PureComponent {
    render () {
    return (
        <div className="bg-black-trading px-0 mx-0">
        </div>
    );
    }

    componentDidMount(){
        $(".pincode-input-text").on('keypress',function(e) {
            if(e.which == 13) {
                $("#pin-click-verify").click();
                }
            });
    }
    render () {
        return (
            //hanya memanggil headernya saja
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}

//============= 1. Investment Board Display =============
class LandingPage_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var input = document.getElementById("press_login");
        input.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById("click_login").click();
            }
        });     
        $('#pieChart').css('height', '100%');
        
    }

    closeClick = (e) => {
        this.refs.frameActions.closeModal(100);
    }

    buttonClickBuy = (e) => {
        this.refs.frameActions.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-border click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: BuyModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    buttonClickSell = (e) => {
        this.refs.frameActions.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-border click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: SellModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    render() {               
        var p = this.props;
        var data = [
            {
                x: "Equity Portfolio", value: p.portofolioInfo.mktVal,
                normal:  {
                    fill: "#88C800",
                },
                hovered: {
                    outline: {
                        enabled: true,
                    }
                },
                selected: {
                    outline: {
                        enabled: true,
                    }
                }
            },
            {
                x: "Cash", value: p.portofolioInfo.balance,
                normal:  {
                    fill: "#F8A32F",
                },
                hovered: {
                    outline: {
                        enabled: true,
                    }
                },
                selected: {
                    outline: {
                        enabled: true,
                    }
                }
            },
        ]; 
        var chart = anychart.pie3d(data);
        var credits = chart.credits();
        credits.enabled(false);

        anychart.onDocumentReady(function () {
            // set the container id
            // create a chart and set the data
            // chart.data(newData);
            // set the position of the legend
            chart.legend().position("bottom");

            // set the alignment of the legend
            chart.legend().align("center");

            // set the layout of the legend
            chart.legend().itemsLayout("horizontal-expandable");

            var legends = chart.legend();

            legends.padding(10);
            legends.fontColor("white");
            var backgrounds = legends.background();

            backgrounds.fill("#8597B0");
            backgrounds.enabled(true);
            // backgrounds.stroke("#96a6a6");
            backgrounds.cornerType("round");
            backgrounds.corners(10);

            chart.normal().outline().enabled(true);
            chart.normal().outline().width("5%");
            chart.hovered().outline().width("10%");
            chart.selected().outline().width("3");
            chart.selected().outline().fill("#455a64");
            chart.selected().outline().stroke(null);
            chart.selected().outline().offset(2);

            // configure connectors
            chart.connectorStroke({color: "#888888", thickness: 2, dash:"2 2"});

            // explode the fourth and fifth slices
            /*chart.select(2);*/

            // set the position of labels
            chart.labels().position("outside");

            // // set the chart title
            // chart.title("Investment Board");

            chart.listen("pointsSelect", function(e) {
                var points = e.point.index;
                if (points === 0){
                    p.changeStateLanding('0');
                    if (e.point.selected()) {
                        chart.unselect([1]);
                    } else {
                        chart.unselect([1]);
                        chart.select(0);
                    }
                } else if (points === 1){
                    p.changeStateLanding('1');
                    if (e.point.selected()) {
                        chart.unselect([0,1]);
                    } else {
                        chart.unselect([0,1]);
                        chart.select(1);
                    }
                }
            });

            chart.legend().listen("legendItemClick", function(e) {
                var legend = e.itemIndex;
                // Set disturber.
                /*chart.select([5]);*/
                chart.select([2]);
                if (legend === 0){
                    chart.unselect([1]);
                    p.changeStateLanding('0');
                } else if (legend === 1){
                    p.changeStateLanding('1');
                    chart.unselect([0,1]);
                }
            });

            /*chart.unselect([1,2,3]);
            chart.select(0);*/

            // set the container id
            chart.container("pieChart");

            // initiate drawing the chart
            chart.draw();
        });

        return (
            <div className="container-fluid px-0 bg-black-trading">
                <div className="card-527 col-sm-12 px-0 mx-0 row">
                    <div className="col-sm-4 px-0">
                        <AnyChart instance={chart} id="pieChart" title="Investment Board"/>
                    </div>
                    {/* <div id="pieChart" className="col-sm-4 px-0"></div> */}
                    <div className="col-sm-8 px-0 d-border-left">
                        <AppFrameAction ref="frameActions" />
                        <main>
                            <div className="container-fluid px-0">
                                <div className="container px-0 mx-0 col-sm-12 bg-grey" style={{display: p.stateLanding === 'k'  ? 'block' : 'none'}}>
                                    <div className="card-body card-527 align-self-center text-center bg-grey f-14 py-3">
                                        <div className="py-5 my-5">
                                            <div className="py-5 my-5">
                                                <i className="icon-icon-portofolio f-25"></i>
                                                <div>Please choose one menu in chart pie to show</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container px-0 mx-0 col-sm-12" style={{display: p.stateLanding == '0' ? 'block' : 'none'}}>
                                    <div id="pinPortofolio" className={`${(p.verifyPinStatus || p.pinPage === "portfolioPage") ? "d-none" : "d-block"} text-center align-self-center`}>
                                        <VerifyPINPortofolio pos="portofolio" onClose={() => null} />
                                    </div>
                                    <div id="detailPortofolio" className={`${(p.verifyPinStatus || p.pinPage === "portfolioPage") ? "d-block" : "d-none"}`}>
                                        <div className="card-header card-header-investment bg-grey h-40">
                                            <div className="row col-sm-12 px-0 mx-0 py-1">
                                                <div className="col-sm-3 px-4 mx-0 f-14">
                                                    <b>Equity Portfolio</b>
                                                </div>
                                                <div className="col-sm-3 px-4 mx-0 f-14">
                                                    Market Val: <span className="text-primary">{mathCall.setRupiah(p.portofolioInfo.mktVal)}</span>
                                                </div>
                                                <div className="col-sm-3 px-4 mx-0 f-14">
                                                    P/L : <span className={ColorLabel(Number(p.portofolioInfo.plRatio))}>
                                                    <i className={IconChange(Number(p.portofolioInfo.plRatio)) }>
                                                    </i>&nbsp;{ConvertToRupiah(p.portofolioInfo.pl)+" ("+p.portofolioInfo.plRatio+"%)"}</span>
                                                </div>
                                                <div className={"col-sm-2 px-4 mx-0 f-14"}>
                                                    <button
                                                        className={ p.portofolioInfo.stream ? 'btn btn-danger' : 'btn btn-success' }
                                                        style={{"fontSize":"12px","marginTop":"-7px"}}
                                                        onClick={()=>p.handleOnClick("landingPage","startStopStreamPortfolio","none")}
                                                    >
                                                        { p.portofolioInfo.stream ? 'Stop Live Update' : 'Resume Live Update'}
                                                    </button>
                                                </div>
                                                <div className={"col-sm-1 px-4 mx-0 f-14"}>
                                                    <button
                                                        onClick={()=>p.handleOnClick("refreshPage","portfolioMyAccount","none")}
                                                        className="pull-right btn btn-primary"
                                                        style={{"fontSize":"12px","marginTop":"-7px","width":"38px"}}>
                                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <PortofolioAgGrid size={widthSize()} gridView="tab" classView="f-12" clickbuy={this.buttonClickBuy} clicksell={this.buttonClickSell} tp1="ptooltip1" tp2="ptooltip2" tp3="ptooltip3" tp4="ptooltip4" tp5="ptooltip5"
                                                 portofolios={p.portofolios}  handleOnClick={(pageType, type, data)=>p.handleOnClick(pageType, type, data)} />
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="container px-0 mx-0 col-sm-12" style={{display: p.stateLanding === '1' ? 'block' : 'none'}}>
                                    <div className="card-header header-pegadaian bg-grey">
                                        <div className="row col-sm-12 px-0 mx-0 py-3">
                                            <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                                            <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                                                <i className="f-18 ion ion-md-sync click-pointer"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body align-self-center text-center f-16 py-5">
                                        <div className="py-5">
                                            <div className="py-5">
                                                <i className="icofont icofont-warning-alt f-18"></i>
                                                <p>Not Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                                <div className="container px-0 mx-0 col-sm-12" style={{display: p.stateLanding == '1' ? 'block' : 'none'}}>
                                    <div className="card-header card-header-investment bg-grey h-40">
                                        <div className="row col-sm-12 px-0 mx-0 py-1">
                                            <div className="col-sm-5 px-4 mx-0 f-14">
                                                Total Nominal : <span className="text-primary">0</span>
                                            </div>
                                            <div className="col-sm-5 px-4 mx-0 f-14"></div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <FixedIncomeAgGrid size={widthSize()} gridView="tab" classView="f-12"/>
                                    </div>
                                </div>
                                <div className="container px-0 mx-0 col-sm-12" style={{display: p.stateLanding == '2' ? 'block' : 'none'}}>
                                    <div className="card-header card-header-investment bg-grey h-40">
                                        <div className="row col-sm-12 px-0 mx-0 py-1">
                                            <div className="col-sm-4 px-4 mx-0 f-14">
                                                Invested : <span className="text-primary">0</span>
                                            </div>
                                            <div className="col-sm-4 px-4 mx-0 f-14">
                                                P/L : <span className="text-success">0 (0)</span>
                                                {/* P/L : <span className="text-success">+496,198 (+9.50%)</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <MutualFundAgGrid size={widthSize()} gridView="tab" classView="f-12" />
                                    </div>
                                </div>
                                <div className="container px-0 mx-0 col-sm-12 bg-grey" style={{display: p.stateLanding === '4' ? 'block' : 'none'}}>
                                    <div className="card-body card-527 align-self-center text-center bg-grey f-14 py-3">
                                        <div className="py-5 my-5">
                                            <div className="py-5 my-5">
                                                <i className="icon-icon-portofolio f-25"></i>
                                                <div>Please choose one menu in chart pie to show</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}
//============= 1.a Table of portfolio =============
class PortofolioAgGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: 80,
                    minWidth: 80,  suppressSizeToFit: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-left f-12 d-border-aggrid-right";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code 
                },
                { field: "avgprice", headerName: "Avg. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?185:s=="s50"?170:s=="s67"?140:s=="s75"?135:110, minWidth: 110,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : ConvertToRupiah(params.data.avgprice)
                },
                { field: "lastprice", headerName: "Last Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?154:110, minWidth: 110,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var pl1 = params.data.pl.split("|")[1];
                        return pl1 < 0 ?  "text-danger text-right  grid-table  d-border-aggrid-right f-12" :
                            (pl1 > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" : 
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right");
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : ConvertToRupiah(params.data.lastprice)
                },
                { field: "lot", headerName: "Lot", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?100:s=="s67"?80:s=="s75"?75:70, minWidth: 70,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var pl1 = params.data.pl.split("|")[1];
                        return pl1 < 0 ? "text-danger text-right grid-table  d-border-aggrid-right f-12":
                            (pl1 > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right");
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : ConvertToRupiah(params.data.lot)
                },
                { field: "shares", headerName: "Shares", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?160:s=="s50"?150:s=="s67"?110:100, minWidth: 100,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var pl1 = params.data.pl.split("|")[1];
                        return pl1 < 0 ? "text-danger text-right grid-table d-border-aggrid-right f-12": 
                            pl1 > 0 ? "text-success text-right grid-table d-border-aggrid-right f-12":
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : ConvertToRupiah(params.data.shares)
                },
                { field: "stockval", headerName: "Stock Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?245:s=="s67"?220:s=="s75"?190:120,
                    minWidth: 120,                
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        var pl1 = params.data.pl.split("|")[1];
                        return pl1 < 0 ? "text-danger text-right grid-table d-border-aggrid-right f-12":
                            pl1 > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : ConvertToRupiah(params.data.stockval) ,
                },
                { field: "pl", headerName: "P/L %", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?190:s=="s67"?180:s=="s75"?160:150,
                    minWidth: 150,  
                    comparator: stringComparator,                  
                    cellClass : function (params) {                       
                        var pl1 = params.data.pl.split("|")[1];
                        return pl1 < 0 ? "text-danger text-right grid-table d-border-aggrid-right f-12":
                            pl1 > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    },
                    cellRenderer : function (params) {
                        var pl = params.data.pl
                        var pl0 = params.data.pl.split("|")[0];
                        var pl1 = params.data.pl.split("|")[1];
                        if(params.data.code === "\xa0"){
                            return ""
                        }else if(params.data.pl === '0|0'){
                            return 0
                        }
                        else{
                            return pl1 < 0 ? ConvertToRupiah(pl0)+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+ConvertToRupiah(pl1)+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-down text-danger"></i>' :
                                pl1 > 0 ? ConvertToRupiah(pl0)+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+ConvertToRupiah(pl1)+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-up text-success"></i>' :
                                ConvertToRupiah(pl0)+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+ConvertToRupiah(pl1) +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont icofont-minus text-warning"></i>';
                        }
                    }
                },
                { field: "notation", headerName: "Special Notation", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?175:s=="s50"?155:s=="s67"?150:s=="s75"?150:150,
                    minWidth:150,
                    cellClass : function (params) {
                        return " grid-table text-primary d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer : function (params) {
                        var notation = params.data.notation;
                        var eDiv = document.createElement('div');

                        if(params.data.code === "\xa0" || notation === ""){
                            eDiv.innerHTML = "";
                            return eDiv;
                        }else{
                            eDiv.innerHTML = '<span class="btn-cellorder click-pointer px-1">' +notation+ '</span>';
                            var aButton = eDiv.querySelectorAll('.btn-cellorder')[0];

                            function closeClick(){
                                self.refs.frameAction.closeModal(1000);
                            }

                            function clickNotationDetail(){
                                self.refs.frameAction.showModal({
                                    headerClass: () =>
                                        <div className="col-sm-12 px-0 py-0 mx-0 text-right">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={closeClick}></i>
                                        </div>,
                                    size: 'tiny',
                                    contentClass: SpecialNotationModal,
                                    onOpen :self.props.handleOnClick('stockPage', 'specialNotationDetail',params.data.notationDes),
                                })
                            }
                            aButton.addEventListener('click', clickNotationDetail);
                            return eDiv;
                        }
                    },
                },
                { field: "action", headerName: "Action", sortable: false,
                    width: s=="s49"?180:s=="s50"?160:150, pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table locked-col locked-visible d-border-aggrid-right text-center";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.lot !== ''){
                            eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellbuy btn btn-sm btn-danger mx-1 f-9 w-50">Buy more</button>' +
                                '<button class="btn-cellsell btn btn-sm btn-success mx-1 f-9 w-50">Sell</button>' +
                                '</span>';
                            var bButton = eDiv.querySelectorAll('.btn-cellbuy')[0];
                            var sButton = eDiv.querySelectorAll('.btn-cellsell')[0];

                            function closeClick(){
                                self.refs.frameAction.closeModal(1000);
                            }
    
                            function buyFunction(){
                                self.refs.frameAction.showModal({
                                    headerClass: () => <div className="text-right">
                                            <i className="icofont icofont-close text-icofont-close text-border click-pointer" onClick={closeClick}></i>
                                        </div>,
                                    size: 'large',
                                    contentClass: BuyModal,
                                    onOpen :self.props.handleOnClick('transaction', 'openBuySellModal', params.data.code),
                                    onClose:()=>self.props.handleOnClick('transaction','closeBuySellModal', "none")
                                })
                            }
    
                            function sellFunction(){                            
                                self.refs.frameAction.showModal({
                                    headerClass:() => <div className="text-right">
                                            <i className="icofont icofont-close text-icofont-close text-border click-pointer" onClick={closeClick}></i>
                                        </div>,
                                    size:'large',
                                    contentClass:SellModal,
                                    onOpen :self.props.handleOnClick('transaction', 'openBuySellModal', params.data.code),
                                    onClose:()=>self.props.handleOnClick('transaction','closeBuySellModal', "none")
                                })
                            }
                            bButton.addEventListener('click', buyFunction);
                            sButton.addEventListener('click', sellFunction);
                        } else {
                            // eDiv.innerHTML = '<span>' +
                            //     '<i class="tolTipRemaks fa fa-info-circle text-info" id="ptooltip1" data-tip="true" data-for="infoTooltip"></i>' +
                            //     '</span>';
                            eDiv.innerHTML = '';
                        }
                        return eDiv;
                    }, suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 500);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        let ndata = this.props.portofolios.filter(nz=>Number(nz.shares !==0))
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-487 ag-theme-balham-dark ag-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={ndata} 
                        rowHeight={32}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
//============= 1.b Table of Fixed Income =============
class FixedIncomeAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?100:s=="s50"?85:s=="s75"?80:56, minWidth: 56,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    },
                },
                { field: "serial", headerName: "Serial", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?185:s=="s50"?175:s=="s75"?170:130, minWidth: 130,
                    suppressSizeToFit: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    },
                    cellRenderer :(params)=>params.data.serial === "\xa0" ? '' : params.data.serial },
                { field: "nominal", headerName: "Nominal (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?215:s=="s50"?185:s=="s67"?180:s=="s75"?160:150, minWidth: 150,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table text-right f-12 d-border-aggrid-right";
                    }},
                { field: "coupon", headerName: "Coupon", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?205:s=="s50"?170:s=="s67"?164:s=="s75"?134:s=="s80"?123:90,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-right f-12 d-border-aggrid-right";
                    } },
                { field: "couponpdate", headerName: "Coupon Payment Date", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?225:s=="s50"?200:170, minWidth: 170,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    } },
                { field: "duedate", headerName: "Due Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?285:s=="s50"?255:s=="s67"?230:s=="s75"?170:s=="s80"?140:120,
                    minWidth: 120,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    } },
                { field: "detail", headerName: "Detail", resizable: true,
                    width: s=="s49"?190:s=="s50"?175:s=="s67"?170:s=="s75"?130:s=="s80"?100:80, minWidth: 80,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 d-border-aggrid-right";
                    },
                    cellRenderer : (params) => params.data.serial !== "\xa0" ? '<i class="fa fa-search click-pointer text-danger"></i>' : "",
                },
                { field: "action", headerName: "Action", width: 200, minWidth: 200,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table text-center locked-col locked-visible d-border-aggrid-right";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.serial !== "\xa0"){
                            eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellbuy btn btn-sm btn-danger mx-1 f-9 w-50">Subscribe</button>' +
                                '<button class="btn-cellredemption btn btn-sm btn-primary mx-1 f-9 w-50">Redeem</button>' +
                                '</span>';
                            var bButton = eDiv.querySelectorAll('.btn-cellbuy')[0];
                            var sButton = eDiv.querySelectorAll('.btn-cellredemption')[0];

                            /*bButton.addEventListener('click', function () {});
                            sButton.addEventListener('click', function () {});*/

                            return eDiv;
                        }else{eDiv.innerHTML = '';}

                    },suppressSizeToFit: true },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {        
        ResizeResponsive();
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-487 ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.fixedIncome}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight={32}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
//============= 1.c Table of Mutual Fund =============
class MutualFundAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?330:s=="s50"?285:s=="s67"?270:s=="s75"?200:s=="s80"?140:120,
                    minWidth: 120,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer : function (params) {
                        if(params.data.code !== "\xa0"){
                            var code = params.data.code;
                            var scode = code.split("-");

                            return '<span className="font-weight-bold">'+scode[0]+'</span>' +
                                '<br /><span>'+scode[1]+'</span>';
                        }else{return ''}
                    }, suppressSizeToFit: true },
                { field: "nav", headerName: "NAV", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?165:s=="s75"?158:130, minWidth: 130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "navdate", headerName: "NAV Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s75"?155:140, minWidth: 140,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    } },
                { field: "currency", headerName: "Currency", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?335:s=="s50"?300:s=="s67"?280:s=="s75"?260:s=="s90"? 200:s=="s80"?235:150,
                    minWidth: 150,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }  },
                { field: "potentialpl", headerName: "Potential P/L", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?360:s=="s50"?330:s=="s67"?313:s=="s75"?285:s=="s80"? 250:s=="s90" ? 210:165,
                    minWidth: 165,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        var pl = params.data.potentialpl;
                        return pl.includes('-') === true ? 'grid-table d-border-aggrid-right text-right f-12 text-danger' :
                            'grid-table d-border-aggrid-right text-right f-12 text-success'
                    },
                    cellRenderer : function (params) {
                        if(params.data.code !== "\xa0"){
                            var pl = params.data.potentialpl;
                            return pl.includes('-') === true ? pl +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-down text-danger"></i>' :
                                pl +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-up text-success"></i>';
                        }else{return ''}
                    } },
                { field: "action", headerName: "Action", sortable: false,
                    width: s=="s49"?200:s=="s50"?178:150, minWidth: 150,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right text-center f-12 locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.code !== "\xa0"){
                            eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellbuy btn btn-sm btn-danger mx-1 f-9 w-50">Subscribe</button>' +
                                '<button class="btn-cellredemption btn btn-sm btn-primary mx-1 f-9 w-50">Redeem</button>' +
                                '</span>';
                            var bButton = eDiv.querySelectorAll('.btn-cellbuy')[0];
                            var sButton = eDiv.querySelectorAll('.btn-cellredemption')[0];

                            /*bButton.addEventListener('click', function(){});
                            sButton.addEventListener('click', function(){});*/

                            return eDiv;
                        }else{return ''}
                    }, suppressSizeToFit: true },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight: function (params) {
                return 40;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {        
        ResizeResponsive();
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-487 ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.mutualFund}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 2. Stock & Cash Display =============
class StockCash_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }
    render() {
        var p =this.props;
        var data = p.stockCashInfo;
        ResizeResponsive();

        const imgdisplay = {
            display: 'inline-flex',
            paddingTop: '10px'
        };

        const paddingParagraph = {
            padding: '3px'
        }

        const divMargin = {
            marginBottom: '15px'
        }

        const imgUser = {
            margin: 'auto',
            backgroundColor: 'var(--warna-bg-trading-gray)',
        }
        return (
            <>
                <AppFrameAction ref="frameAction" />

                <div className={`${(p.verifyPinStatus || p.pinPage === "stockCashPage") ? "d-none" : "d-block"} card-527 col-sm-12 px-0 mx-0 row`} id="verifyPinStockCash">
                    <VerifyPINPortofolio pos="stock" onClose={() => null} />
                </div>

                <div className={`${(p.verifyPinStatus || p.pinPage === "stockCashPage") ? "d-block" : "d-none"} container-fluid px-0 bg-black-trading f-12`} id="contentPinStockCash">
                    <div className="card-527 col-sm-12 px-0 mx-0 row">
                        <div className="col-sm-2 px-1">
                            <div class="row mx-0">
                                <div className="col-sm-12 stockcash-header h-77 mx-0 px-0" style={imgUser}>
                                    <div className="col-sm-12 pr-0 pt-1 h-77" style={imgdisplay}>
                                        <img src={user_avatar} alt="User" className="img-avatar d-border mt-4" />
                                        <p style={paddingParagraph}> 
                                            {data.user_name}<br />
                                            <i>{data.bank_account}</i><br />
                                            <button
                                                className={ data.stream ? 'btn-live-update btn-danger' : 'btn-live-update btn btn-success' }
                                                onClick={()=>p.handleOnClick("landingPage","startStopStreamStockCash","none")}
                                                style={{"fontSize":"90px !important"}}
                                                id={"buttonLiveStock"}
                                            >
                                                {(data.stream) ? 'Stop Live Update':'Resume Live Update'}
                                            </button>
                                            <button
                                                className="btn-live-update btn-primary"
                                                onClick={()=>this.props.handleOnClick("refreshPage","stockAndCash","none")}
                                                style={{"paddingLeft":"5px"}}>
                                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                            </button>                                        
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 px-0">
                                <ul className="list-group card-448 f-14 mb-0">
                                    <li className="list-group-item-portofolio">Cash Balance <br/><span className="text-primary pull-right">{mathCall.setRupiah(data.balance)}</span></li>
                                    <li className="list-group-item-portofolio">P/L <br/><span className={`pull-right ${ColorLabel(Number(data.plRatio))}`}>{mathCall.setRupiah(data.pl)}</span></li>
                                    <li className="list-group-item-portofolio">P/L Ratio <br/><span className={`pull-right ${ColorLabel(Number(data.plRatio))}`}>{data.plRatio}</span></li>
                                    <li className="list-group-item-portofolio">Collateral Ratio <br/><span className="text-primary pull-right">{mathCall.setRupiah(data.clRatio)}</span></li>
                                    <li className="list-group-item-portofolio">Buy Limit <br/><span className="pull-right">{mathCall.setRupiah(data.bl)}</span></li>
                                    <li className="list-group-item-portofolio">Additional Buy Limit <br/><span className="pull-right">{mathCall.setRupiah(data.additionalBl)}</span></li>
                                    <li className="list-group-item-portofolio">Liquidity Value <br/><span className="pull-right">{mathCall.setRupiah(data.liquidVal)}</span></li>
                                    {/*<li className="list-group-item-portofolio">Unsettled Amt <br/><span className="pull-right">?</span></li>*/}
                                    <li className="list-group-item-portofolio">Mkt. Value <br/><span className="pull-right">{mathCall.setRupiah(data.mktVal)}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-10 px-0">
                            <div className="col-sm-12 px-0">
                                <StockCashAgGrid size={widthSize()}/>
                            </div>
                            <div className="col-sm-12 card-221 px-0">
                                <p className="text-center mt-3 mb-0 h-17 bg-tableheader">Settlement</p>
                                <table className="table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 card-194">
                                    <tbody>
                                        <tr>
                                            <td className="no-wrap bg-gray-tradding d-border-tr-black py-1">Date</td>
                                            <td className="d-border-tr-gray-all py-1 text-center">{GetTimeForDateTicker(0,0)}</td>
                                            <td className="d-border-tr-gray-all py-1 text-center">{p.fundTransferT0T1.t1}</td>
                                            <td className="d-border-tr-gray-all py-1 text-center">{p.fundTransferT0T1.t2}</td>
                                        </tr>
                                        {p.scSettlement.map((d,id)=>{
                                            let idMax = p.scSettlement.length-1
                                            return(<tr key={"stc"+id} className={id==idMax ? "d-border-footer" : ''}>
                                                    <td className={id==idMax ? "no-wrap bg-gray-tradding d-border-tr-gray py-1" : "no-wrap bg-gray-tradding d-border-tr-black py-1"}>{d.label}</td>
                                                    <td className="d-border-tr-gray-all text-right py-1">{mathCall.setRupiah(d.t0)}</td>
                                                    <td className="d-border-tr-gray-all text-right py-1">{mathCall.setRupiah(d.t1)}</td>
                                                    <td className="d-border-tr-gray-all text-right py-1">{mathCall.setRupiah(d.t2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
//Table of Stock Cash
class StockCashAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "codeTop", headerName: "", sortable: true,
                    filter: "agTextColumnFilter", resizable: true,
                    width: 85, minWidth: 85,
                    lockPosition: true, lockVisible: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-center f-12 locked-visible locked-col d-border-aggrid-right";
                    }, suppressSizeToFit: true, children: [{
                        field: "codeR", headerName: "Code", sortable: true,
                        resizable: true,
                        width: 85,  minWidth: 85,
                        lockPosition: true, lockVisible: true,
                        suppressSizeToFit: true,
                        comparator: stringComparator,
                        cellClass : function (params) {
                            return " grid-table text-left f-12 locked-visible locked-col d-border-aggrid-right";
                        },
                        cellRenderer:(params)=>params.data.codeR === "\xa0" ? "" : params.data.codeR 
                    },]},
                { field: "avgpriceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }, children: [
                        { field: "avgpriceR", headerName: "Avg. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                            width: s=="s49"?210:s=="s50"?180:s=="s67"?160:s=="s75"?140:s=="s80"?130:120,
                            minWidth: 95,
                            comparator: integerComparator,
                            valueFormatter:currencyFormatter,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                    ],
                },
                { field: "lastpriceTop", headerName: "", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 100, minWidth: 100,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        var pl = params.data.pl;
                        return pl < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right" :
                            pl > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    }, children: [{ field: "lastpriceR", headerName: "Last Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?170:s=="s50"?160:s=="s67"?130:s=="s75"?110:100, minWidth: 100,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            var pl = params.data.plR;
                            return pl < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right" :
                                pl > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                                "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                        }
                    },
                    ],
                },
                { field: "port", headerName: "Portfolio",sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 160, minWidth: 160,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                    ,
                    children: [
                        { field: "plot", headerName: "Lot", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            comparator: stringComparator,
                            valueFormatter:currencyFormatter,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                        { field: "pshares", headerName: "Shares", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                            width: 80, minWidth: 80,
                            comparator: stringComparator,
                            valueFormatter:currencyFormatter,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            },
                        }
                    ]
                },
                { field: "mktvalueTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 100, minWidth: 100,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    },children:[{ field: "mktvalueR", headerName: "Mkt. Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?190:s=="s50"?160:s=="s67"?130:s=="s75"?110:100, minWidth: 100,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            return " text-right grid-table f-12 d-border-aggrid-right";
                        }
                    },]
                },
                { field: "plTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 207, minWidth: 207,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        var pl = params.data.pl;
                        return pl < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right":
                            pl > 0  ? "text-success text-right  grid-table f-12 d-border-aggrid-right" : 
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    }, children: [{ field: "plR", headerName: "P/L", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?190:s=="s50"?170:s=="s67"?130:s=="s75"?110:s=="s80"?110:100,
                        minWidth: 60,
                        comparator: integerComparator,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            var pl = params.data.plR;
                            return pl < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right":
                                pl > 0 ?  "text-success text-right  grid-table f-12 d-border-aggrid-right" :
                                "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                        }
                    },]
                },
                { field: "persTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 207, minWidth: 207,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        var pl = params.data.pl;
                        return pl < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right":
                            pl > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" : 
                            "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                    }, children: [{ field: "persenR", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?170:s=="s50"?130:s=="s67"?100:s=="s75"?75:s=="s80"?75:60,
                        minWidth: 60,
                        comparator: integerComparator,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            var pr = params.data.persenR;
                            return pr < 0 ? "text-danger text-right  grid-table f-12 d-border-aggrid-right":
                                pr > 0 ? "text-success text-right  grid-table f-12 d-border-aggrid-right" : 
                                "text-warning text-right  grid-table f-12 d-border-aggrid-right";
                        }
                    },]
                },
                { field: "sellable", headerName: "Sellable Balance", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 164, minWidth: 164,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 ";
                    }
                    ,
                    children: [
                        { field: "slot", headerName: "Lot", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                            width: 82, minWidth: 82,
                            comparator: integerComparator,
                            valueFormatter:currencyFormatter,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            }
                        },
                        { field: "sshares", headerName: "Shares", sortable: true, filter: "agNumberColumnFilter", resizable: true, width: 82,
                            comparator: integerComparator,
                            valueFormatter:currencyFormatter,
                            cellClass : function (params) {
                                return " text-right grid-table f-12 d-border-aggrid-right";
                            },
                        }
                    ]
                },
                { field: "lqValTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 98, minWidth: 98,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-success text-right  grid-table f-12 d-border-aggrid-right";
                    },children:[{ field: "lqValR", headerName: "Lq. Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?220:s=="s50"?210:s=="s67"?194:s=="s75"?186:s=="s80"?100:98,
                        minWidth: 98,
                        comparator: integerComparator,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            return "text-success text-right  grid-table f-12 d-border-aggrid-right";
                        }
                    },]
                },
                { field: "stockValTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 130, minWidth: 130,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        var pl = params.data.pl;
                        return "text-success text-right  grid-table f-12 d-border-aggrid-right";
                    },children:[ { field: "stockValR", headerName: "Stock Val (Avg.)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                        width: s=="s49"?260:s=="s50"?220:s=="s67"?195:s=="s75"?195:s=="s80"?140:140,
                        minWidth: 130,
                        valueFormatter:currencyFormatter,
                        cellClass : function (params) {
                            var pl = params.data.pl;
                            return "text-success text-right  grid-table f-12 d-border-aggrid-right";
                        }
                    }]
                },
                { field: "notationTop", headerName: "", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 130, minWidth: 130, comparator: integerComparator,
                    cellClass : function (params) {
                        var pl = params.data.pl;
                        return "text-success text-right  grid-table f-12 d-border-aggrid-right";
                    },children:[ { 
                        field: "notationR", headerName: "Special Notation", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: stringComparator,
                        width: s=="s49"?170:s=="s50"?160:150,
                        minWidth:150,
                        cellClass : function (params) {
                            return " grid-table text-primary d-border-aggrid-right text-center f-12";
                        },
                        cellRenderer : function (params) {
                            var notation = params.data.notationR;
                            var eDiv = document.createElement('div');
        
                            if(params.data.code === "\xa0" || notation === ""){
                                eDiv.innerHTML = "";
                                return eDiv;
                            }else{
                                eDiv.innerHTML = '<span class="btn-cellorder click-pointer px-1">' +notation+ '</span>';
                                var aButton = eDiv.querySelectorAll('.btn-cellorder')[0];
        
                                function closeClick(){
                                    self.refs.frameAction.closeModal(1000);
                                }
        
                                function clickNotationDetail(){
                                    self.refs.frameAction.showModal({
                                        headerClass: () =>
                                            <div className="col-sm-12 px-0 py-0 mx-0 text-right">
                                                <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={closeClick}></i>
                                            </div>,
                                        size: 'tiny',
                                        contentClass: SpecialNotationModal,
                                        onOpen :self.props.handleOnClick('stockPage', 'specialNotationDetail',params.data.notationDes),
                                    })
                                }
                            
                                aButton.addEventListener('click', clickNotationDetail);
                                return eDiv;
                            }
                        },
                    }]
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },            
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        let ndata = this.props.stockCash.filter(nz=>Number(nz.pshares !==0))

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-305 ag-theme-balham-dark ag-bordered ag-header-gray table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={ndata}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= 3. Historicals Display =============
class TradeListHistory_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            sd.setDate(sd.getDate() - 1);
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#trxHistoryStart').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDatetrxHistory").click(); 
            });
            $('#trxHistoryEnd').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDatetrxHistory").click(); 
            });

        });
    }

    handleChangeDate=()=>{
        // let nStartDate = this.refs.trxHistoryStart.value
        // let nEndDate =  this.refs.trxHistoryEnd.value
        let nStartDate = document.getElementById('trxHistoryStart').value
        let nEndDate =  document.getElementById('trxHistoryEnd').value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('trxHistoryEnd').value = GetTimeForDateTicker(0,+1)
            // this.refs.trxHistoryEnd.value = GetTimeForDateTicker(0,+1)
            nEndDate = GetTimeForDateTicker(0,+1)
            $("#trxHistoryEnd").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to:nEndDate}
        this.props.handleOnClick('datepicker','transactionHistory', nDatepicker)
    }
    render () {
        let p = this.props
        const paddingParagraph = {
            paddingTop: '10px'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid px-1 f-12" >
                    <div id="HistoricalPin" className={`${(p.verifyPinStatus || p.pinPage === "tradeListPage") ? "d-none" : "d-block"} col-sm-12 text-center align-self-center`}>
                        <VerifyPINPortofolio pos="historical" onClose={() => null} />
                    </div>
                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}
                    <div className={`${(p.verifyPinStatus || p.pinPage === "tradeListPage") ? "d-block" : "d-none"} col-sm-12 px-0`} id={"ContentHistorical"} style={paddingParagraph}>
                        {/* <PortofolioAgGrid/> */}
                        <div className="cssmenu col-sm-8 mx-0 px-0 h-45">
                            <ul className={"d-border-top d-border-left d-border-right"}>
                                <li className={ this.state.activeTab === '1' ? 'd-border-right active click-pointer col-sm-4 px-0 mx-0 f-12 text-center' : 'd-border-right text-white click-pointer col-sm-4 px-0 mx-0 f-12 text-center' } onClick={() => { this.toggle('1'); }}><a><span className="f-11">&nbsp; Trade List History</span></a></li>
                                <li className={ this.state.activeTab === '2' ? 'd-border-right active click-pointer col-sm-4 px-0 mx-0 f-12 text-center' : 'd-border-right text-white click-pointer col-sm-4 px-0 mx-0 f-12 text-center' } onClick={() => { this.toggle('2'); }}><a><span className="f-11">&nbsp; Order History</span></a></li>
                                <li className={ this.state.activeTab === '3' ? 'd-border-right active click-pointer col-sm-4 px-0 mx-0 f-12 text-center' : 'd-border-right text-white click-pointer col-sm-4 px-0 mx-0 f-12 text-center' } onClick={() => { this.toggle('3'); }}><a><span className="f-11">&nbsp; Transaction History</span></a></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 px-0 py-0 mx-0 my-0 bg-grey bg-black-trading d-border card-472">
                            <div className={this.state.activeTab === '1' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <ModalHistorical/>
                                </div>
                            </div>
                            <div className={this.state.activeTab === '2' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <ModalOrderHistory/>
                                </div>
                            </div>
                            <div className={this.state.activeTab === '3' ? 'block f-12' : 'd-none'}>
                                <div className={"row"}>
                                        <div className="col-sm-12 row px-0 mx-0">
                                            <div className="col-sm-12 h-62">
                                                <div className="ui small input col-sm-8 pl-4 f-12 text-center align-self-center black ver-center">
                                                <input type="hidden" onClick={this.handleChangeDate} id={"handleDatetrxHistory"} />
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className={"px-0"}>
                                                                    <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>From</span>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                                        <Input placeholder='dd/mm/yy' id="trxHistoryStart" ref="trxHistoryStart" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                        <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                        <span
                                                                            className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                                        <Input placeholder='dd/mm/yy' ref="trxHistoryEnd" id="trxHistoryEnd" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                        <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                        <span
                                                                            className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                                        onClick={()=>p.handleOnClick('landingPage','GoTransactiontHistory','none')}>Go</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    <div className={"col-sm-6 pr-2"}>
                                        <div className="bg-tableheader text-center py-3 h-30"><span>STOCK TRANSACTION</span>
                                        </div>
                                        <StockTransactionHistoryAgGrid size={widthSize()}/>
                                    </div>

                                    <div className={"col-sm-6 pl-2"}>
                                        <div className="bg-tableheader text-center py-3 h-30"><span>CASH TRANSACTION</span>
                                        </div>
                                        <CashTransactionHistoryAgGrid size={widthSize()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
// 3.1 TradeList History
class ModalHistorical_Base extends React.PureComponent {
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            sd.setDate(sd.getDate() - 1);
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#trdHistoryStart').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDatetrdHistory").click(); 
            });
            $('#trdHistoryEnd').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : GetTimeForDateTicker(0,+1),
                todayHighlight: true,
                // todayBtn: "linked",
            }).
            on('changeDate', function(e) {
                document.getElementById("handleDatetrdHistory").click(); 
            });
        });
    }

    handleChangeDate=()=>{
        let sd = new Date(); sd.setDate(sd.getDate() - 1);
        let nStartDate = document.getElementById('trdHistoryStart').value
        let nEndDate =  document.getElementById('trdHistoryEnd').value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('trdHistoryEnd').value = GetTimeForDateTicker(0,+1)
            nEndDate = GetTimeForDateTicker(0,+1)
            $("#trdHistoryEnd").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to: nEndDate}
        this.props.handleOnClick('datepicker','tradelistHistory', nDatepicker)
    }

    render() {
        let p = this.props
        const paddingParagraphBottom = {
            paddingBottom: '10px'
        }

        const divMargin = {
            marginBottom: '15px'
        }

        const imgUser = {
            margin: 'auto',
            backgroundColor: 'var(--warna-bg-trading-gray)',
            // borderBottom: '2px solid var(--warna-inactive-gradient)'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid pl-0 pr-0 f-12">
                    <div className="col-sm-12 row px-0 mx-0 d-border-bottom" style={{paddingBottom: '10px'}}>
                        <div className="col-sm-12 h-62">
                            <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                <input type="hidden" onClick={this.handleChangeDate} id={"handleDatetrdHistory"} />                                
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>From</span>
                                            </td>
                                            <td className={"px-0"}>
                                            <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                
                                                <Input placeholder='dd/mm/yy' ref="trdHistoryStart"  id="trdHistoryStart" className="col-sm-12 pl-0 pr-0 text-center align-self-center"/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                            </td>
                                            <td className={"px-0"}>

                                            <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                <Input placeholder='dd/mm/yy' ref="trdHistoryEnd" id="trdHistoryEnd" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="submit"  style={{height:'30px !important'}}  className="btn btn-md btn-block  btn-dark btnDatePick"
                                                    onClick={()=>p.handleOnClick('landingPage','GoTradelistHistory','none')}>Go</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}

                    <div className="col-sm-12 px-0 pt-0" >
                        <TradeListAgGrid 
                            size={widthSize()}/>
                    </div>

                </div>
            </>
        );
    }
}
// Table of Trade list history
class TradeListAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?290:s=="s50"?270:s=="s67"?260:s=="s75"?230:s=="s80"?175:s=="s85"?140:s=="s90"?125:s=="s100"?110:110, comparator: dateComparator,
                    minWidth: 114,
                    sort:'desc',
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-left f-12 d-border-aggrid-right";
                    }, suppressSizeToFit: true
                },
                { field: "trade", headerName: "Trade#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?280:s=="s50"?260:s=="s67"?255:s=="s75"?240:s=="s80"?185:s=="s85"?145:s=="s90"?125:s=="s100"?100:100, comparator: stringComparator,
                    minWidth: 100,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                },{ field: "order", headerName: "Order#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?320:s=="s50"?290:s=="s67"?280:s=="s75"?240:s=="s80"?185:s=="s85"?155:s=="s90"?125:s=="s100"?110:110, comparator: stringComparator,
                    minWidth: 100,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                },
                { field: "code", headerName: "Code", sortable: true,  resizable: true,
                    width: s=="s49"?250:s=="s50"?200:120, minWidth: 120,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table f-12 d-border-aggrid-right";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code
                },
                { field: "cmd", headerName: "Cmd", sortable: true, resizable: true,
                    width: s=="s49"?330:s=="s50"?290:s=="s67"?280:s=="s75"?250:s=="s80"?185:s=="s85"?155:s=="s90"?135:s=="s100"?125:125,
                    minWidth: 125,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table f-12 d-border-aggrid-right";
                    }
                },
                // { field: "type", headerName: "Type", sortable: true, resizable: true,
                //     width: s=="s49"?190:s=="s50"?160:s=="s67"?150:s=="s75"?148:s=="s80"?110:107,
                //     minWidth: 147,
                //     comparator: stringComparator,
                //     cellClass : function (params) {
                //         return "text-center  grid-table f-12 d-border-aggrid-right";
                //     }
                // },
                // { field: "orderType", headerName: "Order Type", sortable: true, resizable: true, comparator: stringComparator,
                //     width: s=="s49"?130:s=="s50"?110:107, minWidth: 107,
                //     cellClass : function (params) {
                //         return " text-left grid-table f-12 d-border-aggrid-right";
                //     }
                // },
                { field: "mkt", headerName: "Mkt", sortable: true, resizable: true,
                    width: s=="s49"?140:s=="s50"?120:107, minWidth: 107,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-center grid-table f-12 d-border-aggrid-right";
                    }
                },
                { field: "vol", headerName: "Vol (Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?140:s=="s50"?120:107, minWidth: 107,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                },
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 150, minWidth: 150,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                },
                { field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?160:s=="s50"?150:120, minWidth: 120, 
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                },
                { field: "time", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?190:120, minWidth: 120, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                }
            ],            
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowStyle:{
                function (params) {
                    if (params.data.vol > 1) {
                        console.log('ieuuuu aya teuuuu', params.data.vol)
                        return {fontColor: 'red' }
                    }
                }
            },
            // rowClassRules: {
            //     "f-18": function (params) {
            //         console.log('ieuuuu aya teuuuu', params.data.vol)
            //         return params.data.vol > 1;
            //     }
            // },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }
    
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        // gridOptions.getRowStyle = function(params) {
        //     if (params.node.rowIndex % 2 === 0) {
        //         return { background: 'red' }
        //     }
        // }

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    // getRowStyle(params) {
    //     if (params.data.vol > 1) {
    //         console.log('ieuuuu aya teuuuu', params.data.vol)
    //         return { backgroundColor: 'red' }
    //     }
    // }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-392 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradeListHistory}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}                        
                        rowClassRules={this.state.rowClassRules}
                        rowStyle={this.state.rowStyle}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// 3.2 ORDER HISTORY
class ModalOrderHistory_Base extends React.PureComponent {
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            sd.setDate(sd.getDate() - 1);
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#ordHistoryStart').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateordHistory").click(); 
            });
            $('#ordHistoryEnd').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateordHistory").click(); 
            });

        });
    }

    handleChangeDate=()=>{
        let nStartDate = document.getElementById('ordHistoryStart').value
        let nEndDate =  document.getElementById('ordHistoryEnd').value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('ordHistoryEnd').value = GetTimeForDateTicker(0,+1)
            nEndDate = GetTimeForDateTicker(0,+1)
            $("#ordHistoryEnd").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to:nEndDate}
        this.props.handleOnClick('datepicker','orderHistory', nDatepicker)
    }

    render() {
        let p = this.props
        const paddingParagraphBottom = {
            paddingBottom: '10px'
        }
        const marketOptions = [
            //untuk top active
            { key: 'all', value: 'all', text: 'All' },
            { key: 'rg', value: 'rg', text: 'RG' },
            { key: 'tn', value: 'tn', text: 'TN' },
            { key: 'ng', value: 'ng', text: 'NG' },
        ];
        const  statusOptions = [
            //untuk top active
            { key: 'all', value: 'all', text: 'All' },
            { key: 'open', value: 'open', text: 'Open' },
            { key: 'amend', value: 'amend', text: 'Amend' },
            { key: 'withdraw', value: 'withdraw', text: 'Withdraw' },
            { key: 'partial', value: 'partial', text: 'Partial' },
            { key: 'donePartial', value: 'donePartial', text: 'Done - Partial' },
            { key: 'reject', value: 'reject', text: 'Reject' },
            { key: 'send', value: 'send', text: 'Send' },
        ];
        const buySellOptions = [
            //untuk top active
            { key: 'all', value: 'ALL', text: 'All' },
            { key: 'buy', value: 'BUY', text: 'Buy' },
            { key: 'sell', value: 'SELL', text: 'Sell' },
        ];
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid pl-0 pr-0 f-12" >
                    <div className="col-sm-12 row px-0 mx-0 d-border-bottom" style={paddingParagraphBottom}>
                        <div className="col-sm-12 h-62">
                        <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                            <input type="hidden" onClick={this.handleChangeDate} id={"handleDateordHistory"} />                                
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' ref="ordHistoryStart" id="ordHistoryStart" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' ref="ordHistoryEnd" id="ordHistoryEnd" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="submit" className="btn btn-md btn-block btn-default btn-dark btnDatePick"
                                                    onClick={()=>p.handleOnClick('landingPage','GoOrderlistHistory','none')}>Go</button>
                                            </td>
                                            {/* <td class="text-white">
                                                Market
                                            </td>
                                            <td className="td-select-historical">
                                                <Dropdown defaultValue={marketOptions[0].value} placeholder='' search selection options={marketOptions} className="col-sm-12 f-12"/>
                                            </td>
                                            <td className="text-white">
                                                Status
                                            </td>
                                            <td className="td-select-historical">
                                                <Dropdown placeholder='' defaultValue={statusOptions[0].value} search selection options={statusOptions} className="col-sm-12 f-12"/>
                                            </td>
                                            <td className="text-white">
                                                Buy/Sell
                                            </td>
                                            <td className="td-select-historical">
                                                <Dropdown placeholder='' defaultValue={buySellOptions[0].value} search selection options={buySellOptions} className="col-sm-12 f-12"/>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}

                    <div className="col-sm-12 px-0 pt-0" >
                        <TransactionOrderHistoryAgGrid size={widthSize()}/>
                    </div>
                </div>
            </>
        );
    }
}
// Table of order history
class TransactionOrderHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "order", headerName: "Order#", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,  minWidth: 100,
                    comparator: stringComparator,
                    cellClass : (params)=>params.data.code === "\xa0" ? "grid-table d-border-aggrid-right text-left f-12" 
                        : "grid-table d-border-aggrid-right text-left f-12 click-pointer",
                    cellRenderer : function (params) {
                        var order = params.data.order;
                        var eDiv = document.createElement('div');
                        if(params.data.code === "\xa0"){
                            eDiv.innerHTML = '';
                            return eDiv
                        }else{ 
                            eDiv.innerHTML = '<span class="btn-cellorder px-1">' +order+ '</span>';
                            var aButton = eDiv.querySelectorAll('.btn-cellorder')[0];

                            function closeClick(){                            
                                self.refs.frameAction.closeModal(1000)
                            }
                            // memanggil audit trail
                            function clickOrderDetail() {
                                self.refs.frameAction.showModal({
                                    headerClass: () =>
                                        <div className="col-sm-12 px-0 mx-0 text-right">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={closeClick}></i>
                                        </div>,
                                    size: 'large',
                                    contentClass: OrderDetailModal,
                                    onOpen :self.props.handleOnClick('landingPage', 'openModalOrderDetail',params.data),
                                    onClose: (result) => {console.log('Modal 1 result = ', result)}
                                })
                            }

                            aButton.addEventListener('dblclick', clickOrderDetail);
                            return eDiv;
                        }

                    },
                    suppressSizeToFit: true
                }, { field: "marketNoC", headerName: "Market No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100, minWidth: 100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }, suppressSizeToFit: true
                },
                { field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,  minWidth:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, 
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code,
                    suppressSizeToFit: true
                },{ field: "cmd", headerName: "Cmd", sortable: true, resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,  minWidth:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "type", headerName: "Type", sortable: true, resizable: true,
                    width: s=="s49"?140:s=="s50"?120:90, minWidth:90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "mkt", headerName: "Mkt", sortable: true, resizable: true,
                    width: s=="s49"?170:s=="s50"?150:100, minWidth: 100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    },},
                { field: "vol", headerName: "Vol (Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:110, minWidth:110,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130, minWidth:130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "mvol", headerName: "M Vol (Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130, minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "mprice", headerName: "M Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130, minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "leaveVolC", headerName: "Leave Vol (Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?160:130, minWidth:130,
                    comparator: integerComparator,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "status", headerName: "Status", sortable: true, resizable: true,
                    width: s=="s49"?210:s=="s50"?190:160, minWidth:160,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    },},
                { field: "time", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true, sort:'desc',
                    width: s=="s49"?160:s=="s50"?155:100, minWidth:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer:(params)=>params.data.time.substr(0,19),
                },
                { field: "rejectreason", headerName: "Reject Reason", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?160:s=="s50"?155:130, minWidth:130,
                    comparator: stringComparator,
                    cellClass :(params)=>params.data.rejectreason == '-' ? "text-center grid-table d-border-aggrid-right f-12" :
                        "text-left grid-table d-border-aggrid-right f-12"
                    },
                { field: "userOrderC", headerName: "User Order", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?160:130, minWidth:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    },},
                { field: "userId", headerName: "UserID", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:s=="s49"?130:100, minWidth:100, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "method", headerName: "Method", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:s=="s49"?130:100, minWidth:100, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "terminal", headerName: "Terminal", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width:s=="s49"?130:100, minWidth:100, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    order: "0001",
                    originNo: "011",
                    code: 'AALI',
                    cmd: 'Buy',
                    type: 'Day',
                    mkt: 'RG',
                    vol: 100,
                    price: 12.000,
                    mvol: 100,
                    mprice: 1000,
                    restvol: 0,
                    status: "Done",
                    time: '09:03:10',
                    rejectreason: '-',
                    ordered: '',
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-392 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.orderHistory}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// Modal Audit trail for Order history
class OrderDetailModal extends React.PureComponent {
    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <ModalOrderDetail/>
            </>
        );
    }
}
// 3.3 Transaction History
class ModalTransaction extends React.PureComponent {

    render() {

        const imgdisplay = {
            display: 'inline-flex',
            paddingTop: '3px'
        };

        const paddingParagraph = {
            paddingTop: '10px',
            paddingBottom: '0px'
        }
        const paddingParagraphBottom = {
            paddingBottom: '10px'
        }

        const divMargin = {
            marginBottom: '15px'
        }

        const imgUser = {
            margin: 'auto',
            backgroundColor: 'var(--warna-bg-trading-gray)',
            // borderBottom: '2px solid var(--warna-inactive-gradient)'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid pl-0 pr-0 f-12" >
                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}
                    <div className="col-sm-12 px-0 pt-0" >
                        <TransactionAgGrid size={widthSize()}/>
                    </div>
                </div>
            </>
        );
    }
}
// Table of transaction history
class TransactionAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                }, { field: "detail", headerName: "Detail", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                },
                { field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    }, suppressSizeToFit: true
                },{ field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?130:s=="s50"?120:100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "inOut", headerName: "In/Out Qty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?140:s=="s50"?120:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "balA", headerName: "Bal. Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "balQ", headerName: "Bal. Qty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "fee", headerName: "Fee", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "paidAmt", headerName: "Paid Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?150:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "penalty", headerName: "Penalty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?160:s=="s67"?155:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "add", headerName: "Add Outstanding", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?210:s=="s50"?190:s=="s67"?170:160,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "tradeAmt", headerName: "Trade Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?160:s=="s50"?155:s=="s67"?135:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "wht", headerName: "WHT", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?160:s=="s50"?155:s=="s67"?135:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
                { field: "incomeTax", headerName: "Income Tax", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?170:s=="s50"?160:s=="s67"?140:130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    },},
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },            
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-392 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.transHistory}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// Stock transaction history Table
class StockTransactionHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?160:s=="s75"?150:s=="s80"?130:s=="s85"?110:s=="s90"?100:s=="s100"?90:90,
                    minWidth: 100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                }, { field: "code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?160:s=="s75"?150:s=="s80"?125:s=="s90"?105:s=="s100"?100:90,
                    minWidth: 90, 
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer:(params)=>params.data.code === "\xa0" ? "" : params.data.code,
                    suppressSizeToFit: true
                },
                { field: "buySell", headerName: "Buy / Sell", sortable: true, resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?145:s=="s80"?125:s=="s85"?120:s=="s90"?115:s=="s100"?110:100,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                },{ field: "inOut", headerName: "In/Out Qty", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?155:s=="s75"?145:s=="s80"?130:s=="s85"?120:s=="s90"?115:s=="s100"?110:105,
                    minWidth: 105,
                    comparator: stringComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?150:s=="s80"?130:s=="s85"?120:s=="s90"?120:s=="s100"?120:110,
                    minWidth: 140,
                    comparator: stringComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "trx", headerName: "Trx Type", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?180:s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s85"?120:s=="s90"?110:s=="s100"?100:90,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    }
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    date: "22/06/2019",
                    code: 'AALI'+s,
                    buySell: 'Buy',
                    inOut: '10',
                }, {
                    date: "20/06/2019",
                    code: 'ABBA',
                    buySell: 'Sell',
                    inOut: '10',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                }, {
                    date: "",
                    code: '',
                    buySell: '',
                    inOut: '',
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-392-historical ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTransactionHistory}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// cash transaction history Table
class CashTransactionHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?170:s=="s75"?160:s=="s80"?130:s=="s85"?120:s=="s90"?110:s=="s100"?110:100,
                    minWidth: 100,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                }, { field: "debitCredit", headerName: "Debit / Credit", sortable: true, resizable: true,
                    width: s=="s49"?240:s=="s50"?225:s=="s67"?200:s=="s75"?190:s=="s80"?170:s=="s85"?160:s=="s90"?145:s=="s100"?140:130,
                    minWidth: 130,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                },
                { field: "desc", headerName: "Description", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?280:s=="s50"?240:s=="s67"?210:s=="s75"?205:s=="s80"?180:s=="s85"?160:s=="s90"?150:s=="s100"?140:130,
                    minWidth: 130,
                    suppressSizeToFit: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, 
                    cellRenderer:(params)=>params.data.desc === "\xa0" ? "" : params.data.desc,
                },{ field: "amount", headerName: "Amount", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?225:s=="s67"?195:s=="s75"?190:s=="s80"?170:s=="s85"?150:s=="s90"?145:s=="s100"?140:140,
                    minWidth: 140,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }
                },{ field: "trx", headerName: "Trx Type", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?160:s=="s75"?140:s=="s80"?120:s=="s90"?110:s=="s100"?100:90,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    }
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    date: "22/06/2019",
                    debitCredit: "Debit",
                    desc: "",
                    amount: "34,500",
                },{
                    date: "21/06/2019",
                    debitCredit: "Debit",
                    desc: "",
                    amount: "14,500",
                },
                {
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },{
                    date: "",
                    debitCredit: "",
                    desc: "",
                    amount: "",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-392-historical ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.cashTransactionHistory}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// ============= 4.Fund transfer =============
const subMenuFT = [
    {tab: 1, show: true, title: "FUND TRANSFER"},
    {tab: 2, show: true, title: "F/T LIST"},
    {tab: 3, show: false, title: "STOCK TRANSFER"},
    {tab: 4, show: false, title: "STOCK RECEIVE"},
    {tab: 5, show: false, title: "STOCK TRANSFER LIST"},
]
// 4.1 Fund Transaction
class FundTransfer_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            code: 'Please Select Code',
            broker: 'Please Select Broker',
            vol: 0,
            price: 0,
            code2: 'Please Select Code',
            broker2: 'Please Select Broker',
            vol2: 0,
            price2: 0,
        };
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#ftListStart').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateFtlist").click(); 
            });
            $('#ftListEnd').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateFtlist").click(); 
            });

            // handle Transfer date
            let nsd = new Date(), ned = new Date();
            nsd.setDate(nsd.getDate() + 1);
            ned.setDate(ned.getDate() + 2);
            $('#datepickerTransferDate').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: nsd,
                endDate : ned,
                autoclose: true,
                todayHighlight: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleTransferDate").click(); 
            });

            // STOCK TRANSFER LIST
            let ed = new Date();
            $('#datepickerstartSTL').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayHighlight: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateSTL").click(); 
            });
            $('#datepickerendSTL').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayHighlight: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateSTL").click(); 
            });
        });
    }
    handleChangeDate = (a) => {
        switch(a) {
            case "handleDateFtlist": {
                let nStartDate = document.getElementById('ftListStart').value
                let nEndDate =  document.getElementById('ftListEnd').value
                if(nStartDate != "" && nEndDate == ""){
                    document.getElementById('ftListEnd').value = GetTimeForDateTicker(0,0)
                    nEndDate = GetTimeForDateTicker(0,0)
                    $("#ftListEnd").datepicker("setDate", nEndDate);
                }
                let nDatepicker = {start:nStartDate,to:nEndDate}
                this.props.handleOnClick('datepicker','FundTransferList', nDatepicker)
            }; break;
            case "handleTransferDate": {
                this.props.handleOnClick('datepicker','transferDate', this.refs.transferDate.value)
            }; break;
            case "handleDateSTL" : {
                let nStartDate = document.getElementById("datepickerstartSTL").value
                let nEndDate =  document.getElementById("datepickerendSTL").value
                if(nStartDate != "" && nEndDate == ""){
                    document.getElementById("datepickerendSTL").value = GetTimeForDateTicker(0,0)
                    nEndDate = GetTimeForDateTicker(0,0)
                    $("#datepickerendSTL").datepicker("setDate", nEndDate);
                }
                let nDatepicker = {start: nStartDate, to: nEndDate}
                this.props.handleOnClick('datepicker','FundTransferSTL', nDatepicker)
            }; break;
        }
    }
    handleChange = (type, val) => {
        if(type === "setVol"){ this.setState({ vol: Number(val.replace(/[^0-9]/g, '')) }); }
        if(type === "plusVol"){ this.setState({ vol: val + 1}); }
        if(type === "minusVol" && val > 0){ this.setState({ vol: val - 1}); }
        if(type === "setPrice"){ this.setState({ price: Number(val.replace(/[^0-9]/g, '')) }); }
        if(type === "setVol2"){ this.setState({ vol2: Number(val.replace(/[^0-9]/g, '')) }); }
        if(type === "plusVol2"){ this.setState({ vol2: val + 1}); }
        if(type === "minusVol2" && val > 0){ this.setState({ vol2: val - 1}); }
        if(type === "setPrice2"){ this.setState({ price2: Number(val.replace(/[^0-9]/g, '')) }); }
    }
    onFocus = (e) => {
        if(this.props.rtgsStatus){
            this.props.handleOnClick('landingPage',"checkBoxRTGS")
        }
        e.target.select()
    }
    handleCheckbox = (e) => {
        if(Number(this.props.amountWithdrawable) > 100000000){
            this.props.handleOnClick('landingPage',"checkBoxRTGS")
        }else{
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,"Via RTGS (The above amount is more than IDR 100,000,000)")                
        }
    }
    handleSendFT = (e) => {
        let { code, broker, vol, price, code2, broker2, vol2, price2 } = this.state;
        let { tabFundTransfer, amountWithdrawable, statusAlertN=false, statusAlertC=false, 
            stocklistOptions=[], custodyList=[], handleOnClick, handleStatusAlert3,
        } = this.props;
        switch(tabFundTransfer){
            case 1: { /* Fund Transfer */
                if((amountWithdrawable * 1) === 0){
                    handleStatusAlert3('noConfirm', statusAlertN, "Withdrawal Amount cannot be empty")                
                }else{
                    var frameAction = this.refs.frameAction;
                    frameAction.showModal({
                        size: "mini",
                        headerClass: () => <div className="text-right"></div>,
                        contentClass: VerifyPIN,
                        contentProps: { 
                            onSubmit: () => {
                                frameAction.closeModal(100);
                                handleStatusAlert3('confirm', statusAlertC, 'Are you sure?', 'landingPage#sendFundTransfer#none')
                            }
                        },
                    });
                }
            }; break;
            case 3: {
                if(code == "Please Select Code"){
                    handleStatusAlert3('noConfirm', statusAlertN, "Please select Stock")                
                }else if(broker == "Please Select Broker"){
                    handleStatusAlert3('noConfirm', statusAlertN, "Please select Broker")                
                }else if(price == 0 || vol == 0){
                    handleStatusAlert3('noConfirm', statusAlertN, "Vol or Price can't zero")
                }else {
                    handleOnClick("landingPage", "sendFundTransfer", {
                        "stock_code": stocklistOptions.find(d => d.saham == code).code,
                        "stock_price": price,
                        "transfer_qty": vol,
                        "message_id": GenerateId("idST"),
                        "custody_dest": custodyList.find(d => d.saham == broker).code,
                        "settle_reason": "OWNE",
                    });
                    this.setState({
                        code: 'Please Select Code',
                        broker: 'Please Select Broker',
                        vol: 0,
                        price: 0,
                    });
                }
            }; break;
            case 4: { 
                if(code2 == "Please Select Code"){
                    handleStatusAlert3('noConfirm', statusAlertN, "Please select Stock")                
                }else if(broker2 =="Please Select Broker"){
                    handleStatusAlert3('noConfirm', statusAlertN, "Please select Broker")                
                }else if(price2 == 0 || vol2 == 0){
                    handleStatusAlert3('noConfirm', statusAlertN, "Vol or Price can't zero")
                }else{
                    handleOnClick("landingPage", "sendFundTransfer", {
                        "stock_code": stocklistOptions.find(d => d.saham == code2).code,
                        "stock_price": price2,
                        "transfer_qty": vol2,
                        "message_id": GenerateId("idST"),
                        "custody_source": custodyList.find(d => d.saham == broker2).code,
                        "settle_reason": "OWNE",
                    });
                    this.setState({
                        code2: 'Please Select Code',
                        broker2: 'Please Select Broker',
                        vol2: 0,
                        price2: 0,
                    })
                }
            }
        }
    }
    render () {
        let p = this.props; let s = this.state; 
        let menuLeng = subMenuFT.filter(d=>d.show).length;
        let colType = menuLeng > 6 || menuLeng === 5 ? "mn-"+menuLeng : "sm-"+(12/menuLeng);
        let tab = this.props.tabFundTransfer;
        let user = p.userProfile;
        const paddingParagraph = {
            paddingTop: '10px'
        }

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        const customFilter = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if (searchText.toLowerCase().includes(' ')) {
                if (saham) {
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="bg-black-trading card card-75">
                    <div className={`${(p.verifyPinStatus || p.pinPage === "fundTransferPage") ? "d-none" : "d-block"} container-fluid px-1 f-12`} id="FundPin">
                        <VerifyPINPortofolio pos="fund" onClose={() => null} />
                    </div>
                    <div className={`${(p.verifyPinStatus || p.pinPage === "fundTransferPage") ? "d-block" : "d-none"} container-fluid px-1 f-12`} id="ContentFund">
                        {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}
                        <div className="col-sm-12 px-0" style={paddingParagraph}>
                            <div className={`cssmenu col-sm-${menuLeng > 2 ? "12" : "6"} mx-0 px-0 h-45`}>
                                <ul className={"d-border-top d-border-left d-border-right"}>
                                    {
                                        subMenuFT.filter(d=>d.show).map((item, id) => (
                                            <li className={`d-border-right click-pointer col-${colType} px-0 mx-0 f-12 text-center ${(tab === item.tab) ? "active" : "text-white"}`}
                                                onClick={() => {this.props.handleOnClick('landingPage', "tabFundTransfer", item.tab); }}>
                                                <a><span className="f-11">&nbsp; {item.title}</span></a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="col-sm-12 px-4 bg-black-trading pt-0 d-border card-472">
                                <div className={tab === 1 ? 'd-block f-12' : 'd-none'}>
                                    <div className="container mx-0 pt-3">
                                        <div className="row">
                                            <div className="col-md pr-5 pl-0">
                                                <div>Available Cash (T0/T1)</div>
                                                <table className="table text-white d-border-table bg-dark-grey table-sm ">
                                                    <tbody>
                                                        <tr>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black text-center d-border-right">
                                                                {GetTimeForDateTicker(0,0)}
                                                            </td>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black text-center">
                                                                {p.fundTransferT0T1.t1}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={"text-right d-border-right"}>{ConvertToRupiah(p.fundTransferT0T1.c1)}</td>
                                                            <td className={"text-right"}>{ConvertToRupiah(p.fundTransferT0T1.c2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md">
                                                <div>Bank Information</div>
                                                <table className="table text-white d-border-table bg-dark-grey table-sm table-borderless">
                                                    {/* <tbody> */}
                                                        <tr>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black">Account No</td>
                                                            <td className="d-border-tr-gray-all text-right">{user.bank_account === undefined ? ""  : user.bank_account}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black">Account Name</td>
                                                            <td className="d-border-tr-gray-all text-right">{user.bank_account === undefined ? ""  : user.bank_user}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black">Bank Name</td>
                                                            <td className="d-border-tr-gray-all text-right">{user.bank_account === undefined ? ""  : user.bank_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-wrap bg-gray-tradding d-border-tr-black">Branch Name</td>
                                                            <td className="d-border-tr-gray-all text-right">{user.bank_account === undefined ? ""  : user.bank_branch}</td>
                                                        </tr>
                                                    {/* </tbody> */}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="p-2">If the above bank Information is wrong, please contact our call center at 14009 or by website 
                                                &nbsp;<a href="http://www.bahanasekuritas.id" target="_blank">
                                                    www.bahanasekuritas.id
                                                </a>
                                            </div>
                                            <div className="d-border bg-gray-tradding">
                                                <div className="col-md-12 p-3">
                                                    <div className="row p-3">
                                                        {/* <div className="col-md-2 mt-3">
                                                            Amount (Not Including Fee)
                                                        </div>
                                                        <div className="col-md-1 mt-3">
                                                            IDR
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                defaultValue={p.amountFund} 
                                                                onChange={(e)=>p.onChangeTextHandler("fundTransfer","amountFund",e.target.value)}
                                                                value={ConvertToRupiah(p.amountFund)} 
                                                                placeholder='Name' 
                                                                className="col-sm-12 pl-4 pr-0 text-right align-self-center input-right"
                                                            />
                                                        </div> */}
                                                        <div className="col-md-2 mt-3">
                                                            Withdrawal Amount
                                                        </div>
                                                        <div className="col-md-1 mt-3">
                                                            IDR
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input 
                                                                defaultValue={p.amountWithdrawable} 
                                                                onChange={(e)=>p.onChangeTextHandler("fundTransfer","amountWithdrawable",e.target.value)}
                                                                value={ConvertToRupiah(p.amountWithdrawable)}
                                                                placeholder='Name' 
                                                                onFocus={this.onFocus}
                                                                className="col-sm-12 pl-4 pr-0 text-right align-self-center input-right"/>
                                                        </div>
                                                    {/* </div> <div className="row p-3">
                                                    <div className="col-md-2 mt-3">
                                                        Transfer Date (T1/T2)
                                                    </div>
                                                    <div className="col-md-1">

                                                    </div>
                                                    <div className="col-md-3 ui input" style={{paddingRight:'53px'}}>
                                                        <span onClick={()=>this.handleChangeDate("handleTransferDate")} id={"handleTransferDate"}></span>
                                                        <input placeholder='dd/mm/yy' ref="transferDate" id="datepickerTransferDate" className="col-sm-12 pl-4 pr-0 text-center align-self-center"
                                                            value={p.transferDate}/>
                                                        <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}><span
                                                            className="fa fa-calendar-alt"></span></span> 
                                                    </div>*/}
                                                <div className={"col-md-6"}></div>
                                                    <div className={"col-sm-12 f-11 mt-4"}>
                                                        <input className="magic-checkbox" type="checkbox" name="viaRTGS"
                                                            id="viaRTGS"
                                                            checked={p.rtgsStatus}
                                                            onChange={this.handleCheckbox} />
                                                        <label htmlFor="viaRTGS"
                                                                className="text-white f-12-center pt-1">
                                                            Via RTGS (The above amount is more than IDR 100,000,000)
                                                        </label>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4 pr-4">
                                                <div className="col-sm-6 mt-2">
                                                </div>
                                                <div className={"col-sm-6 text-right mb-0 px-3 h-40"}>
                                                    <button onClick={this.handleSendFT} className={"btn btn-primary"}>
                                                        <i className={"fa fa-paper-plane"}>
                                                        &nbsp;Send</i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                </div>
                                <div className={tab === 2 ? 'd-block f-12' : 'd-none'}>
                                    <div className="d-border-bottom mb-3">
                                        <div className="form-group mb-3 px-0">
                                            <div className="col-sm-9 pl-0 h-62">
                                                <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                                    <input type="hidden" onClick={()=>this.handleChangeDate("handleDateFtlist")} id={"handleDateFtlist"} />                                                    
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className={"px-0"}>
                                                                    <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                                        <Input placeholder='dd/mm/yy' id="ftListStart" ref="ftListStart" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                        <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                        <span
                                                                            className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                                                </td>
                                                                <td className={"px-0"}>
                                                                    <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                                        <Input placeholder='dd/mm/yy' id="ftListEnd" ref="ftListEnd" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                        <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                        <span
                                                                            className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button type="submit" style={{height:'30px !important'}}  className="btn btn-md btn-block btn-dark btnDatePick"
                                                                        onClick={()=>p.handleOnClick('landingPage','GoFundTransferList','none')} >Go</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <FundAgGrid size={widthSize()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={tab === 3 ? 'd-block f-12' : 'd-none'}>
                                    <div className="mb-3">
                                        <div className="form-group mb-3 px-0">
                                            <div className="col-sm-12 pl-0 mt-3 pb-3">
                                                <h3>Stock Transfer</h3>
                                            </div>
                                            <div className="bg-dark-grey pt-2 col-sm-7 d-border">
                                                <div className="row">
                                                    <div className="col-sm-12 f-12 pt-3">
                                                        <div className="col-sm-12 row mb-2 pr-0">
                                                            <div className="col-sm-5 mb-3 f-14">
                                                                <label
                                                                    className="f-13 f-xs-14 mt-3 align-middle align-self-center pr-0">
                                                                    Stock
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-3 pr-0 pl-0 z99">
                                                                <Select
                                                                    getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                                                    filterOption={customFilter}
                                                                    isSearchable={true}
                                                                    maxMenuHeight={155}
                                                                    styles={customStyles}
                                                                    placeholder={<div>Search..</div>}
                                                                    options={p.fundStockCodeList}
                                                                    onChange={(e)=>this.setState({code: e.saham, price: parseInt(p.stockProfile.find(v => v.stockcode === e.code).averageprice)})}
                                                                    className="LandingPageSelectCode text-left"
                                                                    theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                                                    value={s.code === "Please Select Code" ? "" : searchCode('selected', s.code, p.stocklistOptions)}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4 pr-0">
                                                                <label
                                                                    className="f-13 f-xs-14 mt-3 align-middle align-self-center pr-0">
                                                                    { this.state.code }
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-5 mb-3 f-14">
                                                                <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>Vol(Shr)</label>
                                                            </div>
                                                            <div className="col-sm-3 pr-0 pl-0">
                                                                {/* <NumberInput idclassname="MyAccountVolShr" defaultValue={"0"}/> */}
                                                                <div className="input-group">
                                                                    <span className="input-group-btn">
                                                                        <button type="button"  
                                                                            onClick={()=>this.handleChange("minusVol", Number(s.vol))} 
                                                                            className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                                            style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                                            <i className="icofont icofont-minus f-9"></i>
                                                                        </button>
                                                                    </span>
                                                                    <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                                        className="form-control f-12" defaultValue='0' value={ConvertToRupiah(s.vol)}  placeholder="0"
                                                                        onChange={(e)=>this.handleChange("setVol", e.target.value)} 
                                                                        onFocus={(e) => e.target.select()} />
                                                                    <span className="input-group-btn">
                                                                        <button type="button"   
                                                                            onClick={()=>this.handleChange("plusVol", Number(s.vol))} 
                                                                            className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                                            style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                                            <span className="icofont icofont-plus f-9"></span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-1 pr-0">
                                                                <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>Price</label>
                                                            </div>
                                                            <div className="col-sm-3 pr-0">
                                                                <div
                                                                    className="ui input col-sm-12 pl-4 pr-0 text-right align-self-center input-right">
                                                                    <input placeholder="Name" type="text" readOnly
                                                                        // onChange={(e)=>this.handleChange("setPrice", e.target.value)} 
                                                                        // onFocus={(e) => e.target.select()} 
                                                                        value={ConvertToRupiah(s.price)}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-5 mb-3 f-14">
                                                                <label htmlFor=""
                                                                    className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>
                                                                    Custodian Bank Destination
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-3 pr-0 pl-0">
                                                                <Select
                                                                    getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                                                    filterOption={customFilter}
                                                                    isSearchable={true}
                                                                    maxMenuHeight={155}
                                                                    styles={customStyles}
                                                                    placeholder={<div>Search..</div>}
                                                                    options={p.custodyList}
                                                                    onChange={(e) => this.setState({broker: e.saham})}
                                                                    className="LandingPageSelectBroker text-left"
                                                                    theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                                                    value={s.broker === "Please Select Broker" ? "" : searchCode('selected', s.broker, p.custodyList)}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4 pr-0">
                                                                <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>
                                                                    {this.state.broker}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-12 mb-3 pr-0 text-right">
                                                                <button onClick={this.handleSendFT} className="btn btn-primary"><i className={"fa fa-paper-plane"}></i>&nbsp;Send</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={tab === 4 ? 'd-block f-12' : 'd-none'}>
                                    <div className="d-border-transparent-grey">
                                        <div className="mb-3">
                                            <div className="form-group mb-3 px-0">
                                                <div className="col-sm-12 pl-0 mt-3 pb-3">
                                                    <h3>Stock Receive</h3>
                                                </div>
                                                <div className="bg-dark-grey pt-2 col-sm-7 d-border">
                                                    <div className="row">
                                                        <div className="col-sm-12 f-12 pt-3">
                                                            <div className="col-sm-12 row mb-2 pr-0">
                                                                <div className="col-sm-5 mb-3 f-14">
                                                                    <label
                                                                        className="f-13 f-xs-14 mt-3 align-middle align-self-center pr-0">
                                                                        Stock
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-3 pr-0 pl-0 z99">
                                                                    <Select
                                                                        getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                                                        filterOption={customFilter}
                                                                        isSearchable={true}
                                                                        maxMenuHeight={155}
                                                                        styles={customStyles}
                                                                        placeholder={<div>Search..</div>}
                                                                        options={p.stocklistOptions}
                                                                        onChange={(e)=>this.setState({code2: e.saham})}
                                                                        className="LandingPageSelectCode text-left"
                                                                        theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                                                        value={s.code2 === "Please Select Code" ? "" : searchCode('selected', s.code2, p.stocklistOptions)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4 pr-0">
                                                                    <label
                                                                        className="f-13 f-xs-14 mt-3 align-middle align-self-center pr-0">
                                                                        { this.state.code2 }
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-5 mb-3 f-14">
                                                                    <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>Vol(Shr)</label>
                                                                </div>
                                                                <div className="col-sm-3 pr-0 pl-0">
                                                                    {/* <NumberInput idclassname="MyAccountVolReceipt" defaultValue={"0"}/> */}
                                                                    <div className="input-group">
                                                                        <span className="input-group-btn">
                                                                            <button type="button"  
                                                                                onClick={()=>this.handleChange("minusVol2", Number(s.vol2))} 
                                                                                className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom "} 
                                                                                style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down">
                                                                                <i className="icofont icofont-minus f-9"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                                            className="form-control f-12" defaultValue='0' value={ConvertToRupiah(s.vol2)}  placeholder="0"
                                                                            onChange={(e)=>this.handleChange("setVol2", e.target.value)} 
                                                                            onFocus={(e) => e.target.select()} />
                                                                        <span className="input-group-btn">
                                                                            <button type="button"   
                                                                                onClick={()=>this.handleChange("plusVol2", Number(s.vol2))} 
                                                                                className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom "} 
                                                                                style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up">
                                                                                <span className="icofont icofont-plus f-9"></span>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-1 pr-0">
                                                                    <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>Price</label>
                                                                </div>
                                                                <div className="col-sm-3 pr-0">
                                                                    <div
                                                                        className="ui input col-sm-12 pl-4 pr-0 text-right align-self-center input-right">
                                                                        <input placeholder="Name" type="text"
                                                                            onChange={(e)=>this.handleChange("setPrice2", e.target.value)} 
                                                                            onFocus={(e) => e.target.select()} 
                                                                            value={ConvertToRupiah(s.price2)}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-5 mb-3 f-14">
                                                                    <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>Broker Receive</label>
                                                                </div>
                                                                <div className="col-sm-3 pr-0 pl-0">
                                                                    <Select
                                                                        getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                                                        filterOption={customFilter}
                                                                        isSearchable={true}
                                                                        maxMenuHeight={155}
                                                                        styles={customStyles}
                                                                        placeholder={<div>Search..</div>}
                                                                        options={p.custodyList}
                                                                        onChange={(e) => this.setState({broker2: e.saham})}
                                                                        className="LandingPageSelectBroker text-left"
                                                                        theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                                                        value={s.broker2 === "Please Select Broker" ? "" : searchCode('selected', s.broker2, p.custodyList)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4 pr-0">
                                                                    <label htmlFor="" className={"f-13 f-xs-14 mt-3 align-middle align-self-center pr-0"}>
                                                                        {this.state.broker2}
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-12 mb-3 pr-0 text-right">
                                                                    <button onClick={this.handleSendFT} className="btn btn-primary"><i className={"fa fa-paper-plane"}></i>&nbsp;Send</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={tab === 5 ? 'd-block f-12' : 'd-none'}>
                                    <div className="d-border-bottom mb-3">
                                        <div className="form-group mb-3 px-0">
                                            <div className="col-sm-9 pl-0 h-62">
                                                <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                                    <input type="hidden" onClick={()=>this.handleChangeDate("handleDateSTL")} id={"handleDateSTL"} /> 
                                                    <table>
                                                        <tr>
                                                            <td className={"px-0"}>
                                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                                    <Input placeholder='dd/mm/yy' id="datepickerstartSTL" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                    <span
                                                                        className="fa fa-calendar-alt"></span>
                                                                </span>
                                                                </div>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                                    <Input placeholder='dd/mm/yy' id="datepickerendSTL" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                    <span
                                                                        className="fa fa-calendar-alt"></span>
                                                                </span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                                    onClick={()=>p.handleOnClick('landingPage','GoFundTransferSTL','none')}>Go</button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <FundStockAgGrid size={widthSize()}/>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={tab === 3 ? 'd-block f-12' : 'd-none'}>
                                    <div className="d-border-transparent-grey">
                                        <div className="form-group px-0 pt-5" style={{marginBottom : "10px"}}>
                                            <CancelGrid size={widthSize()}/>
                                            <CancelGrid2 size={widthSize()}/>
                                        </div>
                                    </div>
                                    <div className={"col-sm-12 text-right mb-0 px-3 h-40"}>
                                        <button onClick={this.handleSendFT} className={"btn btn-primary"}><i className={"fa fa-paper-plane"}>&nbsp;Send</i></button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
// 4.2 F/T List table
class FundAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?180:s=="s75"?100:90, minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                    return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                },{ field: "no", headerName: "No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?190:s=="s67"?170:s=="s75"?130:s=="s80"?120:s=="s85"?100:60, comparator: integerComparator,
                    minWidth: 60,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?290:s=="s50"?210:s=="s67"?205:s=="s75"?195:s=="s80"?170:s=="s85"?150:130, comparator: integerComparator,
                    minWidth: 115,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "fee", headerName: "Fee", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?325:s=="s50"?310:s=="s67"?300:s=="s75"?235:s=="s80"?195:s=="s85"?140:s=="s90"?130:130, comparator: integerComparator,
                    minWidth: 130,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "bank", headerName: "Bank", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?315:s=="s50"?310:s=="s67"?310:s=="s75"?260:s=="s80"?220:s=="s85"?200:s=="s90"?185:180, comparator: stringComparator,
                    minWidth: 150,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "bankAcNo", headerName: "Bank Ac No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?315:s=="s50"?310:s=="s67"?300:s=="s75"?290:s=="s80"?230:s=="s85"?180:s=="s90"?155:150, comparator: integerComparator,
                    minWidth: 150,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "reqDate", headerName: "Req Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s90"?195:s=="s75"?140:120, minWidth: 110,
                    comparator: stringComparator,
                    sort:"desc",
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                    cellRenderer :params=>params.data.status === "-" ? "" : params.data.reqDate.substr(0,10)
                },{ field: "reqTime", headerName: "Req Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s75"?140:120, minWidth: 110,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "status", headerName: "Status", sortable: true, resizable: true,
                    width: s=="s49"?210:s=="s50"?200:s=="s75"?170:160, minWidth: 160,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                    cellRenderer:(params)=>params.data.status === "-" ? "" : params.data.status
                },{ field: "action", headerName: "Actions", sortable: true, filter: "agTextColumnFilter", width:100,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.no === "\xa0"){
                            eDiv.innerHTML = '';
                        }else{
                            let status = params.node.data.status.toUpperCase();
                            if(status === "NEW"){
                                eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9">Cancel</button>'+
                                '</span>';
                                var aButton = eDiv.querySelectorAll('.btn-cellamend')[0];
                                
                                function closeClick(){
                                    self.refs.frameAction.closeModal(100);
                                }
    
                                function cancelModal(){
                                    self.refs.frameAction.showModal({
                                        headerClass: () => <div className="text-right">
                                            <i className="icofont icofont-close text-icofont-close text-white click-pointer"
                                                onClick={closeClick}></i></div>,
                                        size: 'mini',
                                        contentClass: DetailCancelFT,
                                        onOpen: self.props.handleOnClick('landingPage', 'cancelFundTransfer|openModal',params.data.no),
                                    })
                                }
    
                                aButton.addEventListener('click', cancelModal);
                            }else{
                                eDiv.innerHTML = '<span class="px-1">' +
                                '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9 disabled">Cancel</button>'+
                                '</span>';
                            }                  
                        }                   
                        return eDiv;
                    },
                },

            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },  
            getRowHeight : function (params) {
                return 32;
            },          
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-381 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.fundTransferList}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// 4.3 Cancel 1 table
class CancelGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const size = this.props.size;
        this.state = {
            columnDefs: [
                { field: "cw", headerName: "Cw#", sortable: true, filter: "agTextColumnFilter", resizable:
                    true, width: size=="s75"?190:84, minWidth: 84,
                    suppressSizeToFit: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    }, 
                    cellRenderer:(params)=>params.data.cw === "\xa0" ? "" : params.data.cw
                },
                { field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: size=='s90'?734:size == 's80'?836:size=='s75'?870:size=='s67'?846:size=='s50'?1397:644,
                    minWidth: 644,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " text-right grid-table d-border-aggrid-right f-12";
                    }
                },
                { field: "fee", headerName: "Fee", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: size=='s90'?570:size == 's80'?644:size=='s75'?690:size=='s67'?956:size=='s50'?1041:515,
                    minWidth: 515,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right  grid-table d-border-aggrid-right f-12";
                    }
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {   cw: "Cwxx",
                    amount: "1,223,222",
                    fee: "0",
                }, {   cw: "Cwxx",
                    amount: "1,223,222",
                    fee: "0",
                }, {   cw: "Cwxx",
                    amount: "1,223,222",
                    fee: "0",
                }, {   cw: "Cwxx",
                    amount: "1,223,222",
                    fee: "0",
                },

            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-155 ag-theme-balham-dark ag-bordered table-bordered h-100 ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.fundTransferCancel1}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// 4.3 Cancel 2 table
class CancelGrid2_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const size = this.props.size;
        this.state = {
            columnDefs: [
                { field: "cw", headerName: "Cw #", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: size=="s75"?230:100, minWidth:100,
                    suppressSizeToFit: true,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer:(params)=>params.data.cw === "\xa0" ? "" : params.data.cw,
                },
                { field: "requestdate", headerName: "Request Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: size=="s90"?319:size=="s80"?363:size=="s75"?375:size=="s67"?442:size=="s50"?603:283,
                    minWidth: 283,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-left grid-table d-border-aggrid-right f-12";
                    }
                },
                { field: "transferdate", headerName: "Transfer Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: size=="s90"?321:size=="s80"?366:size=="s75"?380:size=="s67"?447:size=="s50"?610:285,
                    minWidth: 285,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left  grid-table d-border-aggrid-right f-12";
                    }
                },
                { field: "amount", headerName: "Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: size=="s90"?321:size=="s80"?366:size=="s75"?380:size=="s67"?447:size=="s50"?610:304,
                    minWidth: 304,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right  grid-table d-border-aggrid-right f-12";
                    }
                },

                { field: "fee", headerName: "Fee", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: size=="s90"?326:size=="s80"?370:size=="s75"?380:size=="s67"?448:size=="s50"?599:269,
                    comparator: integerComparator,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {   cw: "CWxx",
                    requestdate: "1/12/2018",
                    transferdate: "10/12/2018",
                    amount: "1,223,333",
                    fee:"0",
                }, {   cw: "CWxx",
                    requestdate: "1/12/2018",
                    transferdate: "10/12/2018",
                    amount: "1,223,333",
                    fee:"0",
                }, {   cw: "CWxx",
                    requestdate: "1/12/2018",
                    transferdate: "10/12/2018",
                    amount: "1,223,333",
                    fee:"0",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-220 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                        marginTop: "30px"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.fundTransferCancel2}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// 4.5 Stock Transfer List
class FundStockAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { field: "date", headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?330:s=="s50"?310:s=="s67"?270:s=="s75"?250:s=="s80"?180:s=="s85"?170:s=="s90"?140:155, minWidth: 130, comparator: dateComparator,
                    sort:"asc",
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    }, suppressSizeToFit: true
                },{ field: "no", headerName: "No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?360:s=="s50"?285:s=="s67"?235:s=="s75"?210:s=="s80"?250:s=="s85"?190:s=="s90"?160:140, comparator: integerComparator,
                    minWidth: 60,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "stock", headerName: "Code", sortable: true, resizable: true,
                    width: 100, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "vol", headerName: "Vol(Shr)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?415:s=="s50"?345:s=="s67"?325:s=="s75"?315:s=="s80"?275:s=="s85"?240:s=="s90"?225:200, comparator: integerComparator,
                    minWidth: 150,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "amount", headerName: "Amount", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?380:s=="s50"?345:s=="s67"?325:s=="s75"?315:s=="s80"?275:s=="s85"?220:s=="s90"?210:200, comparator: integerComparator,
                    minWidth: 150,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "custodianBankDestination", headerName: "Custodian Bank Destination", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?405:s=="s50"?380:s=="s67"?320:s=="s75"?300:s=="s80"?225:s=="s90"?225:200, minWidth: 170, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "status", headerName: "Status", sortable: true, resizable: true,
                    width: s=="s49"?345:s=="s50"?310:s=="s67"?260:s=="s75"?240:s=="s80"?175:s=="s90"?215:200, minWidth: 150, comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "action", headerName: "Action", sortable: true, filter: "agTextColumnFilter", width:140,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center locked-col locked-visible";
                    },
                    cellRenderer : function (params) {
                        var eDiv = document.createElement('div');
                        if(params.data.date === "\xa0"){
                            eDiv.innerHTML = "";
                        }else{
                            let status = params.node.data.status.toUpperCase();
                            if(status === "NEW"){                                    
                                eDiv.innerHTML = '<span class="px-1">' +
                                    '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9">Cancel</button>'+
                                    '</span>';
                                    var aButton = eDiv.querySelectorAll('.btn-cellamend')[0];
                                aButton.addEventListener('click', () => self.props.handleOnClick('landingPage', "cancelStocktransfer", 
                                    {"message_id": params.data.msg_id, "ref_no": params.data.no}));
                            }else{
                                eDiv.innerHTML = '<span class="px-1">' +
                                    '<button class="btn-cellamend btn btn-sm btn-primary mx-1 f-9 disabled">Cancel</button>'+
                                    '</span>';
                            }

                            return eDiv;
                        }
                    }, suppressSizeToFit: true
                },

            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-381 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.fundStockList}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// Pin Verifikasi
class PINVerify extends React.PureComponent {

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'fundtransfer'/>
            </>
        );
    }
}

// ============= WARRANT/RIGHT EXERCISE DISPLAY =============
const subMenuWarrantR = [
    {tab: 1, show: true, title: "EXERCISE REQUEST"},
    {tab: 2, show: true, title: "EXERCISE LIST"},
]
class WarrantRight_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rangeReq: {}
        };
    }

    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartWR').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateWr").click(); 
            });
            $('#datepickerendWR').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateWr").click(); 
            });
        });
    }
    handleChangeDate = () => {
        let start = document.getElementById('datepickerstartWR').value
        let to =  document.getElementById('datepickerendWR').value
        if(start != "" && to == ""){
            document.getElementById('datepickerendWR').value = GetTimeForDateTicker(0,0);
            to = GetTimeForDateTicker(0,0)
            $("#datepickerendWR").datepicker("setDate", to);
        };
        this.setState({...this.state, rangeReq: {start, to}})
    }
    buttonClickPIN = (e) => {
        var frameAction = this.refs.frameAction;
        frameAction.showModal({
            headerClass: () => <div className="text-right"></div>,
            contentClass: PINVerify,
            onClose: (result) => console.log('Second modal result = ', result),
            size: "mini"
        });
    }
    handleSetQty = (qty, checked) => {
        let p = this.props; let s = this.state;
        let e_value = p.exerciseState.price ? p.exerciseState.price * qty * p.exerciseState.ratio : 0;
        let feeVal = e_value * (p.exerciseState.fee/100);
        this.setState({...s, checked, qty, e_value, feeVal, amount: e_value + feeVal});
    };
    sendClick = (e) => {
        let p = this.props; let s = this.state;
        if(!p.exerciseState.saham || !s.qty){
            p.handleStatusAlert3('noConfirm', p.statusAlertN, "Form can't empty");
        }else if(s.qty > p.exerciseState.sellableqty){
            p.handleStatusAlert3('noConfirm', p.statusAlertN, "your volume is less");
        }else if(s.amount > p.exerciseState.a_cash)
            p.handleStatusAlert3('noConfirm', p.statusAlertN, 'Exercise Amount max: '+thousandSeparator(p.exerciseState.a_cash));
        else{
            p.setDataHandler({ "tempExercise": setTempData.createExercise({...s, ...p.exerciseState}) });
            var frameAction = this.refs.frameAction;
            frameAction.showModal({
                headerClass: () => <div className="text-right"></div>,
                contentClass: ConfirmationExerciseRequest,
                onClose: (status) => status === "send" && this.resetForm(),
                size: "mini"
            });
        }
    };
    resetForm = (tab) => this.setState({ saham: null, ratio: 0,
        price: 0, e_value: 0, amount: 0, sellableqty: 0, qty: 0
    })

    render () {
        let p = this.props; let s = this.state;
        !(p.exerciseState.price) && this.resetForm()
        let menuLeng = subMenuWarrantR.filter(d=>d.show).length;
        let colType = menuLeng > 6 || menuLeng === 5 ? "mn-"+menuLeng : "sm-"+(12/menuLeng);
        const paddingParagraph = {
            paddingTop: '10px'
        }

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        const customFilter = (option, searchText) => {
            var code = option.data.asset_code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if (searchText.toLowerCase().includes(' ')) {
                if (saham) {
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };

        return (<>
            <div className="bg-black-trading card card-75">
                <AppFrameAction ref="frameAction" />
                <div className={`${(p.verifyPinStatus || p.pinPage === "rightWarantPage") ? "d-none" : "d-block"} container-fluid px-1 f-12`} id="RightWarrantPIN">
                    <VerifyPINPortofolio pos="rightWarrant" onClose={() => null} />
                </div>
                <div className={`${(p.verifyPinStatus || p.pinPage === "rightWarantPage") ? "d-block" : "d-none"} container-fluid px-1 f-12`} id="ContentRightWarrant">
                    <div className="col-sm-12 px-0" style={paddingParagraph}>
                        <div className={`cssmenu col-sm-${menuLeng > 2 ? "12" : "6"} mx-0 px-0 h-45`}>
                            <ul className={"d-border-top d-border-left d-border-right"}>
                                {
                                    subMenuWarrantR.filter(d=>d.show).map((item, id) => (
                                        <li className={`d-border-right click-pointer col-${colType} px-0 mx-0 f-12 text-center ${p.exerciseState.tab == item.tab ? "active" : "text-white"}`} 
                                            key={"smw"+id} onClick={() => p.exerciseState.tab !== item.tab && p.handleOnClick("landingPage", "tabExercise", item.tab)}>
                                            <a><span className="f-11">&nbsp; {item.title}</span></a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="col-sm-12 px-4 bg-black-trading pt-0 d-border card-472">
                            <div className={p.exerciseState.tab === 1 ? 'd-block f-12' : 'd-none'}>
                                <div className="container mx-0 pt-3">
                                    <div className="row">
                                        <div className="col-md-8 pl-0">
                                            <div className="bg-tableheader col-sm-12 px-0 mx-0 text-center py-2 f-16">EXERCISE RIGHT ISSUE</div>
                                            <table className="table text-white d-border-table table-sm table-borderless">
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Code</td>
                                                    <td width="33%" className="text-right pb-0">
                                                        <Select
                                                            getOptionLabel={(option) => `${option.asset_code} - ${option.saham}`}
                                                            filterOption={customFilter}
                                                            isSearchable={true}
                                                            maxMenuHeight={155}
                                                            styles={customStyles}
                                                            placeholder={<div>Search..</div>}
                                                            options={p.exerciseCode}
                                                            className="stockPageSelect text-left"
                                                            theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                                            onChange={(e) => p.setDataHandler({"exerciseState": {...p.exerciseState, ...e}})}
                                                            value={p.exerciseState.saham ? p.exerciseState : null}
                                                        />
                                                    </td>
                                                    <td width="40%" className="text-left ver-center py-0">{p.exerciseState.saham || "Please Select Code"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Exercise Ratio</td>
                                                    <td className="text-right pb-0">
                                                        <NumberFormat
                                                            value={p.exerciseState.ratio || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap  ver-center pb-0">Exercise Price</td>
                                                    <td className="text-right pb-0"><NumberFormat
                                                            value={p.exerciseState.price || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            thousandSeparator={true}
                                                            disabled={true}
                                                            allowNegative={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Available Right</td>
                                                    <td className="text-right pb-0">
                                                        <NumberFormat
                                                            value={p.exerciseState.sellableqty || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td class="text-left text-muted ver-center py-0">(Share)</td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Exercise Quantity</td>
                                                    <td className=" text-right pb-0">
                                                        {/* <NumberInput idclassname="ExerciseRequestInput" defaultValue={"0"} /> */}
                                                        <div className={"input-group z0"}>
                                                            <span className="input-group-btn">
                                                                <button type="button" className={"form-control ui icon button bg-grey px-2 d-border-left d-border-top d-border-bottom"} 
                                                                    style={{"border-top-right-radius": "0px", "border-bottom-right-radius": "0px", "margin": "0px"}} command="Down"
                                                                    onClick={()=>this.handleSetQty(Number(s.qty) > 0 ? Number(s.qty) - 1 : 0)}>
                                                                    <i className="icofont icofont-minus f-9"></i>
                                                                </button>
                                                            </span>
                                                            {/* <input type="text" style={{"border-radius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} className="form-control f-12"/> */}
                                                            <NumberFormat
                                                                value={s.qty || 0}
                                                                id="tradeValue"
                                                                style={{"borderRadius": "0px", "text-align": "right", "border-right": "0", "border-left":"0"}} 
                                                                className="form-control bg-grey px-2"
                                                                decimalSeparator = "."
                                                                displayType = "input"
                                                                thousandSeparator={true}
                                                                allowNegative={false}
                                                                onValueChange={({value}) => this.handleSetQty(value)}
                                                                onFocus={(e) => e.target.select()}
                                                            />
                                                            <span className="input-group-btn">
                                                                <button type="button" className={"form-control ui icon button bg-grey px-2 d-border-right d-border-top d-border-bottom"} 
                                                                    style={{"border-top-left-radius": "0px", "border-bottom-left-radius": "0px"}} command="Up"
                                                                    onClick={()=>this.handleSetQty(Number(s.qty || 0) + 1)}>
                                                                    <span className="icofont icofont-plus f-9"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </td>  
                                                    <td class="text-left text-muted ver-center py-0">(Share)&nbsp;
                                                        <Checkbox 
                                                            label='All' 
                                                            name={"allCheck"} 
                                                            checked={s.checked}
                                                            onChange={() => this.handleSetQty(p.exerciseState.sellableqty || 0, !s.checked)}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Exercise Value</td>
                                                    <td className="text-right pb-0">
                                                        <NumberFormat
                                                            value={s.e_value || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            disabled={true}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap ver-center pb-0">Exercise Fee</td>
                                                    <td className=" text-right pb-0">
                                                        <NumberFormat
                                                            value={s.feeVal || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            disabled={true}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap  ver-center pb-0">Total Amount</td>
                                                    <td className="text-right pb-0">
                                                        <NumberFormat
                                                            value={s.amount || 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            defaultValue="0"
                                                            disabled={true}
                                                            thousandSeparator={true}
                                                            allowNegative={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="no-wrap  ver-center pb-0">Available Cash</td>
                                                    <td className="text-right pb-0">
                                                        <NumberFormat
                                                            value={p.exerciseState.a_cash > 0 ? p.exerciseState.a_cash : 0}
                                                            id="tradeValue"
                                                            className="form-control bg-grey px-2 d-border"
                                                            decimalSeparator = "."
                                                            displayType = "input"
                                                            type="text"
                                                            defaultValue="0"
                                                            disabled={true}
                                                            thousandSeparator={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="text-right pb-2">
                                                        <button className="btn btn-primary" 
                                                            disabled={p.userProfile.status != "ACTIVE" || s.qty <= 0 || p.exerciseState.a_cash === undefined || p.exerciseState.a_cash <= 0}
                                                            onClick={(p.userProfile.status == "ACTIVE" || p.exerciseState.a_cash > 0) && this.sendClick}>
                                                            <i className={"fa fa-paper-plane"}></i>&nbsp;Send
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={p.exerciseState.tab === 2 ? 'd-block f-12' : 'd-none'}>
                                    <div className="d-border-bottom mb-3">
                                        <input type="hidden" onClick={this.handleChangeDate} id={"handleDateWr"} />
                                        <div className="form-group mb-3 px-0">
                                            <div className="col-sm-9 pl-0 h-62">
                                                <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                                    <table>
                                                        <tr>
                                                            <td className={"px-0"}>
                                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                                    <Input placeholder='dd/mm/yy' id="datepickerstartWR" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                    <span
                                                                        className="fa fa-calendar-alt"></span>
                                                                </span>
                                                                </div>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                                            </td>
                                                            <td className={"px-0"}>
                                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                                    <Input placeholder='dd/mm/yy' id="datepickerendWR" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                                    <span
                                                                        className="fa fa-calendar-alt"></span>
                                                                </span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                                    onClick={()=>p.handleOnClick('landingPage', 'goExercise', s.rangeReq)}>Go</button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <ExcersiceListAgGrid size={widthSize()}/>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

class ConfirmationExerciseRequest_Base extends React.PureComponent {
    componentDidMount(){
        $(document).keypress(function(e) {
            if(e.which === 13) {
                $("#enter-ok").click();
            }
        });
    }

    render() {
        let p = this.props;
        return (<>
            <AppFrameAction ref="frameAction" />
            <div className="col-sm-12 text-white px-0 mx-0 f-12">
                <div className="col-sm-12 row mx-0 px-0">
                    <div className="col-sm-6 px-0 mx-0">
                        Excersice Information
                    </div>
                    <div className="col-sm-6 px-0 mx-0 text-right">
                        (Exercise No: <span className="text-primary">#{p.tempExercise.id || 0}</span>)
                    </div>
                </div><br/>
                <div className="col-sm-12 mx-0 px-0">
                    <Table size="sm" responsive borderless className="text-white">
                        <thead></thead>
                        <tbody className="no-wrap d-border-table">
                            {p.tempExercise.data.map((item, id) => (
                                <tr key={"tpE"+id}>
                                    <td>{item.label}</td>
                                    <td>:</td>
                                    <td>
                                        {
                                            item.type === "text" ? 
                                                item.value 
                                            : <NumberFormat
                                                value={item.value}
                                                type="text"
                                                displayType="text"
                                                thousandSeparator={true}
                                            />
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div><br/>
            <div className="col-sm-12 text-center f-13 mx-0 px-0">
                Do you want to excercise this RIGHT?
            </div><br/>
            <div className="col-sm-12 row mx-0 px-0">
                <div className="col-sm-6 text-right">
                    <button className="btn btn-dark col-sm-12" onClick={
                        () => this.refs.frameAction.closeModal("cancel")
                    }>Cancel</button>
                </div>
                <div className="col-sm-6">
                    <button className="btn btn-danger col-sm-12" id="enter-ok" onClick={
                        () => {
                            p.handleOnClick('landingPage', 'sendExercise')
                            this.refs.frameAction.closeModal("send")
                        }
                    }>OK</button>
                </div>
            </div>
        </>);
    }
}

class ExcersiceListAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = this.props.size;
        this.state = {
            columnDefs: [
                { 
                    field: "no", headerName: "No", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?180:s=="s67"?170:s=="s75"?130:s=="s80"?80:70, 
                    comparator: comparatorType["integer"],
                    minWidth: 70,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                },{ 
                    field: "reqdate", headerName: "Req. Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?160:s=="s75"?120:100, minWidth: 100, 
                    comparator: comparatorType["text"],
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12", 
                    suppressSizeToFit: true
                },{ field: "exrid", headerName: "Exercise ID", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?240:s=="s50"?220:s=="s67"?190:s=="s75"?160:s=="s80"?110:100, 
                    comparator: comparatorType["integer"],
                    minWidth: 100,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                },{ 
                    field: "right_code", headerName: "Exercise Code", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?200:s=="s67"?180:s=="s75"?170:s=="s80"?150:130, 
                    comparator: comparatorType["text"],
                    minWidth: 130,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                },{ 
                    field: "price", headerName: "Exercise Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?200:s=="s67"?170:s=="s75"?150:s=="s80"?130:120, 
                    comparator: comparatorType["integer"],
                    minWidth: 120,
                    valueFormatter: ({ value }) => thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "fee", headerName: "Exercise Fee", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?190:s=="s67"?170:s=="s75"?160:s=="s80"?150:135, 
                    comparator: comparatorType["integer"],
                    minWidth: 150,
                    valueFormatter: ({ value })=>thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "quantity_right", headerName: "Right Qty(shr)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s67"?160:s=="s75"?140:s=="s90"?160:140, minWidth: 140, 
                    comparator: comparatorType["integer"],
                    valueFormatter: ({ value }) => thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "quantity_stock", headerName: "Stock Qty (shr)", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s90"?160:s=="s75"?140:140, minWidth: 140, 
                    comparator: comparatorType["integer"],
                    valueFormatter: ({ value }) => thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "value", headerName: "Value", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?180:s=="s67"?150:s=="s75"?155:s=="s80"?125:90, minWidth: 90, 
                    comparator: comparatorType["integer"],
                    valueFormatter: ({ value }) => thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "amount", headerName: "Amount", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?210:s=="s50"?190:s=="s67"?150:s=="s75"?150:s=="s80"?135:120, minWidth: 110, 
                    comparator: comparatorType["integer"],
                    valueFormatter: ({ value }) => thousandSeparator(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12",
                },{ 
                    field: "status", headerName: "Status", sortable: true, resizable: true,
                    width: s=="s49"?210:s=="s50"?180:s=="s67"?160:s=="s75"?150:s=="s80"?145:125, minWidth: 125, 
                    comparator: comparatorType["text"],
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                },{ 
                    field: "action", headerName: "Action", sortable: true, filter: "agTextColumnFilter", width:100,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass: () => " grid-table d-border-aggrid-right text-center locked-col locked-visible",
                    cellRenderer: 'cancelRenderer',
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            frameworkComponents: {
                cancelRenderer: ({ data }) => data.status ? 
                    <span class="px-1">
                        <button class={`btn btn-sm btn-primary mx-1 f-9 ${data.status === "New" ? "" : "disabled"}`}
                            disabled={!(data.status === "New")}
                            onClick={() => this.cancelHandler(data)}>Cancel</button>
                    </span> : ""
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    cancelHandler = (data) => this.refs.frameAction.showModal({
        headerClass: () => <div className="text-right">
                <i className="icofont icofont-close text-icofont-close text-border click-pointer" 
                    onClick={() => this.refs.frameAction.closeModal(1000)}></i>
            </div>,
        size: 'mini',
        contentClass: ConfirmationExerciseRequest,
        onOpen: this.props.handleOnClick('landingPage', 'openExercise', data),
        onClose: (val) => val === "cancel" && this.props.handleOnClick('setData','tempExercise', {})
    });

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction" />
                <div
                    className={"card-381 ag-theme-balham-dark ag-bordered table-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%",
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.exerciseList}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// ============= 5. Account Info Display =============
class InquryAccount_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render () {
        var p = this.props
        const imgdisplay = {
            display: 'inline-flex',
            paddingTop: '3px'
        };

        const paddingParagraph = {
            paddingTop: '10px'
        }
        const paddingParagraphBottom = {
            paddingBottom: '10px'
        }

        const divMargin = {
            marginBottom: '15px'
        }

        const imgUser = {
            margin: 'auto',
            backgroundColor: 'var(--warna-bg-trading-gray)',
            // borderBottom: '2px solid var(--warna-inactive-gradient)'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid px-1 f-12" >
                    <div id="AccountPin" className={`${(p.verifyPinStatus || p.pinPage === "accountPagePage") ? "d-none" : "d-block"} col-sm-12 text-center align-self-center`}>
                        <VerifyPINPortofolio pos="account" onClose={() => null} />
                    </div>
                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}

                    <div className={`${(p.verifyPinStatus || p.pinPage === "accountPagePage") ? "d-block" : "d-none"} col-sm-12 px-0`} style={paddingParagraph} id={"ContentAccount"}>
                        {/* <PortofolioAgGrid/> */}
                        <div className="cssmenu col-sm-6 mx-0 px-0 h-45">
                            <ul className={"d-border-top d-border-left d-border-right"}>
                                <li className={ this.state.activeTab === '1' ? 'd-border-right active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' : 'd-border-right text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } onClick={() => { this.toggle('1'); }}><a><span className="f-11">&nbsp; Account Infromation</span></a></li>
                                <li className={ this.state.activeTab === '2' ? 'd-border-right active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' : 'd-border-right text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } onClick={() => { this.toggle('2'); }}><a><span className="f-11">&nbsp; Contact Information</span></a></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 px-4 pb-0 bg-grey bg-black-trading pt-0 d-border card-472">
                            {/* Account  Information Display*/}
                            <div className={this.state.activeTab === '1' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0" style={{ paddingTop : "10px" }}>
                                    <div className="row">
                                        <div className={"col-sm-6 pl-0"}>
                                            {/* table 1 bagian kiri */}
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-290"}>
                                                {p.ai.ai1.map((d, id)=>{
                                                        return(<tr key={"ai1"+id}>
                                                            <td className={d.label == "-" ? "" : "d-border"}>{d.label == "-" ? <>&nbsp;</> : d.label}</td>
                                                            <td width={d.label == "-" ? "" :"50%"} 
                                                                className={d.label == "-" ? "" : (id%2 == 1 ? "d-border hover-tables" : "even-td d-border hover-tables")}>
                                                                    {d.label == "-" ? <>&nbsp;</> : d.value}
                                                            </td>
                                                        </tr>)
                                                    })}
                                            </table>
                                        </div>
                                        <div className={"col-sm-6 pr-0"}>
                                            {/* table 1 bagian kanan  */}
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-290"}>
                                                {p.ai.ai2.map((d, id)=>{
                                                        return(<tr key={"ai2"+id}>
                                                            <td width="50%" className={d.label == "ai2" ? "bg-tableheader" : "d-border"}>{d.label == "ai2" ? <>&nbsp;</> : d.label}</td>
                                                            <td width={d.label == "ai2" ? "" :"50%"} 
                                                                className={d.label == "ai2" ? "bg-tableheader" : ( id < 6 ? 
                                                                    (id%2==1 ? "d-border hover-tables" : "even-td d-border hover-tables") :
                                                                    (id%2==0 ? "d-border hover-tables" : "even-td d-border hover-tables"))}>
                                                                {d.value}
                                                            </td>
                                                        </tr>)
                                                    })}  
                                            </table>
                                        </div>
                                        <div className={"col-md-4 px-0 pt-4"}>
                                            <table width="100%"
                                                    className={"table table-bordered table-responsive mb-0"}>
                                                    <tr className={"bg-tableheader"}>
                                                        <td colSpan="2" className={"text-center"}>Bank Account 1</td>
                                                    </tr>
                                                    {p.ai.bankAccount.ba1.map((d, id)=>{
                                                        return(<tr key={"ba1"+id}>
                                                            <td width="50%" className={d.label == "ai2" ? "bg-tableheader" : "d-border"}>{d.label == "ai2" ? <>&nbsp;</> : d.label}</td>
                                                            <td width={d.label == "ai2" ? "" :"50%"} 
                                                                className={d.label == "ai2" ? "bg-tableheader" : (id%2==1 ? "d-border hover-tables" : "even-td d-border hover-tables")}>
                                                                {d.value}
                                                            </td>
                                                        </tr>)
                                                    })}
                                            </table>
                                        </div>
                                        <div className={"col-md-4 px-0 pt-4"}>
                                            <table width="100%"
                                                    className={"table table-bordered table-responsive mb-0"}>
                                                    <tr className={"bg-tableheader"}>
                                                        <td colSpan="2" className={"text-center"}>Bank Account 2</td>
                                                    </tr>
                                                    {p.ai.bankAccount.ba2.map((d, id)=>{
                                                        return(<tr key={"ba2"+id}>
                                                            <td width="50%" className={d.label == "ai2" ? "bg-tableheader" : "d-border"}>{d.label == "ai2" ? <>&nbsp;</> : d.label}</td>
                                                            <td width={d.label == "ai2" ? "" :"50%"} 
                                                                className={d.label == "ai2" ? "bg-tableheader" : (id%2==1 ? "d-border hover-tables" : "even-td d-border hover-tables")}>
                                                                {d.value}
                                                            </td>
                                                        </tr>)
                                                    })}
                                            </table>
                                        </div>
                                        <div className={"col-md-4 px-0 pt-4"}>
                                            <table width="100%"
                                                    className={"table table-bordered table-responsive mb-0"}>
                                                    <tr className={"bg-tableheader"}>
                                                        <td colSpan="2" className={"text-center"}>Bank Account 3</td>
                                                    </tr>
                                                    {p.ai.bankAccount.ba3.map((d, id)=>{
                                                        return(<tr key={"ba3"+id}>
                                                            <td width="50%" className={d.label == "ai2" ? "bg-tableheader" : "d-border"}>{d.label == "ai2" ? <>&nbsp;</> : d.label}</td>
                                                            <td width={d.label == "ai2" ? "" :"50%"} 
                                                                className={d.label == "ai2" ? "bg-tableheader" : (id%2==1 ? "d-border hover-tables" : "even-td d-border hover-tables")}>
                                                                {d.value}
                                                            </td>
                                                        </tr>)
                                                    })}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Contact Information Display*/}
                            <div className={this.state.activeTab === '2' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0" style={{ paddingTop : "30px" }}>
                                    <div className="row">
                                        <div className={"col-sm-6 px-0"}>
                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>
                                                {p.ai.ci1.map((d,id)=>{
                                                    return (<tr key={"ci1"+id}>
                                                            <td className={"d-border"}>{d.label}</td>
                                                            <td width="50%" className={id%2==0 ? "d-border hover-tables" : "even-td d-border hover-tables"}>{d.value}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                        <div className={"col-sm-6 pr-0"}>
                                            <table width="100%" className={"table table-bordered table-responsive card-169"}>
                                                {p.ai.ciJob.map((d,id)=>{
                                                    return(<tr key={"cijob"+id}>
                                                            <td width="50%" className={"d-border"} >{d.label == "-" ? <>&nbsp;</> : d.label}</td>
                                                            <td width="50%" className={id%2==0 ? "d-border hover-tables" : "even-td d-border hover-tables"}>{d.value}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                        <div className={"col-sm-12 px-0"}>
                                            <table className={"table table-borderder table-responsive card-113"}>
                                                <tbody>
                                                    <tr>
                                                        <td className={"d-border text-center td-bluelight"}>Item</td>
                                                        <td className={"d-border text-center td-bluelight"}>Post No.</td>
                                                        <td className={"d-border text-center td-bluelight"}>Address</td>
                                                        <td className={"d-border text-center td-bluelight"} width="50%">Address</td>
                                                    </tr>
                                                    {p.ai.ciAddress.map((d,id)=>{
                                                        return(<tr key={"ciA"+id} className={id%2==0 ? "hover-tables even-td" : "hover-tables"}>
                                                                <td className={"d-border"}>{d.item}</td>
                                                                <td className={"d-border"}>{d.postno}</td>
                                                                <td className={"d-border"}>{d.address}</td>
                                                                <td className={"d-border"}>{d.address2}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

// ============= 5.5 TRADE P/L Display =============
class TradePLPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        };
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartTrade').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/11/2020',
                autoclose: true,
                endDate: ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateTradePl").click(); 
            });
            $('#datepickerendTrade').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/11/2020',
                autoclose: true,
                endDate: ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateTradePl").click(); 
            });
        });
    }
    handleChangeDate=()=>{
        let nStartDate = document.getElementById('datepickerstartTrade').value
        let nEndDate =  document.getElementById('datepickerendTrade').value
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('datepickerendTrade').value = GetTimeForDateTicker(0,0)
            nEndDate = GetTimeForDateTicker(0,0)
            $("#datepickerendTrade").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to:nEndDate}
        this.props.handleOnClick('datepicker','tradePl', nDatepicker)
    }
    render() {
        let p = this.props;
        return (
            <div className="container-fluid px-2 mx-0 pb-0 pt-1 card-527">
                <div id="TradePLPIN" className={`text-center align-self-center ${(p.verifyPinStatus || p.pinPage === "tradePLPage") ? "d-none" : "d-block"}`}>
                    <VerifyPINPortofolio pos="tradePL" onClose={() => null} />
                </div>
                <div id="ContentTradePL" className={`${(p.verifyPinStatus || p.pinPage === "tradePLPage") ?  "d-block" : "d-none"}`}>
                    <AppFrameAction ref="frameAction"/>
                    <div className="row f-12">
                        <div className={"col-sm-12"}>
                        <input type="hidden" onClick={this.handleChangeDate} id={"handleDateTradePl"} />                            
                            <table>
                                <tr>
                                    <td className={"px-0 py-0"}>
                                        <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                    </td>
                                    <td className={"px-0  py-0"}>
                                        <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                            <Input placeholder='dd/mm/yy' id="datepickerstartTrade" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                            <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                        </div>
                                    </td>
                                    <td className={"px-0  py-0"}>
                                        <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                    </td>
                                    <td className={"px-0 py-0" }>

                                        <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                            <Input placeholder='dd/mm/yy' id="datepickerendTrade" className="col-sm-12 pl-0 pr-0 text-center align-self-center "/>
                                            <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                            onClick={()=>p.handleOnClick('landingPage','GoTradePl','none')}>Go</button>
                                    </td>
                                </tr>
                            </table>

                        </div>
                            <div className="col-md-12">
                                <div className="row p-3">
                                    <div className="col-md-3 mt-3 mb-5">
                                        Sell Amount
                                    </div>
                                    <div className="col-md-3">
                                        <Input readonly defaultValue='0,00.' value={mathCall.setRupiah(p.tradePlInfo.sellAmount)}
                                            className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        Sales PL
                                    </div>
                                    <div className="col-md-3">
                                        <Input readonly defaultValue='0,00.' value={mathCall.setRupiah(p.tradePlInfo.salesPl)}
                                            className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        Buy Amount
                                    </div>
                                    <div className="col-md-3">
                                        <Input readonly defaultValue='0,00.' value={mathCall.setRupiah(p.tradePlInfo.buyAmount)}
                                            className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        Sales PL(%)
                                    </div>
                                    <div className="col-md-3">
                                        <Input readonly defaultValue='0,00.' value={mathCall.setRupiah(p.tradePlInfo.perSalesPl, 2, '0.00')}
                                            className="col-sm-12 pl-4 pr-0 text-center align-self-center"/>
                                    </div>
                                </div>

                            </div>
                        </div>

                    <div className="col-sm-12 px-0">
                        <TradePLAgGrid
                            size={widthSize()}/>
                    </div>
                </div>
            </div>
        )
    };
}

// accAvgBuyPrice == Acq. Avg. Price
class TradePLAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "stock", headerName: "Code", sortable: true, resizable: true,
                    comparator: comparatorType.text,
                    width: s=="s49"?210:s=="s50"?195:s=="s67"?180:s=="s75"?160:s=="s80"?135:s=="s85"?120:s=="s90"?110:s=="s100"?105:100,
                    minWidth:140,
                    cellClass: (params) => "grid-table d-border-aggrid-right text-left f-12",
                },{ field: "buy_vol", headerName: "Buy Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?290:s=="s50"?255:s=="s67"?230:s=="s75"?210:s=="s80"?180:s=="s85"?175:s=="s90"?165:s=="s100"?160:150, minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "buy_amount", headerName: "Buy Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?230:s=="s75"?210:s=="s80"?200:s=="s85"?185:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => " grid-table d-border-aggrid-right text-right f-12",
                },{ field: "accumulation_avg_price", headerName: "Acq. Avg. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?310:s=="s50"?270:s=="s67"?230:s=="s75"?230:s=="s80"?200:s=="s85"?180:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "sell_vol", headerName: "Sell Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?210:s=="s50"?195:s=="s67"?170:s=="s75"?160:s=="s80"?140:s=="s85"?135:s=="s90"?115:s=="s100"?110:100,
                    minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "sell_amount", headerName: "Sell Amount", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?310:s=="s50"?280:s=="s67"?240:s=="s75"?220:s=="s80"?200:s=="s85"?185:s=="s90"?170:s=="s100"?160:150, minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "sell_avg_price", headerName: "Avg. Sell Price", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?255:s=="s75"?240:s=="s80"?200:s=="s85"?185:s=="s90"?170:s=="s100"?160:150,
                    minWidth: 150,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "pl", headerName: "P/L(+/-)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?290:s=="s50"?260:s=="s67"?220:s=="s75"?220:s=="s80"?185:s=="s90"?160:s=="s100"?150:140,
                    minWidth: 140,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },{ field: "pl_percentage", headerName: "(%)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    comparator: comparatorType.integer,
                    width: s=="s49"?160:s=="s50"?150:s=="s67"?140:s=="s75"?135:s=="s80"?120:s=="s90"?110:s=="s100"?100:95,
                    minWidth: 140,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 2, '0.00'),
                    cellClass: (params) => "grid-table d-border-aggrid-right text-right f-12",
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowData: [
                {
                    code :"AALI"+s,
                    vol: 3,
                    buyAmount : "13.000",
                    sellAmount: "14.000",
                    feeTax: "15.000",
                    avgPrice: "222",
                    pl: "123",
                    percentage: "1000",
                },{
                    code :"BUMI"+s,
                    vol: 3,
                    buyAmount : "13.000",
                    sellAmount: "14.000",
                    feeTax: "15.000",
                    avgPrice: "222",
                    pl: "123",
                    percentage: "1000",
                },{
                    code :"BUDI"+s,
                    vol: 3,
                    buyAmount : "13.000",
                    sellAmount: "14.000",
                    feeTax: "15.000",
                    avgPrice: "222",
                    pl: "123",
                    percentage: "1000",
                },{
                    code :"SMGR"+s,
                    vol: 3,
                    buyAmount : "13.000",
                    sellAmount: "14.000",
                    feeTax: "15.000",
                    avgPrice: "222",
                    pl: "123",
                    percentage: "1000",
                },
            ],
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterChanged(params){
        let refData = this.props.tradePl.filter(d => d.code != "").map(d => d.code);
        let data = params.api.getFilterModel();
        if(data.code != undefined){
            this.props.handleOnClick("landingPage", "filterTradePL", data.code.values);
        }else if(data.code == undefined && refData.length > 0){
            this.props.handleOnClick("landingPage", "filterTradePL", refData);
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-tradePL ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.tradePl}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered= {this.onFirstDataRendered.bind(this)}
                        onFilterChanged= {this.onFilterChanged.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

// ============= 6.TC & Soe =============
class tcAndSoa extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render () {
        const imgdisplay = {
            display: 'inline-flex',
            paddingTop: '3px'
        };

        const paddingParagraph = {
            paddingTop: '10px'
        }
        const paddingParagraphBottom = {
            paddingBottom: '10px'
        }

        const divMargin = {
            marginBottom: '15px'
        }

        const imgUser = {
            margin: 'auto',
            backgroundColor: 'var(--warna-bg-trading-gray)',
            // borderBottom: '2px solid var(--warna-inactive-gradient)'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="container-fluid px-1 f-12" >


                    {/* <div class="ui section divider small  col-sm-12 f-12 text-center align-self-center"></div> */}

                    <div className="col-sm-12 px-0" style={paddingParagraph}>
                        {/* <PortofolioAgGrid/> */}
                        <div className="cssmenu col-sm-6 mx-0 px-0 h-45">
                            <ul className={"d-border-top d-border-left d-border-right"}>
                                <li 
                                className={ this.state.activeTab === '1' ? 
                                                'd-border-right active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' 
                                                : 'd-border-right text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } 
                                            onClick={() => { this.toggle('1'); }}><a><span className="f-11">
                                                &nbsp; Trade Confirm</span>
                                                </a>
                                                </li>
                                <li className={ 
                                    this.state.activeTab === '2' 
                                    ? 'd-border-right active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' 
                                    : 'd-border-right text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } 
                                    onClick={() => { this.toggle('2'); }}><a><span className="f-11">&nbsp; Settlement of Account</span></a></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 px-0 py-0 mx-0 my-0 bg-grey bg-black-trading d-border card-472">

                            <div className={this.state.activeTab === '1' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <TradeConfirmPage/>
                                </div>
                            </div>
                            <div className={this.state.activeTab === '2' ? 'd-block f-12' : 'd-none'}>
                                <div className="container-fluid mx-0 px-0 my-0 mx-0 py-0" style={{ paddingTop : "10px" }}>
                                    <SoaPage/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class SoaPage extends React.PureComponent{
    constructor(props) {
        super(props);

    }
    render(){
        return(
            <div className="d-border-transparent-grey">
                <div className="d-border-bottom">
                    <div className="form-group px-0">
                        <div className="col-sm-9 pl-0 h-62">
                            <div className="ui small input col-sm-8 f-12 text-center align-self-center black ver-center">
                                {/* <Input type="text" /> */}
                                {/* Update Zaky */}
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="input-group input-daterange input-daterangestock h-35" style={{"z-index":0}}>
                                                    <span className="input-group-addon h-35 bg-tableheader">Periode</span>
                                                    <input placeholder="dd/mm/yyyy" id="startDateFirst" name="startDate1" type="text" className="form-control date-clear h-35" readOnly="readonly" />
                                                    <span className="input-group-addon h-35 bg-tableheader">
                                                                        <span className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                    <span className="input-group-addon h-35 bg-tableheader">to</span>
                                                    <input placeholder="dd/mm/yyyy" id="endDateFirst" name="endDate1" type="text" className="form-control date-clear h-35" readOnly="readonly" />
                                                    <span className="input-group-addon h-35 bg-tableheader">
                                                                        <span className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-md btn-block btn-default btn-dark btnDatePick">Export Pdf</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*<div className="d-border-top">*/}
                        {/*/!*<TradeConfirmPageAgGrid />*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }

}

class TradeConfirmPage extends React.PureComponent{
    constructor(props) {
        super(props);

    }
    render(){
        return(
            <div className="d-border-transparent-grey">
                <div className="d-border-bottom">
                    <div className="form-group px-0">
                        <div className="col-sm-9 pl-0 h-62">
                            <div className="ui small input col-sm-8 f-12 text-center align-self-center black ver-center">
                                {/* <Input type="text" /> */}
                                {/* Update Zaky */}
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="input-group input-daterange input-daterangestock h-35" style={{"z-index":0}}>
                                                    <span className="input-group-addon h-35 bg-tableheader">Periode</span>
                                                    <input placeholder="dd/mm/yyyy" id="startDateFirst" name="startDate1" type="text" className="form-control date-clear h-35" readOnly="readonly" />
                                                    <span className="input-group-addon h-35 bg-tableheader">
                                                                        <span className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                    <span className="input-group-addon h-35 bg-tableheader">to</span>
                                                    <input placeholder="dd/mm/yyyy" id="endDateFirst" name="endDate1" type="text" className="form-control date-clear h-35" readOnly="readonly" />
                                                    <span className="input-group-addon h-35 bg-tableheader">
                                                                        <span className="fa fa-calendar-alt"></span>
                                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="submit" className="btn btn-md btn-block btn-default btn-dark btnDatePick">Go</button>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-md btn-block btn-default btn-dark btnDatePick">Export Pdf</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-border-top">
                            <TradeConfirmPageAgGrid size={widthSize()}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class TradeConfirmPageAgGrid_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = { 
            columnDefs: [
                { field: "order", headerName: "Order Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?230:s=="s50"?200:150,  minWidth: 150,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table text-left f-12 d-border-aggrid-right";
                    },
                },
                { field: "settle", headerName: "Settlement Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?400:s=="s50"?360:s=="s67"?320:s=="s75"?310:s=="s80"?288:s=="s90"?240:220, minWidth: 220,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-left grid-table f-12 d-border-aggrid-right";
                    }
                },{ field: "stockBuy", headerName: "Stock Buy", sortable: true, resizable: true,
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?335:s=="s80"?290:s=="s90"?245:210, minWidth: 220,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-left grid-table f-12 d-border-aggrid-right";
                    }
                },{ field: "stockSell", headerName: "Stock Sell", sortable: true, resizable: true,
                    width: s=="s49"?450:s=="s50"?410:s=="s67"?360:s=="s75"?335:s=="s80"?290:s=="s90"?245:210, minWidth: 210,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " text-left grid-table f-12 d-border-aggrid-right";
                    }
                },{ field: "amountBuy", headerName: "Amount Buy", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?450:s=="s50"?400:s=="s67"?375:s=="s75"?325:s=="s80"?290:s=="s90"?270:240, minWidth:240,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                },{ field: "amountSell", headerName: "Amount Sell", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?450:s=="s50"?400:s=="s67"?370:s=="s75"?340:s=="s80"?290:s=="s90"?270:240,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " text-right grid-table f-12 d-border-aggrid-right";
                    }
                },

            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-392 ag-theme-balham-dark ag-bordered ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.TradeControl}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class VerifyPINPortofolio_Base extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            value: "",
            visible:false
        }
    }   

    onChange = value =>{
        this.setState({ value });
    };

    buttonClickPin = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right">
                {/* <i className="icofont icofont-close text-icofont-close text-border click-pointer"
                    onClick={this.closeClick}></i>*/}</div>, 
            size: 'mini',
            contentClass: PinModal,
            onClose: () => this.props.onClose()
        })
    }

    onClickSubmit = (e) => {
        if(this.state.value.length >= '6'){
            if(this.state.value === '123456') {
                $("#pinPortofolio").removeClass("d-block");
                $("#pinPortofolio").addClass("d-none");
                $("#detailPortofolio").addClass("d-block");
                $("#detailPortofolio").removeClass("d-none");

                $("#contentPinStockCash").removeClass("d-none");
                $("#contentPinStockCash").addClass("d-block");

                $("#verifyPinStockCash").removeClass("d-block");
                $("#verifyPinStockCash").addClass("d-none");

                $("#FundPin").addClass("d-none");
                $("#ContentFund").removeClass("d-none");
                $("#ContentFund").addClass("d-block");

                $("#pinTradePL").addClass("d-none");
                $("#detailTradePL").addClass("d-block");

            } else{
                var visible = true;
                this.setState({ visible });
            }
        }
    };

    onClickCloseAlert = (e) => {
        var visible = false;
        var value = "";
        this.setState({ visible });
        this.pin.clear();
        this.setState({ value });
    };

    forgotPIN = (e) =>{
        var frameAction = this.refs.frameAction;
        frameAction.showModal({
            headerClass: () => <div className="text-white text-center"><h1 className="text-center">DX TRADE</h1></div>,
            contentClass: ForgotPINPModal,
            onClose: (result) => console.log('Second modal result = ', result),
            size: "mini"
        });
    }

    componentDidMount(){
        // this.pin.clear();
        $(".pincode-input-text").on('keypress',function(e) {
            if(e.which == 13) {
                $("#pin-click").click();
            }
        });
    }

    render(){
        const {value} = this.state;
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <div className={`text-white f-12 ${(this.props.pos == "portofolio") ? 'p-pinportofolio' : 'p-pinlanding' }`} style={{ paddingTop: "60px"}}>
                    <div className="card-334 pt-5 mt-5">
                        <div className="text-center align-self-center align-middle mb-3">
                            <div className="d-border-bold img-round-icon">
                                <i className="icofont icofont-lock icofont-4x"></i>
                            </div>
                        </div>
                        <div className="form-group text-center pt-2 mb-3">
                            <label className="col-sm-12 px-5 py-1 col-form-label f-16 font-weight-bold mb-2">PLEASE INPUT PIN</label>
                            <label className="col-sm-12 px-5 py-1 col-form-label">
                                &nbsp;
                            </label>
                        </div>
                        <div className="text-center">
                            <button
                                onClick={() => this.buttonClickPin()}
                                id="pin-click" type="submit" className={'btn btn-danger form-control py-0 col-md-3'}>
                                INPUT PIN
                            </button>
                        </div>
                    </div>
                    <div className={this.state.visible ? "col-sm-12 text-center bg-danger fade-in" : "col-sm-12 text-center bg-danger fade-out"}>
                        <div className={/*{cssmode == 'night'? */"px-2 pt-2 text-right text-white" /*: "px-2 pt-2 text-right text-black"*/}><i className="click-pointer icofont icofont-close" onClick={this.onClickCloseAlert}></i></div>
                        <div className={/*cssmode == 'night'? */"px-2 py-4 text-white" /*: "px-2 py-4 text-black"*/}>
                            PIN is wrong!
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class ForgotPINPModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <div className="f-12">
                <AppFrameAction ref="frameAction" />
                <label className="col-sm-12 px-5 py-2 col-form-label text-gray-tradding">Forgot PIN</label>
                <div className="text-white">
                    <div className="form-group">
                        <label className="col-sm-12 px-5 py-2 col-form-label">Enter your email address and we'll
                            send link to reset your PIN
                        </label>
                    </div>
                    <div className="form-group mb-0">
                        <label className="col-sm-12 px-5 py-2 col-form-label">Email</label>
                        <div className="col-sm-12 px-5 py-0">
                            <input type="email" className="text-white input-login col-sm-12"/>
                        </div>
                    </div>

                    <div className="form-group py-3">
                        <div className="justify-content-center align-items-center d-flex py-0 px-5">
                            <button type="submit" className="btn btn-primary form-control py-0">
                                Submit
                            </button>
                        </div>
                    </div>

                    <div className="form-group text-center">
                        <label className="col-sm-12 px-5 py-2 col-form-label">
                            <span className="click-pointer btn btn-link text-primary" onClick={this.closeClick}> Back to Verify PIN</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

class PinModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'pinLanding'/>
            </>
        );
    }
}

//************************** Modal**************************
class BuyModal_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        // if(this.props.BuySellModalStatus){
        //     this.refs.frameAction.closeModal(100);
        //     this.props.handleModalStatus("false");
        // }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <ModalBuy/>
            </>
        );
    }
}

class SellModal_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        // if(this.props.BuySellModalStatus){
        //     this.refs.frameAction.closeModal(100);
        //     // this.props.handleModalStatus("false");
        // }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <ModalSell/>
            </>
        );
    }
}

class DetailCancelFT_Base extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        $(document).keypress(function(e) {
            if(e.which == 13) {
                $("#enter-ok").click();
            }
        });
    }
    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        let data = this.props.cancelFundtransfer
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div className="col-sm-12 text-white px-0 mx-0 f-12">
                    <div className="col-sm-12 mx-0 px-0">
                        <Table size="sm" responsive borderless className="text-white">
                            <thead className="d-border-table">
                            <tr>
                                <th
                                    className="text-center bg-brown text-white" colSpan="3">FUND TRANSFER DETAIL</th>
                            </tr>
                            </thead>
                            <tbody className="no-wrap d-border-table">
                            <tr>
                                <td>No</td>
                                <td>:</td>
                                <td>{data.no}</td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>:</td>
                                <td>{mathCall.setRupiah(data.amount)}</td>
                            </tr>
                            {/* <tr>
                                <td>Bank</td>
                                <td>:</td>
                                <td>{data.bank+" "+data.bankAcNo}</td>
                            </tr> */}
                            <tr>
                                <td>Request Date</td>
                                <td>:</td>
                                <td>{data.reqDate}</td>
                            </tr>

                            </tbody>
                            </Table>
                    </div>
                </div><br/>
                <div className="col-sm-12 text-center mx-0 px-0 f-12">Do you want to cancel the fund transfer ?</div><br/>
                <div className="col-sm-12 row mx-0 px-0">
                    <div className="col-sm-7 text-center">
                        <button className="btn btn-danger col-sm-12" id="enter-ok" 
                            onClick={() => this.props.handleOnClick('landingPage', 'cancelFundTransfer|cancelYes',)
                        }>Cancel Fundtransfer</button>
                    </div>
                    <div className="col-sm-5 text-center">
                        <button className="btn btn-trans col-sm-6" onClick={
                            () => {
                                var refs = this.refs;
                                refs.frameAction.closeModal(100)
                            }
                        }>Cancel</button>
                    </div>
                </div>
            </>
        );
    }
}

//************************** Context Connector **************************
// investmend board
const LandingPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        stateLanding: vars.stateLanding,
        portofolioInfo: vars.portofolioInfo,
        portofolios: vars.portofolios,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        changeStateLanding: (stateLanding) => actions.sendAction('changeStateLanding', {stateLanding}),
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['changeStateLanding', 'handleOnClick']
)(LandingPage_Base);
const FixedIncomeAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        fixedIncome: vars.fixedIncome,
    })
)(FixedIncomeAgGrid_Base)

const MutualFundAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        mutualFund: vars.mutualFund,
    })
)(MutualFundAgGrid_Base)

// 2. Stock & Cash Display
const StockCash = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        fundTransferT0T1: vars.fundTransferT0T1,
        stockCashInfo: vars.stockCashInfo,
        scSettlement: vars.scSettlement,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick']
)(StockCash_Base);
// table of Stock cash
const StockCashAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        stockCash: vars.stockCash,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ['handleOnClick']
)(StockCashAgGrid_Base);

// My Account Page : 3. Historical
const TradeListHistory = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        rangeTransactionHistory: vars.rangeTransactionHistory,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(TradeListHistory_Base);
// 3.1 TradeList History
const ModalHistorical = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        rangeTradelistHistory: vars.rangeTradelistHistory,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(ModalHistorical_Base);
// trade list history Table
const TradeListAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        tradeListHistory: vars.tradeListHistory,
    })
)(TradeListAgGrid_Base)
// 3.2 ORDER HISTORY
const ModalOrderHistory = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        rangeOrderlistHistory: vars.rangeOrderlistHistory,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(ModalOrderHistory_Base)
// Order history Table
const TransactionOrderHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        orderHistory: vars.orderHistory,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(TransactionOrderHistoryAgGrid_Base)
// Transaction History
const TransactionAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        transHistory: vars.transHistory,
    })
)(TransactionAgGrid_Base);
// Stock transaction history Table
const StockTransactionHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        stockTransactionHistory: vars.stockTransactionHistory,
    })
)(StockTransactionHistoryAgGrid_Base)
// Cash transaction history Table
const CashTransactionHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        cashTransactionHistory: vars.cashTransactionHistory,
    })
)(CashTransactionHistoryAgGrid_Base)

// My Account Page : 4. Fund Transfer
const FundTransfer = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        tabFundTransfer: vars.tabFundTransfer,
        userProfile: vars.userProfile,
        stocklistOptions: vars.stocklistOptions,
        fundStockCodeList: vars.fundStockCodeList,
        stockProfile: vars.stockProfile,
        // brokerList: vars.brokerList,
        custodyList: vars.custodyList,
        // amountFund: vars.amountFund,
        // idftCancel: vars.idftCancel,
        // rangeFundTransferList: vars.rangeFundTransferList,
        amountWithdrawable: vars.amountWithdrawable,
        statusAlertN: vars.statusAlertN,
        transferDate: vars.transferDate,
        fundTransferT0T1: vars.fundTransferT0T1,
        rtgsStatus: vars.rtgsStatus,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        handleStatusAlert3: (type, statusAlert, msg, data) => actions.sendAction('handleStatusAlert3', {type, statusAlert, msg, data}),
        onChangeTextHandler: (pageType, type, data) => actions.sendAction('onChangeTextHandler', {pageType, type, data}),
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ["onChangeTextHandler",'handleOnClick','handleStatusAlert3']
)(FundTransfer_Base);
// F/T List Table
const FundAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        fundTransferList: vars.fundTransferList,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick']
)(FundAgGrid_Base);
// 4.5 Stock Transfer List
const FundStockAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        fundStockList: vars.fundStockList,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick']
)(FundStockAgGrid_Base);

// Cancel 1 Table
const CancelGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        fundTransferCancel1: vars.fundTransferCancel1,
    })
)(CancelGrid_Base);
// Cancel 2 Table
const CancelGrid2 = ContextConnector(BIPSAppContext,
    (vars) => ({
        fundTransferCancel2: vars.fundTransferCancel2,
    })
)(CancelGrid2_Base);

/** WARRANT/RIGHT EXERCISE DISPLAY */
const WarrantRight = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        verifyPinStatus: vars.verifyPinStatus,
        userProfile: vars.userProfile,
        exerciseCode: vars.exerciseCode,
        exerciseState: vars.exerciseState,
        statusAlertN: vars.statusAlertN,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
        handleStatusAlert3: (type, statusAlert, msg, data) => actions.sendAction('handleStatusAlert3', {type, statusAlert, msg, data}),
    }),
    ['handleOnClick', 'handleStatusAlert3', 'setDataHandler']
)(WarrantRight_Base);

const ConfirmationExerciseRequest = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        tempExercise: vars.tempExercise,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ['handleOnClick']
)(ConfirmationExerciseRequest_Base);

const ExcersiceListAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        exerciseList: vars.exerciseList,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ['handleOnClick']
)(ExcersiceListAgGrid_Base);

// 5 Account Info
const InquryAccount = ContextConnector(BIPSAppContext,
    (vars) => ({
        ai: vars.ai,
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
    })
)(InquryAccount_Base);

// 5.5 TRADE PL AG-GRID
const TradePLPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        verifyPinStatus: vars.verifyPinStatus,
        pinPage: vars.pinPage,
        tradePlInfo: vars.tradePlInfo,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(TradePLPage_Base)

const TradePLAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        tradePl: vars.tradePl,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick']
)(TradePLAgGrid_Base)

// 6.1 Trade Control
const TradeConfirmPageAgGrid = ContextConnector(BIPSAppContext,
    (vars) => ({
        TradeControl: vars.TradeControl,
    })
)(TradeConfirmPageAgGrid_Base);

const VerifyPINPortofolio = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        handleOnClick: (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(VerifyPINPortofolio_Base)

const BuyModal = ContextConnector(BIPSAppContext,
    (vars) => ({
        networkState: vars.networkState,
    })
)(BuyModal_Base)

const SellModal = ContextConnector(BIPSAppContext,
    (vars) =>({
        networkState: vars.networkState,
    })
)(SellModal_Base)


const DetailCancelFT = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        networkState: vars.networkState,
        cancelFundtransfer: vars.cancelFundtransfer,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),        
    }),
    ['handleOnClick', ]
)(DetailCancelFT_Base)

//************************** Value Formatter For Ag-grid & Filter without blank For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}
// >>>>>>> Standart Filter for Aggrid <<<<<<<
function stringComparator(valueA, valueB){
    if(valueA !== null && valueB !== null){
        if(valueA.length < 2){
            return null;
        }else if(valueB.length < 2){
            return null;
        }else{
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    }
}
function integerComparator(valueA, valueB){
    if(valueA == ""){
        return null;
    }else if(valueB == ""){
        return null;
    }else{
        return valueA - valueB;
    }
}
function dateComparator(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return date1;
    }
    if (date1Number === null) {
        return date1;
    }
    if (date2Number === null) {
        return date2;
    }
    return date1Number - date2Number;
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

export { CustomFrameHeaderLanding, LandingPage,
    StockCash,
    TradeListHistory,
    FundTransfer,
    WarrantRight,
    InquryAccount,
    VerifyPINPortofolio,
    TradePLPage,
    tcAndSoa,
    PinModal,
};
export default Landing;
