import React, { memo } from 'react';
import {Dropdown, Input, Checkbox} from "semantic-ui-react";
import NumberFormat from 'react-number-format';
import Select from "react-select";
import { Progress } from 'semantic-ui-react';
import $ from 'jquery';

import { AppFrameAction } from '../appframe.js';
import { AppFrame } from "../appframe";
import { BIPSAppContext } from '../AppData.js';
import { ContextConnector } from '../appcontext.js';
import FillHeaderTab from "../tabheaderfill";
import { WSConnectionAction } from "./../appnetwork";
import VerifyPIN from "./verifyPin";

import {AgGridReact} from "ag-grid-react";

import { InputPin } from './app_pin';
import { 
    optList, TermConditionRegistration, DetailEarlyRedemption, 
    TermConditionSubscription, ModalHistoEsbn, EsbnPaymentMethod
} from './app_esbn'
import { ResizeResponsive, widthSize, dxInfo, fmsg, addRowEmpty, customStyles, 
    selectSelectionTab, dummy, timeCall, comparatorType, mathCall, strCall
} from "../utils";
import * as util from '../utils';

window.$ = window.jQuery = $;

/**
 * optList adalah objek yang berisi status & pernyataan2 yang digunakan di modul ESBN 
 * seperti status halaman, pernyataan subscription, mekanisme pembayaran [atm & internet_banking]
 * 
 */

const EsbnFrameHeader = memo((props) => {
    return (
        <>
        </>
    );
})

class Esbn extends React.PureComponent {

    render() {
        return (
            util.pageStatus.esbn ? 
                //hanya memanggil headernya saja
                <div className="bg-black-trading px-0 mx-0">
                </div>
            : 
                <div>
                    <div className="card-header header-pegadaian bg-grey">
                        <div className="row col-sm-12 px-0 mx-0 py-3">
                            <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                            <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                                <i className="f-18 ion ion-md-sync click-pointer"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-body align-self-center text-center f-16 py-5">
                        <div className="py-5">
                            <div className="py-5">
                                <i className="icofont icofont-warning-alt f-18"></i>
                                <p>E-SBN</p>
                                <p>Not yet available. <br/>{dxInfo.callCenter}</p>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

const CustomFrameHeaderEsbn_Base = memo((props) => {
    return (
        util.pageStatus.esbn ? 
            <div>
                <div className={props.checkStatusEsbn === 3 ? 'd-none' : 'd-block'}>
                    <div className="row col-sm-12 px-0 mx-0 align-self-center">
                        <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                        <FillHeaderTab tabMode="beforeregister" tabColumn="sm-3" treeName="/esbnPage"
                            linkTitles={
                                {
                                    EsbnPage: 'PENDAFTARAN SBSN',
                                }
                            } />
                        </div>
                    </div>
                </div>
                <div className={props.checkStatusEsbn === 3 ? 'd-block' : 'd-none'}>
                    <div className="row col-sm-12 px-0 mx-0 align-self-center">
                        <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                            <FillHeaderTab tabMode="afterregister" tabColumn="sm-6"  treeName="/esbnPage"
                                linkTitles={
                                {
                                    EsbnxPageTransaction: 'TRANSAKSI',
                                    EsbnxPagePortfolio: 'PORTOFOLIO',
                                    /*EsbnxPageHistorical: 'HISTORICAL ESBN',*/
                                }
                            } />
                        </div>
                    </div>
                </div>
                <AppFrame treeName="/esbnPage" headerComponent={EsbnFrameHeader} />
            </div>
        : ''
    );
})

class PINVerify extends React.PureComponent {

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <VerifyPIN tipe = 'esbnregister'/>
            </>
        );
    }
}

/** REGISTER */
class EsbnPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            alert: false,
            checkbox: false,
        }
    }

    onClickTermCondition = (val) => {
        this.setState(ps => ({...ps, checkbox: val}));
        val && this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center pb-3">
                <h3 className="text-center">Ketentuan dan Persyaratan Registrasi</h3>
            </div>,
            size: 'large',
            contentClass: TermConditionRegistration,
            contentProps: {onClose: () => this.refs.frameAction.closeModal(100) }
        })
    }

    buttonClickPIN = (e) => {
        let p = this.props;
        if(this.state.checkbox){
            p.setDataHandler({"onLoading": true})
            this.registerInvestor(p.userInfo);
        }else{
            p.setDataHandler({"statusAlertN": true, "msgAlert3": "Silahkan Periksa Daftar"});
            // this.setState(prevState => ({ ...prevState, alert: true }) )
        }
    }

    registerInvestor = ({ sid, custcode, custname, user_id }) => {
        this.refs.netAction.send(fmsg.sbn_registration(sid, custcode, custname, user_id));
    }

    render() {
        ResizeResponsive()
        let p = this.props, s = this.state;
        let { isLoadingStatusESBN=true } = this.props;
        return (<>
            <AppFrameAction ref="frameAction" />
            <WSConnectionAction ref="netAction" />
            {isLoadingStatusESBN === true ?
                <div className="m-5 p-5">
                    <div className="w-100 text-center">
                        <div className="ui big active centered inline loader mb-3"></div>
                        <p className="f-13 text-white">Mohon menunggu ...</p>
                    </div>
                    <Progress className="w-100 my-0" percent={100} active/>
                </div>
            : <>
                {s.alert && 
                    <div id="alert-declare" className={"col-sm-2 text-center fade-in f-12"} style={{ position : "absolute", zIndex : 1, background: "brown", bottom: "35px", left: "35px" }}>
                        <div className={/*{cssmode == 'night'? */"px-2 pt-2 text-right text-white" /*: "px-2 pt-2 text-right text-black"*/}>
                            <i className="click-pointer icofont icofont-close" onClick={()=>this.setState({ ...s, alert: false })}></i>
                        </div>
                        <div id="content-alertdeclare" className={"px-2 py-4 text-white fade-in"}>
                            Silahkan Periksa Daftar
                        </div>
                    </div>
                }
                <div>
                    {/*<div className="card-header header-pegadaian bg-grey">
                        <div className="row col-sm-12 px-0 mx-0 py-3">
                            <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                            <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                                <i className="f-18 ion ion-md-sync click-pointer"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-body align-self-center text-center f-16 py-5">
                        <div className="py-5">
                            <div className="py-5">
                                <i className="icofont icofont-warning-alt f-18"></i>
                                <p>E-SBN</p>
                                <p>Not yet available. <br/>{dxInfo.callCenter}</p>
                            </div>
                        </div>
                    </div>*/}

                    <div className="col-sm-12 px-4 pb-0 bg-grey bg-black-trading pt-0 d-border card-527">
                        <div className={this.props.checkStatusEsbn === 2 ?'d-block f-12 pt-3' : 'd-block f-12'}>
                            {
                                this.props.checkStatusEsbn === 2 &&
                                    <div className="w-100 bg-yellow-red-grad px-2 py-2 text-center box-warning-esbnreg">
                                        <p className="text-black mb-1 font-weight-bold">
                                            {!p.onLoading ? "Permintaan pendaftaran sedang dalam proses, Silahkan coba lagi nanti setelah proses selesai." : <>&nbsp;</>}
                                        </p>
                                        <p className="text-black font-weight-bold">
                                            {!p.onLoading ? "Untuk informasi lebih lanjut, Silahkan hubungi Pusat Dukungan kami 14009." : <>&nbsp;</>}
                                        </p>
                                    </div>
                            }
                            <div id="exTab" className="container-fluid mx-0" style={{ paddingTop : "10px" }}>
                                <div className="row mb-2">
                                    <div className={"col-sm-12 px-0"}>
                                        <ul className="nav nav-pills">
                                            <li>
                                                <a href="#1b" data-toggle="tab"  className="bg-black-trading">
                                                    DATA Nasabah
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content clearfix bg-black-trading">
                                            <div className="tab-pane active py-2" id="1b">
                                                <div className={"row"}>
                                                    <div className={"col-sm-6"}>
                                                        <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>
                                                            <tr>
                                                                <td className={"d-border"}>SID</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"} >{p.userInfo.sid || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Email</td>
                                                                <td width="50%" className={"d-border hover-tables"}>{p.userInfo.email || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Nama</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.custname || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>No ID</td>
                                                                <td width="50%" className={"d-border hover-tables"} >{p.userInfo.idcardno || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Tanggal Lahir</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.birthdate || ""}</td>

                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Tempat Lahir</td>
                                                                <td width="50%" className={"d-border hover-tables"}>{p.userInfo.birthplace || ""}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className={"col-sm-6"}>
                                                        <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>

                                                            <tr>
                                                                <td width="50%" className={"d-border"} >Pekerjaan</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.occupation || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Alamat</td>
                                                                <td width="50%" className={"d-border hover-tables"}>{p.userInfo.address || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>Kota</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.city || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>No HP</td>
                                                                <td width="50%" className={"d-border hover-tables"}>{p.userInfo.phonecell || ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={"d-border"}>No Telepon</td>
                                                                <td width="50%" className={"even-td d-border hover-tables"}>{p.userInfo.phone || ""}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mb-2">
                                    <div className={"col-sm-6 pl-0"}>
                                        <ul className="nav nav-pills">
                                            <li>
                                                <a href="#2b" data-toggle="tab" className="bg-black-trading">DATA Rekening</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content clearfix bg-black-trading">
                                            <div className="tab-pane active py-2" id="2b">
                                                <table width="100%" className={"table table-bordered table-responsive mb-0 card-92"}>
                                                    <tr>
                                                        <td className={"d-border"}>Nama Rekening</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"} >{p.userInfo.rdncbaccname || ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>No Rekening</td>
                                                        <td width="50%" className={"d-border hover-tables"}>{p.userInfo.rdncbaccno || ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Nama Bank</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"}>
                                                            {strCall.rdnBank(p.userInfo.rdnbncode)}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-sm-6 pr-0"}>
                                        <ul className="nav nav-pills">
                                            <li>
                                                <a href="#3b" data-toggle="tab" className="bg-black-trading">DATA AKUN SB</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content clearfix bg-black-trading">
                                            <div className="tab-pane active py-2" id="3b">
                                                <table width="100%" className={"table table-bordered table-responsive mb-0 card-92"}>
                                                    <tr>
                                                        <td className={"d-border"}>Nama Rekening SB</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"} >{p.userInfo.custname || ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>No Rekening SB</td>
                                                        <td width="50%" className={"d-border hover-tables"}>{p.userInfo.sre01 || ""}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className="col-sm-12 px-0">
                                        { p.checkStatusEsbn === 1 ? 
                                            <div className="tab-content clearfix bg-black-trading">
                                                <div className="tab-pane active py-2" id="4b">
                                                    <div className={"col-sm-12 mt-4 card-76"}>
                                                        <input className="magic-checkbox" type="checkbox" name="declare"
                                                            id="declare" value={s.checkbox} 
                                                            onChange={() => this.onClickTermCondition(!s.checkbox)}
                                                        />
                                                        <label htmlFor="declare"
                                                            className="text-white f-12-center">
                                                            Saya menyatakan telah:
                                                        </label>
                                                        <ol className={"mb-0"}>
                                                            <li className="text-white">Mengisi data dengan benar dan lengkap.</li>
                                                            <li className="text-white">Mengerti dan menyetujui <b><u>ketentuan dan persyaratan</u></b> penggunaan sistem layanan transaksi SBN Ritel secara elektronik.</li>
                                                        </ol>
                                                        <div className={"col-sm-12 text-right mb-0 px-3 h-40"}>
                                                            <button id="btn-sbmt" onClick={this.buttonClickPIN} className={"btn btn-primary"} disabled={p.onLoading}>
                                                                { p.checkStatusEsbn === 1 && p.onLoading ? 
                                                                    "Memuat..."
                                                                : 
                                                                    <i className={"fa fa-paper-plane"}>&nbsp;Selanjutnya</i>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div className="tab-content clearfix bg-black-trading row mx-0 card-64">
                                                <div className="tab-pane active text-right w-100 py-2" id="4b" style={{ "alignSelf" : "center" }}>
                                                    <button className={"btn btn-primary f-12"}
                                                        onClick={() => {
                                                            p.setDataHandler({"onLoading": true})
                                                            this.refs.netAction.send(fmsg.sbn_account(p.userInfo.sid))
                                                        }}
                                                    >
                                                        { p.checkStatusEsbn === 2 && p.onLoading ? "Memuat..." : "Muat Ulang" }
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>);
    }
}

/** TRANSACTION */
class TransactionEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state={
            activeTab: '1',
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    querySbnList = () => this.refs.netAction.send(fmsg.sbn_list);

    render() {
        const paddingParagraph = {
            paddingTop: '10px'
        }

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="netAction" />
                <div className="container-fluid px-1 f-12" >
                    <div className="col-sm-12 px-0" style={paddingParagraph}>
                        {
                            this.props.verifyPinStatus ? 
                                <TransactionSBN />
                            : 
                                <InputPin onClose={() => this.querySbnList()} lang='id' /> 
                                // <VerifyPINPortofolio onClose={() => this.querySbnList()} /> 
                        }
                    </div>
                </div>

            </>
        );
    }

}

const TransactionSBN_Base = (props) => {
    return (
        <div>
            {/*<BIPSAppProvider>*/}
            <WSConnectionAction />
            <div className="row col-sm-12 px-0 mx-0 align-self-center">
                <div className="col-sm-8 px-0 mx-0 d-border-bottom">
                    <FillHeaderTab esbnMode="afterregister" treeName="/esbnPage/EsbnxPageTransaction" linkTitles={
                        {
                            EsbnTransSubscribe: 'PEMESANAN',
                            EsbnTransHistorical: 'RIWAYAT TRANSAKSI',
                        }
                    } />
                </div>
            </div>
            <AppFrame treeName="/esbnPage/EsbnxPageTransaction" headerComponent={EsbnFrameHeader} />
        </div>
    );
}


/** TRANSACTION : SUBSCRIBE / PEMESANAN */
class SubscribeEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            toggleSubs : false,
            sbncodeSubs : ''
        };
    }

    refreshSBNList = () => this.refs.netAction.send(fmsg.sbn_list);

    reqSbnRedeemOffer = (data) =>  {
        let { earlyReedem, Id } = data;
        !(earlyReedem) && this.refs.netAction.send(fmsg.sbn_redeemOfferBySeriId(Id));
    }

    clickBackSbnSubs = () => {
        this.setState({
            toggleSubs : false,
            sbncodeSubs : ''
        });
    };

    subscribe = (data) => {
        this.refs.netAction.send(fmsg.sbn_showKuotaSeriBySid(data.Id, this.props.SID));
        this.setState({
            toggleSubs : true,
            sbncodeSubs : data
        });
    }

    render() {
        let p = this.props;
        let jTradable = {'Non Tradable': 'TIDAK', 'Tradable': 'YA'};
        return (
            <>
                <WSConnectionAction ref="netAction" />
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                        <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                            <i className="f-18 ion ion-md-sync click-pointer" onClick={this.refreshSBNList}></i>
                        </div>
                    </div>
                </div>
                <div className="card-406 align-self-center f-12 py-2 px-2 bg-trading-gray text-white" 
                    style={{ overflowY: "scroll", minHeight: window.innerHeight - 273 }}>
                    {this.state.toggleSubs ? 
                        <SBNSubscribeBuy clicksubsback={this.clickBackSbnSubs} sbnData={this.state.sbncodeSubs}/>
                    :
                        <div className="d-block panel-group" id="accordion">
                            {p.SBN_LIST ?
                                p.SBN_LIST.length > 0 ? 
                                    p.SBN_LIST.map((data, index) => (
                                        <div key={index} className="panel panel-default">
                                            <div className="panel-heading bg-black-trading text-white click-pointer"
                                                data-toggle="collapse" data-parent="#accordion" href={"#collapse"+index}
                                                onClick={() => this.reqSbnRedeemOffer(data)}
                                            >
                                                <h4 className="panel-title">
                                                    {data.Seri}
                                                </h4>
                                            </div>
                                            <div id={"collapse"+index} className={index === 0 ? "panel-collapse collapse in" : "panel-collapse collapse"}>
                                                <div className="panel-body bg-trading-gray ">
                                                    <div className={"row"}>
                                                        <div className={"col-sm-6"}>
                                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>
                                                                <tr>
                                                                    <td className={"d-border"}>Tingkat Kupon (%)</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"} >{data.TingkatKupon} %</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Periode Penawaran</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {timeCall.todatetime2(data.TglMulaiPemesanan) + " - " + timeCall.todatetime2(data.TglAkhirPemesanan)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Tanggal Jatuh Tempo</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>
                                                                        {timeCall.todate2(data.TglJatuhTempo)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Kelipatan Pemesanan (IDR)</td>
                                                                    <td width="50%" className={"d-border hover-tables"} >
                                                                        {mathCall.setRupiah(data.MinPemesanan)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Minimum Pemesanan (IDR)</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>
                                                                        {mathCall.setRupiah(data.MinPemesanan)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Maksimum Pemesanan (IDR)</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {mathCall.setRupiah(data.MaxPemesanan)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Tanggal Imbal Hasil</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>{data.TglBayarKupon}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Tanggal Setelmen</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {timeCall.todate2(data.TglSetelmen)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Pencairan Awal</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>{data.earlyReedem ? "YA" : "TIDAK"}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className={"col-sm-6"}>
                                                            <table width="100%" className={"table table-bordered table-responsive mb-0 card-169"}>
                                                                <tr>
                                                                    <td width="50%" className={"d-border"} colSpan={2}>PENCAIRAN AWAL</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Periode Pencairan Awal</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {data.earlyReedem ? 
                                                                            timeCall.todatetime2(data.earlyReedem.TglMulaiRedeem) + " - " + timeCall.todatetime2(data.earlyReedem.TglAkhirRedeem)
                                                                        : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Tanggal Pengiriman Dana</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>
                                                                        {data.earlyReedem ? timeCall.todate2(data.earlyReedem.TglSetelmen) : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Minimum Pencairan (IDR)</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {data.earlyReedem ? mathCall.setRupiah(data.earlyReedem.MinRedeem) : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Maksimum Pencairan (%)</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>
                                                                        {data.earlyReedem ? `${data.earlyReedem.MaxPcent}%` : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Tanggal Setelmen</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {data.earlyReedem ? timeCall.todate2(data.earlyReedem.TglSetelmen) : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Diperdagangkan</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>
                                                                        {jTradable[data.Tradability] || ''}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>Detail Memorandum Informasi</td>
                                                                    <td width="50%" className={"d-border hover-tables"}>
                                                                        {data.memos !== '' ? 
                                                                            <a href={data.LinkMemo} target="_blank"><i className="fa fa-link"></i> Memorandum Informasi</a> 
                                                                        : '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={"d-border"}>&nbsp;</td>
                                                                    <td width="50%" className={"even-td d-border hover-tables"}>&nbsp;</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className={"row py-3"}>
                                                        <div className="col-sm-12">
                                                            <button className="btn btn-primary w-100" onClick={() => this.subscribe(data) }>
                                                                <i className="fa fa-shopping-cart"></i> PESAN
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    )
                                : <h6 className="text-center">Tidak ada data</h6>
                            : 
                                <div className="m-5 p-5">
                                    <div className="w-100 text-center">
                                        <div className="ui big active centered inline loader mb-3"></div>
                                        <p className="f-13 text-white">Mohon menunggu ...</p>
                                    </div>
                                    <Progress className="w-100 my-0" percent={100} active/>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        );
    }
}

class SBNSubscribeBuy_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            isComplete1: false,
            inputamountsubs: 0,
            nextcheck2:false,
            pin: '',
            isRead: false,

            active1:true,
            active2:false,
            active3:false,
            activeTab:1,
            next2:false,
            next3:false,
            checkform:false,
            checkconfirmation:false,
            form: { message: "" }
        }
    };

    onClickCloseAlertDeclareSubs = (e) => {
        $("#alert-declaresubs").removeClass("fade-in");
        $("#alert-declaresubs").addClass("fade-out");
    };

    clickSubsAlert = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center">
                    <h3 className="text-center pt-1">Ketentuan dan persyaratan</h3>
                </div>,
            size: 'mini',
            contentClass: TermConditionSubscription,
            contentProps: {onClose: () => this.refs.frameAction.closeModal(100) }
        })
    }

    clickPinAlert = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center">
                    <h3 className="text-center pt-1">Informasi PIN</h3>
                </div>,
            size: 'mini',
            contentClass: ModalPINWrong,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    onClickPaymentMethod = (key, title) => {
        let { showModal, closeModal } = this.refs.frameAction;
        let size = (key === "teller") ? "mini" : "large";
        showModal({
            headerClass: () => <div className="text-white text-center">
                    <h3 className="text-center pt-1">{title}</h3>
                </div>,
            size,
            contentClass: EsbnPaymentMethod,
            contentProps: { method: key, closeModal },
        });
    }

    handleChangeInput = event => {
        let p = this.props;
        const { value, maxLength } = event.target;
        const message = value.slice(0, maxLength);

        this.setState({
            form: {
                message
            }
        });

        if($("#inputesbnpinsubs").val() === ""){
            this.setState({next3: false});
            $("#inputesbnpinsubs").addClass("border-error");
            $("#req_esbnpinsubs").removeClass("d-none");
            $("#req_esbnpinsubs").addClass("d-block");
        } else {
            if($("#inputesbnpinsubs").val() === "123456"){
                $("#inputesbnpinsubs").removeClass("border-error");
                $("#req_esbnpinsubs").removeClass("d-block");
                $("#req_esbnpinsubs").addClass("d-none");
                this.setState({next3: true});
                let params = {
                    "sid": p.userInfo.sid,
                    "seri_id": p.sbnData.Id, 
                    "fund_acc_id": p.sbnInfo.fund_acc.Id, 
                    "security_acc_id": p.sbnInfo.security_acc.Id, 
                    "amount": this.state.inputamountsubs
                }
                this.refs.netAction.send(fmsg.sbn_order(params));
            } else {
                $("#inputesbnpinsubs").removeClass("border-error");
                $("#req_esbnpinsubs").removeClass("d-block");
                $("#req_esbnpinsubs").addClass("d-none");
                this.setState({next3: false});
            }
        }
    };

    nextHandler = (tab) => {
        let p = this.props, s = this.state;
        if(tab === 1){
            this.setState({ ...s, active1: true});
            p.setDataHandler({"sbnInfo": {...p.sbnInfo, tab: tab}})
        }else if(tab === 2){
            if(Number(s.inputamountsubs) < Number(p.sbnData.MinPemesanan)){
                p.handleStatusAlert3('noConfirm', true, `Minimum pemesanan Rp. ${mathCall.setRupiah(p.sbnData.MinPemesanan)}`)
            }else if(Number(s.inputamountsubs) > p.sbnData.MaxPemesanan){
                p.handleStatusAlert3('noConfirm', true, "Maksimal pemesanan Rp. " +mathCall.setRupiah(p.sbnData.MaxPemesanan))
                // p.handleStatusAlert3('noConfirm', true, "Max amount subscribe Rp. " +mathCall.setRupiah(p.sbnData.MaxPemesanan))
            }else if(Number(s.inputamountsubs) > p.sbnInfo.KuotaSeri){
                p.handleStatusAlert3('noConfirm', true, "Maksimal Kuota pemesanan Nasional Rp. " +mathCall.setRupiah(p.sbnInfo.KuotaSeri));
            }else if(Number(s.inputamountsubs) > p.sbnInfo.KuotaInvestor){
                p.handleStatusAlert3('noConfirm', true, "Kuota pemesanan Rp. " +mathCall.setRupiah(p.sbnInfo.KuotaInvestor))
                // p.handleStatusAlert3('noConfirm', true, "Available amount subscribe Rp. " +mathCall.setRupiah(p.sbnInfo.KuotaInvestor))
            }else if(Number(s.inputamountsubs) % p.sbnData.KelipatanPemesanan !== 0){
                p.handleStatusAlert3('noConfirm', true, "Kelipatan pemesanan Rp. " +mathCall.setRupiah(p.sbnData.KelipatanPemesanan))
                // p.handleStatusAlert3('noConfirm', true, "multiples of subscribe Rp. " +mathCall.setRupiah(p.sbnData.KelipatanPemesanan))
            }else if(!s.nextcheck2){
                p.handleStatusAlert3('noConfirm', true, "Silahkan Periksa Daftar Pernyataan")
            }else{
                this.setState({ ...s, active2: true});
                p.setDataHandler({"sbnInfo": {...p.sbnInfo, tab: tab}})
            }
        }else if(tab === 3){
            if(p.sbnInfo.fund_acc === undefined || p.sbnInfo.security_acc.Id === undefined ){
                this.props.handleStatusAlert3('noConfirm', true, <>Anda tidak memiliki rekening SB. <br/>{dxInfo.infoLayanan}</>);
            }else if(s.pin === ""){
                this.props.handleStatusAlert3('noConfirm', true, 'Silahkan masukkan pin Anda');
            }else{
                p.setDataHandler({"onLoading": true});
                // p.setDataHandler({"isDataLoading": true});                
                let params = {
                    "sid": p.userInfo.sid,
                    "seri_id": p.sbnData.Id, 
                    "fund_acc_id": p.sbnInfo.fund_acc.Id, 
                    "security_acc_id": p.sbnInfo.security_acc.Id, 
                    "amount": this.state.inputamountsubs
                }
                p.setDataHandler({"sbnInfo": {...p.sbnInfo, params}});
                this.refs.netAction.send(fmsg.checkPin(s.pin));
            }
        }
    }

    tabActiveHandler = (id) => {
        this.setState({...this.state, activeTab: id});
        this.props.setDataHandler({"sbnInfo": {...this.props.sbnInfo, tab: id}})
    }

    handleCheckBox = () => {
        let { isRead, nextcheck2 } = this.state;
        if(isRead){
            this.setState(s => ({...s, nextcheck2: !nextcheck2}))
        } else {
            this.props.handleStatusAlert3('noConfirm', true, 'Anda harus membaca terlebih dahulu Memorandum Informasi!')
        }
    }

    clickFinish = () => {
        let p = this.props;
        $("#inputesbnsubs").val("");
        $('#declaresubs').prop('checked', false);
        this.setState({
            active1: true,
            active2:false,
            active3:false,
            activeTab: 1,
            next2:false,
            next3:false,
            form: { message: "" },
            checkform:false,
            checkconfirmation:false,
        });
        p.setDataHandler({"sbnInfo": {tab: 1, security_acc: p.sbnInfo.security_acc, fund_acc: p.sbnInfo.fund_acc}});
        p.clicksubsback()
    };

    render() {
        let p = this.props; let s = this.state;

        return (
            <>
                <WSConnectionAction ref="netAction" />
                <AppFrameAction ref="frameAction" />
                <div id="alert-declaresubs" className={"col-sm-2 text-center fade-out f-12"} style={{ position : "absolute", zIndex : 1, background: "brown", bottom: "35px", left: "35px" }}>
                    <div className={"px-2 pt-2 text-right text-white"}><i className="click-pointer icofont icofont-close" onClick={this.onClickCloseAlertDeclareSubs}></i></div>
                    <div id="content-alertdeclaresubs" className={"px-2 py-4 text-white"}></div>
                </div>
                <div className="text-white f-12 container-fluid">
                    <div className="col align-self-center py-5">
                        <div className="step-tab step-esbn">
                            <div id="crumbs" className="w-100">
                                <ul className="nav nav-pills w-100">
                                    <li className={`step1 col-sm-form-wizard ${p.sbnInfo.tab === 1 && "active "} ${p.sbnInfo.status ? "click-disabled" : "click-pointer"}`} 
                                        onClick={() => p.sbnInfo.tab !== 1 && !p.sbnInfo.status && this.nextHandler(1)} disabled={p.sbnInfo.status}>
                                        <a href={null} className={(s.active1 ? "active " : "") + (p.sbnInfo.status ? "click-disabled" : "click-pointer")} >Formulir</a>
                                    </li>
                                    <li className={`col-sm-form-wizard ${p.sbnInfo.status ? "click-disabled" : "click-pointer"} ${p.sbnInfo.tab === 2 && "active"}`} 
                                        onClick={() => (p.sbnInfo.tab !== 2 && !p.sbnInfo.status) && this.nextHandler(2)}>
                                        <a href={null} className={`${s.active2 && "active"} ${p.sbnInfo.status ? "click-disabled" : "click-pointer"}`} >Konfirmasi</a>
                                    </li>
                                    <li className={`step-last col-sm-form-wizard ${p.sbnInfo.status && "click-disabled"}`} disabled={p.sbnInfo.status}>
                                        <a href={null} className={p.sbnInfo.tab === 3 && "active"} >Selesai</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div></div>
                        <div className="tab-content clearfix" >
                            <div className="tab-pane active" id="subs1">
                                <div className="container content-step">
                                    <div className="row">
                                        <div id="codeesbnget1" className="col-sm-12 py-3 black-trading" style={{ borderRadius:'0 10px 0 0', color: 'white', backgroundColor: '#080808', marginBottom: '2px', border: '.5px #ffffff30 solid' }}>
                                            {p.sbnData.Seri}
                                        </div>
                                        {p.sbnInfo.tab < 3 ? <>
                                            <div className="col-sm-12 py-2 px-0">
                                                <table width="100%" className={"table table-bordered table-responsive mb-0 card-334"}>
                                                    <tr>
                                                        <td className={"d-border"}>SID</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"} >
                                                            {p.userInfo.sid || ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Nama Nasabah</td>
                                                        <td width="50%" className={"d-border hover-tables"}>
                                                            {p.userInfo.custname || ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>No Rekening Dana</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"} >
                                                            {p.userInfo.rdncbaccno || ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>No Rekening SB</td>
                                                        <td width="50%" className={"d-border hover-tables"}>
                                                            {p.sbnInfo.security_acc.NoRek || ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Kuota Nasional (IDR)</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"}>
                                                            {p.sbnInfo.KuotaSeri === undefined ? 
                                                                <div className="col-sm-6 px-2 mx-0" style={{"alignSelf":"center"}}>
                                                                    <i className="text-muted">memuat ...</i>
                                                                </div>
                                                            :  
                                                                mathCall.setRupiah(p.sbnInfo.KuotaSeri) 
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Maksimum Pemesanan (IDR)</td>
                                                        <td width="50%" className={"d-border hover-tables"}>
                                                            {mathCall.setRupiah(p.sbnData.MaxPemesanan)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Kuota Individu (IDR)</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"}>
                                                            {p.sbnInfo.KuotaInvestor === undefined ?
                                                                <div className="col-sm-6 px-2 mx-0" style={{"alignSelf":"center"}}>
                                                                    <i className="text-muted">memuat ...</i>
                                                                </div>
                                                            :
                                                                mathCall.setRupiah(p.sbnInfo.KuotaInvestor) 
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Minimum Pemesanan (IDR)</td>
                                                        <td width="50%" className={"d-border hover-tables"}>
                                                            {mathCall.setRupiah(p.sbnData.MinPemesanan)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={"d-border"}>Jumlah Nominal (IDR)*</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"}>
                                                            <div className="row mx-0 px-0 col-sm-12">
                                                                <div className="col-sm-6 px-0 mx-0">
                                                                    <NumberFormat
                                                                        value={s.inputamountsubs}
                                                                        placeholder='Jumlah Nominal (IDR)'
                                                                        className={`${p.sbnInfo.tab === 1 ? "form-control input-left bg-grey d-border" : ""} pr-1 text-right align-self-center `}
                                                                        decimalSeparator = "."
                                                                        displayType={p.sbnInfo.tab === 1 ? 'input' : 'text'}
                                                                        type="text"
                                                                        thousandSeparator={true}
                                                                        allowNegative={false}
                                                                        // isAllowed={({ value }) => value <= p.sbnData.MaxPemesanan ? true : false}
                                                                        onValueChange={({value}) => this.setState({...s, inputamountsubs: value }) }
                                                                        onFocus={e => e.target.select()}
                                                                    />
                                                                    <small className="pl-4 text-danger d-none" id="req_subsamount">harus diisi</small>
                                                                </div>
                                                                {p.sbnInfo.tab === 1 && 
                                                                    <div className="col-sm-6 px-2 mx-0" style={{"alignSelf":"center"}}>
                                                                        <i className="text-muted">Kelipatan &nbsp;<span>{mathCall.setRupiah(p.sbnData.MinPemesanan)}</span></i>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {p.sbnInfo.tab === 2 &&  
                                                        <tr>
                                                            <td className={"d-border"}>Masukan Pin Anda*</td>
                                                            <td width="50%" className={"d-border hover-tables"}>
                                                                <NumberFormat
                                                                    value={s.pin}
                                                                    className="form-control f-12 input-left bg-grey d-border col-sm-12 pl-4 pr-1 text-left align-self-center"
                                                                    type="password"
                                                                    placeholder='Masukan Pin Anda'
                                                                    onFocus={e => e.target.select()}
                                                                    disabled={p.onLoading}
                                                                    isAllowed={({ value }) => value.length <= 6 ? true : false}
                                                                    onValueChange={({value}) => this.setState({...s, pin: value })}
                                                                />
                                                                <small className="pl-4 text-danger d-none" id="req_esbnpinsubs">harus diisi</small>
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            </div>
                                            {p.sbnInfo.tab === 1 && 
                                                <div className="col-sm-12 py-3">
                                                    <Checkbox
                                                        label={'Saya menyatakan bahwa saya telah:'}
                                                        onChange={this.handleCheckBox}
                                                        key={'labelCb'}
                                                        className="text-white f-12 text-bold"
                                                        checked={s.nextcheck2}
                                                        disabled={s.checkform}
                                                    />
                                                    <label id="declaresubs" className="text-white f-12-center">
                                                        <ol style={{listStyleType: '"✔ "'}}>
                                                            <li>Telah membaca dan memahami isi&nbsp;
                                                                <a href={p.sbnData.LinkMemo}
                                                                    onClick={() => this.setState({...s, isRead: true}) } target="_blank">Memorandum Informasi
                                                                </a>.
                                                            </li>
                                                            {optList.declareSubscription.map((item, i) => 
                                                                <li key={'dcred'+i}>{item}</li>
                                                            )}
                                                        </ol>
                                                    </label>
                                                </div>
                                            }
                                        </> :
                                            <div className="col-sm-12 py-2 px-0">
                                                <table width="100%" className={"table table-bordered table-responsive mb-0"}>
                                                    <tr style={{height: 10}}>
                                                        <td className={"d-border"}>Total Pembayaran (IDR)</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"} >
                                                            {mathCall.setRupiah(s.inputamountsubs)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{height: 10}}>
                                                        <td className={"d-border"}>Kode Billing</td>
                                                        <td width="50%" className={"d-border hover-tables"}>
                                                            {p.sbnInfo.billCode}
                                                        </td>
                                                    </tr>
                                                    <tr style={{height: 10}}>
                                                        <td className={"d-border"}>Batas Waktu Pembayaran</td>
                                                        <td width="50%" className={"even-td d-border hover-tables"}>
                                                            {timeCall.todatetime2(p.sbnInfo.deadline)}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                    {p.sbnInfo.tab === 3 && 
                                        <div className={"row bg-gray-tradding py-3"}>
                                            <div className="col-sm-12 px-0">

                                                <div className="tab-content clearfix">
                                                    <div className="tab-pane active py-2" id="4b">
                                                        <div className={"col-sm-12 mt-4 card-132"}>
                                                            <div className={"text-center col-sm-12 bg-danger py-5"}>
                                                                <i className="text-white">Jika anda melebihi batas waktu pembayaran maka pemesanan akan otomatis dibatalkan.</i>
                                                            </div>
                                                            <div className={"text-center row col-sm-12 py-3"}>
                                                                <div className={"col-sm-12 text-left py-2"}>Instruksi Pembayaran :</div>
                                                                { [
                                                                    { key: "atm", title: "ATM Banking" },
                                                                    { key: "internet_banking", title: "Internet Banking" },
                                                                    { key: "mobile_banking", title: "Mobile Banking" },
                                                                    { key: "teller", title: "Teller" },
                                                                ].map(({ key, title }) => (
                                                                    <div key={"payListEsbn"+key} className={"col-sm-3 py-2 bg-grey-mystic"}>
                                                                        <a href="#subs3" data-toggle="tab" style={{fontWeight:'bold'}}
                                                                            onClick={() => this.onClickPaymentMethod(key, title)}
                                                                        >
                                                                        <i className="fa fa-book"></i> {title}</a>
                                                                    </div>
                                                                )) }
                                                            </div>
                                                            <div className={"col-sm-12 text-right mb-0 px-3 h-40 py-5"}>
                                                                <a href={"#subs1"} data-toggle="tab" onClick={this.clickFinish} className={"btn btn-primary pull-right"}>Selesai</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {p.sbnInfo.tab !== 3 &&
                                    <div className="container next-btn">
                                        <button className="btn btn-primary pull-right" disabled={p.onLoading}
                                            onClick={() =>  this.nextHandler(p.sbnInfo.tab + 1)}>
                                            {p.onLoading ? "Memuat..." : "Selanjutnya"}
                                        </button>
                                        <a href={"#subs1"} data-toggle="tab" onClick={this.clickFinish} className={"btn btn-success pull-right mx-2"}>
                                            Kembali
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

/** TRANSACTION : HISTORICAL ORDER / RIWAYAT TRANSAKSI */
class HistoricalOrderEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            Seri: "all",
            Status: "all",
            nDate: {},
        }
    }

    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartHSbn').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateSBN").click(); 
            });
            $('#datepickerendHSbn').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateSBN").click(); 
            });
        });
    }

    onFilter = (data) => {
        this.props.setDataHandler({'filterHisOrderSBN': data});
        let { start_date, end_date } = this.state.nDate;
        this.refs.netAction.send(fmsg.sbn_getAllOrderBySid( this.props.SID, start_date, end_date ));
    }

    handleChangeDate = () => {
        let start_date = document.getElementById('datepickerstartHSbn').value
        let end_date =  document.getElementById('datepickerendHSbn').value
        if(start_date != "" && end_date == ""){
            document.getElementById('datepickerendHSbn').value = timeCall.getdate();
            end_date =  timeCall.getdate()
            $("#trxHistoryEnd").datepicker("setDate", end_date);
        }
        this.setState({ ...this.state, nDate: {start_date, end_date} });
    }

    render() {
        let p = this.props, s = this.state;
        //Add your search logic here.
        const customFilterSbn  = (option, searchText) => {
            var sbncode = option.data.text.toLowerCase().includes(searchText.toLowerCase());
            if (sbncode) {
                return true;
            }
        };

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="netAction" />
                <input type="hidden" onClick={this.handleChangeDate} id={"handleDateSBN"} />
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        {/*<div className="col-sm-12 card-113">*/}
                        <div className="col-sm-12 h-84">
                            <div className="row">
                                <div className="col-sm-6 row">
                                    <div className="col-sm-3 align-self-center">SBN No. </div>
                                    <Dropdown
                                        placeholder='Choose'
                                        search selection
                                        options={p.SBN_ID}
                                        defaultValue={"all"}
                                        className="col-sm-9 f-12"
                                        onChange={(e, data) => {
                                            this.setState({...s, Seri: data.value})
                                            this.onFilter({...s,  Seri: data.value});
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6 row">
                                    <div className="col-sm-3 align-self-center">Status </div>
                                    <Dropdown
                                        placeholder='Choose'
                                        search selection
                                        options={optList.statusTrxESBN}
                                        defaultValue={"all"}
                                        className="col-sm-9 f-12"
                                        onChange={(e, data) => {
                                            this.setState({...s, Status: data.value})
                                            this.onFilter({...s, Status: data.value})
                                        } }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="row col-sm-9 ui small input f-12 text-center align-self-center black ver-center px-5">
                                    <table>
                                        <tr>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Dari</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' id="datepickerstartHSbn" className="col-sm-12 pl-0 pr-0 text-center align-self-center f-12"/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                <span
                                                    className="fa fa-calendar-alt"></span>
                                            </span>
                                                </div>
                                            </td>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Ke</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                    <Input placeholder='dd/mm/yy' id="datepickerendHSbn" className="col-sm-12 pl-0 pr-0 text-center align-self-center f-12"/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                <span
                                                    className="fa fa-calendar-alt"></span>
                                            </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-sm-3 align-self-center px-5">
                                    <button className="btn btn-primary w-100" onClick={() => this.onFilter(s)}>
                                        Cari
                                    </button>
                                </div>
                            </div>
                            {/* <div className="row px-5 j-content-right">
                                <div className="col-sm-4 align-self-center px-5 text-right">
                                    <Select
                                        getOptionLabel={(option) => `${option.idSeri} - ${option.text}`}
                                        filterOption={customFilterSbn}
                                        isSearchable={true}
                                        maxMenuHeight={155}
                                        styles={customStyles}
                                        placeholder={<div>Cari..</div>}
                                        options={p.SBN_CODE}
                                        className="stockPageSelect text-left"
                                        theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                        onChange={(e) => this.onfilter("Seri", e.value)}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16">
                    <TableHistoricalEsbn 
                        size={widthSize()} 
                        gridView="tab" 
                        classView="f-12"
                    />
                </div>
            </>
        );
    }
}

class TableHistoricalEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { field: "TglPemesanan", headerName: "Waktu Pemesanan", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, sort: 'desc',
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?200:190, minWidth: 190, 
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                },
                { field: "Seri", headerName: "Kode SBN", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?380:s=="s50"?350:s=="s67"?305:s=="s75"?280:s=="s80"?230:s=="s85"?210:s=="s90"?200:190, minWidth: 190, 
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                    suppressSizeToFit: true
                },
                { field: "KodeBilling", headerName: "Kode Billing", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?370:s=="s50"?320:s=="s67"?280:s=="s75"?270:s=="s80"?230:s=="s85"?210:s=="s90"?200:190, minWidth: 190, 
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                    suppressSizeToFit: true
                },
                { field: "Nominal", headerName: "Nominal (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?350:s=="s50"?310:s=="s67"?270:s=="s75"?260:s=="s80"?230:s=="s85"?210:s=="s90"?200:180, minWidth: 180, 
                    comparator: comparatorType['integer'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: () => " grid-table text-right f-12 d-border-aggrid-right"
                },
                { field: "NTPN", headerName: "NTPN", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType['text'],
                    width: s=="s49"?350:s=="s50"?314:s=="s67"?270:s=="s75"?260:s=="s80"?230:s=="s85"?210:s=="s90"?190:180, minWidth: 180,
                    cellClass: () => " grid-table text-right f-12 d-border-aggrid-right"
                },
                { field: "Status", headerName: "Status", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?340:s=="s50"?300:s=="s67"?270:s=="s75"?250:s=="s80"?230:s=="s85"?210:s=="s90"?190:180, minWidth: 180, 
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                },
                { field: "detail", headerName: "Detail", width: 140, minWidth: 140,
                    pinned: "right", lockPosition: true, lockVisible: true,
                    cellClass: () => " grid-table text-center locked-col locked-visible d-border-aggrid-right",
                    cellRenderer : 'detailRenderer',
                    suppressSizeToFit: true 
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                detailRenderer: (p) => p.data.TglPemesanan ? <span class="px-1" onClick={()=>this.onDetail(p.data)}>
                        <i class="btn-cellredemption fa fa-search click-pointer"></i>
                    </span> : "",
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }
    
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onDetail = (data) => {
        this.props.setDataHandler({'SBN_HISTORY_ORDER': []});
        this.refs.netAction.send(fmsg.sbn_historyOrderByOrderCode(data.KodePemesanan));
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div style={{ borderBottom:"1px solid white" }}>
                    <div className="row py-2">
                        <div className="col-sm-6">
                            <h5 id="sbncodetitlehist" className="font-weight-bold">{data.Seri}</h5>
                        </div>
                        <div className="col-sm-6 text-right">
                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={()=>this.refs.frameAction.closeModal(100)}></i>
                        </div>
                    </div>
                </div>,
            size: 'large',
            contentProps: data,
            contentClass: ModalHistoEsbn,
            onClose: (result) => this.props.setDataHandler({'SBN_HISTORY_ORDER': []})
        })
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction"/>
                <WSConnectionAction ref="netAction" />
                <div
                    className={"card-344 ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{
                        width: "100%", minHeight: window.innerHeight - 350
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.SBN_HISTORY}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight={29.5}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        isExternalFilterPresent={this.isExternalFilterPresent}
                        doesExternalFilterPass={this.doesExternalFilterPass}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class HistoricalEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="card-header header-pegadaian bg-grey">
                    <div className="row col-sm-12 px-0 mx-0 py-3">
                        <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                        <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                            <i className="f-18 ion ion-md-sync click-pointer"></i>
                        </div>
                    </div>
                </div>
                <div className="card-body align-self-center text-center f-16 py-5">
                    <div className="py-5">
                        <div className="py-5">
                            <i className="icofont icofont-warning-alt f-18"></i>
                            <p>e-SBN - Histori Pemesanan Esbn</p>
                            <p>Belum Tersedia. <br/>{dxInfo.infoLayanan}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

/** PORTFOLIO */
class PortfolioEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    queryPortfolio = (data) => {
        data ? this.props.setDataHandler({'filterPortfoioSBN': data}) : this.refs.netAction.send(fmsg.sbn_list);
        this.refs.netAction.send(fmsg.sbn_consolidatePortfolio(this.props.SID));
    }

    render() {
        let p = this.props;

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var sbncode = option.data.text.toLowerCase().includes(searchText.toLowerCase());
            var coupondate = option.data.coupondate.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(coupondate){
                    return true;
                }
            } else {
                if (sbncode) {
                    return true;
                }
            }
        };

        return (
            <>
                <AppFrameAction ref="frameAction"/>
                <WSConnectionAction ref="netAction" />
                {
                    this.props.verifyPinStatus ? <>
                        <div className="card-header header-pegadaian bg-grey py-2 f-12">
                            <div className="row col-sm-12 px-0 mx-0 py-1">
                                <div className="col-sm-10 px-0 mx-0 f-14 align-self-center"></div>
                                <div className="col-sm-2 text-right font-weight-bold px-0 mx-0">
                                    <Select
                                        getOptionLabel={(option) => `${option.text}`}
                                        filterOption={customFilter}
                                        isSearchable={true}
                                        maxMenuHeight={155}
                                        styles={customStyles}
                                        placeholder={<div>Cari..</div>}
                                        options={p.SBN_CODE}
                                        className="stockPageSelect text-left"
                                        theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                        onChange={(e) => this.queryPortfolio(e.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body align-self-center text-center f-16">
                            {/*<i className="icofont icofont-warning-alt f-18"></i>*/}
                            <TablePorfolioEsbn 
                                clicksbncode={this.buttonClickSbnCode} 
                                clicksbnredeem={this.buttonClickSbnRedeem} 
                                size={widthSize()} 
                                gridView="tab" 
                                classView="f-12"
                            />
                        </div>
                    </> : 
                    <div className="container-fluid px-1 f-12" >
                        <div className="col-sm-12 px-0" style={{paddingTop: '10px'}}>
                            <InputPin onClose={() => this.queryPortfolio()} lang='id' /> 
                            {/* <VerifyPINPortofolio onClose={()=>this.queryPortfolio()} /> */}
                        </div>
                    </div>
                }
            </>
        );
    }

}

class TablePorfolioEsbn_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "no", headerName: "#", sortable: false, filter: "agNumberColumnFilter",
                    width: 50, minWidth: 50, 
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                    cellRenderer: 'noRenderer'
                },
                { field: "seri", headerName: "Seri SBN", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?320:s=="s50"?305:s=="s67"?290:s=="s75"?275:s=="s80"?260:s=="s85"?245:s=="s90"?230:215, minWidth: 200,
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right",
                    suppressSizeToFit: true
                },
                { field: "nominal", headerName: "Nominal (IDR)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?320:s=="s50"?305:s=="s67"?290:s=="s75"?275:s=="s80"?260:s=="s85"?245:s=="s90"?230:215, minWidth: 200,
                    comparator: comparatorType['integer'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    cellClass: () => " grid-table text-right f-12 d-border-aggrid-right"
                },
                { field: "tingkat_kupon", headerName: "Tingkat Kupon (%)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?220:s=="s50"?205:s=="s67"?190:s=="s75"?175:s=="s80"?160:s=="s85"?145:s=="s90"?130:115, minWidth: 110,
                    comparator: comparatorType['text'],
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 3, '0.000 %', ' %'),
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right"
                },
                { field: "tanggal_bayar_kupon", headerName: "Tanggal Pembayaran Kupon", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?320:s=="s50"?305:s=="s67"?290:s=="s75"?275:s=="s80"?260:s=="s85"?245:s=="s90"?230:215, minWidth: 200,
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-right f-12 d-border-aggrid-right"
                },
                { field: "tanggal_jatuh_tempo", headerName: "Tanggal Jatuh Tempo", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?703:s=="s50"?567:s=="s67"?433:s=="s75"?411:s=="s80"?304:s=="s85"?267:s=="s90"?257:257, minWidth: 257,
                    valueFormatter: ({ value}) => value && timeCall.todate2(value, " "),
                    comparator: comparatorType['text'],
                    cellClass: () => " grid-table text-center f-12 d-border-aggrid-right"
                },
                { field: "modals_kode", headerName: "Detail (Pencairan Awal)",
                    width: s=="s49"?420:s=="s50"?380:s=="s67"?340:s=="s75"?305:s=="s80"?250:s=="s85"?215:s=="s90"?200:190,
                    pinned: "right", lockPosition: true, lockVisible: true, sortable: false,
                    cellClass: () => " grid-table text-center locked-col locked-visible d-border-aggrid-right",
                    cellRenderer: 'reedemRenderer',
                    suppressSizeToFit: true 
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({rowIndex, data}) => data.early_redemption_button || data.modals_kode ? rowIndex + 1 : "",
                reedemRenderer: ({ data }) => data.modals_kode ?
                        data.is_early_redemption === "yes" ?
                            <span class="px-1" onClick={() => this.onDetail(data)}>
                                <i class="btn-cellredemption fa fa-search click-pointer"></i>
                            </span> 
                        : "-"
                    : "",
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    onDetail = (data) => {
        this.props.setDataHandler({'SBN_SHOW_PORTFOLIO': addRowEmpty("card-129")})
        this.refs.netAction.send(fmsg.sbn_showPortfolio(this.props.SID, data.id_seri));
        this.refs.netAction.send(fmsg.sbn_redeemOfferBySeriId(data.id_seri));
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div style={{ borderBottom:"1px solid white" }}>
                    <div className="row py-2">
                        <div className="col-sm-6">
                            <h5 id="sbncodetitle" className="font-weight-bold">{data.seri}</h5>
                        </div>
                        <div className="col-sm-6 text-right">
                            <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={this.closeClick}></i>
                        </div>
                    </div>
                </div>,
            size: 'large',
            contentClass: DetailEarlyRedemption,
            contentProps: data
        })
    }

    render() {
        ResizeResponsive()
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <AppFrameAction ref="frameAction"/>
                <WSConnectionAction ref="netAction" />
                <div
                    className={"card-472 ag-theme-balham-dark ag-striped-odd"}
                    id="myGrid"
                    style={{ width: "100%" }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.SBN_CONSOLIDATE_PORTFOLIO}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight={29.5}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

/** MODAL */

class ModalPINWrong extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white f-12 text-center bg-dark-grey">
                    <div className="form-group">
                        <p className="col-sm-12 px-5 py-2 col-form-label">
                            Pin Anda Salah.
                        </p>
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-primary btn-sm col-sm-3" onClick={this.closeClick}>OK</button>
                </div>
            </>
        );
    }
}

const EsbnPage = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        checkStatusEsbn: vars.checkStatusEsbn,
        userInfo: vars.userInfo,
        onLoading: vars.onLoading,
        isLoadingStatusESBN: vars.isLoadingStatusESBN,
        headerTabEsbn: (tab) => actions.sendAction('headerTabEsbn', {tab}),
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),["headerTabEsbn", "setDataHandler"]
)(EsbnPage_Base);

const PortfolioEsbn = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        thememode: vars.thememode,
        SBN_CODE: vars.SBN_CODE,
        verifyPinStatus: vars.verifyPinStatus,
        SID: vars.SID,
        filterPortfoioSBN: vars.filterPortfoioSBN,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }), ["setDataHandler"]
)(PortfolioEsbn_Base);

const TablePorfolioEsbn = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        SID: vars.SID,
        SBN_CONSOLIDATE_PORTFOLIO: vars.SBN_CONSOLIDATE_PORTFOLIO,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }), ["setDataHandler"]
)(TablePorfolioEsbn_Base);

const TransactionEsbn = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        verifyPinStatus: vars.verifyPinStatus
    }),
)(TransactionEsbn_Base);

const HistoricalEsbn = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({

    }),
)(HistoricalEsbn_Base);

const TableHistoricalEsbn = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        SBN_HISTORY: vars.SBN_HISTORY,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }), ["setDataHandler"]
)(TableHistoricalEsbn_Base);

const CustomFrameHeaderEsbn = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        checkStatusEsbn : vars.checkStatusEsbn,
    }), []
)(CustomFrameHeaderEsbn_Base);

const SubscribeEsbn = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        SBN_LIST: vars.SBN_LIST,
        SID: vars.SID,
    })
)(SubscribeEsbn_Base)

const TransactionSBN = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        checkStatusEsbn : vars.checkStatusEsbn,
    }), []
)(TransactionSBN_Base);

const SBNSubscribeBuy = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        userInfo: vars.userInfo,
        sbnInfo: vars.sbnInfo,
        onLoading: vars.onLoading,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        handleStatusAlert3:(type, statusAlert, msg, data) => actions.sendAction('handleStatusAlert3', {type, statusAlert, msg, data}),
    }), ["setDataHandler", 'handleStatusAlert3']
)(SBNSubscribeBuy_Base);

const HistoricalOrderEsbn = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        thememode: vars.thememode,
        SBN_HISTORY: vars.SBN_HISTORY,
        SBN_CODE: vars.SBN_CODE,
        SBN_ID: vars.SBN_ID,
        SID: vars.SID,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),["setDataHandler"]
)(HistoricalOrderEsbn_Base);

export default EsbnPage;
export {CustomFrameHeaderEsbn, HistoricalEsbn, PortfolioEsbn, TransactionEsbn, Esbn, SubscribeEsbn, HistoricalOrderEsbn};
