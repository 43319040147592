import React from 'react';
import { AppFrameAction } from '../appframe.js';

import {ContextConnector} from "../appcontext";
import { BIPSAppContext, ConvertToRupiah, ColorLabel } from "../AppData";

import {AppFrame, AppFrameProvider, AppModal} from "../appframe";
import {WSConnectionAction} from "../appnetwork";
import FillHeaderTab from "../tabheaderfill";

import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { widthSize, GetTimeForDateTicker, comparatorType, colorText, mathCall, selectSelectionTab } from "../utils";

import './chatSupport.css';

import {Dropdown, Input, Popup} from 'semantic-ui-react'
import $ from 'jquery';
import {AgGridReact} from "ag-grid-react";
import ModalBuy from "../app_modals/modal_buy";
import Select from "react-select";
import {Table as TableBS} from "react-bootstrap";

window.$ = window.jQuery = $;
require('../../node_modules/bootstrap/dist/js/bootstrap.js');

class CustomFrameHeaderBrokerPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {/*<BIPSAppProvider>*/}
                <WSConnectionAction />
                <div className="col-sm-12 px-0 mx-0 align-self-center row">
                    <div className="col-sm-12 pb-0 px-0 mx-0 d-border-bottom">
                        <FillHeaderTab treeName="/brokerPage" linkTitles={
                            {
                                BrokerInfo: 'Broker Info',
                                BrokerTradeSummary: 'Broker Trade Summary',
                                BrokerTradeHistory: 'Broker Trade History',
                                BrokerTopListPage: 'TOP BROKER',
                            }
                        }/>
                    </div>
                </div>
                <AppFrame treeName="/brokerPage" headerComponent={BrokerPageFrameHeader}/>
                {/*<AppModal/>*/}
                {/*</BIPSAppProvider>*/}
            </div>
        );
    }
}

//============= 1. Broker Info Display =============
class BrokerInfo_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
        }
    }

    render () {
        let p = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    {/*outer*/}
                    {/*<div className="container-fluid f-12 card-520">*/}
                    <div className="container-fluid px-0 f-12">
                        {/*card 520*/}
                        <div className="py-2 px-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-4 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-4 px-0 mx-0">Broker Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-8 text-left align-self-center">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={p.brokerList} 
                                            onChange={(e)=>this.props.handleSearchCode('brokerInfo', e.value)}
                                            className="stockPageSelect" 
                                            theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                            value={p.codeBrokerInfo}
                                            />
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-12 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {p.codeBrokerInfo.saham === undefined ? "" : p.codeBrokerInfo.saham}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 pl-1 card-472 mt-2 bg-dark-grey pr-0">
                            <div className="col-sm-12 px-0 mx-0 text-center row ">
                                <div className="col-sm-12 px-2 pt-3 mx-0 text-left f-15 bg-currencies">
                                    PROFILE BROKER
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            onClick={() => this.props.handleOnClick("refreshPage", "brokerInfo", "none")}
                                            className={`btn btn-primary pull-right col-sm-1`}
                                            style={{"font-size": "14px", "width": "38px", "margin-top": "-8px"}}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    }/>
                                </div>
                            </div>
                            <div className="container-fluid mx-0" style={{ paddingTop : "10px" }}>
                                <div className="row">
                                    <div className={"col-sm-12 pl-0"}>
                                        <table width="100%" className={"table table-bordered table-responsive mb-0 ml-2 card-brokerInfo"}>
                                            {
                                                p.brokerInfoDetail.map((d, id) => {
                                                    return(<tr>
                                                        <td className={"d-border"}>{d.label}</td>
                                                        <td width="70%" className={`d-border hover-tables ${(id%2 === 0 ? "even-td" : "")}`} >
                                                            {d.label.includes("Modal D") ? ConvertToRupiah(d.value) : d.value}
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

//============= 2. Broker Trade Summary Display =============
class BrokerTradeSummary_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
            currStock: 'TLKM',
        }
    }

    render () {
        let p = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    {/*outer*/}
                    {/*<div className="container-fluid f-12 card-520">*/}
                    <div className="container-fluid px-0 f-12">
                        {/*card 520*/}
                        <div className="py-2 px-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-4 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-4 px-0 mx-0">Broker Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-8 text-left align-self-center">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={p.brokerList}
                                            onChange={(e)=>this.props.handleSearchCode('brokerTradeSummary', e.value)}
                                            className="stockPageSelect" 
                                            theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                            value={p.codeBrokerInfo}/>
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-3 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-12 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {p.codeBrokerInfo.saham === undefined ? "" : p.codeBrokerInfo.saham}
                                    </label>
                                </div>                                
                                <div class="col-sm-5 pl-0 pr-0 mx-0">
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            onClick={()=>p.handleOnClick("refreshPage", "brokerTradeSummary" ,"none")}
                                            className={`btn btn-primary pull-right col-sm-1 mt-1`}
                                            style={{"font-size": "14px", "width": "38px"}}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    }/>
                                    <table className="table bg-black-trading mb-0 h-35 table-sm table-borderless" 
                                        style={{width : "90%"}}>
                                        <thead></thead>
                                        <tbody className="d-border-top d-border-bottom">
                                            <tr>
                                                <td>T. Buy Val. :<span className={ColorLabel(p.brokerTradeSumInfo.tBuyVal)}> {p.brokerTradeSumInfo && ConvertToRupiah(p.brokerTradeSumInfo.tBuyVal)}</span></td>
                                                <td>T. Sell Val. :<span className={ColorLabel(p.brokerTradeSumInfo.tSellVal)}> {p.brokerTradeSumInfo && ConvertToRupiah(p.brokerTradeSumInfo.tSellVal)}</span></td>
                                                <td>Total Value :<span className={ColorLabel(p.brokerTradeSumInfo.tVal)}> {p.brokerTradeSumInfo && ConvertToRupiah(p.brokerTradeSumInfo.tVal)}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="px-1 mx-0 col-sm-12 row">
                            <div className="col-md-12 pr-1 pl-0">
                                <BrokerTradeSummaryAgGrid size={widthSize()}/>
                            </div>

                            <div className="col-md-4 pr-1 pl-0 py-2 d-none">
                                <div className="col-sm-12 px-0 mx-0 text-center row ">
                                    <div className="col-sm-12 px-2 pt-3 mx-0 text-center f-15 bg-currencies">
                                        TRADE SUMMARY "{ this.state.currStock }"
                                        <Popup content='Refresh' position='top center' trigger={
                                            <button
                                                className={`btn btn-primary pull-right col-sm-1`}
                                                style={{"font-size": "14px", "width": "38px", "margin-top": "-8px"}}>
                                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                            </button>
                                        }/>
                                    </div>
                                    <div className="col-sm-12 px-0 mx-0 bg-trading-gray " style={{marginBottom : "10px"}}>
                                        <BrokerTradeSummary2AgGrid size={widthSize()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
class BrokerTradeSummaryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "stock", headerName: "Stock", resizable: true,
                    sortable: true, filter: "agNumberColumnFilter", comparator: stringComparator,
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?215:s=="s75"?190:s=="s80"?180:s=="s85"?160:s=="s90"?150:150, minWidth: 130,
                    lockVisible:true, lockPosition:true,
                    // checkboxSelection: (params) => params.data.stock === "\xa0" ? false : true,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12 text-white  locked-col locked-visible";
                    },
                },
                { field: "buyVol", headerName: "Buy Vol(Shr)", filter: "agNumberColumnFilter", resizable: true,
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?260:s=="s50"?240:s=="s67"?215:s=="s75"?200:s=="s80"?170:s=="s85"?150:s=="s90"?140:150, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "buyVal", headerName: "Buy Val", resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?260:s=="s50"?240:s=="s67"?210:s=="s75"?200:s=="s80"?170:s=="s85"?160:s=="s90"?150:150, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "avgBuyPrice", headerName: "Avg. Buy Price", resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?210:s=="s75"?200:s=="s80"?170:s=="s85"?155:s=="s90"?150:140, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "sellVol", headerName: "Sell Vol(Shr)", resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?215:s=="s75"?200:s=="s80"?170:s=="s85"?150:s=="s90"?150:140, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "sellVal", headerName: "Sell Val" , resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?215:s=="s75"?200:s=="s80"?170:s=="s85"?150:s=="s90"?150:140, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "avgSellPrice", headerName: "Avg. Sell Price" , resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?210:s=="s75"?200:s=="s80"?170:s=="s85"?160:s=="s90"?150:140, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "netVol", headerName: "Net Vol(Shr)", resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?210:s=="s75"?200:s=="s80"?180:s=="s85"?160:s=="s90"?150:130, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
                { field: "netVal", headerName: "Net Val", resizable: true, filter: "agNumberColumnFilter",
                    sortable: true,  comparator: integerComparator,
                    width: s=="s49"?270:s=="s50"?240:s=="s67"?210:s=="s75"?200:s=="s80"?180:s=="s85"?160:s=="s90"?150:130, minWidth: 130,
                    valueFormatter: currencyFormatter,
                    cellClass : function (params) {
                        return `text-right grid-table d-border-aggrid-right f-12 ${ColorLabel(params.data.netVol)}`;
                    },},
            ],
            defaultColDef: {
                sortable: false,
                filter: true,
                // headerCheckboxSelection: isFirstColumn,
                // checkboxSelection: isFirstColumn,
            },
            rowSelection: "multiple",
            rowData: [
                {
                    stock : "AGRI",
                    buyVol : "1,450,595",
                    buyVal : "12,139",
                    avgBuyPrice : "12",
                    sellVol : "1,462,73",
                    sellVal : "1,462,73",
                    avgSellPrice: "1,488,19",
                    netVol : "1,450,07",
                    netVal : "10,454,100",
                }
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="grid-294 ag-header-border-gray ag-theme-balham-dark ag-striped-even card-brokerTradeSummary"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.brokerTradeSum1}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        rowSelection={this.state.rowSelection}
                        groupSelectsFiltered={true}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
class BrokerTradeSummary2AgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s90"?105:100, minWidth: 100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    },},
                { field: "vol", headerName: "Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s90"?105:100, minWidth: 100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s90"?110:100, minWidth: 100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "freq", headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s90"?110:100, minWidth: 100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "netQty", headerName: "Net QTY", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s90"?110:100, minWidth: 100,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                { price: "10,870",
                    freq: "9",
                    vol: "20",
                    value: "156",
                    netQty: "2",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className={"card card-215 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"}
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

//============= 3. Broker Trade History Display =============
class BrokerTradeHistory_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
            currStock: 'TLKM',
        }
    }

    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartBroker').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateBroker").click(); 
            });
            $('#datepickerendBroker').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDateBroker").click(); 
            });


        });
    }

    handleChangeDate = () => {
        let nStartDate = document.getElementById('datepickerstartBroker').value;
        let nEndDate =  document.getElementById('datepickerendBroker').value;
        
        if(nStartDate != "" && nEndDate == ""){
            document.getElementById('datepickerendBroker').value = GetTimeForDateTicker(0, +1);
            nEndDate = GetTimeForDateTicker(0, +1);
            $("#datepickerendBroker").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start: nStartDate, to: nEndDate};
        this.props.handleOnClick('datepicker','brokerTradehistory', nDatepicker)
    }

    render () {
        let p = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    {/*outer*/}
                    {/*<div className="container-fluid f-12 card-520">*/}
                    <div className="container-fluid px-0 f-12">
                        {/*card 520*/}
                        <div className="py-2 px-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-4 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-4 px-0 mx-0">Broker Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-8 text-left align-self-center">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={p.brokerList}
                                            onChange={(e)=>this.props.handleSearchCode('brokerTradeHistory', e.value)}
                                            className="stockPageSelect" 
                                            theme={(theme) => selectSelectionTab(theme, p.thememode)}
                                            value={p.codeBrokerInfo}/>
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-12 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {p.codeBrokerInfo.saham === undefined ? "" : p.codeBrokerInfo.saham}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row container-fluid">
                            <div className="col-sm-6 mx-0 px-0 align-self-center">
                                <span onClick={this.handleChangeDate} id={"handleDateBroker"}></span>   
                                <table className={"ml-2"}>
                                    <tr>
                                        <td className={"px-0 py-1"}>
                                            <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                        </td>
                                        <td className={"px-0 py-1"}>
                                            <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                <Input placeholder='dd/mm/yy' id="datepickerstartBroker" className="col-sm-12 pl-0 pr-0 text-center align-self-center "
                                                    value={p.rangeBrokerTradeHistory.start}/>
                                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                            </div>
                                        </td>
                                        <td className={"px-0 py-1"}>
                                            <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                        </td>
                                        <td className={"px-0 py-1"}>

                                            <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                <Input placeholder='dd/mm/yy' id="datepickerendBroker" className="col-sm-12 pl-0 pr-0 text-center align-self-center "
                                                    value={p.rangeBrokerTradeHistory.to}/>
                                                <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                onClick={() => p.handleOnClick('brokerPage', 'GoBrokerTradeHistory', 'none')}>Go</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-sm-6 pt-3 mx-0">  
                            <Popup content='Refresh' position='top center' trigger={
                                    <button
                                        onClick={()=>p.handleOnClick("refreshPage","brokerTradeHistory","none")}
                                        className={`btn btn-primary pull-right col-sm-1 mt-1`}
                                        style={{"font-size": "14px", "width": "38px"}}>
                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                    </button>
                                }/>                              
                                <table className="table bg-black-trading mb-0 h-35 table-sm table-borderless" style={{width : "90%"}}>
                                    <thead></thead>
                                    <tbody className="d-border-top d-border-bottom">
                                        <tr>
                                            <td>T. Buy Val. :<span className={ColorLabel(p.brokerTradeHisInfo.tBuyVal)}> {p.brokerTradeHisInfo && ConvertToRupiah(p.brokerTradeHisInfo.tBuyVal)}</span></td>
                                            <td>T. Sell Val. :<span className={ColorLabel(p.brokerTradeHisInfo.tSellVal)}> {p.brokerTradeHisInfo && ConvertToRupiah(p.brokerTradeHisInfo.tSellVal)}</span></td>
                                            <td>Total Value :<span className={ColorLabel(p.brokerTradeHisInfo.tVal)}> {p.brokerTradeHisInfo && ConvertToRupiah(p.brokerTradeHisInfo.tVal)}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="px-1 mx-0 col-sm-12 row">
                            <div className="col-md-12 pr-1 pl-0">
                                <BrokerTradeHistoryAgGrid size={widthSize()}/>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        );
    }
}
class BrokerTradeHistoryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                // { field: "date", headerName: "Date ", sortable: true, filter: "agNumberColumnFilter", resizable: true, comparator: integerComparator,
                //     width: s=="s49"?195:s=="s50"?175:s=="s67"?170:s=="s75"?165:s=="s80"?135:s=="s85"?125:s=="s90"?105:120, minWidth: 100,
                //     cellClass : function (params) {
                //         return "grid-table d-border-aggrid-right f-12 text-left";
                //     },},
                { field: "stock", headerName: "Code", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    comparator: comparatorType.text,
                    width: s=="s49"?225:s=="s50"?220:s=="s67"?200:s=="s75"?190:s=="s80"?175:s=="s85"?155:s=="s90"?150:150, minWidth: 120,
                    cellClass: () => `grid-table d-border-aggrid-right f-12 text-left`
                    },
                { field: "buy_vol", headerName: "Buy Vol(Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    width: s=="s49"?230:s=="s50"?200:s=="s67"?170:s=="s75"?190:s=="s80"?170:s=="s85"?160:s=="s90"?150:150, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "buy_val", headerName: "Buy Val", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    width: s=="s49"?280:s=="s50"?260:s=="s67"?230:s=="s75"?215:s=="s80"?190:s=="s85"?170:s=="s90"?160:150, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "avg_buy", headerName: "Avg. Buy", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    width: s=="s49"?280:s=="s50"?260:s=="s67"?230:s=="s75"?215:s=="s80"?190:s=="s85"?160:s=="s90"?160:160, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 2),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "sell_vol", headerName: "Sell Vol(Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true, 
                    width: s=="s49"?290:s=="s50"?260:s=="s67"?230:s=="s75"?215:s=="s80"?190:s=="s85"?160:s=="s90"?160:130, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "sell_val", headerName: "Sell Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?290:s=="s50"?250:s=="s67"?230:s=="s75"?205:s=="s80"?160:s=="s85"?145:s=="s90"?140:130, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "avg_sell", headerName: "Avg. Sell", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?220:s=="s75"?185:s=="s80"?160:s=="s85"?150:s=="s90"?140:130, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 2),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "net_vol", headerName: "Net Vol(Shr)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?200:s=="s75"?185:s=="s80"?160:s=="s85"?150:s=="s90"?140:130, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
                { field: "net_val", headerName: "Net Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?200:s=="s75"?185:s=="s80"?165:s=="s85"?150:s=="s90"?140:130, minWidth: 130,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.stock && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.net_vol)}`
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                {
                    date: "10,870",
                    code: "9",
                    buyVol: "20",
                    buyVal: "20",
                    avgBuy: "156",
                    sellVol: "123",
                    sellVal: "111",
                    avgSell: "123123123",
                    netVol: "123",
                    netVal: "2317",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className={"card card-brokerInfo ag-theme-balham-dark ag-header-border-gray ag-striped-odd"}
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.brokerTradeHistory}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

//============= 4. Top Broker Display =============
class BrokerTopListPage_Base extends React.PureComponent {
    render(){
        return(
            <div className="f-12 px-0">
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction />
                <div className="card bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37 pt-3">
                        TOP BROKER
                        <Popup content='Refresh' position='top center' trigger={
                            <button
                                onClick={() => this.props.handleOnClick("refreshPage", "topbrokerMarketStatistic", "none")}
                                className="col-sm-1 pull-right btn btn-primary"
                                style={{"font-size": "12px", "margin-top": "-7px", "width": "38px"}}>
                                <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                            </button>
                        }/>
                    </div>
                    <div className="card-body">
                        <TopBrokerAgGrid />
                    </div>
                </div>
                <div className="card card-175 bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37 pt-3">
                        TOP BUYER
                    </div>
                    <div className="card-body">
                        <TopBrokerBAgGrid />
                    </div>
                </div>
                <div className="card card-175 bg-black-trading f-12">
                    <div className="card-header bg-tableheader h-37 pt-3">
                        TOP SELLER
                    </div>
                    <div className="card-body">
                        <TopBrokerSAgGrid />
                    </div>
                </div>
            </div>
        );
    }
}
class TopBrokerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "no", headerName: "#", sortable: true,
                    filter: "agNumberColumnFilter", resizable: true,comparator: integerComparator,
                    width: s=="s49"?140:s=="s50"?90:s=="s67"?70:50, minWidth:50,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                    cellRenderer: 'noRenderer'
                },
                { 
                    field: "broker_code", headerName: "Code", sortable: true, resizable: true,
                    width: s=="s49"?200:s=="s50"?170:s=="s67"?140:120,minWidth:120,
                    suppressSizeToFit:true, lockVisible:true,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12 locked-visible"
                },
                { 
                    field: "broker_name", headerName: "Company", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?500:s=="s50"?460:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s85"?300:s=="s90"?300:240,minWidth:240,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },
                { 
                    field: "total_val", headerName: "T. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s85"?180:s=="s90"?180:150,minWidth:150,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "buy_val", headerName: "B. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?300:s=="s50"?270:s=="s67"?240:s=="s75"?230:s=="s80"?210:s=="s85"?190:s=="s90"?190:170,minWidth: 170,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "sell_val", headerName: "S. Val(T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?290:s=="s50"?280:s=="s67"?240:s=="s75"?230:s=="s80"?210:s=="s85"?190:s=="s90"?190:175,minWidth: 175,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_vol", headerName: "T. Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?270:s=="s50"?220:s=="s67"?200:190,minWidth:190,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_freq", headerName: "T. Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?390:s=="s50"?380:s=="s67"?350:s=="s75"?340:s=="s80"?210:s=="s85"?190:s=="s90"?190:170,minWidth:170,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({rowIndex, data}) => data.broker_code ? rowIndex + 1 : "",
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138 ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.topBrokerVal}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="23"
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class TopBrokerBAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "no", headerName: "#", sortable: true,
                    width: s=="s49"?140:50,minWidth:50,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                    cellRenderer: "noRenderer"
                },
                { 
                    field: "broker_code", headerName: "Code", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?195:s=="s67"?180:120,minWidth:120, uppressSizeToFit:true, lockVisible:true,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12 locked-visible"
                },
                { 
                    field: "broker_name", headerName: "Company", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?520:s=="s50"?500:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s85"?300:s=="s90"?300:240,
                    minWidth: 240,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },
                { 
                    field: "total_val", headerName: "T. Val(T)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?330:s=="s50"?300:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s85"?180:s=="s90"?180:150,
                    minWidth: 150,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "buy_val", headerName: "B. Val(T)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?325:s=="s50"?300:s=="s67"?250:s=="s75"?230:s=="s80"?210:s=="s85"?190:s=="s90"?190:170,
                    minWidth: 170,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_vol", headerName: "T. Vol(Lot)", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?300:260,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_freq", headerName: "T. Freq", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?570:s=="s50"?550:s=="s67"?500:s=="s75"?480:s=="s80"?350:s=="s85"?310:s=="s90"?310:275,
                    minWidth: 275,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({rowIndex, data}) => data.broker_code ? rowIndex + 1 : "",
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138 ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.topBrokerBuy}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="23"
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class TopBrokerSAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = widthSize();
        this.state = {
            columnDefs: [
                { 
                    field: "no", headerName: "#", sortable: true,
                    width: s=="s49"?140:50, minWidth: 50,
                    cellClass: () => " grid-table d-border-aggrid-right text-center f-12",
                    cellRenderer: "noRenderer"
                },
                { 
                    field: "broker_code", headerName: "Code", sortable: true, filter: "agTextColumnFilter",
                    resizable: true, width: s=="s49"?200:s=="s50"?195:s=="s67"?180:120, minWidth: 120,
                    suppressSizeToFit:true, lockVisible:true,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12 locked-visible"
                },
                { 
                    field: "broker_name", headerName: "Company", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?520:s=="s50"?500:s=="s67"?420:s=="s75"?410:s=="s80"?370:s=="s85"?300:s=="s90"?300:240,
                    minWidth: 240,
                    cellClass: () => " grid-table d-border-aggrid-right text-left f-12"
                },
                { 
                    field: "total_val", headerName: "T. Val(T)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?330:s=="s50"?300:s=="s67"?250:s=="s75"?240:s=="s80"?230:s=="s85"?180:s=="s90"?180:150,
                    minWidth: 150,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "sell_val", headerName: "S. Val(T)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?325:s=="s50"?300:s=="s67"?250:s=="s75"?230:s=="s80"?210:s=="s85"?190:s=="s90"?190:170,
                    minWidth: 170,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_vol", headerName: "T. Vol(Lot)", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?300:260, minWidth: 260,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
                { 
                    field: "total_freq", headerName: "T. Freq", sortable: true, filter: "agNumberColumnFilter",
                    resizable: true, width: s=="s49"?570:s=="s50"?550:s=="s67"?500:s=="s75"?480:s=="s80"?350:s=="s85"?310:s=="s90"?310:275,
                    minWidth: 275,
                    valueFormatter: ({ value }) => ConvertToRupiah(value),
                    cellClass: () => " grid-table d-border-aggrid-right text-right f-12"
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                noRenderer: ({rowIndex, data}) => data.broker_code ? rowIndex + 1 : "",
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className="card-138 ag-theme-balham-dark ag-striped-odd"
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.topBrokerSell}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="23"
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}


class ChatListSupport extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            activeIndex: -1,
            friendList: [
                {
                    'id':'IT SUPPORT',
                    'from':'IT SUPPORT',
                    'message':'',
                    'status':'active',
                    'new':'0',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'FINANCE SUPPORT',
                    'from':'FINANCE SUPPORT',
                    'message':'',
                    'status':'active',
                    'new':'0',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'QUALITY SUPPORT',
                    'from':'QUALITY SUPPORT',
                    'message':'',
                    'status':'active',
                    'new':'0',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'NETWORK SUPPORT',
                    'from':'NETWORK SUPPORT',
                    'message':'',
                    'status':'active',
                    'new':'0',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },
            ],
        }
        this.doParentToggle = this.doParentToggle.bind(this);
    }
    doParentToggle(_counterFromChild){
        this.setState({
            activeIndex: _counterFromChild,
        });
        this.props.parentToggle(_counterFromChild);

        // console.log(_counterFromChild);
        // this.props.changeIdChatBot(_counterFromChild)
    }

    render(){


        return(

            <div className="container-fluid px-1 mx-0 col-sm-12 scroll d-border-top">

                <nav className="nav flex-column">

                    {this.state.friendList.map((charx, index) => {
                        return <Square
                            from={charx.from}
                            message={charx.message}
                            new={charx.new}
                            id={charx.id}
                            img={charx.img}
                            time={charx.time}
                            isFriend="null"
                            active={(charx.id == this.state.activeIndex) ? "active" : ""}
                            parentToggle={this.doParentToggle}
                        />
                    })}
                </nav>
            </div>

        )
    }
}

//PARENT CHAT COMMENT PAGE
class ChatCommentPage extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            'activeId':-1,
            'isSearch': false,
            'chatId':0,
        }
        this.changeActiveId = this.changeActiveId.bind(this);
    }
    changeActiveId(_counter){
        this.setState({
            'activeId':_counter,
        })
    }
    render(){
        const switchChat = () => {
            if(this.state.activeId !== -1){
                return <ActionPageFrame_Base chatId={this.state.activeId}/>
            }else{
                return <ChatListEmpty chatId={this.state.activeId}/>
            }
        }
        return(
            <div className="row px-1 mx-0">
                <div className="col-sm-4 px-1 mx-0">
                    <ChatListComment parentToggle={this.changeActiveId}/>
                </div>
                <div className="col-sm-8 px-1 mx-0">
                    {switchChat()}
                </div>
            </div>
        )
    }
}
class ChatListComment extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            activeIndex: -1,
            friendList: [
                {
                    'id':'BUY',
                    'from':'BUY',
                    'message':'',
                    'status':'active',
                    'new':'',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'SELL',
                    'from':'SELL',
                    'message':'',
                    'status':'active',
                    'new':'',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'AMEND',
                    'from':'AMEND',
                    'message':'',
                    'status':'active',
                    'new':'',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },{
                    'id':'WITHDRAW',
                    'from':'WITHDRAW',
                    'message':'',
                    'status':'active',
                    'new':'',
                    'time':'',
                    'img': '/static/media/man.3e62c017.png',
                },
            ],
        }
        this.doParentToggle = this.doParentToggle.bind(this);
    }
    doParentToggle(_counterFromChild){
        this.setState({
            activeIndex: _counterFromChild,
        });
        this.props.parentToggle(_counterFromChild);

        // console.log(_counterFromChild);
        // this.props.changeIdChatBot(_counterFromChild)
    }

    render(){


        return(

            <div className="container-fluid px-1 mx-0 col-sm-12 scroll rsc-scroll d-border-top">

                <nav className="nav flex-column">

                    {this.state.friendList.map((charx, index) => {
                        return <Square
                            from={charx.from}
                            message={charx.message}
                            new={charx.new}
                            id={charx.id}
                            img={charx.img}
                            time={charx.time}
                            isFriend="null"
                            active={(charx.id == this.state.activeIndex) ? "active" : ""}
                            parentToggle={this.doParentToggle}
                        />
                    })}
                </nav>
            </div>

        )
    }
}


class ActionPageFrame_Base extends React.PureComponent{
    constructor(props){
        super(props);
        this.state= {
            steps: [
                {
                    id:'1',
                    message:'Hello, What`s your name?',
                    trigger:'1.2',
                },
                {
                    id:'1.2',
                    user:true,
                    trigger: '2',
                },
                {
                    id: '2',
                    message: 'Hey {previousValue}, Greatings!',
                    trigger: 'next2',
                },
                {
                    id: 'next2',
                    message: 'Can I help you?',
                    trigger: '3',
                },
                {
                    id: '3',
                    options: [
                        { value: "Error Text", label: 'Chat Issue', trigger: '3.1' },
                        { value: "What is trading?", label: 'Trade Issue', trigger: '3.2' },
                        { value: "Error Chart", label: 'Chart Issue', trigger: '3.1' },
                    ],
                },
                {
                    id: '3.1',
                    message: 'Maybe this can help you.',
                    trigger: '3.1.2',
                },
                {
                    id: '3.1.2',
                    component: (
                        <div> You can open and reload the page </div>
                    ),
                    trigger: 'next2',
                },
                {
                    id: '3.2',
                    message: 'Trading is ..',
                    trigger: 'next2',
                },
            ],
        }
    }

    render() {

        const theme = {
            background: '#4D4E4E',
            fontFamily: 'Open Sans',
            headerBgColor: '#000000',
            headerFontColor: '#fff',
            headerFontSize: '12px',
            botBubbleColor: '#fff',
            botFontColor: '#000',
            userBubbleColor: '#2cf871',
            userFontColor: '#000',
        };
        var logo = "/static/media/man.3e62c017.png";

        const HeaderTitleProvider = () => {
            return (
                <div className="rsc-header">
                    <div className="row">
                        <div className="col-md-1">
                            <img src={logo} alt="User" className="img-avatar d-border mr-2"/>
                        </div>
                        <div className="col-md-11 divStatusChat">
                            <span className="textTitleChat">{this.props.chatId}</span>
                            <span className="textStatusChat">Online</span>

                        </div>
                    </div>
                </div>
            )
        };

        const ThemedExample = () => (
            <ThemeProvider theme={theme}>
                <ChatBot
                    steps={this.state.steps}
                    headerTitle="Support"
                    hideBotAvatar="true"
                    hideUserAvatar="true"
                    width="100%"
                    headerComponent={HeaderTitleProvider()}
                />
            </ThemeProvider>
        );
        return(
            <div className="bg-grey col-sm-12 mx-0 px-0">
                <div style={{ display : this.props.chatId !== '' ? "none" : "block"}}><ChatListEmpty/></div>
                <ThemedExample/>
            </div>
        )
    }

}
const BrokerPageFrameHeader = (props) => {
    return (
        <></>
    );
}

class Square extends React.PureComponent {
    constructor(props){
        super(props);
        this.doParentToggleFromChild = this.doParentToggleFromChild.bind(this);
        this.addFriendChild = this.addFriendChild.bind(this);
        this.id= this.props.id;
        this.time=this.props.time;
        this.new=this.props.new;
        this.active = this.props.active;

    }
    doParentToggleFromChild(){
        this.props.parentToggle(this.id)
    }
    addFriendChild(){
        this.props.parentAdd(this.id);
    }
    render() {
        const actionButton = (props) => {
            if(props === true || props === false){
                return <span className="textPesanTimeMessage">&nbsp;</span>
            }else{
                return <span className="textPesanTimeMessage">{this.time}</span>
            }
        }
        const spanList = (props) => {
            if(props === true){
                return <i className="fa fa-comment"></i>
            }else if(props === false){
                return <i className="fa fa-user-plus" onClick={() => this.addFriendChild()}></i>
            }else{
                return <span className={(parseInt(this.new) > 0) ? "badge textPesanBadge" : ""}>
                    {(parseInt(this.new) > 0) ? this.new : ""}
                    </span>
            }
        }
        const checkFriend = (props) => {
            if(props === true){
                this.doParentToggleFromChild();
            }else if(props === false){
                return false;
            }else{
                this.doParentToggleFromChild();
            }
        }
        return (
            <div
                className={`container-fluid divChatList nav-link row ${this.active}`}
                onClick={() => checkFriend(this.props.isFriend)}>
                <div className="col-sm-3 divImgListChat">
                    <img src={this.props.img} alt="User" className="img-avatar d-border mr-2"/>
                </div>
                <div className="col-sm-7 divBodyListChat">
                    <span className="textPesanTitle"> {this.props.from}</span>
                    <span className="textPesan">{this.props.message}</span>
                </div>
                <div className="col-sm-2 divAttrListChat" >
                    {actionButton(this.props.isFriend)}
                    {spanList(this.props.isFriend)}
                </div>
            </div>
        )
    }
}

class ChatListEmpty extends  React.PureComponent{
    render(){
        return(
            <>
                <div className="card-body card-527 align-self-center text-center bg-grey f-14 py-3">
                    <div className="py-5 my-5">
                        <div className="py-5 my-5">
                            <i className="icofont icofont-warning-alt f-25"></i>
                            <div className="py-3">Empty</div>
                            <div>Please choose one person to send a message</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class ChatActionPage_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            activeIndex: -1,
            chatMessage: [
                {
                    'id':'budibudibuiantara@gmail',
                    'from':'budiantara@gmail',
                    'message':'Hello dude - test',
                    'status':'active',
                    'new':'6',
                    'time':'09.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'asepsupriadibalap@support',
                    'from':'asep@support',
                    'message':'Hello dude2',
                    'status':'active',
                    'new':'6',
                    'time':'08.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'iantaracintadanduka@ceo',
                    'from':'iantara@ceo',
                    'message':'Hello dude3',
                    'status':'active',
                    'new':'1',
                    'time':'07.55',
                    'img': '/static/media/man.3e62c017.png',

                },{
                    'id':'tatangsutarmasihsama@gmail',
                    'from':'tatangsutarma@gmail',
                    'message':'Hello dude - test',
                    'status':'active',
                    'new':'6',
                    'time':'09.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'odingdingdangding@support',
                    'from':'oding@support',
                    'message':'Hello dude2',
                    'status':'active',
                    'new':'6',
                    'time':'08.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'sigarantangdinatarang@ceo',
                    'from':'sigarantang@ceo',
                    'message':'Hello dude3',
                    'status':'active',
                    'new':'1',
                    'time':'07.55',
                    'img': '/static/media/man.3e62c017.png',

                },{
                    'id':'amirbudiardjojobu@gmail.com',
                    'from':'amirbudiardjo@gmail',
                    'message':'Hello dude - test',
                    'status':'active',
                    'new':'6',
                    'time':'09.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'wirantokenapanto@support',
                    'from':'wiranto@support',
                    'message':'Hello dude2',
                    'status':'active',
                    'new':'6',
                    'time':'08.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'emilembambabilo@ceo',
                    'from':'emilembamba@ceo',
                    'message':'Hello dude3',
                    'status':'active',
                    'new':'1',
                    'time':'07.55',
                    'img': '/static/media/man.3e62c017.png',

                },{
                    'id':'udjangjjangmirna@gmail',
                    'from':'udjangudha@gmail',
                    'message':'Hello dude - test',
                    'status':'active',
                    'new':'6',
                    'time':'09.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'rohmatullohirahmin@support',
                    'from':'rohmatulloh@support',
                    'message':'Hello dude2',
                    'status':'active',
                    'new':'6',
                    'time':'08.00',
                    'img': '/static/media/man.3e62c017.png',

                },
                {
                    'id':'ulisulistiawastid@ceo',
                    'from':'ulisulistia@ceo',
                    'message':'Hello dude3',
                    'status':'active',
                    'new':'1',
                    'time':'07.55',
                    'img': '/static/media/man.3e62c017.png',

                }
            ],
        }
        this.doParentToggle = this.doParentToggle.bind(this);
    }
    doParentToggle(_counterFromChild){
        this.setState({
            activeIndex: _counterFromChild,
        });
        console.log(_counterFromChild);
        this.props.changeIdChatBot(_counterFromChild)
    }
    render(){
        return(

            <div className="container-fluid px-1 mx-0 col-sm-4 scroll d-border-top">
                <nav className="nav flex-column">
                    {this.state.chatMessage.map((charx, index) => {

                        return <Square
                            from={charx.from}
                            message={charx.message}
                            new={charx.new}
                            id={charx.id}
                            img={charx.img}
                            time={charx.time}
                            active={(charx.id == this.state.activeIndex) ? "active" : ""}
                            parentToggle={this.doParentToggle}
                        />
                    })}
                </nav>
            </div>

        )
    }
}

class BrokerPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <ChatSupport2/>
        )
    }
}

class ChatSupport2 extends React.PureComponent {
    render () {

        return (
            //hanya memanggil headernya saja
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}


//************************** Context Connector **************************
const CustomFrameHeaderBrokerPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        chatId : vars.chatId,
        changeIdChatBot : (chatId) => {actions.sendAction('changeIdChatBot', {chatId})}
    }),
)(CustomFrameHeaderBrokerPage_Base);

// 1. Broker Info Display
const BrokerInfo = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        brokerList: vars.brokerList,
        brokerInfoDetail: vars.brokerInfoDetail,
        codeBrokerInfo: vars.codeBrokerInfo,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
        handleSearchCode: (type, code) => actions.sendAction('handleSearchCode', {type, code}),
    }),
    ["handleOnClick", "handleSearchCode"]
)(BrokerInfo_Base);

// 2. Broker Trade Summary Display
const BrokerTradeSummary = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        brokerList: vars.brokerList,
        codeBrokerInfo: vars.codeBrokerInfo,
        brokerTradeSumInfo: vars.brokerTradeSumInfo,
        handleSearchCode: (type, code) => actions.sendAction('handleSearchCode', {type, code}),
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleSearchCode", "handleOnClick"]
)(BrokerTradeSummary_Base);

const BrokerTradeSummaryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        brokerTradeSum1: vars.brokerTradeSum1
    }),
)(BrokerTradeSummaryAgGrid_Base);

const BrokerTradeSummary2AgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        brokerTradeSum2: vars.brokerTradeSum2
    }),
)(BrokerTradeSummary2AgGrid_Base);

// 3. Broker Trade Summary Display
const BrokerTradeHistory = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        brokerList: vars.brokerList,
        codeBrokerInfo: vars.codeBrokerInfo,
        rangeBrokerTradeHistory: vars.rangeBrokerTradeHistory,
        brokerTradeHisInfo: vars.brokerTradeHisInfo,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
        handleSearchCode: (type, code) => actions.sendAction('handleSearchCode', {type, code}),
    }),
    ["handleOnClick", "handleSearchCode"]
)(BrokerTradeHistory_Base);

const BrokerTradeHistoryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        brokerTradeHistory: vars.brokerTradeHistory
    }),
)(BrokerTradeHistoryAgGrid_Base);

// 4. Top Broker Display
const BrokerTopListPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"],
)(BrokerTopListPage_Base);

const TopBrokerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        topBrokerVal: vars.topBrokerVal || []
    }),
)(TopBrokerAgGrid_Base);

const TopBrokerBAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        topBrokerBuy: vars.topBrokerBuy || []
    }),
)(TopBrokerBAgGrid_Base);
const TopBrokerSAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        topBrokerSell: vars.topBrokerSell || []
    }),
)(TopBrokerSAgGrid_Base);






//************************** Value Formatter For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}

function stringComparator(valueA, valueB){
    if(valueA !== null && valueB !== null){
        if(valueA.length < 2){
            return null;
        }else if(valueB.length < 2){
            return null;
        }else{
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    }

}
function integerComparator(valueA, valueB){
    if(valueA == ""){
        return null;
    }else if(valueB == ""){
        return null;
    }else{
        return valueA - valueB;
    }
}

export default BrokerPage;
export {CustomFrameHeaderBrokerPage, 
    BrokerInfo,
    BrokerTradeSummary,
    BrokerTradeHistory,
    BrokerTopListPage,
};
