import React from 'react';
import Select from 'react-select';
import {AppFrame, AppFrameAction,} from "../appframe";
import {Dropdown,Input, Popup} from 'semantic-ui-react';
import {Table as TableBS} from 'react-bootstrap';
import d from '../dummyData';

import {BIPSAppContext, ConvertToRupiah, GetStockWathclist, GetStockTradeHistory, 
    CurrencyModifier, GroupList, searchCode, sumDataArray,
    ColorLabel, IconChange, ShareToLot, ConvertToTime } from "../AppData";
import FillHeaderTab from "../tabheaderfill";
import {  WSConnectionAction } from '../appnetwork.js';
import { ContextConnector } from '../appcontext.js';

import ModalBuy from "./../app_modals/modal_buy";
import ModalSell from "./../app_modals/modal_sell";
import MenuOfContent from "./../menuofcontent";
import TableInfoTransaction from "./../app_transaction/tableInfoTransaction";

import { GetTimeForDateTicker, dummy, fmsg, addRowEmpty, mathCall, strCall, updownIcon, colorText } from '../utils';
import { setFieldCorpAction, CorpActionDetail } from './app_StockPage'
import FormBuy from "./../app_transaction/form_buy";
import FormSell from "../app_transaction/form_sell";
import StockChart from "./stockChart";

//datepicker
import '../bootstrap-3.3.7/bootstrap-datepicker.min.css';
import $ from 'jquery';
import {AgGridReact} from "ag-grid-react";
import { ResizeResponsive, widthSize, comparatorType} from "../utils";

import anychart from 'anychart';
import AnyChart from '../../node_modules/anychart-react/dist/anychart-react.min.js'
import '../../node_modules/anychart/dist/css/anychart-ui.min.css';
import '../../node_modules/anychart/dist/js/anychart-ui.min.js';
import '../../node_modules/anychart/dist/fonts/css/anychart-font.min.css';
import '../../node_modules/anychart/dist/js/anychart-data-adapter.min.js'
import '../../node_modules/anychart/dist/js/anychart-annotations.min.js';

import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
import '../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js';

window.$ = window.jQuery = $;
require('../../node_modules/bootstrap/dist/js/bootstrap.js');
require('../bootstrap-3.3.7/bootstrap-datepicker.standalone.min.css');
require('../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js');

const summaryOptions = [
    //untuk top active
    { key: 'all', value: 'ALL', text: 'All' },
    // { key: 'rg', value: 'RG', text: 'RG' },
    { key: 'tn', value: 'TN', text: 'TN' },
    { key: 'ng', value: 'NG', text: 'NG' },
];

const yearOptions = [
    { key: 'satu', value: '1', text: '1\'st Qtr ' },
    { key: 'dua', value: '2', text: '2\'nd Qtr' },
    { key: 'tiga', value: '3', text: '3\'rd Qtr' },
    { key: 'empat', value: '4', text: '4\'th Qtr' },
]

//============= Sub header of Stock Page =============
// 1.stock info, 2.stock Watchlis, 3.stock trade history
const CustomFrameHeaderStock = (props) => {
    return (
        <div>
            {/* <BIPSAppProvider> */}
            <WSConnectionAction />
            <div className="col-sm-12 px-0 mx-0 align-self-center">
                <div className="col-sm-12 px-0 mx-0 d-border-bottom">
                    <FillHeaderTab treeName="/stockPage" linkTitles={
                        {
                            stockInfoPage : 'STOCK INFO',
                            stockFinancialStatement : 'FINANCIAL STATEMENT',
                            stockWatchlistPage: 'STOCK WATCHLIST',
                            stockHistoryPage: 'STOCK TRADE HISTORY',
                            stockTradeSummaryPage: 'STOCK TRADE SUMMARY',
                            stockSpecialNotation: 'SPECIAL NOTATION',
                            stockCorpAction: 'CORP. ACTION',
                        }
                    } />
                </div>
            </div>
            <AppFrame treeName="/stockPage" headerComponent={StockFrameHeader}/>
            {/* </BIPSAppProvider> */}
        </div>
    );
}

const StockFrameHeader = (props) => {
    return (
        <></>
    );
}

class Stocks extends React.PureComponent {

    render () {
        return (
            <div className="bg-black-trading px-0 mx-0">
            </div>
        );
    }
}

//============= 1. Stock Info Display =============
class StockPage_Base extends React.PureComponent {
    
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{   
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: BuyPage2,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    buttonClickSell = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{ 
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: SellPage,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    render () {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        let code = strCall.code(this.props.codeSearchStockInfo)
        let selectedData = searchCode('selected', code, this.props.stocklistOptions)
        let stockData = this.props.iStockData
        let sStockSmry = this.props.sStockSmry
        let change = sStockSmry.change_price;

        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" /> {/* websocket connection component*/}
                <main>
                    <div className="container-fluid f-12 card-527">
                        <div className="py-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40 mt-2">
                                <div className="col-sm-3 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-2 px-0 mx-0">Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-10 text-left align-self-center">
                                        {/* <Select maxMenuHeight={75} styles={customStyles} placeholder={<div>Search..</div>} options={stockOptions} 
                                        className="stockPageSelect text-left" theme={this.selectSelectionTab}
                                        onChange={(e)=>this.props.handleSearchCode('stockInfo', e.value)} className="stockPageSelect" 
                                        value={selectedData}
                                        /> */}
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={this.props.stocklistOptions} 
                                            onChange={(e)=>this.props.handleSearchCode('stockInfo', e.value)}
                                            className="stockPageSelect text-left" theme={this.selectSelectionTab}
                                            value={selectedData}/>
                                    
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-6 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {stockData.stock_code !== undefined ? stockData.name : ""}
                                    </label>
                                    <label className="col-sm-3 f-13 f-xs-14 align-middle align-self-center px-2 text-left">
                                        Last <span className={change < 0 ? "text-danger" : (change > 0 ? "text-success" : "text-warning")}>
                                            { change !== undefined ? ConvertToRupiah(sStockSmry.close_price) :  ""}
                                        </span>
                                    </label>
                                    <label className={change < 0 ?"col-sm-3 text-danger f-13 f-xs-14 align-middle align-self-center px-0 text-left"
                                        :(change > 0 ? "col-sm-3 text-success f-13 f-xs-14 align-middle align-self-center px-0 text-left" 
                                        : "col-sm-3 text-warning f-13 f-xs-14 align-middle align-self-center px-0 text-left")}>
                                        {change !== undefined ? (change < 0 ? <i className="oi oi-caret-bottom"></i> : (change > 0 ? <i className="oi oi-caret-top"></i> : '')): ''}&nbsp;
                                        { change !== undefined ? ConvertToRupiah(change)+' ('+ConvertToRupiah(sStockSmry.change_price_pcts)+'%'+')' : ""}
                                    </label>
                                </div>
                                <div className="col-sm-3 align-self-center mx-0 px-0">
                                    <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-success" onClick={this.buttonClickSell}><span>Sell</span></button>
                                    <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-danger" onClick={this.buttonClickBuy}><span>Buy</span></button>
                                </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                <div className="col-md-7 px-1 py-2 card-482">
                                    <div id="stock-chart" className="card card-283 bg-trading-gray">
                                        <StockChart/>
                                    </div>
                                    <StockInfo/>
                                </div>
                                <div className="col-md-5 px-1 py-0 card-482">
                                    <TableInfoTransaction lotshare="stockInfoBuy" card="card-310-is" card2="card-140" />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

// a.Table of Stock Info, b.Table of Profile, c. Table of Corp Actions
const StockInfo = (props) => {
    return(
        <div>
            {/*<BIPSAppProvider>*/}
            <WSConnectionAction />
            <div className="row col-sm-12 px-0 mx-0 pt-1 card-191">
                <div className="col-sm-12 px-0 h-30">
                    <MenuOfContent treeName="/stockPage/stockInfoPage" linkTitles={
                        {
                            stockInfoTable : 'STOCK INFO',
                            dailyTechnicalTable : 'DAILY TECHNICAL',
                        }
                    } />
                </div>
                <div className="col-sm-12 px-0 card-161">
                    <AppFrame treeName="/stockPage/stockInfoPage" headerComponent={StockInfoFrameHeader}/>
                </div>
            </div>
            {/*</BIPSAppProvider>*/}
        </div>
    );
}

const StockInfoFrameHeader = (props) => {
    return (
        <></>
    );
}

//============= 1.a Table of Stock Info =============
class TableStockInfo_Base extends React.PureComponent{
    render() {
        let iStockData = this.props.iStockData
        let sStockSmry = this.props.sStockSmry
        let cek = iStockData.stock_code
        // Board 
        let board = cek == undefined ? "" : iStockData.board_pos.split(" ")[0]
        // Mkt. Capital(M)
        let mktCapital = ((Number(sStockSmry.close_price)*Number(iStockData.listed_share))/1000000).toFixed(0)
        let preOpeningStatus = cek == undefined || iStockData.trade_in_pre == "" ? "" : iStockData.trade_in_pre.includes("stock cannot") ? "No" : "Yes"
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <TableBS responsive size="sm" className="text-white my-0 bg-dark-grey card-161-1 table-striped-trans">
                    <thead></thead>
                    <tbody>
                    <tr>
                    <td className="py-1 bg-gray-tradding d-border-tr-black" width="25%">Listed</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right" width="30%">
                            {cek !== undefined ? ConvertToRupiah(Number(iStockData.listed_share)) : ""}
                        </td>
                        <td className="py-1 bg-gray-tradding d-border-tr-black" width="25%">IPO</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right" width="20%">
                            {cek !== undefined ? ConvertToRupiah(iStockData.ipo_price) : ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Tradeable</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right">
                            { cek !== undefined ? ConvertToRupiah(Number(iStockData.tradable_share)) : ""}
                        </td>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Base</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right">
                            { cek !== undefined ? ConvertToRupiah(iStockData.base_price) : ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Fg. Avail</td>
                        <td className="py-1 text-primary text-right d-border-tr-gray">
                            { cek !== undefined ? ConvertToRupiah(Number(sStockSmry.available_for_frg)) : ""}
                        </td>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Board</td>
                        <td className="py-1 text-primary text-left d-border-tr-gray">
                            { cek !== undefined ? board : ""}
                        </td>                        
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Mkt. Capital(M)</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right">
                            { cek !== undefined ? ConvertToRupiah(mktCapital) : ""}
                        </td>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Pre - Opening</td>
                        <td className="py-1 text-primary d-border-tr-gray text-left">{preOpeningStatus}</td>
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Corp. Action</td>
                        <td className="py-1 text-primary d-border-tr-gray text-right">{ cek !== undefined ? iStockData.corp_action : ""}</td>
                        <td className="py-1 bg-gray-tradding d-border-tr-black"></td>
                        <td className="py-1 text-primary d-border-tr-gray text-left"></td>  
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding d-border-tr-black">Marginable</td>
                        <td className="py-1 text-primary d-border-tr-gray text-left" colSpan="3">
                            { cek !== undefined ? iStockData.margin_type : ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-1 bg-gray-tradding">Sub Sector</td>
                        <td className="py-1 text-primary d-border-tr-gray text-left" colSpan="3">
                            { cek !== undefined ? iStockData.sub_sector_name : ""}
                        </td>
                    </tr>
                    </tbody>
                </TableBS>
            </>
        );
    }
}

//============= 1.b Table of Profile =============
class TableProfil extends React.PureComponent{
    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <TableBS responsive size="sm" className="text-white my-2" borderless>
                    <thead></thead>
                    <tbody>
                    {/* <tr className="py-3"><td>Corporate Governance</td></tr>
                    <tr className="py-3">
                        <td>
                            PT Astra Agro Lestari Tbk’s ISS Governance Quality
                            Score as of N/A is N/A. The pillar scores are Audit: N/A; Board: N/A;
                            Shareholder Rights: N/A; Compensation: N/A.
                        </td>
                    </tr>
                    <tr className="py-3">
                        <td>
                            Corporate governance scores courtesy of Institutional Shareholder Services (ISS).
                            Scores indicate decile rank relative to index or region. A decile score of 1 indicates
                            lower governance risk, while a 10 indicates higher governance risk.
                        </td>
                    </tr> */}
                    </tbody>
                </TableBS>
            </>
        );
    }
}

//============= 1.c Table of DailyTechnical =============
class DisclaimerStockModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white f-13">
                    <p className="ti-5 text-justify px-5 mx-5 py-1">
                        Publikasi ini disiapkan oleh PT. Bahana Sekuritas . Publikasi ini dan isinya dimaksudkan hanya untuk
                        tujuan informasi saja, dan dapat berubah tanpa pemberitahuan lebih lanjut. Setiap penggunaan, pengungkapan, distribusi, penyebaran, penyalinan, pencetakan atau ketergantungan pada publikasi ini untuk tujuan lain apa pun
                        tanpa persetujuan atau persetujuan sebelumnya sangat dilarang. PT. Bahana Sekuritas tidak akan menerima tanggung
                        jawab atau kewajiban apa pun atas penggunaan atau ketergantungan pada publikasi ini atau isinya. Baik publikasi ini, maupun konten apa pun di sini, bukan merupakan, atau untuk ditafsirkan sebagai, tawaran atau ajakan untuk membeli
                        atau menjual saham atau investasi apa pun yang disebutkan di sini di negara atau yurisdiksi mana pun. Setiap
                        pandangan, rekomendasi, pendapat atau saran yang diungkapkan dalam publikasi ini merupakan pandangan dari
                        analis PT Bahana Sekuritas . Laporan riset ini tidak boleh diandalkan oleh siapa pun dalam membuat keputusan
                        investasi atau memberi nasihat terkait, atau berurusan dengan, saham yang disebutkan, karena tidak
                        memperhitungkan tujuan investasi khusus, situasi keuangan, dan kebutuhan khusus perusahaan siapapun.
                    </p>
                    <p className="ti-5 text-justify px-5 mx-5 py-1">
                        Bahana Sekuritas, atau afiliasinya masing-masing, atau masing-masing direktur, pejabat dan karyawannya dari waktu
                        ke waktu mungkin memiliki perdagangan sebagai prinsipal, atau memiliki posisi di, atau memiliki kepentingan lain
                        dalam sekuritas perusahaan yang diteliti termasuk aktivitas pembuatan pasar, turunan sehubungan dengan sekuritas
                        tersebut atau mungkin juga memiliki melakukan perbankan investasi dan layanan lainnya untuk penerbit sekuritas
                        tersebut. Bahana Sekuritas, atau afiliasinya masing-masing melakukan dan berusaha untuk berbisnis dengan
                        perusahaan -perusahaan yang tercakup dalam laporan penelitian ini. Oleh karena itu, investor harus mewaspadai
                        kemungkinan adanya benturan kepentingan.
                    </p>
                    <div className="text-center">
                        <button className="btn btn-primary col-sm-3" onClick={this.closeClick}>Close</button>
                    </div>
                </div>
            </>
        );
    }
}

class TableDailyTechnical_Base extends React.PureComponent {

    buttonClickStockDisclaimer = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-white text-center">Important Disclosures and Disclaimer</div>,
            closeIcon: false,
            contentClass: DisclaimerStockModal,
        })
    }

    render() {
        let data = this.props.techDayData;
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                
                <div className="row">
                    <div className="col-sm-12">
                        <table class="table table-bordered mb-0 card-topDT" style={{"width":"100%"}}>
                            <tbody>
                                <tr>
                                    <td className="text-center bg-tableheader py-2 d-border-tr-gray-all" style={{"width":"19%"}}>Support 2</td>
                                    <td className="text-center bg-tableheader py-2 d-border-tr-gray-all" style={{"width":"19%"}}>Support 1</td>
                                    <td className="text-center bg-tableheader py-1 d-border-tr-gray-all">Research View</td>
                                    <td className="text-center bg-tableheader py-2 d-border-tr-gray-all" style={{"width":"19%"}}>Resistance 1</td>
                                    <td className="text-center bg-tableheader py-2 d-border-tr-gray-all" style={{"width":"19%"}}>Resistance 2</td>
                                </tr>
                                <tr>
                                    <td className="text-center bg-gray-tradding py-1 d-border-tr-black">
                                        {mathCall.setRupiah(data.support_2 || '', 0, <>&nbsp;</>)}
                                    </td>
                                    <td className="text-center bg-gray-tradding py-1 d-border-tr-black">
                                        {mathCall.setRupiah(data.support_1 || '', 0, <>&nbsp;</>)}
                                    </td>
                                    <td className="text-center bg-gray-tradding py-1 d-border-tr-black">
                                        <span class={`f-15 ${data.nRec && (data.nRec.toUpperCase().includes("BUY") ? "text-danger" : data.nRec.toUpperCase().includes("SELL") ? "text-success" : "text-warning")}`}>
                                            {data.nRec && data.nRec}
                                        </span>
                                        <p class="text-xs text-left text-blue click-pointer" onClick={this.buttonClickStockDisclaimer}>*<i><u>Disclaimer</u></i></p>
                                    </td>
                                    <td className="text-center bg-gray-tradding py-1 d-border-tr-black">
                                        {mathCall.setRupiah(data.resist_1 || '', 0, <>&nbsp;</>)}
                                    </td>
                                    <td className="text-center bg-gray-tradding py-1 d-border-tr-black">
                                        {mathCall.setRupiah(data.resist_2 || '', 0, <>&nbsp;</>)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-12 mt-1">
                        <table class="table table-bordered tb-dailytech">
                            <tbody>
                                {/* <tr>
                                    <td className="text-left bg-gray-tradding py-2 d-border-tr-black" style={{"width":"30%"}}>Daily Recommendation</td>
                                    <td className="text-right py-2 d-border-tr-gray  text-info">{data.recommendation ? data.recommendation : <>&nbsp;</>}</td>
                                    <td className="text-left bg-gray-tradding py-2 d-border-tr-black"  style={{"width":"30%"}}>Market Cap(Bn)</td>
                                    <td className="text-right py-2 d-border-tr-gray  text-info">{data.market_cap ? ConvertToRupiah(data.market_cap) : <>&nbsp;</>}</td>
                                </tr> */}
                                <tr>
                                    <td className="bg-gray-tradding py-2 d-border-tr-black d-border-tr-black" width="30%" >Turnover(Bn)</td>
                                    <td className="text-right  py-2 d-border-tr-gray text-info">
                                        {mathCall.setRupiah(data.turnover || '', 2, <>&nbsp;</>)}
                                    </td>
                                    <td className="bg-gray-tradding py-2 d-border-tr-black"  width="30%">3 Month Avg Turnover(Bn)</td>
                                    <td className="text-right py-2 d-border-tr-gray text-info">
                                        {mathCall.setRupiah(data['3m_turnover'] || '', 2, <>&nbsp;</>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-gray-tradding py-2 d-border-tr-grey">Remark</td>
                                    <td colspan="3" className="py-2 d-border-tr-grey text-left">
                                        <ul>
                                            {/* <li></li> */}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

//============= 1.c Table of Corp Action =============
class TableCorpAction extends React.PureComponent{

    render() {
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <main>
                    <div className="container px-0 mx-0 col-sm-12">
                        <div className="bg-black-inactive card card-161-2">
                            <CorpActionAgGrid size={widthSize()} />
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

class CorpActionAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "type", headerName: "Type", sortable: true, resizable: true,
                    width: s=="s49"?275:s=="s50"?240:s=="s67"?220:s=="s75"?200:s=="s80"?190:s=="s90"?155:140,
                    minWidth: 140,
                    comparator: stringComparator,
                    suppressSizeToFit:true, lockVisible:true, lockPosition:true,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                    },},
                { field: "cumdate", headerName: "Cum Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?275:s=="s50"?250:s=="s67"?220:s=="s75"?200:s=="s80"?190:s=="s90"?155:140,
                    minWidth: 140,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    },},
                { field: "distdate", headerName: "Dist. Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?275:s=="s50"?250:s=="s67"?220:s=="s75"?210:s=="s80"?180:s=="s90"?160:140,
                    minWidth: 140,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-left grid-table d-border-aggrid-right f-12";
                    },},
                { field: "ratio", headerName: "Ratio", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?275:s=="s50"?250:s=="s67"?220:s=="s75"?210:s=="s70"?180:s=="s80"?168:s=="s90"?155:145,
                    minWidth: 145,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }, },
                { field: "exprice", headerName: "Ex. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?275:s=="s50"?250:s=="s67"?220:s=="s75"?210:s=="s70"?170:s=="s80"?168:145,
                    minWidth: 145,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12";
                    }, },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                { type: "CASH DIVIDEND"+s,
                    cumdate: "2018-04-17",
                    distdate: "2018-05-09",
                    ratio: "1.00 : 322.00",
                    exprice: "0.00" },
                { type: "CASH DIVIDEND",
                    cumdate: "2018-04-20",
                    distdate: "2018-05-12",
                    ratio: "1.00 : 322.00",
                    exprice: "0.00" },
                { type: "CASH DIVIDEND",
                    cumdate: "2018-04-19",
                    distdate: "2018-05-15",
                    ratio: "1.00 : 370.00",
                    exprice: "0.00" },
                { type: "CASH DIVIDEND",
                    cumdate: "2018-04-21",
                    distdate: "2018-05-15",
                    ratio: "1.00 : 370.00",
                    exprice: "0.00" },
                { type: "CASH DIVIDEND",
                    cumdate: "2018-04-22",
                    distdate: "2018-05-15",
                    ratio: "1.00 : 472.00",
                    exprice: "0.00" },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-161-2 ag-theme-balham-dark ag-striped-odd ag-header-border"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        // rowData={this.state.rowData}
                        rowData={this.props.corpAction}                        
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

//============= 1.5 Stock Financial Display =============
class StockFinancialStatement_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: 1,
        }
    }

    onChange = (e, type) => {
        if(type === "changeYear"){
            this.props.onChangeTextHandler("financialReport", "changeYear", e)
        }else{
            this.props.handleOnClick('stockPage', 'dropdownQuarter', e)
        }
    } 
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.onChangeTextHandler("financialReport","handleKeyDown")
        }
    }

    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{ 
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: BuyPage2,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    buttonClickSell = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{ 
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: SellPage,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', 'none'),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    render () {
        let p = this.props
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        let data = p.stockFinancial
        let code = strCall.code(this.props.codeSearchStockInfo)
        let selectedData = searchCode('selected', code, this.props.stocklistOptions)
        let stockData = this.props.iStockData
        let sStockSmry = this.props.sStockSmry
        let change = sStockSmry.change_price;

        return (
            <div className="bg-black-trading card card-75">
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" /> {/* websocket connection component*/}
                <main>
                    <div className="container-fluid f-12 card-527" style={{"fontFamily":"Segoe UI"}}>
                        <div className="py-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40 mt-2">
                                <div className="col-sm-3 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-2 px-0 mx-0">Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-10 text-left align-self-center">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter}
                                            isSearchable={true}
                                            maxMenuHeight={155}
                                            styles={customStyles}
                                            placeholder={<div>Search..</div>}
                                            options = {this.props.stocklistOptions} 
                                            onChange={(e)=>this.props.handleSearchCode('stockInfo', e.value)}
                                            className="stockPageSelect text-left"
                                            theme={this.selectSelectionTab}
                                            value={selectedData}
                                        />
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-6 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {stockData.stock_code !== undefined ? stockData.name : ""}
                                    </label>
                                    <label className="col-sm-3 f-13 f-xs-14 align-middle align-self-center px-2 text-left">
                                        Last <span className={change < 0 ? "text-danger" : (change > 0 ? "text-success" : "text-warning")}>
                                            { change !== undefined ? ConvertToRupiah(sStockSmry.close_price) :  ""}
                                        </span>
                                    </label>
                                    <label className={change < 0 ?"col-sm-3 text-danger f-13 f-xs-14 align-middle align-self-center px-0 text-left"
                                        :(change > 0 ? "col-sm-3 text-success f-13 f-xs-14 align-middle align-self-center px-0 text-left" 
                                        : "col-sm-3 text-warning f-13 f-xs-14 align-middle align-self-center px-0 text-left")}>
                                        {change !== undefined ? (change < 0 ? <i className="oi oi-caret-bottom"></i> : (change > 0 ? <i className="oi oi-caret-top"></i> : '')): ''}&nbsp;
                                        { change !== undefined ? ConvertToRupiah(change)+' ('+ConvertToRupiah(sStockSmry.change_price_pcts)+'%'+')' : ""}
                                    </label>
                                </div>
                                <div className="col-sm-3 align-self-center mx-0 px-0">
                                    <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-success" onClick={this.buttonClickSell}><span>Sell</span></button>
                                    <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-danger" onClick={this.buttonClickBuy}><span>Buy</span></button>
                                </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                <div className="col-md-7 pr-1 pl-0 py-2 card-482">
                                        <div className="row pl-4 h-48 f-livetrade">
                                            <div
                                                className={`col-sm-2 px-0 pt-3 text-center f-12
                                                ${data.tab == 1?"livetradeMenuActive":"livetradeMenu"}`}
                                                onClick={()=>p.handleOnClick('stockPage', 'tabQuarterYear', 1)}
                                                >
                                                <i className={data.tab == 1 ? "far fa-dot-circle" : "far fa-circle"}></i>
                                                &nbsp;&nbsp;&nbsp;
                                                Quarter
                                            </div>
                                            <div
                                                className={`col-sm-2 px-0 pt-3 text-center f-12
                                                ${data.tab == 2?"livetradeMenuActive":"livetradeMenu"}`}
                                                onClick={()=>p.handleOnClick('stockPage', 'tabQuarterYear', 2)}

                                                >
                                                <i className={data.tab == 2 ? "far fa-dot-circle" : "far fa-circle"}></i>
                                                &nbsp;
                                                Year
                                            </div>
                                            <div className={`col-sm-2 ${data.tab == 1?"d-block":"d-none"}`}>
                                                <Dropdown
                                                    placeholder='Choose'
                                                    search selection
                                                    options={yearOptions}
                                                    defaultValue={data.valQtr}
                                                    className="col-sm-12 f-12"
                                                    onChange={(e, data) => this.onChange(data.value, "changeQuarter")}                                                 
                                                    />
                                            </div>
                                            <div className={`col-sm-2 ${data.tab == 2?"d-block":"d-none"}`}>
                                                <input type="text" className="form-control f-12"
                                                    value={p.stockFinancialyear} style={{borderRadius: "0px", height: "32.84px"}}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={(e)=> this.onChange(e.target.value, "changeYear")} 
                                                    onKeyDown={this.handleKeyDown}/>
                                            </div>
                                            <div className={"col-sm-5"}></div>
                                            <div className={`col-sm-1 pl-0 pr-0`}>
                                                <Popup content='Refresh' position='top center' trigger={
                                                    <button
                                                        className={`btn btn-primary btn-refresh-2-right`}
                                                        style={{"font-size":"12px","width":"38px"}}
                                                        onClick={()=>this.props.handleOnClick('refreshPage','stockFinancialReport','none')}>
                                                        <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                                    </button>
                                                } />
                                            </div>
                                            <div className={"col-sm-12 pl-0 pt-2 card-444"}>
                                                <table className="table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center f-12" style={{height: "100%"}}>
                                                    <tbody>
                                                        {
                                                            p.stockFinancialReport.map((item, id) => {
                                                                var bgtd1 = "no-wrap d-border-tr-black py-0",  bgtd2 = "no-wrap d-border-tr-black py-0";
                                                                if(id == 0 ){
                                                                    bgtd1 = "d-border-table d-border-tr-grey-all py-0 text-center";
                                                                    bgtd2 = "d-border-table d-border-tr-grey-all py-0 text-center";
                                                                }else if(id == 1){
                                                                    bgtd1 = "d-border-table td-gradient-yellow py-0 text-center"; 
                                                                    bgtd2 = "d-border-table td-gradient-yellow py-0 text-center";
                                                                }
                                                                else if(id == 2){
                                                                    bgtd1 = "d-border-table d-border-tr-grey-all py-0 text-center"; 
                                                                    bgtd2 = "d-border-table d-border-tr-grey-all py-0 text-center";
                                                                }else if(id >= 3 && item.label != "colspan"){
                                                                    if(id % 2 == 0){
                                                                        bgtd1 = "d-border-table d-border-tr-grey-all py-0 even-td"; 
                                                                        bgtd2 = "d-border-table d-border-tr-grey-all py-0 text-right even-td";
                                                                    }else{
                                                                        bgtd1 = "d-border-table d-border-tr-grey-all py-0 even-td-blue"; 
                                                                        bgtd2 = "d-border-table d-border-tr-grey-all py-0 text-right";                                                                        
                                                                    }
                                                                }
                                                                return(<tr key={"sfr"+id} className={item.label == "Last Statement" || item.label == "Quarter/Year" 
                                                                    || item.label ==  "colspan" ? "d-border-table bg-tableheader" : "d-border-table"}>
                                                                    <td className={bgtd1}>{item.label == "colspan" ? "\xa0" : item.label}</td>
                                                                    <td className={bgtd2}>
                                                                        {item.label == "Last Statement" || item.label == "Quarter/Year" || item.label ==  "colspan" ? 
                                                                            item.v1 : ConvertToRupiah(item.v1)}
                                                                    </td>
                                                                    <td className={bgtd2}>
                                                                        {item.label == "Last Statement" || item.label == "Quarter/Year" || item.label ==  "colspan" ? 
                                                                            item.v2 : ConvertToRupiah(item.v2)}
                                                                    </td>
                                                                    <td className={bgtd2}>
                                                                        {item.label == "Last Statement" || item.label == "Quarter/Year" || item.label ==  "colspan" ? 
                                                                            item.v3 : ConvertToRupiah(item.v3)}
                                                                    </td>
                                                                    <td className={bgtd2}>
                                                                        {item.label == "Last Statement" || item.label == "Quarter/Year" || item.label ==  "colspan" ? 
                                                                            item.v4: ConvertToRupiah(item.v4)}
                                                                    </td>
                                                                </tr>)
                                                            })
                                                        }
                                                        {/* <tr className="bg-tableheader">
                                                            <td className="d-border-tr-grey-all py-0">Last Statement</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">Book Value</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">EPS</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">PER</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">PBV</td>
                                                        </tr>
                                                        <tr >
                                                            <td className="d-border-tr-gray-all py-0 text-center">1</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">1,754</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">78</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">6,9</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">1,3</td>
                                                        </tr>
                                                        <tr className="bg-tableheader">
                                                            <td className="d-border-tr-gray-all py-0 text-center">Quarter/Year</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">1st Qty 2020</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">2nd Qty 2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">3rd Qty 2018</td>
                                                            <td className="d-border-tr-gray-all py-0 text-center">4th Qty 2017</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">Sales Revenue</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">383,0</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">0</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">0</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">0</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td">Gross Profit</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">EBITDA</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td">NET Income</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">EPS</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr className="bg-tableheader">
                                                            <td colSpan="5" className="no-wrap d-border-tr-black py-0">&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">Total Asset</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td">Total Liabilities</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">Total Equity</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr className="bg-tableheader">
                                                            <td colSpan="5" className="no-wrap d-border-tr-black py-0">&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">PER</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td">PBV</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">ROA</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td">ROE</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right even-td">24/6/2019</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="d-border-tr-gray-all py-0 even-td-blue">Book Value</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">22/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">23/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                            <td className="d-border-tr-gray-all py-0 text-right">24/6/2019</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-5 px-1 py-0 card-482">
                                    <TableInfoTransaction lotshare="stockInfoBuy" card="card-310-is" card2="card-140" />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

//============= 2. Stock Watchlist Display =============
// a.Buy, b.Sell
const StockWatchlist = (props) => {
    return(
        <div>
            {/* <BIPSAppProvider> */}
            <div className="row col-sm-12 card-527 px-2 mx-0 pt-2 pb-0">
                <div className="col-sm-4 px-0 mx-0 card-514">
                    {/*<AppModal/>*/}
                    <TableStockWatchlist/>
                </div>
                <div className="col-sm-8 pl-3 pr-0 mx-0 card-514">
                    <div className="col-sm-12 px-0 pt-0">
                        <BuyPageOld/>
                    </div>
                </div>
            </div>
            {/* </BIPSAppProvider> */}
        </div>
    );
}

class BuyPageOld_Base extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state= {
            activeTab: 1,
        };
    }
    componentDidMount(){
        ResizeResponsive();
    }
    toggle(no){
        this.setState({
            activeTab: no,
        });
    }

    clickBuy=()=>{
        this.props.handleOnClick('stockPage', 'buyStockWatchlist', 'none')
        this.toggle(1);
    }

    clickSell=()=>{
        this.props.handleOnClick('stockPage', 'sellStockWatchlist', 'none')
        this.toggle(2);
    }

    render(){
        const swapContent = () => {
            if(this.state.activeTab === 1){
                return <FormBuy idPrice="stockBuyPrice" part="stockInfo" idVol="stockBuyVol" idValue="stockBuyValue" columnSm="col-sm-12" part="stock" />
            }else{
                return <FormSell idPrice="stockSellPrice" part="stockInfo" idVol="stockSellVol" idValue="stockSellValue" columnSm="col-sm-12" part="stock"/>
            }
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                    <TableInfoTransaction lotshare="buyPage" card="card-350"/>
                    </div>
                    <div className={this.state.activeTab === 1 ? "col-sm-6 mt-0 bg-buy pt-0 pb-0 px-0 card-520 d-border" : "col-sm-6 mt-0 bg-sell pt-0 pb-0 px-0 card-520 d-border"}>
                        <div className="cssmenumodal bg-dark-grey pb-0 col-sm-12 mx-0 px-0 h-33 mb-1">
                            <ul>
                                <li className={ (this.state.activeTab === 1)  ? 'd-border-bottom active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' 
                                    : 'd-border-bottom text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' }
                                    onClick={this.clickBuy}><a className="pt-4 pb-2"><span className="f-12">
                                    &nbsp; Buy
                                </span></a></li>
                                <li className={ (this.state.activeTab === 2) ? 'd-border-bottom active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' 
                                    : 'd-border-bottom text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' }
                                    onClick={this.clickSell}><a className="pt-4 pb-2"><span className="f-12">
                                    &nbsp; Sell
                                </span></a></li>
                            </ul>
                        </div>
                        {swapContent()}
                    </div>
                </div>
            </>
        );
    }

}

class BuyPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        ResizeResponsive();
    }
    render(){
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="stockInfoBuy2" card="card-356" />
                    </div>
                    <div className={"col-sm-6 mt-0 d-border-active bg-buy card-520 d-border"}>
                        <FormBuy
                            idPrice="stockBuyPrice"
                            part="stockInfo"
                            idVol="stockBuyVol"
                            idValue="stockBuyValue"
                            columnSm="col-sm-12"
                            part="stock"
                        />
                    </div>
                </div>
            </>
        );
    }

}

class BuyPage2_Base extends React.PureComponent{

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        ResizeResponsive();
    }
    render(){
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350" />
                    </div>
                    <div className={"col-sm-6 mt-0 d-border-active bg-buy card-520 d-border"}>
                        <FormBuy
                            idPrice="stockBuyPrice"
                            part="stockInfo"
                            idVol="stockBuyVol"
                            idValue="stockBuyValue"
                            columnSm="col-sm-12"
                            part="stock"
                        />
                    </div>
                </div>
            </>
        );
    }

}

class SellPage_Base extends React.PureComponent{
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        ResizeResponsive();
    }
    render(){
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        return(
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <div className="col-sm-12 px-0 py-0 mx-0 row">
                    <div className="col-sm-6 pr-3 pl-0 f-12">
                        <TableInfoTransaction lotshare="buyPage" card="card-350" />
                    </div>
                    <div className="col-sm-6 mt-0 d-border-active bg-sell card-520 d-border">
                        <FormSell
                            idPrice="stockSellPrice"
                            part="stockInfo"
                            idVol="stockSellVol"
                            idValue="stockSellValue"
                            columnSm="col-sm-12"
                            part={"stock"}/>
                    </div>
                </div>
            </>
        );
    }

}

//Table Stock Watchlist
class TableStockWatchlist extends React.PureComponent{
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickAmendRegister = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'tiny',
            contentClass: RegisterAmendModal,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }
    render(){        
        return(
            <>
                <WSConnectionAction ref="wsAction" /> {/* websocket connection component */}
                <div className="bg-black-trading f-12">
                    <AppFrameAction ref="frameAction" />
                    <div className="px-0 py-0">
                        <StockWatchlistAgGrid size={widthSize()} />
                    </div>
                </div>
            </>
        );
    }
}

class StockWatchlistAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 85, minWidth: 85, lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    // sort: "asc",
                    cellClass : function (params) {
                        var code = params.data.code
                        if(code === self.props.codeBlink){
                            // let csss=  "text-center grid-table d-border-aggrid-right  d-bold text-primary locked-col locked-visible click-pointer";
                        //     setTimeout(()=>{
                        //         csss = "text-center grid-table d-border-aggrid-right f-12 locked-col locked-visible click-pointer";
                        //     },100);
                            return "text-center grid-table d-border-aggrid-right  d-bold text-primary locked-col locked-visible click-pointer";
                        }else{
                            return "text-center grid-table d-border-aggrid-right f-12 locked-col locked-visible click-pointer";
                        }
                    },
                    cellRenderer: function (params) {
                        var eDiv = document.createElement('div');
                        // if(params.data.code == ''){
                        //     eDiv.innerHTML = '';
                        // }else{
                            eDiv.innerHTML = 
                                '<div class="searchWatchlist">'+params.data.code+'</div>';
                            var bButton = eDiv.querySelectorAll('.searchWatchlist')[0];

                            bButton.addEventListener('click', ()=>self.props.handleOnClick('stockPage', 'stockWathlist', params.data.code));
                        // }
                        return eDiv;
                    },},
                { field: "price", headerName: "Price", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 93, minWidth: 93,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        var code = params.data.code
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right  grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    }},
                { field: "change", headerName: "Change", sortable: false, resizable: true, filter: "agTextColumnFilter",
                    width: 90, minWidth: 90,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right  grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    } },
                { field: "persen", headerName: "(%)", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 60, minWidth: 50,
                    valueFormatter:currencyFormatter, cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right  grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    } },
                { field: "tvol", headerName: "T. Vol", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 85, minWidth: 85,
                    valueFormatter:currencyFormatter,
                    cellRenderer: "agAnimateShowChangeCellRenderer",
                    cellClass : function (params) {
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right  grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    } },
                { field: "tval", headerName: "T. Val", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 85, minWidth: 85,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        var change = params.data.change;
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);

                    } },
                { field: "AvgPrice", headerName: "Avg. Price", sortable: false, resizable: true, filter: "agNumberColumnFilter",
                    width: 180, minWidth: 100,
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        // return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                        var change = params.data.change;
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    },
                },
                { field: "AccForVol", headerName: "Accumulate Foreign Vol", sortable: false, resizable: true,
                    width: 180, minWidth: 180, filter: "agNumberColumnFilter",
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        // return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                        var change = params.data.change;
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    },    
                },
                { field: "AccForVal", headerName: "Accumulate Foreign Val", sortable: false, resizable: true,
                    width: 180, minWidth: 180, filter: "agNumberColumnFilter",
                    valueFormatter:currencyFormatter,
                    cellClass : function (params) {
                        // return "text-right grid-table d-border-aggrid-right f-12 locked-col locked-visible";
                        var change = params.data.change;
                        var change = params.data.change;
                        var code = params.data.code;
                        function c(change, code){
                            if(change < 0){
                                if(code === self.props.codeBlink){
                                    return  "text-right  grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return  "text-danger text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else if(change > 0 ){
                                if(code === self.props.codeBlink){
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-success text-right grid-table d-border-aggrid-right f-12"
                                }
                            } else { 
                                if(code === self.props.codeBlink){                                
                                    return "text-right grid-table d-border-aggrid-right d-bold text-primary"
                                }else{
                                    return "text-warning text-right grid-table d-border-aggrid-right f-12"
                                }
                            }
                        }
                        return c(change, code);
                    },
                },
            ],
            defaultColDef: {
                sortable: false,
                filter: false,
                enableCellChangeFlash: true,
            },
            getRowHeight : function(params){
                return 27.5;
            },
            getRowStyle: function(params) {
                if (params.data.code == self.props.codeBlink) {
                    return {
                        'background-color': 'var(--warna-bg-data-orange)',
                    };
                }
                return null;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
            pages: 1,
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 10);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    changePage=(type, e)=>{
        if(type === 'previous'){
            this.props.handleOnClick('stockPage', 'stockWatchlistPageChange',e-1)
        }else if(type === "active"){
            this.props.handleOnClick('stockPage', 'stockWatchlistPageChange',e)
        }else{
            this.props.handleOnClick('stockPage', 'stockWatchlistPageChange',e+1)
        }
    }   

    render() {         
        var p = this.props
        const pagination = () => {
            let paginationtext = [];
            paginationtext.push(<button key="prevpgstc"
                className={`btn btn-sm py-1 px-1 mr-1 btn-page ${(p.thisPageWatchlist === 0)?"disabled":""}`}
                onClick={p.thisPageWatchlist !== 0 ? () => this.changePage("previous",p.thisPageWatchlist) : ""}  
            >   &nbsp;&nbsp;
                <i className={"glyphicon glyphicon-chevron-left"}></i>
                &nbsp;&nbsp;
            </button>);
            for (let i = 0; i <p.sumPageWatchlist; i++) {
                paginationtext.push(<button key={"pgstc"+i}
                    className={`btn btn-sm py-1 px-1 mr-1 btn-page ${(p.thisPageWatchlist === i)?"active":""}`}
                    onClick={() => this.changePage("active",i)}
                >
                    &nbsp;&nbsp;
                        {i+1}
                    &nbsp;&nbsp;
                </button>);
            }
            paginationtext.push(<button key="nextpgstc"
                className={`btn btn-sm py-1 px-1 mr-1 btn-page ${(p.thisPageWatchlist === p.sumPageWatchlist-1)?"disabled":""}`}
                onClick={ p.thisPageWatchlist !== p.sumPageWatchlist-1 ?  () => this.changePage("next",p.thisPageWatchlist) : ""}                  
            >   &nbsp;&nbsp;
                <i className={"glyphicon glyphicon-chevron-right"}></i>
                &nbsp;&nbsp;
    
            </button>);
            return paginationtext;
        }  
        if(p.watchlistCode.length){
            var data = GetStockWathclist('stockPage',this.props.dataWatchlist)
        }else{
            var data = d.stockWatchlist
        }
        
        return (
            <>
                <div
                    className="card-watchlistcust ag-theme-balham-dark ag-header-border d-border ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={data}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        getRowStyle={this.state.getRowStyle}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>

                <div className={"text-center mt-2"}>
                    {pagination()}
                </div>
            </>
        );
    }
}

//============= 3. Stock Trade History Display =============
class StockHistoryPage_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            // tabNumber: 1,  
        }
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            sd.setDate(sd.getDate() - 1);
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartSH').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDate").click(); 
            });

            $('#datepickerendSH').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : ed,
                todayHighlight: true,
                // todayBtn: "linked",
            })
            .on('changeDate', function(e) {
                document.getElementById("handleDate").click(); 
            });
        });

        $("#btn-clear-date").click(function () {
            $(".date-clear").datepicker("clearDates");
        })
    }

    handleChangeDate=()=>{
        let nStartDate = this.refs.datepickerstartSH.value
        let nEndDate =  this.refs.datepickerendSH.value
        if(nStartDate != "" && nEndDate == ""){
            this.refs.datepickerendSH.value = GetTimeForDateTicker(0,0)
            nEndDate = GetTimeForDateTicker(0,0)
            $("#datepickerendSH").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start:nStartDate,to:nEndDate}
        this.props.handleOnClick('datepicker','stocktradehistory', nDatepicker)
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    // Button Buy & Sell
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{ 
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: BuyPage2,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', this.props.codeSearchStockInfo),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    buttonClickSell = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: SellPage,
            onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', this.props.codeSearchStockInfo),
            onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
        })
    }

    render () {
        const tabActive = (props) => {
            if (this.props.tabStockTradeHistory == props) {
                return "col-sm-3 click-pointer d-border-right text-center active";
            } else {
                return "col-sm-3 click-pointer d-border-right text-center"
            }
        }
        const changeActiveGridHistory = () => {
            if(this.props.tabStockTradeHistory === 1){
                return <HistoryBrokerAgGridThird  size={widthSize()}/>
            } else if(this.props.tabStockTradeHistory === 2){
                return <HistoryBrokerAgGrid/>
            }else  if(this.props.tabStockTradeHistory === 3){
                return <HistoryBrokerAgGridSecond/>
            }else{
                return <HistoryBrokerChart/>
            }
        }
        
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };

        var p=this.props
        // var code = this.props.codeSearchStockTradeHistory
        var code = strCall.code(this.props.codeSearchStockInfo)
        let change = p.stockTradeHistorySMRY.change_price
        let selectedData = searchCode('selected', code,this.props.stocklistOptions)        
        let nameCode = selectedData != undefined ? selectedData[0].saham : ""
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    <div className="container-fluid f-12 card-520">
                        <div className="py-2 card-520">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-3 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-2 px-0 mx-0">Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-10 text-left align-self-center">
                                        {/* <Select maxMenuHeight={75} styles={customStyles} placeholder={<div>Search..</div>} options={stockOptions} 
                                        className="stockPageSelect" theme={this.selectSelectionTab}                                        
                                        value={selectedData} onChange={(e)=>this.props.handleSearchCode('stockTradeHistory',e.value)} /> */}
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={this.props.stocklistOptions} 
                                            className="stockPageSelect" theme={this.selectSelectionTab}
                                            value={selectedData} onChange={(e)=>this.props.handleSearchCode('stockTradeHistory',e.value)}/>
                                    
                                    </div>
                                    {/*<div className="col-sm-2 text-left align-self-center px-2"><i className="fa fa-search fa-2x click-pointer text-dark"></i></div>*/}
                                    {/*<Input defaultValue='Arga Argo Lestari Tbk.' placeholder='Name' size='small' className="col-sm-3 align-self-center"/>*/}
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-12 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {nameCode}
                                    </label>
                                </div>
                                <div className="col-sm-3 row mx-0 px-0 align-self-center">
                                    <div className="col-sm-12 align-self-center mx-0 px-0">
                                        <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-success" onClick={this.buttonClickSell}><span>Sell</span></button>
                                        <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-danger" onClick={this.buttonClickBuy}><span>Buy</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 mx-0 mt-3 col-sm-12 mb-3 row h-40">
                                <div className="col-sm-4 px-0 mx-0">
                                    <span onClick={this.handleChangeDate} id={"handleDate"}></span>   

                                    <div className="input-group h-35" style={{"z-index":0}}>
                                        <span className="input-group-addon h-35 bg-tableheader">Start</span>
                                        <input placeholder='dd/mm/yy' ref="datepickerstartSH" id="datepickerstartSH" 
                                            className="form-control date-clear col-sm-12 pl-0 pr-0 text-center align-self-center"
                                            value={p.rangeStockTradeHistory.start}
                                        />
                                        <span className="input-group-addon h-35 bg-tableheader">
                                            <span className="fa fa-calendar-alt"></span>
                                        </span>
                                        <span className="input-group-addon bg-tableheader">to</span>
                                        <input placeholder='dd/mm/yy' ref="datepickerendSH" id="datepickerendSH" 
                                            className="form-control col-sm-12 pl-0 pr-0 text-center date-clear align-self-center "
                                            value={p.rangeStockTradeHistory.to} 
                                            />

                                        <span className="input-group-addon h-35 bg-tableheader">
                                            <span className="fa fa-calendar-alt"></span>
                                        </span>
                                        <span id="btn-clear-date" className="input-group-addon bg-gold click-pointer hover-gold h-35">
                                            <span className="ion ion-ios-close"></span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-1 pl-2 pr-0 mx-0">
                                    <Popup content='Refresh' position='top center' trigger={
                                        <button
                                            className={`btn btn-primary`}
                                            style={{"font-size":"14px","width":"38px","margin-top":"2px"}}
                                            onClick={()=>this.props.handleOnClick('refreshPage','stocktradehistory','none')}>
                                            <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                        </button>
                                    }/>
                                </div>
                                <div className="col-sm-7 pl-0 pr-0 mx-0">
                                    <TableBS borderless size="sm" className="bg-black-trading mb-0 h-35">
                                        <thead></thead>
                                        <tbody className="d-border-top d-border-bottom">
                                        <tr>
                                            <td>
                                                Last                                                 
                                                <span className={ColorLabel(change)}> {ConvertToRupiah(p.stockTradeHistorySMRY.close_price)}</span> 
                                            </td>
                                            <td>
                                                Change(%) &nbsp;                                                
                                                <span className={ColorLabel(change)}>
                                                    <i className={IconChange(change,"minus")}></i> 
                                                    {change ? change+" ("+p.stockTradeHistorySMRY.change_price_pcts+")" : ''} 
                                                </span>
                                            </td>
                                            <td>
                                                High 
                                                <span className={ColorLabel(change)}> {ConvertToRupiah(p.stockTradeHistorySMRY.high_price)}</span>
                                            </td>
                                            <td>
                                                Low 
                                                <span className={ColorLabel(change)}> {ConvertToRupiah(p.stockTradeHistorySMRY.low_price)}</span>
                                            </td>
                                            <td>
                                                T.Vol 
                                                <span className={ColorLabel(change)}> &nbsp;
                                                    { p.stockTradeHistorySMRY.traded_vol == 0 ? 0 : ConvertToRupiah(ShareToLot(p.stockTradeHistorySMRY.traded_vol))}
                                                </span>
                                            </td>
                                            <td>
                                                Value
                                                <span className={ColorLabel(change)}> &nbsp;
                                                    { ConvertToRupiah(p.stockTradeHistorySMRY.traded_val)}
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </TableBS>
                                </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                <div className="col-sm-8 px-1 py-2">
                                    {/*Zaky*/}
                                    {/*Add menu tab*/}
                                    <div className="cssmenu d-border-bottom d-border-top d-border-left mb-2 small h-30">
                                        <ul className="ul-menu h-27">
                                            <li name="stockDaily"
                                                className={tabActive(1)} onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistory',1)}>
                                                <a className="linkCustomStockTab h-27">
                                                    <span
                                                        className="f-12">DAILY</span></a></li>
                                            <li name="stockPage"
                                                className={tabActive(2)} onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistory',2)}>
                                                <a className="linkCustomStockTab h-27">
                                                    <span
                                                        className="f-12" >BROKER SUMMARY</span></a></li>
                                            <li name="stockBrokerChart" 
                                                className={tabActive(4)} onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistory',4)}>
                                                <a className="linkCustomStockTab h-27">
                                                    <span
                                                        className="f-12" >BROKER CHART</span></a></li>
                                            <li name="stockWatchlistPage"
                                                className={tabActive(3)} onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistory',3)}>
                                                <a className="linkCustomStockTab h-27">
                                                    <span
                                                        className="f-12">FOREIGN NET</span></a></li>

                                        </ul>
                                    </div>
                                    <div className="bg-trading-gray">
                                        {/*Zaky*/}
                                        {/*switch grid by state*/}
                                        {changeActiveGridHistory()}
                                    </div>
                                </div>

                                <div className="col-sm-4 px-1 pt-2 pb-0">
                                    <div className="bg-trading-gray" style={{marginBottom : "10px"}}>
                                        <HistoryPriceAgGrid type="tradeHistory" changethis={this.props.tabStockTradeHistory == "4" ? "active" : "none"}  size={widthSize()} />
                                    </div>
                                    <div
                                        className="bg-trading-gray mb-3"
                                        style={{"display": (p.tabStockTradeHistory == 4)?"none":"block"}}
                                    >
                                        <HistoryBuyerAgGrid size={widthSize()}/>
                                    </div>
                                    <div
                                        className="bg-trading-gray mb-3"
                                        style={{"display": (p.tabStockTradeHistory == 4)?"none":"block"}}
                                    >
                                        <HistorySellerAgGrid size={widthSize()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
// 1. Daily Table - DAILY
class HistoryBrokerAgGridThird_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: 'date', headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?110:s=="s67"?105:s=="s75"?100:s=="s80"?90:s=="s85"?80:s=="s90"?80:s=="s100"?75:75,
                    minWidth: 100,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    sort: "desc",
                    comparator: comparatorType.text,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'open_price', headerName: "Open", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?110:s=="s67"?105:s=="s75"?100:s=="s80"?90:s=="s85"?80:s=="s90"?80:s=="s100"?75:75,
                    minWidth: 100,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'high_price', headerName: "High", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?110:s=="s67"?105:s=="s75"?100:s=="s80"?90:s=="s85"?80:s=="s90"?80:s=="s100"?75:75,
                    minWidth: 100,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'low_price', headerName: "Low", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?110:s=="s67"?105:s=="s75"?100:s=="s80"?90:s=="s85"?80:s=="s90"?80:s=="s100"?75:75,
                    minWidth: 100,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'close_price', headerName: "Close", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?130:s=="s50"?110:s=="s67"?105:s=="s75"?100:s=="s80"?90:s=="s85"?80:s=="s90"?80:s=="s100"?75:75,
                    minWidth: 100,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.date && 
                        `text-right grid-table d-border-aggrid-right f-12 locked-position locked-visible ${colorText(data.change_price)}`
                    },
                { field: "updown", headerName: "", resizable: true,
                    width: 35, minWidth: 35,
                    cellClass: ({ data }) => data.date && `text-right grid-table d-border-aggrid-right f-12 ${colorText(data.change_price)}`,
                    cellRenderer: 'updownRenderer'
                    },
                { field: "change_price", headerName: "Change", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?120:s=="s50"?95:s=="s67"?90:85, minWidth: 85,
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.date && `text-right grid-table f-12 d-border-aggrid-right ${colorText(data.change_price)}`,
                    },
                { field: "change_price_pcts", headerName: "%", resizable: true, filter: "agNumberColumnFilter",
                    width: s=="s49"?80:s=="s50"?70:s=="s67"?65:60, minWidth: 60,
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.date && `text-right grid-table f-12 d-border-aggrid-right ${colorText(data.change_price)}`,
                    },
                { field: 'traded_vol', headerName: "Volume", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?180:s=="s67"?165:s=="s75"?160:s=="s80"?130:s=="s85"?105:s=="s90"?100:s=="s100"?90:90,
                    minWidth: 90,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.date && `text-right grid-table f-12 d-border-aggrid-right ${colorText(data.change_price)}`,
                    },
                { field: 'traded_val', headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?240:s=="s50"?230:s=="s67"?200:s=="s75"?185:s=="s80"?150:s=="s85"?120:s=="s90"?110:s=="s100"?109:109,
                    minWidth: 109,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: ({ data }) => data.date && `text-right grid-table f-12 d-border-aggrid-right ${colorText(data.change_price)}`,
                    },
                { field: 'traded_freq', headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?160:s=="s75"?140:s=="s80"?110:s=="s85"?105:s=="s90"?80:s=="s100"?70:70,
                    minWidth: 70,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                updownRenderer: ({ data }) => data.date ? <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className={updownIcon(data.change_price)}></i>
                    </> : "",
            },
            getRowHeight : function(params){
                return 27.5;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);
        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-372 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.stockTradeHistoryMain}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// 2.Broker Table - BROKER SUMMARY
class HistoryBrokerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: 'broker', headerName: "Broker", sortable: true, resizable: true,
                    width: 84, minWidth: 84,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    comparator: comparatorType.text,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'b_vol', headerName: "Buy Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 110, minWidth: 110,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'b_val', headerName: "Buy Val (T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'b_avg', headerName: "Avg. Buy", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 4),
                    comparator: comparatorType.integer,   
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 "
                    },
                { field: 's_vol', headerName: "Sell Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,   
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 's_val', headerName: "Sell Val (T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,    
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 "
                    },
                { field: 's_avg', headerName: "Avg. Sell", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 4),
                    comparator: comparatorType.integer,    
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 "
                    },
                { field: 'n_vol', headerName: "Net Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,  
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 "
                    },
                { field: 'n_val', headerName: "Net Val (T)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 120, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,   
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 "
                    },
                // { field: 'avgnet', headerName: "Avg. Net", sortable: true, filter: "agTextColumnFilter", resizable: true,
                //     width: 120, minWidth: 120,
                //     cellClass : function (params) {
                //         return "text-right grid-table d-border-aggrid-right f-12 ";
                //     }, },   
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27.5;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                {/*Zaky*/}
                {/*update ukuran card*/}
                <div
                    className="card card-372 ag-theme-balham-dark yellow-hover ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeHistoryMain}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// 3. Broker Chart
class HistoryBrokerChart_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            heightValue: "335px",
        }
    }
    componentDidMount(){
        $('#container-bar').css('height', '100%');
    }

    changesize(){
        var newval = $("#heightValue-trigger").val();
        var temp = String(newval+"px");
        console.log(newval);
        this.setState({heightValue: temp});
    }

    render() {
        let tab = this.props.tabStockTradeHistoryChart
        // Chart
        let chart = anychart.bar();
        let credits = chart.credits();
        credits.enabled(false);        
        chart.title(tab == 1 ? "TOP 10 BUYER" : tab == 2 ? "TOP 10 SELLER" : "TOP 10 NET");
        // set container id for the chart
        chart.container("container-barchart");
         // set the titles of the axes
        chart.xAxis().title("Broker");
        chart.yAxis().title("Volume(Lot)");
        // set data (broker, volume, value, freq)
        var data = anychart.data.set(this.props.stockTradeHistoryChart);
        // set chart (x , value)=> (broker, volume)
        var data1 = data.mapAs({x: 0, value: 1, freq: 3, volume: 2,});
        var series = chart.bar(data1);
        series.name("Series");
        chart.tooltip().useHtml(true);
        var tooltip = chart.tooltip();
        tooltip.positionMode("point");
        let nformat = tab == 3 ? "" : "Freq: <b>{%freq}</b>"
        tooltip.format("Volume: <b>{%value}</b><br>Value: <b>{%volume}</b><br>"+nformat);
        
        return (
            <>
                <div
                    className="card card-372 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{width: 'auto' }}>
                    <div className="cssmenu d-border-bottom d-border-top d-border-left mb-2 small h-30">
                        <ul className="ul-menu h-27">
                            <li name="stockDaily"
                                className={(tab == 1) ? "col-sm-4 click-pointer d-border-right text-center active": 
                                "col-sm-4 click-pointer d-border-right text-center"}
                                onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistoryChart',1)}>
                                <a className="linkCustomStockTab h-27"> <span className="f-12">TOP BUYER</span></a>
                            </li>
                            <li name="stockPage"
                                className={(tab == 2) ? "col-sm-4 click-pointer d-border-right text-center active":
                                    "col-sm-4 click-pointer d-border-right text-center"}
                                onClick={()=>this.props.handleOnClick('stockPage','tabStockTradeHistoryChart',2)}>
                                <a className="linkCustomStockTab h-27"><span className="f-12">TOP SELLER</span></a>
                            </li>
                            <li name="stockBrokerChart"
                                className={(tab == 3) ? "col-sm-4 click-pointer d-border-right text-center active":
                                    "col-sm-4 click-pointer d-border-right text-center" }
                                onClick={()=> this.props.handleOnClick('stockPage','tabStockTradeHistoryChart',3)}>
                                <a className="linkCustomStockTab h-27"><span className="f-12">TOP NET</span></a>
                            </li>
                        </ul>
                    </div>
                    <input type="hidden" id={"heightValue-trigger"} onClick={()=>this.changesize()}/>
                    <AnyChart id={"container-barchart"} instance={chart} type='bar' legend="true" height={this.state.heightValue} />
                </div>
            </>
        );
    }
}
// 4. Foreign Table - FOREIGN NET
class HistoryBrokerAgGridSecond_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            columnDefs: [
                { field: 'date', headerName: "Date", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: 95, minWidth: 95,
                    lockVisible:true, lockPosition:true, suppressSizeToFit:true,
                    sort:'desc',
                    comparator: comparatorType.text,
                    cellClass: () => "text-center grid-table d-border-aggrid-right f-12 locked-position locked-visible"
                    },
                { field: 'foreign_buy_val', headerName: "Foreign Buy Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 95, minWidth: 135,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'foreign_buy_vol', headerName: "Foreign Buy Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 135, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'foreign_sell_val', headerName: "Foreign Sell Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 95, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'foreign_sell_vol', headerName: "Foreign Sell Vol(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 140, minWidth: 135,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
                { field: 'foreign_net_val', headerName: "Foreign Net Val", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: 95, minWidth: 120,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 0, 0),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12"
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27.5;
            },
            rowData: [
                {
                    date: "2019-02-01",
                    fBuyVal: 100,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-02",
                    fBuyVal: 200,
                    fBuyVol: 300,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-02",
                    fBuyVal: 200,
                    fBuyVol: 300,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-02",
                    fBuyVal: 200,
                    fBuyVol: 300,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-02",
                    fBuyVal: 200,
                    fBuyVol: 300,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-03",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 500,
                    fSellVol: 150,
                    fNetVal: 100,
                },{
                    date: "2019-02-04",
                    fBuyVal: 200,
                    fBuyVol: 100,
                    fSellVal: 100,
                    fSellVol: 150,
                    fNetVal: 100,
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);
        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-372 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeHistoryMain}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// stock trade price Table
class HistoryPriceAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;        
        this.state = {
            columnDefs: [
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105: 95, minWidth: 95,
                    suppressSizeToFit:true, lockPosition:true, lockVisible:true,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success locked-visible locked-col"
                    },
                { field: "freq", headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
                { field: "vol", headerName: "Vol", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "val", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s85"?113:s=="s90"?110:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className={
                        this.props.type === "tradeHistory" ?
                                this.props.tabStockTradeHistory == 4 ?
                                    "card card-406 ag-theme-balham-dark ag-header-border-gray ag-striped-odd" :
                                    "card card-129 ag-theme-balham-dark ag-header-border-gray ag-striped-odd" :
                        "card card-215 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"}
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeHistoryPrice}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// stock trade Buyer Table
class HistoryBuyerAgGrid_Base extends React.PureComponent {                    
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "buyer_code", headerName: "Top Buyer", sortable: true, resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105: 95, minWidth: 95,
                    suppressSizeToFit:true, lockVisible:true, lockPosition:true,
                    comparator: comparatorType.text,
                    cellClass: () => 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible'
                    },
                { field: "vol", headerName: "Volume", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
                { field: "freq", headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
                { field: "avg", headerName: "Avg", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s85"?113:s=="s90"?110:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 4),
                    comparator: comparatorType.integer,
                    cellClass: () =>  "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-129 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeHistoryBuy}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// stock trade Seller Table
class HistorySellerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "seller_code", headerName: "Top Seller", sortable: true, resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105: 95, minWidth: 95,
                    suppressSizeToFit:true, lockPosition:true, lockVisible:true,
                    comparator: comparatorType.text,
                    cellClass: () => 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible'
                    },
                { field: "vol", headerName: "Volume", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?145:s=="s80"?115:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
                { field: "freq", headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s85"?110:s=="s90"?105:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
                { field: "avg", headerName: "Avg", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?170:s=="s67"?150:s=="s75"?140:s=="s80"?135:s=="s85"?113:s=="s90"?110:100, minWidth: 100,
                    valueFormatter: ({ value }) => mathCall.setRupiah(value, 4),
                    comparator: comparatorType.integer,
                    cellClass: () => "text-right grid-table d-border-aggrid-right f-12 text-success"
                    },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 1000);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-129 ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeHistorySell}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}

//============= 4. Stock Trade Summary Display =============
class StockTradeSummaryPage_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
        }
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('.input-daterange').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                todayBtn: "linked",
            });
        });

        $("#btn-clear-date").click(function () {
            $(".date-clear").datepicker("clearDates");
        })
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });

    // Button Buy & Sell
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        if(this.props.codeSearchStockInfo == ""){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please insert the stock code',)
        }else{ 
            this.refs.frameAction.showModal({
                headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                    onClick={this.closeClick}></i></div>,
                size: 'large',
                contentClass: BuyPage2,
                onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', this.props.codeSearchStockInfo),
                onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
            })
        }
    }

    buttonClickSell = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: SellPage,
            onOpen :this.props.handleOnClick('transaction', 'openBuySellModal', this.props.codeSearchStockInfo),
            onClose:()=>this.props.handleOnClick('transaction','closeBuySellModal', 'none'),
        })
    }

    render () {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };

        // var code = this.props.codeSearchStockTradeHistory
        let p = this.props
        let change = p.stockTradeSummarySMRY.change_price
        let code = strCall.code(this.props.codeSearchStockInfo)
        let selectedData = searchCode('selected', code,this.props.stocklistOptions)        
        let nameCode = selectedData != undefined ? selectedData[0].saham : ""
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    {/*outer*/}
                    {/*<div className="container-fluid f-12 card-520">*/}
                    <div className="container-fluid f-12">
                        {/*card 520*/}
                        <div className="py-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-3 px-0 mx-0 row">
                                    <label className="align-self-center col-sm-2 px-0 mx-0">Code</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    <div className="col-sm-10 text-left align-self-center">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={155} styles={customStyles} placeholder={<div>Search..</div>} 
                                            options={this.props.stocklistOptions}  
                                            className="stockPageSelect" theme={this.selectSelectionTab}
                                            value={selectedData} onChange={(e)=>this.props.handleSearchCode('stockTradeSummary',e.value)}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 row mx-0 px-0 align-self-center">
                                    <label className="col-sm-12 f-13 f-xs-14 align-middle align-self-center pr-0">
                                        {nameCode}
                                    </label>
                                </div>
                                <div className="col-sm-3 row mx-0 px-0 align-self-center">
                                    <div className="col-sm-12 align-self-center mx-0 px-0">
                                        <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-success" onClick={this.buttonClickSell}><span>Sell</span></button>
                                        <button className="d-border mx-1 pull-right col-sm-5 col-md-3 btn btn-danger" onClick={this.buttonClickBuy}><span>Buy</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-12 row mx-0 px-0 align-self-center">        
                                    <TableBS borderless size="sm" className="bg-black-trading mb-0 h-35">
                                        <thead></thead>
                                        <tbody className="d-border-top d-border-bottom">
                                        <tr>
                                            <td>
                                                Last <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>{ConvertToRupiah(p.stockTradeSummarySMRY.close_price)}</span>
                                            </td>
                                            <td>
                                                Change(%) &nbsp;
                                                <span className={ColorLabel(change)}>
                                                    <i className={IconChange(change)}></i>
                                                    {change ? change+" ("+p.stockTradeSummarySMRY.change_price_pcts+")" : '' }
                                                </span>
                                            </td>
                                            <td>
                                                High <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>{ConvertToRupiah(p.stockTradeSummarySMRY.high_price)}</span>
                                            </td>
                                            <td>
                                                Low <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>{ConvertToRupiah(p.stockTradeSummarySMRY.low_price)}</span>
                                            </td>
                                            <td>
                                                T.Vol <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>{ConvertToRupiah(ShareToLot(p.stockTradeSummarySMRY.traded_vol))}</span>
                                            </td>
                                            <td>
                                                Value <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>{ConvertToRupiah(p.stockTradeSummarySMRY.traded_val)}</span>
                                            </td>
                                            <td>
                                                Avg <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}>
                                                {p.stockTradeSummarySMRY.traded_vol != 0 ? ConvertToRupiah(((p.stockTradeSummarySMRY.traded_val/p.stockTradeSummarySMRY.traded_vol).toFixed(2))) : "0"}
                                                </span>
                                            </td>
                                            <td>
                                                F. Net <span className={ColorLabel(p.stockTradeSummarySMRY.change_price)}> 
                                                {ConvertToRupiah(Number(p.stockTradeSummarySMRY.foreign_buy_val)-Number(p.stockTradeSummarySMRY.foreign_sell_val))}
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </TableBS>
                                </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                <div className="col-sm-8 px-1 pt-1 pb-0">
                                    <div className="col-sm-12 pl-0 pr-0 mb-3">
                                        <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center row bg-tableheader">
                                            <div className={"col-sm-6 bg-tableheader"}></div>
                                            <div className="col-sm-2 px-0 mx-0 text-left pt-3 pb-2 h-30 f-12 bg-tableheader">
                                                STOCK TICK
                                            </div>
                                            <div className={"col-sm-2 bg-tableheader"}></div>
                                            <div className="col-sm-2 px-0 mx-0 bg-tableheader">
                                                <Dropdown
                                                    placeholder='Choose'
                                                    search selection
                                                    options={summaryOptions}
                                                    className="col-sm-12 f-11" 
                                                    onChange={(e,data)=>{this.props.handleOnClick('selection', 'dropdownStockTradeSummary', data.value)}}
                                                    value={this.props.stockTickBoardType}/>
                                            </div>
                                        </div>
                                        <StockTickAgGrid size={widthSize()}/>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-sm-6 pr-1"}>
                                            <SummaryBuyerAgGrid size={widthSize()}/>
                                        </div>
                                        <div className={"col-sm-6 pl-1"}>
                                            <SummarySellerAgGrid size={widthSize()}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4 pl-1 pr-0 pt-1 pb-0">
                                    <div className="col-sm-12 px-0 mx-0 bg-gray-tradding text-center">
                                    <div className="col-sm-12 px-0 mx-0 text-center pt-3 pb-2 h-30 f-12 bg-tableheader">
                                            RG TRADE SUMMARY
                                            <Popup content='Refresh' position='top center' trigger={
                                                <button
                                                    className={`btn btn-primary btn-10 pull-right`}
                                                    style={{"width":"36px","margin-top":"-9px"}}
                                                    onClick={()=>this.props.handleOnClick('refreshPage','stocktradesummary','none')}>
                                                    <i className="glyphicon glyphicon-refresh" aria-hidden={"true"}></i>
                                                </button>
                                            }/>
                                        </div>
                                    </div>

                                    {/*<TradeSummaryTableScroll/>*/}
                                    <TradeSummaryAgGrid size={widthSize()}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


        );
    }
}
// Table Tick summary
class StockTickAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "time", headerName: "Time", sortable: true, filter: "agTextColumnFilter", resizable: true,
                width: s=="s49"?160:s=="s50"?130:s=="s67"?115:s=="s75"?110:s=="s80"?100:s=="s85"?110:s=="s90"?80:s=="s100"?75:70,
                minWidth: 70,
                    // sort: "desc",
                    valueFormatter: TimeFormatter,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                width: s=="s49"?170:s=="s50"?140:s=="s67"?130:s=="s75"?120:s=="s80"?115:s=="s85"?110:s=="s90"?110:s=="s100"?100:100,
                minWidth:100,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "updown", headerName: "", resizable: true,
                    width: 40, minWidth: 40,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return  change < 0  ? 'grid-table d-border-aggrid-right text-right f-12 text-danger' :
                            change > 0 ? 'grid-table d-border-aggrid-right text-right f-12 text-success'  :
                            'grid-table d-border-aggrid-right text-right f-12 text-warning'
                    },
                    cellRenderer : function (params) {
                        var code = params.data.code;
                        var change = params.data.change;
                        return code == "\xa0" ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' :  change < 0  ? '&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-down text-danger"></i>&nbsp;&nbsp;':
                            change > 0 ? '&nbsp;&nbsp;&nbsp;<i class="icofont icofont-caret-up text-success"></i>&nbsp;&nbsp;' :
                            '&nbsp;&nbsp;&nbsp;<i class="icofont icofont-minus text-warning"></i>&nbsp;&nbsp;' ;
                    },
                },
                { field: "change", headerName: "Change", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?190:s=="s50"?160:s=="s67"?135:s=="s75"?130:s=="s80"?120:s=="s85"?110:s=="s90"?105:s=="s100"?100:90,
                    minWidth:90,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        var change = params.data.change;
                        return change < 0 ? 'grid-table d-border-aggrid-right text-right f-12 text-danger' :
                            change > 0 ? 'grid-table d-border-aggrid-right text-right f-12 text-success' :
                            'grid-table d-border-aggrid-right text-right f-12 text-warning'
                    },
                },
                { field: "percent", headerName: "%", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?200:s=="s50"?190:s=="s67"?125:s=="s75"?120:s=="s80"?110:s=="s85"?110:s=="s90"?100:90, minWidth: 90,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table text-right d-border-aggrid-right text-right f-12";
                    },
                },{ field: "vol", headerName: "Volume(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s67"?150:s=="s75"?140:s=="s80"?130:s=="s85"?110:s=="s90"?110:s=="s100"?110:115, minWidth: 115,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "buyer", headerName: "Buyer", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?240:s=="s67"?205:s=="s75"?190:s=="s80"?135:s=="s85"?110:s=="s90"?120:s=="s100"?110:90,comparator: stringComparator,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer : function (params) {
                        var buyer = params.data.buyer;
                        var sBuyer = buyer !== undefined ? buyer.split('-') : '';
                        return buyer === undefined ? '' : buyer.length > 0 ? '<span >'+sBuyer[0]+'</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+sBuyer[1] : '';
                    }},
                { field: "seller", headerName: "Seller", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?240:s=="s67"?205:s=="s75"?190:s=="s80"?135:s=="s85"?105:s=="s90"?120:s=="s100"?110:90,comparator: stringComparator,
                    minWidth: 90,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    },
                    cellRenderer : function (params) {
                        var seller = params.data.seller;
                        var sSeller = seller !== undefined ? seller.split('-') : '';
                        return seller === undefined ? '' : seller.length > 0 ? '<span>'+sSeller[0]+'</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+sSeller[1] : '';
                    }},
                { field: "board", headerName: "Board", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    width: s=="s49"?180:s=="s50"?150:s=="s67"?130:s=="s75"?125:s=="s80"?120:s=="s85"?100:s=="s90"?80:80,
                    minWidth: 110,
                    comparator: stringComparator,
                    cellClass : function (params) {
                        return "text-center grid-table d-border-aggrid-right f-12";
                    }},
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-220-a ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTickMain}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
// table buyer pada tick summary
class SummaryBuyerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "buyer", headerName: "Buyer", sortable: true, resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?210:s=="s75"?195:s=="s80"?170:s=="s85"?140:s=="s90"?140:s=="s100"?140:130,
                    minWidth: 130,
                    suppressSizeToFit:true, lockVisible:true, lockPosition:true,   
                    comparator: stringComparator,                 
                    cellClass : function (params) {
                        // var volume = parseInt(params.data.volume);
                        // if (volume < 22 || volume > 26) {
                        //     var value = 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible';
                        // } else {
                        //     var value = 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible';
                        // }

                        // return value;
                        return 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible'
                    }, },
                { field: "volume", headerName: "Volume(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?205:s=="s75"?190:s=="s80"?170:s=="s85"?150:s=="s90"?140:s=="s100"?130:120,
                    minWidth: 120, sort:'desc',
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "avg", headerName: "Avg. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?150:s=="s90"?135:s=="s100"?130:120,
                    minWidth: 120, 
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                { buyer: "DX",
                    volume: "20",
                    freq: "5",
                    avg: "10,820"},
                { buyer: "DX",
                    volume: "21",
                    freq: "6",
                    avg: "10,830"},
                { buyer: "DX",
                    volume: "22",
                    freq: "7",
                    avg: "10,840"},
                { buyer: "DX",
                    volume: "23",
                    freq: "8",
                    avg: "10,850"},
                { buyer: "DX",
                    volume: "24",
                    freq: "9",
                    avg: "10,860"},
                { buyer: "DX",
                    volume: "25",
                    freq: "4",
                    avg: "10,870"},
                { buyer: "DX",
                    volume: "26",
                    freq: "3",
                    avg: "10,880"},
                { buyer: "DX",
                    volume: "27",
                    freq: "2",
                    avg: "10,890"},
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-220-b ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockSummaryBuyerMain}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// table seller pada tick summary
class SummarySellerAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "seller", headerName: "Seller", sortable: true, resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?210:s=="s75"?195:s=="s80"?170:s=="s85"?150:s=="s90"?140:s=="s100"?140:130,
                    minWidth: 130,
                    comparator: stringComparator,
                    suppressSizeToFit:true, lockPosition:true, lockVisible:true,
                    cellClass : function (params) {
                        var volume = parseInt(params.data.volume);
                        if (volume < 22 || volume > 26) {
                            var value = 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible';
                        } else {
                            var value = 'text-center grid-table d-border-aggrid-right f-12 text-warning locked-col locked-visible';
                        }
                        return value;
                    }, },
                { field: "volume", headerName: "Volume(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?260:s=="s50"?230:s=="s67"?205:s=="s75"?190:s=="s80"?170:s=="s85"?150:s=="s90"?140:s=="s100"?130:120,
                    minWidth: 120,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
                { field: "avg", headerName: "Avg. Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?250:s=="s50"?230:s=="s67"?200:s=="s75"?190:s=="s80"?160:s=="s85"?145:s=="s90"?135:s=="s100"?130:120,
                    minWidth: 120, 
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return "text-right grid-table d-border-aggrid-right f-12 text-success";
                    }, },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function(params){
                return 27;
            },
            rowData: [
                { seller: "DX",
                    volume: "20",
                    freq: "5",
                    avg: "10,820"},
                { seller: "DX",
                    volume: "21",
                    freq: "6",
                    avg: "10,830"},
                { seller: "DX",
                    volume: "22",
                    freq: "7",
                    avg: "10,840"},
                { seller: "DX",
                    volume: "23",
                    freq: "8",
                    avg: "10,850"},
                { seller: "DX",
                    volume: "24",
                    freq: "9",
                    avg: "10,860"},
                { seller: "DX",
                    volume: "25",
                    freq: "4",
                    avg: "10,870"},
                { seller: "DX",
                    volume: "26",
                    freq: "3",
                    avg: "10,880"},
                { seller: "DX",
                    volume: "27",
                    freq: "2",
                    avg: "10,890"},
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    render() {
        return (
            <>
                <div
                    className="card card-220-b ag-theme-balham-dark ag-header-border-gray ag-striped-odd"
                    style={{
                        width: 'auto' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockSummarySellerMain}
                        defaultColDef={this.state.defaultColDef}
                        getRowHeight={this.state.getRowHeight}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}>
                    </AgGridReact>
                </div>
            </>
        );
    }
}
// Table Trade summary (RG TRADE SUmmary)
class TradeSummaryAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "price", headerName: "Price", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?146:s=="s80"?127:s=="s85"?120:s=="s90"?105:s=="s100"?90:80,
                    minWidth: 80,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    // pinnedRowCellRendererParams: { style: { "font-style": "italic" } },
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },{ field: "value", headerName: "Value", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?146:s=="s80"?127:s=="s85"?110:s=="s90"?110:s=="s100"?110:100,
                    minWidth:100,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "volume", headerName: "Volume(Lot)", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?146:s=="s80"?127:s=="s85"?110:s=="s90"?110:s=="s100"?110:110,
                    minWidth:110,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table text-right d-border-aggrid-right text-right f-12";
                    },
                },
                { field: "freq", headerName: "Freq", sortable: true, filter: "agNumberColumnFilter", resizable: true,
                    width: s=="s49"?195:s=="s50"?175:s=="s67"?155:s=="s75"?146:s=="s80"?127:s=="s85"?110:s=="s90"?105:s=="s100"?90:90,
                    minWidth: 90,
                    valueFormatter:currencyFormatter,
                    comparator: integerComparator,
                    cellClass : function (params) {
                        return " grid-table text-right d-border-aggrid-right text-right f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            getRowHeight : function (params) {
                return 32;
            },
            getRowStyle: function(params) {
                if (params.node.rowPinnedData) {
                    return { "font-weight": "bold" };
                }
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
        setTimeout(function() {
            params.api.refreshCells(params);
        }, 100);
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    createData() {
        var result = [];
        result.push({
                price: "",
                value: sumDataArray(this.props.stockTradeSummaryMain,'object-value'),
                volume: sumDataArray(this.props.stockTradeSummaryMain,'object-volume'),
                freq: sumDataArray(this.props.stockTradeSummaryMain,'object-freq'),
            });
        return result;
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-440-a ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.stockTradeSummaryMain}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        getRowStyle={this.state.getRowStyle}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

//============= CorpAction DISPLAY =============
class StockCorpAction_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
        }
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('#datepickerstartCA').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateCA").click(); 
            });
            $('#datepickerendCA').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                // endDate: ed,
                autoclose: true,
                todayBtn: "linked",
            }).on('changeDate', function(e) {
                document.getElementById("handleDateCA").click(); 
            });
        });

        $("#btn-clear-date").click(function () {
            $(".date-clear").datepicker("clearDates");
        })
    }

    handleChangeDate=()=>{
        let nStartDate = this.refs.datepickerstartCA.value;
        let nEndDate =  this.refs.datepickerendCA.value;
        if(nStartDate != "" && nEndDate == ""){
            this.refs.datepickerendCA.value = GetTimeForDateTicker(0,+1)
            nEndDate = GetTimeForDateTicker(0,+1)
            $("#datepickerendCA").datepicker("setDate", nEndDate);
        }
        let nDatepicker = {start: nStartDate, to: nEndDate}
        this.props.setDataHandler({'rangeCorpAction': nDatepicker});
    }

    GoHandler = ({ start="", to="" }) => {
        if(start === "" && to === ""){
            this.props.handleStatusAlert3('noConfirm', true, 'Please insert the periode',)
        }else{
            this.refs.netAction.send(fmsg.q_corpAction(start, to));
        }
    }

    render () {
        let p = this.props;
        return (
            <>
            <WSConnectionAction ref="netAction"/> {/* websocket connection component */}
            <AppFrameAction ref="frameAction"/>
            
            <div className="bg-black-trading">
                <main>
                    <div className="container-fluid f-12">
                        <div className="py-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-12 px-0 mx-0 row">
                                    <label className="align-self-center px-0 mx-0">Corp Action</label>
                                </div>
                            </div>
                            <div className="col-sm-12 pl-1">
                                <div className="ui small input col-sm-8 pl-0 f-12 text-center align-self-center black ver-center">
                                    <span onClick={this.handleChangeDate} id={"handleDateCA"}></span>
                                    <table>
                                        <tr>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>Periode</span>
                                            </td>
                                            <td className={"px-0"}>
                                                <div className="ui input pl-0" style={{paddingRight:'37px',marginLeft:'-1px'}}>
                                                    <input placeholder='dd/mm/yy' ref="datepickerstartCA" id="datepickerstartCA" 
                                                        className="col-sm-12 pl-1 pr-0 text-center align-self-center "
                                                    />
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td className={"px-0"}>
                                                <span className="input-group-addon h-35 bg-tableheader height-span-addon" style={{border: "1px solid var(--warna-d-border)"}}>To</span>
                                            </td>
                                            <td className={"px-0"}>

                                                <div className="ui input" style={{paddingRight:'40px',marginLeft:'-1px'}}>
                                                    <input placeholder='dd/mm/yy' ref="datepickerendCA" id="datepickerendCA" className="col-sm-12 pl-1 pr-0 text-center align-self-center "/>
                                                    <span className="input-group-addon h-35 no-border-radius bg-tableheader" style={{width: '100%'}}>
                                                    <span
                                                        className="fa fa-calendar-alt"></span>
                                                </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="submit" style={{height:'30px !important'}} className="btn btn-md btn-block btn-dark btnDatePick"
                                                    onClick={() => this.GoHandler(p.rangeCorpAction || {})}
                                                >Go</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                <StockCorpActionAgGrid size={widthSize()} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
        );
    }
}

class StockCorpActionAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { 
                    field: "recorddate", headerName: "Record Date", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType["text"],
                    width: s=="s49"?370:s=="s50"?310:s=="s67"?270:s=="s75"?260:s=="s80"?250:s=="s85"?240:s=="s90"?230:s=="s100"?210:210,
                    minWidth: 210,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{
                    field: "scripcode", headerName: "Code", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType["text"],
                    width: s=="s49"?520:s=="s50"?460:s=="s67"?405:s=="s75"?380:s=="s80"?360:s=="s85"?340:s=="s90"?320:s=="s100"?300:300,
                    minWidth:300,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ 
                    field: "scripname", headerName: "Stock Name", sortable: true, filter: "agTextColumnFilter", resizable: true, 
                    comparator: comparatorType["text"],
                    width: s=="s49"?770:s=="s50"?700:s=="s67"?650:s=="s75"?600:s=="s80"?580:s=="s85"?505:s=="s90"?467:s=="s100"?450:450,
                    minWidth:450,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },{ 
                    field: "ca_name", headerName: "Corp. Action", sortable: true, filter: "agTextColumnFilter", resizable: true,
                    comparator: comparatorType["text"],
                    width: s=="s49"?650:s=="s50"?600:s=="s67"?510:s=="s75"?480:s=="s80"?310:s=="s85"?280:s=="s90"?260:s=="s100"?250:250,
                    minWidth:250,
                    cellClass: params => ` grid-table text-primary d-border-aggrid-right text-left f-12 ${params.data.scripcode ? "click-pointer" : ""}`,
                    cellRenderer : 'corpActionRenderer'
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                corpActionRenderer: ({ data }) => data.scripcode ? 
                    <span class="btn-cellorder px-1" onClick={() => this.detailCorpAction(data)}>
                        {data.ca_name}
                        {/* {setFieldCorpAction(data.catype)} */}
                    </span> : "",
            },
            getRowHeight : function (params) {
                return 32;
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
        function handleOnClick(params){
            alert(params);
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    setBottomPinned(){
        // alert('hee');
        var rows = this.createData();
        // this.gridApi.setPinnedBottomRowData(rows);
    }
    createData() {
        var result = [];
        result.push({
                price: "",
                value: 1,
                volume: 1,
                freq: 1,
            });
        return result;
    }
    detailCorpAction = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <></>,
            size: 'big',
            contentClass: CorpActionDetail,
            onOpen: this.refs.netAction.send(fmsg.q_corpActionDetail(data.caid)),
            contentProps: data,
            onClose: () => this.props.setDataHandler({'CORPORATE_ACTION_DETAIL': undefined})
        })
    }
    render() {
        return (
            <>
            <AppFrameAction ref="frameAction"/>
            <WSConnectionAction ref="netAction"/>
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-406 ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    {/*<span onClick={() => this.onClickYeah()}>Aku</span>*/}
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.props.CORPORATE_ACTION_LIST}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        getRowHeight={this.state.getRowHeight}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        frameworkComponents={this.state.frameworkComponents}
                        // pinnedBottomRowData={this.state.rowPinnedData}
                    >
                    </AgGridReact>
                </div>
            </div>
            </>
        );
    }
}


//============= SPECIAL NOTATION DISPLAY =============
class StockSpecialNotation_Base extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            tabNumber: 1,
            startRow: 0,
        }
    }
    componentDidMount() {
        $(document).ready(function() {
            var sd = new Date(), ed = new Date();
            var isRtl = $('html').attr('dir') === 'rtl';
            $('.input-daterange').datepicker({
                orientation: isRtl ? 'auto right' : 'auto left',
                format: "dd/mm/yyyy",
                changeMonth: true,
                changeYear: true,
                startDate: '01/01/1920',
                autoclose: true,
                endDate : sd,
                todayHighlight: true,
                todayBtn: "linked",
            });
        });

        $("#btn-clear-date").click(function () {
            $(".date-clear").datepicker("clearDates");
        })
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#3D3E3F' : '#CDCDCE',
            neutral20: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral30: this.props.thememode === true ? '#333332' : '#E9E9E9',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#878787',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#4D4D4E' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#202020' : '#ece9ea',
            primary: '#0071BC',
        },
    });
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    buttonClickBuy = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: ModalBuy,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }

    buttonClickSell = (e) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div className="text-right"><i className="icofont icofont-close text-icofont-close text-white click-pointer"
                onClick={this.closeClick}></i></div>,
            size: 'large',
            contentClass: SellPage,
            onClose: (result) => {console.log('Modal 1 result = ', result)}
        })
    }
    render () {

        const stockOptions = [
            { value:'bmpt', code: 'BMPT', saham: 'Bumi Mega Pertama ' },
            { value:'bnmp-ppt', code: 'BNMP-PPT', saham: 'Bumi Nusa Putra ' },
            { value:'bumi', code: 'BUMI', saham: 'Bumi Resource ' },
            { value:'asii', code: 'ASII', saham: 'Argo Astra Lestari ' },
            { value:'tlkm', code: 'TLKM', saham: 'Telekomunikasi Indonesia ' },
            { value:'wskt', code: 'WSKT', saham: 'Waskita ' },
            { value:'indf', code: 'INDF', saham: 'Indofood ' },
            { value:'bbca', code: 'BBCA', saham: 'Bank BCA ' },
            { value:'smrg', code: 'SMGR', saham: 'Semen Indonesia ' },
            { value:'bbri', code: 'BBRI', saham: 'Bank BRI ' }
        ];
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        return (
            <div className="bg-black-trading">
                <AppFrameAction ref="frameAction" />
                <main>
                    {/*outer*/}
                    {/*<div className="container-fluid f-12 card-520">*/}
                    <div className="container-fluid f-12">
                        {/*card 520*/}
                        <div className="py-2 pb-0">
                            <div className="px-1 mx-0 my-0 col-sm-12 row h-40">
                                <div className="col-sm-12 px-0 mx-0 row">
                                    <label className="align-self-center px-0 mx-0">Special Notation Stock</label>
                                    {/*<Input defaultValue='AALI' placeholder='Code' size='small' className="col-sm-8 text-center align-self-center"/>*/}
                                    </div>
                            </div>
                            <div className="px-1 mx-0 col-sm-12 row">
                                    <SpecialNotationAgGrid size={widthSize()}/>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


        );
    }
}
class SpecialNotationAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        const s = props.size;
        this.state = {
            columnDefs: [
                { field: "code", headerName: "Code", sortable: true, filter: "agTextColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?460:s=="s50"?420:s=="s67"?365:s=="s75"?340:s=="s80"?280:s=="s85"?260:s=="s90"?250:s=="s100"?230:210,
                    minWidth: 210,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-center f-12";
                    },
                },{ field: "company", headerName: "Company", sortable: true, filter: "agTextColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?660:s=="s50"?600:s=="s67"?530:s=="s75"?490:s=="s80"?440:s=="s85"?410:s=="s90"?380:s=="s100"?360:310,
                    minWidth:310,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
                { field: "notation", headerName: "Special Notation", sortable: true, filter: "agTextColumnFilter", resizable: true, comparator: stringComparator,
                    width: s=="s49"?660:s=="s50"?600:s=="s67"?530:s=="s75"?490:s=="s80"?420:s=="s85"?380:s=="s90"?350:s=="s100"?335:310,
                    minWidth:310,
                    cellClass : function (params) {
                        return " grid-table text-primary d-border-aggrid-right text-center f-12";
                    },
                    cellRenderer : 'notationRenderer',
                },
                { field: "board", headerName: "Board", sortable: true, resizable: true, comparator: stringComparator,
                    width: s=="s49"?570:s=="s50"?500:s=="s67"?450:s=="s75"?440:s=="s80"?390:s=="s85"?350:s=="s90"?330:s=="s100"?310:310,
                    minWidth: 310,
                    cellClass : function (params) {
                        return " grid-table d-border-aggrid-right text-left f-12";
                    },
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            frameworkComponents: {
                notationRenderer: ({ data }) => data.code ? 
                    <span class="btn-cellorder click-pointer px-1" onClick={()=>this.clickNotationDetail(data)}>
                        {data.notation}
                    </span> : "",
            },
            rowData: [
                {
                    code: "AKPI",
                    company: "Argha Karya Prima Ind. Tbk",
                    notation: "B-M-E-A-D-L-S-C-Q-Y",
                    board: "Development",
                },
                {
                    code: "ANJT",
                    company: "PT Austindo Nusantara jaya Tbk",
                    notation: "M-L-Y",
                    board: "Main",
                },
            ],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                        },
                    }, {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ],
                defaultToolPanel: ""
            },
        }
        function isFirstColumn(params) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });

        params.api.sizeColumnsToFit();
    };

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    setBottomPinned(){
        // alert('hee');
        var rows = this.createData();
        // this.gridApi.setPinnedBottomRowData(rows);
    }
    createData() {
        var result = [];
        result.push({
                price: "",
                value: 1,
                volume: 1,
                freq: 1,
            });
        return result;
    }
    closeClick(){
        this.refs.frameAction.closeModal(1000);
    }

    clickNotationDetail(data){
        this.refs.frameAction.showModal({
            headerClass: () =>
                <div className="col-sm-12 px-0 py-0 mx-0 text-right">
                    <i className="icofont icofont-close text-icofont-close text-white click-pointer" onClick={()=>this.closeClick()}></i>
                </div>,
            size: 'tiny',
            contentClass: SpecialNotationModal,
            onOpen: this.props.handleOnClick('stockPage', 'specialNotationDetail', data.notationDes),
        })
    }
    render() {
        return (<>
            <AppFrameAction ref="frameAction"/>
            <div style={{ width: "100%", height: "100%" }}>
                <div
                    className={"card-specialNotation ag-theme-balham-dark ag-bordered ag-striped-odd d-border"}
                    id="myGrid"
                    style={{
                        width: "100%"
                    }}>
                    {/*<span onClick={() => this.onClickYeah()}>Aku</span>*/}
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.props.specialNotation}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowHeight="32"
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        // pinnedBottomRowData={this.state.rowPinnedData}
                    >
                    </AgGridReact>
                </div>
            </div>
        </>);
    }
}
class SpecialNotationModal_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        };
    handleOnClick = (e) => {
        var notationVal = $("#tempNotation").val();
        var ArrNotation = notationVal.split("-");
        console.log(ArrNotation);
    }
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }
    render() {
        let data = this.props.specialNotationDetail;
        return (<>
            <AppFrameAction ref="frameAction"/>
            <div className={"col-sm-12 px-0 pt-0"}>
                <div className="col-sm-12 px-0 mx-0 text-center pt-2 pb-2 h-30 f-15 bg-tableheader">
                    Special Notation Stock Information
                </div>
                    <table className="table text-white d-border-table bg-dark-grey table-sm table-borderless mb-0 tb-align-center tb-notation f-13" style={{height: "100%"}}>
                    <thead className="bg-tableheader">
                        <th className="text-center">Notation</th>
                        <th className="text-center">Information</th>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((item, id) => (
                            <tr key={"spnd"+id}>
                                <td className="text-center">{item.notation}</td>
                                <td>{item.notationDesc}</td>
                            </tr>
                        ))}
                    </tbody>                                                    
                </table>
            </div>
        </>);
    }
}


class TradeSummaryTableScroll extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            rowData: [
                {
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },
                {
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },{
                    price: 0,
                    value: 0,
                    freq: 0,
                    volume: 0,
                },
                // {
                //     price: 0,
                //     value: 0,
                //     freq: 0,
                //     volume: 0,
                // },{
                //     price: 0,
                //     value: 0,
                //     freq: 0,
                //     volume: 0,
                // },{
                //     price: 0,
                //     value: 0,
                //     freq: 0,
                //     volume: 0,
                // },{
                //     price: 0,
                //     value: 0,
                //     freq: 0,
                //     volume: 0,
                // },
            ],
        }
    }

    render() {
        const splitcode = (props) => {
            var splited = props.split('-');
            return <span>{splited[0]}</span>
        }
        const splitname = (props) => {
            let splited = props.split("-");
            return <span>{splited[1]}</span>
        }
        return (
            <>
                <div
                    className="card-stock-trade-sum-tb ag-theme-balham-dark ag-header-border-grey d-border"
                    style={{
                        width: 'auto'}}>
                    <table className="table table-fixed table-hovered d-border-bottom-grey">
                        <thead>
                        <tr>
                            <th className="col-xs-3 v15-align-center">Price</th>
                            <th className="col-xs-3 v15-align-center ">Value</th>
                            <th className="col-xs-3 v15-align-center ">Volume(Lot)</th>
                            <th className="col-xs-3 v15-align-center ">Freq</th>
                        </tr>
                        </thead>
                        <tbody className={"body-trade-sum-tb"}>
                        {this.state.rowData.map((charx, index) => {
                            return (
                                <tr className="trTableFix">
                                    <td className={`col-xs-3 text-right d-border-right codeTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {charx.price}</td>
                                    <td className={`col-xs-3 text-right d-border-right codeTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {charx.value}</td>
                                    <td className={`col-xs-3 text-right d-border-right codeTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {charx.volume}</td>
                                    <td className={`col-xs-3 text-right d-border-right buttonTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {charx.freq}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                        {/*<thead>*/}
                        {/*<tr>*/}
                            {/*<th className="col-xs-10 v50-align-center"></th>*/}
                            {/*<th className="col-xs-2 v50-align-center">Total Val: </th>*/}
                            {/*<th className="col-xs-2 v50-align-center">Total Vol: </th>*/}
                            {/*<th className="col-xs-2 v50-align-center">Freq: </th>*/}
                        {/*</tr>*/}
                        {/*</thead>*/}
                    </table>
                    <table className="table table-fixed table-hovered d-border-bottom-grey">
                    <thead>
                        <tr>
                            <th className="col-xs-3 ">&nbsp;</th>
                            <th className="col-xs-3 " style={{"textAlign":"left !important"}}>Val: </th>
                            <th className="col-xs-3 " style={{"textAlign":"left !important"}}>Vol: </th>
                            <th className="col-xs-3 " style={{"textAlign":"left !important"}}>Freq: </th>
                        </tr>
                        </thead>
                    </table>
                </div>
            </>
        );
    }
}

//************************** MODAL **************************
// >>>>>>> Modal of Register Amend <<<<<<<
class RegisterAmendModal_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    selectSelectionTab = theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            neutral0: this.props.thememode === true ? '#565252' : '#999999',
            neutral20: this.props.thememode === true ? '#333332' : '#CDCDCE',
            neutral30: this.props.thememode === true ? '#333332' : '#CDCDCE',
            neutral40: this.props.thememode === true ? '#1A1A1A' : '#1A1A1A',
            neutral80: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary75: this.props.thememode === true ? '#FFFFFF' : '#FFFFFF',
            primary50: this.props.thememode === true ? '#333332' : '#4D4D4E',
            primary25: this.props.thememode === true ? '#7e7a7a' : '#c5c2c3',
            primary: '#0071BC',
        },
    });

    // handle onclick plus (+) in AMEND GROUP
    addAmendgroup=()=>{
        if(this.props.codeSelected === ''){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please select stock to be added',)
        }else{
            let groupName = this.props.amendGroupName.split(' ')[1];
            let indexGroup = this.props.amendGroup.findIndex(({ group_name }) => group_name == groupName);
            var codeListGroup= this.props.amendGroup[indexGroup].stocks            
            let indexCode = codeListGroup.findIndex(code => code === this.props.codeSelected)
            //pengecekan apakah code yang ditambahkan sudah ada atau belum 
            if(indexCode == -1){
                if(codeListGroup.length >= 20){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,"Groups cannot be more than 20 stocks",)
                }else{
                    this.props.handleOnClick('myWatclist', 'amendForCode',this.props.codeSelected+'|'+indexGroup)
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,this.props.codeSelected+" Stock succesfully added.",)
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,this.props.codeSelected+" already exists",)
            }
        } 
    }

    // handle onclick Save in AMEND GROUP
    saveAmendGroup=()=>{
        this.props.handleOnClick('myWatclist', 'amendGroupToServer', 'none')
    }

    // handle onclick plus (+) in ADD GROUP
    addCodeToGroup=()=>{
        if(this.props.codeSelected === ''){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please select stock to be added',)
        }else{
            let codeResult2 = searchCode('selectedSearch', this.props.codeSelected, this.props.addGroupCode)
            if(codeResult2 === undefined){
                if(this.props.addGroupCode.length >= 45){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,"Groups cannot be more than 45 stocks",)
                }else{
                    this.props.handleOnClick('myWatclist', 'addCodeToNewGroup',this.props.codeSelected)
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,this.props.codeSelected+" Stock succesfully added.",)
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,this.props.codeSelected+" already exists",)
            }
        }      
    }

    handleChanngeGroupName=(e)=>{
        if(e.length < 11){
            this.props.onChangeTextHandler('myWatchlist', 'addGroupName',e.toUpperCase().replace(/\s/g, ''))
        }
    }

    // handle onclick Save in ADD Group
    saveAddGroup=()=>{
        var groupName = this.props.addGroupName
        var data = this.props.addGroupCode
        if(groupName.length === 0 && data.length === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please input group name and code',)
        }else if(groupName.length === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please input group name',)
        }else if(groupName.length > 10){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Group Name max. 10 characters',)
        }
        else if(data.length === 0){
            this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Please select stock to be added',)
        }else{
            var groupNameList = GroupList('groupNames', this.props.watchlistCode)
            var result = searchCode('code1',groupName,groupNameList)
            if(result === undefined){
                if(this.props.watchlistCode.length >= 10){
                    this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,"Watchlist cannot be more than 10 groups",)
                }else{
                    this.props.handleOnClick('myWatclist', 'addGroupToServer', groupName)
                }
            }else{
                this.props.handleStatusAlert3('noConfirm',this.props.statusAlertN,'Group name already exists',)
            }
        }
    }

    render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                // match with the menu
                borderRadius: 0,
                border: "var(--warna-d-border) 1px solid",
                color : "white!important"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
            }),
            menuList: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,
                color : "white!important"
            })
        };

        //Add your search logic here.
        const customFilter  = (option, searchText) => {
            var code = option.data.code.toLowerCase().includes(searchText.toLowerCase());
            var saham = option.data.saham.toLowerCase().includes(searchText.toLowerCase());

            if(searchText.toLowerCase().includes(' ')){
                if(saham){
                    return true;
                }
            } else {
                if (code) {
                    return true;
                }
            }
        };
        
        var code = this.props.codeSelected  
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <div>
                    <div className="cssmenumodal bg-grey pb-4 col-sm-12 mx-0 px-0">
                        <ul>
                            <li className={ this.props.amendActiveTab === '1' ? 'd-border-bottom active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' : 'd-border-bottom text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } 
                            onClick={() => { this.props.handleOnClick('myWatclist', 'amendOrAdd', '1'); }}><a><span className="f-11">&nbsp; Amend Group</span></a></li>
                            <li className={ this.props.amendActiveTab === '2' ? 'd-border-bottom active click-pointer col-sm-6 px-0 mx-0 f-12 text-center' : 'd-border-bottom text-white click-pointer col-sm-6 px-0 mx-0 f-12 text-center' } 
                            onClick={() => { this.props.handleOnClick('myWatclist', 'amendOrAdd', '2'); }}><a><span className="f-11">&nbsp; Add Group</span></a></li>
                        </ul>
                    </div>
                    <div className="card-520">
                        {/* form of Amend Group */}
                        <div className={this.props.amendActiveTab === '1' ? 'card card-520 d-border d-block f-12' : 'd-none'}>
                            <div className="card d-border-transparent-grey h-auto">
                                <div className="card card-145 bg-grey">
                                    <AmendGroupNameAgGrid />
                                </div>
                                <div className="form-group row col-sm-12 px-0 mx-0 my-0 py-3 text-white h-63 v-align-items-center">
                                    <div className="col-sm-5">
                                        <label className="col-sm-12">Name</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <Input defaultValue={this.props.amendGroupName} readOnly value={this.props.amendGroupName} placeholder='Group Name' size='small' className="gray col-sm-12 align-self-center"/>
                                    </div>
                                </div>
                                <div className="card card-145 bg-grey">
                                    <AmendGroupCodeAgGrid />
                                </div>
                                <div className="form-group row col-sm-12 px-0 mx-0 my-0 py-3 text-white h-67 v-align-items-center">
                                    <div className="form-group col-sm-8 my-1 text-left">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={110} styles={customStyles} placeholder={<div style={{color:"white"}}>Search..</div>} 
                                            options={this.props.stocklistOptions} className="stockPageSelect text-left" theme={this.selectSelectionTab}
                                            value={this.props.selectedData}
                                            onChange={(e)=>this.props.handleOnClick('myWatclist', 'codeSelected',e.value)}/>
                                    </div>
                                    <div className="col-sm-1 px-0 mx-0 align-self-center align-middle">
                                        <button className="btn btn-sm bg-gray-tradding border-gray-tradding" onClick={this.addAmendgroup}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row col-sm-12 px-0 mx-0 z-99 text-white my-0 h-100 pt-40">
                                <div className="col-sm-9 align-self-center align-middle">
                                    <label className="text-muted">Max Group is 10 group with 45 stock list</label>
                                </div>
                                <div className="col-sm-3 align-self-center align-middle">
                                    <button className="btn btn-sm btn-grey-gray border-gray-tradding col-sm-12" onClick={this.saveAmendGroup}>Save</button>
                                </div>
                            </div>
                        </div>

                        {/* form of Add Group */}
                        <div className={this.props.amendActiveTab === '2' ? 'card card-520 d-border d-block f-12' : 'd-none'}>
                            <div className="card d-border-transparent-grey h-auto">
                                <div className="form-group row col-sm-12 px-0 mx-0 my-0 py-3 text-white h-63 v-align-items-center">
                                    <div className="col-sm-5">
                                        <label className="col-sm-12">Group Name</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <Input ref='groupName' onChange={(e)=>this.handleChanngeGroupName(e.target.value)} placeholder='Group Name' size='small' className="gray col-sm-12 align-self-center"
                                            value ={this.props.addGroupName}/>
                                    </div>
                                </div>
                                <div className="card card-290 bg-grey">
                                    <AddGroupCodeAgGrid />
                                </div>
                                <div className="form-group row col-sm-12 px-0 mx-0 my-0 py-3 text-white h-67 v-align-items-center">
                                    <div className="form-group col-sm-8 my-1 text-left">
                                        <Select
                                            getOptionLabel={(option) => `${option.code} - ${option.saham}`}
                                            filterOption={customFilter} isSearchable={true}
                                            maxMenuHeight={110} styles={customStyles} placeholder={<div style={{color:"white"}}>Search..</div>} 
                                            onChange={(e)=>this.props.handleOnClick('myWatclist', 'codeSelected',e.value)}
                                            value={this.props.selectedData}
                                            options={this.props.stocklistOptions} 
                                            className="stockPageSelect text-left" theme={this.selectSelectionTab}
                                            />
                                    </div>
                                    <div className="col-sm-1 px-0 mx-0 align-self-center align-middle">
                                        <button className="btn btn-sm bg-gray-tradding border-gray-tradding" onClick={this.addCodeToGroup}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row col-sm-12 px-0 mx-0 z-99 text-white my-0 h-100 pt-40">
                                <div className="col-sm-9 align-self-center align-middle">
                                    <label className="text-muted">Max Group is 10 group with 45 stock list</label>
                                </div>
                                <div className="col-sm-3 align-self-center align-middle">
                                    <button className="btn btn-sm btn-grey-gray border-gray-tradding col-sm-12" onClick={this.saveAddGroup}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
// >>>>>>> Modal of Group Name Amend <<<<<<<
class AmendGroupNameAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
        }
    }

    deleteGroup=(data)=>{
        this.props.handleStatusAlert3('confirm',this.props.statusAlertC,'Are you sure?', 'myWatclist#delete#deleteGroupFromAmendGroup'+'|'+data)
    }

    render() {
        return (
            <>
                <div
                    className="card-145 ag-theme-balham-dark ag-header-border-grey d-border"
                    style={{
                        width: 'auto'}}>
                    <table className="table table-fixed table-hovered">
                        <thead>
                        <tr>
                            <th className="col-xs-7 v50-align-center">Group</th>
                            <th className="col-xs-2 v50-align-center">Member</th>
                            <th className="col-xs-3 v50-align-center">#</th>
                        </tr>
                        </thead>
                        <tbody className="tbodyGroup">
                        {this.props.amendGroupContainer.map((list, index) => {
                            return (
                                <tr className="trTableFix" key={index}>
                                    <td className={`col-xs-7 groupName text-center text-primary ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>{list === '\xa0' ? '' : list.groupname}</td>
                                    <td className={`col-xs-2 totalMember text-right ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>{list === '\xa0' ? '' : list.totalmember}</td>
                                    <td className={`col-xs-3 buttonTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {list === '\xa0' ? '' :<>
                                            <button type="button" className="btn btn-info btn-sm" 
                                                onClick={()=>this.props.handleOnClick('myWatclist','amendForGroup', list.groupname)}>
                                                <i className="icofont-pencil"></i>
                                            </button> 
                                            <button type="button" className="btn btn-danger btn-sm" onClick={()=>this.deleteGroup(list.groupname)}>
                                                <i className="icofont-trash"></i>
                                            </button></>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}
// >>>>>>> Modal of amend Group Code <<<<<<<
class AmendGroupCodeAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
        }
    }

    deleteGroup=(data)=>{
        this.props.handleStatusAlert3('confirm',this.props.statusAlertC,'Are you sure?', 'myWatclist#delete#deleteCodeFromAmendGroup'+'|'+data)

    }

    render() { 
        return (
            <>
                <div
                    className="card-145 ag-theme-balham-dark ag-header-border-grey d-border"
                    style={{
                        width: 'auto'}}>
                    <table className="table table-fixed table-hovered">
                        <thead>
                        <tr>
                            <th className="col-xs-10 v50-align-center">Code</th>
                            <th className="col-xs-2 v50-align-center">#</th>
                        </tr>
                        </thead>
                        <tbody className="tbodyGroup">
                            {this.props.amendCodeContainer.map((list, index) => {
                                {if(list !== '\xa0'){
                                    var code = list.code
                                    var sCode = code.split(' - ')
                                    var sCode2 = code.split('-')
                                    sCode2.splice(0,2).join("-")}
                                }
                                return (
                                    <tr className="trTableFix" key={index}>
                                        <td className={`col-xs-10 codeTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                            {list === '\xa0' ? '\xa0' : <><kbd> {sCode[0]} </kbd>&nbsp;{sCode[1]}</> }
                                        </td>
                                        <td className={`col-xs-2 buttonTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                            {list === '\xa0' ? '\xa0' : <><button type="button" className="btn btn-danger btn-sm" 
                                                    onClick={()=>this.deleteGroup(sCode[0])}>
                                                    <i className="icofont-trash"></i>
                                                </button> </>
                                            }
                                        </td>
                                    </tr>
                                )})
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}
// >>>>>>> Modal of add Group Code <<<<<<<
class AddGroupCodeAgGrid_Base extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    deleteCode=(data)=>{
        this.props.handleStatusAlert3('confirm',this.props.statusAlertC,'Are you sure to delete the stock?', 'myWatclist#delete#deleteCodeFromNewGroup'+'|'+data)

    }


    render() {
        return (
            <>
                <div
                    className="card-290 ag-theme-balham-dark ag-header-border-grey d-border"
                    style={{
                        width: 'auto','overflow-y':'hidden'}}>
                    <table className="table table-fixed table-hovered">
                        <thead>
                        <tr>
                            <th className="col-xs-10 v50-align-center">Code</th>
                            <th className="col-xs-2 v50-align-center">#</th>
                        </tr>
                        </thead>
                        <tbody className="tbodyGroupAdd">
                        { this.props.addGroupCodeContainer.map((list, index) => {
                            var code = list === '\xa0' ? '\xa0' : list.code;
                            var sCode = list === '\xa0' ? '' : code.split(' - ');
                            return (
                                <tr className="trTableFix" key={index}>
                                    <td className={`col-xs-10 codeTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                    {list === '\xa0' ? '' : 
                                        <>
                                            <kbd> {sCode[0]} </kbd>
                                            &nbsp;{sCode[1]}
                                        </>}
                                    </td>                                    
                                    <td className={`col-xs-2 buttonTd ${((index+1) % 2 == 0) ? 'td-even' : ''}`}>
                                        {list === '\xa0' ? '' : <button type="button" 
                                            onClick={()=>this.deleteCode(index)}
                                                className="btn btn-danger btn-sm">
                                                <i className="icofont-minus"></i>
                                            </button> 
                                        }
                                    </td>
                                </tr>
                            )}).reverse() 
                        }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

class BuyModal_Base extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        // if(this.props.BuySellModalStatus){
        //     this.refs.frameAction.closeModal(100);
        //     this.props.modalStatus("false");
        // }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <ModalBuy/>
            </>
        );
    }
}

class SellModal_Base extends React.PureComponent  {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        if(!this.props.networkState && this.refs.frameAction != undefined){
            this.refs.frameAction.closeModal(100);
        }
        // if(this.props.BuySellModalStatus){
        //     this.refs.frameAction.closeModal(100);
        //     this.props.modalStatus("false");
        // }
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction /> {/* websocket connection component */}
                <ModalSell/>
            </>
        );
    }
}

//************************** Context Connector **************************
// 1. Stock Info Display
const StockPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        stocklistOptions:vars.stocklistOptions,
        codeSearchStockInfo: vars.codeSearchStockInfo,
        iStockData:vars.iStockData,
        sStockSmry:vars.sStockSmry,
        statusAlertN:vars.statusAlertN,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type,code)=>actions.sendAction('handleSearchCode', {type,code}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleOnClick", "handleSearchCode", "handleStatusAlert3"]
)(StockPage_Base);

const TableStockInfo = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        iStockData:vars.iStockData,
        sStockSmry:vars.sStockSmry,  
    }),
)(TableStockInfo_Base)

const CorpActionAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        corpAction:vars.corpAction,
    }),
)(CorpActionAgGrid_Base);

const TableDailyTechnical = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        techDayData: vars.techDayData,
    }),
)(TableDailyTechnical_Base);


// 1.5. Stock Watchlist Display
const StockFinancialStatement = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        stockFinancialyear: vars.stockFinancialyear,
        stockFinancial: vars.stockFinancial,
        stockFinancialReport:vars.stockFinancialReport,
        stocklistOptions:vars.stocklistOptions,
        codeSearchStockInfo: vars.codeSearchStockInfo,
        iStockData:vars.iStockData,
        sStockSmry:vars.sStockSmry,
        statusAlertN:vars.statusAlertN,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleSearchCode:(type,code)=>actions.sendAction('handleSearchCode', {type,code}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
        onChangeTextHandler:(pageType, type, data)=>actions.sendAction('onChangeTextHandler',{pageType, type, data}),
    }),
    ["handleOnClick", "handleSearchCode", "handleStatusAlert3", "onChangeTextHandler"]
)(StockFinancialStatement_Base);


// 2. Stock Watchlist Display
const BuyPageOld = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick"]
)(BuyPageOld_Base)

const StockWatchlistAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        watchlistCode:vars.watchlistCode,
        codeBlink:vars.codeBlink,
        dataWatchlist:vars.dataWatchlist,
        // pagination
        sumPageWatchlist:vars.sumPageWatchlist,
        thisPageWatchlist:vars.thisPageWatchlist,        
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
    }),
    ["handleOnClick"]
)(StockWatchlistAgGrid_Base)

// 3. Stock Trade History Display
const StockHistoryPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        rangeStockTradeHistory:vars.rangeStockTradeHistory,
        stockTradeHistorySMRY:vars.stockTradeHistorySMRY,
        stocklistOptions:vars.stocklistOptions,
        codeSearchStockInfo:vars.codeSearchStockInfo,
        tabStockTradeHistory:vars.tabStockTradeHistory,
        statusAlertN:vars.statusAlertN,
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode',{type, code}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleSearchCode", "handleOnClick", "handleStatusAlert3"]
)(StockHistoryPage_Base);
// Daily Table
const HistoryBrokerAgGridThird = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistoryMain: vars.stockTradeHistoryMain
    })
)(HistoryBrokerAgGridThird_Base);
// Broker Table
const HistoryBrokerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistoryMain: vars.stockTradeHistoryMain
    })
)(HistoryBrokerAgGrid_Base)
// Foreign Table
const HistoryBrokerAgGridSecond = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistoryMain: vars.stockTradeHistoryMain
    })
)(HistoryBrokerAgGridSecond_Base)
// Price Table
const HistoryPriceAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistoryPrice:vars.stockTradeHistoryPrice,
        tabStockTradeHistory:vars.tabStockTradeHistory,
    })
)(HistoryPriceAgGrid_Base)
// Buy Table
const HistoryBuyerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions,props)=>({
        stockTradeHistoryBuy : vars.stockTradeHistoryBuy
    })
)(HistoryBuyerAgGrid_Base)
// sell Table
const HistorySellerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistorySell : vars.stockTradeHistorySell
    })
)(HistorySellerAgGrid_Base)
// broker chart
const HistoryBrokerChart = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTradeHistoryChart:vars.stockTradeHistoryChart,
        tabStockTradeHistoryChart:vars.tabStockTradeHistoryChart,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleOnClick"]
)(HistoryBrokerChart_Base)

// 4. Stock Trade Summary Display
const StockTradeSummaryPage = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        // stockTradeSummaryMain:vars.stockTradeSummaryMain,
        stockTradeSummarySMRY:vars.stockTradeSummarySMRY,
        stocklistOptions:vars.stocklistOptions,
        codeSearchStockInfo:vars.codeSearchStockInfo,
        stockTickBoardType:vars.stockTickBoardType,
        statusAlertN:vars.statusAlertN,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
        handleSearchCode:(type, code)=>actions.sendAction('handleSearchCode',{type, code}),
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
    }),
    ["handleSearchCode", "handleOnClick", "handleStatusAlert3"]
)(StockTradeSummaryPage_Base);
// Stock tick Table
const StockTickAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        stockTickMain : vars.stockTickMain
    })
)(StockTickAgGrid_Base)
// stock trade summary table
const TradeSummaryAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        stockTradeSummaryMain:vars.stockTradeSummaryMain,
    }),
)(TradeSummaryAgGrid_Base);
// stock summary buyer table
const SummaryBuyerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        stockSummaryBuyerMain:vars.stockSummaryBuyerMain,
    }),
)(SummaryBuyerAgGrid_Base);
// stock summary seller table
const SummarySellerAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        stockSummarySellerMain:vars.stockSummarySellerMain,
    }),
)(SummarySellerAgGrid_Base);

// 5. Stock Special Notation
const StockSpecialNotation = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode
    }),
)(StockSpecialNotation_Base);
const SpecialNotationAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        specialNotation: vars.specialNotation,
        handleOnClick: (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type, data}),
    }),
    ["handleOnClick"]
)(SpecialNotationAgGrid_Base);
const SpecialNotationModal = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        specialNotationDetail: vars.specialNotationDetail,
    }),
)(SpecialNotationModal_Base);

const StockCorpAction = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        rangeCorpAction: vars.rangeCorpAction,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
        handleOnClick : (pageType, type, data) => actions.sendAction('handleOnClick', {pageType, type,data}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["setDataHandler", "handleOnClick", "handleStatusAlert3"]
)(StockCorpAction_Base);

const StockCorpActionAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        CORPORATE_ACTION_LIST: vars.CORPORATE_ACTION_LIST || [],
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["setDataHandler"]
)(StockCorpActionAgGrid_Base);


// >>>>>>> Modal <<<<<<<
const RegisterAmendModal = ContextConnector(BIPSAppContext,
    (vars, actions) => ({
        thememode: vars.thememode,
        amendActiveTab:vars.amendActiveTab,
        stocklistOptions:vars.stocklistOptions,
        amendGroupName : vars.amendGroupName,
        amendGroup : vars.amendGroup,
        addGroupName:vars.addGroupName,
        addGroupCode : vars.addGroupCode,
        codeSelected:vars.codeSelected,
        watchlistCode:vars.watchlistCode,
        statusAlertN:vars.statusAlertN,
        handleOnClick:(pageType, type, data)=>actions.sendAction('handleOnClick',{pageType, type, data}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
        onChangeTextHandler:(pageType, type, data)=>actions.sendAction('onChangeTextHandler',{pageType, type, data}),
    }),
    ["handleOnClick", "handleStatusAlert3", "onChangeTextHandler"]
)(RegisterAmendModal_Base);

const AmendGroupNameAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({
        amendGroupContainer:vars.amendGroupContainer,
        statusAlertC:vars.statusAlertC,
        handleOnClick : (pageType, type, data)=>actions.sendAction('handleOnClick', {pageType, type,data}),
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleOnClick", "handleStatusAlert3"]
)(AmendGroupNameAgGrid_Base)

const AmendGroupCodeAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions, props)=>({    
        amendCodeContainer:vars.amendCodeContainer,
        statusAlertC:vars.statusAlertC,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleStatusAlert3"]
)(AmendGroupCodeAgGrid_Base)
    
const AddGroupCodeAgGrid = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        addGroupCodeContainer:vars.addGroupCodeContainer,
        statusAlertC:vars.statusAlertC,
        handleStatusAlert3:(type,statusAlert,msg, data)=>actions.sendAction('handleStatusAlert3',{type,statusAlert,msg, data}),
    }),
    ["handleStatusAlert3"]
)(AddGroupCodeAgGrid_Base)

const BuyModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(BuyModal_Base)

const SellModal = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(SellModal_Base)

const BuyPage = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(BuyPage_Base)

const BuyPage2 = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(BuyPage2_Base)

const SellPage = ContextConnector(BIPSAppContext,
    (vars, actions)=>({
        networkState:vars.networkState,
    })
)(SellPage_Base)

//************************** Value Formatter For Ag-grid **************************
// >>>>>>> Standart Currency Formatter <<<<<<<
const currencyFormatter = (params) => {
    return ConvertToRupiah(params.value);
}
// >>>>>>> Standart Currency Formatter <<<<<<<
const AvgFormatter = (params) => {
    return ConvertToRupiah(params.value, 'avg');
}
// >>>>>>> Standart Time Formatter <<<<<<<
const TimeFormatter=(params)=>{
    return ConvertToTime(params.value);
}
// >>>>>>> Trillion Currency Formatter <<<<<<<
const trilionModifier =(params)=>{
    return CurrencyModifier('trilion', params.value)
}

function stringComparator(valueA, valueB){
    if(valueA !== null && valueB !== null){
        if(valueA.length < 2){
            return null;
        }else if(valueB.length < 2){
            return null;
        }else{
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    }

}
function integerComparator(valueA, valueB){
    if(valueA == ""){
        return null;
    }else if(valueB == ""){
        return null;
    }else{
        return valueA - valueB;
    }
}

function dateComparator(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return date1;
    }
    if (date1Number === null) {
        return date1;
    }
    if (date2Number === null) {
        return date2;
    }
    return date1Number - date2Number;
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

export default Stocks;
export { CustomFrameHeaderStock, BuyPage, BuyPage2, BuyPageOld, SellPage, AmendGroupNameAgGrid, AmendGroupCodeAgGrid, AddGroupCodeAgGrid, BuyModal, SellModal, RegisterAmendModal };
export {
    StockWatchlist, StockHistoryPage, StockPage,
    TableStockInfo, 
    StockFinancialStatement,
    TableProfil, TableCorpAction,
    StockTradeSummaryPage,
    TableDailyTechnical,
    StockSpecialNotation,
    SpecialNotationModal,
    StockCorpAction
};
