import React from 'react';
import { WSConnectionAction } from "../../appnetwork";
import { AppFrameAction } from '../../appframe.js';
import { optList } from './index';

/**
 * optList adalah objek yang berisi pernyataan2 yang digunakan di ESBN 
 * seperti pernyataan untuk syarat & ketentuan Subscription
 */


class TermConditionSubscription extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { }
    }

    render() {
        let p = this.props;
        return (
            <>
                <AppFrameAction ref="frameAction" />
                <WSConnectionAction ref="wsAction" />
                <div className="text-white f-13 text-center bg-dark-grey">
                    <div className="form-group row">
                        <div className="col-sm-12 px-5 py-2 col-form-label" style={{"text-align": "justify"}}>
                            <ol className='pl-4'>
                                {optList.term_condition_subscription.map((d, id) =>
                                    <>
                                        <li key={'tcsub'+id}>{d.textIndo}</li>
                                        {d.textIndo_ && <ol type='a'>
                                            {d.textIndo_.map((dd, key) => <li key={'tcsubd'+key}>{dd}</li>)}
                                        </ol>}
                                    </>
                                )}
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="text-center col-sm-12">
                    <button className="btn btn-primary btn-sm col-sm-2" 
                        onClick={() => p.onClose()}
                    >OK</button>
                </div>
            </>
        );
    }
}

export default TermConditionSubscription;