import React from 'react';
import { Popup} from 'semantic-ui-react';
import Select from "react-select";
import Slider from 'react-input-slider';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import { BIPSAppContext } from "../../AppData";
import { WSConnectionAction } from "../../appnetwork";
import { ContextConnector } from "../../appcontext";
import { AppFrameAction } from "../../appframe";

import { ModalEtalaseFund } from './index';
import { DropdownIndicator } from '../../app_components';
import * as util from '../../utils';

class ModalRedemption_Base extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeStock: props.dataTable,
            qtyUnit: "",
            qtyAmount: "",
            listMax: 4,
            productList: [],
            checked: false,
            sliderVal: 0,
        }
    }

    onChangeUnit = (type, val) => {
        let { activeStock } = this.state;
        let { currency_value } = this.props.aperdConvertCurrency.find(rc => rc.currency_from === (activeStock.currency || activeStock.Currency || "IDR"));
        let qtyUnit = type === 'input' ? val : (val * Number(activeStock.Unit) / 100).toFixed(4);
        let sliderVal = type === 'slider' ? val : (Number(val) * 100 / activeStock.Unit).toFixed(4);
        let qtyAmount = (Number(qtyUnit) * Number(activeStock.LastPrice) * Number(currency_value)).toFixed(0);
        this.setState({ sliderVal, qtyUnit, qtyAmount });
    }

    doAdd = () => {
        let { activeStock, qtyUnit, qtyAmount, productList } = this.state;
        let { PORTFOLIO_MUTUAL_FUND, aperdConvertCurrency, cbByRdnNFund, userInfo, setDataHandler } = this.props;
        if((qtyAmount * 1) < (activeStock.MinRedem * 1)){
            setDataHandler({"statusAlertN": true, "msgAlert3": `Minimum transaction ${util.mathCall.setRupiah(activeStock.MinRedem)}`});
        }else{
            if(activeStock.fee_redm === undefined){
                activeStock = PORTFOLIO_MUTUAL_FUND.find(d => d.FundCode === activeStock.FundCode);
            }
            let fIndex = productList.findIndex(d => d.FundCode === activeStock.FundCode);
            let { currency_value } = aperdConvertCurrency.find(rc => rc.currency_from === activeStock.currency || activeStock.Currency);
            if(fIndex < 0){
                let custBank = (cbByRdnNFund || []).find(d => d.FundCode === activeStock.FundCode)
                !(custBank) && this.refs.netAction.send(util.fmsg.aperd_cbByRdnFund(activeStock.FundCode, userInfo.rdnbncode));
                productList.push({ ...activeStock, 
                    QtyUnit: Number(qtyUnit),
                    QtyAmount: Number(qtyUnit)*Number(activeStock.LastPrice)*Number(currency_value), 
                    Fee_nominal: Number(activeStock.fee_redm) === 0 ? 0 : Number(activeStock.fee_redm)*Number(qtyUnit)*Number(activeStock.LastPrice)*Number(currency_value)/100
                });
            } else if((productList[fIndex].QtyUnit + Number(qtyUnit)) > activeStock.Unit){
                setDataHandler({ statusAlertN: true, msgAlert3: `maximum redemption ${util.mathCall.setRupiah(activeStock.Unit, 4)}` })
            } else {
                productList = productList.map(d => d.FundCode === activeStock.FundCode ? { ...d, 
                    QtyUnit: d.QtyUnit + Number(qtyUnit),
                    QtyAmount: (d.QtyUnit + Number(qtyUnit))*Number(d.LastPrice)*Number(currency_value), 
                    Fee_nominal: Number(activeStock.fee_redm) === 0 ? 0 : Number(d.fee_redm)*(d.QtyUnit + Number(qtyUnit))*Number(activeStock.LastPrice)*Number(currency_value)/100
                } : d);
            }
            this.setState({ productList, qtyUnit: "", qtyAmount: "" });
        }
    }

    onOpenModal = (data) => {
        let custBank = (this.props.cbByRdnNFund || []).find(d => d.FundCode === data.FundCode)
        !(custBank) && this.refs.netAction.send(util.fmsg.aperd_cbByRdnFund(data.FundCode, this.props.userInfo.rdnbncode));
        this.refs.netAction.send(util.fmsg.aperd_fundDetail(data.FundCode));
        this.refs.netAction.send(util.fmsg.aperd_companyProfileByIMCode(data.IMCode));
        this.refs.netAction.send(util.fmsg.aperd_navByPeriodeAndFundCode(data.FundCode, "1Y", /**util.timeCall.getdate('/') */));
        
    }

    buttonClickFundDetail = (data) => {
        this.refs.frameAction.showModal({
            headerClass: () => <div class="row">
                    <div className="col-sm-6 pl-4 pr-0 mx-0">Product Profile</div>
                    <div className="col-sm-6 pr-4 mx-0 text-right">
                        <i className="icofont icofont-close text-icofont-close text-white click-pointer" 
                            onClick={() => this.refs.frameAction.closeModal(100)}
                        ></i>
                    </div>
                </div>,
            size: 'large',
            contentClass: ModalEtalaseFund,
            contentProps: {dataTable: data},
            onOpen: this.onOpenModal(data)
        })
    }

    setSelectedStock = (data) => {
        this.setState({ activeStock: data, qtyUnit: "", sliderVal: 0 });
        data.fee_redm === undefined && this.refs.netAction.send(util.fmsg.aperd_getMutualFundByFundCode(data.FundCode));
    }

    getMoreProduct = () => {
        let listMax = this.props.PORTFOLIO_MUTUAL_FUND.filter(d => d.FundCode).length > this.state.listMax ? this.state.listMax + 4 : 4;
        this.setState({ listMax })
    }

    doCheckout = () => {
        let data = this.state.productList.map(d => ({ 
            TransDate: moment().format("YYYY-MM-DD HH:mm:ss"),
			Trans_type: "2",
            FundCode: d.FundCode,
			sa_code: this.props.userInfo.sid,
            SID: this.props.userInfo.sid,
			QtyAmount: d.QtyAmount.toFixed(4),
            QtyUnit: d.QtyUnit.toString(),
			LastNav: d.Nav,
            Fee_nominal: d.Fee_nominal.toString(),
			fee_persen: d.fee_redm.split(".")[0],
			redm_payment_acc_seq_code: "",
			redm_payment_bic_code: "",
			redm_payment_acc_no: "",
			payment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
			transfer_type: "2",
            "device_id": 1
        }));
        this.refs.netAction.send(util.fmsg.aperd_redemption(data));
        this.refs.frameAction.closeModal(100);
    }

    doDeleteBasket = (fundCode) => {
        let { productList } = this.state;
        this.setState({ productList: productList.filter( productList => productList.FundCode !== fundCode )});
    }

    render() {
        let { thememode, availableCash, userInfo, PORTFOLIO_MUTUAL_FUND=[] } = this.props;
        let { activeStock, qtyUnit, qtyAmount, listMax, productList, checked, sliderVal } = this.state;
        let subTotal = productList.reduce((a,b) => a + b.QtyAmount, 0);
        let feeTotal = productList.reduce((a,b) => a + b.Fee_nominal, 0);

        return (<>
            <AppFrameAction ref="frameAction" />
            <WSConnectionAction ref="netAction" />
            <div className="text-white f-14 bg-dark-grey py-2">
                <div className="form-group row mx-2 pt-2 pb-3" style={{ "borderBottom": "var(--warna-d-border) solid 1px", "alignItems":"center" }}>
                    <div className="col-sm-10 px-0">List Item</div>
                    {/* <div className="col-sm-2 px-0">
                        <div className="w-100 bg-gray-tradding d-border p-2">
                            <p>Informasi Available Cash</p>
                            <p className="font-weight-bold">Rp. {util.mathCall.setRupiah(availableCash)}</p>
                        </div>
                    </div> */}
                </div>
                <div className="form-group row mx-2 py-0" style={{ "alignItems":"center" }}>
                    <div className="col-sm-4 px-2" style={{ "borderLeft": "var(--warna-d-border) solid 1px", "alignItems":"center" }}>
                        <h4 className="font-weight-bold">{activeStock.FundName}</h4>
                        <p className="text-muted mb-0">
                            Availabe Unit {util.mathCall.setRupiah(activeStock.Unit, 4)}
                        </p>
                        <p className="text-muted">
                            NAV/Unit {util.mathCall.setRupiah(activeStock.LastPrice)}&nbsp;
                            <Popup content={`As of ${util.timeCall.todate3(activeStock.Last_Nav_Date)}`}
                                mouseEnterDelay={900}
                                mouseLeaveDelay={100}
                                position='top center'
                                trigger={
                                    <sup>
                                        <i class="fa fa-info-circle"></i>
                                    </sup>
                            } />
                        </p>
                    </div>
                    <div className="row col-sm-3">
                        <div className="w-100 mb-3">
                            <NumberFormat
                                value={qtyUnit}
                                className="form-control bg-grey d-border text-right col-sm-12 px-0 align-self-center holo f-14"
                                type="text"
                                thousandSeparator={true}
                                placeholder='Insert unit'
                                onFocus={e => e.target.select()}
                                isAllowed={({ value }) => Number(value) <= Number(activeStock.Unit) ? true : false}
                                // disabled={p.onLoading}
                                allowNegative={false}
                                onValueChange={({value}) => this.onChangeUnit("input", value) }
                            />
                        </div>
                        <div className="row px-0 mx-0 w-100">
                            <div className="col-sm-10 px-1 mx-0" style={{ "alignItems":"center" }}>
                                <Slider
                                    axis="x"
                                    x={sliderVal}
                                    onChange={({ x }) => this.onChangeUnit('slider', x) }
                                    styles={{
                                        track: { backgroundColor: "var(--warna-d-border)" },
                                        active: { backgroundImage: "linear-gradient(#5cb85c,#255625)" },
                                        disabled: { opacity: 0.5 }
                                    }}
                                />
                            </div>
                            <div className="col-sm-2 mx-0 text-muted" style={{ "alignItems":"center" }}>{sliderVal}%</div>
                        </div>
                    </div>
                    <div className="col-sm-3 py-0">
                        <div className="w-100 mb-3">
                            <NumberFormat
                                className="form-control bg-grey d-border text-right col-sm-12 px-0 align-self-center holo f-14"
                                type="text"
                                thousandSeparator={true}
                                placeholder='Estimated Total Amount '
                                onFocus={e => e.target.select()}
                                disabled={true}
                                value={qtyAmount}
                                allowNegative={false}
                            />
                        </div>
                        <div className="row px-0 mx-0 w-100">&nbsp;</div>
                    </div> 
                    <div className="col-sm-2 mx-0" style={{ "alignItems":"center" }}>
                        <button className="my-2 mx-0 btn btn-sm btn-success" 
                            disabled={qtyUnit === "" || qtyUnit === 0}
                            onClick={() => this.doAdd()}
                        >Add</button>
                    </div>
                </div>
                <div className="bg-gray-tradding form-group row mx-2 py-0" style={{ "alignItems":"center" }}>
                    <div className="row col-sm-10 pl-3 pr-1 py-3 mx-0">
                        {
                            PORTFOLIO_MUTUAL_FUND.filter(d => d.FundCode && d.FundCode !== activeStock.FundCode).map((data, index) => (
                                index >= listMax-4 && index < listMax ?
                                    <div key={index} className={"col-sm-3 px-1 "+index}>
                                        <div className="w-100 f-14 font-weight-bold">
                                            {data.FundName}
                                        </div>
                                        <div className="w-100 f-12">
                                            Availabe Unit {util.mathCall.setRupiah(data.Unit, 4)}
                                        </div>
                                        <div className="w-100 f-12">
                                            Last NAV/Unit {util.mathCall.setRupiah(data.LastPrice)}
                                        </div>
                                        <div className="w-100 row mx-0 px-0">
                                            <div className="col-sm-6 pr-1 pl-0 mx-0">
                                                <button className="my-2 mx-0 col-sm-12 btn btn-sm btn-success" 
                                                    onClick={() => this.setSelectedStock(data)}
                                                > Select </button>
                                            </div>
                                            <div className="col-sm-6 pl-1 pr-0 mx-0">
                                                <button className="my-2 mx-0 col-sm-12 btn btn-sm btn-dark"
                                                    onClick={() => this.buttonClickFundDetail(data)}
                                                >More Info</button>
                                            </div>
                                        </div>
                                    </div> : ''
                                )
                            )
                        }
                    </div>
                    <div className="col-sm-2 px-0">
                        <div className="w-100 p-2 text-center">
                            <button disabled={PORTFOLIO_MUTUAL_FUND.filter(d => d.FundCode).length <= 4 ? true : false} 
                                className="btn btn-sm btn-link f-14" style={{ "color" : "var(--warna-text-basic)" }}
                                onClick={() => this.getMoreProduct()} 
                            >More Product</button>
                            <label className='f-14'>
                                {listMax/4} of {Math.ceil(PORTFOLIO_MUTUAL_FUND.filter(d => d.FundCode).length/4)}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row mx-2 pt-2 pb-0 mb-0" style={{ "borderTop": "var(--warna-d-border) solid 1px", "alignItems":"center" }}>
                    <div className="w-100 mb-2">Selected Product</div>
                    <div className="col-sm-3 p-2 mx-0 bg-gray-tradding text-center" style={{ "alignItems":"center" }}>
                        Product
                    </div>
                    <div className="col-sm-3 p-2 mx-0 bg-gray-tradding text-center" style={{ "alignItems":"center" }}>
                        Unit
                    </div>
                    <div className="col-sm-3 p-2 mx-0 bg-gray-tradding text-center" style={{ "alignItems":"center" }}>
                        NAV/Unit
                    </div>
                    <div className="col-sm-3 p-2 mx-0 bg-gray-tradding text-center" style={{ "alignItems":"center" }}>
                        Fee (%)
                    </div>
                </div>
                <div className="form-group mx-2 pt-2 card-76" style={{ "border": "var(--warna-d-border) solid 0.5px", "alignItems":"center", "overflow-x": "hidden","min-height": "76px"}}>
                    { productList.map(
                        (data, index) =>
                            (
                                <div key={index} className={index % 2 === 0 ? "bg-black-trading w-100 row px-0 mx-0 " : "w-100 row px-0 mx-0 "}>
                                    <div className="col-sm-3 p-2 mx-0 text-center" style={{ "border": "var(--warna-d-border) solid 0.5px", "alignItems":"center", "verticalAlign":"middle" }}>
                                        {data.FundName}
                                    </div>
                                    <div className="col-sm-3 p-2 mx-0 text-center" style={{ "border": "var(--warna-d-border) solid 0.5px", "alignItems":"center", "verticalAlign":"middle" }}>
                                        {util.mathCall.setRupiah(data.QtyUnit, 4)}
                                    </div>
                                    <div className="col-sm-3 p-2 mx-0 text-center" style={{ "border": "var(--warna-d-border) solid 0.5px", "alignItems":"center", "verticalAlign":"middle" }}>
                                        {util.mathCall.setRupiah(data.LastPrice)} {data.Currency}
                                    </div>
                                    <div className="col-sm-3 p-2 mx-0 text-center" style={{ "border": "var(--warna-d-border) solid 0.5px", "alignItems":"center", "verticalAlign":"middle" }}>
                                        {util.mathCall.setRupiah(data.fee_redm)}
                                        <button className="mx-0 col-sm-2 btn btn-xs btn-danger pull-right" 
                                            onClick={() => this.doDeleteBasket(data.FundCode)}
                                        >
                                            <i className='fa fa-trash'></i>
                                        </button>
                                    </div>
                                </div>
                            )
                    )
                    }
                </div>
                <div className="form-group row mx-2 pt-1 pb-2" style={{ "borderBottom": "var(--warna-d-border) solid 1px", "alignItems":"center" }}>
                    <div className="col-sm-6 px-2 py-3 mx-0"></div>
                    <div className="col-sm-3 px-2 py-3 mx-0">
                        <div className="w-100">
                            Sub Total
                            <Popup content='Redemption Amount'
                                mouseEnterDelay={900}
                                mouseLeaveDelay={100}
                                position='bottom center'
                                trigger={
                                    <i className="fa fa-info-circle text-dark" 
                                        style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                    ></i>
                                } 
                            />
                        </div>
                        <div className="w-100">
                            Total Fee
                            <Popup content='Redemption Fee'
                                mouseEnterDelay={900}
                                mouseLeaveDelay={100}
                                position='bottom center'
                                trigger={
                                    <i className="fa fa-info-circle text-dark" 
                                        style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                    ></i>
                                } 
                            />
                        </div>
                        <div className="w-100">
                            Estimated Total Redemption&nbsp;
                            {/* <Popup content={`${util.mathCall.setRupiah(productList.reduce((a,b) => a + b.QtyUnit, 0).toFixed(0))} Unit`}
                                mouseEnterDelay={900}
                                mouseLeaveDelay={100}
                                position='bottom center'
                                trigger={
                                    <i className="fa fa-info-circle text-dark"
                                        style={{"fontSize": "8px","verticalAlign": "text-top"}}
                                    ></i>
                                } 
                            /> */}
                        </div>
                    </div>
                    <div className="col-sm-3 px-2 py-3 mx-0">
                        <div className="w-100">Rp. {util.mathCall.setRupiah(subTotal)}</div>
                        <div className="w-100">Rp. {util.mathCall.setRupiah(feeTotal)}</div>
                        <div className="w-100">Rp. {util.mathCall.setRupiah(subTotal - feeTotal)}</div>
                    </div>
                </div>
            </div>
            <div className="form-group row mx-2 py-0 mb-1" style={{ "alignItems":"center" }}>
                <div className="col-sm-6 px-2 mx-0">
                    <input className="magic-checkbox" type="checkbox" name="agreesubs" id="agreesubs" 
                        value={checked}
                        onChange={() => this.setState({checked: !checked})}
                    />
                    <label htmlFor="agreesubs" className="text-white f-12" style={{ "textAlign" : "justify" }}>
                        I agree that the redemption action on this page. I understand that the disbursed funds are an estimated
                        nominal that will be received and the nominal of the disbursed funds may change depending on the
                        calculation by custodian bank.
                    </label>
                </div>
                <div className="row col-sm-6 px-2 mx-0">
                    <div className="col-sm-3 px-2 mx-0 f-14" style={{ "alignSelf" : "center" }}>
                        Benefeciary Account
                    </div>
                    <div className="col-sm-9 px-2 mx-0 f-14" style={{ "alignSelf" : "center" }}>
                        <Select
                            components={{ DropdownIndicator }}
                            // getOptionLabel={(option) => `${option.bankcode}`}
                            isSearchable={true}
                            maxMenuHeight={155}
                            styles={util.customStyles}
                            placeholder={<div>Benefeciary Account..</div>}
                            // options={optList.bank}
                            options={[
                                { value: userInfo.rdncbaccno, label:`RDN - ${userInfo.rdnbncode} - ${userInfo.rdncbaccno}` }
                            ]}
                            className="bankPenerimaSelect text-left"
                            theme={(theme) => util.selectSelectionTab(theme, thememode)}
                            isDisabled={true}
                            value={{ value: userInfo.rdncbaccno, label:`RDN - ${userInfo.rdnbncode} - ${userInfo.rdncbaccno}` }}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group w-100 mx-0 mb-0 px-4 py-0 text-right" style={{ "alignItems":"center" }}>
                <button className="my-1 mx-0 px-0 col-sm-2 btn btn-sm btn-dark" 
                    disabled={!(checked && productList.length > 0)}
                    onClick={() => this.doCheckout()}
                >Submit</button>
            </div>
        </>);
    }
}

const ModalRedemption = ContextConnector(BIPSAppContext,
    (vars, actions, props) => ({
        thememode: vars.thememode,
        availableCash: vars.availableCash,
        PORTFOLIO_MUTUAL_FUND: vars.PORTFOLIO_MUTUAL_FUND,
        aperdConvertCurrency: vars.aperdConvertCurrency,
        cbByRdnNFund: vars.cbByRdnNFund,
        userInfo: vars.userInfo,
        setDataHandler: (newVars) => actions.sendAction('setDataHandler', {newVars}),
    }),
    ["setDataHandler"]
)(ModalRedemption_Base);

export default ModalRedemption;