import React from "react";
import { AppFrameAction } from "../../appframe";

class ForgotPINModal extends React.PureComponent {
    closeClick = (e) => {
        this.refs.frameAction.closeModal(100);
    }

    render() {
        let { lang='eng' } = this.props;
        
        return (<>
            <AppFrameAction ref="frameAction" />
            <div className="card-325">
                <label className="col-sm-12 px-5 py-2 col-form-label text-center text-gray-tradding f-25 mt-4">
                    {lang === 'eng' ? 'Forgot PIN' : 'Lupa Password'}
                </label>
                <div className="text-white f-12 mt-5">
                    <div className="form-group mb-3 text-center f-15">
                        <label className="col-sm-12 px-5 py-2 col-form-label">
                            {lang === 'eng' ? 
                                'Please contact our customer service' 
                            : 
                                'Silahkan hubungi Dukungan Layanan kami'
                            }
                        </label>
                    </div>
                    <div className="form-group mb-5 text-center f-25">
                        <label className="col-sm-12 px-5 py-2 col-form-label">
                            14009
                        </label>
                    </div>
                    <div className="form-group py-3">
                        <div className="justify-content-center align-items-center d-flex py-0 px-5">
                            <button type="submit" className="btn btn-primary form-control py-0" 
                                id="btn_pass" onClick={this.closeClick}
                            >
                                {lang === 'eng' ? 'Back' : 'Kembali'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default ForgotPINModal;