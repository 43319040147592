import React, { Component } from 'react';
import { AppFrameAction } from '../../appframe.js';
import anychart from 'anychart';
import AnyChart from 'anychart-react'
import 'anychart/dist/css/anychart-ui.min.css';
import 'anychart/dist/js/anychart-ui.min';
import 'anychart/dist/fonts/css/anychart-font.min.css';
import 'anychart/dist/js/anychart-data-adapter.min'
import 'anychart/dist/js/anychart-annotations.min';

import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min';
import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap/dist/js/bootstrap');

class EtalaseChart extends Component {

    componentDidMount() {
        $('#etalase-container').css('height', '100%');
    }

    render() {
        let { chartData=[], fundName='' } = this.props;
        let firstDate = chartData.length > 0 ? chartData[0][0] : '';
        let lastDate =  chartData.length > 0 ? chartData[chartData.length-1][0] : '';
        
        var chart = anychart.stock();
        
        chart.scroller(true);
        chart.scroller().enabled(false);
        var credits = chart.credits();
        credits.enabled(false);
        
        anychart.onDocumentReady(function () {
            console.log(chartData)
            var table = anychart.data.table();
            table.addData(chartData);
            var mapping = table.mapAs({x: 0, value: 1,});    

            chart.padding().right(45);
            chart.padding().top(0);
            chart.padding().left(15);
            chart.padding().bottom(10);

            // create plot on the chart
            var plot = chart.plot(0);
            // enabled x-grid/y-grid
            plot.yGrid().enabled(true);
            plot.yGrid().stroke({color: "#555555", dash: "2 1"});
            plot.xMinorGrid().enabled(true);
            plot.xMinorGrid().stroke({color: "#555555", dash: "2 1"});

            // set orientation y-axis to the right side
            plot.yAxis().orientation('right');

            // create line series on the plot
            var aaplSeries = plot.line(mapping);
            // set series settings
            aaplSeries.name(fundName).zIndex(chartData.length-1);
            aaplSeries.stroke("#00ff08");
            
            chart.selectRange(firstDate, lastDate);

            chart.background().fill("none");

            chart.draw();
        });
        return (
            <AnyChart instance={chart} id="etalase-container" />
        );
    }
}

export default EtalaseChart;
