export { default as bank_bca } from './img-bank/Logo-BCA.png';
export { default as bank_bni } from './img-bank/BNI.png';
export { default as bank_bni_s } from './img-bank/BNISyariah.png';
export { default as bank_bsi } from './img-bank/bsi.png';
export { default as bank_bri } from './img-bank/BRI.png';
export { default as bank_mandiri } from './img-bank/Mandiri_logo.png';
export { default as bank_cimb } from './img-bank/CIMB_Niaga_logo.svg';

export { default as idx_logo_mini } from './idx_mini.png';
export { default as idx_logo } from './idx.png';
export { default as bahana_logo } from './Bahana_Sekuritas_Reverse_BW.png';
export { default as ojk_logo } from './ojk_small.png';
export { default as dxtrade_logo } from './logo_white_small_medium(2).png';
