import React, { memo, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

// import 'bootstrap/dist/css/bootstrap.min.css';

import { ContextConnector } from '../../appcontext';
import { BIPSAppContext } from '../../AppData';
import { ContentPushNotif } from './pushNoification';

const CarouselNotif_Base = memo(({ setDataHandler, listNotif={status:false, data:[]} }) => {

   const [index, setIndex] = useState(0);

   const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
   };

   return (<>
      <SweetAlert
         show={listNotif.status}
         text="SweetAlert in React"
         title={<span className="text-white py-3 my-4">NOTIFIKASI WEB</span>}
         style={{'color':'var(--text-white)', "background-color": "var(--warna-bg-dark-grey)", width: "42em"}}
         showConfirm={false}
         showCancel={true}
         cancelBtnText="Ok"
         onCancel={() => setDataHandler({ listNotif: undefined })}
         cancelBtnCssClass="btn btn-primary text-white text-bold"
      >
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
               
               {/* <!-- Indicators --> */}
               {/* <ol className="carousel-indicators">
                  <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                  <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                  <li data-target="#carousel-example-generic" data-slide-to="2"></li>
               </ol> */}

               {/* <!-- Wrapper for slides --> */}
               <div style={{'color':'var(--text-white) !important', "background-color": "var(--warna-bg-dark-grey) !important"}}>
                  <div className="carousel-inner" role="listbox" >
                     {listNotif.data.map((d, i) => (
                        <div key={"list-notif"+i} className={`item ${i === index ? "active" : ""}`}>
                           <ContentPushNotif content_data={d.content_data} content_url={d.content_url} />
                        </div>
                     ))}
                  </div>
               </div>

               {/* <!-- Controls --> */}
               <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev" style={{ background: "none" }}>
                  <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
               </a>
               <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next" style={{ background: "none" }}>
                  <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
               </a>
            </div>
         <div className="card card-body row mx-0 pt-4" style={{ maxHeight: "70vh", overflowX: "auto"}}>
         </div>
      </SweetAlert>
   </>)
});

const CarouselNotif = ContextConnector(BIPSAppContext,
   (vars, act) => ({
      listNotif: vars.listNotif,
      setDataHandler: (newVars) => act.sendAction('setDataHandler', {newVars}),
   }), 
   ["setDataHandler"]
)(CarouselNotif_Base)

export default CarouselNotif;